import { connect } from "react-redux";
import Copy from "clipboard-copy";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";

import AssetView from "app/pages/Dashboard/assets/indexView";

import { bytesToSize } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import CSVImg from "assets/csv-file-icon.svg";
import PDFImg from "assets/pdf.png";

const getIcon = dataItem => {
  if (dataItem.content_type === "application/pdf") {
    return <img src={PDFImg} alt="PDF" />;
  } else if (dataItem.content_type === "text/csv") {
    return <img src={CSVImg} alt="CSV" />;
  } else {
    return <img src={dataItem.url} alt="Asset Icon" />;
  }
};

const fileNameFormat = dataItem => (
  <span className="name-container">
    <span className="img-container">{getIcon(dataItem)}</span>
    <span className="file-name">{dataItem.filename}</span>
  </span>
);

const fileSizeFormat = dataItem => {
  return bytesToSize(dataItem.filesize);
};
const headings = [
  { label: "Name", id: "filename", formator: fileNameFormat },
  { label: "Uploaded At", id: "created_at", type: "date" },
  { label: "Size", id: "filesize", formator: fileSizeFormat },
];

const Assets = props => {
  const { sport_key } = props.match.params;
  const [copySnackbar, setCopySnackbar] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataId, setDataId] = useState("");
  const [deleteFileName, setDeleteFileName] = useState("");

  const { assests, assestLoading, getAssetData, totalAssetsCount } = props;

  useEffect(() => {
    getAssetData();
  }, [getAssetData]);

  const copyHandler = (event, uid) => {
    const { url } = assests.find(dataObject => dataObject.uid === uid);
    Copy(url);
    setCopySnackbar(true);
  };
  const handleSnackbarClose = event => setCopySnackbar(false);

  // Download Image

  const downloadHandler = (event, uid) => {
    const assetObj = assests.find(dataObject => uid === dataObject.uid);
    /*istanbul ignore else*/
    if (assetObj) {
      const link = document.createElement("a");
      link.href = assetObj.url;
      link.setAttribute("download", assetObj.filename);
      document.body.appendChild(link);
      return link.click();
    }
  };
  // Delete Single Api Call
  const deleteHandler = async event => {
    await props.deleteAsset({
      url: `/v1/admin/applications/${sport_key}/assets/${dataId}`,
    });

    getAssetData();
    setDataId("");
    setDeleteModal(false);
  };
  const deleteModalHandler = (event, uid, dataItem) => {
    setDeleteFileName(dataItem.filename);
    setDataId(uid);
    setDeleteModal(true);
  };
  const handleModalClose = event => {
    setDeleteModal(false);
    setDataId("");
  };

  const actions = [
    {
      id: "copy",
      label: "Copy",
      icon: <CopyIcon fontSize="small" data-testid="copy-btn" />,
      handler: copyHandler,
    },
    {
      id: "download",
      label: "Download",
      icon: <DownloadIcon fontSize="small" data-testid="download-btn" />,
      handler: downloadHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => props.isOwner,
    },
  ];

  return (
    <AssetView
      data={assests}
      headings={headings}
      actions={actions}
      handleSnackbarClose={handleSnackbarClose}
      copySnackbar={copySnackbar}
      deleteModal={deleteModal}
      handleModalClose={handleModalClose}
      deleteHandler={deleteHandler}
      deleteFileName={deleteFileName}
      name="Assets"
      isSorting
      totalCount={totalAssetsCount}
      isLoading={assestLoading}
    />
  );
};

/*istanbul ignore next*/
const mapStateToProps = state => {
  return {
    isOwner: state.application.isOwner,
  };
};

/*istanbul ignore next*/
const mapDispatchToProps = dispatch => {
  return {
    deleteAsset: payload => dispatch(deleteRecord(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Assets);
export { Container, Assets as Component };
