import React from "react";

const Dashboard = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M17 2.92V4.84H13V2.92H17ZM7 2.92V8.68H3V2.92H7ZM17 10.6V16.36H13V10.6H17ZM7 14.44V16.36H3V14.44H7ZM19 1H11V6.76H19V1ZM9 1H1V10.6H9V1ZM19 8.68H11V18.28H19V8.68ZM9 12.52H1V18.28H9V12.52Z"
          fill="#EEEEEE"
        />
      </g>
    </svg>
  );
};
export default Dashboard;
