import { Button } from "@dfep/ui-component";
import { FormWrapper } from "styles/global.style";
import { useForm } from "react-hook-form";
import Form from "app/components/Common/Form";
import PanelFooter from "app/components/Common/PanelFooter";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useState } from "react";

const UpdateDevicesDataView = props => {
  const InstallationDataForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all", // It will Focus on every field  where error is there not on first error field which is by default
  });
  const {
    formState: { dirtyFields, errors },
  } = InstallationDataForm;
  const [customSchema] = useState({
    title: "",
    uid: "installationData",
    name: "installationData",
    type: "group",
    defaultValue: "",
    validations: {},
    field_metadata: {
      options: [],
    },
    _schema: [
      {
        title: "Push Provider",
        uid: "push_provider",
        name: "push_provider",
        type: "text",
        defaultValue: props?.installationData?.push_provider ?? "",
        validations: {},
        field_metadata: {
          isBorder: false,
          textFieldProps: { disabled: true },
          options: [],
        },
        _schema: [],
      },
      {
        title: "User Id",
        uid: "user_uid",
        name: "user_uid",
        type: "text",
        defaultValue: props?.installationData?.user_uid ?? "",
        validations: {
          required: "User Id is required.",
        },
        field_metadata: {
          isBorder: false,
          options: [],
        },
        _schema: [],
      },
      {
        title: "Device Token",
        uid: "device_token",
        name: "device_token",
        type: "text",
        defaultValue: props?.installationData?.device_token ?? "",
        validations: {
          required: "Device Token is required.",
        },
        field_metadata: {
          isBorder: false,
          options: [],
        },
        _schema: [],
      },
      {
        title: "Device Type",
        uid: "device_type",
        name: "device_type",
        type: "select",
        defaultValue: props?.installationData?.device_type ?? "",
        validations: {
          required: "Device Type is required.",
        },
        field_metadata: {
          isBorder: false,
          options: [
            { label: "Android", value: "android" },
            { label: "iOS", value: "ios" },
          ],
        },
        _schema: [],
      },
      {
        title: "Custom Fields",
        uid: "custom",
        name: "custom_fields",
        type: "jsonEditor",
        defaultValue:
          JSON.stringify(props?.installationData?.custom_fields, null, "\t") ??
          "{}",
        validations: {
          required: "Custom Fields required.",
        },
        field_metadata: {
          isBorder: true,
        },
        _schema: [],
      },
    ],
  });

  return (
    <>
      <PanelHeader
        backUrl={`/dashboard/${props.sport_key}/${props.env_key}/reports/devices`}
        title="Update Devices Data"
        breadCrumbItems={[
          {
            title: "Reports",
          },
        ]}
      />
      <FormWrapper elevation={4} variant="outlined">
        <Form
          formId="installationDataForm"
          customSchema={customSchema}
          onSubmit={props.formSubmit}
          form={InstallationDataForm}
          additionalFormAttributes={{
            className: "maxWidthSm",
            "data-testid": "installationDataForm",
          }}
        />

        <PanelFooter>
          <div>
            <Button
              onClick={props.formCancelHandler}
              variant="contained"
              color="primary"
              data-testid="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              data-testid="submit-btn"
              disabled={
                !Object.keys(dirtyFields).length || !!Object.keys(errors).length
              }
              form="installationDataForm"
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </div>
        </PanelFooter>
      </FormWrapper>
    </>
  );
};

export default UpdateDevicesDataView;
