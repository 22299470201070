import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { deleteRecord } from "services/deleteObject";
import { useModal } from "app/hooks/modal";
import AdvPlayerStatTableView from "app/pages/Dashboard/Data/Roster/AdvPlayerStats/get/AdvPlayerStatTableView";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";

const headings = [
  { label: "Player ID", id: "pid" },
  { label: "Team ID", id: "tid" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];
const extraInfo = [
  { label: "Player ID", id: "pid" },
  { label: "league ID", id: "league_id" },
  { label: "Season ID", id: "season_id" },
  { label: "Year", id: "year" },
];

function AdvPlayersData({
  fetchAdvStatsData,
  setAdvStatData,
  advStatData,
  advStatCount,
  isLoading,
  match,
  location,
  dispatch,
  openSyncModal,
  syncModalClose,
  syncDetails,
  access_type,
  READ_ONLY,
  history,
}) {
  const { team_name, env_key, sport_key } = match.params;

  const [playerObj, setPlayerObj] = useState(null);
  const [selected, setSelected] = useState([]);

  const {
    showDelete,
    showMultipleDelete,
    openModalHandler,
    closeModalHandler,
  } = useModal();

  //   Delete Api Call

  const deleteAdvPlayerStatReq = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/advanced_player_stats/${playerObj.uid}`,
      }),
    );
    CloseModal();
    setSelected([]);
    fetchAdvStatsData();
  };
  const deleteMultiReq = async () => {
    await dispatch(
      deleteRecord({
        url: "/v1/nba/admin/data/advanced_player_stats",
        key: "uids",
        form: selected,
      }),
    );
    CloseModal();
    setSelected([]);
    fetchAdvStatsData();
  };

  //  Delete  Modal functions

  const CloseModal = () => {
    closeModalHandler();
    setPlayerObj(null);
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };
  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({
      event,
      data: advStatData,
    });
    setSelected(newSelecteds);
  };

  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  useEffect(() => {
    fetchAdvStatsData();
    return () => {
      setAdvStatData([]);
      setSelected([]);
      setPlayerObj(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team_name, fetchAdvStatsData]);

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-icon" />,
      handler: (event, uid) =>
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/rosters/advancedPlayerStats/update/${uid}`,
          state: { from: location },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-icon" />,
      handler: (event, uid, dataItem) => {
        setPlayerObj(dataItem);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== READ_ONLY,
    },
  ];

  return (
    <div>
      <AdvPlayerStatTableView
        isLoading={isLoading}
        // Sync Modal
        openSyncModal={openSyncModal}
        syncModalClose={syncModalClose}
        syncDetails={syncDetails}
        // Delete modalProps
        showDelete={showDelete}
        showMultipleDelete={showMultipleDelete}
        CloseModal={CloseModal}
        playerObj={playerObj}
        // Api Call
        deleteAdvPlayerStatReq={deleteAdvPlayerStatReq}
        deleteMultiReq={deleteMultiReq}
        // restTableProps
        name="Advance Player Stats"
        data={advStatData}
        totalCount={advStatCount}
        headings={headings}
        extraInfo={extraInfo}
        actions={actions}
        checkbox={access_type !== READ_ONLY}
        selectedList={selected}
        numSelected={selected.length}
        handleSelectAllClick={handleSelectAllClick}
        handleSingleClick={handleSingleClick}
        multipleDeleteHandler={deleteMultipleHandler}
      />
    </div>
  );
}

export default AdvPlayersData;
