/* istanbul ignore file */
import { Container as Application } from "app/pages/Dashboard/Application/index";
import { applicationAction } from "store/reducers/applicationSlice";
import { authServices } from "services/auth";
import { clearApplicationState } from "store/reducers/applicationSlice";
import { closeSidebar } from "store/reducers/sidebarSlice";
import { Constants } from "utils/constants";
import { deleteKey } from "utils/cookies";
import { get } from "services/getObject";
import { Helmet } from "react-helmet-async";
import { NotFoundPage } from "app/components/NotFoundPage/Loadable";
import { Route, Switch } from "react-router-dom";
import { saveSports } from "store/reducers/constants";
import { setProfile, unsetProfile } from "store/reducers/adminSlice";
import { useDispatch } from "react-redux";
import Accounts from "app/pages/Dashboard/account";
import EnvDashboard from "app/pages/Dashboard/envDashboard";
import Layout from "app/layouts/Dashboard";
import React, { useEffect } from "react";
import TabIndex from "./TabIndex";

const Dashboard = props => {
  const dispatch = useDispatch();

  // eslint-disable-next-line no-useless-escape
  const layoutRegEx = /^\/dashboard\/(account|application)[\/a-z0-9!@#$&()\\-`_.+,"\/]*/gm;

  //API to fetch Admin Account Profile details
  const getProfile = async () => {
    const response = await dispatch(
      authServices({
        url: "/v1/admin/auth/user",
        method: "GET",
        loader: false,
      }),
    );
    const { first_name, last_name, email } = response.data.user;
    dispatch(setProfile({ first_name, last_name, email }));
  };

  const getConstants = async () => {
    const {
      data: {
        constants: { sports, order, drops_admin_message_type },
      },
    } = await dispatch(
      get({ url: `/v1/admin/constant_fields`, loader: false }),
    );
    dispatch(saveSports({ sports, order, drops_admin_message_type }));
  };

  const logout = async () => {
    try {
      const { ACCESS_TOKEN, REFRESH_TOKEN } = Constants;
      await dispatch(
        authServices({ url: "/v1/admin/auth/user/logout", showToast: true }),
      );
      dispatch(clearApplicationState());
      dispatch(unsetProfile());
      dispatch(closeSidebar());
      deleteKey(ACCESS_TOKEN);
      deleteKey(REFRESH_TOKEN);
      props.history.push("/auth/login");
    } catch (error) {
      /*istanbul ignore next*/
      console.error("Error in SignOut", error);
    }
  };

  useEffect(() => {
    getProfile();
    getConstants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Application Details
  const getApplication = async sport_key => {
    // setKey(Constants.SPORT_KEY, sport_key);
    const { data } = await dispatch(
      get({ url: `/v1/admin/applications/${sport_key}`, loader: false }),
    );
    const { application } = data;
    dispatch(
      applicationAction({
        // access_type: application.access_type,
        name: application.name,
        sport_key: application.sport_key,
        isOwner: application.isOwner,
        team_name: application.plugins?.team_name,
        tid: application.plugins?.team_id,
        league_name: application.plugins?.league_name,
        sport_type: application.sport_type,
        master_key: application.master_key,
      }),
    );
  };

  // Get Env Details
  const getEnv = async (sport_key, env_key) => {
    const { data } = await dispatch(
      get({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}`,
        loader: false,
      }),
    );
    const { env } = data;
    dispatch(
      applicationAction({
        access_type: env.access_type,
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>DFE Admin Portal</title>
        <meta name="description" content="DFE Admin Portal homepage" />
      </Helmet>
      {layoutRegEx.test(props.location.pathname) ? (
        <Layout {...props} logout={logout}>
          <Switch>
            <Route
              path={`${props.match.path}/application/:sport_key`}
              render={props => (
                <TabIndex {...props} getApplication={getApplication} />
              )}
            />
            <Route
              path={`${props.match.path}/application`}
              component={Application}
              exact
            />
            <Route path={`${props.match.path}/account`} component={Accounts} />
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      ) : (
        <Switch>
          <Route
            path={`${props.match.path}/:sport_key/:env_key`}
            render={props => (
              <EnvDashboard
                {...props}
                logout={logout}
                getApplication={getApplication}
                getEnv={getEnv}
              />
            )}
          />
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </>
  );
};

export { Dashboard };
