import { Container as AutoComplete } from "app/pages/Dashboard/pushNotification/Messages/form/Auto";
import { Controller } from "react-hook-form";
import { PUSH_AUDIENCE, PUSH_STATUS } from "utils/constants";
import Date from "app/components/Common/Form/DatePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import has from "lodash/has";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";

const GroupRadio = ({
  name,
  validations,
  options,
  uid,
  showBorder,
  readOnly,
  defaultValue,
  useForm,
  uids,
  push_provider,
}) => {
  return (
    <Controller
      name={name}
      control={useForm.control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field: { value }, fieldState, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, name);
        const fieldError = get(errors, name);
        return (
          <>
            <RadioGroup
              value={value}
              onChange={e => {
                useForm.setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
            >
              {options.map(
                ({ label, value: radioValue, auto, date }, index) => (
                  <div key={`${uid}-${index}`} className="radio-fields">
                    <FormControlLabel
                      data-testid={uid}
                      value={radioValue}
                      control={
                        <Radio
                          inputProps={{
                            readOnly: !!readOnly,
                          }}
                        />
                      }
                      label={label}
                    />

                    {auto ? (
                      radioValue === value ? (
                        value === PUSH_AUDIENCE.default ? (
                          <div>
                            <AutoComplete
                              type={radioValue}
                              useForm={useForm}
                              uids={uids}
                              push_provider={push_provider}
                            />
                          </div>
                        ) : (
                          <div className="radio-content">
                            <AutoComplete
                              type={radioValue}
                              useForm={useForm}
                              uids={uids}
                              push_provider={push_provider}
                            />
                          </div>
                        )
                      ) : null
                    ) : null}

                    {date ? (
                      value === PUSH_STATUS.scheduled ? (
                        <div className="radio-content">
                          <Date
                            fieldName="schedule_date"
                            ampm={true}
                            control={useForm.control}
                            validations={{
                              required: "Date is required",
                            }}
                            title="Schedule Date"
                            format="yyyy-MM-dd p"
                            className={"schedule_time"}
                            uid="schedule"
                          />
                        </div>
                      ) : null
                    ) : null}
                  </div>
                ),
              )}
            </RadioGroup>
            {hasFieldError && (
              <FormHelperText>{fieldError.message}</FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default GroupRadio;
