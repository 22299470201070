const schema = {
  class: {
    title: "Events",
    uid: "events",
    _schema: [
      {
        display_name: "Event ID",
        uid: "event_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Name",
        uid: "name",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Images",
        uid: "images",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Ratio",
            uid: "ratio",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Url",
            uid: "url",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Width",
            uid: "width",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Height",
            uid: "height",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fallback",
            uid: "fallback",
            mandatory: false,
            multiple: false,
            data_type: "boolean",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Sales",
        uid: "sales",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Public Sale",
            uid: "publicsale",
            mandatory: false,
            multiple: false,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Start Date Time",
                uid: "startDateTime",
                mandatory: false,
                multiple: false,
                data_type: "date",
                field_metadata: {},
              },
              {
                display_name: "Start TBD",
                uid: "startTBD",
                mandatory: false,
                multiple: false,
                data_type: "boolean",
                field_metadata: {},
              },
              {
                display_name: "End Date Time",
                uid: "endDateTime",
                mandatory: false,
                multiple: false,
                data_type: "date",
                field_metadata: {},
              },
            ],
          },
          {
            display_name: "Presales",
            uid: "presales",
            mandatory: false,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Start Date Time",
                uid: "startDateTime",
                mandatory: true,
                multiple: false,
                data_type: "date",
                field_metadata: {},
              },
              {
                display_name: "End Date Time",
                uid: "endDateTime",
                mandatory: false,
                multiple: false,
                data_type: "date",
                field_metadata: {},
              },
              {
                display_name: "Name",
                uid: "name",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
            ],
          },
        ],
      },
      {
        display_name: "Event Date",
        uid: "event_date",
        mandatory: true,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Ticket Url",
        uid: "ticket_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Parking Url(Optional)",
        uid: "parking_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Price Ranges",
        uid: "price_ranges",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Type",
            uid: "type",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Currency",
            uid: "currency",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Minimum",
            uid: "min",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Maximum",
            uid: "max",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Seatmap Url",
        uid: "seatmap_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Content",
        uid: "content",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Additional Content",
        uid: "additional_content",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Sponser Ads",
        uid: "sponser_ads",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Promoters",
        uid: "promoters",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Id",
            uid: "id",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Name",
            uid: "name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Description",
            uid: "description",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Ticket Limit",
        uid: "ticket_limit",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Type",
        uid: "type",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Doors open time",
        uid: "doors_open_time",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
