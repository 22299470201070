import { Button } from "@dfep/ui-component";
import { FormWrapper } from "styles/global.style";
import { useForm } from "react-hook-form";
import {
  validateKey,
  validateLatitude,
  validateLongitude,
} from "utils/functions";
import Form from "app/components/Common/Form";
import PanelFooter from "app/components/Common/PanelFooter";
import React, { useState } from "react";

const BroadcastTopicForm = props => {
  const { apiCallFunction, cancelForm } = props;

  const BroadcastUseForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all", // It will Focus on every field  where error is there not on first error field which is by default
  });
  const {
    formState: { dirtyFields, errors },
  } = BroadcastUseForm;

  const [topicSchema] = useState({
    title: "",
    name: "Topic",
    uid: "topic",
    field_metadata: {
      options: [],
    },
    defaultValue: "",
    validations: {},
    type: "group",
    _schema: [
      {
        title: "Name",
        uid: "topic-name",
        name: "name",
        type: "text",
        defaultValue: props?.broadcastData?.name ?? "",
        validations: {
          required: "Name is required.",
          maxLength: {
            value: 60,
            message: "Exceeds max length. [60 MAX CHARACTER]",
          },
          pattern: {
            value: /^[a-zA-Z0-9]+((\s+)?[a-zA-Z0-9]+)+?$/,
            message: "Invalid name.",
          },
        },
        field_metadata: {
          isBorder: false,
          options: [],
        },
        _schema: [],
      },
      {
        title: "Key",
        uid: "key",

        name: "key",
        type: "text",
        defaultValue: props?.broadcastData?.key ?? "",
        validations: {
          required: "Key is required.",
          validate: {
            pattern: val => validateKey(val) || "Invalid key.",
          },
        },
        field_metadata: {
          isBorder: false,
          readOnly: !!props?.broadcastData?.key,
          options: [],
        },
        _schema: [],
      },
      {
        title: "Select-Service",
        uid: "services",
        name: "service",
        type: "select",
        defaultValue: props?.broadcastData?.service ?? "push",
        validations: {
          required: "Service is required.",
        },
        field_metadata: {
          isBorder: false,
          options: [
            { label: "Push", value: "push" },
            { label: "Real Time", value: "realtime" },
          ],
        },
        _schema: [],
      },
      {
        title: "Description",
        uid: "description",
        name: "description",
        type: "text",
        defaultValue: props?.broadcastData?.description ?? "",
        validations: {},
        field_metadata: {
          isBorder: false,

          options: [],
        },
        _schema: [],
      },
      {
        title: "",
        uid: "checkbox-group",
        name: "checkedValue",
        type: "checkboxGroup",
        defaultValue: {
          isLocation: props?.broadcastData?.isLocEnabled ?? false,
        },
        validations: {},
        field_metadata: {
          isBorder: false,
          options: [
            {
              label: "Location",
              name: "isLocation",
              value: "location",
              mapSchema: ["location"],
              uid: "location-checkbox",
            },
          ],
        },
        _schema: [
          {
            title: "Location",
            schemaName: "location",
            uid: "location-accordian",
            dataTestid: "location-accordian",

            name: "location",
            type: "groupAccordian",
            validations: {},
            defaultValue: "",
            field_metadata: {
              isBorder: true,
              options: [],
            },
            _schema: [
              {
                title: "Latitude",
                uid: "latitude",
                name: "location.lat",
                type: "number",
                defaultValue: props?.broadcastData?.location?.lat ?? "",
                validations: {
                  required: "Latitude is required.",
                  validate: {
                    pattern: val =>
                      validateLatitude(val) || "Invalid latitude.",
                  },
                },
                field_metadata: {
                  options: [],
                },
                _schema: [],
              },
              {
                title: "Longitude",
                uid: "longitude",
                name: "location.long",
                type: "number",
                defaultValue: props?.broadcastData?.location?.long ?? "",
                validations: {
                  required: "Longitude is required.",
                  validate: {
                    pattern: val =>
                      validateLongitude(val) || "Invalid longitude.",
                  },
                },
                field_metadata: {
                  options: [],
                },
                _schema: [],
              },
              {
                title: "Radius",
                uid: "radius",
                name: "location.radius",
                type: "number",
                defaultValue: props?.broadcastData?.location?.radius ?? "",
                validations: {
                  required: "Radius is required.",
                },
                field_metadata: {
                  options: [],
                },
                _schema: [],
              },
            ],
          },
        ],
      },
      {
        title: "Custom Fields",
        uid: "customFields",
        name: "customField",
        type: "jsonEditor",
        defaultValue:
          JSON.stringify(props?.broadcastData?.custom_fields, null, "\t") ??
          "{}",
        validations: {},
        field_metadata: {
          isBorder: true,
        },
        _schema: [],
      },
    ],
  });

  const submitFormHandler = async data => {
    const form = {
      ...props?.broadcastData,
      name: data.name,
      key: data.key,
      inbuilt: props?.broadcastData ? props?.broadcastData?.inbuilt : false,
      service: data.service,
      description: data.description,
      isLocEnabled: data.checkedValue.isLocation,
      location: data.location,
      custom_fields: JSON.parse(`${data.customField}`),
    };

    await apiCallFunction(form);
  };

  return (
    <>
      <FormWrapper elevation={4} variant="outlined">
        <Form
          formId="broadcastForm"
          customSchema={topicSchema}
          onSubmit={submitFormHandler}
          watchList={["checkedValue", "isLocation"]}
          additionalFormAttributes={{
            className: "maxWidthSm",
            "data-testid": "broadcastForm",
          }}
          form={BroadcastUseForm}
        />

        <PanelFooter>
          <div>
            <Button
              data-testid="cancel-btn"
              onClick={cancelForm}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={
                !Object.keys(dirtyFields).length || !!Object.keys(errors).length
              }
              data-testid="broadcastSubmit"
              form="broadcastForm"
              variant="contained"
              color="primary"
              type="submit"
            >
              {props?.broadcastData ? "Save" : "Add"}
            </Button>
          </div>
        </PanelFooter>
      </FormWrapper>
    </>
  );
};

export default BroadcastTopicForm;
