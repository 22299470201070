import { clearApplicationState } from "store/reducers/applicationSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { DashboardContainer, Divider } from "./Card.style";
import { getApplication } from "services/getObjects";
import { Grid } from "@material-ui/core";
import { useQueryParams } from "app/hooks/index";
import AppCard from "./Card";
import CardLoader from "./ApplicationLoader";
import Pagination from "app/components/Common/Pagination";
import PanelHeader from "../../../components/Common/PanelHeader";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

const Application = props => {
  const [applications, setApplications] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [count, setCount] = useState();
  const [loading, isLoading] = useState(false);
  const { sports } = useSelector(state => state.constants);
  const dispatch = useDispatch();

  const getConstants = useCallback(() => {
    const sport = sports?.find(field => field.value === "nba");
    setLeagues(sport ? sport["leagues"] : []);
  }, [sports]);

  const limit = 12;
  const { page, skip } = useQueryParams({ limit });

  useEffect(() => {
    setApplications([]);
    isLoading(true);
    props.getApplications({ skip, limit }).then(response => {
      setApplications(response.data.applications);
      setCount(response.data.count);
      isLoading(false);
    });
    // Clear the selected Application as user we reselect the application from here
    dispatch(clearApplicationState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, skip]);

  useEffect(() => {
    getConstants();
  }, [getConstants]);

  if (loading && !applications.length) {
    return <CardLoader count={1} />;
  }
  return (
    <DashboardContainer>
      <PanelHeader title="Applications" className="panel-header" />
      <Divider />
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={5}
        data-testid="resolved"
      >
        {React.Children.toArray(
          applications.map(item => (
            <AppCard path={props.match.path} data={item} leagues={leagues} />
          )),
        )}
      </Grid>
      {count > limit && <Pagination count={count} limit={limit} />}
    </DashboardContainer>
  );
};

Application.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
    path: PropTypes.string,
  }),
};

/*istanbul ignore next*/
const mapDispatchToProps = dispatch => {
  return {
    getApplications: ({ ...params }) => dispatch(getApplication({ ...params })),
  };
};

const Container = connect(null, mapDispatchToProps)(Application);

export { Container, Application as Component };
