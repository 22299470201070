import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddNewsFeeds = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/news`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/feeds",
          key: "feed",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add News Feed"
        breadCrumbItems={[
          {
            title: "Add News Feed",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="feeds"
        backUrl={getURL}
      />
    </>
  );
};

export default AddNewsFeeds;
