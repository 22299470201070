import { Container as GetSeason } from "app/pages/Dashboard/Data/Team/Season_average/get";
import { Route, Switch } from "react-router-dom";
import AddSeason from "./add";
import React from "react";
import UpdateSeason from "./update";

const SeasonAverage = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddSeason} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateSeason}
        exact
      />
      <Route path={`${props.match.path}`} component={GetSeason} />
    </Switch>
  );
};

export default SeasonAverage;
