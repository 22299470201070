import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddMessage = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getUrl = `/dashboard/${sport_key}/${env_key}/data/device/messages`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/messages",
          key: "message",
          form: data,
        }),
      );

      props.history.push(getUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Messages"
        breadCrumbItems={[
          {
            title: "Add Messages",
          },
        ]}
        backUrl={getUrl}
      />
      <SchemaForm onSubmit={onSubmit} schema={schema} backUrl={getUrl} />
    </>
  );
};

export default AddMessage;
