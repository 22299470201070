import { Button, Modal } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { Controller, useForm } from "react-hook-form";
import { createRecord } from "services/createObject";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { getCustomService } from "services/getObjects";
import { NoData } from "styles/global.style";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import { validateUrl } from "utils/functions";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";
import TextField from "app/components/Common/TextField";

export default function CustomServer(props) {
  const [serviceData, setServieData] = useState([]); // All service is set as per limit
  const [serviceCount, setServiceCount] = useState(0); // all service count in db
  const [isLoading, setIsLoading] = useState(false);
  const [serviceId, setServiceId] = useState(""); // service Id is set
  const [formType, setFormType] = useState(""); // formType --> Update or Add According to this modal will open
  const [service, setService] = useState({}); // Single Service is Set for Update
  const [deleteData, setDeleteData] = useState();
  const { access_type } = useSelector(state => state.application);

  const useServiceForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
  });

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
  } = useServiceForm;

  const [isModalOpen, setIsModalOpen] = useState({
    form: false,
    delete: false,
  });
  const { skip, limit, sort, value: sortValue } = useQueryParams();

  const dispatch = useDispatch();

  const fetchServiceData = useCallback(async () => {
    setIsLoading(true);

    const {
      data: { apps, count },
    } = await dispatch(
      getCustomService({ skip, limit, sort, value: sortValue }),
    );

    if (count !== 0 && apps.length === 0) {
      initialRoute(props.history);
    } else {
      setServieData(apps);
      setServiceCount(count);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit, sort, sortValue]);

  const deleteService = async () => {
    await dispatch(
      deleteRecord({ url: `/v1/custom_service/admin/data/apps/${serviceId}` }),
    );

    fetchServiceData();
    setIsModalOpen({ ...isModalOpen, delete: false });
    setServiceId("");
  };

  const modalClose = () => {
    /* istanbul ignore else*/
    if (formType === "Update") {
      setService({});
      setServiceId("");
      setFormType("");
    }

    setIsModalOpen({
      form: false,
      delete: false,
    });
  };

  const submitHandler = async data => {
    /* istanbul ignore else*/
    if (formType === "Update") {
      await dispatch(
        updateRecord({
          url: `/v1/custom_service/admin/data/apps/${serviceId}`,
          key: "app",
          form: data,
        }),
      );

      fetchServiceData();
      setService({});
      setServiceId("");
    }
    if (formType === "Add") {
      await dispatch(
        createRecord({
          url: `/v1/custom_service/admin/data/apps`,
          key: "app",
          form: {
            ...data,
            new: true,
          },
        }),
      );

      fetchServiceData();
    }

    setIsModalOpen({ ...isModalOpen, form: false });
  };

  useEffect(() => {
    fetchServiceData();
    return () => {
      setServieData([]);
      setServiceCount(0);
      setServiceId("");
      setFormType("");
      setService({});
    };
  }, [fetchServiceData]);

  const headings = [
    { label: "Name", id: "name" },
    { label: "Key", id: "key" },
    { label: "UID", id: "uid" },
    { label: "URL", id: "url" },
  ];
  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon data-testid="edit-btn" fontSize="small" />,
      handler: (event, uid, dataItem) => {
        setFormType("Update");
        setService(dataItem);
        setServiceId(uid);
        setIsModalOpen({ ...isModalOpen, form: true });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon data-testid="delete-btn" fontSize="small" />,
      handler: (event, uid, data) => {
        setDeleteData(data.name);
        setServiceId(uid);
        setIsModalOpen({ ...isModalOpen, delete: true });
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      {/* Panel Header */}
      <PanelHeader
        title="Custom Service"
        // Refresh Button
        refreshHandler={fetchServiceData}
        breadCrumbItems={[{ title: "Custom Service" }]}
      >
        {/* Add Button */}
        {access_type !== Constants.READ_ONLY && (
          <Button
            onClick={() => {
              setIsModalOpen({ ...isModalOpen, form: true });
              setFormType("Add");
            }}
            data-testid="add-btn"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>

      {/* Table Loader */}
      {isLoading ? (
        <SkeletonTable headings={headings} name="Services" actions={actions} />
      ) : null}

      {/* Table  */}

      {serviceData.length > 0 && !isLoading ? (
        <Table
          data-testid="resolved"
          name="Services"
          headings={headings}
          data={serviceData}
          actions={actions}
          isSorting
          totalCount={serviceCount}
        />
      ) : (
        // If No data Found
        !isLoading && <NoData> No record found </NoData>
      )}

      {/* Delete Modal */}
      <Modal
        open={isModalOpen.delete}
        handleClose={modalClose}
        title="Delete Services"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: modalClose,
            additionalAttributes: { "data-testid": "modal-cancel-btn" },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteService,
            additionalAttributes: {
              "data-testid": "modal-delete-btn",
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        {serviceId.length > 0 && (
          <>
            {deleteConfirmationMessage({
              name: deleteData,
              module: "Services",
            })}
          </>
        )}
      </Modal>

      {/* Form Modal  for Add and Update as  per the formType state */}
      <Modal
        open={isModalOpen.form}
        handleClose={modalClose}
        title={`${formType} Service`}
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: () => {
              /* istanbul ignore else*/

              if (formType === "Update") {
                setService({});
                setFormType("");
              }
              modalClose("form");
            },
            additionalAttributes: {
              "data-testid": "cancel-btn",
            },
          },
          {
            id: "2",

            name: formType,
            handler: null,
            additionalAttributes: {
              type: "submit",
              form: "serviceForm",
              disabled:
                !Object.keys(dirtyFields).length ||
                !!Object.keys(errors).length,
              "data-testid": "submit-btn",
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        {/* Form with react hook form */}
        <form
          noValidate
          id="serviceForm"
          data-testid="serviceForm"
          onSubmit={handleSubmit(submitHandler)}
        >
          <>
            <Controller
              control={control}
              name="name"
              defaultValue={service?.name ?? ""}
              rules={{
                required: "Name is required",
              }}
              render={({ field, formState }) => {
                const { errors } = formState;

                return (
                  <TextField
                    fullWidth
                    name={field.name}
                    error={errors["name"] && true}
                    helperText={errors["name"] && errors["name"].message}
                    id="name-field"
                    label="Name"
                    onChange={field.onChange}
                    onBlur={e => {
                      e.target.value = e.target.value.trim();
                      field.onChange(e.target.value);
                      field.onBlur(e.target.value);
                    }}
                    value={field.value}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              defaultValue={service?.key ?? ""}
              name="key"
              rules={{
                required: "Key is required",
                pattern: { value: /^[a-z]+$/, message: "Invalid Key" },
                maxLength: {
                  value: 10,
                  message: "Should have a maximum length of 10.",
                },
              }}
              render={({ field, formState }) => {
                const { errors } = formState;

                return (
                  <TextField
                    fullWidth
                    error={errors["key"] && true}
                    helperText={errors["key"] && errors["key"].message}
                    id="key-field"
                    label="Key"
                    type="text"
                    onChange={field.onChange}
                    onBlur={e => {
                      e.target.value = e.target.value.trim();
                      field.onChange(e.target.value);
                      field.onBlur(e.target.value);
                    }}
                    value={field.value}
                    inputRef={field.ref}
                    variant="outlined"
                    InputLabelProps={{
                      required: true,
                    }}
                    InputProps={{
                      readOnly: service?.key ? true : false,
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              defaultValue={service?.url ?? ""}
              name="url"
              rules={{
                required: "URL is required",

                validate: url => (!validateUrl(url) ? "Invalid URL" : true),
              }}
              render={({ field, formState }) => {
                const { errors } = formState;
                return (
                  <TextField
                    fullWidth
                    error={errors["url"] && true}
                    helperText={errors["url"] && errors["url"].message}
                    id="url-field"
                    label="URL"
                    type="text"
                    onChange={field.onChange}
                    onBlur={e => {
                      e.target.value = e.target.value.trim();
                      field.onChange(e.target.value);
                      field.onBlur(e.target.value);
                    }}
                    value={field.value}
                    inputRef={field.ref}
                    variant="outlined"
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                );
              }}
            />
          </>
        </form>
      </Modal>
    </>
  );
}
