import { Route, Switch } from "react-router-dom";
import AddCoaches from "./add";
import GetCoaches from "app/pages/Dashboard/Data/Roster/Coaches/get";
import React from "react";
import UpdateCoaches from "./update";

const Coaches = props => {
  return (
    <>
      <Switch>
        <Route path={`${props.match.path}/add`} component={AddCoaches} exact />
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdateCoaches}
          exact
        />
        <Route path={props.match.path} component={GetCoaches} />
      </Switch>
    </>
  );
};

export default Coaches;
