import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import has from "lodash/has";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "app/components/Common/TextField";

const Select = ({
  name,
  validations,
  display_name,
  data_type,
  field_metadata,
  defaultValue,
  parentName,
}) => {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, `${field.name}`);
        const fieldError = get(errors, `${field.name}`);
        return (
          <TextField
            label={display_name}
            select
            type={data_type} // number
            variant="outlined"
            value={field.value}
            onChange={e => {
              setValue(`${parentName}.field_metadata.additional_options`, [], {
                shouldDirty: true,
              });
              setValue(`${parentName}.mandatory`, false);
              setValue(`${parentName}.multiple`, false);
              if (field.value === "string")
                setValue(`${parentName}.field_metadata.multiline`, false);
              field.onChange(e);
            }}
            onBlur={field.onBlur}
            inputRef={field.ref}
            error={hasFieldError}
            inputProps={{
              "data-testid": `${name}`,
            }}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            helperText={hasFieldError && fieldError?.message}
            InputLabelProps={{
              required: !!validations.required,
            }}
          >
            {field_metadata?.options?.map(({ value, label }) => (
              <MenuItem disabled={!!!value} key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
};

export default Select;
