import { generateQueryParams } from "utils/functions";
import { SearchComponent } from "./Search.style";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const WAIT_INTERVAL = 700;
let typingTimeOut = null;

const Search = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const { search: searchTextParam } = queryString.parse(window.location.search);

  const onSearchInputChange = event => {
    const text = event.target.value;
    if (text.length && !text.trim().length) return;
    setSearchInput(text);
    clearTimeout(typingTimeOut);

    typingTimeOut = setTimeout(() => {
      const state = { ...queryString.parse(window.location.search), page: 1 };
      const url = `${window.location.pathname}${generateQueryParams({
        obj: state,
        key: "search",
        value: encodeURIComponent(text),
      })}`;
      // Redirect to Page 1 with previous sort values and new Search is pushed to URL
      history.push(url, {
        ...state,
        search: encodeURIComponent(text),
      });
    }, WAIT_INTERVAL);
  };

  useEffect(() => {
    setSearchInput(searchTextParam ? searchTextParam : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextParam]);

  return (
    <SearchComponent
      className="search"
      id="search"
      label="Search"
      onChange={onSearchInputChange}
      value={searchInput}
    />
  );
};

export default Search;
