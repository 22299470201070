import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { useModal } from "app/hooks/modal";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetStandingView from "./GetStandingView";
import React, { useEffect, useState } from "react";

const headings = [
  { label: "Team ID", id: "tid" },
  { label: "League ID", id: "league_id" },
  { label: "Season ID", id: "season_id" },
  { label: "Year", id: "year" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const GetTeams = props => {
  const [deleteData, setDeleteData] = useState({});
  const { team_name } = props.match.params;
  const [dataId, setDataId] = useState("");
  const [selected, setSelected] = useState([]);
  const {
    access_type,
    totalDataCount,
    standingData,
    isLoading,
    getStandingData,
  } = props;
  const { sport_key, env_key } = props.match.params;
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  useEffect(() => {
    setSelected([]);
    getStandingData(team_name);
  }, [getStandingData, team_name]);

  const deleteHandler = async event => {
    await props.deleteData({
      url: `/v1/nba/admin/data/team_standing/${dataId}`,
    });
    setSelected([]);
    getStandingData(team_name);
    handleModalClose();
    setDeleteData({});
    setDataId("");
  };
  const deleteModalHandler = (event, uid, data) => {
    setDeleteData(data);
    setDataId(uid);
    openModalHandler("showDelete");
  };
  const handleModalClose = event => {
    setDataId("");
    setDeleteData({});
    closeModalHandler();
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };
  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: standingData });
    setSelected(newSelecteds);
  };

  const deleteMultipleHandler = () => {
    openModalHandler("showMultipleDelete");
  };

  const handleMultiDelete = async () => {
    await props.deleteMultiple({
      url: "/v1/nba/admin/data/team_standing",
      uids: selected,
    });
    setSelected([]);
    getStandingData(team_name);
    handleModalClose();
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-btn" />,
      handler: (event, uid) =>
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/team/standing/update/${uid}`,
          state: { from: props.location },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <GetStandingView
      name="Team Standing"
      data={standingData}
      headings={headings}
      checkbox={true}
      actions={actions}
      multipleDeleteHandler={deleteMultipleHandler}
      handleSelectAllClick={handleSelectAllClick}
      handleSingleClick={handleSingleClick}
      selectedList={selected}
      numSelected={selected.length}
      isSorting
      totalCount={totalDataCount}
      multipleModal={showMultipleDelete}
      deleteModal={showDelete}
      handleMultiDelete={handleMultiDelete}
      handleModalClose={handleModalClose}
      actionMenu={false}
      deleteHandler={deleteHandler}
      isLoading={isLoading}
      openSync={props.openSync}
      closeSync={props.closeSync}
      sendSync={props.sendSync}
      deleteData={deleteData}
    />
  );
};

/* istanbul ignore next */
const mapDisptachToProps = dispatch => {
  return {
    deleteData: payload => dispatch(deleteRecord(payload)),
    deleteMultiple: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDisptachToProps)(GetTeams);
export { Container, GetTeams as Component };
