import { Controller } from "react-hook-form";
import get from "lodash/get";
import has from "lodash/has";
import React from "react";
import TextField from "app/components/Common/TextField";

const Form = ({
  formInstance,
  id,
  className,
  handleFormSubmit,
  autoFocus,
  name,
  requiredMsg,
  pattern,
  defaultValue,
  placeholder,
  label,
  type,
  variant,
  TextFieldId,
}) => {
  const { handleSubmit, control } = formInstance;

  return (
    <form
      id={id}
      className={className}
      noValidate
      data-testid="rename-app-form"
      onKeyDown={e => {
        if (e.code === "Enter") e.preventDefault();
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          required: requiredMsg,
          validate: pattern,
        }}
        defaultValue={defaultValue}
        render={({ field, formState }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, name);
          const fieldError = get(errors, name);
          return (
            <TextField
              id={TextFieldId}
              placeholder={placeholder}
              autoFocus={autoFocus}
              label={label}
              type={type}
              variant={variant}
              error={hasFieldError}
              value={field.value}
              helperText={hasFieldError && fieldError.message}
              onChange={field.onChange}
              inputRef={field.ref}
              InputLabelProps={{
                required: true,
              }}
              fullWidth
            />
          );
        }}
      />
    </form>
  );
};
export default Form;
