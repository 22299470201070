import { FileUploadWrapper, Typography } from "./FileUpload.style";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";
import React from "react";

const FileUpload = props => {
  return (
    <FileUploadWrapper>
      <div className="content">
        <div
          data-testid="add-asset-container"
          className="drop-container"
          onDragOver={props.dragOver}
          onDragEnter={props.dragEnter}
          onDragLeave={props.dragLeave}
          onDrop={props.fileDrop}
          onClick={props.fileInputClicked}
        >
          <div className="drop-message">
            <input
              ref={props.fileInputRef}
              className="file-input"
              type="file"
              accept={props.acceptFileType}
              multiple={props.selectMultiple}
              onChange={props.filesSelected}
              data-testid="add-image-input"
            />
            <Typography className="message">
              Drag and drop a file here or click
            </Typography>
            <CloudUploadIcon className="upload-icon" />
          </div>
        </div>
        <Typography className="small">
          Drag and drop a file here or click
        </Typography>
        <div className="file-display-container">
          {props.validFiles.length > 0 ? (
            <>
              {props.validFiles.map((data, i) => (
                <div className="file-status-bar" key={i}>
                  <div className="file">
                    <ImageIcon className="file-type-logo" />
                    <span className="file-name">{data.name}</span>
                    <span className="file-size">
                      {props.fileSize(data.size)}
                    </span>{" "}
                  </div>
                  <div
                    className="file-remove"
                    onClick={() => props.removeFile(data.name)}
                  >
                    <DeleteIcon data-testid="delete-image" />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <Typography className="empty-text">No File Selected</Typography>
          )}
        </div>
      </div>
    </FileUploadWrapper>
  );
};

FileUpload.defaultProps = {
  acceptFileType: "image/*",
  selectMultiple: true,
};
export default FileUpload;
