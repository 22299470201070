/* eslint-disable react-hooks/exhaustive-deps */
import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { useQueryParams } from "app/hooks";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import BroadcastTableView from "./BroadcastTableView";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useState } from "react";

function BroadcastTopics({ history, match, location }) {
  const { sport_key, env_key } = match.params;

  const [refresh, setRefresh] = useState(false);
  const { access_type } = useSelector(state => state.application);
  const { searchText } = useQueryParams();

  const tab = { push: 0, realtime: 1 }[
    location.pathname.split("/").slice(-1)[0]
  ]; // remove "push" from /dashboard/sports_key/env_keys/broadcast-topics/get/push

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  if (tab === undefined) {
    return <Redirect from={`${match.url}`} to={`${match.url}/push`} />;
  }

  return (
    <>
      <PanelHeader
        title="Broadcast Topics"
        refreshHandler={() => {
          setRefresh(true);
        }}
        breadCrumbItems={[{ title: "Broadcast Topics" }]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            onClick={() => {
              history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/broadcast-topics/add`,
                state: { from: location },
              });
            }}
            data-testid="add-btn"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
        >
          <Tab
            label="Push Topics"
            component={Link}
            to={`${match.url}/push`}
            {...a11yProps(0)}
          />
          <Tab
            label="Real Time Topics"
            component={Link}
            to={`${match.url}/realtime`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <Switch>
        <>
          <TabPanel>
            <Route
              path={`${match.path}/:topic`}
              render={routeProps => (
                <BroadcastTableView
                  {...routeProps}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  access_type={access_type}
                />
              )}
            />
          </TabPanel>
        </>
      </Switch>
    </>
  );
}

export default BroadcastTopics;
