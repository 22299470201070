import { Grid } from "app/pages/Dashboard/Data/Device/SettingsCard/Card.style";
import { NoData } from "styles/global.style";
import CardLoader from "app/pages/Dashboard/Data/Device/SettingsCard/CardLoader";
import DeviceCard from "app/pages/Dashboard/Data/Device/SettingsCard/Card";
import Pagination from "app/components/Common/Pagination";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const AppVersion = ({
  data,
  isLoading,
  count,
  limit,
  getMenuData,
  name,
  isOwner,
  access_type,
  location,
  ...props
}) => {
  const deleteHandler = async uid => {
    await props.deleteMenu({
      url: `/v1/nba/admin/app/menu/${uid}`,
    });
    getMenuData();
  };

  useEffect(() => {
    getMenuData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMenuData]);

  if (isLoading) {
    return <CardLoader count={1} />;
  }
  if (count < 1) {
    return <NoData>No Data Found</NoData>;
  }
  return (
    <>
      <Grid container direction="row">
        {data.map((item, index) => (
          <DeviceCard
            updateUrl={`/dashboard/${props.match.params.sport_key}/${props.match.params.env_key}/data/device/menu/update/${item.uid}`}
            location={location}
            item={item}
            deleteHandler={deleteHandler}
            key={index}
            name={name}
            isOwner={isOwner}
            access_type={access_type}
          />
        ))}
      </Grid>
      {count > limit && <Pagination count={count} limit={limit} />}
    </>
  );
};

AppVersion.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  count: PropTypes.number,
  limit: PropTypes.number,
  deleteHandler: PropTypes.func,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default AppVersion;
