import { handleError } from "../helper";
import { httpRequest } from "..";
import { showLoader } from "store/reducers/loaderSlice";

export const get = ({ url, loader = true }) => (dispatch, getState) => {
  const { sport_key, env_key } = getState().application;
  const httpObject = {
    url,
    headers: {
      sport_key: sport_key,
      env_key: env_key,
    },
    data: {
      _method: "Get",
    },
  };
  return new Promise(function (resolve, reject) {
    if (loader) {
      dispatch(showLoader({ show: true }));
    }
    httpRequest(httpObject)
      .then(response => {
        dispatch(showLoader({ show: false }));
        resolve(response);
      })
      .catch(error => handleError(error, dispatch));
  });
};
