// import { Container as addSchema } from "app/pages/Dashboard/customSchema/Add";
import { getIntegrations, getIntegrationSchema } from "services/getObjects";
import { isNullOrEmpty, saveSportsEnvKey } from "utils/functions";
import { NotFoundPage } from "app/components/NotFoundPage/Loadable";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BroadcastTopic from "app/route/broadcastTopics";
// import CustomField from "app/pages/Dashboard/CustomField";
import { FEATURES_ARRAY } from "utils/constants";
import {
  handleStateForContentVisibilityInSidebar,
  handleVisibilityByCategoryInSidebar,
} from "utils/sidebarHandler";
import { setFeatureFlag, setNbaFlag } from "store/reducers/applicationSlice";
import AppUsers from "app/route/appUsers";
import CustomField from "app/route/customFields";
import CustomService from "app/pages/Dashboard/CustomService";
import Data from "app/pages/Dashboard/Data";
import Drops from "app/route/drops/index";
import GeoFencing from "app/route/geoFencing";
import InteractiveMaps from "app/route/interactiveMaps";
import LayoutWithSidebar from "app/layouts/Dashboard/LayoutWithSidebar";
import Orders from "app/pages/Dashboard/Orders/index.js";
import PushNotification from "app/route/pushNotification/index";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Reports from "app/route/reports";
import Settings from "app/route/settings";
import SkeletonPanelHeader from "./skeletonPanelHader";
import Stories from "app/route/stories";
import Webhooks from "app/route/webhooks";

const EnvDashboard = props => {
  const { match } = props;
  const { team_name, access_type, tid, nbaEnable } = useSelector(
    state => state.application,
  );
  const [showRoute, setShowRoute] = useState(false);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    saveSportsEnvKey({ dispatch, params: match.params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sport_key, env_key } = props.match.params;
    /*istanbul ignore else*/
    if (sport_key) {
      props.getApplication(sport_key);
      props.getEnv(sport_key, env_key);
    }
    handleVisibilityInSidebar({ sport_key, env_key });
    if (!isNullOrEmpty(team_name) || !isNullOrEmpty(tid)) {
      getNbaConfig({ sport_key, env_key });
    } else {
      setShowRoute(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team_name, tid]);

  const getNbaConfig = async ({ sport_key, env_key }) => {
    const uid = "nba";
    const {
      data: { integration },
    } = await dispatch(getIntegrationSchema({ sport_key, env_key, uid }));
    dispatch(setNbaFlag({ nbaEnabled: integration.enabled }));
    setShowRoute(true);
    // setNbaEnable(integration.enabled);
  };

  const handleVisibilityInSidebar = async ({ sport_key, env_key }) => {
    const featureIds = FEATURES_ARRAY.map(feature => feature.featureName);
    const {
      data: { integrations },
    } = await dispatch(getIntegrations({ sport_key, env_key }));

    const visibilityInSidebarObj = handleStateForContentVisibilityInSidebar(
      integrations,
      featureIds,
    );

    //handle visibility in sidebar based on category
    const featuresToBeHandledByCategory = FEATURES_ARRAY.filter(
      features => features.handleByCategory === true,
    );

    const visibilityInSidebarByCatObj = handleVisibilityByCategoryInSidebar(
      featuresToBeHandledByCategory,
      integrations.data,
    );

    dispatch(
      setFeatureFlag(
        Object.assign(visibilityInSidebarObj, visibilityInSidebarByCatObj),
      ),
    );
  };

  return (
    <LayoutWithSidebar {...props} logout={props.logout}>
      {!isNullOrEmpty(access_type) && showRoute ? (
        <Switch>
          {nbaEnable ? (
            <Redirect from={`${match.path}`} to={`${match.path}/data`} exact />
          ) : (
            <Redirect
              from={`${match.path}`}
              to={`${match.path}/customField`}
              exact
            />
          )}

          {/* <Route path={`${match.path}/customField`} component={CustomField} /> */}
          <Route
            path={`${match.path}/customServer`}
            component={CustomService}
          />
          <Route path={`${match.path}/customField`} component={CustomField} />
          <Route
            path={`${match.path}/broadcast-topics`}
            component={BroadcastTopic}
          />
          <Route path={`${match.path}/data`} component={Data} />

          <Route
            path={`${match.path}/push-notification`}
            component={PushNotification}
          />
          <Route path={`${match.path}/drops`} component={Drops} />
          <Route path={`${props.match.path}/settings`} component={Settings} />
          <Route path={`${match.path}/stories`} component={Stories} />
          <Route
            path={`${match.path}/interactive-maps`}
            component={InteractiveMaps}
          />
          <Route path={`${match.path}/geo_fencing`} component={GeoFencing} />
          <Route path={`${match.path}/webhooks`} component={Webhooks} />
          <Route path={`${match.path}/reports`} component={Reports} />
          <Route path={`${match.path}/orders`} component={Orders} />
          <Route path={`${match.path}/app-users`} component={AppUsers} />

          {/* <Route path={`${match.path}/add`} component={addSchema} /> */}
          <Route component={NotFoundPage} />
        </Switch>
      ) : (
        <SkeletonPanelHeader />
      )}
    </LayoutWithSidebar>
  );
};

export default EnvDashboard;
