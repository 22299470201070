import { Container as AddSegment } from "./add";
import { Container as GetSegment } from "./get";
import { Route, Switch } from "react-router-dom";
import React from "react";

const Segment = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}`} component={GetSegment} exact />
      <Route path={`${props.match.path}/add`} component={AddSegment} exact />
    </Switch>
  );
};

export default Segment;
