import {
  getGoogleMapApiKey,
  getSearchData as getSearchDataRequest,
  getVenueData as getVenueDataRequest,
} from "services/getObjects";
import { handleError } from "services/helper";
// import { showLoader } from "store/reducers/loaderSlice";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { useDispatch } from "react-redux";
import { useQueryParams } from "app/hooks";
import Loader from "./Loader";
import Map from "./Map";
import React, { useCallback, useEffect, useState } from "react";

function MapApp() {
  const [showLoader, setShowLoader] = useState(true);
  const [venueData, setVenueData] = useState(null);
  const [venueID, setVenueID] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [googleMapApiKey, setGoogleMapApiKey] = useState(null);

  const { querySearch } = useQueryParams();
  const query = new URLSearchParams(querySearch);
  const sport_key = query.get("sport_key");
  const env_key = query.get("env_key");

  const dispatch = useDispatch();

  const getInitialData = useCallback(async () => {
    try {
      setShowLoader(true);
      // dispatch(showLoader({ show: true }));
      const urlQueryParams = new URLSearchParams();
      urlQueryParams.append("device", "ios");
      urlQueryParams.append("version", process.env.REACT_APP_GRAPHQL_VERSION);
      urlQueryParams.append("Content-Type", "application/json");
      urlQueryParams.append("sport_key", sport_key);
      urlQueryParams.append("env_key", env_key);
      urlQueryParams.append("query", "{config{integrations}}");
      setVenueData(null);
      setSearchData(null);

      const {
        data: {
          data: {
            config: { integrations },
          },
        },
      } = await dispatch(
        getGoogleMapApiKey({
          url: `/v1/app/graphql?${urlQueryParams.toString()}`,
        }),
      );
      /* istanbul ignore else */
      if (!Object.keys(integrations).includes("interactive_maps"))
        throw new Error("Can not find interactive_maps integration");

      const { google_access_key, venue_id } = integrations["interactive_maps"];
      setVenueID(venue_id);

      urlQueryParams.delete("query");
      const { data } = await dispatch(
        getVenueDataRequest({
          url: `/v1/app/interactive_maps/venues/${venue_id}?${urlQueryParams.toString()}`,
        }),
      );
      const { data: searchDetail } = await dispatch(
        getSearchDataRequest({
          url: `/v1/app/interactive_maps/venues/search_criteria/${venue_id}?${urlQueryParams.toString()}`,
        }),
      );

      //<------- remove those POIs from the features for whom category_id does not exist
      const categoryIds = data.categories?.map(cat => cat.id) || [];

      const filteredFeatures = Array.from(
        searchDetail?.features,
      ).filter(feature => categoryIds.includes(feature.properties.category_id));
      searchDetail.features = filteredFeatures;
      //-------->

      searchDetail?.features.forEach(feature => {
        feature.properties["id"] = feature.uid;
      });

      setGoogleMapApiKey(google_access_key);
      setVenueData(data);
      setSearchData(searchDetail);
      setShowLoader(false);
      // dispatch(showLoader({ show: false }));
    } catch (error) {
      const err = {
        response: {
          data: {
            error_message: error.message,
          },
        },
      };
      handleError(err, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key]);

  useEffect(() => {
    if (
      !sport_key ||
      !env_key ||
      sport_key.length === 0 ||
      env_key.length === 0
    ) {
      const err = {
        response: {
          data: {
            error_message: "Can not find valid sport_key or env_key",
          },
        },
      };
      handleError(err, dispatch);
    } else getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoom = 18;

  const render = status => {
    switch (status) {
      case Status.LOADING:
        return <Loader />;
      case Status.FAILURE:
        return <Loader />;
      case Status.SUCCESS:
        return (
          <Map
            zoom={zoom}
            venueData={venueData}
            venueID={venueID}
            searchData={searchData}
            dispatch={dispatch}
            sport_key={sport_key}
            env_key={env_key}
          />
        );
      default:
        return <Loader />;
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      {venueData && googleMapApiKey && (
        <Wrapper apiKey={googleMapApiKey} render={render} />
      )}
    </>
  );
}

export default MapApp;
