import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "app/components/Common/schemaForm/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import TextField from "app/components/Common/TextField";

const useStyles = makeStyles({
  selectMenu: {
    whiteSpace: "normal",
  },
});

const SelectField = ({
  uid,
  validations,
  display_name,
  field_metadata,
  onFieldDelete,
  index,
  isBorder = false,
  // defaultValue,
  visible = true,
}) => {
  const classes = useStyles();
  const { control, getValues } = useFormContext();
  return (
    <Controller
      name={`${uid}`}
      control={control}
      rules={{
        required: validations?.required ? `${display_name} is required` : false,
      }}
      defaultValue={getValues(`${uid}`) ?? ""}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, `${field.name}`);
        const fieldError = get(errors, `${field.name}`);
        return (
          <FieldBorder isBorder={isBorder} isError={hasFieldError}>
            {onFieldDelete && (
              <IconButton onClick={event => onFieldDelete(event, index)}>
                <DeleteIcon />
              </IconButton>
            )}
            {visible && (
              <TextField
                id={uid}
                label={display_name}
                variant="outlined"
                select
                error={hasFieldError}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                  multiple: field_metadata?.multiple || false,
                  ...(field_metadata?.multiple && {
                    classes: {
                      selectMenu: classes.selectMenu,
                    },
                  }),
                }}
                helperText={hasFieldError && fieldError?.message}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={field.ref}
                InputLabelProps={{
                  required: validations.required,
                }}
              >
                {field_metadata?.options?.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.value === "" && option.label === "Select"}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {/* protected field checkbox for Integration's config form  */}
            {field_metadata?.hasOwnProperty("Protected") && (
              <Checkbox
                uid={`${uid}-protected-checkbox`}
                display_name="Protected"
                defaultValue={field_metadata.isProtected}
              />
            )}
          </FieldBorder>
        );
      }}
    />
  );
};
export default SelectField;
