import { Container as AddMessages } from "app/pages/Dashboard/pushNotification/Messages/add";
import { Container as AddTemplates } from "app/pages/Dashboard/pushNotification/Template/add";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container as UpdateMessages } from "app/pages/Dashboard/pushNotification/Messages/update";
import { Container as UpdateTemplate } from "app/pages/Dashboard/pushNotification/Template/update";
import GetMessages from "app/pages/Dashboard/pushNotification/Messages/get";
import GetTemplates from "app/pages/Dashboard/pushNotification/Template/get";
import React from "react";
// import Report from "app/pages/Dashboard/pushNotification/Messages/reports";
import { Container as UpdateUser } from "app/pages/Dashboard/pushNotification/users/update/index";
import { Container as Users } from "app/pages/Dashboard/pushNotification/users/get";
import Segment from "app/pages/Dashboard/pushNotification/Segments";

const PushNotification = props => {
  // const { sport_key, env_key } = props.match.params;
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/messages`}
        exact
      />

      {/* message Routes */}
      <Route
        path={`${props.match.path}/messages/add/:uid`}
        component={AddMessages}
        exact
      />
      <Route
        path={`${props.match.path}/messages/add`}
        component={AddMessages}
      />
      <Route
        path={`${props.match.path}/messages/update/:uid`}
        component={UpdateMessages}
        exact
      />
      {/* <Route
        path={`${props.match.path}/messages/report/:uid`}
        render={routeProps => (
          <Report sport_key={sport_key} env_key={env_key} {...routeProps} />
        )}
      /> */}
      <Route path={`${props.match.path}/messages`} component={GetMessages} />

      {/* segment Routes */}
      <Route path={`${props.match.path}/segment`} component={Segment} />

      {/* template Routes */}
      <Route
        path={`${props.match.path}/template/add`}
        component={AddTemplates}
        exact
      />
      <Route
        path={`${props.match.path}/template/update/:uid`}
        component={UpdateTemplate}
        exact
      />
      <Route path={`${props.match.path}/template`} component={GetTemplates} />

      {/* users */}
      <Route path={`${props.match.path}/users`} component={Users} exact />
      <Route
        path={`${props.match.path}/users/update/:uid`}
        component={UpdateUser}
        exact
      />
    </Switch>
  );
};

export default PushNotification;
