import { isNullOrEmpty } from "utils/functions";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Collaborators from "app/pages/Dashboard/settings/collaborators";
import DataSync from "app/pages/Dashboard/settings/syncData";
import Features from "app/pages/Dashboard/settings/features";
import React from "react";

const Settings = props => {
  const { team_name, tid } = useSelector(state => state.application);
  return (
    <>
      <Switch>
        {!isNullOrEmpty(team_name) && !isNullOrEmpty(tid) ? (
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/syncData`}
            exact
          />
        ) : (
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/features`}
            exact
          />
        )}

        <Route
          path={`${props.match.path}/features`}
          exact
          component={Features}
        />
        {!isNullOrEmpty(team_name) && !isNullOrEmpty(tid) ? (
          <Route path={`${props.match.path}/syncData`} component={DataSync} />
        ) : null}
        <Route
          path={`${props.match.path}/collaborators`}
          component={Collaborators}
        />
      </Switch>
    </>
  );
};

export default Settings;
