import { Autocomplete, FormWrapper } from "styles/global.style";
import { Button } from "@dfep/ui-component";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import MenuItem from "@material-ui/core/MenuItem";
import PanelFooter from "app/components/Common/PanelFooter";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useState } from "react";
import TextField from "app/components/Common/TextField";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddSegmentView = ({
  cancelHandler,
  submitFormHandler,
  getOptionsData,
  sport_key,
  env_key,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const AddSegmentForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all",
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
  } = AddSegmentForm;
  const onDataFieldChange = async value => {
    /* istanbul ignore else */
    if (getValues("type") && value) {
      setOptions([]);
      setIsLoading(true);
      const optionsArray = await getOptionsData(value, getValues("type"));
      setOptions(optionsArray);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Segments"
        backUrl={`/dashboard/${sport_key}/${env_key}/push-notification/segment`}
        breadCrumbItems={[
          {
            title: "Push Notification",
          },
        ]}
      />
      <FormWrapper elevation={2} variant="outlined">
        <form
          id="add_segment"
          className="maxWidthSm"
          noValidate
          data-testid="add_segment"
          onSubmit={handleSubmit(submitFormHandler)}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required.",
            }}
            defaultValue=""
            render={({ field, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, "name");
              const fieldError = get(errors, "name");
              return (
                <FieldBorder isBorder={false} isError={hasFieldError}>
                  <TextField
                    autoFocus
                    label="Name"
                    type="text"
                    variant="outlined"
                    error={hasFieldError}
                    value={field.value}
                    id="name"
                    helperText={hasFieldError && fieldError.message}
                    onChange={field.onChange}
                    onBlur={e => {
                      e.target.value = e.target.value.trim();
                      field.onChange(e.target.value);
                      field.onBlur(e.target.value);
                    }}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </FieldBorder>
              );
            }}
          />
          <Controller
            name="type"
            control={control}
            rules={{
              required: "Field is required.",
            }}
            defaultValue=""
            render={({ field, fieldState, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, "type");
              const fieldError = get(errors, "type");
              return (
                <FieldBorder isBorder={false} isError={hasFieldError}>
                  <TextField
                    label="Type"
                    type="select"
                    variant="outlined"
                    id="select-type"
                    error={hasFieldError}
                    select
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    helperText={hasFieldError && fieldError.message}
                    value={field.value}
                    onChange={event => {
                      setOptions([]);
                      setValue("data", []);
                      field.onChange(event);
                    }}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: true,
                    }}
                  >
                    <MenuItem value="topic">Topic</MenuItem>
                    <MenuItem value="personal_id">Personal ID</MenuItem>
                    <MenuItem value="device_token">Device Token</MenuItem>
                  </TextField>
                </FieldBorder>
              );
            }}
          />
          <Controller
            name="data"
            control={control}
            rules={{
              required: "Data field is required.",
              validate: data => {
                /* istanbul ignore else */
                if (getValues("type") === "topic" && data.length > 5)
                  return "Only 5 tags are allowed.";
              },
            }}
            defaultValue=""
            render={({ field, fieldState, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, "data");
              const fieldError = get(errors, "data");
              return (
                <FieldBorder isBorder={false} isError={hasFieldError}>
                  <Autocomplete
                    multiple
                    forcePopupIcon={false}
                    filterSelectedOptions
                    open={open}
                    onOpen={event => {
                      event.target.value.trim();
                      /* istanbul ignore else */
                      if (event.target.value !== "") setOpen(true);
                    }}
                    /* istanbul ignore next */
                    onClose={() => {
                      setOpen(false);
                    }}
                    getOptionSelected={(option, value) => {
                      return (
                        option.personal_id === value.personal_id &&
                        option.name === value.name &&
                        option.device_token === value.device_token
                      );
                    }}
                    getOptionLabel={option => {
                      return (
                        option?.personal_id ||
                        option?.name ||
                        option?.device_token
                      );
                    }}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.personal_id ||
                          option.name ||
                          option.device_token}
                      </React.Fragment>
                    )}
                    value={field.value}
                    options={options}
                    defaultValue={[]}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    loading={isLoading}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Data"
                        variant="outlined"
                        error={hasFieldError}
                        helperText={
                          (hasFieldError && fieldError.message) ||
                          (getValues("type") === "topic" &&
                            "Only 5 tags are allowed.")
                        }
                        onChange={(ev, value) => {
                          ev.target.value = ev.target.value.trim();
                          setOptions([]);
                          /* istanbul ignore else */
                          if (ev.target.value !== "") {
                            onDataFieldChange(ev.target.value);
                          } else {
                            setOpen(false);
                          }
                        }}
                        onBlur={field.onBlur}
                        inputRef={field.ref}
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                    )}
                  />
                </FieldBorder>
              );
            }}
          />
        </form>
        <PanelFooter>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={cancelHandler}
              data-testid="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              disabled={
                Object.keys(dirtyFields).length !== 3 ||
                !!Object.keys(errors).length
              }
              form="add_segment"
              variant="contained"
              color="primary"
              type="submit"
              data-testid="submit-btn"
            >
              Add
            </Button>
          </div>
        </PanelFooter>
      </FormWrapper>
    </>
  );
};

export default AddSegmentView;
