import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import schema from "./schema";
import SchemaForm from "app/components/Common/schemaForm";

const Form = ({ onSubmit, backUrl, defaultValues }) => {
  // State
  const [modifiedSchema, setSchemaState] = useState();

  //access drops_admin_message_type type from store
  const { drops_admin_message_type } = useSelector(state => state.constants);

  //drop-down options
  const getOptions = (obj = {}) => {
    const options = [];
    Object.keys(obj).forEach((key, i) => {
      options.push({
        label: [key],
        value: obj[key],
      });
    });
    return options;
  };

  const modifySchema = useCallback(
    async () => {
      const Schema = cloneDeep(schema);

      Schema.class._schema.forEach(obj => {
        let options = [];
        switch (obj.uid) {
          case "type":
            options = getOptions(drops_admin_message_type);
            obj.field_metadata.options.push(...options);
            break;
          default:
            break;
        }
      });
      setSchemaState(Schema);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drops_admin_message_type],
  );

  // Component Life Cycle
  useEffect(() => {
    modifySchema();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drops_admin_message_type]);
  return (
    <>
      {modifiedSchema && (
        <SchemaForm
          onSubmit={onSubmit}
          schema={modifiedSchema}
          backUrl={backUrl}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default Form;
