import {
  Box,
  BoxWrap,
  Grid,
} from "app/pages/Dashboard/Data/Device/SettingsCard/Card.style";
import { Card } from "@dfep/ui-component";
import MuiSkeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

const Skeleton = styled(MuiSkeleton)`
  &.MuiSkeleton-text {
    border-radius: 10px;
  }
`;

const Loader = ({ count }) => {
  return (
    <Grid container direction="row" data-testid="loading">
      {[...Array(count)].map((_, index) => {
        return (
          <Grid
            key={count + index}
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
            className="card"
          >
            <Card
              cardBorderColor="4px solid grey"
              className="editDetails skeleton-loader"
              cardContentclassName="cardHover"
              cardContent={() => (
                <>
                  <BoxWrap
                    mb={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="caption">
                        <span>Version</span>
                      </Typography>
                      <Skeleton variant="text" width="70%" height={24} />
                    </Box>
                  </BoxWrap>
                  <BoxWrap display="flex" alignItems="center">
                    <Box className="version-update">
                      <Typography variant="caption">
                        <span>Created At</span>
                      </Typography>
                      <Skeleton variant="text" width="100%" height={25} />
                    </Box>

                    <Box className="version-update">
                      <Typography variant="caption">
                        <span>Last Modified</span>
                      </Typography>
                      <Skeleton variant="text" width="100%" height={25} />
                    </Box>
                  </BoxWrap>
                </>
              )}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

Loader.propTypes = {
  count: PropTypes.number,
};

export default Loader;
