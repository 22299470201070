const schema = {
  class: {
    title: "Announcements",
    uid: "Announcements",
    _schema: [
      {
        uid: "title",
        display_name: "Title",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "cs_uid",
        display_name: "CS Uid",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "drop_uids",
        display_name: "Drop Uids",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: true,
        _schema: [],
      },
      {
        uid: "type",
        display_name: "Announcement Type",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "announcements",
        display_name: "Announcements",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: true,
        _schema: [
          {
            uid: "message",
            display_name: "Message",
            data_type: "string",
            field_metadata: {
              multiline: true,
            },
            mandatory: true,
            _schema: [],
          },
        ],
      },
    ],
  },
};

export default schema;
