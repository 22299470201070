import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import SchemaForm from "../schemaForm";

const AddAnnouncement = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/drops/v1/announcements`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: `/v1/admin/drops/portal/announcements`,
          key: "announcements",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Drop Announcement"
        breadCrumbItems={[
          {
            title: "Add Drop Announcement",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm onSubmit={onSubmit} backUrl={getURL} props={props} />
    </>
  );
};

export default AddAnnouncement;
