import { Redirect, Route, Switch } from "react-router-dom";
import AdvPlayerStats from "app/pages/Dashboard/Data/Roster/AdvPlayerStats";
import Coaches from "app/pages/Dashboard/Data/Roster/Coaches";
import PlayerAvg from "app/pages/Dashboard/Data/Roster/PlayerAverage";
import Players from "app/pages/Dashboard/Data/Roster/Players";
import PlayerStats from "app/pages/Dashboard/Data/Roster/PlayerStats";
import React from "react";

const PlayersRoute = props => {
  return (
    <Switch>
      <Redirect
        from={props.match.path}
        to={`${props.match.url}/players`}
        exact
      />
      <Route path={`${props.match.path}/players`} component={Players} />
      <Route path={`${props.match.path}/playerStats`} component={PlayerStats} />
      <Route
        path={`${props.match.path}/advancedPlayerStats`}
        component={AdvPlayerStats}
      />
      <Route path={`${props.match.path}/playerAverage`} component={PlayerAvg} />
      <Route path={`${props.match.path}/coaches`} component={Coaches} />
    </Switch>
  );
};

export default PlayersRoute;
