const validateLowerCase = value => {
  return /^(?=.*[a-z])/.test(value);
};

const validateUpperCase = value => {
  return /^(?=.*[A-Z])/.test(value);
};

const validateLength = value => {
  return !(value.length < 8 || value.length > 16);
};

const validateSpecialCharacter = value => {
  return /^(?=.*[@#$^~!%*?_&])/.test(value);
};

const validateDigit = value => {
  return /^(?=.*\d)/.test(value);
};

/* istanbul ignore file */
const provideValidationToggle = value => {
  const low_letter = document.getElementById("low_letter");
  const up_letter = document.getElementById("up_letter");
  const spl = document.getElementById("special_character");
  const length = document.getElementById("length");
  const digit = document.getElementById("digit");

  if (validateLowerCase(value)) {
    low_letter.classList.remove("incorrect");
    low_letter.classList.add("correct");
  } else {
    low_letter.classList.add("incorrect");
    low_letter.classList.remove("correct");
  }

  if (validateUpperCase(value)) {
    up_letter.classList.remove("incorrect");
    up_letter.classList.add("correct");
  } else {
    up_letter.classList.add("incorrect");
    up_letter.classList.remove("correct");
  }

  if (validateLength(value)) {
    length.classList.remove("incorrect");
    length.classList.add("correct");
  } else {
    length.classList.add("incorrect");
    length.classList.remove("correct");
  }

  if (validateDigit(value)) {
    digit.classList.remove("incorrect");
    digit.classList.add("correct");
  } else {
    digit.classList.add("incorrect");
    digit.classList.remove("correct");
  }

  if (validateSpecialCharacter(value)) {
    spl.classList.remove("incorrect");
    spl.classList.add("correct");
  } else {
    spl.classList.add("incorrect");
    spl.classList.remove("correct");
  }
};

export { provideValidationToggle };
