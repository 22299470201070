import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import PropTypes from "prop-types";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

function DevicesDataView(props) {
  return (
    <>
      {props.isLoading || props.refresh ? (
        <SkeletonTable
          headings={props.headings}
          checkbox={props.checkbox}
          name={props.name}
          actions={props.actions}
        />
      ) : props.totalCount > 0 && !props.refresh ? (
        <Table {...props} />
      ) : (
        <NoData>No Data Found</NoData>
      )}

      <Modal
        className="small"
        open={props.openModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props.name}`}
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: props.deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: props?.deleteUserId,
          module: props?.name,
        })}
      </Modal>
      <Modal
        className="small"
        open={props.multipleDeleteModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props.name}`}
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: props.handleModalClose,
            additionalAttributes: {
              "data-testid": "delete-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: props.deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "delete-succeed",
            },
          },
        ]}
      >
        Are you sure you want to delete <strong>{props.numSelected}</strong>{" "}
        items?
      </Modal>
    </>
  );
}
DevicesDataView.propTypes = {
  numSelected: PropTypes.number,
  deleteHandler: PropTypes.func,
};
export default DevicesDataView;
