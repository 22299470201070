const DISPLAY = [
  { label: "Enabled", value: "enabled" },
  { label: "Disabled", value: "disabled" },
  { label: "Hide", value: "hide" },
];

const LOCATION_FILTER_FIELDS = [
  { label: "In Arena", value: "inArena" },
  { label: "Within Region", value: "withinRegion" },
  { label: "Other", value: "others" },
];

const GAME_FILTER_FIELDS = [
  { label: "Pre", value: "pre" },
  { label: "Ongoing", value: "ongoing" },
  { label: "Post", value: "post" },
];

const schema = {
  class: {
    title: "Device Menu",
    uid: "device_menu",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Menus",
        uid: "menus",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Name",
            uid: "name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Key",
            uid: "key",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Items",
            uid: "items",
            mandatory: false,
            multiple: true,
            data_type: "group",
            _schema: [
              {
                display_name: "Name",
                uid: "name",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Key",
                uid: "key",
                mandatory: true,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Icon Url",
                uid: "icon_url",
                mandatory: false,
                multiple: false,
                data_type: "file",
                field_metadata: {},
              },
              {
                display_name: "Icon Text",
                uid: "icon_text",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Internal link",
                uid: "internal_link_url",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Position",
                uid: "position",
                mandatory: true,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Display",
                uid: "display",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {
                  select: true,
                  options: [{ label: "Select", value: "" }, ...DISPLAY],
                },
              },
              {
                display_name: "Filter",
                uid: "filter",
                mandatory: false,
                multiple: false,
                data_type: "group",
                _schema: [
                  {
                    display_name: "Location",
                    uid: "location",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {
                      select: true,
                      options: [
                        { label: "None", value: "" },
                        ...LOCATION_FILTER_FIELDS,
                      ],
                    },
                  },
                  {
                    display_name: "Game",
                    uid: "game",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {
                      select: true,
                      options: [
                        { label: "None", value: "" },
                        ...GAME_FILTER_FIELDS,
                      ],
                    },
                  },
                ],
                field_metadata: {},
              },
              {
                display_name: "Style",
                uid: "style",
                mandatory: false,
                multiple: false,
                data_type: "mixed",
                field_metadata: {},
                _schema: [],
              },
              {
                display_name: "Custom Fields",
                uid: "custom_fields",
                mandatory: false,
                multiple: false,
                data_type: "mixed",
                field_metadata: {},
                _schema: [],
              },
            ],
            field_metadata: {},
          },
          {
            display_name: "Style",
            uid: "style",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
          {
            display_name: "Custom Fields",
            uid: "custom_fields",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
