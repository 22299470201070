import { Autocomplete } from "styles/global.style";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import { getDataOptions } from "services/getObjects";
import { PUSH_AUDIENCE } from "utils/constants";
import { query as searchQuery } from "services/helper";
import { useParams } from "react-router";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CircularProgress from "@material-ui/core/CircularProgress";
import get from "lodash/get";
import has from "lodash/has";
import React, { useCallback, useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GetData = props => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [active, setActive] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [beforeValue, setBeforeValue] = useState();
  const loading = open && options.length === 0;
  const {
    useForm: { setValue, getValues, control },
    dataSearch,
    uids,
    push_provider,
  } = props;
  const { sport_key, env_key } = useParams();
  const WAIT_INTERVAL = 700;
  let typingTimeOut = null;

  // getting Add labels
  const getOption = useCallback(
    async (props, uids) => {
      switch (props.type) {
        case "personal_id":
          if (uids) {
            const {
              data: { users },
            } = await dataSearch({
              url: "/v1/app_user/admin/user",
              query: {
                uid: { $in: uids },
              },
            });

            setBeforeValue(users);
          } else {
            const {
              data: { users },
            } = await dataSearch({
              url: "/v1/app_user/admin/user",
              query: {
                ...searchQuery(fieldName).users,
              },
              limit: 5,
            });

            if (active) {
              setOptions(users);
            }
          }

          break;

        case "topic":
          if (uids) {
            const {
              data: { topics },
            } = await dataSearch({
              url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics`,
              query: {
                uid: {
                  $in: uids,
                },
                service: "push",
              },
            });

            setBeforeValue(topics);
          } else {
            const {
              data: { topics },
            } = await dataSearch({
              url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics`,
              query: {
                ...searchQuery(fieldName).topics,
                service: "push",
              },
              limit: 5,
            });

            if (active) {
              setOptions(topics);
            }
          }

          break;

        case "device_token":
          if (uids) {
            const {
              data: { installation_data },
            } = await dataSearch({
              url: "/v1/push_notification/admin/installation_data",
              query: {
                uid: {
                  $in: uids,
                },
                push_provider,
              },
            });

            setBeforeValue(installation_data);
          } else {
            const {
              data: { installation_data },
            } = await dataSearch({
              url: "/v1/push_notification/admin/installation_data",
              query: {
                ...searchQuery(fieldName).installationDropDown,
                push_provider,
              },
              limit: 5,
            });

            if (active) {
              setOptions(installation_data);
            }
          }

          break;

        default:
          if (uids) {
            const {
              data: { segment_data },
            } = await dataSearch({
              url: "/v1/push_notification/admin/segment",
              query: {
                uid: { $in: uids },
              },
            });
            setBeforeValue(segment_data);
          } else {
            const {
              data: { segment_data },
            } = await dataSearch({
              url: "/v1/push_notification/admin/segment",
              query: {
                ...searchQuery(fieldName).segment,
              },
              limit: 5,
            });

            if (active) {
              setOptions(segment_data);
            }
          }

          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldName, uids],
  );

  //getting default labels
  const getDefaultTopics = async porps => {
    const {
      data: { topics },
    } = await props.dataSearch({
      url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics`,
      query: {
        key: `${env_key}_default`,
      },
    });
    setValue("AddToken", {
      uid: topics.map(item => item.uid),
      set: PUSH_AUDIENCE.topic,
    });
  };

  useEffect(() => {
    setActive(true);

    if (!loading) {
      return undefined;
    }

    return () => {
      setActive(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (fieldName.length > 0) {
      getOption(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOption]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setFieldName("");
    }
  }, [open]);

  useEffect(() => {
    if (props.type === PUSH_AUDIENCE.default) {
      getDefaultTopics(props);
    }

    if (uids?.length > 0) {
      setValue("AddToken", { uid: uids });
      getOption(props, uids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAudienceSearch = (event, field) => {
    clearTimeout(typingTimeOut);
    typingTimeOut = setTimeout(() => {
      setOptions([]);
      setFieldName(event.target.value.trim());
      field.onChange(event.target.value.trim());
    }, WAIT_INTERVAL);
  };

  return (
    <>
      {props.type === PUSH_AUDIENCE.default ? null : uids?.length > 0 ? (
        beforeValue && (
          <Controller
            className="auto-complete"
            name="select"
            control={control}
            rules={{
              required: "select Value is required.",
              validate: {
                validate: data => {
                  if (
                    props.type === PUSH_AUDIENCE.segment &&
                    getValues("AddToken")?.uid?.length > 1
                  )
                    return "Only 1 tags  allowed";
                  if (
                    props.type === PUSH_AUDIENCE.topic &&
                    getValues("AddToken")?.uid?.length > 5
                  )
                    return "Only 5 tags are allowed";
                },
              },
            }}
            render={({ field, fieldState, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, "select");
              const fieldError = get(errors, "select");
              return (
                <Autocomplete
                  multiple
                  id="topic auto"
                  open={open}
                  defaultValue={field.value}
                  onOpen={event => {
                    event.target.value.trim();
                    if (event.target.value !== "") setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onChange={(event, value) => {
                    setValue("AddToken", {
                      uid: value.map(item => item.uid),
                    });
                    field.onChange(value);
                  }}
                  forcePopupIcon={false}
                  getOptionSelected={(option, value) =>
                    option.name === value.name &&
                    option.device_token === value.device_token &&
                    option.personal_id === value.personal_id
                  }
                  getOptionLabel={option =>
                    option.name || option.device_token || option.personal_id
                  }
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name || option.device_token || option.personal_id}
                    </React.Fragment>
                  )}
                  options={options}
                  filterSelectedOptions
                  disableCloseOnSelect
                  loading={loading}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label="Select Value"
                        error={hasFieldError}
                        variant="outlined"
                        onChange={(e, value) => {
                          // e.target.value = e.target.value.trim();
                          // setOptions([]);
                          // setFieldName(e.target.value);
                          onAudienceSearch(e, field);
                        }}
                        helperText={
                          (hasFieldError && fieldError.message) ||
                          (props.type === PUSH_AUDIENCE.topic &&
                            "Only 5 tags are allowed") ||
                          (props.type === PUSH_AUDIENCE.segment &&
                            "Only 1 tags  allowed")
                        }
                        onBlur={field.onBlur}
                        inputRef={field.ref}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading && (
                                <CircularProgress color="inherit" size={25} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                />
              );
            }}
            defaultValue={beforeValue}
          />
        )
      ) : (
        <Controller
          name="select"
          className="auto-complete"
          control={control}
          rules={{
            required: "select Value is required.",
            validate: {
              validate: data => {
                if (
                  props.type === PUSH_AUDIENCE.segment &&
                  getValues("AddToken")?.uid.length > 1
                )
                  return "Only 1 tags  allowed";
                if (
                  props.type === PUSH_AUDIENCE.topic &&
                  getValues("AddToken")?.uid.length > 5
                )
                  return "Only 5 tags are allowed";
              },
            },
          }}
          defaultValue=""
          render={({ field, fieldState, formState }) => {
            const { errors } = formState;
            const hasFieldError = has(errors, "select");
            const fieldError = get(errors, "select");
            return (
              <Autocomplete
                multiple
                id="topic auto"
                open={open}
                onOpen={event => {
                  event.target.value.trim();
                  if (event.target.value !== "") setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, value) => {
                  setValue("AddToken", {
                    uid: value.map(item => item.uid),
                  });
                }}
                forcePopupIcon={false}
                getOptionLabel={option =>
                  option.name || option.device_token || option.personal_id
                }
                getOptionSelected={(option, value) =>
                  option.name === value.name &&
                  option.device_token === value.device_token &&
                  option.personal_id === value.personal_id
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name || option.device_token || option.personal_id}
                  </React.Fragment>
                )}
                options={options}
                filterSelectedOptions
                disableCloseOnSelect
                loading={loading}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label="Select Value"
                      error={hasFieldError}
                      variant="outlined"
                      onChange={(e, value) => {
                        onAudienceSearch(e, field);
                      }}
                      helperText={
                        (hasFieldError && fieldError.message) ||
                        (props.type === PUSH_AUDIENCE.topic &&
                          "Only 5 tags are allowed") ||
                        (props.type === PUSH_AUDIENCE.segment &&
                          "Only 1 tags  allowed")
                      }
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading && (
                              <CircularProgress color="inherit" size={25} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
              />
            );
          }}
        />
      )}
    </>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    dataSearch: payload => dispatch(getDataOptions(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(GetData);

export { Container, GetData as Component };
