/* istanbul ignore file */
import Default from "./default.svg";

import Atlanta_Hawks from "./atlanta-hawks.svg";
import Boston_Celtics from "./boston-celtics.svg";
import Brooklyn_Nets from "./nets.svg";
import Charlotte_Hornets from "./hornets.svg";
import Chicago_Bulls from "./chicago-bulls.svg";
import Cleveland_Cavaliers from "./cavaliers.svg";
import Dallas_Mavericks from "./mavericks.svg";
import DenverNuggets from "./denver-nuggets.svg";
import Detroit_Pistons from "./pistons.svg";
import DFE_Logo from "./DFE-Logo.svg";
import Golden_State_Warriors from "./warriors.svg";
import Houston_Rockets from "./rockets.svg";
import Indiana_Pacers from "./indiana-pacers.svg";
import LA_Clippers from "./clippers.svg";
import Los_Angeles_Lakers from "./lakers.svg";
import Memphis_Grizzlies from "./grizzlies.svg";
import Miami_Heat from "./miami-heat.svg";
import Milwaukee_Bucks from "./milwaukee-bucks.svg";
import Minnesota_Timberwolves from "./minnesota-timberwolves.svg";
import New_Orleans_Pelicans from "./pelicans.svg";
import New_York_Knicks from "./knicks.svg";
import Oklahoma_City_Thunder from "./thunder.svg";
import Orlando_Magic from "./magic.svg";
import Philadelphia_76ers from "./76ers.svg";
import Phoenix_Suns from "./suns.svg";
import Portland_Trail_Blazers from "./trail_blazers.svg";
import Sacramento_Kings from "./sacramento-kings.svg";
import San_Antonio_Spurs from "./spurs.svg";
import Toronto_Raptors from "./raptors.svg";
import Utah_Jazz from "./jazz.svg";
import Washington_Wizards from "./wizards.svg";

// WNBA Imports
import Phoenix_Mercury from "./mercury.svg";

// import GoogleMap from "./google-maps.svg";

function TeamLogo(name) {
  switch (name) {
    case "dfe":
      return DFE_Logo;
    case "hawks":
      return Atlanta_Hawks;
    case "bulls":
      return Chicago_Bulls;
    case "nuggets":
      return DenverNuggets;
    case "heat":
      return Miami_Heat;
    case "pacers":
      return Indiana_Pacers;
    case "kings":
      return Sacramento_Kings;
    case "bucks":
      return Milwaukee_Bucks;
    case "celtics":
      return Boston_Celtics;
    case "timberwolves":
      return Minnesota_Timberwolves;
    case "nets":
      return Brooklyn_Nets;
    case "hornets":
      return Charlotte_Hornets;
    case "cavaliers":
      return Cleveland_Cavaliers;
    case "mavericks":
      return Dallas_Mavericks;
    case "pistons":
      return Detroit_Pistons;
    case "warriors":
      return Golden_State_Warriors;
    case "rockets":
      return Houston_Rockets;
    case "clippers":
      return LA_Clippers;
    case "lakers":
      return Los_Angeles_Lakers;
    case "grizzlies":
      return Memphis_Grizzlies;
    case "pelicans":
      return New_Orleans_Pelicans;
    case "knicks":
      return New_York_Knicks;
    case "thunder":
      return Oklahoma_City_Thunder;
    case "magic":
      return Orlando_Magic;
    case "76ers":
      return Philadelphia_76ers;
    case "suns":
      return Phoenix_Suns;
    case "trail_blazers":
      return Portland_Trail_Blazers;
    case "spurs":
      return San_Antonio_Spurs;
    case "raptors":
      return Toronto_Raptors;
    case "jazz":
      return Utah_Jazz;
    case "wizards":
      return Washington_Wizards;

    // WNBA Cases
    case "mercury":
      return Phoenix_Mercury;

    default:
      return Default;
  }
}

export default TeamLogo;
