import { createRecord } from "services/createObject";
import { getSchema } from "services/customObject/getSchema";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useEffect, useState } from "react";
import SchemaForm from "app/components/Common/schemaForm";

const AddObject = props => {
  const [schema, setSchema] = useState();
  const dispatch = useDispatch();

  const { sport_key, env_key, schema_uid } = props.match.params;
  const backUrl = `/dashboard/${sport_key}/${env_key}/customField/${schema_uid}/objects`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: `/v1/custom_schema/admin/classes/${schema_uid}/objects`,
          key: "object",
          form: data,
        }),
      );
      props.history.push(backUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  const getSchemaObject = async () => {
    try {
      const { data } = await dispatch(getSchema({ uid: schema_uid }));
      setSchema(data);
    } catch (error) {
      /*istanbul ignore next*/
      console.error(error);
    }
  };

  useEffect(() => {
    getSchemaObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {schema && (
        <>
          <PanelHeader
            title={`Add ${schema.class.title}`}
            breadCrumbItems={[
              {
                title: "Custom Fields",
                link: `/dashboard/${sport_key}/${env_key}/customField`,
              },
              {
                title: `Add ${schema.class.title}`,
              },
            ]}
            backUrl={backUrl}
          />
          <SchemaForm onSubmit={onSubmit} schema={schema} backUrl={backUrl} />
        </>
      )}
    </Fragment>
  );
};

export default AddObject;
