import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import MuiTextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";

const TextFieldComponent = props => {
  return (
    <MuiTextField
      inputProps={{ "data-testid": `${props.id}-input` }}
      FormHelperTextProps={{ "data-testid": `${props.id}-helper-text` }}
      variant="outlined"
      {...props}
    />
  );
};

export default function Levels({ levels = [], levelValue, setLevelValue }) {
  const [levelInputValue, setLevelInputValue] = useState(levelValue.level);
  // set default level for once
  useEffect(() => {
    // istanbul ignore else
    if (!levels.map(level => String(level.id)).includes(levelInputValue)) {
      levels.some(level => {
        if (level.is_default === true) {
          setLevelValue({ level: level.id, refreshControl: false });
          setLevelInputValue(level.id);
          return true;
        }
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle onLevelChange
  const onLevelChange = e => {
    e.preventDefault();
    setLevelValue({ level: e.target.value, refreshControl: false });
    setLevelInputValue(e.target.value);
  };

  return (
    <FormControl variant="filled" fullWidth style={{ background: "#8fbc8f" }}>
      <TextFieldComponent
        id={"venue_levels"}
        label={"Levels"}
        variant="outlined"
        select
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            marginThreshold: 0,
          },
        }}
        value={levelInputValue}
        onChange={onLevelChange}
      >
        {levels.length &&
          levels.map(level => (
            <MenuItem key={level.id} value={level.id}>
              {level.name}
            </MenuItem>
          ))}
      </TextFieldComponent>
    </FormControl>
  );
}
