import { Card } from "@dfep/ui-component";
import { Link } from "react-router-dom";
import MuiCardHeader from "@material-ui/core/CardHeader";
import MuiDivider from "@material-ui/core/Divider";
import MuiSkeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

export const DashboardContainer = styled.div`
  /* padding: 24px; */
  .MuiCardContent-root {
    padding: 0 16px 21px 22px;
    word-break: break-word;
  }
  .panel-header {
    margin: 0;
    padding: 0;
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .skeleton-loader {
    .timestamp {
      width: 100%;
    }
  }
`;

export const CardHeader = styled(MuiCardHeader)`
  &.MuiCardHeader-root {
    padding: 10px 0 0;
    min-height: 50px;
    .MuiCardHeader-content {
      display: flex;
      justify-content: flex-end;
    }
  }
  & .headerLogo {
    width: 100%;
    justify-content: flex-end;
  }
  & .MuiAvatar-img {
    max-width: 34px;
  }
`;

export const CardLink = styled(Link)`
  text-decoration: none;
`;

export const Divider = styled(MuiDivider)`
  margin: 24px 0 23px;
  background-color: ${props => props.theme.palette.grey.greyE0E0E0};
`;

export const DashboardCard = styled(Card)`
  box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  .headerLogo {
    width: 40px;
    position: relative;
    height: 40px;
    overflow: hidden;
    img {
      position: absolute;
      max-width: 100%;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Skeleton = styled(MuiSkeleton)`
  &.MuiSkeleton-circle {
    margin-right: 20px;
  }
  &.MuiSkeleton-text {
    border-radius: 10px;
  }
`;
