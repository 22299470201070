import MuiChip from "@material-ui/core/Chip";
import styled from "styled-components";

export const Chip = styled(MuiChip)`
  &.MuiChip-root {
    &.active {
      background: ${props => props.theme.palette.green.green0E9642};
      color: ${props => props.theme.palette.white.main};
    }
    &.full_access {
      background: ${props => props.theme.palette.secondary.main};
      color: ${props => props.theme.palette.secondary.contrastText};
    }
  }
`;
