/* eslint-disable react-hooks/exhaustive-deps */
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import TreeView from "app/components/Common/CustomFieldForm";

const UpdateCustomFields = ({ match, history }) => {
  // States
  const [schema, setSchema] = useState({});

  // Params
  const { uid, sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Functions
  const getSchemaClass = useCallback(async () => {
    const { data } = await dispatch(
      get({ url: `/v1/custom_schema/admin/classes/${uid}`, loader: true }),
    );
    setSchema(data);
  }, []);

  const onSubmit = async data => {
    const form = { ...schema.class, ...data };
    const { status } = await dispatch(
      updateRecord({
        url: `/v1/custom_schema/admin/classes/${uid}`,
        key: "class",
        form,
        loader: true,
      }),
    );
    status === 200 && push(`/dashboard/${sport_key}/${env_key}/customField`);
  };

  // LifeCycle
  useEffect(() => {
    getSchemaClass();
  }, [getSchemaClass]);

  // Render
  return (
    <>
      <PanelHeader
        data-testid="customfields-header"
        backUrl={`/dashboard/${sport_key}/${env_key}/customField`}
        title="Update Custom Field"
        breadCrumbItems={[{ title: "Custom Field" }]}
      />
      {Object.keys(schema).length > 0 && (
        <TreeView
          schema={schema}
          onSubmit={onSubmit}
          schema_type="collection"
          disabled={{ uid: true }}
          backUrl={`/dashboard/${sport_key}/${env_key}/customField`}
          data-testid="customfield-treeview"
        />
      )}
    </>
  );
};

export default UpdateCustomFields;
