import { capitalize } from "utils/functions";
import { Collapsible } from "app/components/Common/Collapsible";
import { connect } from "react-redux";
import { getMockGames } from "services/getObjects";
import { isValidWholeNumber, setError } from "utils/functions";
import { requestAPI } from "services/helper";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import cloneDeep from "lodash/cloneDeep";
import HighlightOffSharpIcon from "@material-ui/icons/HighlightOffSharp";
import PanelHeader from "app/components/Common/PanelHeader";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import TableView from "./indexView";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  fastForwardMinutesInput: {
    hasError: false,
    message: "Enter time in minutes to skip mock game",
  },
};

const MockGames = props => {
  const [mockData, setMockData] = useState([]);
  const [mockCount, setMockCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [actionType, setActionType] = useState();
  const [game_id, setGame_id] = useState();
  const { sort, sortValue, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const [fastForwardMinutesInput, setMinutes, resetMinutes] = useInput(0);
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));

  const getMockGame = useCallback(async () => {
    setIsLoading(true);
    setMockData([]);
    const {
      data: { count, recorded_games },
    } = await props.getMockGames({
      sort,
      sortValue,
      skip,
      limit,
      searchText,
    });
    recorded_games.forEach(item => {
      item.mockgame_running = capitalize(`${item.mockgame_running}`);
    });
    setMockData(recorded_games);
    setMockCount(count);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, sortValue, skip, limit, searchText]);

  useEffect(() => {
    getMockGame();
  }, [getMockGame]);

  const headings = [
    { label: "Game Code", id: "gcode" },
    { label: "Game ID", id: "gid" },
    { label: "Running", id: "mockgame_running" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const validateInput = input => {
    if (!isValidWholeNumber(input)) {
      const error = setError(
        inputError,
        "fastForwardMinutesInput",
        "Invalid Input , number should be greater than or equal to zero",
      );
      setInputError(cloneDeep(error));
      return false;
    } else return true;
  };

  const StartHandler = async (event, uid, data) => {
    setGame_id(data.gid);
    setActionType("start");
    resetMinutes(0);
    openModalHandler("show");
  };

  const AbortHandler = (event, uid, data) => {
    setGame_id(data.gid);
    setActionType("abort");
    openModalHandler("show");
  };

  const modalHandleClose = () => {
    closeModalHandler();
    setGame_id();
    setActionType();
    getMockGame();
  };

  const handleModalStart = async () => {
    try {
      if (validateInput(fastForwardMinutesInput)) {
        await props.mockGameReplyStart({
          url:
            `/v1/nba/admin/data/recorded_games/replay/${game_id}` +
            (parseInt(fastForwardMinutesInput)
              ? `?fast_forward_minutes=${fastForwardMinutesInput}`
              : ""),
          method: "get",
        });
        modalHandleClose();
      }
    } catch (error) {}
  };

  const handleModalAbort = async () => {
    await props.mockGameReplyAbort({
      url: `v1/nba/admin/data/recorded_games/abort/${game_id}`,
      method: "post",
    });
    modalHandleClose();
  };

  const actions = [
    {
      id: "start",
      label: "Start",
      icon: <PlayCircleOutlineIcon fontSize="small" data-testid="start-btn" />,
      handler: StartHandler,
      showAction: ({ mockgame_running }) => mockgame_running === "False",
    },
    {
      id: "abort",
      label: "Abort",
      icon: <HighlightOffSharpIcon fontSize="small" data-testid="abort-btn" />,
      handler: AbortHandler,
      showAction: ({ mockgame_running }) => mockgame_running === "True",
    },
  ];

  const extraInfo = [
    { label: "Home Team", id: "home_team" },
    { label: "Visitor Team", id: "visitor_team" },
    { label: "Created At", id: "created_at", type: "date" },
  ];

  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [fastForwardMinutesInput]);

  return (
    <Fragment>
      <PanelHeader
        title="Mock Game"
        breadCrumbItems={[
          {
            title: "Mock Games",
          },
        ]}
        refreshHandler={getMockGame}
        search={true}
      />

      <TableView
        name={"Mock Game"}
        actionType={actionType}
        data={mockData}
        isLoading={isLoading}
        actions={actions}
        totalCount={mockCount}
        handleModalClose={modalHandleClose}
        handleModalStart={handleModalStart}
        handleModalAbort={handleModalAbort}
        collapsibleComponent={<Collapsible />}
        openModal={show}
        isSorting
        extraInfo={extraInfo}
        headings={headings}
        checkbox={false}
        actionMenu={false}
        setMinutes={setMinutes}
        inputError={inputError}
      />
    </Fragment>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getMockGames: payload => dispatch(getMockGames(payload)),
    mockGameReplyStart: payload => dispatch(requestAPI(payload)),
    mockGameReplyAbort: payload => dispatch(requestAPI(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(MockGames);

export { Container, MockGames as Component };
