import { Menu, MenuItem } from "./Menu.style";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";

const MenuOptions = ({
  id,
  icon,
  menuItems,
  renderComponent,
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = event => {
    event.preventDefault();
    setAnchorEl(null);
  };
  return (
    <div {...restProps}>
      {icon ? (
        <IconButton data-testid={`${id}-open`} onClick={handleMenuOpen}>
          {icon}
        </IconButton>
      ) : (
        <div onClick={handleMenuOpen}>{renderComponent()}</div>
      )}
      <Menu
        id="simple-menu"
        data-testid={`${id}`}
        className="envActionPopover"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems.map(
          ({ handler, icon, name, showItem }) =>
            showItem() && (
              <MenuItem
                key={name}
                onClick={event => {
                  event.preventDefault();
                  handleMenuClose(event);
                  handler(event);
                }}
                data-testid={`${id}-${name.split(" ").join("-")}`}
              >
                {icon ? (
                  <>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <Typography variant="inherit">{name}</Typography>
                  </>
                ) : (
                  name
                )}
              </MenuItem>
            ),
        )}
      </Menu>
    </div>
  );
};

export default MenuOptions;
