import { createSlice } from "@reduxjs/toolkit";
import { FEATURES_ARRAY } from "utils/constants";

const initialFeatureState = {};
FEATURES_ARRAY.map(feature => {
  if (!["test_adaptor", "airship", "firebase"].includes(feature.featureName))
    Object.assign(initialFeatureState, {
      [`${feature.featureName}Enable`]: false,
    });
  return null;
});

export const applicationSlice = createSlice({
  name: "application",
  initialState: {
    access_type: "",
    name: "",
    sport_key: "",
    env_key: "",
    isOwner: null,
    team_name: "",
    tid: "",
    master_key: "",
    league_name: "",
    sport_type: "",
    nbaEnable: false,
    ...initialFeatureState,
  },
  reducers: {
    applicationAction: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setNbaFlag: (state, { payload }) => {
      return {
        ...state,
        nbaEnable: payload.nbaEnabled,
      };
    },
    setFeatureFlag: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    clearApplicationState: () => {
      return {
        access_type: "",
        name: "",
        sport_key: "",
        env_key: "",
        isOwner: null,
        team_name: "",
        tid: "",
        master_key: "",
        league_name: "",
        sport_type: "",
        nbaEnable: false,
        ...initialFeatureState,
      };
    },
  },
});

export const {
  applicationAction,
  clearApplicationState,
  setNbaFlag,
  setFeatureFlag,
} = applicationSlice.actions;

export default applicationSlice.reducer;
