import { Get, Update } from "app/pages/Dashboard/appUsers";
import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";

const AppUsersRoute = props => {
  return (
    <>
      <Switch>
        {/* 
        redirected /add to initial /app-users route
        Note : remove this if add is enabled
        */}
        <Redirect
          from={`${props.match.path}/add`}
          to={`${props.match.url}`}
          exact
        />

        {/* disabled add */}
        {/* <Route exact path={`${props.match.path}/add`} component={Add} /> */}
        <Route
          exact
          path={`${props.match.path}/update/:uid`}
          component={Update}
        />
        <Route path={`${props.match.path}`} component={Get} />
      </Switch>
    </>
  );
};

export default AppUsersRoute;
