import {
  Box,
  Card,
  CardContent,
  MUITypography,
  SelectType,
  TopGrid,
} from "./SelectFeatureType.style";
import { Button, Modal } from "@dfep/ui-component";
import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { cloneDeep } from "lodash";
import { Constants } from "utils/constants";
import { createRecord } from "services/createObject";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { get } from "services/getObject";
import { getInteractiveMapFeatures } from "services/getObjects";
import { Grid, IconButton } from "@material-ui/core";
import { MapMode } from "../../Maps/Map";
import { NoData } from "styles/global.style";
import { showNotifier } from "store/reducers/notifierSlice";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExportIcon from "@material-ui/icons/CloudDownloadOutlined";
import ImportIcon from "@material-ui/icons/CloudUploadOutlined";
import Label from "app/components/Common/Label";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";
import Tooltip from "@material-ui/core/Tooltip";

const headings = [
  {
    id: "geometry.type",
    label: "Type",
    formator: data =>
      data.geometry.type !== "Point"
        ? data.geometry.type
        : data.properties.type,
  },
  {
    id: "properties.draw_layer",
    label: "Draw layer",
    formator: data => data.properties.draw_layer,
  },
  {
    id: "properties.venue_id",
    label: "Venue ID",
    formator: data => data.properties.venue_id,
  },
  { id: "created_at", label: "Created At", type: "date" },
  { id: "updated_at", label: "Updated At", type: "date" },
];

const Logo = ({ src, name }) => {
  return <img src={src} alt={name} />;
};

const GetFeatures = ({ match, history, location }) => {
  const { access_type } = useSelector(state => state.application);
  const { READ_ONLY } = Constants;

  //States
  const [features, setFeatures] = useState([]);
  const [featureObj, setFeatureObj] = useState(null);
  const [dataId, setUid] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [featureCount, setFeatureCount] = useState(0);
  const [selected, setSelected] = useState([]); //for checkboxes

  //handle modals
  const {
    show,
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  // Params
  const { sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { page, skip, limit, sort, value, searchText } = useQueryParams();

  // Functions

  //Handle Single Delete
  const handleModalClose = () => {
    closeModalHandler();
    setUid("");
    setFeatureObj();
  };
  const handleDelete = async (event, uid) => {
    event.stopPropagation();
    await dispatch(
      deleteRecord({
        url: `/v1/admin/interactive_maps/features/${uid}`,
      }),
    );
    setSelected([]);
    handleModalClose();
    getFeatures();
  };

  // Handle Multi Delete
  const multipleDeleteHandler = event => {
    openModalHandler("showMultipleDelete");
  };
  const deleteMultiple = async () => {
    await dispatch(
      deleteRecords({
        url: `/v1/admin/interactive_maps/features`,
        uids: selected,
      }),
    );
    setSelected([]);
    getFeatures();
    handleModalClose();
  };

  // Handle checkboxes
  const handleSingleClick = (event, dataId) => {
    const newSingleSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSingleSelected);
  };

  const handleSelectAllClick = event => {
    const newMultiSelected = checkboxSelectAllClick({ event, data: features });
    setSelected(newMultiSelected);
  };

  // Get Features API Call
  const getFeatures = async () => {
    setLoading(true);
    setSelected([]);

    const {
      data: { features, count },
    } = await dispatch(
      getInteractiveMapFeatures({
        skip,
        limit,
        sort,
        sortValue: value,
        searchText,
      }),
    );

    if (count !== 0 && features.length === 0) {
      initialRoute(history);
    } else {
      setFeatures([...features]);
      setFeatureCount(count);
      setLoading(false);
    }
  };

  // const exportFeatures = async event => {
  //   // get features locally
  //   console.log("Export feature clicked");
  //   const { data } = await dispatch(
  //     get({ url: `/v1/admin/interactive_maps/features/export` }),
  //   );
  //   const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", `features.json`);
  //   document.body.appendChild(link);
  //   link.click();
  // };

  // const importFeaturesReq = async features => {
  //   // import Api Call

  //   console.log("calling import feature API", features);
  //   // await dispatch(
  //   //   createRecord({
  //   //     url: `/v1/admin/interactive_maps/features/export`,
  //   //     key: "features",
  //   //     form: features,
  //   //   }),
  //   // );

  //   getFeatures();
  // };

  // const retrieveFiles = files => {
  //   //Convert and validate the Json data to js
  //   Array.from(files).forEach(file => {
  //     file.progress = 0;
  //     /*istanbul ignore next*/
  //     if (file.type === "application/json") {
  //       var reader = new FileReader();
  //       reader.readAsText(file);

  //       reader.onloadend = () => {
  //         try {
  //           /*istanbul ignore next*/

  //           const featuresArray = cloneDeep(
  //             JSON.parse(JSON.parse(JSON.stringify(reader.result))),
  //           );
  //           console.log("importing features =>\n", featuresArray);
  //           for (let i = 0, to = featuresArray.length; i < to; i++) {
  //             console.log("singleData =>", featuresArray[i]);
  //             if (
  //               featuresArray[i].hasOwnProperty("geometry") &&
  //               featuresArray[i].hasOwnProperty("type") &&
  //               featuresArray[i].hasOwnProperty("properties")
  //             ) {
  //               if (featuresArray[i]["type"] !== "Feature")
  //                 throw new Error("Feature object type should be 'Feature'");
  //             } else {
  //               console.log("data to be import ...=>\n", featuresArray[i]);
  //               throw new Error("Provided Json is not valid to import");
  //             }
  //           }
  //           importFeaturesReq(featuresArray);
  //         } catch (error) {
  //           /*istanbul ignore next*/
  //           dispatch(
  //             showNotifier({
  //               open: true,
  //               message: error.message,
  //               errors: {},
  //               variant: "error",
  //             }),
  //           );
  //         }
  //       };
  //     }
  //   });
  // };

  // Component Life Cycle
  useEffect(() => {
    getFeatures();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, skip, limit, sort, value, searchText]);

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: (
        <EditIcon
          fontSize="small"
          data-testid="edit_btn"
          aria-label="Edit button"
        />
      ),
      handler: (event, uid) => {
        push({
          pathname: `/dashboard/${sport_key}/${env_key}/interactive-maps/features/update/${uid}`,
          state: { from: location },
        });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: (
        <DeleteIcon
          fontSize="small"
          data-testid="delete_btn"
          aria-label="Delete button"
        />
      ),
      handler: (event, uid, dataItem) => {
        setFeatureObj(dataItem);
        setUid(uid);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const featureTypeModal = [
    {
      id: 1,
      icon: (
        <Logo
          name={MapMode.ADD_POI}
          src={"/static/map/admin/icon_map_location.d0d9502bcf31.png"}
        />
      ),
      title: "POI",
      testid: "poi-btn",
      content: "Create Point poi feature for the interactive map",
      handler: () => {
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/interactive-maps/features/add`,
          search: `?type=${MapMode.ADD_POI}`,
          state: { from: location },
        });
      },
    },
    {
      id: 2,
      icon: (
        <Logo
          name={MapMode.ADD_TEXT}
          src={"/static/map/admin/icon_text.e51f4614c753.png"}
        />
      ),
      title: "Text",
      testid: "text-btn",
      content: "Create Text poi feature for the interactive map",
      handler: () => {
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/interactive-maps/features/add`,
          search: `?type=${MapMode.ADD_TEXT}`,
          state: { from: location },
        });
      },
    },
    {
      id: 3,
      icon: (
        <Logo
          name={MapMode.ADD_IMAGE}
          src={"/static/map/admin/icon_photo.3ec0f3537b47.png"}
        />
      ),
      title: "Image",
      testid: "image-btn",
      content: "Create Image poi feature for the interactive map",
      handler: () => {
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/interactive-maps/features/add`,
          search: `?type=${MapMode.ADD_IMAGE}`,
          state: { from: location },
        });
      },
    },
    {
      id: 4,
      icon: (
        <Logo
          name={MapMode.ADD_LINE}
          src={"/static/map/admin/icon_draw_line.d5fab4ec313c.png"}
        />
      ),
      title: "LineString",
      testid: "linestring-btn",
      content: "Create Line feature for the interactive map",
      handler: () => {
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/interactive-maps/features/add`,
          search: `?type=${MapMode.ADD_LINE}`,
          state: { from: location },
        });
      },
    },
    {
      id: 5,
      icon: (
        <Logo
          name={MapMode.ADD_POLYGON}
          src={"/static/map/admin/icon_shape_tool.1975a914b299.png"}
        />
      ),
      title: "Polygon",
      testid: "polygon-btn",
      content: "Create Polygon feature for the interactive map",
      handler: () => {
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/interactive-maps/features/add`,
          search: `?type=${MapMode.ADD_POLYGON}`,
          state: { from: location },
        });
      },
    },
  ];

  return (
    <>
      <PanelHeader
        title="Interactive Map Features"
        refreshHandler={getFeatures}
        data-testid="panel-header"
        breadCrumbItems={[
          {
            title: "Interactive Map Features",
          },
        ]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            data-testid="Add-btn"
            color="primary"
            variant="contained"
            onClick={() => {
              openModalHandler("show");
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
        {/* <Button
          data-testid="Export-btn"
          color="primary"
          variant="contained"
          onClick={() => {
            exportFeatures();
          }}
          startIcon={<ExportIcon />}
        >
          Export
        </Button>

        <input
          accept=".json"
          data-testid="import-features"
          id="json-file-upload-input"
          type="file"
          onChange={e => {

            if (e.target.files.length > 0) {
              retrieveFiles(e.target.files);
              e.target.value = null;
            }
          }}
          hidden
        />
        {access_type !== Constants.READ_ONLY && (
          <Label
            htmlFor="json-file-upload-input"
            className="json-file-upload-input"
          >
            <Tooltip title="Import">
              <IconButton color="primary" component="span">
                <ImportIcon />
              </IconButton>
            </Tooltip>
          </Label>
        )} */}
      </PanelHeader>
      {isLoading && (
        <SkeletonTable
          headings={headings}
          name="Interactive Map Features"
          actions={[
            {
              id: "edit",
              label: "Edit",
              icon: (
                <EditIcon
                  fontSize="small"
                  data-testid="edit-btn"
                  aria-label="Edit button"
                />
              ),
            },
            {
              id: "delete",
              label: "Delete",
              icon: (
                <DeleteIcon
                  fontSize="small"
                  data-testid="delete-btn"
                  aria-label="Delete button"
                />
              ),
            },
          ]}
          checkbox={true}
        />
      )}
      {features.length >= 1 && isLoading === false ? (
        <Table
          data-testid="table"
          name="Interactive Map Features"
          headings={headings}
          actions={actions}
          data={features}
          isSorting
          limit={10}
          totalCount={featureCount}
          checkbox={access_type !== READ_ONLY}
          handleSelectAllClick={handleSelectAllClick}
          handleSingleClick={handleSingleClick}
          selectedList={selected}
          numSelected={selected.length}
          multipleDeleteHandler={multipleDeleteHandler}
        />
      ) : (
        !isLoading && <NoData data-testid="empty-data">No Data Found</NoData>
      )}
      <SelectType
        open={show}
        handleClose={closeModalHandler}
        title="Select Feature Type"
        className="text"
      >
        <IconButton aria-label="close" onClick={closeModalHandler}>
          <CloseIcon />
        </IconButton>
        <TopGrid container className="cards-container">
          {featureTypeModal.map(item => {
            return (
              <Grid item md={4} sm={4} xs={12} className="cards" key={item.id}>
                <Card
                  onClick={item.handler}
                  data-testid={item.testid}
                  variant="outlined"
                >
                  <CardContent>
                    <Box component="div">{item.icon}</Box>
                    <MUITypography
                      className="card-title"
                      gutterBottom
                      variant="h5"
                    >
                      {item.title}
                    </MUITypography>
                    <MUITypography gutterBottom variant="body1" align="center">
                      {item.content}
                    </MUITypography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </TopGrid>
      </SelectType>
      <Modal
        className="small"
        data-testid="delete-modal"
        open={showDelete}
        handleClose={handleModalClose}
        title="Delete Feature"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "No",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "no_btn",
            },
          },
          {
            id: "2",
            name: "Yes",
            handler: event => {
              return handleDelete(event, dataId);
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "yes_btn",
            },
          },
        ]}
      >
        {featureObj &&
          deleteConfirmationMessage({
            name: featureObj.properties.type
              ? featureObj.properties.type
              : featureObj.geometry.type,
            module: "Interactive Map Features",
          })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        handleClose={handleModalClose}
        title="Delete Features"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "confirm_multi_del",
            },
          },
        ]}
      >
        Are you sure want to delete <strong>{selected.length}</strong> items?
      </Modal>
    </>
  );
};

export default GetFeatures;
