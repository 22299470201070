import { Redirect, Route, Switch } from "react-router-dom";
import Config from "app/pages/Dashboard/Data/Device/Config";
import Eula from "app/pages/Dashboard/Data/Device/Eula";
import Menu from "app/pages/Dashboard/Data/Device/Menu";
import Messages from "app/pages/Dashboard/Data/Device/Messages";
import onBoardings from "app/pages/Dashboard/Data/Device/Onboardings";
import React from "react";
import Settings from "app/pages/Dashboard/Data/Device/Settings";
const Device = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.url}`}
        to={`${props.match.url}/config`}
        exact
      />
      <Route path={`${props.match.path}/config`} component={Config} />
      <Route path={`${props.match.path}/menu`} component={Menu} />
      <Route path={`${props.match.path}/setting`} component={Settings} />
      <Route path={`${props.match.path}/eula`} component={Eula} />
      <Route path={`${props.match.path}/messages`} component={Messages} />
      <Route path={`${props.match.path}/onBoarding`} component={onBoardings} />
    </Switch>
  );
};

export default Device;
