/* istanbul ignore file */
import "./Map.css";
import { createRecord } from "services/createObject";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { deleteMapRecord } from "services/deleteObject";
import { getLevelsData as getLevelDataRequest } from "services/getObjects";
import { updateRecord } from "services/updateObject";
import FeatureModal, { FeatureRequestModal } from "../Modal";
import Levels from "../Levels";
import Loader from "../Loader";
import React from "react";
import ReactDOM from "react-dom";

export const MapMode = {
  ADD_POI: "ADD_POI",
  ADD_TEXT: "ADD_TEXT",
  ADD_IMAGE: "ADD_IMAGE",
  ADD_POLYGON: "ADD_POLYGON",
  ADD_LINE: "ADD_LINE",
  MOVE: "MOVE",
  RESIZE: "RESIZE",
  ROTATE: "ROTATE",
  DEFAULT: "DEFAULT",
};

const initialModalState = {
  defaultValues: null,
  isOpenModal: false,
  mapMode: MapMode.DEFAULT,
  modalMode: "Add",
};

const mapStyle = [
  {
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
];
const mapDivStyle = { height: "86vh" };

const TILE_SIZE = 256;

function setBoundaries({
  map,
  coordinates: { southwest_coordinate, northeast_coordinate },
}) {
  const southWestLatLng = new window.google.maps.LatLng(
    southwest_coordinate.coordinates[0],
    southwest_coordinate.coordinates[1],
  );
  const northEastLatLng = new window.google.maps.LatLng(
    northeast_coordinate.coordinates[0],
    northeast_coordinate.coordinates[1],
  );
  map.fitBounds(
    new window.google.maps.LatLngBounds(southWestLatLng, northEastLatLng),
  );
  return null;
}

function addLayers({
  map,
  layers: { showTraffic = false, showTransit = false, showBicycling = false },
}) {
  if (showTraffic) {
    const trafficLayer = new window.google.maps.TrafficLayer();
    trafficLayer.setMap(map);
  }
  if (showTransit) {
    const transitLayer = new window.google.maps.TransitLayer();
    transitLayer.setMap(map);
  }
  if (showBicycling) {
    const bicyclingLayer = new window.google.maps.BicyclingLayer();
    bicyclingLayer.setMap(map);
  }
}

function initializeTextOverlay() {
  window.TextOverlay = function ({ feature, map, selectedFeature }) {
    // Initialize all properties.
    this.feature = feature;
    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    const division = document.createElement("div");
    division.style.borderStyle = "none";
    division.style.borderWidth = "0px";
    division.style.position = "absolute";
    division.style.width = "auto";
    division.style.cursor = "pointer";
    division.draggable = true;

    this.div = division;
    this.amenitiesMap = map;
    this._selectedFeature = selectedFeature;

    // Explicitly call setMap on this overlay.
    this.setMap(map);
  };

  window.TextOverlay.prototype = new window.google.maps.OverlayView();

  window.TextOverlay.prototype.onAdd = function () {
    // Add the element to the "overlayLayer" pane.
    const panes = this.getPanes();
    // TODO: try it with panes.overlayLayer.appendChild()
    panes.overlayMouseTarget.appendChild(this.div);
  };

  window.TextOverlay.prototype.draw = function () {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    const overlayProjection = this.getProjection();

    if (overlayProjection !== undefined && overlayProjection) {
      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      const center = overlayProjection.fromLatLngToDivPixel(
        this.feature.getGeometry().get(),
      );
      const bottomBound = new window.google.maps.LatLng(
        this.feature.getGeometry().get().lat() +
          parseFloat(this.feature.getProperty("size")),
        this.feature.getGeometry().get().lng(),
      );
      const height = Math.round(
        center.y - overlayProjection.fromLatLngToDivPixel(bottomBound).y,
      );

      const borderSize = this.feature === this._selectedFeature.current ? 1 : 0;

      // Resize the image's div to fit the indicated dimensions.
      const div = this.div;
      div.textContent = this.feature.getProperty("name");
      div.style.fontSize = height + "px";
      div.style.whiteSpace = "nowrap";
      div.style.border = borderSize + "px dotted black";
      const width =
        Math.round(getTextWidth(div.textContent, height + "pt Arial")) + 1;
      div.style.height = height + "px";
      div.style.lineHeight = height + "px";
      div.style.width = width + "px";
      div.style.left = center.x - (width + borderSize * 2) / 2 + "px";
      div.style.top = center.y - (height + borderSize * 2) / 2 + "px";
      div.style.textAlign = "center";
      div.style.zIndex =
        -10000 + parseInt(this.feature.getProperty("draw_layer"));
      div.style.transform =
        "rotate(" + this.feature.getProperty("rotation") + "deg)";
      div.style.color = this.feature.getProperty("text_color");
      div.style.display = "block";
      div.style.visibility =
        this.feature.getProperty("hidden") === true ? "hidden" : "visible";
    }
  };

  window.TextOverlay.prototype.onRemove = function () {
    this.div.parentNode.removeChild(this.div);
    // this.div = null;
  };

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return null;
}

function initializeImageOverlay() {
  window.ImageOverlay = function ({ feature, map, selectedFeature }) {
    // Initialize all properties.
    this.feature = feature;

    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    const division = document.createElement("div");
    division.style.borderStyle = "none";
    division.style.borderWidth = "0px";
    division.style.position = "absolute";
    division.style.cursor = "pointer";

    this.div = division;
    this._selectedFeature = selectedFeature;
    this._last_url = null;

    // Explicitly call setMap on this overlay.
    this.setMap(map);
  };
  window.ImageOverlay.prototype = new window.google.maps.OverlayView();

  window.ImageOverlay.prototype.onAdd = function () {
    this.reloadImage();
    // Add the element to the "overlayLayer" pane.
    const panes = this.getPanes();
    // TODO: try it with panes.overlayLayer.appendChild()
    panes.overlayMouseTarget.appendChild(this.div);
  };

  window.ImageOverlay.prototype.draw = function () {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    const overlayProjection = this.getProjection();

    if (overlayProjection) {
      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      const center = overlayProjection.fromLatLngToDivPixel(
        this.feature.getGeometry().get(),
      );
      const bottomBound = new window.google.maps.LatLng(
        this.feature.getGeometry().get().lat() +
          parseFloat(this.feature.getProperty("size")),
        this.feature.getGeometry().get().lng(),
      );
      const height = Math.round(
        center.y - overlayProjection.fromLatLngToDivPixel(bottomBound).y,
      );

      // Resize the image's div to fit the indicated dimensions.
      const div = this.div;

      const self = this;
      if (this.image_loaded) {
        if (this.feature.getProperty("name") !== this._last_url) {
          this.reloadImage();
          return;
        }
        const borderSize =
          this.feature === this._selectedFeature.current ? 1 : 0;
        const width = Math.round(height * this.imageAspectRatio);
        div.style.height = height + "px";
        div.style.width = width + "px";
        div.style.border = borderSize + "px dotted black";
        div.style.backgroundImage =
          "url('" + this.feature.getProperty("name") + "')";
        div.style.backgroundSize = "cover";
        div.style.backgroundRepeat = "no-repeat";
        div.style.left = center.x - (width + borderSize * 2) / 2 + "px";
        div.style.top = center.y - (height + borderSize * 2) / 2 + "px";
        div.style.zIndex =
          -10000 + parseInt(self.feature.getProperty("draw_layer"));
        div.style.transform =
          "rotate(" + self.feature.getProperty("rotation") + "deg)";
        div.style.display = "block";
        div.style.visibility =
          this.feature.getProperty("hidden") === true ? "hidden" : "visible";
      }
    }
  };

  window.ImageOverlay.prototype.reloadImage = function () {
    this.image_loaded = false;
    const self = this;
    const image = document.createElement("IMG");
    const imageUrl = this.feature.getProperty("name");
    image.src = imageUrl;
    image.onload = function () {
      self._last_url = imageUrl;
      self.image_loaded = true;
      self.imageAspectRatio = image.naturalWidth / image.naturalHeight;
      self.draw();
    };
  };

  window.ImageOverlay.prototype.onRemove = function () {
    this.div.parentNode.removeChild(this.div);
    // this.div = null;
  };
  return null;
}

// TODO: add comment from googlemaps sample project
function project(latLng) {
  let siny = Math.sin((latLng.lat() * Math.PI) / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  return new window.google.maps.Point(
    TILE_SIZE * (0.5 + latLng.lng() / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  );
}

function add_zoom_changed_listner(map) {
  map.addListener("zoom_changed", function () {
    map.data.forEach(function (feature) {
      if (
        feature.getGeometry().getType() === "Point" &&
        feature.getProperty("type") === "poi"
      ) {
        map.data.overrideStyle(feature, {});
      }
    });
  });
}

function EditSaveButtonControl() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
      }}
    >
      <button className="map-submit-button danger" id="delete-button" disabled>
        Delete feature
      </button>
      <button className="map-submit-button primary" id="edit-button" disabled>
        Edit feature
      </button>
    </div>
  );
}

const editFeatureHandler = ({
  map,
  mapMode,
  selectedFeature,
  setModalState,
}) => {
  switch (selectedFeature.current.getGeometry().getType()) {
    case "Point":
      switch (selectedFeature.current.getProperty("type")) {
        case "poi":
          mapMode.current = MapMode.ADD_POI;
          break;
        case "text":
          mapMode.current = MapMode.ADD_TEXT;
          break;
        case "image":
          mapMode.current = MapMode.ADD_IMAGE;
          break;
        default:
          break;
      }
      break;
    case "LineString":
      mapMode.current = MapMode.ADD_LINE;
      break;
    case "Polygon":
      mapMode.current = MapMode.ADD_POLYGON;
      break;
    default:
      break;
  }
  featureModalHandler({
    feature: selectedFeature.current,
    map,
    mapMode,
    selectedFeature,
    setModalState,
  });
};

function verifyFeatureSaveChange({ updatedFeatureData, saveModalHandler }) {
  if (updatedFeatureData.current) {
    saveModalHandler();
    return true;
  } else return false;
}

function refreshEditButtons({ selectedFeature }) {
  document
    .querySelectorAll("#move,#resize,#rotate,#action-divider")
    .forEach(node => (node.style.display = "none"));

  if (selectedFeature.current) {
    document.getElementById("edit-button").removeAttribute("disabled");
    document.getElementById("delete-button").removeAttribute("disabled");
    switch (selectedFeature.current.getGeometry().getType()) {
      case "Point":
        document
          .querySelectorAll("#move,#resize,#action-divider")
          .forEach(element => {
            element.style.display = "block";
          });
        switch (selectedFeature.current.getProperty("type")) {
          case "image":
          case "text":
            document.getElementById("rotate").style.display = "block";
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }
  } else {
    document.getElementById("edit-button").setAttribute("disabled", "disabled");
    document
      .getElementById("delete-button")
      .setAttribute("disabled", "disabled");
  }
}

function selectFeature({ feature, selectedFeature, map }) {
  let previousFeature;
  if (selectedFeature.current !== feature) {
    previousFeature = selectedFeature.current;
    selectedFeature.current = feature;
    if (feature != null) {
      if (selectedFeature.current.overlay !== undefined)
        selectedFeature.current.overlay.draw();
      else map.data.overrideStyle(selectedFeature.current, {});
    }
    if (previousFeature && previousFeature.overlay !== undefined)
      previousFeature.overlay.draw();
    else map.data.overrideStyle(previousFeature, {});
    // previousFeature && previousFeature.setProperty("selected", false);
  } else {
    previousFeature = selectedFeature.current;
    selectedFeature.current = null;
    if (previousFeature && previousFeature.overlay !== undefined)
      previousFeature.overlay.draw();
    else map.data.overrideStyle(previousFeature, {});
  }
  refreshEditButtons({ selectedFeature });
}

function add_feature_click_listner({
  map,
  updatedFeatureData,
  saveModalHandler,
  selectedFeature,
}) {
  map.data.addListener("click", function (featureInfo) {
    if (
      !verifyFeatureSaveChange({
        updatedFeatureData,
        saveModalHandler,
      })
    )
      selectFeature({ feature: featureInfo.feature, selectedFeature, map });
  });
}

function add_removeAllFeatures_method({ map, nonGeoJsonFeatures }) {
  map.removeAllFeatures = function () {
    map.data.forEach(function (feature) {
      map.data.remove(feature);
    });
    nonGeoJsonFeatures.current.forEach(function (feature) {
      feature.overlay.setMap(null);
    });
    nonGeoJsonFeatures.current = [];
  };
}

function setMapStyle({ map, mapMode, categoryMap, levels, selectedFeature }) {
  map.data.setStyle(function (feature) {
    // inspired from https://developers.google.com/maps/documentation/javascript/datalayer#style_options
    if (feature.getGeometry() != null) {
      switch (feature.getGeometry().getType()) {
        case "Polygon":
        case "MultiPolygon":
          return {
            fillOpacity: feature.getProperty("fill_opacity") || 0.7,
            fillColor: feature.getProperty("fill_color") || "#000000",
            strokeColor: feature.getProperty("stroke_color") || "#000000",
            strokeOpacity: feature.getProperty("stroke_opacity") || 1,
            strokeWeight: feature.getProperty("stroke_weight") || 1,
            editable: selectedFeature.current === feature,
            draggable: false,
            clickable:
              feature === selectedFeature.current ||
              mapMode.current !== MapMode.MOVE,
            visible: feature.getProperty("hidden") !== true,
            zIndex:
              -10000 +
              (!isNaN(parseInt(feature.getProperty("draw_layer")))
                ? parseInt(feature.getProperty("draw_layer"))
                : 0),
          };
        case "LineString":
        case "MultiLineString":
          return {
            strokeColor: feature.getProperty("stroke_color") || "#000000",
            strokeOpacity: feature.getProperty("stroke_opacity") || 0.8,
            strokeWeight: feature.getProperty("stroke_weight") || 2,
            editable: selectedFeature.current === feature,
            draggable: false,
            clickable:
              feature === selectedFeature.current ||
              mapMode.current !== MapMode.MOVE,
            visible: feature.getProperty("hidden") !== true,
            zIndex:
              -10000 +
              (!isNaN(parseInt(feature.getProperty("draw_layer")))
                ? parseInt(feature.getProperty("draw_layer"))
                : 0),
          };
        case "Point":
          if (
            feature.getProperty("uid") === undefined &&
            feature.getProperty("category_id") === undefined
          )
            return { visible: true };
          return {
            icon: getPoiMarker({
              feature,
              selectedFeature,
              categoryMap,
              map,
            }),
            title:
              (feature.getProperty("name") || "") +
              "\n\n" +
              getLevelName({
                level_ids: feature.getProperty("level_ids"),
                levels: levels,
              }),
            draggable: selectedFeature.current === feature,
            visible: feature.getProperty("hidden") !== true,
            clickable:
              feature === selectedFeature.current ||
              mapMode.current !== MapMode.MOVE,
            zIndex:
              feature === selectedFeature.current
                ? 0
                : -10000 + parseInt(feature.getProperty("draw_layer")),
          };
        default:
          return {};
      }
    }
  });
}

function addFeature({
  map,
  geoJSON,
  nonGeoJsonFeatures,
  selectedFeature,
  updatedFeatureData,
  saveModalHandler,
}) {
  switch (geoJSON.geometry.type) {
    case "Polygon":
    case "MultiPolygon":
    case "LineString":
    case "MultiLineString":
      map.data.addGeoJson(geoJSON);
      break;
    case "Point":
      switch (geoJSON.properties.type) {
        case "poi":
          map.data.addGeoJson(geoJSON);
          break;
        case "text":
          const textFeature = new window.google.maps.Data.Feature({
            geometry: {
              lat: geoJSON.geometry.coordinates[1],
              lng: geoJSON.geometry.coordinates[0],
            },
            properties: geoJSON.properties,
          });
          textFeature.overlay = new window.TextOverlay({
            feature: textFeature,
            map,
            selectedFeature,
          });
          textFeature.overlay.div.addEventListener("click", function (e) {
            e.stopPropagation();
            if (
              !verifyFeatureSaveChange({
                updatedFeatureData,
                saveModalHandler,
              })
            )
              selectFeature({
                feature: textFeature,
                map,
                selectedFeature,
              });
          });
          featureSetListner({ feature: textFeature, map });
          nonGeoJsonFeatures.current.push(textFeature);
          return textFeature;
        case "image":
          const imageFeature = new window.google.maps.Data.Feature({
            geometry: {
              lat: geoJSON.geometry.coordinates[1],
              lng: geoJSON.geometry.coordinates[0],
            },
            properties: geoJSON.properties,
          });
          imageFeature.overlay = new window.ImageOverlay({
            feature: imageFeature,
            map,
            selectedFeature,
          });
          imageFeature.overlay.div.addEventListener("click", function (e) {
            e.stopPropagation();
            if (
              !verifyFeatureSaveChange({
                updatedFeatureData,
                saveModalHandler,
              })
            )
              selectFeature({
                feature: imageFeature,
                map,
                selectedFeature,
              });
          });
          featureSetListner({ feature: imageFeature, map });
          nonGeoJsonFeatures.current.push(imageFeature);
          return imageFeature;
        default:
          break;
      }
      break;
    default:
      break;
  }
}

function getPoiMarker({ feature, categoryMap, selectedFeature, map }) {
  const category = categoryMap[feature.getProperty("category_id")];
  const iconInfo =
    feature === selectedFeature.current
      ? category.selected_icon
      : category.default_icon;

  let height;
  let latHeight = feature.getProperty("size");
  if (feature.getProperty("size") == null) {
    latHeight = 8e-5;
  }

  if (latHeight != null) {
    if (feature === selectedFeature.current) {
      latHeight = latHeight * parseFloat(3.0);
    }
    const scale = 1 << map.getZoom();
    const startCoordinate = feature.getGeometry().get();
    const endCoordinate = new window.google.maps.LatLng(
      feature.getGeometry().get().lat() + latHeight,
      feature.getGeometry().get().lng(),
    );
    const startPixel = project(startCoordinate);
    const endPixel = project(endCoordinate);
    height = Math.abs(Math.round(scale * (endPixel.y - startPixel.y)));
  } else {
    height = 30;
    if (feature === selectedFeature.current) {
      height = height * parseFloat(3.0);
    }
  }

  let size;
  let image;
  if (feature === selectedFeature.current) {
    if (category.selectedImage === undefined) {
      category.selectedImage = new Image();
      category.selectedImage.src = iconInfo;
    }
    image = category.selectedImage;
  } else {
    if (category.image === undefined) {
      category.image = new Image();
      category.image.src = iconInfo;
    }
    image = category.image;
  }

  if (image.complete) {
    const aspectRatio = image.width / image.height;
    size = new window.google.maps.Size(aspectRatio * height, height);
  } else {
    image.addEventListener("load", function () {
      map.data.overrideStyle(feature, {});
    });
    size = new window.google.maps.Size(0, 0);
  }

  const anchor =
    feature === selectedFeature.current
      ? null
      : new window.google.maps.Point(size.width / 2, size.height / 2);

  return {
    url: iconInfo,
    scaledSize: size,
    anchor: anchor,
  };
}

function getLevelName({ level_ids = [], levels = [] }) {
  return level_ids
    .map(id => levels.find(level => level.id === id)?.name || null)
    .join("\n");
}

function changeMapMode({
  mode,
  mapMode,
  map,
  selectedFeature,
  moveClickListener,
  updatedFeatureData,
  saveModalHandler,
}) {
  document.querySelectorAll(".action-button").forEach(node => {
    node.style.backgroundColor = "";
  });
  if (
    mode === mapMode.current ||
    ((mode === MapMode.MOVE ||
      mode === MapMode.RESIZE ||
      mode === MapMode.ROTATE) &&
      selectedFeature.current === null)
  ) {
    mode = MapMode.DEFAULT;
  }
  const previousMode = mapMode.current;

  mapMode.current = mode;
  if (previousMode === MapMode.MOVE) {
    map.data.forEach(function (feature) {
      map.data.overrideStyle(feature, {});
    });
  }
  document.querySelector(`.action-button[map-mode=${mode}]`) &&
    (document.querySelector(
      `.action-button[map-mode=${mode}]`,
    ).style.backgroundColor = "rgb(0 110 0)");

  if (moveClickListener && moveClickListener.current) {
    moveClickListener.current.remove();
    map.setOptions({ draggableCursor: null });
  }

  if (updatedFeatureData && saveModalHandler)
    verifyFeatureSaveChange({ updatedFeatureData, saveModalHandler });

  switch (mode) {
    case MapMode.ADD_POI:
    case MapMode.ADD_TEXT:
    case MapMode.ADD_IMAGE:
      map.data.setDrawingMode("Point");
      break;
    case MapMode.ADD_LINE:
      map.data.setDrawingMode("LineString");
      break;
    case MapMode.ADD_POLYGON:
      map.data.setDrawingMode("Polygon");
      break;
    case MapMode.MOVE:
      map.setOptions({ draggableCursor: "crosshair" });
      moveClickListener.current = window.google.maps.event.addListenerOnce(
        map,
        "click",
        function (eventInfo) {
          if (!updatedFeatureData.current) {
            const originalStateFeature = selectedFeature.current;
            originalStateFeature.toGeoJson(geoJSON => {
              updatedFeatureData.current = geoJSON;
              selectedFeature.current.setGeometry(eventInfo.latLng);
              saveModalHandler();
              changeMapMode({
                mode: MapMode.DEFAULT,
                mapMode,
                map,
                selectedFeature,
              });
            });
          } else {
            selectedFeature.current.setGeometry(eventInfo.latLng);
            saveModalHandler();
            changeMapMode({
              mode: MapMode.DEFAULT,
              mapMode,
              map,
              selectedFeature,
            });
          }
        },
      );
      map.data.forEach(function (feature) {
        map.data.overrideStyle(feature, {});
      });
      break;
    case MapMode.DEFAULT:
      map.setOptions({ draggableCursor: null });
      map.data.setDrawingMode(null);
      break;
    default:
      break;
  }
}

function getActionsControl({
  map,
  mapMode,
  moveClickListener,
  selectedFeature,
  updatedFeatureData,
  saveModalHandler,
}) {
  const control = document.createElement("div");
  control.style =
    "z-index:1; box-shadow: rgb(158 237 255 / 66%) 0px 0px 6px 4px; border-radius: 2px; margin-left:20px; margin-top:20px; font-size: 14px; cursor: pointer;";
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Add Point of Interest";
      div.id = "add-poi";
      div.setAttribute("map-mode", "ADD_POI");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_map_location.d0d9502bcf31.png') no-repeat #3f9dff; background-size:30%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Add Line";
      div.id = "add-line";
      div.setAttribute("map-mode", "ADD_LINE");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_draw_line.d5fab4ec313c.png') no-repeat #3f9dff; background-size:30%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Add Polygon";
      div.id = "add-polygon";
      div.setAttribute("map-mode", "ADD_POLYGON");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_shape_tool.1975a914b299.png') no-repeat #3f9dff; background-size:30%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Add Text";
      div.id = "add-text";
      div.setAttribute("map-mode", "ADD_TEXT");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_text.e51f4614c753.png') no-repeat #3f9dff; background-size:30%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Add Image";
      div.id = "add-image";
      div.setAttribute("map-mode", "ADD_IMAGE");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_photo.3ec0f3537b47.png') no-repeat #3f9dff; background-size:30%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.id = "action-divider";
      div.style =
        "display:none; height:1px; width:80%; background-color:#D9D9D9; margin-left:10%;";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Move Selected Feature";
      div.id = "move";
      div.setAttribute("map-mode", "MOVE");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "display:none; height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_freeroam.9cdf5a0e21a9.png') no-repeat #3f9dff; background-size:30%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Resize Feature";
      div.id = "resize";
      div.setAttribute("map-mode", "RESIZE");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "display:none; height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_resize.893750b99ac7.png') no-repeat #3f9dff; background-size:40%; background-position:center";
      return div;
    })(),
  );
  control.appendChild(
    (function () {
      const div = document.createElement("div");
      div.title = "Rotate Feature";
      div.id = "rotate";
      div.setAttribute("map-mode", "ROTATE");
      div.setAttribute("expanded", "false");
      div.classList.add("action-button");
      div.style =
        "display:none; height:66px; width:66px; text-align:center; vertical-align:middle; background: url('/static/map/admin/icon_rotate.3423a203bc5b.png') no-repeat #3f9dff; background-size:40%; background-position:center";
      return div;
    })(),
  );

  control.querySelectorAll(".action-button").forEach(node => {
    node.addEventListener("click", function (e) {
      changeMapMode({
        mode: e.target.getAttribute("map-mode"),
        map,
        mapMode,
        moveClickListener,
        selectedFeature,
        updatedFeatureData,
        saveModalHandler,
      });
    });
  });

  return control;
}

function copyToClipboardEventListner(event) {
  switch (event.code) {
    case "KeyC": // c key
      navigator.clipboard.writeText(
        document.getElementById("coordinates").innerHTML.replace("<br>", "\n"),
      );
      break;
    default:
      break;
  }
}

function createLatitudeAndLongitudeControl({ map }) {
  const controlDiv = document.createElement("div");
  controlDiv.id = "coordinates";
  controlDiv.style = "z-index: 2; font-size: 10px;";

  const overlay = new window.google.maps.OverlayView();
  overlay.draw = function () {};
  overlay.setMap(map);

  const mapDOM = document.getElementById("map");
  mapDOM.addEventListener("mousemove", function (event) {
    const { left, top } = mapDOM.getBoundingClientRect();
    const x = event.pageX - left;
    const y = event.pageY - top;

    if (!overlay.getProjection()) return;

    const coordinate = overlay
      .getProjection()
      .fromContainerPixelToLatLng(new window.google.maps.Point(x, y));
    document.getElementById("coordinates") &&
      (document.getElementById("coordinates").innerHTML =
        "Latitude = " +
        coordinate.lat().toFixed(6) +
        "<br/>Longitude = " +
        coordinate.lng().toFixed(6));
  });

  document.addEventListener("keydown", copyToClipboardEventListner);

  return controlDiv;
}

function featureChanged({ featureInfo, map }) {
  if (featureInfo.feature.overlay) featureInfo.feature.overlay.draw();
  else map.data.overrideStyle(featureInfo.feature, {});
  return null;
}

function featureSetListner({ feature, map }) {
  const handleFeatureChanged = featureInfo => {
    return featureChanged({
      featureInfo,
      map,
    });
  };
  window.google.maps.event.addListener(
    feature,
    "setgeometry",
    handleFeatureChanged,
  );
  window.google.maps.event.addListener(
    feature,
    "setproperty",
    handleFeatureChanged,
  );
}

function getCategoryOption(category) {
  let option = {};
  if (category.child_categories?.length === 0)
    return { [category.id]: category.display_name };
  else {
    category.child_categories.forEach(cat => {
      const opt = getCategoryOption(cat);
      for (let id in opt) {
        option[id] = opt[id];
      }
    });
    return option;
  }
}

const featureModalHandler = ({
  feature,
  map,
  mapMode,
  selectedFeature,
  setModalState,
}) => {
  switch (mapMode.current) {
    case MapMode.ADD_POI:
      feature !== selectedFeature.current &&
        selectFeature({
          feature: feature,
          selectedFeature,
          map,
        });
      changeMapMode({
        map,
        mapMode,
        mode: MapMode.DEFAULT,
        selectedFeature,
      });
      feature.toGeoJson(function (GeoJSON) {
        Object.keys(GeoJSON.properties).length > 0
          ? setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                expandable: false,
                hidden: false,
                search: false,
                size: 0.00008,
                level_ids: [],
                ...GeoJSON.properties,
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_POI,
              modalMode: "Update",
            })
          : setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                expandable: false,
                hidden: false,
                search: false,
                size: 0.00008,
                level_ids: [],
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_POI,
              modalMode: "Add",
            });
      });
      break;
    case MapMode.ADD_TEXT:
      feature !== selectedFeature.current &&
        selectFeature({
          feature: feature,
          selectedFeature,
          map,
        });
      changeMapMode({
        map,
        mapMode,
        mode: MapMode.DEFAULT,
        selectedFeature,
      });
      feature.toGeoJson(function (GeoJSON) {
        Object.keys(GeoJSON.properties).length > 0
          ? setModalState({
              defaultValues: {
                name: "",
                draw_layer: 1,
                expandable: false,
                hidden: false,
                search: false,
                size: 0.00002,
                rotation: 0,
                text_color: "#000000",
                level_ids: [],
                ...GeoJSON.properties,
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_TEXT,
              modalMode: "Update",
            })
          : setModalState({
              defaultValues: {
                name: "",
                draw_layer: 1,
                expandable: false,
                hidden: false,
                search: false,
                size: 0.00002,
                rotation: 0,
                text_color: "#000000",
                level_ids: [],
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_TEXT,
              modalMode: "Add",
            });
      });
      break;
    case MapMode.ADD_LINE:
      feature !== selectedFeature.current &&
        selectFeature({
          feature: feature,
          selectedFeature,
          map,
        });
      changeMapMode({
        map,
        mapMode,
        mode: MapMode.DEFAULT,
        selectedFeature,
      });
      feature.toGeoJson(function (GeoJSON) {
        Object.keys(GeoJSON.properties).length > 0
          ? setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                hidden: false,
                search: false,
                stroke_color: "#000000",
                stroke_opacity: 0.8,
                stroke_weight: 2,
                level_ids: [],
                ...GeoJSON.properties,
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_LINE,
              modalMode: "Update",
            })
          : setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                hidden: false,
                search: false,
                stroke_color: "#000000",
                stroke_opacity: 0.8,
                stroke_weight: 2,
                level_ids: [],
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_LINE,
              modalMode: "Add",
            });
      });
      break;
    case MapMode.ADD_POLYGON:
      feature !== selectedFeature.current &&
        selectFeature({
          feature: feature,
          selectedFeature,
          map,
        });
      changeMapMode({
        map,
        mapMode,
        mode: MapMode.DEFAULT,
        selectedFeature,
      });
      feature.toGeoJson(function (GeoJSON) {
        Object.keys(GeoJSON.properties).length > 0
          ? setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                expandable: false,
                hidden: false,
                search: false,
                fill_color: "#000000",
                fill_opacity: 0.7,
                stroke_color: "#000000",
                stroke_opacity: 1,
                stroke_weight: 1,
                level_ids: [],
                ...GeoJSON.properties,
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_POLYGON,
              modalMode: "Update",
            })
          : setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                expandable: false,
                hidden: false,
                search: false,
                fill_color: "#000000",
                fill_opacity: 0.7,
                stroke_color: "#000000",
                stroke_opacity: 1,
                stroke_weight: 1,
                level_ids: [],
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_POLYGON,
              modalMode: "Add",
            });
      });
      break;
    case MapMode.ADD_IMAGE:
      feature !== selectedFeature.current &&
        selectFeature({
          feature: feature,
          selectedFeature,
          map,
        });
      changeMapMode({
        map,
        mapMode,
        mode: MapMode.DEFAULT,
        selectedFeature,
      });
      feature.toGeoJson(function (GeoJSON) {
        Object.keys(GeoJSON.properties).length > 0
          ? setModalState({
              defaultValues: {
                name: "",
                draw_layer: 0,
                expandable: false,
                hidden: false,
                search: false,
                size: 0.0002,
                rotation: 0,
                level_ids: [],
                ...GeoJSON.properties,
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_IMAGE,
              modalMode: "Update",
            })
          : setModalState({
              defaultValues: {
                draw_layer: 0,
                expandable: false,
                hidden: false,
                search: false,
                size: 0.0002,
                rotation: 0,
                level_ids: [],
              },
              isOpenModal: true,
              mapMode: MapMode.ADD_IMAGE,
              modalMode: "Add",
            });
      });
      break;
    default:
      break;
  }
};

const setFeaturePropertyType = ({ feature, mapMode }) => {
  switch (mapMode) {
    case MapMode.ADD_POI:
      feature.setProperty("type", "poi");
      break;
    case MapMode.ADD_IMAGE:
      feature.setProperty("type", "image");
      break;
    case MapMode.ADD_TEXT:
      feature.setProperty("type", "text");
      break;
    default:
      break;
  }
};

const addKeyboardListner = ({
  mapMode,
  map,
  moveClickListener,
  updatedFeatureData,
  saveModalHandler,
  selectedFeature,
}) => {
  window.document.addEventListener("keydown", async event => {
    if (
      (mapMode.current === MapMode.MOVE ||
        mapMode.current === MapMode.ROTATE ||
        mapMode.current === MapMode.RESIZE) &&
      !updatedFeatureData.current
    ) {
      await selectedFeature.current.toGeoJson(geoJSON => {
        updatedFeatureData.current = geoJSON;
      });
    }

    if (mapMode.current === MapMode.MOVE) {
      let nextGeometry = null;
      switch (event.code) {
        case "ArrowDown":
          nextGeometry = {
            lat: selectedFeature.current.getGeometry().get().lat() - 0.000001,
            lng: selectedFeature.current.getGeometry().get().lng(),
          };
          break;
        case "ArrowUp":
          nextGeometry = {
            lat: selectedFeature.current.getGeometry().get().lat() + 0.000001,
            lng: selectedFeature.current.getGeometry().get().lng(),
          };
          break;
        case "ArrowLeft":
          nextGeometry = {
            lat: selectedFeature.current.getGeometry().get().lat(),
            lng: selectedFeature.current.getGeometry().get().lng() - 0.000001,
          };
          break;
        case "ArrowRight":
          nextGeometry = {
            lat: selectedFeature.current.getGeometry().get().lat(),
            lng: selectedFeature.current.getGeometry().get().lng() + 0.000001,
          };
          break;
        case "Enter":
          window.google.maps.event.removeListener(moveClickListener.current);
          saveModalHandler();
          changeMapMode({
            map,
            mapMode,
            mode: MapMode.DEFAULT,
            selectedFeature,
          });
          break;
        default:
          break;
      }

      if (nextGeometry !== null) {
        selectedFeature.current.setGeometry(nextGeometry);
        event.preventDefault();
        return false;
      }
    }

    if (mapMode.current === MapMode.RESIZE) {
      let sizeDiff = 0;
      switch (event.code) {
        case "ArrowUp":
          sizeDiff = 0.00001;
          break;
        case "ArrowDown":
          sizeDiff = -0.00001;
          break;
        case "Enter":
          saveModalHandler();
          changeMapMode({
            map,
            mapMode,
            mode: MapMode.DEFAULT,
            selectedFeature,
          });
          break;
        default:
          break;
      }

      if (sizeDiff !== 0) {
        if (!selectedFeature.current.getProperty("size")) {
          selectedFeature.current.setProperty("size", 8e-5);
        }
        selectedFeature.current.setProperty(
          "size",
          selectedFeature.current.getProperty("size") + sizeDiff,
        );
        event.preventDefault();
        return false;
      }
    }

    if (mapMode.current === MapMode.ROTATE) {
      let rotationDiff = 0;
      switch (event.code) {
        case "ArrowUp":
          rotationDiff = 1;
          break;
        case "ArrowDown":
          rotationDiff = -1;
          break;
        case "Enter": // enter key
          saveModalHandler();
          changeMapMode({
            map,
            mapMode,
            mode: MapMode.DEFAULT,
            selectedFeature,
          });
          break;
        default:
          break;
      }

      if (rotationDiff !== 0) {
        selectedFeature.current.setProperty(
          "rotation",
          selectedFeature.current.getProperty("rotation") + rotationDiff,
        );
        event.preventDefault();
        return false;
      }
    }
  });
};

const getFeatureGeometry = ({ feature }) => {
  return new Promise(res => {
    feature?.toGeoJson(GeoJSON => {
      res(GeoJSON.geometry);
    });
  });
};

function Map({
  zoom,
  venueData,
  venueID,
  dispatch,
  sport_key,
  env_key,
  ...rest
}) {
  const mapRef = createRef(null);
  const selectedFeature = useRef(null);
  const moveClickListener = useRef(null);
  const mapMode = useRef(MapMode.DEFAULT);
  const center = {
    lat: parseFloat(venueData.venue.center_coordinate.coordinates[0]),
    lng: parseFloat(venueData.venue.center_coordinate.coordinates[1]),
  };
  const [showLoader, setShowLoader] = useState(true);
  const [levelValue, setLevelValue] = useState({
    level: "",
    refreshControl: false,
  });
  const [features, setFeatures] = useState([]);
  const nonGeoJsonFeatures = useRef([]);
  const [map, setMap] = useState(null);
  const [featureModalState, setModalState] = useState({
    defaultValues: null,
    isOpenModal: false,
    mapMode: MapMode.DEFAULT,
    modalMode: "Add",
  });
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [updateFeatureModalState, setUpdateFeatureModalState] = useState(false);
  const updatedFeatureData = useRef(null);

  const urlQueryParams = new URLSearchParams();
  urlQueryParams.append("device", "ios");
  urlQueryParams.append("version", process.env.REACT_APP_GRAPHQL_VERSION);
  urlQueryParams.append("Content-Type", "application/json");
  urlQueryParams.append("sport_key", sport_key);
  urlQueryParams.append("env_key", env_key);

  const categoryParentMap = {};
  const categoryMap = {};

  function populateCategoryMap(category) {
    categoryMap[category.id] = category;
    if (category.child_categories) {
      category.child_categories.forEach(function (childCategory) {
        populateCategoryMap(childCategory);
        categoryParentMap[childCategory.id] = category;
      });
    }
  }
  if (venueData.categories) venueData.categories.forEach(populateCategoryMap);

  const getCategoryOptionCallback = useCallback(() => {
    const data = {};
    venueData.categories.forEach(cat => {
      const optionObject = getCategoryOption(cat);
      for (let id in optionObject) {
        data[id] = optionObject[id];
      }
    });
    const optionArray = [];
    for (let id in data) {
      optionArray.push({ label: data[id], value: id });
    }
    optionArray.sort((a, b) => (a.label > b.label ? 1 : -1));
    return optionArray;
  }, [venueData.categories]);
  const categoryOptions = useRef(null);

  const getLevelOptions = useCallback(() => {
    const data = [];
    venueData.levels.forEach(level => {
      data.push({ label: level.name, value: level.id });
    });
    data.sort((a, b) => (a.label > b.label ? 1 : -1));
    return data;
  }, [venueData.levels]);
  const levelOptions = useRef(null);

  const getLevelData = useCallback(async () => {
    const {
      data: { features },
    } = await dispatch(
      getLevelDataRequest({
        url: `/v1/app/interactive_maps/venues/geojson/${venueID}/${
          levelValue.level
        }?${urlQueryParams.toString()}`,
      }),
    );
    features?.forEach(feature => (feature.properties.uid = feature.uid));

    //<------ remove those POIs from the features for whom category_id does not exist
    const categoryIds = Object.keys(categoryMap);
    const filteredFeatures = Array.from(features).filter(
      feature =>
        !(
          feature.geometry.type === "Point" &&
          feature.properties.type === "poi" &&
          !categoryIds.includes(String(feature.properties.category_id))
        ),
    );
    // ------>

    setFeatures(filteredFeatures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelValue]);

  const handleDeleteModal = useCallback(() => {
    setDeleteModalState(!deleteModalState);
  }, [deleteModalState]);

  const deleteFeatureHandler = useCallback(async () => {
    if (selectedFeature.current) {
      const uid = selectedFeature.current.getProperty("uid");
      if (uid) {
        if (selectedFeature.current.overlay) {
          const arrayLocation = nonGeoJsonFeatures.current.findIndex(
            feature => {
              return feature.getProperty("uid") === uid;
            },
          );
          if (!(arrayLocation === -1))
            nonGeoJsonFeatures.current.splice(arrayLocation, 1);
        }
        await dispatch(
          deleteMapRecord({
            url: `/v1/admin/interactive_maps/features/${uid}`,
          }),
        );
      }
      if (selectedFeature.current.overlay)
        selectedFeature.current.overlay.setMap(null);
      else map.data.remove(selectedFeature.current);
      selectFeature({
        feature: null,
        selectedFeature,
        map,
      });
    }
    handleDeleteModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature.current, deleteModalState]);

  useEffect(() => {
    if (map && levelValue.refreshControl) addMapLevelControl();
    if (
      venueData.levels
        .map(level => level.id)
        .includes(parseInt(levelValue.level))
    ) {
      setShowLoader(true);
      getLevelData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelValue]);

  const addMapLevelControl = () => {
    if (map.controls[window.google.maps.ControlPosition.TOP_LEFT].length > 0) {
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].forEach(
        (control, index) => {
          if (control.classList.contains("level-control"))
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].removeAt(
              index,
            );
        },
      );
    }
    const levelControlDiv = document.createElement("div");
    levelControlDiv.classList.add("level-control");
    levelControlDiv.style.marginTop = "10px";
    levelControlDiv.style.minWidth = "400px";
    levelControlDiv.style.width = "50%";
    ReactDOM.render(
      <Levels
        levels={venueData.levels}
        levelValue={levelValue}
        setLevelValue={setLevelValue}
      />,
      levelControlDiv,
    );
    levelControlDiv.index = 0;
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      levelControlDiv,
    );
  };

  const cancelModalHandler = async () => {
    if (selectedFeature.current) {
      if (updatedFeatureData.current) await rollbackFeatureState();
      selectedFeature.current.toGeoJson(function (GeoJSON) {
        // Any previously created feature will must have properties
        // Remove only if it's just been created
        if (Object.keys(GeoJSON.properties).length === 0)
          map.data.remove(selectedFeature.current);
        selectFeature({
          feature: null,
          selectedFeature,
          map,
        });
      });
    }
    setModalState(initialModalState);
  };

  const submitModalHandler = async ({ form, type }) => {
    if (selectedFeature.current) {
      switch (type) {
        case MapMode.ADD_POI:
          for (let key in form)
            selectedFeature.current.setProperty(key, form[key]);
          if (!selectedFeature.current.getProperty("type"))
            setFeaturePropertyType({
              feature: selectedFeature.current,
              mapMode: MapMode.ADD_POI,
            });
          if (!selectedFeature.current.getProperty("venue_id"))
            selectedFeature.current.setProperty("venue_id", venueData.venue.id);
          break;
        case MapMode.ADD_LINE:
          for (let key in form)
            selectedFeature.current.setProperty(key, form[key]);
          if (!selectedFeature.current.getProperty("venue_id"))
            selectedFeature.current.setProperty("venue_id", venueData.venue.id);
          break;
        case MapMode.ADD_POLYGON:
          for (let key in form)
            selectedFeature.current.setProperty(key, form[key]);
          if (!selectedFeature.current.getProperty("venue_id"))
            selectedFeature.current.setProperty("venue_id", venueData.venue.id);
          break;
        case MapMode.ADD_TEXT:
          if (!selectedFeature.current.overlay) {
            // Create a new feature...
            const coordinates = [];
            selectedFeature.current
              .getGeometry()
              .forEachLatLng(function (LatLng) {
                coordinates.push(LatLng.lng());
                coordinates.push(LatLng.lat());
              });
            const geoJSON = {
              geometry: await getFeatureGeometry({
                feature: selectedFeature.current,
              }),
              properties: {
                type: "text",
                venue_id: venueData.venue.id,
                ...form,
              },
            };
            map.data.remove(selectedFeature.current);
            const newFeature = addFeature({
              map,
              geoJSON,
              nonGeoJsonFeatures,
              selectedFeature,
              updatedFeatureData,
              saveModalHandler,
            });
            selectFeature({
              feature: newFeature,
              selectedFeature,
              map,
            });
          } else {
            // Edit existing feature
            for (let key in form)
              selectedFeature.current.setProperty(key, form[key]);
            if (!selectedFeature.current.getProperty("type"))
              setFeaturePropertyType({
                feature: selectedFeature.current,
                mapMode: MapMode.ADD_TEXT,
              });
            if (!selectedFeature.current.getProperty("venue_id"))
              selectedFeature.current.setProperty(
                "venue_id",
                venueData.venue.id,
              );
          }
          break;
        case MapMode.ADD_IMAGE:
          if (!selectedFeature.current.overlay) {
            // Create a new feature...
            const geoJSON = {
              geometry: await getFeatureGeometry({
                feature: selectedFeature.current,
              }),
              properties: {
                type: "image",
                venue_id: venueData.venue.id,
                ...form,
              },
            };
            map.data.remove(selectedFeature.current);
            const newFeature = addFeature({
              map,
              geoJSON,
              nonGeoJsonFeatures,
              selectedFeature,
              updatedFeatureData,
              saveModalHandler,
            });
            selectFeature({
              feature: newFeature,
              selectedFeature,
              map,
            });
          } else {
            // Edit existing feature
            for (let key in form)
              selectedFeature.current.setProperty(key, form[key]);
            if (!selectedFeature.current.getProperty("type"))
              setFeaturePropertyType({
                feature: selectedFeature.current,
                mapMode: MapMode.ADD_IMAGE,
              });
            if (!selectedFeature.current.getProperty("venue_id"))
              selectedFeature.current.setProperty(
                "venue_id",
                venueData.venue.id,
              );
          }
          break;
        default:
          break;
      }
      selectedFeature.current?.toGeoJson(async GeoJSON => {
        /*
        - Add/Update selected feature call on UID basis
        */
        if (GeoJSON.properties.uid) {
          await dispatch(
            updateRecord({
              form: GeoJSON,
              url: `/v1/admin/interactive_maps/features/${GeoJSON.properties.uid}`,
              key: "feature",
            }),
          );
        } else {
          const {
            data: {
              feature: { uid },
            },
          } = await dispatch(
            createRecord({
              form: GeoJSON,
              url: "/v1/admin/interactive_maps/features",
              key: "feature",
            }),
          );
          selectedFeature.current.setProperty("uid", uid);
        }
        // since modal based updates are supposed to be sent to the server and update on
        // client side simultaneously so no need to maintain changed flag for that feature
        delete selectedFeature.current.changed;
        if (
          !selectedFeature.current
            .getProperty("level_ids")
            .includes(parseInt(levelValue.level))
        ) {
          selectedFeature.current.overlay
            ? selectedFeature.current.overlay.setMap(null)
            : map.data.remove(selectedFeature.current);
          selectFeature({
            feature: null,
            selectedFeature,
            map,
          });
        } else {
          selectedFeature.current.overlay
            ? selectedFeature.current.overlay.draw()
            : map.data.overrideStyle(selectedFeature.current, {});
        }
      });
    }
    // selectFeature({ feature: null, selectedFeature, map });
    setModalState(initialModalState);
  };

  const rollbackFeatureState = useCallback(() => {
    return new Promise(res => {
      if (updatedFeatureData.current && selectedFeature.current)
        selectedFeature.current.toGeoJson(geoJSON => {
          const previousGeoJson = updatedFeatureData.current;
          if (geoJSON.geometry !== previousGeoJson.geometry) {
            selectedFeature.current.setGeometry({
              lat: previousGeoJson.geometry.coordinates[1],
              lng: previousGeoJson.geometry.coordinates[0],
            });
          }
          if (geoJSON.properties !== previousGeoJson.properties) {
            for (let key in previousGeoJson.properties)
              selectedFeature.current.setProperty(
                key,
                previousGeoJson.properties[key],
              );
          }
          selectedFeature.current.overlay
            ? selectedFeature.current.overlay.draw()
            : map.data.overrideStyle(selectedFeature.current, {});
          updatedFeatureData.current = null;
          res();
        });
      else res();
    });
  }, [map]);

  const saveModalHandler = useCallback(async () => {
    if (!updateFeatureModalState) setUpdateFeatureModalState(true);
    else {
      await rollbackFeatureState();
      setUpdateFeatureModalState(false);
    }
  }, [rollbackFeatureState, updateFeatureModalState]);

  const saveModalSubmitHandler = useCallback(() => {
    selectedFeature.current?.toGeoJson(async GeoJSON => {
      await dispatch(
        updateRecord({
          form: GeoJSON,
          url: `/v1/admin/interactive_maps/features/${GeoJSON.properties.uid}`,
          key: "feature",
        }),
      );
      updatedFeatureData.current = null;
    });
    setUpdateFeatureModalState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature.current]);

  // create Map, textOverlay, imageOverlay
  useEffect(() => {
    setMap(
      new window.google.maps.Map(mapRef.current, {
        center: center,
        styles: mapStyle,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoom: zoom,
      }),
    );
    initializeTextOverlay();
    initializeImageOverlay();
    categoryOptions.current = getCategoryOptionCallback();
    levelOptions.current = getLevelOptions();

    //to particularly handle map's zoom functionality
    //by setting its parent's padding to 0
    document.getElementsByClassName("innerContent")[0] &&
      document
        .getElementsByClassName("innerContent")[0]
        .classList.add("innerContentMap");
    return () => {
      document.getElementsByClassName("innerContent")[0] &&
        document
          .getElementsByClassName("innerContent")[0]
          .classList.remove("innerContentMap");

      document.removeEventListener("keydown", copyToClipboardEventListner);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize required features in map once its build
  useEffect(() => {
    if (map) {
      const actionsControl = getActionsControl({
        map,
        mapMode,
        moveClickListener,
        selectedFeature,
        updatedFeatureData,
        saveModalHandler,
      });
      actionsControl.index = 1;
      map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(
        actionsControl,
      );

      addKeyboardListner({
        map,
        mapMode,
        moveClickListener,
        updatedFeatureData,
        saveModalHandler,
        selectedFeature,
      });

      const coordinateControl = createLatitudeAndLongitudeControl({ map });
      coordinateControl.index = 1;
      map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(
        coordinateControl,
      );

      const controlDiv = document.createElement("div");
      ReactDOM.render(<EditSaveButtonControl />, controlDiv, function () {
        controlDiv
          .querySelector("#edit-button")
          .addEventListener("click", function (e) {
            e.preventDefault();
            editFeatureHandler({
              map,
              mapMode,
              selectedFeature,
              setModalState,
            });
          });
        controlDiv
          .querySelector("#delete-button")
          .addEventListener("click", function (e) {
            e.preventDefault();
            handleDeleteModal();
          });
      });
      controlDiv.index = 1;
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        controlDiv,
      );

      // Set boundaries of map viewport to see required entire area at once
      if (
        venueData.venue.southwest_coordinate &&
        venueData.venue.northeast_coordinate
      ) {
        setBoundaries({
          map,
          coordinates: {
            northeast_coordinate: venueData.venue.northeast_coordinate,
            southwest_coordinate: venueData.venue.southwest_coordinate,
          },
        });
      }
      // Add required layers to the map
      addLayers({
        map,
        layers: {
          showTraffic: venueData.venue.show_traffic,
          showBicycling: venueData.venue.show_bicycling,
          showTransit: venueData.venue.show_transit,
        },
      });

      //<------- [START] custom methods for map ---------->

      // Handle when zoom changes on map
      add_zoom_changed_listner(map);

      // Add handling for when we click on a feature
      add_feature_click_listner({
        map,
        updatedFeatureData,
        saveModalHandler,
        selectedFeature,
      });

      // function to clear all features at once
      add_removeAllFeatures_method({
        map,
        nonGeoJsonFeatures,
      });

      // function to add feature into the map
      map.addFeature = addFeature;

      // <------- [END] custom methods for map ---------->
      addMapLevelControl();

      setMapStyle({
        map,
        mapMode,
        categoryMap,
        levels: venueData.levels,
        selectedIconMultiplier: venueData.venue.selected_icon_multiplier,
        selectedFeature,
      });

      map.data.addListener("addfeature", function (featureInfo) {
        featureSetListner({ feature: featureInfo.feature, map });
        featureModalHandler({
          feature: featureInfo.feature,
          map,
          mapMode,
          selectedFeature,
          setModalState,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // update features on map
  useEffect(() => {
    if (map) {
      map.removeAllFeatures();
      features.forEach(feature =>
        map.addFeature({
          map,
          geoJSON: feature,
          nonGeoJsonFeatures,
          selectedFeature,
          updatedFeatureData,
          saveModalHandler,
        }),
      );
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);
  return (
    <>
      {showLoader && <Loader />}
      <div ref={mapRef} style={mapDivStyle} id="map" />
      <FeatureModal
        cancelModalHandler={cancelModalHandler}
        onSubmitHandler={submitModalHandler}
        categoryOptions={categoryOptions}
        levelOptions={levelOptions}
        modalState={featureModalState}
      />
      <FeatureRequestModal
        type={"delete"}
        open={deleteModalState}
        cancelModalHandler={handleDeleteModal}
        onSubmitHandler={deleteFeatureHandler}
      />
      <FeatureRequestModal
        type={"save"}
        open={updateFeatureModalState}
        cancelModalHandler={saveModalHandler}
        onSubmitHandler={saveModalSubmitHandler}
      />
    </>
  );
}
export default Map;
