import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { getUsers } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { useQueryParams } from "app/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useCallback, useEffect, useState } from "react";
import UserView from "./indexView";

export default function Users(props) {
  const [data, setData] = useState([]);
  const [deleteData, setDeleteData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [dataId, setDataId] = useState("");
  const [selected, setSelected] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { sort, value, skip, limit, searchText } = useQueryParams();
  const { access_type } = useSelector(state => state.application);

  const { sport_key, env_key } = props.match.params;

  const headings = [
    { label: "Personal ID", id: "personal_id" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const getUsersData = useCallback(async () => {
    try {
      setIsLoading(true);
      setSelected([]);
      const {
        data: { users, count },
      } = await props.getData({ sort, value, skip, limit, searchText });

      if (count !== 0 && users.length === 0) {
        initialRoute(props.history);
      } else {
        setData(users);
        setTotalUsersCount(count);
        setIsLoading(false);
      }
    } catch (e) {}
  }, [props, sort, value, skip, limit, searchText]);

  useEffect(() => {
    setSelected([]);
    getUsersData();
  }, [getUsersData]);

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  const deleteHandler = async event => {
    await props.deleteUser({
      url: `/v1/app_user/admin/user/${dataId}`,
    });
    setDataId("");
    handleModalClose();
    getUsersData();
  };

  const deleteModalHandler = (event, uid, data) => {
    setDeleteData(data.personal_id);
    setDataId(uid);
    setDeleteModal(true);
  };

  const updateUser = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/users/update/${uid}`,
      state: { from: props.location },
    });
  };

  const handleModalClose = event => {
    setDeleteModal(false);
    setDeleteAllModal(false);
    setDataId("");
  };

  const deleteMultipleHandler = async event => {
    const { deleteUsers } = props;
    await deleteUsers({
      url: "/v1/app_user/admin/user",
      uids: selected,
    });
    getUsersData();
    handleModalClose();
    setSelected([]);
  };

  const actions = [
    {
      id: "edit",
      label: "edit",
      icon: <EditIcon fontSize="small" data-testid="update-btn" />,
      handler: updateUser,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <UserView
      headings={headings}
      data={data}
      actions={actions}
      deleteModal={deleteModal}
      handleModalClose={handleModalClose}
      deleteHandler={deleteHandler}
      checkbox={true}
      selectedList={selected}
      numSelected={selected.length}
      handleSelectAllClick={handleSelectAllClick}
      handleSingleClick={handleSingleClick}
      multipleDeleteHandler={() => setDeleteAllModal(true)}
      name="Users"
      personal_id={deleteData}
      isSorting
      totalCount={totalUsersCount}
      isLoading={isLoading}
      getUsersData={getUsersData}
      deleteAllModal={deleteAllModal}
      deleteMultiple={deleteMultipleHandler}
    />
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getData: payload => dispatch(getUsers(payload)),
    deleteUser: payload => dispatch(deleteRecord(payload)),
    deleteUsers: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Users);

export { Container, Users as Component };
