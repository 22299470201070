import { Container as GetSettings } from "app/pages/Dashboard/Data/Device/Settings/get";
import { Route, Switch } from "react-router-dom";
import AddSettings from "app/pages/Dashboard/Data/Device/Settings/add";
import React from "react";
import UpdateSettings from "app/pages/Dashboard/Data/Device/Settings/update";

const Settings = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddSettings} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateSettings}
        exact
      />
      <Route path={`${props.match.path}`} component={GetSettings} />
    </Switch>
  );
};

export default Settings;
