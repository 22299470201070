import React from "react";

const Setup = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={0.5}>
        <path
          d="M5.833 3.75v-.083a2 2 0 012-2H15.5a2 2 0 012 2v12.666a2 2 0 01-2 2H7.833a2 2 0 01-2-2v-.083"
          stroke="#fff"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.93 12.917c.24-.1.457-.242.666-.4l-.017.025.842.325c.191.075.408 0 .508-.184l.7-1.216a.422.422 0 00-.1-.534l-.708-.566-.017.025c.017-.134.042-.267.042-.4 0-.134-.025-.267-.042-.4l.017.025.708-.567a.422.422 0 00.1-.533L8.93 7.3a.415.415 0 00-.508-.183l-.842.325.017.025a3.747 3.747 0 00-.667-.384l-.142-.9a.406.406 0 00-.408-.35h-1.4a.406.406 0 00-.408.35l-.142.9c-.242.1-.458.242-.667.4l.017-.025-.85-.325a.406.406 0 00-.508.184l-.7 1.216a.422.422 0 00.1.534l.708.566.017-.025c-.017.125-.042.259-.042.392s.025.267.042.4l-.017-.025-.708.567a.422.422 0 00-.1.533l.7 1.217c.1.175.316.258.508.183l.842-.325-.009-.033c.209.158.425.3.667.4l.142.891c.025.209.2.359.408.359h1.4c.208 0 .383-.15.408-.35l.142-.9zM4.011 10c0-.917.75-1.667 1.667-1.667s1.667.75 1.667 1.667-.75 1.667-1.667 1.667-1.667-.75-1.667-1.667z"
          fill="#fff"
        />
        <circle cx={11.667} cy={15} fill="#fff" r={0.833} />
      </g>
    </svg>
  );
};
export default Setup;
