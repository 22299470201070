import { TableHead as Head, TableCell, TableRow } from "@dfep/ui-component";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

const SkeletonTableHead = ({ checkbox, headings, actions }) => {
  return (
    <Head>
      <TableRow>
        {checkbox && (
          <TableCell className="checkbox" padding="checkbox">
            <Checkbox disabled={true} />
          </TableCell>
        )}
        {headings?.map(({ id, label }) => (
          <TableCell
            id={id}
            className="table-cell-head"
            key={id}
            align="left"
            padding="none"
          >
            {label}
          </TableCell>
        ))}
        {actions && (
          <TableCell className="table-cell-head actions-head" padding="none">
            Actions
          </TableCell>
        )}
      </TableRow>
    </Head>
  );
};

export default SkeletonTableHead;
