import { Button } from "@dfep/ui-component";
import MuiBox from "@material-ui/core/Box";
import MuiPaper from "@material-ui/core/Paper";
import MuiTypography from "@material-ui/core/Typography";
import styled from "styled-components";

export const Paper = styled(MuiPaper)`
  width: 100%;
  padding: 24px;
  margin: 0 0 20px;
`;

export const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-family: Nunito;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 0 8px;
    padding-left: 0;
    h6 {
      font-size: 17px;
    }
    span {
      color: rgba(0, 0, 0, 0.54);
    }
    p {
      font-size: 14px;
    }
    &.master-key {
      padding: 6px 0;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px dashed ${props => props.theme.palette.grey.greyE4E7EB};
  padding-bottom: 24px;
  margin-bottom: 24px;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .MuiTextField-root {
    width: 700px;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 100%;
    }
  }
  .MuiTypography-body2 {
    color: rgba(0, 0, 0, 0.54);
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  .MuiButton-root {
    max-height: 36px;
  }
  .MuiButton-contained.Mui-disabled {
    color: ${props => props.theme.palette.white.main};
    opacity: 0.5;
  }
`;

export const ActionButton = styled(Button)`
  margin-right: 15px;
  &.delete-btn {
    color: ${props => props.theme.palette.white.main};
    background: ${props => props.theme.palette.red.main};
  }
`;

export const Box = styled(MuiBox)`
  margin-right: 15px;
  min-width: 186px;
  background: ${props => props.theme.palette.white.white200};
  border-radius: 4px;
  text-align: center;
  .MuiTypography-body2 {
    color: rgba(0, 0, 0, 0.87);
  }
`;
export const IconBox = styled(MuiBox)`
  svg {
    cursor: pointer;
    margin: 5px 8px 0 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 17px;
  }
`;
