import { Container as GetMenu } from "app/pages/Dashboard/Data/Device/Menu/get";
import { Route, Switch } from "react-router-dom";
import AddMenu from "app/pages/Dashboard/Data/Device/Menu/add";
import React from "react";
import UpdateMenu from "app/pages/Dashboard/Data/Device/Menu/update";

const Menu = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddMenu} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateMenu}
        exact
      />
      <Route path={`${props.match.path}`} component={GetMenu} />
    </Switch>
  );
};

export default Menu;
