import { Toolbar } from "@dfep/ui-component";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

export default function TableToolbar({
  numSelected,
  name,
  multipleDeleteHandler,
}) {
  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Fragment>
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={multipleDeleteHandler}
              data-testid="multiple-delete-handler"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      ) : (
        <Typography data-testid="table-name" color="inherit" variant="h6">
          {name}
        </Typography>
      )}
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  name: PropTypes.string,
  multipleDeleteHandler: PropTypes.func,
};
