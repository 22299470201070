import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  show: false,
  showDelete: false,
  showMultipleDelete: false,
};

export const useModal = () => {
  const [modalStates, setModalState] = useState(cloneDeep(initialState));

  const closeModalHandler = () => {
    setModalState(cloneDeep(initialState));
  };

  const openModalHandler = key => {
    const state = { ...modalStates, [key]: true };
    setModalState(cloneDeep(state));
  };
  const { show, showDelete, showMultipleDelete } = modalStates;
  return {
    show,
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  };
};
