/* eslint-disable prettier/prettier */
export const THEME_SET = "THEME_SET";

export const acceptFileType =
  "image/jpeg, image/jpg, image/png, image/gif, application/pdf, application/json";

export const acceptImageFileType =
  "image/jpeg, image/jpg, image/png, image/gif";

export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const Constants = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  SPORT_KEY: "sport_key",
  ENV_KEY: "env_key",
  OWNER: "owner",
  COLLABORATOR: "collaborator",
  READ_ONLY: "read_only",
  FULL_ACCESS: "full_access",
};

export const seasons = [
  {
    label: "Preseason",
    value: "01",
  },
  {
    label: "Regular Season",
    value: "02",
  },
  {
    label: "All-star",
    value: "03",
  },
  {
    label: "Playoffs",
    value: "04",
  },
  {
    label: "G League Showcase",
    value: "05",
  },
];

export const PUSH_TYPE = {
  message: "message",
  inbox: "inbox",
  message_inbox: "message_inbox",
};

export const PUSH_STATUS = {
  draft: "draft",
  scheduled: "scheduled",
  hide: "hide",
  completed: "completed",
};

export const Event_Data = [
  { label: "Carbon", value: "carbon" },
  { label: "Ticket Master", value: "tm" },
];

export const PUSH_AUDIENCE = {
  default: "default",
  segment: "segment",
  topic: "topic",
};

// Setting feature Provider

export const provider = {
  push_notification: [
    { label: "Firebase", value: "firebase" },
    { label: "AirShip", value: "airship" },
    { label: "Test Adaptor", value: "test_adaptor" },
  ],
  cms: [{ label: "Contentstack", value: "contentstack" }],
  analytics: [{ label: "Mixpanel", value: "mixpanel" }],
  realtime: [{ label: "PubNub", value: "pubnub" }],
  wsc_config: [{ label: "WSC", value: "wsc" }],
  event_config: Event_Data,
};

export const schedule_type = [
  { label: "Every 15 minutes", value: "EVERY_15_MIN" },
  { label: "Every 30 minutes  ", value: "EVERY_30_MIN" },
  { label: "Every 1 hour", value: "EVERY_1_HR" },
  { label: "Every 6 hours", value: "EVERY_6_HR" },
  { label: "Every 8 hours", value: "EVERY_8_HR" },
  { label: "Every 12 hours", value: "EVERY_12_HR" },
  { label: "Every 24 hours", value: "EVERY_1_DAY" },
];

export const scheduler_data = [
  {
    method: "SYNC_ALL_TEAMS_SCHEDULE",
  },
  {
    method: "SYNC_ALL_TEAMS_STATS",
  },
  {
    method: "SYNC_ALL_TEAMS_SEASON_AVERAGE",
  },
  {
    method: "SYNC_ALL_TEAMS_LEADERS",
  },
  {
    method: "SYNC_ALL_TEAMS_COACHES",
  },
  {
    method: "SYNC_ALL_PLAYERS_STATS",
  },
  {
    method: "SYNC_ALL_PLAY_BY_PLAY",
  },
  {
    method: "SYNC_TEAMS",
  },
  {
    method: "SYNC_TEAM_STANDINGS",
  },
  {
    method: "SYNC_TEAM_SPLITS",
  },
  {
    method: "SYNC_PLAYERS",
  },
  {
    method: "SYNC_PLAYER_AVERAGE",
  },
  {
    method: "SYNC_COACHES",
  },
  {
    method: "SYNC_GAME_DETAILS",
  },
  {
    method: "SYNC_PLAYER_LOGS",
  },
  {
    method: "SYNC_GAME_LEADERS",
  },
  {
    method: "SYNC_TEAM_LEADER_DETAILS",
  },
  {
    method: "SYNC_EVENTS",
  },
  {
    method: "SYNC_ARTICLES",
  },
  {
    method: "SYNC_GALLERY",
  },
  {
    method: "SYNC_VIDEOS",
  },
  {
    method: "SYNC_ALL_ADVANCED_TEAMS_STATS",
  },
  {
    method: "SYNC_ALL_ADVANCED_PLAYERS_STATS",
  },
  {
    method: "SYNC_ALL_TEAM_LEADER_DETAILS",
  },
  {
    method: "SYNC_ALL_PLAYER_AVERAGE",
  },
  {
    method: "SYNC_WSC_VIDEOS",
  },
];

export const customFieldsOptions = [
  {
    label: "Mandatory",
    value: "mandatory",
  },
  {
    label: "Multiple",
    value: "multiple",
  },
];

export const Form_Mode = {
  ADD: "add",
  UPDATE: "update",
};

// array of features to be mentioned to decide visibility of content in sidebar on the basis of whether the features are enabled
export const FEATURES_ARRAY = [
  //<-- for pushNotification
  { featureName: "test_adaptor" },
  { featureName: "airship" },
  { featureName: "firebase" },
  // -->
  { featureName: "push_notification", sidebarId: "pushNotification" },
  { featureName: "drops", sidebarId: "drops_v1" },
  { featureName: "drops_v2", sidebarId:"drops_v2" },
  { featureName: "stories", sidebarId: "stories" },
  { featureName: "interactive_maps", sidebarId: "interactiveMaps" },
  { featureName: "geo_fencing", sidebarId: "GeoFences"},
  //category based feature visibility handling in sidebar
  {
    featureName: "appetize",
    sidebarId: "order_history",
    handleByCategory: true,
  },
  { featureName: "freedompay", sidebarId: "refund", handleByCategory: true },
];

// Names of features to be mentioned 
export const FEATURES_NAME  = {
  SIGNALR_TRANSCRIPT: "signalr_transcript",
  FORTRESS: "fortress",
  SEGMENTATION : "segmentation",
};

export const TeamStandingMapperObject = {
  ranking: ["crnk", "drnk"],
  streaks: ["home_streak", "road_streak", "win_streak", "loss_streak"],
  halftime_record: [
    "ahead_at_half_wins",
    "ahead_at_half_losses",
    "behind_at_half_wins",
    "behind_at_half_losses",
    "tied_at_half_wins",
    "tied_at_half_losses",
  ],
  third_qtr_record: [
    "ahead_at_third_wins",
    "ahead_at_third_losses",
    "behind_at_third_wins",
    "behind_at_third_losses",
    "tied_at_third_wins",
    "tied_at_third_losses",
  ],
  overtime_record: ["ot_wins", "ot_losses"],
  score_gap_record: [
    "three_pts_or_less_wins",
    "three_pts_or_less_losses",
    "ten_pts_or_more_wins",
    "ten_pts_or_more_losses",
  ],
  hundred_plus_point_record: [
    "score_100_plus_wins",
    "score_100_plus_losses",
    "opp_score_100_plus_wins",
    "opp_score_100_plus_losses",
  ],
  five_hundred_percent_opponent_record: [
    "opp_over_500_wins",
    "opp_over_500_losses",
  ],
  statistical_record: [
    "lead_in_fgpct_wins",
    "lead_in_fgpct_losses",
    "lead_in_reb_wins",
    "lead_in_reb_losses",
    "fewer_turnovers_wins",
    "fewer_turnovers_losses",
  ],
};
export const blockedSearchArray = ["settings"];

export const googleMapsLibrariesToImport = ["places", "drawing"]; 

export const GOOGLE_MAPS = {
  JS_API_LOADER: "@googlemaps/js-api-loader",
};

export const broadcasterDetailsSchema = [
  {
    display_name: "Broadcaster Details",
    name: "broadcasters",
    uid: "broadcasters",
    mandatory: false,
    multiple: true,
    data_type: "group",
    field_metadata: {
      open: true,
      multiple_open: true
    },
    _schema: [
      {
        display_name: "Broadcaster Id",
        uid: "broadcaster_id",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Broadcast Display (call letters, etc.)",
        uid: "disp",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Type (tv, radio)",
        uid: "type",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Scope (natl, can, home, away)",
        uid: "scope",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "DFE Display Text",
        uid: "dfe_display_text",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "DFE Broadcaster Logo",
        uid: "dfe_broadcaster_logo",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {
          file_type: "image/jpeg, image/jpg, image/png, image/gif"
        },
      },
      {
        display_name: "DFE Click through Url",
        uid: "dfe_click_through_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom field 1",
        uid: "custom_field_1",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom field 2",
        uid: "custom_field_2",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
    ],
  },
  {
    display_name: "Scope",
    name: "scope",
    uid: "scope",
    mandatory: false,
    multiple: true,
    data_type: "group",
    field_metadata: {
      open: true,
      multiple_open: true
    },
    _schema: [
      {
        display_name: "Type",
        uid: "type",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Value",
        uid: "value",
        mandatory: false,
        multiple: true,
        data_type: "string",
        field_metadata: {},
      },
    ],
  },
  {
    display_name: "Position",
    name: "position",
    uid: "position",
    mandatory: false,
    multiple: true,
    data_type: "string",
    field_metadata: {},
  },
];

export const euroleagueConfigSchema = [
  {
    uid: "action_url",
    display_name: "Action URL",
    data_type: "string",
    mandatory: false,
    multiple: false,
    field_metadata: {},
  },
  {
    uid: "headshot_url",
    display_name: "Headshot URL",
    data_type: "string",
    mandatory: false,
    multiple: false,
    field_metadata: {},
  },
  {
    uid: "competitions",
    display_name: "Competitions",
    data_type: "group",
    mandatory: false,
    multiple: true,
    field_metadata: {
      open: true,
      multiple_open: true
    },
    _schema: [
      {
        display_name: "Competition Code",
        uid: "competition_code",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Phase Type Code",
        uid: "phase_type_code",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season Code",
        uid: "season_code",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Sync",
        uid: "sync",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
    ],
  },

];

export const languagesSchema = [
  {
    display_name: "Languages",
    name: "languages",
    uid: "languages",
    mandatory: false,
    multiple: true,
    data_type: "group",
    field_metadata: {
      open: true,
      multiple_open: true
    },
    _schema: [
      {
        display_name: "Language Code 1",
        uid: "language_code_1",
        mandatory: true,
        multiple: false,
        data_type: "string",
        validations: {
          pattern: /^[a-zA-Z]{2}(?:-[a-zA-Z]{2})?$/,
          required: true
        },
        field_metadata: {},
      },
      {
        display_name: "Language Code 2",
        uid: "language_code_2",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Language Name",
        uid: "language_name",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Is Default",
        uid: "is_default",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
    ],
  },
];