import { createSlice } from "@reduxjs/toolkit";

export const constants = createSlice({
  name: "constants",
  initialState: {
    sports: [],
    order: {},
  },
  reducers: {
    saveSports(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { saveSports } = constants.actions;

export default constants.reducer;
