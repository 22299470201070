/* istanbul ignore file */
import { Route, Switch } from "react-router-dom";
import AddRefund from "./add";
import GetRefund from "./get";
import React from "react";
import UpdateRefund from "./update";

const Refund = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddRefund} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateRefund}
        exact
      />
      <Route path={props.match.path} component={GetRefund} />
    </Switch>
  );
};

export default Refund;
