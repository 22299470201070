import {
  AuthContainer,
  AuthFormWrap,
  FooterInfo,
  PasswordWrap,
} from "../authGlobal.style";
import { authServices } from "services/auth";
import { Box, Paper, Typography } from "@material-ui/core";
import { Button } from "@dfep/ui-component";
import { connect } from "react-redux";
import { encryptPassword } from "utils/crypto";
import { Link } from "react-router-dom";
import { provideValidationToggle } from "utils/passwordValidation";
import { setError, validatePassword } from "utils/functions";
import cloneDeep from "lodash/cloneDeep";
import loginLogo from "assets/DFEP-login-logo.svg";
import PasswordValidator from "app/components/Common/PasswordValidator";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  newPassword: {
    hasError: false,
    message: "",
  },
  confirmPassword: {
    hasError: false,
    message: "",
  },
};

const ResetPassword = props => {
  const [newPassword, bindNewPassword] = useInput("");
  const [confirmPassword, bindConfirmPassword] = useInput("");
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));
  const [openValidationBar, setPasswordValidationBar] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  // Ref for new password input field
  const newPasswordRef = useRef(null);

  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [newPassword, confirmPassword]);

  // useEffect for openValidationBar callback
  useEffect(() => {
    if (openValidationBar) {
      provideValidationToggle(newPassword);
    }
  }, [newPassword, openValidationBar]);

  // Reset Password API Call on Form Submit
  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      const { reset_token } = props.match.params;
      const form = await formValidation();
      props
        .resetPasswordService({
          url: `/v1/admin/auth/reset_password/${reset_token}`,
          form,
          method: "PUT",
          showToast: true,
        })
        .then(result => {
          setResetSuccess(true);
        });
    } catch (error) {}
  };

  // Form Validation for Email and Password
  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!newPassword) {
        const error = setError(inputError, "newPassword", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Field is required" });
      } else if (!confirmPassword) {
        const error = setError(
          inputError,
          "confirmPassword",
          "Field is Required!",
        );
        setInputError(cloneDeep(error));
        reject({ error: "Field is required" });
      } else if (!validatePassword(newPassword)) {
        const error = setError(
          inputError,
          "newPassword",
          "Invalid Password Format!",
        );
        setInputError(cloneDeep(error));
        newPasswordRef.current.focus();
        reject({ error: "Invalid Password Format" });
      } else if (newPassword !== confirmPassword) {
        const error = setError(
          inputError,
          "confirmPassword",
          "Password did not match!",
        );
        setInputError(cloneDeep(error));
        reject({ error: "Password did not match!" });
      } else {
        const form = { ...encryptPassword(newPassword) };
        resolve(form);
      }
    });
  };

  return (
    <AuthContainer>
      <AuthFormWrap>
        <img alt="logo" className="login-logo" src={loginLogo} />

        <Paper className="auth-form">
          {resetSuccess ? (
            <>
              <Typography variant="h1">Successful Password Reset</Typography>
              <Typography>
                You can use your new password to login into your account
              </Typography>
              <div className="field-wrap">
                <Button
                  data-testid="login-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => props.history.push("/auth/login")}
                >
                  Sign-In
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography variant="h1">Reset Password</Typography>
              <form data-testid="rp-form" noValidate onSubmit={onFormSubmit}>
                <PasswordWrap>
                  <TextField
                    label="New Password"
                    id="newPassword"
                    type="password"
                    error={inputError.newPassword.hasError}
                    inputRef={newPasswordRef}
                    required={true}
                    onFocus={() => setPasswordValidationBar(true)}
                    onBlur={() => setPasswordValidationBar(false)}
                    helperText={inputError.newPassword.message}
                    {...bindNewPassword}
                  />
                  {openValidationBar && <PasswordValidator />}
                </PasswordWrap>
                <TextField
                  label="Confirm New Password"
                  id="confirmPassword"
                  type="password"
                  required={true}
                  error={inputError.confirmPassword.hasError}
                  helperText={inputError.confirmPassword.message}
                  {...bindConfirmPassword}
                />
                <div className="field-wrap">
                  <Button type="submit" variant="contained" color="primary">
                    Reset Password
                  </Button>
                </div>
              </form>
              <div className="field-wrap link-btn">
                <Box justifyContent="center" display="flex">
                  <Link to="/auth/login">
                    <Button color="primary">Back to Signin</Button>
                  </Link>
                </Box>
              </div>
            </>
          )}
        </Paper>
      </AuthFormWrap>
      <FooterInfo>
        <Typography variant="body1" component="h6">
          © 2021 DFEP. All rights reserved.
        </Typography>
      </FooterInfo>
    </AuthContainer>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      reset_token: PropTypes.string,
    }),
  }),
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    resetPasswordService: payload => dispatch(authServices(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(ResetPassword);

export { Container, ResetPassword as Component };
