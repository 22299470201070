import {
  getGoogleMapApiKey,
  getVenueData as getVenueDataRequest,
} from "services/getObjects";
import { GOOGLE_MAPS } from "utils/constants";
import { handleError } from "services/helper";
import { removeGoogleMapsPackage } from "utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import Loader from "./Loader";
import Map from "./Map";
import React, { useCallback, useEffect, useState } from "react";

const modulesToBeRemoved = [GOOGLE_MAPS.JS_API_LOADER];

function MapApp(props) {
  const [reactWrapperComponents, setRWComponent] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [venueData, setVenueData] = useState(null);
  const [googleMapApiKey, setGoogleMapApiKey] = useState(null);
  const [mapLoaderInstance, setMapLoaderInstance] = useState({});
  const [mapLoaderStatus, setMapLoaderStatus] = useState(null);
  const { querySearch } = useQueryParams();
  const query = new URLSearchParams(querySearch);
  const venueID = query.get("venue_id");

  const { sport_key, env_key } = useSelector(state => state.application);

  const dispatch = useDispatch();

  const getInitialData = useCallback(async () => {
    try {
      setShowLoader(true);
      const urlQueryParams = new URLSearchParams();
      urlQueryParams.append("device", "ios");
      urlQueryParams.append("version", process.env.REACT_APP_GRAPHQL_VERSION);
      urlQueryParams.append("Content-Type", "application/json");
      urlQueryParams.append("sport_key", sport_key);
      urlQueryParams.append("env_key", env_key);
      urlQueryParams.append("query", "{config{integrations}}");
      setVenueData(null);

      const {
        data: {
          data: {
            config: { integrations },
          },
        },
      } = await dispatch(
        getGoogleMapApiKey({
          url: `/v1/app/graphql?${urlQueryParams.toString()}`,
        }),
      );
      /* istanbul ignore else */
      if (!Object.keys(integrations).includes("interactive_maps"))
        throw new Error("Can not find interactive_maps integration");

      const { google_access_key } = integrations["interactive_maps"];

      urlQueryParams.delete("query");
      const { data } = await dispatch(
        getVenueDataRequest({
          url: `/v1/app/interactive_maps/venues/${venueID}?${urlQueryParams.toString()}`,
        }),
      );
      setGoogleMapApiKey(google_access_key);
      setVenueData(data);
      setShowLoader(false);
    } catch (error) {
      const err = {
        response: {
          data: {
            error_message: error.message,
          },
        },
      };
      handleError(err, dispatch);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key, venueID]);

  const callBack = useCallback((status, loader) => {
    setMapLoaderStatus(status);
    setMapLoaderInstance(loader);
  }, []);

  useEffect(() => {
    import("@googlemaps/react-wrapper").then(setRWComponent);
    if (
      !sport_key ||
      !env_key ||
      !venueID ||
      sport_key.length === 0 ||
      env_key.length === 0 ||
      venueID.length === 0
    ) {
      const err = {
        response: {
          data: {
            error_message:
              "Can not find valid sport_key or env_key or venue_id",
          },
        },
      };
      handleError(err, dispatch);
    } else getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      removeGoogleMapsPackage({
        mapLoaderInstance,
        mapLoaderStatus,
        modulesToBeRemoved,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaderInstance, mapLoaderStatus]);

  const zoom = 18;

  const render = status => {
    switch (status) {
      case reactWrapperComponents.Status.LOADING:
        return <Loader />;
      case reactWrapperComponents.Status.FAILURE:
        return <Loader />;
      case reactWrapperComponents.Status.SUCCESS:
        return (
          <Map
            zoom={zoom}
            venueData={venueData}
            venueID={venueID}
            dispatch={dispatch}
            sport_key={sport_key}
            env_key={env_key}
            {...props}
          />
        );
      default:
        return <Loader />;
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      {venueData && googleMapApiKey && reactWrapperComponents && (
        <reactWrapperComponents.Wrapper
          apiKey={googleMapApiKey}
          render={render}
          callback={callBack}
        />
      )}
    </>
  );
}

export default MapApp;
