import { isAuthenticated } from "../../utils/auth";
import { Redirect, Route } from "react-router-dom";
import React from "react";

export const PrivateRoute = props => {
  const { component: Component, ...rest } = props;
  const render = props => {
    if (!isAuthenticated()) {
      return <Redirect to="/auth" />;
    }
    return <Component {...props} />;
  };
  return <Route {...rest} render={render} />;
};

export const PublicRoute = props => {
  const { component: Component, restricted = false, ...rest } = props;
  const render = props => {
    if (isAuthenticated() && restricted) {
      return <Redirect to="/dashboard" />;
    }
    return <Component {...props} />;
  };
  return <Route {...rest} render={render} />;
};
