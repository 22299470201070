import { createRecord } from "services/createObject";
import { FEATURES_ARRAY } from "utils/constants";
import { getCMSLookup, getIntegrations } from "services/getObjects";
import { getSettingScheduler } from "services/getObjects";
import {
  Grid,
  NoPluginsData,
} from "app/pages/Dashboard/settings/features/cards/Card.style";
import {
  handleStateForContentVisibilityInSidebar,
  handleVisibilityByCategoryInSidebar,
} from "utils/sidebarHandler";
import { requestAPI } from "services/helper";
import { setFeatureFlag, setNbaFlag } from "store/reducers/applicationSlice";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import CardLoader from "app/pages/Dashboard/settings/features/cards/CardLoader";
import FeatureCards from "app/pages/Dashboard/settings/features/cards/FeatureCards";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";

function Features({ match }) {
  const { sport_key, env_key } = match.params;
  const { searchText } = useQueryParams();

  const [isLoading, setIsLoading] = useState(true);
  const [recordGame, setRecordGame] = useState({});
  const [liveGame, setLiveGame] = useState(false);
  const [features, setFeatures] = useState([]);

  const { nbaEnable } = useSelector(state => state.application);
  const settingsURL = nbaEnable
    ? `${match.url.split("/features")[0]}`
    : match.url;

  const dispatch = useDispatch();

  const syncSchedule = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/all" }));
  };

  const getFeatures = integrations => {
    let newFeature = [];
    for (const property in integrations) {
      newFeature = [
        ...newFeature,
        {
          value: property,
          label: integrations[property].display_name,
          category: integrations[property].category[0],
          description: integrations[property].description,
          updated_at: integrations[property].updated_at,
          data: {
            enabled: integrations[property].enabled,
            config: integrations[property].config,
          },
        },
      ];
    }
    return newFeature;
  };

  const fetchSettings = useCallback(async () => {
    setIsLoading(true);
    const projection = {
      cms: 1,
      push_notification: 1,
      realtime: 1,
      event_config: 1,
      analytics: 1,
      wsc_config: 1,
      nba_sync_config: 1,
      tm_config: 1,
      updated_at: 1,
    };

    const {
      data: { integrations },
    } = await dispatch(
      getIntegrations({ sport_key, env_key, projection, searchText }),
    );
    setFeatures(getFeatures(integrations.data));
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key, searchText]);

  const handleVisibilityInSidebar = async ({ sport_key, env_key }) => {
    const featureIds = FEATURES_ARRAY.map(feature => feature.featureName);
    const {
      data: { integrations },
    } = await dispatch(getIntegrations({ sport_key, env_key }));

    const visibilityInSidebarObj = handleStateForContentVisibilityInSidebar(
      integrations,
      featureIds,
    );

    //handle visibility in sidebar based on category
    const featuresToBeHandledByCategory = FEATURES_ARRAY.filter(
      features => features.handleByCategory === true,
    );

    const visibilityInSidebarByCatObj = handleVisibilityByCategoryInSidebar(
      featuresToBeHandledByCategory,
      integrations.data,
    );

    dispatch(
      setFeatureFlag(
        Object.assign(visibilityInSidebarObj, visibilityInSidebarByCatObj),
      ),
    );
  };

  const upadteSetting = useCallback(async form => {
    let formState;
    for (const key in form) {
      formState = {
        enabled: form[key].enabled,
        config: form[key].config,
      };
      const {
        data: {
          integrations: { enabled },
        },
      } = await dispatch(
        updateRecord({
          url: `/v1/admin/applications/${sport_key}/envs/${env_key}/integrations/${key}`,
          key: "integrations",
          form: formState,
        }),
      );
      if (key === "nba") {
        dispatch(setNbaFlag({ nbaEnabled: enabled }));
      } else {
        handleVisibilityInSidebar({ sport_key, env_key });
      }
      fetchSettings();
    }
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    getSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSchedule = async () => {
    isLoading ?? setIsLoading(true);
    const {
      data: { schedule },
      status,
    } = await dispatch(getSettingScheduler());

    setLiveGame({
      enable: !!schedule.find(
        data => data.schedule_type === "NBA_SET_LIVE_GAME",
      )?.enable,
      schedule_type: "NBA_SET_LIVE_GAME",
      service: "NBA_DJ_SERVICE",
    });

    setIsLoading(false);
    return status;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const createSchedule = async formData => {
    const url = `v1/scheduler/schedule`;
    await dispatch(createRecord({ url, key: "schedule", form: formData }));
    return getSchedule();
  };

  return (
    <>
      <PanelHeader
        title="Features"
        breadCrumbItems={[
          {
            title: "Settings",
            link: settingsURL,
          },
          {
            title: "Features",
          },
        ]}
        search={true}
        refreshHandler={fetchSettings}
      />
      {isLoading && <CardLoader count={1} />}
      {!isLoading && (
        <Grid container direction="row">
          {features.length > 0 ? (
            features.map((feature, index) => (
              <FeatureCards
                path={match.path}
                label={feature.label}
                uid={feature.value}
                enabled={feature.data.enabled}
                featureName={feature.value}
                category={feature.category}
                handleSwitch={upadteSetting}
                updated_at={feature.updated_at}
                data={feature.data}
                key={index}
                upadteSetting={upadteSetting}
                syncSchedule={syncSchedule}
                recordGame={recordGame}
                setRecordGame={setRecordGame}
                liveGame={liveGame}
                setLiveGame={setLiveGame}
                createSchedule={createSchedule}
                description={feature.description}
                sport_key={sport_key}
                env_key={env_key}
              />
            ))
          ) : (
            <NoPluginsData>No Plugins Found</NoPluginsData>
          )}
        </Grid>
      )}
    </>
  );
}

export default Features;
