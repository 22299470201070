import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { Modal } from "@dfep/ui-component";
import { useModal } from "app/hooks/modal";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import React, { useEffect, useState } from "react";
import TableView from "./indexView";

const Feeds = props => {
  const [selected, setSelected] = useState([]);
  const [deleteItem, setDeleteItem] = useState([]);

  const [uid, setUid] = useState("");
  const {
    feedsData,
    isLoading,
    feedsCount,
    getFeedsData,
    access_type,
    syncFeeds,
    openSyncModal,
    closeSyncModal,
  } = props;
  const { sport_key, env_key } = props.match.params;
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  const headings = [
    { label: "Title", id: "title" },
    { label: "Published Date", id: "published_date", type: "date" },
    { label: "Feed Type", id: "feed_type" },
    { label: "News ID", id: "nid" },
    { label: "Category", id: "category" },
  ];

  useEffect(() => {
    setSelected([]);
  }, [getFeedsData]);

  const UpdateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/news/feeds/update/${uid}`,
      state: { from: props.location },
    });
  };

  const DeleteHandler = (event, uid, data) => {
    setDeleteItem(data);
    setUid(uid);
    openModalHandler("showDelete");
  };

  const handleModalClose = () => {
    setUid("");
    closeModalHandler();
    setDeleteItem({});
  };

  const handleModalDelete = () => {
    const url = `v1/nba/admin/data/feeds/${uid}`;
    props.deleteFeeds({ url }).then(res => {
      setSelected([]);
      getFeedsData();
      handleModalClose();
      setDeleteItem({});
    });
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },

    {
      id: "delete",
      label: "Delete",
      icon: <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />,
      handler: DeleteHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: feedsData });
    setSelected(newSelecteds);
  };

  const handleMultiDelete = event => {
    const url = `/v1/nba/admin/data/feeds/`;
    const uids = selected;

    props.deleteMultipleFeeds({ url, uids }).then(res => {
      setSelected([]);
      getFeedsData();
      handleModalClose();
    });
  };

  return (
    <>
      <TableView
        data={feedsData}
        actions={actions}
        headings={headings}
        openModal={showDelete}
        checkbox={true}
        selectedList={selected}
        handleSingleClick={handleSingleClick}
        handleSelectAllClick={handleSelectAllClick}
        multipleDeleteHandler={deleteMultipleHandler}
        handleModalClose={handleModalClose}
        handleModalDelete={handleModalDelete}
        multipleModal={showMultipleDelete}
        handleMultiDelete={handleMultiDelete}
        numSelected={selected.length}
        name="News Feed"
        isSorting
        totalCount={feedsCount}
        isLoading={isLoading}
        deleteItem={deleteItem}
      />
      <Modal
        className="small"
        open={openSyncModal}
        handleClose={closeSyncModal}
        title="Sync"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: closeSyncModal,
            additionalAttributes: {
              "data-testid": "sync-cancel",
            },
          },
          {
            id: "2",
            name: "Start",
            handler: syncFeeds,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-sync",
            },
          },
        ]}
      >
        Are you sure you want to start feeds sync ?
      </Modal>
    </>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    deleteFeeds: payload => dispatch(deleteRecord(payload)),
    deleteMultipleFeeds: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Feeds);

export { Container, Feeds as Component };
