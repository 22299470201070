import { Button } from "@dfep/ui-component";
import {
  convertValues,
  getFieldComponent,
  identifyComponent,
} from "utils/formFunctions";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FormWrapper } from "styles/global.style";
import { useHistory } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import PanelFooter from "app/components/Common/PanelFooter";
import React, { Fragment, useEffect, useState } from "react";

const SchemaForm = ({ schema, defaultValues, onSubmit, backUrl }) => {
  const [schemaState, setSchemaState] = useState(cloneDeep(schema));
  const {
    push,
    location: { state },
  } = useHistory();

  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all",
    shouldFocusError: true,
    defaultValues: defaultValues,
  });
  const content_type = useWatch({
    name: "content_type",
    control: methods.control,
    defaultValue: defaultValues?.content_type ?? "",
  });

  useEffect(() => {
    let schemaObject = cloneDeep(schema);
    // We can target field gid and nid using its index if needed
    if (content_type === "pbp") {
      schemaObject.class._schema.forEach(field => {
        if (field.uid === "gid") field.mandatory = true;
        else if (field.uid === "event_id") field.mandatory = true;
        else if (field.uid === "period") field.mandatory = true;
      });
      setSchemaState(schemaObject);
      methods.clearErrors("nid");
      // methods.trigger("nid");
    } else if (content_type === "video") {
      schemaObject.class._schema.forEach(field => {
        if (field.uid === "nid") field.mandatory = true;
      });
      setSchemaState(schemaObject);
      methods.clearErrors(["gid", "event_id", "period"]);
    } else {
      setSchemaState(schema);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [content_type]);

  const formSubmit = data => {
    return onSubmit(
      convertValues({
        data,
      }),
    );
  };

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(formSubmit)}
          className="maxWidthSm"
          data-testid="schema-form"
        >
          {schemaState?.class?._schema.map(item => {
            return (
              <Fragment key={item.uid}>
                {getFieldComponent(identifyComponent(item))}
              </Fragment>
            );
          })}
          <PanelFooter data-testid="panel-footer">
            <div>
              <Button
                onClick={() =>
                  push(
                    state ? state.from.pathname + state.from.search : backUrl,
                  )
                }
                variant="contained"
                color="primary"
                data-testid="cancel-form"
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
          </PanelFooter>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export default SchemaForm;
