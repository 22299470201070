import { StyledTypography } from "app/components/Common/Table/Table.style";
import { TableCell, TableRow } from "@dfep/ui-component";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonTableCell = ({ checkbox, headings, actionMenu, actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const menuHandler = event => {
    setAnchorEl(event.target);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <TableRow>
      {checkbox && (
        <TableCell className="checkbox" padding="checkbox">
          <Checkbox disabled={true} />
        </TableCell>
      )}
      {headings?.map(({ id, type, formator }) => (
        <TableCell key={id} id={id} className="table-cell-row" padding="none">
          <StyledTypography className="table-row">
            <Skeleton />
          </StyledTypography>
        </TableCell>
      ))}

      <TableCell className="table-cell-row actions" padding="none">
        {actionMenu ? (
          <>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={menuHandler}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu open={openMenu} anchorEl={anchorEl} onClose={handleMenuClose}>
              {actions?.map(({ id, handler, icon, label }) => (
                <MenuItem key={id}>
                  <ListItemIcon onClick={handleMenuClose}>
                    {icon}
                    <StyledTypography className="menu-data" variant="inherit">
                      {label}
                    </StyledTypography>
                  </ListItemIcon>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          actions?.map(({ id, handler, icon }) => (
            <IconButton key={id} aria-label="expand row" size="medium">
              <Skeleton />
            </IconButton>
          ))
        )}
      </TableCell>
    </TableRow>
  );
};

export default SkeletonTableCell;
