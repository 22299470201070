import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateEvents = props => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/arena/events`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/nba/admin/data/events/${uid}`;
    const {
      data: { event },
    } = await dispatch(get({ url }));
    setData(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/data/events/${uid}`,
          key: "event",
          returnErr: true,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Events"
        breadCrumbItems={[
          {
            title: "Update Events",
          },
        ]}
        backUrl={getURL}
      />
      {data && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={data}
          schema={schema}
          modelName="events"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateEvents;
