import { dobErrorHandler, dobValidation } from "utils/functions";
import { format } from "date-fns";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdatePlayer = props => {
  const [playerData, setPlayerData] = useState();
  const { team_name } = useSelector(state => state.application);
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/rosters/players/${team_name}`;

  useEffect(() => {
    getPlayerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPlayerData = useCallback(async () => {
    const url = `/v1/nba/admin/data/players/${uid}`;
    const {
      data: { player },
    } = await dispatch(get({ url }));
    setPlayerData(player);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      let form = data;
      if (form.dob && typeof data.dob !== "string") {
        dobValidation({ data });
        form = {
          ...form,
          dob: format(data.dob, "yyyy-MM-dd"),
        };
      }
      await dispatch(
        updateRecord({
          url: `/v1/nba/admin/data/players/${uid}`,
          key: "player",
          form,
          returnErr: true,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*  istanbul ignore next   */
      dobErrorHandler({ error, dispatch });
      console.log(error.message);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Player"
        breadCrumbItems={[
          {
            title: "Update Player",
          },
        ]}
        backUrl={getURL}
      />
      {playerData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={playerData}
          schema={schema}
          modelName="players"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdatePlayer;
