// /* eslint-disable react-hooks/exhaustive-deps */
// import { TabBox as AddButton } from "styles/global.style";
// import { Button, Modal } from "@dfep/ui-component";
// import { createRecord } from "services/createObject";
// import { Event_Data, seasons } from "utils/constants";
// import {
//   getYearArray,
//   validateKey,
//   validateName,
//   validateUrl,
// } from "utils/functions";
// import { ModalActionBox, ModalActionBoxWrap } from "./Environment.style";
// import {
//   Step,
//   StepConnector,
//   StepLabel,
//   StyledStepper,
// } from "app/components/Common/Stepper";
// import { updateRecord } from "services/updateObject";
// import { useDispatch } from "react-redux";
// import { useForm, useWatch } from "react-hook-form";
// import { useModal } from "app/hooks/modal";
// import { useParams } from "react-router-dom";
// import AddIcon from "@material-ui/icons/Add";
// import clonedeep from "lodash/cloneDeep";
// import Form from "app/components/Common/Form";
// import React, { useEffect, useState } from "react";

// const stepLabels = ["Env Config", "NBA Config", "Events Config"];

// const AddEnvironments = ({ leagues, fetchEnvironment }) => {
//   const [formData, setFormData] = useState({});
//   const EnvironmentForm = useForm({
//     mode: "all",
//     reValidateMode: "all",
//     shouldUnregister: true,
//     defaultValues: { ...formData },
//   });
//   const { control, getValues, unregister } = EnvironmentForm;
//   // Schema
//   const configSchema = [
//     {
//       title: "API key",
//       uid: "api_key",
//       name: "api_key",
//       type: "text",
//       defaultValue: formData?.api_key ?? "",
//       validations:
//         getValues(`provider`) === "tm"
//           ? { required: "API key is Required" }
//           : {},
//       field_metadata: {
//         options: [],
//       },
//       _schema: [],
//     },
//     {
//       title: "Venue Id",
//       uid: "venue_id",
//       name: "venue_id",
//       type: "text",
//       defaultValue: formData?.venue_id ?? "",
//       validations:
//         getValues(`provider`) === "tm"
//           ? { required: "Venue Id is Required" }
//           : {},
//       field_metadata: {
//         options: [],
//       },
//       _schema: [],
//     },
//     {
//       title: "Attraction Id",
//       uid: "attraction_id",
//       name: "attraction_id",
//       type: "text",
//       defaultValue: formData?.attraction_id ?? "",
//       validations:
//         getValues(`provider`) === "tm"
//           ? { required: "Attraction Id is Required" }
//           : {},
//       field_metadata: {
//         options: [],
//       },
//       _schema: [],
//     },
//   ];
//   const Step1 = [
//     {
//       title: "Environment Name",
//       uid: "name",
//       name: "name",
//       type: "text",
//       defaultValue: formData?.name ?? "",
//       validations: {
//         required: "Name is required",
//         maxLength: {
//           value: 60,
//           message: "exceeds max length. [60 MAX CHARACTER]",
//         },
//         validate: { pattern: val => validateName(val) || "Invalid Name" },
//       },
//       field_metadata: {
//         options: [],
//       },
//       _schema: [],
//     },
//     {
//       title: "Environment Key",
//       uid: "env_key",
//       name: "env_key",
//       type: "text",
//       defaultValue: formData?.env_key ?? "",
//       validations: {
//         required: "Environment Key is required",
//         validate: {
//           pattern: val => validateKey(val) || "Invalid Key",
//         },
//       },
//       field_metadata: {
//         options: [],
//       },
//       _schema: [],
//     },
//   ];
//   const Step2 = [
//     {
//       title: "League",
//       uid: "league",
//       name: "league",
//       type: "select",
//       defaultValue: formData?.league ?? "",
//       validations: {
//         required: "League is required ",
//       },
//       field_metadata: {
//         options: leagues.map(({ label, league_id }) => {
//           return { label, value: league_id };
//         }),
//       },
//       _schema: [],
//     },
//     {
//       title: "Season",
//       uid: "season",
//       name: "season",
//       type: "select",
//       defaultValue: formData?.season ?? "",
//       validations: {
//         required: "Season is required ",
//       },
//       field_metadata: {
//         options: seasons,
//       },
//       _schema: [],
//     },
//     {
//       title: "Year",
//       uid: "year",
//       name: "year",
//       type: "select",
//       defaultValue: formData?.year ?? "",
//       validations: {
//         required: "Year is required ",
//       },
//       field_metadata: {
//         options: getYearArray(),
//       },
//       _schema: [],
//     },
//   ];
//   const Step3 = [
//     {
//       title: "Provider",
//       uid: "provider",
//       name: "provider",
//       type: "select",
//       defaultValue: formData?.provider ?? "",
//       validations: {
//         // required: "Provider is required ",
//       },
//       field_metadata: {
//         options: [...Event_Data],
//       },
//       _schema: [],
//     },
//     ...configSchema,
//   ];

//   const urlSchema = [
//     {
//       title: "Url",
//       uid: "url",
//       name: "url",
//       type: "text",
//       defaultValue: formData?.url ?? "",
//       validations: {
//         required: "Url is required",
//         validate: { pattern: val => validateUrl(val) || "Invalid URL" },
//       },
//       field_metadata: {
//         options: [],
//       },
//       _schema: [],
//     },
//     ...configSchema,
//   ];

//   const AddEnvArray = [Step1, Step2, Step3];

//   // States
//   const { show, openModalHandler, closeModalHandler } = useModal();
//   const [activeStep, setActiveStep] = useState(0);
//   const [customSchema, setcustomSchema] = useState({
//     title: "",
//     name: "add-env",
//     uid: "add-env",
//     field_metadata: {
//       options: [],
//     },
//     defaultValue: "",
//     validations: {},
//     type: "group",
//     _schema: AddEnvArray[activeStep],
//   });

//   // react-router-hooks
//   const { sport_key } = useParams();

//   // Redux hooks
//   const dispatch = useDispatch();

//   // Hook Form Instance

//   const provider = useWatch({
//     name: "provider",
//     control,
//     defaultValue: "",
//   });

//   const api_key = useWatch({
//     name: "api_key",
//     control,
//     defaultValue: "",
//   });
//   const venue_id = useWatch({
//     name: "venue_id",
//     control,
//     defaultValue: "",
//   });
//   const attraction_id = useWatch({
//     name: "attraction_id",
//     defaultValue: "",
//     control,
//   });

//   // Api Calls
//   const updateEnvironment = async ({ form, env_key }) => {
//     const eventKeyConfig = {
//       api_key: form.api_key,
//       venue_id: form.venue_id,
//       attraction_id: form.attraction_id,
//     };
//     let event_config;
//     let tm_config = null;
//     if (form.provider === "carbon") {
//       event_config = {
//         enabled: true,
//         service_name: "carbon",
//         credentials: [{ key: "url", value: form.url }],
//       };
//       if (form.api_key && form.venue_id && form.attraction_id) {
//         tm_config = eventKeyConfig;
//       } else {
//         tm_config = null;
//       }
//     } else if (form.provider === "tm") {
//       tm_config = eventKeyConfig;
//       event_config = {
//         enabled: true,
//         service_name: Event_Data[1].value,
//         credentials: [
//           { key: "api_key", value: form.api_key },
//           { key: "venue_id", value: form.venue_id },
//           { key: "attraction_id", value: form.attraction_id },
//         ],
//       };
//     }
//     await dispatch(
//       updateRecord({
//         url: `/v1/admin/applications/${sport_key}/envs/${env_key}/settings`,
//         key: "settings",
//         form: {
//           nba_sync_config: {
//             year: form.year,
//             league_id: form.league,
//             season_id: form.season,
//           },
//           tm_config,
//           event_config: event_config,
//         },
//       }),
//     );
//     handleOnCancel();
//     fetchEnvironment();
//   };

//   // Form handlers

//   // Stepper Functions
//   // Partial Save Form
//   const handleNext = data => {
//     setFormData({ ...formData, ...data });
//     setActiveStep(activeStep + 1);
//   };

//   // Handle Back and keep Values
//   const handleBack = () => {
//     setActiveStep(activeStep - 1);
//   };

//   const handleModalOpen = () => {
//     openModalHandler("show");
//   };

//   const handleOnCancel = () => {
//     closeModalHandler();
//     setFormData({});
//     setActiveStep(0);
//   };

//   // Final Submission
//   const handleCreate = data => {
//     const form = { ...formData, ...data };
//     setFormData({ ...form });
//     submitForm(form);
//   };

//   const submitForm = async form => {
//     const { data } = await dispatch(
//       createRecord({
//         url: `/v1/admin/applications/${sport_key}/envs`,
//         key: "env",
//         form: {
//           env_key: form.env_key,
//           name: form.name,
//         },
//       }),
//     );

//     const {
//       env: { env_key },
//     } = data;
//     updateEnvironment({ form, env_key });
//   };

//   const count = 3;
//   const getStepContent = index => {
//     switch (index) {
//       case 0:
//         return (
//           <Form
//             formId={`step${activeStep}`}
//             customSchema={customSchema}
//             onSubmit={handleNext}
//             form={EnvironmentForm}
//           />
//         );
//       case 1:
//         return (
//           <Form
//             formId={`step${activeStep}`}
//             customSchema={customSchema}
//             onSubmit={handleNext}
//             form={EnvironmentForm}
//           />
//         );
//       case 2:
//         return (
//           <Form
//             formId={`step${activeStep}`}
//             customSchema={customSchema}
//             onSubmit={handleCreate}
//             form={EnvironmentForm}
//           />
//         );
//       /*istanbul ignore next*/
//       default:
//         break;
//     }
//   };

//   let controlDisabled = false;
//   if (
//     api_key?.length > 0 &&
//     venue_id?.length > 0 &&
//     attraction_id?.length > 0
//   ) {
//     controlDisabled = false;
//   } else if (
//     api_key?.length > 0 ||
//     venue_id?.length > 0 ||
//     attraction_id?.length > 0
//   ) {
//     controlDisabled = true;
//   }

//   useEffect(() => {
//     const { _schema } = clonedeep(customSchema);
//     if (provider === Event_Data[0].value && activeStep === 2) {
//       _schema.splice(1);
//       unregister(["api_key", "venue_id", "attraction_id"]);
//       _schema.splice(1, 0, ...urlSchema);
//       setcustomSchema({
//         ...customSchema,
//         _schema,
//       });
//     } else if (provider === Event_Data[1].value && activeStep === 2) {
//       _schema.splice(1);
//       unregister(["api_key", "venue_id", "attraction_id"]);
//       _schema.splice(1, 0, ...configSchema);
//       setcustomSchema({
//         ...customSchema,
//         _schema,
//       });
//     } else {
//       setcustomSchema({
//         ...customSchema,
//         _schema: AddEnvArray[activeStep],
//       });
//     }
//   }, [activeStep, provider]);

//   return (
//     <>
//       <AddButton data-testid="add-env-button">
//         <Button
//           data-testid="add-env"
//           id="last"
//           variant="contained"
//           color="primary"
//           startIcon={<AddIcon />}
//           onClick={handleModalOpen}
//         >
//           Add Environment
//         </Button>
//       </AddButton>

//       <Modal
//         data-testid="create-env-modal"
//         open={show}
//         className="small"
//         handleClose={closeModalHandler}
//         title="Create Environment"
//       >
//         <StyledStepper activeStep={activeStep} connector={<StepConnector />}>
//           {stepLabels.map((label, index) => (
//             <Step key={index}>
//               <StepLabel>{label}</StepLabel>
//             </Step>
//           ))}
//         </StyledStepper>
//         {getStepContent(activeStep)}
//         <ModalActionBoxWrap>
//           {activeStep !== 0 && (
//             <div>
//               <Button
//                 color="primary"
//                 variant="outlined"
//                 onClick={handleBack}
//                 data-testid="stepper-back"
//               >
//                 Back
//               </Button>
//             </div>
//           )}

//           <ModalActionBox
//             display="flex"
//             flexDirection="row"
//             justifyContent="flex-end"
//             alignItems="flex-end"
//           >
//             <Button
//               color="primary"
//               onClick={handleOnCancel}
//               data-testid="stepper-cancel"
//             >
//               Cancel
//             </Button>
//             {activeStep === count - 1 ? (
//               <Button
//                 data-testid="create-step"
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 form={`step${activeStep}`}
//                 disabled={controlDisabled}
//               >
//                 Create
//               </Button>
//             ) : (
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 form={`step${activeStep}`}
//                 data-testid="next-step"
//               >
//                 Next
//               </Button>
//             )}
//           </ModalActionBox>
//         </ModalActionBoxWrap>
//       </Modal>
//     </>
//   );
// };

// export default AddEnvironments;

/* eslint-disable react-hooks/exhaustive-deps */
import { TabBox as AddButton } from "styles/global.style";
import { Button, Modal } from "@dfep/ui-component";
import { createRecord } from "services/createObject";
import { ModalActionBox, ModalActionBoxWrap } from "./Environment.style";
import { setError, validateKey, validateName } from "utils/functions";
import { useDispatch } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const AddEnvironments = ({ fetchEnvironment }) => {
  const initialErrorState = {
    env_key: {
      hasError: false,
      message: "",
    },
    name: {
      hasError: false,
      message: "",
    },
  };

  // States
  const { show, openModalHandler, closeModalHandler } = useModal();
  const [env_key, setEnvKey, resetEnvKey] = useInput("");
  const [name, setEnvName, resetEnvName] = useInput("");
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));

  // This useEffect is call when key/name is changed
  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [name, env_key]);

  // react-router-hooks
  const { sport_key } = useParams();

  // Redux hooks
  const dispatch = useDispatch();

  const handleModalOpen = () => {
    openModalHandler("show");
    resetEnvName("");
    resetEnvKey("");
  };

  const handleOnCancel = () => {
    closeModalHandler();
    // fetchEnvironment();
  };

  // Form Validation for Name and Env Key
  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!name) {
        const error = setError(inputError, "name", "Name is required");
        setInputError(cloneDeep(error));
        reject({ error: "Name is required" });
      } else if (!validateName(name)) {
        const error = setError(inputError, "name", "Invalid Name");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid Name" });
      } else if (!env_key) {
        const error = setError(
          inputError,
          "env_key",
          "Environment Key is required",
        );
        setInputError(cloneDeep(error));
        reject({ error: "Environment Key is required" });
      } else if (!validateKey(env_key)) {
        const error = setError(inputError, "env_key", "Invalid Key");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid Key" });
      } else {
        const form = { name, env_key };
        resolve(form);
      }
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const form = await formValidation();
      await dispatch(
        createRecord({
          url: `/v1/admin/applications/${sport_key}/envs`,
          key: "env",
          form,
        }),
      );
      handleOnCancel();
      fetchEnvironment();
    } catch (error) {}
  };

  return (
    <>
      <AddButton data-testid="add-env-button">
        <Button
          data-testid="add-env"
          id="last"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleModalOpen}
        >
          Add Environment
        </Button>
      </AddButton>

      <Modal
        data-testid="create-env-modal"
        open={show}
        className="small"
        handleClose={closeModalHandler}
        title="Create Environment"
      >
        <form data-testid="create-env-form" noValidate onSubmit={onSubmit}>
          <TextField
            label="Environment Name"
            id="name"
            type="text"
            required={true}
            error={inputError.name.hasError}
            helperText={inputError.name.message}
            {...setEnvName}
          />
          <TextField
            label="Environment Key"
            id="env_key"
            type="text"
            required={true}
            error={inputError.env_key.hasError}
            helperText={inputError.env_key.message}
            {...setEnvKey}
          />
          <ModalActionBoxWrap>
            <ModalActionBox
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                color="primary"
                onClick={handleOnCancel}
                data-testid="cancel-modal"
              >
                Cancel
              </Button>
              <Button
                data-testid="submit-create-env"
                type="submit"
                variant="contained"
                color="primary"
                disabled={name.length === 0 || env_key.length === 0}
              >
                Create
              </Button>
            </ModalActionBox>
          </ModalActionBoxWrap>
        </form>
      </Modal>
    </>
  );
};

export default AddEnvironments;
