import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateStartingLineup = props => {
  const [lineupData, setLineupData] = useState();
  const { team_name } = useSelector(state => state.application);
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/game/startingLineup/${team_name}`;

  useEffect(() => {
    getLineupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLineupData = useCallback(async () => {
    const url = `/v1/nba/admin/data/line_up/${uid}`;
    const {
      data: { lineups },
    } = await dispatch(get({ url }));
    setLineupData(lineups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          url: `/v1/nba/admin/data/line_up/${uid}`,
          key: "lineups",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Starting Lineup"
        breadCrumbItems={[
          {
            title: "Update Starting Lineup",
          },
        ]}
        backUrl={getURL}
      />
      {lineupData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={lineupData}
          schema={schema}
          modelName="startingLineup"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateStartingLineup;
