/* eslint-disable no-unused-vars */
import { Button } from "@dfep/ui-component";
import { Constants, schedule_type } from "utils/constants";
import { createRecord } from "services/createObject";
import { DashedDivider, FormWrapper, Switch } from "styles/global.style";
import { getSettings } from "services/getObjects";
import { getSettingScheduler } from "services/getObjects";
import { requestAPI } from "services/helper";
import { SwitchBox, Title } from "./dataSync.style";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useEffect, useState } from "react";
import ScheduleTypeView from "app/pages/Dashboard/settings/syncData/ScheduleTypeView";
import Skeleton from "@material-ui/lab/Skeleton";

import { updateRecord } from "services/updateObject";

export default function DataSync({ match }) {
  const { sport_key, env_key } = match.params;
  const [schedules, setSchedules] = useState(null);

  const [isLoading, setIsLoading] = useState();

  const { access_type } = useSelector(state => state.application);
  const { READ_ONLY } = Constants;

  const dispatch = useDispatch();

  const getSchedule = async () => {
    isLoading ?? setIsLoading(true);
    const {
      data: { schedule },
      status,
    } = await dispatch(getSettingScheduler());

    setSchedules(schedule);
    setIsLoading(false);
    return status;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const createSchedule = async formData => {
    const url = `v1/scheduler/schedule`;
    await dispatch(createRecord({ url, key: "schedule", form: formData }));
    return getSchedule();
  };

  useEffect(() => {
    getSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PanelHeader
        title="Data Sync"
        breadCrumbItems={[
          {
            title: "Settings",
            link: `${match.url.split("/syncData")[0]}`,
          },
          {
            title: "Data Sync",
          },
        ]}
      />
      <FormWrapper>
        {isLoading === true ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : null}

        {isLoading === false && schedules ? (
          <>
            <Title variant="h6">Periodic Sync</Title>
            {schedule_type.map((scheduleItem, index) => (
              <ScheduleTypeView
                key={index}
                data={schedules.find(schedule => {
                  return scheduleItem.value === schedule.schedule_type;
                })}
                schedule_type={scheduleItem.value}
                setSchedules={setSchedules}
                label={scheduleItem.label}
                createSchedule={createSchedule}
                access_type={access_type}
                READ_ONLY={READ_ONLY}
              />
            ))}
          </>
        ) : null}
      </FormWrapper>
    </>
  );
}
