import BackArrow from "./arrow-left.svg";
import MenuIcon from "./menu-icon.svg";
import React from "react";

const Icons = ({ name }) => {
  switch (name) {
    case "BackArrow":
      return <BackArrow />;
    case "MenuIcon":
      return <MenuIcon />;
    default:
      return <MenuIcon />;
  }
};

export default Icons;
