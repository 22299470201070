import { FormWrapper } from "styles/global.style";
import TemplateMapper from "app/components/Common/CustomFieldForm/CustomFieldMapper";

const UserProfileSettings = () => {
  // States

  // Params

  // Hooks

  // Functions

  // LifeCycle

  // Render
  return (
    <FormWrapper className="settings-wrap" data-testid="form-wrapper">
      <TemplateMapper modelName="profile" title="Profile" uid="profile" />
    </FormWrapper>
  );
};

export default UserProfileSettings;
