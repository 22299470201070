import React, { useEffect, useState } from "react";

const ListItemWrapper = ({ listItem, isSidebarOpen, handledraweropen }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isSidebarOpen === false) {
      setOpen(false);
    }

    return () => {};
  }, [isSidebarOpen]);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(!isOpen);

    if (isSidebarOpen === false) {
      handledraweropen();
    }
  };

  return (
    <>{listItem({ isOpen, handleClick, isSidebarOpen, handledraweropen })}</>
  );
};

export default ListItemWrapper;
