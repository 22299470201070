import { handleError, query as searchQuery } from "../helper";
import { httpRequest } from "../index";

export const getUsers = ({ sort, value, skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/app_user/admin/user",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).users,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getApplication = ({
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/admin/applications",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).applications,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getEnvironment = ({ sport_key }) => {
  let httpObject = {
    url: `/v1/admin/applications/${sport_key}/envs`,
    data: {
      _method: "Get",
    },
  };
  return hitApi(httpObject, null, null, "created_at", 1);
};

export const getAssets = ({
  sport_key,
  sort,
  value,
  skip,
  limit,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/applications/${sport_key}/assets`,
    data: {
      _method: "Get",
    },
  };
  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).assetImageSearch,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getCustomService = ({ skip, limit, sort, value }) => {
  const httpObject = {
    url: `/v1/custom_service/admin/data/apps`,
    data: {
      _method: "Get",
      projection: {
        key: 1,
        name: 1,
        uid: 1,
        url: 1,
      },
    },
  };

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getSchemaClasses = ({
  limit,
  sort,
  sortValue,
  skip,
  projection,
  query,
  searchText,
}) => {
  let httpObject = {
    url: `/v1/custom_schema/admin/classes`,
    data: {
      _method: "Get",
      projection,
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).classes,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getSchemaObjects = ({
  objectType,
  limit,
  sort,
  sortValue,
  skip,
  searchText,
}) => {
  let httpObject = {
    url: `/v1/custom_schema/admin/classes/${objectType}/objects`,
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        updated_at: 1,
        created_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).objects,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};
// Settings/feature
export const getCMSLookup = ({ sport_key, env_key, showToast }) => {
  const httpObject = {
    url: `/v1/admin/applications/${sport_key}/envs/${env_key}/settings/cms_lookup`,
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        title: 1,
      },
    },
  };
  return hitApi(
    httpObject,
    null,
    null,
    null,
    null,
    null,
    showToast,
    false,
    true,
  );
};

export const getSettings = ({ sport_key, env_key, projection }) => {
  let httpObject = {
    url: `v1/admin/applications/${sport_key}/envs/${env_key}/settings`,
    data: {
      _method: "Get",
      projection,
    },
  };
  return hitApi(httpObject);
};

export const getIntegrations = ({ sport_key, env_key, searchText }) => {
  let httpObject = {
    url: `v1/admin/applications/${sport_key}/envs/${env_key}/integrations`,
    data: {
      _method: "Get",
    },
  };

  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).integrations,
    };
  }
  return hitApi(httpObject);
};

export const getIntegrationSchema = ({ sport_key, env_key, uid }) => {
  let httpObject = {
    url: `v1/admin/applications/${sport_key}/envs/${env_key}/integrations/${uid}`,
    data: {
      _method: "Get",
    },
  };

  return hitApi(httpObject);
};

export const getSettingScheduler = () => {
  let httpObject = {
    url: `/v1/scheduler/schedule`,
    data: {
      _method: "Get",
    },
  };
  return hitApi(httpObject);
};

// Broadcast Topic
export const getBroadcastTopicData = ({
  sport_key,
  env_key,
  query,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  let httpObject = {
    url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics`,
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        name: 1,
        key: 1,
        service: 1,
        updated_at: 1,
        created_at: 1,
      },
      query: { service: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).topics,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getLinks = ({ sport_key, skip, limit, sort, sortValue }) => {
  const httpObject = {
    url: `/v1/admin/applications/${sport_key}/internal_links`,
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        name: 1,
        link: 1,
      },
    },
  };
  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getInstallationData = ({
  device_type,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "v1/push_notification/admin/installation_data",
    data: {
      _method: "Get",
      query: { device_type },
      projection: {
        user_uid: 1,
        device_token: 1,
        device_type: 1,
        updated_at: 1,
        created_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).installationData,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getCollaborators = ({
  sport_key,
  env_key,
  limit,
  skip,
  sort,
  value,
}) => {
  const httpObject = {
    url: `/v1/admin/applications/${sport_key}/envs/${env_key}/users`,
    data: {
      _method: "Get",
    },
  };
  return hitApi(httpObject, skip, limit, sort, value);
};

export const getIosInstallationData = () => {
  const httpObject = {
    url: "v1/push_notification/admin/installation_data",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };
  return hitApi(httpObject);
};

export const getDropsContent = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/drops/portal`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        title: 1,
        status: 1,
        start_date: 1,
        end_date: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).dropContent,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getDropsEvents = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/drops/events`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        name: 1,
        value: 1,
        entry_value: 1,
        enabled: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).dropEvents,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getDropsv2Content = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/drops/v2/portal`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        title: 1,
        status: 1,
        start_date: 1,
        end_date: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).dropv2Content,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getDropsv2Config = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `v1/admin/drops/other/v2/config`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        title: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).dropv2Config,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getWebhooks = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/webhooks`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        url: 1,
        method: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).webhooks,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getWebhook = ({ uid, sport_key, env_key }) => {
  const httpObject = {
    url: `/v1/admin/webhooks/${uid}`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      count: true,
    },
  };
  return hitApi(httpObject);
};

export const getStoriesData = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/stories`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        title: 1,
        story_type: 1,
        category: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).stories,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getSegments = ({ sort, value, skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/push_notification/admin/segment",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        type: 1,
        name: 1,
        created_at: 1,
        data: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).segment,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getPushMessages = ({
  skip,
  limit,
  sort,
  sortValue,
  status,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/push_notification/admin/push",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        created_at: 1,
        updated_at: 1,
        "message.title": 1,
        type: 1,
        status: 1,
        pn_type: 1,
      },
    },
  };

  if (status) {
    httpObject.data.query = { status };
  }

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).pushMessages,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getGameDetails = ({
  query,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/game_details",
    data: {
      _method: "Get",
      projection: {
        year: 1,
        uid: 1,
        gid: 1,
        gcode: 1,
        gametime: 1,
        season_id: 1,
        league_id: 1,
        updated_at: 1,
        created_at: 1,
        vls: 1,
        hls: 1,
        stt: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).gameDetails,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getGamePlayByPlay = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/play_by_play",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        evt: 1,
        gcode: 1,
        gid: 1,
        league_id: 1,
        p: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).playByPlay,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getGameStartingLineup = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/line_up",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        evt: 1,
        gcode: 1,
        gid: 1,
        league_id: 1,
        p: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).startingLineup,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getGamePlayerLogs = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/game_player_logs",
    data: {
      _method: "Get",
      projection: {
        tid: 1,
        created_at: 1,
        updated_at: 1,
        gametime: 1,
        gid: 1,
        league_id: 1,
        pid: 1,
        season_id: 1,
        uid: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).gamePlayerLog,
    };
  }
  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getTemplate = ({
  skip,
  limit,
  sort,
  sortValue,
  template_type,
  searchText,
}) => {
  const httpObject = {
    url: "v1/push_notification/admin/push_template",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        name: 1,
        type: 1,
        body: 1,
        created_at: 1,
        updated_at: 1,
      },
    },
  };

  if (template_type === "others") {
    httpObject.data.query = { type: "others" };
  } else {
    httpObject.data.query = { type: { $ne: "others" } };
  }

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).pushTemplates,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getMenu = ({
  device_type,
  sort,
  value,
  skip,
  limit,
  searchText,
}) => {
  const httpObject = {
    url: "v1/nba/admin/app/menu",
    data: {
      _method: "Get",
      query: { device_type },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).device_menu,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getNewsFeeds = ({ skip, limit, sort, sortValue, searchText }) => {
  const httpObject = {
    url: "v1/nba/admin/data/feeds",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).newsFeed,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getWscFeeds = ({ skip, limit, sort, sortValue, searchText }) => {
  const httpObject = {
    url: "v1/nba/admin/data/wsc_feeds",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        title: 1,
        content_type: 1,
        created_at: 1,
        updated_at: 1,
        event_id: 1,
        period: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).wscFeed,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getStanding = ({
  team_name,
  tid,
  sort,
  limit,
  value,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/team_standing",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        tid: 1,
        league_id: 1,
        season_id: 1,
        year: 1,
        created_at: 1,
        updated_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teamStanding,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getSeasonAverage = ({
  team_name,
  tid,
  sort,
  limit,
  value,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/team_season_average",
    data: {
      _method: "Get",
      projection: {
        tn: 1,
        uid: 1,
        tid: 1,
        league_id: 1,
        season_id: 1,
        year: 1,
        created_at: 1,
        updated_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teamSeasonAverage,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getTeamsLeaders = ({
  team_name,
  tid,
  sort,
  limit,
  value,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/team_leaders",
    data: {
      _method: "Get",
      projection: {
        tn: 1,
        uid: 1,
        tid: 1,
        league_id: 1,
        season_id: 1,
        year: 1,
        created_at: 1,
        updated_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teamLeader,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getLeaderDetails = ({
  team_name,
  tid,
  sort,
  limit,
  value,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/team_leader_details",
    data: {
      _method: "Get",
      projection: {
        tn: 1,
        uid: 1,
        tid: 1,
        league_id: 1,
        season_id: 1,
        year: 1,
        created_at: 1,
        updated_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teamLeaderDetails,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getGameLeader = ({
  team_name,
  tid,
  sort,
  limit,
  value,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/game_leaders",
    data: {
      _method: "Get",
      projection: {
        gid: 1,
        uid: 1,
        tid: 1,
        year: 1,
        season_id: 1,
        league_id: 1,
        updated_at: 1,
        created_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).gameLeader,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getTeamStats = ({
  team_name,
  tid,
  sort,
  limit,
  value,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/team_stats",
    data: {
      _method: "Get",
      projection: {
        gid: 1,
        uid: 1,
        tid: 1,
        year: 1,
        season_id: 1,
        league_id: 1,
        updated_at: 1,
        created_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teamStats,
    };
  }

  return hitApi(httpObject, skip, limit, sort, value);
};

export const getTeamSplits = ({
  team_name,
  tid,
  sort,
  limit,
  sortValue,
  skip,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = { $ne: tid };
  } else {
    query = tid;
  }
  const httpObject = {
    url: "/v1/nba/admin/data/team_splits",
    data: {
      _method: "Get",
      projection: {
        gid: 1,
        uid: 1,
        tid: 1,
        year: 1,
        month: 1,
        season_id: 1,
        league_id: 1,
        updated_at: 1,
        created_at: 1,
      },
      query: { tid: query },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teamSplits,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getMockGames = ({ skip, limit, sort, sortValue, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/data/recorded_games/listing",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).mockGame,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getDeviceSettings = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/setting",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).device_setting,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getDeviceConfig = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/config",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).device_config,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getDeviceMenu = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/menu",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).device_menu,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getDeviceEula = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/eula",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).device_eula,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getSchedule = ({
  team_name,
  tid,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  let query;
  if (team_name === "others") {
    query = {
      $or: [
        {
          "v.tid": { $ne: tid },
        },
        {
          "h.tid": { $ne: tid },
        },
      ],
    };
  } else {
    query = {
      $or: [
        {
          "v.tid": tid,
        },
        {
          "h.tid": tid,
        },
      ],
    };
  }
  const httpObject = {
    url: "/v1/nba/admin/data/schedules",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        tid: 1,
        gcode: 1,
        gid: 1,
        game_state: 1,
        gametime: 1,
        league_id: 1,
        season_id: 1,
        created_at: 1,
        updated_at: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).schedules,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getAdvTeamStats = ({
  query,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/advanced_team_stats",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        tid: 1,
        gametime: 1,
        league_id: 1,
        season_id: 1,
        created_at: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).advancedTeamStats,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getTeams = ({ skip, limit, sort, sortValue, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/data/teams",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).teams,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getOnBoardScreen = ({
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/app/onboard_screen",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).onboarding_screen,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getOnBoardScreenOptions = ({ skip, limit, sort, sortValue }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/onboard_screen",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        title: 1,
      },
    },
  };

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getDeviceMeassage = ({
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/messages",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        key: 1,
        uid: 1,
        updated_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).device_messages,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getEvents = ({ skip, limit, sort, sortValue, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/data/events",
    data: {
      _method: "Get",
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).events,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getOnBoardCore = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/onboard",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).onboarding_core,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getParkingAndDirection = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/parking_and_directions/",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).parkingAndDirection,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getPlayers = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/players",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        fn: 1,
        league_id: 1,
        ln: 1,
        pid: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).players,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getPlayerStat = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/player_stats",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        league_id: 1,
        pid: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).playerStats,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getAdvPlayerStat = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/advanced_player_stats",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        league_id: 1,
        pid: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).advancedPlayerStats,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getPlayerAvg = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/player_average",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        league_id: 1,
        pid: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).playerAverage,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getCoaches = ({
  skip,
  limit,
  sort,
  sortValue,
  query,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/nba/admin/data/coaches",
    data: {
      _method: "Get",
      projection: {
        created_at: 1,
        league_id: 1,
        pid: 1,
        season_id: 1,
        tid: 1,
        uid: 1,
        updated_at: 1,
        year: 1,
        fn: 1,
        ln: 1,
      },
      query,
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).coaches,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getArenaPolicies = ({ skip, limit, searchText }) => {
  const httpObject = {
    url: "/v1/nba/admin/app/policies",
    data: {
      _method: "Get",
      query: { device_type: "ios" },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).policies,
    };
  }

  return hitApi(httpObject, skip, limit);
};

export const getDataOptions = ({ url, query }) => {
  const httpObject = {
    url,
    data: {
      _method: "Get",
      query,
      projection: {
        uid: 1,
        name: 1,
        filename: 1,
        personal_id: 1,
        device_token: 1,
      },
    },
  };
  return hitApi(httpObject);
};
// export const getReport = uid => {
//   const httpObject = {
//     url: `v1/push_notification/admin/push/${uid}`,
//     data: {
//       _method: "Get",
//     },
//   };
//   return hitApi(httpObject);
// };

export const getVenueData = ({ url }) => {
  const httpObject = {
    url,
    method: "get",
  };
  return hitApi(httpObject);
};

export const getSearchData = ({ url }) => {
  const httpObject = {
    url,
    method: "get",
  };
  return hitApi(httpObject);
};

export const getGoogleMapApiKey = ({ url }) => {
  const httpObject = {
    url,
    method: "get",
  };
  return hitApi(httpObject);
};

export const getLevelsData = ({ url }) => {
  const httpObject = {
    url,
    method: "get",
  };
  return hitApi(httpObject);
};

export const getInteractiveMapVenues = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/interactive_maps/venues`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        name: 1,
        id: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).interactiveMapVenues,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getInteractiveMapFeatures = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/interactive_maps/features`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        geometry: 1,
        properties: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).interactiveMapFeatures,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getInteractiveMapCategories = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/interactive_maps/categories`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        display_name: 1,
        id: 1,
        created_at: 1,
        updated_at: 1,
        uid: 1,
        child_categories: 1,
        venue_id: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).interactiveMapCategories,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getInteractiveMapLevels = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/interactive_maps/levels`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        id: 1,
        venue_id: 1,
        name: 1,
        is_default: 1,
        ordinal: 1,
        created_at: 1,
        updated_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).interactiveMapLevels,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getOrderHistory = ({
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/admin/order/history",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        order_id: 1,
        order_status: 1,
        order_date: 1,
        provider: 1,
        user_id: 1,
        transaction_id: 1,
        created_at: 1,
        updated_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).history,
    };
  }
  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getRefundData = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/order/refund`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        user_id: 1,
        order_id: 1,
        transaction_id: 1,
        status: 1,
        provider: 1,
        created_at: 1,
        updated_at: 1,
        payment_gateway: 1,
        request_refund_date: 1,
        venue_id: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).refund,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getAnnouncementData = ({
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "/v1/admin/drops/portal/announcements",
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        title: 1,
        cs_uid: 1,
        announcements: 1,
        drop_uids: 1,
        created_at: 1,
        updated_at: 1,
        deleted_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).dropAnnouncements,
    };
  }
  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getUserProfileData = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/app_user/admin/user/profile`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        user_id: 1,
        last_login_at: 1,
        uid: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).userProfile,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getSegmentationSdkVersion = ({ url }) => {
  const httpObject = {
    url,
    method: "get",
  };
  return hitApi(httpObject);
};

export const getGeoLocationsData = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: `/v1/admin/geo_fencing/geo_location`,
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        type: 1,
        geometry: 1,
        "properties.name": 1,
        "properties.type": 1,
        created_at: 1,
        updated_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).geoLocations,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

export const getLightTheBeamData = ({
  sport_key,
  env_key,
  skip,
  limit,
  sort,
  sortValue,
  searchText,
}) => {
  const httpObject = {
    url: "v1/nba/admin/data/light_the_beam_info",
    params: {
      sport_key,
      env_key,
    },
    data: {
      _method: "Get",
      projection: {
        uid: 1,
        enable: 1,
        animation_time_period_after_win: 1,
        text: 1,
        animation_navigation: 1,
        when: 1,
        created_at: 1,
        updated_at: 1,
      },
    },
  };

  /*istanbul ignore else*/
  if (searchText) {
    httpObject.data["query"] = {
      ...searchQuery(searchText).lightTheBeam,
    };
  }

  return hitApi(httpObject, skip, limit, sort, sortValue);
};

const hitApi = (
  httpObject,
  skip,
  limit,
  sort,
  sortValue,
  key,
  showToast = true,
  helperObject = false,
  rejectError = false,
) => (dispatch, getState) => {
  const { sport_key, env_key } = getState().application;
  if (!httpObject.data) {
    httpObject.data = {};
  }
  httpObject.headers = {
    sport_key,
    env_key,
  };

  if (skip !== null) {
    httpObject.data.options = {
      skip,
      limit,
    };
  }

  if (sort && sortValue) {
    httpObject.data.options = {
      ...httpObject.data.options,
      sort: {
        [sort]: parseInt(sortValue),
      },
    };
  }

  httpObject.data.count = true;
  return new Promise(function (resolve, reject) {
    httpRequest(httpObject)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (showToast) {
          handleError(error, dispatch);
        }
        if (rejectError) {
          reject(error);
        }
      });
  });
};
