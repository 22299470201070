import styled from "styled-components";

export const LoaderWrap = styled.div`
  position: fixed;
  width: 100%;
  text-align: center;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  z-index: 1350;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    border: 1px solid ${props => props.theme.palette.white.main};
    width: 90px;
    height: 89px;
    position: absolute;
    right: 0;
    margin: 0 auto;
    left: 0;
    border-radius: 50%;
  }
  img {
    max-width: 100px;
  }
`;
