import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const GetStandingView = ({
  deleteModal,
  handleModalClose,
  deleteHandler,
  totalCount,
  isLoading,
  headings,
  actions,
  checkbox,
  numSelected,
  name,
  handleMultiDelete,
  multipleModal,
  openSync,
  closeSync,
  sendSync,
  deleteData,
  ...restProps
}) => {
  return (
    <>
      {isLoading ? (
        <SkeletonTable
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
        />
      ) : totalCount > 0 ? (
        <Table
          totalCount={totalCount}
          headings={headings}
          checkbox={checkbox}
          name={name}
          numSelected={numSelected}
          actions={actions}
          {...restProps}
        />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <Modal
        className="small"
        open={deleteModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: deleteData?.tid,
          module: name,
        })}
      </Modal>
      <Modal
        className="small"
        open={multipleModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: handleMultiDelete,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        Are you sure want to delete <strong>{numSelected}</strong> items?
      </Modal>
      <Modal
        className="small"
        open={openSync}
        handleClose={closeSync}
        title="Sync"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: closeSync,
            additionalAttributes: { "data-testid": "cancel-btn" },
          },
          {
            id: "2",
            name: "Start",
            handler: sendSync,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-btn",
            },
          },
        ]}
      >
        Are you sure want to start team standing sync?
      </Modal>
    </>
  );
};

export default GetStandingView;
