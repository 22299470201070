import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import styled from "styled-components";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  li {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    &.MuiBreadcrumbs-separator {
      margin: 0;
      color: rgba(0, 0, 0, 0.54);
      padding: 0 9px;
    }
    p {
      color: ${({ theme }) => theme.palette.black.blackLighter};
    }
  }
`;
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.linkColor.main};
  cursor: pointer;
`;
