import { Button, Modal } from "@dfep/ui-component";
import { capitalize } from "utils/functions";
import {
  DashedDivider,
  FieldGroup,
  FormWrapper,
  ImageUploaderWrap,
} from "styles/global.style";
import { DiabledLayer, Grid } from "../Template.style";
import { Container as ImageUploader } from "app/pages/Dashboard/pushNotification/Messages/form/upload/imageUpload";
import { Preview } from "@dfep/ui-component";
import { useForm, useWatch } from "react-hook-form";
import Accordin from "app/components/Common/Form/Accordion";
import CheckBox from "app/components/Common/Form/Checkbox";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import FieldBorder from "app/components/Common/FieldBorder ";
import IconButton from "@material-ui/core/IconButton";
import JavaScriptEditor from "app/components/Common/JavaScriptEditor";
import JsonEditor from "app/components/Common/Form/jsonEditor";
import Label from "app/components/Common/Label";
import PanelFooter from "app/components/Common/PanelFooter";
import React, { Fragment, useState } from "react";
import SelectField from "app/components/Common/Form/SelectField";
import TextField from "app/components/Common/Form/TextField";

const IsolateReRender = ({ control, imageUrl, team_name, title, body }) => {
  const message = useWatch({
    control,
    name: "message",
    defaultValue: {
      titleData: title ?? "",
      body: body ?? "",
    },
  });

  return (
    <Preview
      team={capitalize(team_name)}
      title={message.titleData}
      body={message.body}
      avatar={imageUrl}
    />
  ); // only re-render at the component level, when message changes
};

const UpdateTemplateForm = props => {
  const [url, setUrl] = useState();
  const [iconUrl, setIconUrl] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [bodyModal, setbodyModal] = useState(false);
  const type = props?.push_template?.type;

  const TemplateForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      type: props?.push_template?.type,
      name: props?.push_template?.name,
      enabled: props?.push_template?.enabled,
      message: {
        titleType: props?.push_template?.title?.type,
        titleData: props?.push_template?.title?.data,
        subTitleType: props?.push_template?.subtitle?.type,
        subTitleData: props?.push_template?.subtitle?.data,
        bodyType: props?.push_template?.body?.type,
        body: props?.push_template?.body?.data,
        click_action: props?.push_template?.click_action,
      },
      settings: {
        badge: props?.push_template?.badge,
        sound: props?.push_template?.sound,
        silent_push: props?.push_template?.silent_push,
        icon_color: props?.push_template?.icon_color,
        deeplink_url: props?.push_template?.deeplink_url,
        collapse_key: props?.push_template?.collapse_key,
        jsonEditor:
          JSON.stringify(props?.push_template?.data, null, "\t") ?? "{}",
      },
    },
  });
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    formState: { dirtyFields, errors },
  } = TemplateForm;

  const submitFormHandler = ({
    message,
    settings,
    name,
    type,
    enabled,
    ...data
  }) => {
    const form = {
      name,
      type,
      enabled,
      title: {
        isDefault: false,
        type: message.titleType,
        data: message.titleData,
      },
      subtitle: {
        isDefault: false,
        type: message.subTitleType,
        data: message.subTitleData,
      },
      body: {
        isDefault: false,
        type: message.bodyType,
        data: message.body,
      },
      buttons: [],
      image_url: message?.image_url,
      icon_url: message?.icon_url,
      click_action: message.click_action,
      badge: settings?.badge,
      collapse_key: settings?.collapse_key,
      deeplink_url: settings?.deeplink_url,
      icon_color: settings?.icon_color,
      silent_push: settings?.silent_push,
      sound: settings?.sound,
      data: settings?.jsonEditor ? JSON.parse(`${settings?.jsonEditor}`) : "",
    };

    props.UpdateTemplate(form);
  };

  const ImageState = img => {
    setUrl(img);
  };

  const IconState = img => {
    setIconUrl(img);
  };

  const typeTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Type is required",
    },
    fieldName: "type",
    uid: "type-textFiled",
    title: "type",
    type: "text",
    className: "type-title",
    readOnly: true,
    disabled: false,
    doNotTrim: false,
  };

  const NameTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Name is required",
      maxLength: {
        value: 200,
        message: "exceeds max length. [200 MAX CHARACTER]",
      },
    },
    fieldName: "name",
    uid: "name-textFiled",
    title: "Name",
    type: "text",
    className: "name-title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const EnabledProps = {
    watchInstance: {},
    watchOn: {},
    control,
    fieldName: "enabled",
    validations: {},
    showBorder: false,
    defaultValue:
      props?.push_notification?.payload?.message?.silent_push ?? false,
    className: "check",
    disabled: false,
    uid: "enabled-checkBox",
    title: "Enabled",
  };

  const SelectTitleTextField = {
    uid: "testTitle-select",
    title: "Select Type",
    control,
    fieldName: "message.titleType",
    validations: {},
    className: "select-field",
    readOnly: type === "others" ? true : false,
    options: [
      {
        label: "String",
        value: "string",
      },
      {
        label: "Code",
        value: "code",
      },
    ],
  };

  const TitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Title is required",
    },
    fieldName: "message.titleData",
    uid: "Title-textFiled",
    title: "Title",
    type: "text",
    className: "title",
    readOnly: false,
    disabled: false,
    doNotTrim: true,
  };

  const SelectSubTitle = {
    uid: "subTitle-select",
    title: "Select Type",
    control,
    fieldName: "message.subTitleType",
    validations: {},
    className: "select-field",
    readOnly: type === "others" ? true : false,
    options: [
      {
        label: "String",
        value: "string",
      },
      {
        label: "Code",
        value: "code",
      },
    ],
  };

  const SubTitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Sub Title is required",
    },
    fieldName: "message.subTitleData",
    uid: "sub-title-textFiled",
    title: " Sub Title",
    type: "text",
    className: "sub-title",
    readOnly: false,
    disabled: false,
    doNotTrim: true,
  };

  const SelectBody = {
    uid: "body-select",
    title: "Select Type",
    control,
    fieldName: "message.bodyType",
    validations: {},
    className: "select-field",
    readOnly: type === "others" ? true : false,
    options: [
      {
        label: "String",
        value: "string",
      },
      {
        label: "Code",
        value: "code",
      },
    ],
  };

  const BodyTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Body is required",
    },
    fieldName: "message.body",
    uid: "body-textFiled",
    title: "Body",
    type: "text",
    className: "body",
    readOnly: false,
    disabled: false,
    doNotTrim: true,
  };

  const ClickProps = {
    watchInstance: "",
    watchOn: "",
    showBorder: false,
    control,
    validations: {},
    fieldName: "message.click_action",
    uid: "click-textFiled",
    title: "Enter Click Action",
    type: "text",
    className: "click-textFiled",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const AccordinProps = {
    uid: "test-accordion",
    title: "Advanced Settings",
    showBorder: true,
    fieldName: "settings",
    errors,
  };

  const SoundTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.sound",
    uid: "sound-textFiled",
    title: "Sound",
    type: "text",
    showBorder: false,
    className: "sound",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const CheckBoxProps = {
    watchInstance: {},
    watchOn: {},
    control,
    fieldName: "settings.silent_push",
    validations: {},
    showBorder: false,
    defaultValue:
      props?.push_notification?.payload?.message?.silent_push ?? false,
    className: "check",
    disabled: false,
    uid: "test-checkBox",
    title: "Silent Push",
  };

  const IconTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.icon_color",
    uid: "icon-textFiled",
    title: "Icon Color",
    type: "text",
    className: "icon",
    showBorder: false,
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const BadgeTextField = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.badge",
    uid: "Badge-textFiled",
    title: "Badge",
    type: "number",
    showBorder: false,
    className: "badge",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const DeepLinkTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.deeplink_url",
    uid: "deep-textFiled",
    title: "DeepLink",
    type: "text",
    showBorder: false,
    className: "deep_link",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const CollapseTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.collapse_key",
    uid: "collapse-textFiled",
    title: "Collapse key",
    type: "text",
    showBorder: false,
    className: "collapse_key",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const JsonEditorProps = {
    fieldName: "settings.jsonEditor",
    showBorder: true,
    validations: {},
    control,
    uid: "test-json",
    title: "Data",
    setError,
  };

  const closeModal = () => {
    setOpenModal(false);
    setSubModal(false);
    setbodyModal(false);
  };

  const HandleModal = () => {
    const title = getValues("message.titileJSEditor");
    setValue("message.titleData", title, { shouldDirty: true });
    setOpenModal(false);
  };
  const subHandleModal = () => {
    const subtitle = getValues("message.subTitileJSEditor");
    setValue("message.subTitleData", subtitle, { shouldDirty: true });
    setSubModal(false);
  };

  const bodyHandleModal = () => {
    const body = getValues("message.bodyJSEditor");
    setValue("message.body", body, { shouldDirty: true });
    setbodyModal(false);
  };

  return (
    <Fragment>
      <FormWrapper>
        <form
          id="add_template"
          noValidate
          data-testid="add_template"
          onSubmit={handleSubmit(submitFormHandler)}
        >
          <FieldGroup className="maxWidthSm">
            <TextField {...typeTextfield} />
            <TextField {...NameTextfield} />
            <CheckBox {...EnabledProps} />
          </FieldGroup>
          <DashedDivider />
          <FieldGroup className="d-flex">
            <div className="maxWidthSm">
              <Label className="grp-label">Message Content</Label>

              <Grid
                container
                justify="space-between"
                className="message-content"
              >
                <Grid container spacing={3}>
                  <Grid xs={3} item>
                    <FieldBorder>
                      <SelectField {...SelectTitleTextField} />
                    </FieldBorder>
                  </Grid>
                  <Grid xs={9} item>
                    <TextField {...TitleTextfield} />
                    {getValues("message")?.titleType === "code" ? (
                      <>
                        <DiabledLayer />
                        <IconButton
                          className="code-btn-icon"
                          data-testid="title-modal"
                          onClick={() => {
                            setOpenModal(true);
                          }}
                        >
                          <CodeRoundedIcon />
                        </IconButton>
                      </>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid xs={3} item>
                    <FieldBorder>
                      <SelectField {...SelectSubTitle} />
                    </FieldBorder>
                  </Grid>
                  <Grid xs={9} item>
                    <TextField {...SubTitleTextfield} />
                    {getValues("message")?.subTitleType === "code" ? (
                      <>
                        <DiabledLayer />
                        <IconButton
                          className="code-btn-icon"
                          data-testid="subTitle-modal"
                          onClick={() => {
                            setSubModal(true);
                          }}
                        >
                          <CodeRoundedIcon />
                        </IconButton>
                      </>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid xs={3} item>
                    <FieldBorder>
                      <SelectField {...SelectBody} />
                    </FieldBorder>
                  </Grid>
                  <Grid xs={9} item>
                    <TextField {...BodyTextfield} />
                    {getValues("message")?.bodyType === "code" ? (
                      <>
                        <DiabledLayer />
                        <IconButton
                          className="code-btn-icon"
                          data-testid="body-modal"
                          onClick={() => {
                            setbodyModal(true);
                          }}
                        >
                          <CodeRoundedIcon />
                        </IconButton>
                      </>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid xs={12} item>
                  <TextField {...ClickProps} />
                </Grid>
              </Grid>
              <FieldGroup>
                <ImageUploaderWrap>
                  <Label className="grp-label">Image Url</Label>
                  <ImageUploader
                    ImageState={ImageState}
                    control={control}
                    image_url={props?.push_template?.image_url}
                    setValue={setValue}
                    title="Enter Image"
                    fieldName="message.image_url"
                    uid="image_Url"
                  />
                </ImageUploaderWrap>
                <ImageUploaderWrap>
                  <Label className="grp-label">Icon Url</Label>
                  <ImageUploader
                    ImageState={IconState}
                    control={control}
                    setValue={setValue}
                    image_url={props?.push_template?.icon_url}
                    title="Enter Icon"
                    fieldName="message.icon_url"
                    uid="icon_Url"
                  />
                </ImageUploaderWrap>
              </FieldGroup>
            </div>
            <IsolateReRender
              control={control}
              imageUrl={url}
              iconUrl={iconUrl}
              team_name={props.team_name}
              title={props?.push_template?.title?.data}
              body={props?.push_template?.body?.data}
            />
          </FieldGroup>

          <Modal
            data-testid="code-modal"
            className="medium"
            open={openModal}
            handleClose={closeModal}
            title="Code Block"
            maxwidth="md"
            actions={[
              { id: "1", name: "Cancel", handler: closeModal },
              {
                id: "2",
                name: "Save",
                handler: HandleModal,
                additionalAttributes: {
                  variant: "contained",
                  color: "primary",
                },
              },
            ]}
          >
            <JavaScriptEditor
              control={control}
              defaultValue={getValues("message.titleData")}
              uid="test-javaScript"
              fieldName="message.titileJSEditor"
              validations={{}}
              fontSize={16}
            />
          </Modal>

          <Modal
            data-testid="code-modal1"
            className="medium"
            open={subModal}
            handleClose={closeModal}
            title="Code Block"
            maxwidth="md"
            actions={[
              { id: "1", name: "Cancel", handler: closeModal },
              {
                id: "2",
                name: "Save",
                handler: subHandleModal,
                additionalAttributes: {
                  variant: "contained",
                  color: "primary",
                },
              },
            ]}
          >
            <JavaScriptEditor
              control={control}
              defaultValue={getValues("message.subTitleData")}
              uid="test-javaScript"
              fieldName="message.subTitileJSEditor"
              validations={{}}
              fontSize={16}
            />
          </Modal>

          <Modal
            data-testid="code-modal1"
            className="medium"
            open={bodyModal}
            handleClose={closeModal}
            title="Code Block"
            maxwidth="md"
            actions={[
              { id: "1", name: "Cancel", handler: closeModal },
              {
                id: "2",
                name: "Save",
                handler: bodyHandleModal,
                additionalAttributes: {
                  variant: "contained",
                  color: "primary",
                },
              },
            ]}
          >
            <JavaScriptEditor
              control={control}
              defaultValue={getValues("message.body")}
              uid="test-javaScript"
              fieldName="message.bodyJSEditor"
              validations={{}}
              fontSize={16}
            />
          </Modal>
          <DashedDivider />
          <FieldGroup className="maxWidthSm">
            <Accordin {...AccordinProps}>
              <TextField {...SoundTextfield} />
              <CheckBox {...CheckBoxProps} />
              <TextField {...IconTextfield} />
              <TextField {...BadgeTextField} />
              <TextField {...DeepLinkTextFiled} />
              <TextField {...CollapseTextFiled} />
              <JsonEditor {...JsonEditorProps} />
            </Accordin>
          </FieldGroup>
        </form>
      </FormWrapper>

      <PanelFooter>
        <div>
          <Button
            onClick={props.CancelForm}
            variant="contained"
            color="primary"
            data-testid="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            disabled={Boolean(!Object.keys(dirtyFields).length)}
            form="add_template"
            variant="contained"
            color="primary"
            data-testid="save-btn"
            type="submit"
          >
            Save
          </Button>
        </div>
      </PanelFooter>
    </Fragment>
  );
};

export default UpdateTemplateForm;
