import { Accordion } from "app/components/Common/Accordian";
import { Typography } from "@material-ui/core";
import MuiChip from "@material-ui/core/Chip";
import styled from "styled-components";

export const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 16px;
  }
`;

export const AccordionWrapper = styled(Accordion)`
  &.MuiAccordion-root {
    border: 1px solid ${props => props.theme.palette.grey.greyE3E7EB};
    border-radius: 4px;
    margin-bottom: 16px;
    box-shadow: none;
    &:before {
      content: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .MuiAutocomplete-tag {
      background: ${props => props.theme.palette.blue.blue100};
      color: ${props => props.theme.sidebar.background};
      padding: 2px 12px 2px 8px;
      font-size: 10px;
      span {
        color: ${props => props.theme.sidebar.background};
      }
    }
    .MuiCollapse-wrapper {
      margin-top: 15px;
    }
    .MuiAccordionSummary-root {
      padding: 0 24px;
      &.Mui-expanded {
        background-color: ${props => props.theme.palette.white.white200};
      }
    }
    .MuiAccordionSummary-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      .MuiFormLabel-root {
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 0;
        font-weight: 600;
        font-size: 17px;
        margin-right: 16px;
      }
    }
    .MuiAccordionDetails-root {
      flex-direction: column;
      padding: 0 24px 32px;
      .MuiTypography-caption {
        color: rgba(0, 0, 0, 0.54);
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 16px;
      }
      .form-wrap {
        display: flex;
        margin-top: 4px;
        align-items: center;
        .autocomplete-wrap {
          max-width: 600px;
          width: 100%;
          margin-right: 24px;
          ${({ theme }) => theme.breakpoints.down("md")} {
            max-width: 100%;
          }
          > div {
            margin-bottom: 0;
          }
        }
        .field {
          padding-top: 0;
        }
      }
    }
  }
`;

export const SwitchBox = styled.div`
  align-items: center;
  border: 1px solid ${props => props.theme.palette.grey.greyE3E7EB};
  border-radius: 4px;
  justify-content: space-between;
  display: flex;
  padding: 20px 24px;
  .MuiTypography-root {
    margin-bottom: 0;
  }
`;

export const Chip = styled(MuiChip)`
  &.MuiChip-root {
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 2px 8px;
    border-radius: 20px;
    border: 0;
    font-size: 10px;
    line-height: 14px;
    height: auto;
    &.default-badge {
      background: ${props => props.theme.palette.blue.blue100};
      color: ${props => props.theme.sidebar.background};
    }
    span {
      white-space: normal;
      padding-left: 0;
      color: ${props => props.theme.sidebar.background};
    }
    .MuiSvgIcon-root {
      margin-right: 0;
      width: 12px;
      color: ${props => props.theme.sidebar.background};
    }
  }
`;
