import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { Collapsible } from "app/components/Common/Collapsible";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetSchedulesView from "./GetSchedulesView";
import React, { useEffect, useState } from "react";

import { useModal } from "app/hooks/modal";

const headings = [
  { label: "Game Code", id: "gcode" },
  { label: "Game ID", id: "gid" },
  { label: "Game State", id: "game_state" },
  { label: "Game Time", id: "gametime", type: "date" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];
const extraInfo = [
  { label: "Season ID", id: "season_id" },
  { label: "League ID", id: "league_id" },
];

const GetSchedules = props => {
  const {
    getSchedules,
    data,
    totalDataCount,
    isLoading,
    dispatch,
    openSyncModal,
    syncModalClose,
    syncDetails,
    access_type,
  } = props;

  const [deleteData, setDeleteData] = useState({});
  const { team_name } = props.match.params;
  const [dataId, setDataId] = useState(null);
  const [selected, setSelected] = useState([]);
  const { sport_key, env_key } = props.match.params;

  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  useEffect(() => {
    setSelected([]);
    getSchedules(team_name);
  }, [getSchedules, team_name]);

  const deleteSingleHandler = (event, uid, data) => {
    setDeleteData(data);
    setDataId(uid);
    openModalHandler("showDelete");
  };

  const deleteHandler = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/schedules/${dataId}`,
      }),
    );
    setSelected([]);
    getSchedules(team_name);
    handleModalClose();
    setDeleteData({});
  };

  // Delete Multiple
  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const deleteMultiple = async () => {
    await dispatch(
      deleteRecords({
        url: "/v1/nba/admin/data/schedules",
        uids: selected,
      }),
    );
    setSelected([]);
    getSchedules(team_name);
    handleModalClose();
  };

  const handleModalClose = event => {
    closeModalHandler();
    setDataId("");
    setDeleteData({});
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-icon" />,
      // handler: () => props.history.push(),
      handler: (event, uid) =>
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/team/schedules/update/${uid}`,
          state: { from: props.location },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-icon" />,
      handler: deleteSingleHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      <GetSchedulesView
        name="Schedule"
        data={data}
        headings={headings}
        checkbox={true}
        actions={actions}
        handleSelectAllClick={handleSelectAllClick}
        handleSingleClick={handleSingleClick}
        selectedList={selected}
        numSelected={selected.length}
        isSorting
        totalCount={totalDataCount}
        extraInfo={extraInfo}
        collapsibleComponent={<Collapsible />}
        deleteModal={showDelete}
        handleModalClose={handleModalClose}
        deleteHandler={deleteHandler}
        isLoading={isLoading}
        multiDeleteModal={showMultipleDelete}
        multipleDeleteHandler={deleteMultipleHandler}
        deleteMultiple={deleteMultiple}
        openSyncModal={openSyncModal}
        syncModalClose={syncModalClose}
        syncDetails={syncDetails}
        deleteData={deleteData}
      />
    </>
  );
};

export default GetSchedules;
