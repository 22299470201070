const schema = {
  class: {
    title: "Policies",
    uid: "policies",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Title",
        uid: "title",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Media",
        uid: "media",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Items",
        uid: "items",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Title",
            uid: "title",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Description",
            uid: "desp",
            mandatory: true,
            multiple: false,
            data_type: "rte",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
