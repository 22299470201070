import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { getSegments } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { useQueryParams } from "app/hooks";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useCallback, useEffect, useState } from "react";
import SegmentView from "./indexView";

const dataTagFormator = dataItem => {
  return <span>{dataItem.data.length} Tags Selected</span>;
};

const headings = [
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Type",
    id: "type",
  },
  {
    label: "Created At",
    id: "created_at",
    type: "date",
  },
  {
    label: "Audience",
    id: "dataTag",
    formator: dataTagFormator,
  },
];

const GetSegment = props => {
  const { access_type } = useSelector(state => state.application);
  const [deleteData, setDeleteData] = useState({});
  const { sport_key, env_key } = props.match.params;
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false);
  const [dataId, setdataId] = useState("");
  const [selected, setSelected] = useState([]);
  const [totalSegmentCount, setTotalSegmentCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { sort, value, skip, limit, searchText } = useQueryParams();

  const getSegmentData = useCallback(async () => {
    try {
      setIsLoading(true);
      setSelected([]);
      const {
        data: { segment_data, count },
      } = await props.getData({ sort, value, skip, limit, searchText });

      if (count !== 0 && segment_data.length === 0) {
        initialRoute(props.history);
      } else {
        setData(segment_data);
        setTotalSegmentCount(count);
        setIsLoading(false);
      }
    } catch (e) {}
  }, [props, sort, value, skip, limit, searchText]);

  useEffect(() => {
    getSegmentData();
  }, [getSegmentData]);

  const handleModalClose = event => {
    setdataId("");
    setDeleteData({});
    setDeleteModal(false);
    setDeleteMultipleModal(false);
  };
  const deleteModalHandler = (event, uid, data) => {
    setDeleteData(data);
    setdataId(uid);
    setDeleteModal(true);
  };

  const deleteHandler = async event => {
    try {
      const { status } = await props.deleteData({
        url: `/v1/push_notification/admin/segment/${dataId}`,
      });
      /* istanbul ignore else */
      if (status === 200) {
        setDeleteModal(false);
        setdataId("");
        setSelected([]);
        setDeleteData({});
        getSegmentData();
      }
    } catch (e) {}
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  // Multiple delete Modal
  const multipleDeleteHandler = event => {
    setDeleteMultipleModal(true);
  };

  // Multiple delete Api call
  const deleteMultiple = async event => {
    try {
      const response = await props.deleteMultiple({
        url: "/v1/push_notification/admin/segment",
        uids: selected,
        loader: true,
      });
      /* istanbul ignore else */
      if (response) {
        setSelected([]);
        setdataId("");
        setDeleteData({});
        setDeleteMultipleModal(false);
        getSegmentData();
      }
    } catch (e) {}
  };

  const addSegmentHandler = () => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/segment/add`,
      state: { from: props.location },
    });
  };

  const actions = [
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <SegmentView
      access_type={access_type}
      Constants={Constants}
      isLoading={isLoading}
      headings={headings}
      data={data}
      actions={actions}
      deleteHandler={deleteHandler}
      handleModalClose={handleModalClose}
      deleteModal={deleteModal}
      checkbox={true}
      handleSelectAllClick={handleSelectAllClick}
      handleSingleClick={handleSingleClick}
      selectedList={selected}
      name="Segments"
      multipleDeleteHandler={multipleDeleteHandler}
      numSelected={selected.length}
      isSorting
      totalCount={totalSegmentCount}
      addSegmentHandler={addSegmentHandler}
      getSegmentData={getSegmentData}
      deleteMultiple={deleteMultiple}
      deleteMultipleModal={deleteMultipleModal}
      deleteData={deleteData}
      sport_key={sport_key}
      env_key={env_key}
    />
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getData: payload => dispatch(getSegments(payload)),
    deleteData: payload => dispatch(deleteRecord(payload)),
    deleteMultiple: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(GetSegment);
export { Container, GetSegment as Component };
