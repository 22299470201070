import { get } from "services/getObject";
import { TeamStandingMapperObject } from "utils/constants";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateStanding = props => {
  const [standingsData, setStandingsData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/team/standing/heat`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = useCallback(async () => {
    const url = `/v1/nba/admin/data/team_standing/${uid}`;
    const {
      data: { team_standing },
    } = await dispatch(get({ url }));

    //<-----
    //restructuring data according to schema
    // -grouping data keeping mapper as reference
    const formObject = {};

    for (const property in TeamStandingMapperObject) {
      const grpObj = {};
      TeamStandingMapperObject[property].forEach(key => {
        if (key in team_standing) {
          grpObj[key] = team_standing[key];
          delete team_standing[key];
        }
      });
      formObject[property] = grpObj;
    }
    //----->

    setStandingsData({ ...formObject, ...team_standing });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    const submitObj = {};

    //<------
    //destructuring data on single level
    for (const property in TeamStandingMapperObject) {
      if (property in data) {
        for (const key in data[property]) {
          submitObj[key] = data[property][key];
        }
        delete data[property];
      }
    }
    //----->

    try {
      await dispatch(
        updateRecord({
          form: { ...submitObj, ...data },
          url: `/v1/nba/admin/data/team_standing/${uid}`,
          key: "team_standing",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Standing"
        breadCrumbItems={[
          {
            title: "Update Standing",
          },
        ]}
        backUrl={getURL}
      />
      {standingsData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={standingsData}
          schema={schema}
          modelName="team_standings"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateStanding;
