import { FormWrapper } from "styles/global.style";
import CustomFieldMapper from "app/components/Common/CustomFieldForm/CustomFieldMapper";

const SchedulesSettings = () => {
  // States

  // Params

  // Hooks

  // Functions

  // LifeCycle

  // Render
  return (
    <FormWrapper className="settings-wrap" data-testid="form-wrapper">
      <CustomFieldMapper
        modelName="team_standings"
        title="Team Standings Template"
        uid="team_standings_template"
      />
    </FormWrapper>
  );
};

export default SchedulesSettings;
