import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { getWscFeeds } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { requestAPI } from "services/helper";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import GetWscFeeds from "app/pages/Dashboard/Data/Wsc/Feeds/get/feeds";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";

const FeedsIndex = props => {
  const [feedsData, setFeedsData] = useState([]);
  const [feedsCount, setFeedsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { sort, sortValue, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const { sport_key, env_key } = props.match.params;
  const { READ_ONLY } = Constants;
  const { access_type } = useSelector(state => state.application);
  const dispatch = useDispatch();

  const getFeedsData = useCallback(async () => {
    setIsLoading(true);
    setFeedsData([]);
    const {
      data: { count, wsc_feeds },
    } = await dispatch(
      getWscFeeds({
        skip,
        limit,
        sort,
        sortValue,
        searchText,
      }),
    );

    if (count !== 0 && wsc_feeds.length === 0) {
      initialRoute(props.history);
    } else {
      setFeedsData(wsc_feeds);
      setFeedsCount(count);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit, sort, sortValue, searchText]);

  useEffect(() => {
    getFeedsData();
  }, [getFeedsData]);

  const syncDetails = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/wsc_videos" }));
    closeModalHandler();
  };

  return (
    <>
      <PanelHeader
        title="WSC Feeds"
        breadCrumbItems={[
          {
            title: "WSC Feeds",
          },
        ]}
        refreshHandler={getFeedsData}
        {...(access_type !== READ_ONLY && {
          sync: () => openModalHandler("show"),
        })}
        search={true}
      >
        {access_type !== READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="add-btn"
            startIcon={<AddIcon />}
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/wsc/feeds/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      <GetWscFeeds
        {...props}
        getFeedsData={getFeedsData}
        feedsData={feedsData}
        isLoading={isLoading}
        feedsCount={feedsCount}
        access_type={access_type}
        openSyncModal={show}
        syncModalClose={closeModalHandler}
        syncDetails={syncDetails}
      />
    </>
  );
};

export default FeedsIndex;
