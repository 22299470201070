import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "app/pages/Dashboard/Data/Device/SettingsCard/Card.style";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Typography } from "@material-ui/core";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

const ColorPallete = ({
  uid,
  validations,
  display_name,
  visible = true,
  isBorder = false,
  defaultValue,
}) => {
  const { control, getValues } = useFormContext();

  const [color, setColor] = useState(
    getValues(`${uid}`) ? getValues(`${uid}`) : "#aabbcc",
  );
  return (
    <Controller
      key={uid}
      name={`${uid}`}
      control={control}
      rules={{
        required: validations?.required ? `${display_name} is required` : false,
      }}
      defaultValue={defaultValue ?? getValues(`${uid}`)}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, `${field.name}`);
        const fieldError = get(errors, `${field.name}`);

        return (
          <FieldBorder FieldBorder isBorder={isBorder} isError={hasFieldError}>
            {visible && (
              <>
                <Typography
                  variant="body1"
                  component="label"
                  htmlFor={uid}
                  noWrap={false}
                  style={{ fontSize: "16px" }}
                >
                  <p>{display_name}</p>
                </Typography>

                <Grid
                  container
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    style={{
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid md={4} xs={12} direction="column">
                      <HexColorPicker
                        style={{
                          width: "100%",
                        }}
                        data-testid={`${uid}-hex-color-picker`}
                        color={color}
                        onChange={e => {
                          setColor(e);
                          return field.onChange(e);
                        }}
                      />
                    </Grid>
                    <Grid md={8} xs={12} direction="column">
                      <ColorPickerInput
                        className="colorpickerInput"
                        id={uid}
                        color={color}
                        onChange={e => {
                          setColor(e);
                          return field.onChange(e);
                        }}
                        prefixed
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </FieldBorder>
        );
      }}
    />
  );
};

const ColorPickerInput = props => {
  return (
    <HexColorInputFieldWrap
      className={props.className ? props.className + " field" : "field"}
    >
      <HexColorInput
        data-testid={`${props.id}-hex-color-picker_input`}
        {...props}
      />
    </HexColorInputFieldWrap>
  );
};

ColorPickerInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

const HexColorInputFieldWrap = styled.div`
  .colorpickerInput {
    border: 1px solid rgba(0, 0, 0, 0.37);
    &:focus {
      outline: none;
      border: 2px solid ${props => props.theme.palette.secondary.main};
    }
    border-radius: 8px;
    font: inherit;
    font-size: 16px;
    font-weight: 400;
    height: 47px;
    padding: 0 10px;
    width: 100%;
    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-left: 2%;
      width: 98%;
    }
  }
`;

export default ColorPallete;
