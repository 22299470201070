import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: { first_name: "", last_name: "", email: "" },
  reducers: {
    setProfile(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    /*istanbul ignore next*/
    unsetProfile: () => {
      return {
        first_name: "",
        last_name: "",
        email: "",
      };
    },
  },
});

export const { setProfile, unsetProfile } = adminSlice.actions;

export default adminSlice.reducer;
