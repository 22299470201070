import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "app/components/Common/Accordian";
import { getFieldComponent, identifyComponent } from "utils/formFunctions";
import { useFormContext } from "react-hook-form";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import IconButton from "@material-ui/core/IconButton";
import Label from "app/components/Common/Label";
import React, { Fragment, useEffect, useState } from "react";
import Styled from "styled-components";

const AccordionSummary = Styled(MuiAccordionSummary)`
.MuiAccordionSummary-content{
  display: flex;
  justify-content: space-between;
  align-items:center;
}
`;

const Group = schema => {
  const { isBorder = true } = schema;
  const {
    formState: { errors },
  } = useFormContext();

  const groupError = !!get(errors, schema.uid);
  useEffect(() => {
    if (groupError) setOpen(true);
  }, [groupError]);

  const [open, setOpen] = useState(schema.field_metadata?.open || false);
  return (
    <FieldBorder isBorder={isBorder} isError={!!get(errors, schema.uid)}>
      <Accordion defaultExpanded={false} expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-testid={schema.uid}
          onClick={() => setOpen(!open)}
        >
          {schema.index !== undefined && schema.index !== "" ? (
            <Label>
              {schema.display_name}-{schema.index + 1}
            </Label>
          ) : (
            <Label>{schema.display_name}</Label>
          )}
          {schema.onFieldDelete && (
            <IconButton
              onClick={event => schema.onFieldDelete(event, schema.index)}
            >
              <DeleteIcon className="delete-icon" />
            </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {schema._schema.map((schemaItem, index) => {
            let schemaClass = {
              ...schemaItem,
              uid: `${schema.uid}.${schemaItem.uid}`,
            };
            return (
              <Fragment key={schemaItem.uid + index}>
                {getFieldComponent(identifyComponent(schemaClass))}
              </Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </FieldBorder>
  );
};

export default Group;
