import React from "react";

const Users = () => {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        opacity={0.5}
        stroke="#EEE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.167 17.5v-1.667a3.333 3.333 0 00-3.334-3.333H5.167a3.333 3.333 0 00-3.334 3.333V17.5" />
        <path
          clipRule="evenodd"
          d="M8.5 9.167a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
        />
        <path d="M20.167 17.5v-1.667a3.333 3.333 0 00-2.5-3.225M14.333 2.608a3.333 3.333 0 010 6.459" />
      </g>
    </svg>
  );
};
export default Users;
