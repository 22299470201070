import { Constants } from "utils/constants";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { getBroadcastTopicData } from "services/getObjects";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import { useDispatch } from "react-redux";
import { useQueryParams } from "app/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useCallback, useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

export default function BroadcastTableView({
  match,
  location,
  history,
  refresh,
  setRefresh,
  access_type,
}) {
  const { sport_key, env_key, topic } = match.params;
  const [deleteData, setDeleteData] = useState({});
  const [broadcastTopics, setBroadcastTopics] = useState([]);
  const [broadcastDataCount, setBroadcastDataCount] = useState(0);
  const [broadcastTopicId, setbroadcastTopicId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { skip, limit, sort, value: sortValue, searchText } = useQueryParams();

  const dispatch = useDispatch();

  const getBroadcastData = useCallback(async () => {
    setIsLoading(true);
    const {
      data: { topics, count },
    } = await dispatch(
      getBroadcastTopicData({
        sport_key,
        env_key,
        skip,
        limit,
        sort,
        sortValue,
        query: topic,
        searchText,
      }),
    );

    if (count !== 0 && topics.length === 0) {
      initialRoute(history);
    } else {
      setBroadcastTopics(topics);
      setBroadcastDataCount(count);
      setRefresh(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key, skip, limit, sort, sortValue, topic, searchText]);

  const editTableHandler = (event, uid) => {
    history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/broadcast-topics/update/${uid}`,
      state: { from: location },
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setbroadcastTopicId("");
    setDeleteData({});
  };

  const deleteModalHandler = async () => {
    const url = `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics/${broadcastTopicId}`;
    await dispatch(deleteRecord({ url }));
    getBroadcastData();
    handleDeleteModalClose();
    setDeleteData({});
    setbroadcastTopicId("");
  };

  useEffect(() => {
    if (refresh === false) {
      setIsLoading(true);
      getBroadcastData();
    }
    return () => {
      setBroadcastTopics([]);
      setbroadcastTopicId("");
      setDeleteData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, sport_key, env_key, skip, limit, sort, sortValue, searchText]);

  useEffect(() => {
    if (refresh === true) {
      setIsLoading(true);
      getBroadcastData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const headings = [
    { label: "Name", id: "name" },
    { label: "Key", id: "key" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];
  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon data-testid="edit-btn" fontSize="small" />,
      handler: editTableHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon data-testid="delete-btn" fontSize="small" />,
      handler: (event, uid, data) => {
        setDeleteData(data);
        setbroadcastTopicId(uid);
        setIsDeleteModalOpen(true);
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const topicName = { push: "Push Topics", realtime: "Real Time Topics" }[
    topic
  ];

  return (
    <>
      {isLoading || refresh ? (
        <SkeletonTable
          headings={headings}
          name={searchText ? "Broadcast Topics" : topicName}
          actions={actions}
        />
      ) : null}

      {broadcastTopics.length > 0 && !refresh && !isLoading ? (
        <Table
          data-testid="resolved"
          name={searchText ? "Broadcast Topics" : topicName}
          headings={headings}
          data={broadcastTopics}
          actions={actions}
          isSorting
          totalCount={broadcastDataCount}
        />
      ) : (
        !isLoading && !refresh && <NoData> No record is found </NoData>
      )}

      <Modal
        className="small"
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        title={`Delete ${topicName}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleDeleteModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteModalHandler,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "modal-delete-btn",
            },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: deleteData?.name,
          module: topicName,
        })}
      </Modal>
    </>
  );
}
