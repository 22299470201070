import { Route, Switch } from "react-router-dom";
import AddPlayByPlay from "./add";
import GetPlayByPlay from "app/pages/Dashboard/Data/Game/PlayByPlay/get";
import React from "react";
import UpdatePlayByPlay from "./update";

const PlayByPlay = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddPlayByPlay} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdatePlayByPlay}
        exact
      />
      <Route path={props.match.path} component={GetPlayByPlay} />
    </Switch>
  );
};

export default PlayByPlay;
