import cookie from "cookie";
/**
 * Sets the cookie.
 * @param {string} key
 * @param {any} value
 * @param {number} expireIn Value in ```days```
 * @param {boolean} secure This secures the cookie only if application runs in HTTPS
 */
export const setKey = (
  key,
  value,
  expireIn = 0,
  secure = JSON.parse(process.env.REACT_APP_SECURE_COOKIES) || false,
) => {
  let expires = "";
  if (expireIn) {
    const d = new Date();
    d.setTime(d.getTime() + expireIn * 24 * 60 * 60 * 1000);
    expires = `expires=${d.toUTCString()};`;
  }

  document.cookie = `${key}=${value};${expires}path=/;${
    secure ? "Secure" : ""
  }`;
};

/**
 *
 * @param {*} key
 */
export const getCookie = req => {
  // const cookies = document.cookie;
  // const searchedCookie = cookies
  //   .split(";")
  //   .find((_cookie) => _cookie.trim().split("=")[0] === key);
  // return searchedCookie ? searchedCookie.split("=")[1] : null;
  return cookie.parse(req ? req.headers.cookie || "" : document.cookie);
};

/**
 *
 * @param {*} key
 */
export const getKey = key => {
  const cookies = document.cookie;
  const searchedCookie = cookies
    .split(";")
    .find(_cookie => _cookie.trim().split("=")[0] === key);
  return searchedCookie ? searchedCookie.split("=")[1] : null;
};
/**
 *
 * @param {string} key
 */
export const deleteKey = key => {
  document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
