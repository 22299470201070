import React from "react";

const Settings = () => {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        opacity={0.5}
        stroke="#EEE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4.333 17.5v-5.833M4.333 8.333V2.5M11 17.5V10M11 6.667V2.5M17.667 17.5v-4.167M17.667 10V2.5M1.833 11.667h5M8.5 6.667h5M15.167 13.333h5" />
      </g>
    </svg>
  );
};
export default Settings;
