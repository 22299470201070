import { a11yProps, Tab, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize } from "utils/functions";
import { connect, useDispatch, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { getStanding } from "services/getObjects";
import { Container as GetStanding } from "app/pages/Dashboard/Data/Team/Standing/get/GetStanding";
import { initialRoute } from "utils/functions";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";
import Settings from "./settings";

const StandingTabs = props => {
  const { tid, team_name, access_type } = useSelector(
    state => state.application,
  );

  let tabObj = {};
  tabObj[team_name] = 0;

  tabObj = { ...tabObj, others: 1, settings: 2 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [tabValue, setTabValue] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== tabValue) {
    setTabValue(tab);
  }

  const { show, closeModalHandler, openModalHandler } = useModal();
  const { sport_key, env_key } = props.match.params;
  const breadCrumbItems = [
    {
      title: "Team Standing",
    },
  ];

  const syncTeamsModal = () => {
    openModalHandler("show");
  };

  const syncToTeams = async () => {
    await props.syncTeamsStanding({
      url: "/v1/nba/admin/sync/team_standing",
    });
    closeModalHandler();
  };

  // get api call
  const dispatch = useDispatch();
  const [totalDataCount, setTotalDataCount] = useState();
  const [standingData, setStandingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { sort, limit, value, skip, searchText } = useQueryParams();

  const getStandingData = useCallback(
    async team_name => {
      setIsLoading(true);
      setStandingData([]);
      try {
        const {
          data: { team_standing, count },
        } = await dispatch(
          getStanding({
            team_name,
            tid,
            sort,
            limit,
            value,
            skip,
            searchText,
          }),
        );

        if (count !== 0 && team_standing.length === 0) {
          initialRoute(props.history);
        } else {
          setStandingData(team_standing);
          setTotalDataCount(count);
          setIsLoading(false);
        }
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team_name, sort, limit, value, skip, searchText, tid],
  );

  return (
    <>
      <PanelHeader
        title="Team Standing"
        breadCrumbItems={breadCrumbItems}
        search={true}
        refreshHandler={() => {
          getStandingData(tabValue === 0 ? team_name : "others");
        }}
        sync={syncTeamsModal}
        hasSyncCMS={false}
        syncToCMS={{}}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            data-testid="add-btn"
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/team/standing/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${props.match.url}/${team_name}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${props.match.url}/others`}
            {...a11yProps(1)}
          />

          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(2)}
          />
        </Tabs>
      )}
      <Switch>
        {/* <TabPanel> */}

        <Redirect
          from={`${props.match.path}`}
          to={`${props.match.url}/${team_name}`}
          exact
        />

        <Route
          path={`${props.match.path}/settings`}
          component={Settings}
          exact
        />
        <Route
          path={`${props.match.path}/:team_name`}
          /*istanbul ignore next*/
          render={Routeprops => {
            /*istanbul ignore next*/
            return (
              <GetStanding
                {...Routeprops}
                tid={tid}
                openSync={show}
                closeSync={closeModalHandler}
                sendSync={syncToTeams}
                access_type={access_type}
                totalDataCount={totalDataCount}
                standingData={standingData}
                isLoading={isLoading}
                getStandingData={getStandingData}
              />
            );
          }}
          exact
        />
        {/* </TabPanel> */}
      </Switch>
    </>
  );
};

/* istanbul ignore next */
const mapDisptachToProps = dispatch => {
  return {
    syncTeamsStanding: payload => dispatch(requestAPI(payload)),
  };
};

const Container = connect(null, mapDisptachToProps)(StandingTabs);
export { Container, StandingTabs as Component };
