import AutoComplete from "app/components/Common/schemaForm/Autocomplete";
import Checkbox from "app/components/Common/schemaForm/Checkbox";
import ColorPallete from "app/components/Common/schemaForm/ColorPallete";
import Date from "app/components/Common/schemaForm/DatePicker";
// import FormGroup from "app/components/Common/schemaForm/FormGroup";
import get from "lodash/get";
import ImageUpload from "app/components/Common/schemaForm/imageUpload";
import JSONEditor from "app/components/Common/schemaForm/JSONEditor";
// import MultipleGroup from "app/components/Common/schemaForm/MultipleGroup";
import FormGroup from "app/components/Common/schemaForm/group";
import MultipleGroup from "app/components/Common/schemaForm/multipleFormGroup";
import React from "react";
import RTEditor from "app/components/Common/schemaForm/RTEditor";
import SelectField from "app/components/Common/schemaForm/SelectField";
import set from "lodash/set";
import TextInput from "app/components/Common/schemaForm/TextInput";

export const getFieldComponent = options => {
  switch (options.field_type) {
    case "textInput":
      return <TextInput {...options} />;
    case "group":
      return <FormGroup {...options} />;
    case "color":
      return <ColorPallete {...options} />;
    case "checkbox":
      return <Checkbox {...options} />;
    case "jsonEditor":
      return <JSONEditor {...options} />;
    case "select":
      return <SelectField {...options} />;
    case "multiple":
      return <MultipleGroup {...options} />;
    case "autoComplete":
      return <AutoComplete {...options} />;
    case "rte":
      return <RTEditor {...options} />;
    case "imageUpload":
      return <ImageUpload {...options} />;
    case "date":
      return <Date {...options} />;
    default:
      return <></>;
  }
};

export const identifyStringComponent = ({
  field_metadata,
  multiple,
  mandatory,
  format,
  min,
  max,
  data_type,
  ...rest
}) => {
  let validations;
  if (data_type === "number") {
    validations = { required: mandatory, pattern: format, min, max };
  } else {
    validations = {
      required: mandatory,
      pattern: format,
      minLength: min,
      maxLength: max,
    };
  }
  if (multiple) {
    return {
      field_type: "autoComplete",
      field_metadata,
      multiple,
      data_type,
      validations,
      ...rest,
    };
  } else if (field_metadata?.select) {
    return {
      field_type: "select",
      field_metadata,
      multiple,
      data_type,
      validations,
      ...rest,
    };
  } else {
    return {
      field_type: "textInput",
      field_metadata,
      multiple,
      data_type,
      validations,
      ...rest,
    };
  }
};

export const identifyComponent = item => {
  if (item.multiple && !["string", "number"].includes(item.data_type)) {
    return {
      field_type: "multiple",
      ...item,
    };
  }
  switch (item.data_type) {
    case "string":
    case "number":
      return identifyStringComponent(item);
    case "date":
      return { ...item, field_type: "date" };
    case "color":
      return { ...item, field_type: "color" };
    case "group":
      return { ...item, field_type: "group" };
    case "boolean":
      return { ...item, field_type: "checkbox" };
    case "mixed":
      return { field_type: "jsonEditor", ...item };
    case "rte":
      return { ...item, field_type: "rte" };
    case "file":
      return { ...item, field_type: "imageUpload" };
    default:
      return { field_type: "" };
  }
};

export const convertValues = ({ data }) => {
  let form = data;
  if (form.JSONEditor) {
    form.JSONEditor.forEach(path => {
      if (typeof get(form, path) === "string" && get(form, path)) {
        set(form, path, JSON.parse(get(data, path)));
      }
    });
    delete form.JSONEditor;
  }
  return form;
};

// protected field data handling for Integration's config form
export const checkIsFieldProtected = data => {
  let form = data;
  let Protected = [];
  for (const uid in form.config) {
    if (
      form.config[`${uid}-protected-checkbox`] === true ||
      form.config[`${uid}-protected-checkbox`] === "true"
    ) {
      Protected.push(uid);
    }
  }
  Object.assign(form.config, {
    meta: { protected: Protected },
  });
  return form;
};

export const dataTypeValidation = ({ data, schema }) => {
  schema.forEach(field => {
    if (data.hasOwnProperty(field.uid)) {
      switch (field.data_type) {
        case "number":
          data[field.uid] = parseFloat(data[field.uid]);
          break;
        default:
          break;
      }
    }
  });
  return data;
};

export const formDatatypeConversion = ({ schema, data }) => {
  schema[0]._schema.forEach(field => {
    switch (field.data_type) {
      case "boolean":
      case "number":
        data.config.broadcasters.forEach(broadcaster => {
          broadcaster[field.uid] = JSON.parse(broadcaster[field.uid]);
        });
        break;
      default:
        break;
    }
  });

  return data;
};
