import { Container as GetPolicies } from "app/pages/Dashboard/Data/Arena/Policies/get";
import { Route, Switch } from "react-router-dom";
import AddPolicies from "app/pages/Dashboard/Data/Arena/Policies/add";
import React from "react";
import UpdatePolicies from "app/pages/Dashboard/Data/Arena/Policies/update";

const Policies = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddPolicies} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdatePolicies}
        exact
      />
      <Route path={`${props.match.path}`} component={GetPolicies} />
    </Switch>
  );
};

export default Policies;
