import { useLocation } from "react-router-dom";

export const useQueryParams = options => {
  const limit = options?.limit ?? 10;
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const page = query.get("page") ? query.get("page") : 1;
  const sort = query.get("sort") ? query.get("sort") : "updated_at";
  const value = query.get("value") ? query.get("value") : -1;
  const searchText = query.get("search") ? query.get("search") : null;
  const skip = (page - 1) * limit;
  return {
    page: Number(page),
    sort,
    value: Number(value),
    sortValue: Number(value),
    limit,
    skip,
    querySearch: search,
    searchText,
    pathname,
  };
  // return [getQueryString];
};
