import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { useModal } from "app/hooks/modal";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import TableView from "./GetSeasonView.jsx";

const GetSeason = props => {
  const [selected, setSelected] = useState([]);
  const [uid, setUid] = useState();
  const [deleteData, setDeleteData] = useState({});
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();
  const { access_type, seasonCount, seasonData, isLoading, getSeason } = props;
  const { team_name, sport_key, env_key } = props.match.params;

  useEffect(() => {
    setSelected([]);
    getSeason(team_name);
  }, [getSeason, team_name]);

  const deleteModalHandler = (event, uid, data) => {
    setUid(uid);
    setDeleteData(data);
    openModalHandler("showDelete");
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-btn" />,
      handler: (event, uid) =>
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/team/season_average/update/${uid}`,
          state: { from: props.location },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const headings = [
    { label: "Team ID", id: "tid" },
    { label: "League ID", id: "league_id" },
    { label: "Year", id: "year" },
    { label: "Season ID", id: "season_id" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const modalHandleClose = () => {
    closeModalHandler();
    setUid();
    setDeleteData({});
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: seasonData });
    setSelected(newSelecteds);
  };

  const handleModalDelete = async () => {
    await props.deleteSeason({
      url: `/v1/nba/admin/data/team_season_average/${uid}`,
      uid,
    });
    setSelected([]);
    getSeason(team_name);
    setUid("");
    setDeleteData({});
    modalHandleClose();
  };

  const handleMultiDelete = async () => {
    await props.deleteMultiSeason({
      url: `/v1/nba/admin/data/team_season_average`,
      uids: selected,
    });
    setSelected([]);
    getSeason(team_name);
    modalHandleClose();
  };

  return (
    <TableView
      name="Season Average"
      data={seasonData}
      isLoading={isLoading}
      actions={actions}
      totalCount={seasonCount}
      handleModalDelete={handleModalDelete}
      handleModalClose={modalHandleClose}
      openModal={showDelete}
      multipleModal={showMultipleDelete}
      multipleDeleteHandler={deleteMultipleHandler}
      handleSingleClick={handleSingleClick}
      handleSelectAllClick={handleSelectAllClick}
      handleMultiDelete={handleMultiDelete}
      selectedList={selected}
      numSelected={selected.length}
      isSorting
      headings={headings}
      checkbox={true}
      actionMenu={false}
      deleteData={deleteData}
      {...props}
    />
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    deleteSeason: payload => dispatch(deleteRecord(payload)),
    deleteMultiSeason: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(GetSeason);

export { Container, GetSeason as Component };
