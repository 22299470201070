import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { deleteRecord } from "services/deleteObject";
import { useModal } from "app/hooks/modal";
import CoachesTableView from "app/pages/Dashboard/Data/Roster/Coaches/get/CoachesTableView";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";

const headings = [
  //   { label: "UID", id: "uid" },
  { label: "First Name", id: "fn" },
  { label: "Last Name", id: "ln" },
  { label: "Person ID", id: "pid" },
  { label: "Team ID", id: "tid" },
  { label: "Updated At", id: "updated_at", type: "date" },
];
const extraInfo = [
  { label: "league ID", id: "league_id" },
  { label: "Season ID", id: "season_id" },
  { label: "Year", id: "year" },
  { label: "Created At", id: "created_at", type: "date" },
];

function CoachesData({
  fetchCoachesData,
  setCoachesData,
  coachesData,
  coachesCount,
  isLoading,
  match,
  location,
  history,
  dispatch,
  openSyncModal,
  syncModalClose,
  syncDetails,
  access_type,
  READ_ONLY,
}) {
  const { team_name, sport_key, env_key } = match.params;

  const [coachObj, setCoachObj] = useState(null);
  const [selected, setSelected] = useState([]);

  const {
    showDelete,
    showMultipleDelete,
    openModalHandler,
    closeModalHandler,
  } = useModal();

  //   Delete Api Call

  const deleteCoachesReq = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/coaches/${coachObj.uid}`,
      }),
    );
    CloseModal();
    setSelected([]);
    fetchCoachesData();
  };
  const deleteMultiReq = async () => {
    await dispatch(
      deleteRecord({
        url: "/v1/nba/admin/data/coaches",
        key: "uids",
        form: selected,
      }),
    );
    CloseModal();
    setSelected([]);
    fetchCoachesData();
  };

  //  Delete  Modal functions

  const CloseModal = () => {
    setCoachObj(null);
    closeModalHandler();
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };
  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: coachesData });
    setSelected(newSelecteds);
  };

  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  useEffect(() => {
    fetchCoachesData();
    return () => {
      setCoachesData([]);
      setCoachObj(null);
      setSelected([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team_name, fetchCoachesData]);

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-icon" />,
      handler: (event, uid) =>
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/rosters/coaches/update/${uid}`,
          state: { from: location },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-icon" />,
      handler: (event, uid, dataItem) => {
        setCoachObj(dataItem);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== READ_ONLY,
    },
  ];
  return (
    <div>
      <CoachesTableView
        isLoading={isLoading}
        // Sync Modal
        openSyncModal={openSyncModal}
        syncModalClose={syncModalClose}
        syncDetails={syncDetails}
        // Delete modalProps
        showDelete={showDelete}
        showMultipleDelete={showMultipleDelete}
        CloseModal={CloseModal}
        coachObj={coachObj}
        // Api Call
        deleteCoachesReq={deleteCoachesReq}
        deleteMultiReq={deleteMultiReq}
        // restTableProps
        name="Coaches"
        data={coachesData}
        totalCount={coachesCount}
        headings={headings}
        extraInfo={extraInfo}
        actions={actions}
        checkbox={access_type !== READ_ONLY}
        selectedList={selected}
        numSelected={selected.length}
        handleSelectAllClick={handleSelectAllClick}
        handleSingleClick={handleSingleClick}
        multipleDeleteHandler={deleteMultipleHandler}
      />
    </div>
  );
}

export default CoachesData;
