import { connect } from "react-redux";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import React, { useCallback, useEffect, useState } from "react";
import UpdateDevicesDataView from "./indexView";

const UpdateDeviceData = props => {
  const {
    location: { state },
  } = props;
  const { uid, sport_key, env_key } = props.match.params;
  const [installationData, setInstallationData] = useState();
  const getInstallationDataById = useCallback(async () => {
    const {
      data: { installation_data },
    } = await props.getById({
      url: `/v1/push_notification/admin/installation_data/${uid}`,
    });
    setInstallationData(installation_data);
  }, [uid, props]);

  useEffect(() => {
    getInstallationDataById();
  }, [getInstallationDataById]);

  const formSubmit = async ({
    device_type,
    push_provider,
    user_uid,
    custom_fields,
    device_token,
  }) => {
    const form = {
      ...installationData,
      device_type,
      device_token,
      user_uid,
      custom_fields: JSON.parse(`${custom_fields}`),
    };
    const { status } = await props.updateById({
      url: `/v1/push_notification/admin/installation_data/${uid}`,
      key: "installation_data",
      form,
    });
    /* istanbul ignore else */
    if (status === 200) {
      props.history.push(`/dashboard/${sport_key}/${env_key}/reports/devices`);
    }
  };

  const formCancelHandler = event => {
    props.history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/reports/devices`,
    );
  };

  return (
    <>
      {installationData && (
        <UpdateDevicesDataView
          installationData={installationData}
          formSubmit={formSubmit}
          formCancelHandler={formCancelHandler}
          sport_key={sport_key}
          env_key={env_key}
        />
      )}
    </>
  );
};

const mapDispatchToProps = Dispatch => {
  return {
    getById: payload => Dispatch(get(payload)),
    updateById: payload => Dispatch(updateRecord(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(UpdateDeviceData);
export { Container, UpdateDeviceData as Component };
