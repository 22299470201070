import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import PanelHeader from "app/components/Common/PanelHeader";
import PropTypes from "prop-types";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";
import Typography from "@material-ui/core/Typography";

export default function UserView({
  deleteModal,
  handleModalClose,
  deleteHandler,
  deleteFileName,
  totalCount,
  isLoading,
  headings,
  actions,
  checkbox,
  personal_id,
  name,
  getUsersData,
  numSelected,
  deleteAllModal,
  deleteMultipleHandler,
  deleteMultiple,
  ...restProps
}) {
  return (
    <>
      <PanelHeader
        title="Registered Users"
        breadCrumbItems={[
          {
            title: "Users",
          },
        ]}
        refreshHandler={getUsersData}
        search={true}
      />
      {isLoading ? (
        <SkeletonTable
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
        />
      ) : totalCount > 0 ? (
        <Table
          totalCount={totalCount}
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
          numSelected={numSelected}
          {...restProps}
        />
      ) : (
        <NoData>No Data Found</NoData>
      )}

      <Modal
        className="small"
        open={deleteModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        <Typography>
          {deleteConfirmationMessage({
            name: personal_id,
            module: name,
          })}
        </Typography>
      </Modal>
      <Modal
        className="small"
        open={deleteAllModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "del-btn",
            },
          },
        ]}
      >
        <Typography>
          Are you sure you want to delete <strong>{numSelected}</strong> users?
        </Typography>
      </Modal>
    </>
  );
}
UserView.propTypes = {
  deleteHandler: PropTypes.func,
  deleteModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
};
