import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    width: "95%",
    margin: "0 auto",
    flexGrow: 1,
  },
  rootOpen: {
    animation: `$poiPopUpEffect 500ms ${theme.transitions.easing.easeInOut}`,
  },
  rootClose: {
    animation: `$poiPopOutEffect 800ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
    transform: "translateY(-200%)",
  },
  paper: props => ({
    position: "relative",
    boxShadow: `${props.primaryColor} 0px 0px 0px 3px !important`,
    borderRadius: "10px",
    padding: theme.spacing(2),
    maxWidth: "100%",
  }),
  image: {
    height: 100,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  "@keyframes poiPopUpEffect": {
    "0%": {
      opacity: 0,
      transform: "translateY(200%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  "@keyframes poiPopOutEffect": {
    "0%": {
      opacity: 1,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateY(200%)",
    },
  },
  "popup-close-button": {
    padding: 0,
  },
}));

export default function PoiDetail({ poiDetail }) {
  const styleProps = {
    primaryColor: poiDetail.primaryColor || "rgb(3 32 214 / 30%)",
  };
  const classes = useStyles(styleProps);
  const [popUp, setPopup] = useState(true);

  const closePoiPopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    if (!popUp) {
      setTimeout(() => {
        const feature = poiDetail.feature;
        feature.setProperty("selected", false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUp]);

  return (
    poiDetail.showPoi &&
    poiDetail.poiName && (
      <div
        className={`${classes.root} ${
          popUp ? classes.rootOpen : classes.rootClose
        }`}
      >
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item container>
              <Grid item xs={6}>
                <ButtonBase className={classes.image}>
                  <img
                    className={classes.img}
                    alt={poiDetail.categoryName || "category_type"}
                    src={poiDetail.iconURL}
                  />
                </ButtonBase>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton
                  color="secondary"
                  aria-label="close POI"
                  component="button"
                  onClick={closePoiPopup}
                  className={classes["popup-close-button"]}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item>
                {poiDetail.poiName && (
                  <Typography variant="h6" data-testid="poi-name">
                    {poiDetail.poiName}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={6}>
                {poiDetail.categoryName && (
                  <Typography
                    variant="body2"
                    data-testid="poi-category"
                    gutterBottom
                  >
                    {poiDetail.categoryName}
                  </Typography>
                )}
                {poiDetail.level && (
                  <Typography
                    variant="body2"
                    data-testid="poi-level"
                    color="textSecondary"
                  >
                    {poiDetail.level}
                  </Typography>
                )}
              </Grid>
              {poiDetail.location && (
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    data-testid="poi-location"
                    color="textSecondary"
                    style={{ textAlign: "right" }}
                  >
                    {poiDetail.location}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item container>
              <Grid item>
                {poiDetail.description && (
                  <Typography
                    variant="body2"
                    data-testid="poi-description"
                    color="textSecondary"
                  >
                    {poiDetail.description}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {poiDetail.actionText && poiDetail.actionURL && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  data-testid="poi-action-text"
                  color="primary"
                  href={poiDetail.actionURL}
                  fullWidth
                >
                  {poiDetail.actionText}
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    )
  );
}
