import { Container as GetParkingAndDirections } from "app/pages/Dashboard/Data/Arena/ParkingAndDirections/get";
import { Route, Switch } from "react-router-dom";
import AddParkingAndDirections from "app/pages/Dashboard/Data/Arena/ParkingAndDirections/add";
import React from "react";
import UpdateParkingAndDirections from "app/pages/Dashboard/Data/Arena/ParkingAndDirections/update";

const ParkingAndDirections = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/add`}
        component={AddParkingAndDirections}
        exact
      />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateParkingAndDirections}
        exact
      />
      <Route path={`${props.match.path}`} component={GetParkingAndDirections} />
    </Switch>
  );
};

export default ParkingAndDirections;
