import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdatePlayerStats = props => {
  const [playerStatsData, setPlayerStatsData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const backUrl = `/dashboard/${sport_key}/${env_key}/data/rosters/playerStats`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/nba/admin/data/player_stats/${uid}`;
    const {
      data: { player_stats },
    } = await dispatch(get({ url }));
    setPlayerStatsData(player_stats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/data/player_stats/${uid}`,
          key: "player_stats",
        }),
      );
      props.history.push(backUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Player Stats"
        breadCrumbItems={[
          {
            title: "Update Player Stats",
          },
        ]}
        backUrl={backUrl}
      />
      {playerStatsData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={playerStatsData}
          schema={schema}
          modelName="player_stats"
          backUrl={backUrl}
        />
      )}
    </>
  );
};

export default UpdatePlayerStats;
