import {
  Box,
  BoxWrap,
  Card,
  CardLink,
  Grid,
  Typography,
} from "app/pages/Dashboard/Data/Device/SettingsCard/Card.style";
import { Constants } from "utils/constants";
import { deleteConfirmationMessage } from "utils/functions";
import { format } from "date-fns";
import { Modal } from "@dfep/ui-component";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";

const DeviceCard = props => {
  const { item, deleteHandler, name, isOwner, access_type } = props;

  //delete
  const [open, setOpen] = useState(false);
  const handleDelete = event => {
    deleteHandler(item.uid);
    handleModal(event);
  };
  const handleModal = event => {
    event.preventDefault();
    setOpen(!open);
  };

  const CardComp = {
    cardBorderColor: "4px solid #5B2B82",
    cardContent: () => (
      <>
        <BoxWrap
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="caption">
              <span>Version</span>
            </Typography>
            <Typography variant="h3">{item?.version}</Typography>
            {(isOwner || access_type === Constants.FULL_ACCESS) && (
              <>
                <IconButton data-testid="delete-btn" onClick={handleModal}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Box>
        </BoxWrap>

        <BoxWrap display="flex" alignItems="center" className="update-holder">
          <Box className="version-update">
            <Typography variant="caption">
              <span>Created At</span>
            </Typography>
            <Typography>
              {item?.created_at &&
                format(new Date(item.created_at), "MMM dd yyyy")}
            </Typography>
          </Box>

          <Box className="version-update">
            <Typography variant="caption">
              <span>Last Modified</span>
            </Typography>
            <Typography>
              {item?.updated_at &&
                format(new Date(item.updated_at), "MMM dd yyyy")}
            </Typography>
          </Box>
        </BoxWrap>
      </>
    ),
  };

  return (
    <>
      <Grid
        item
        lg={3}
        md={4}
        sm={6}
        xs={12}
        data-testid="device-card"
        className="card"
      >
        <CardLink
          data-testid="card-link"
          to={{
            pathname: props?.updateUrl,
            ...(props?.location && { state: { from: props?.location } }),
          }}
        >
          <Card
            className="editDetails"
            cardContentclassName="cardHover deviceCard"
            {...CardComp}
          />
        </CardLink>
      </Grid>
      <Modal
        data-testid="delete-modal"
        open={open}
        handleClose={handleModal}
        title={`Delete ${name}`}
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModal,
            additionalAttributes: {
              "data-testid": "cancel-modal",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: handleDelete,
            additionalAttributes: {
              "data-testid": "accept-modal",
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: item?.version,
          module: name,
        })}
      </Modal>
    </>
  );
};
export default DeviceCard;
