import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { useModal } from "app/hooks/modal";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import TableView from "./GetStatsView.jsx";

const GetStats = ({
  isLoading,
  teamStats,
  statsCount,
  getTeamStats,
  deleteStats,
  deleteMultiStats,
  access_type,
  ...props
}) => {
  const [deleteData, setDeleteData] = useState({});
  const { sport_key, env_key, team_name } = props.match.params;
  const [uid, setUid] = useState();
  const [selected, setSelected] = useState([]);
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  useEffect(() => {
    setSelected([]);
    getTeamStats(team_name);
  }, [getTeamStats, team_name]);

  const deleteModalHandler = (event, uid, data) => {
    setDeleteData(data);
    setUid(uid);
    openModalHandler("showDelete");
  };

  const modalHandleClose = () => {
    closeModalHandler();
    setUid();
    setDeleteData({});
  };

  const handleModalDelete = async () => {
    await deleteStats({ url: `/v1/nba/admin/data/team_stats/${uid}` });
    setSelected([]);
    getTeamStats(team_name);
    modalHandleClose();
    setUid();
    setDeleteData({});
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-btn" />,
      handler: (event, uid) =>
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/team/stats/update/${uid}`,
          state: { from: props.location },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const headings = [
    { label: "Team ID", id: "tid" },
    { label: "League ID", id: "league_id" },
    { label: "Year", id: "year" },
    { label: "Season ID", id: "season_id" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const deleteMultipleHandler = () => {
    openModalHandler("showMultipleDelete");
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: teamStats });
    setSelected(newSelecteds);
  };

  const handleMultiDelete = async () => {
    await deleteMultiStats({
      url: `/v1/nba/admin/data/team_stats`,
      uids: selected,
    });
    setSelected([]);
    getTeamStats(team_name);
    modalHandleClose();
  };

  return (
    <TableView
      name="Team Stats"
      data={teamStats}
      isLoading={isLoading}
      actions={actions}
      totalCount={statsCount}
      handleModalDelete={handleModalDelete}
      handleModalClose={modalHandleClose}
      openModal={showDelete}
      multipleModal={showMultipleDelete}
      multipleDeleteHandler={deleteMultipleHandler}
      handleSingleClick={handleSingleClick}
      handleSelectAllClick={handleSelectAllClick}
      handleMultiDelete={handleMultiDelete}
      selectedList={selected}
      numSelected={selected.length}
      isSorting
      headings={headings}
      checkbox={true}
      actionMenu={false}
      deleteData={deleteData}
      {...props}
    />
  );
};

/* istanbul ignore next */
const mapDisptachToProps = dispatch => {
  return {
    deleteStats: payload => dispatch(deleteRecord(payload)),
    deleteMultiStats: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDisptachToProps)(GetStats);
export { Container, GetStats as Component };
