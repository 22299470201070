import { blue, green, grey, indigo, red } from "@material-ui/core/colors";
import { THEMES } from "./constants";
import merge from "deepmerge";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    type: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    white: {
      main: "#fff",
      white100: "#f9f9fc",
      white200: "#f6f6f6",
    },
    linkColor: {
      main: blue[800],
    },
    red: {
      main: red[500],
    },
    grey: {
      grey100: "#f7f9fc",
      grey200: "#eaeaf0",
      grey300: grey[300],
      grey500: grey[500],
      grey800: "#376fd0",
      dimGrey: "#616161",
      greyLight: "#DCDBDC",
      greyLighter: "#F6F8FB",
      greyE0E0E0: "#e0e0e0",
      greyDark: "#999999",
      greyCCC: "#cccccc",
      greyE4E7EB: "#e4e7eb",
      greyE3E7EB: "#e3e7eb",
      greyF1F1F1: "#f1f1f1",
      greyF2F2F2: "#f2f2f2",
      grey222222: "#222222",
      grey9E9E9E: "#9e9e9e",
    },
    black: {
      main: "#000000",
      blackDark: "#1565c0",
      blackLight: "#1C2A41",
      blackLighter: "#1e1e1e",
    },
    green: {
      green0E9642: "#0e9642",
    },
    blue: {
      blue100: "#c2e3ff",
      blue08C: "#08c",
      blue600: "#1e88e5",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    zIndex: 1250,
    maxHeight: "64px",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  subHeader: {
    zIndex: 900,
    maxHeight: "47px",
  },
  footer: {
    color: grey[500],
    background: "#FFF",
    zIndex: 1100,
    maxHeight: "28px",
  },
  sidebar: {
    color: grey[200],
    background: "#1b2430",
    zIndex: 1000,
    drawerwidth: "260px",
    drawerwidthsmall: "68px",
    menuItem: {
      hoverColor: {
        color: "#10151c",
      },
    },
    toolbar: {
      menuButton: {
        background: "#f9f9fc",
        hoverColor: {
          color: "#f9f9fc",
        },
      },
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    type: "dark",
    primary: {
      main: customBlue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    type: "light",
  },
  header: {
    color: grey[200],
    background: customBlue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: customBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    type: "light",
  },
  sidebar: {
    color: "#FFF",
    background: customBlue[700],
    header: {
      color: "#FFF",
      background: customBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: indigo[700],
    header: {
      color: "#FFF",
      background: indigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: indigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants = [
  defaultVariant,
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
];

export default variants;
