import { capitalize } from "utils/functions";
import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { Fragment, useEffect, useState } from "react";
import { useQueryParams } from "app/hooks";
import { useSelector } from "react-redux";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import React from "react";
import TableView from "./TemplatView";

const Templates = props => {
  const { access_type } = useSelector(state => state.application);
  const [deleteData, setDeleteData] = useState({});
  const { template_type, sport_key, env_key } = props.match.params;
  const [modal, openModal] = useState(false);
  const [Multiple, openMultiple] = useState(false);
  const [selected, setSelected] = useState([]);
  const [uid, setUid] = useState("");
  const { data, Templatecount, isLoading, getData } = props;
  const { searchText } = useQueryParams();

  useEffect(() => {
    setSelected([]);
    getData(template_type);
  }, [template_type, getData]);

  const headings = [
    { label: "Name", id: "name" },
    { label: "Type", id: "type" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const UpdateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/template/update/${uid}`,
      state: { from: props.location },
    });
  };

  const DeleteHandler = (event, uid, data) => {
    setDeleteData(data);
    setUid(uid);
    openModal(true);
  };

  const PushHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/add/${uid}`,
      search: `?isTemp=true&type=message`,
      state: { from: props.location },
    });
  };

  /*istanbul ignore next*/
  const handleModalClose = () => {
    setUid("");
    setDeleteData({});
    setSelected([]);
    openModal(false);
  };

  const handleModalDelete = () => {
    const url = `/v1/push_notification/admin/push_template/${uid}`;
    props.deletePushTemplate({ url }).then(res => {
      setUid("");
      setDeleteData({});
      openModal(false);
      setSelected([]);
      getData(template_type);
    });
  };

  const deleteMultipleHandler = event => {
    openMultiple(true);
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  const handleMultiClose = event => {
    setSelected([]);
    openMultiple(false);
  };

  const handleMultiDelete = event => {
    const url = `/v1/push_notification/admin/push_template/`;
    const uids = selected;

    props.deletePushTemplates({ url, uids }).then(res => {
      setSelected([]);
      openMultiple(false);
      setSelected([]);
      getData(template_type);
    });
  };

  const actions =
    template_type === "others"
      ? [
          {
            id: "edit",
            label: "Edit",
            icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
            handler: UpdateHandler,
          },

          {
            id: "delete",
            label: "Delete",
            icon: (
              <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />
            ),
            handler: DeleteHandler,
            showAction: () => access_type !== Constants.READ_ONLY,
          },
          {
            id: "sendPush",
            label: "Send Push",
            icon: <EmailRoundedIcon fontSize="small" data-testid="send-btn" />,
            handler: PushHandler,
          },
        ]
      : [
          {
            id: "edit",
            label: "Edit",
            icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
            handler: UpdateHandler,
          },
        ];

  return (
    <Fragment>
      <TableView
        data={data}
        actions={actions}
        headings={headings}
        openModal={modal}
        checkbox={template_type === "others" ? true : false}
        selectedList={selected}
        handleSingleClick={handleSingleClick}
        handleSelectAllClick={handleSelectAllClick}
        multipleDeleteHandler={deleteMultipleHandler}
        handleModalClose={handleModalClose}
        handleModalDelete={handleModalDelete}
        MultipleModal={Multiple}
        handleMultiClose={handleMultiClose}
        handleMultiDelete={handleMultiDelete}
        numSelected={selected.length}
        name={
          searchText ? "Push Template" : `${capitalize(template_type)} Template`
        }
        isSorting
        totalCount={Templatecount}
        isLoading={isLoading}
        deleteData={deleteData}
      />
    </Fragment>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    deletePushTemplate: payload => dispatch(deleteRecord(payload)),
    deletePushTemplates: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Templates);

export { Container, Templates as Component };
