import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdatePolicies = props => {
  const [policiesData, setPoliciesData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/arena/policies`;

  useEffect(() => {
    getPoliciesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPoliciesData = useCallback(async () => {
    const url = `/v1/nba/admin/app/policies/${uid}`;
    const {
      data: { policy },
    } = await dispatch(get({ url }));
    setPoliciesData(policy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      const form = {
        ...data,
        device_type: "ios",
      };

      await dispatch(
        updateRecord({
          url: `/v1/nba/admin/app/policies/${uid}`,
          key: "policy",
          form,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Policies"
        breadCrumbItems={[
          {
            title: "Update Policies",
          },
        ]}
        backUrl={getURL}
      />
      {policiesData && (
        <SchemaForm
          defaultValues={policiesData}
          onSubmit={onSubmit}
          schema={schema}
          modelName="policies"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdatePolicies;
