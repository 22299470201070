import { Switch } from "styles/global.style";
import { useController, useFormContext } from "react-hook-form";
import React from "react";

export const RHFSwitchField = props => {
  const { name, handleChange, ...restProps } = props;
  const { control } = useFormContext();

  const {
    field: { ref, ...inputProps },
  } = useController({ name, control });

  return (
    <Switch
      checked={inputProps.value}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onChange={e => {
        inputProps.onChange(e.target.checked);
        handleChange && handleChange(e);
      }}
      color="primary"
      {...restProps}
    />
  );
};
