import { a11yProps, TabPanel } from "./TabPanel";
import { Tab, Tabs } from "./Tabs.style";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
const DefaultTabs = ({ tabs, ...restProps }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (tabs.length === 1) {
      const setDefaultLength = 0;
      setValue(setDefaultLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.length]);
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="Mui_Tabs"
        {...restProps}
      >
        {tabs
          ? tabs.map(({ title }, index) => (
              <Tab label={title} {...a11yProps(index)} key={index} />
            ))
          : null}
      </Tabs>
      {tabs
        ? tabs.map(({ content }, index) => (
            <TabPanel value={value} index={index} key={index}>
              {content}
            </TabPanel>
          ))
        : null}
    </>
  );
};
DefaultTabs.defaultProps = {
  tabs: [],
};
DefaultTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, content: PropTypes.element }),
  ),
};
export default DefaultTabs;
