const schema = {
  class: {
    title: "Drops V2 Config",
    uid: "drops_v2_config",
    _schema: [
      {
        uid: "title",
        display_name: "Title",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
    ],
  },
};
export default schema;
