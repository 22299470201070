import { Box } from "./index.style";
import { generateQueryParams } from "utils/functions";
import { Pagination as MuiPagination } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "app/hooks";
import queryString from "query-string";
import React, { useEffect, useState } from "react";

export default function Pagination({ count, limit, ...restProps }) {
  const history = useHistory();
  const { page, sort, value } = useQueryParams();
  const [currentPage, setCurrentPage] = useState(page);
  const totalPage = Math.ceil(Number(count) / Number(limit));

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const onChange = (event, pageNo) => {
    setCurrentPage(pageNo);
    const state = {
      ...queryString.parse(window.location.search),
      page: pageNo,
      sort: sort,
      value: value,
    };
    const url = `${window.location.pathname}${generateQueryParams({
      obj: state,
    })}`;
    history.push(url, { ...state });
  };

  return (
    <Box data-testid="pagination" py={3}>
      <MuiPagination
        count={totalPage}
        page={currentPage}
        onChange={onChange}
        {...restProps}
      />
    </Box>
  );
}
