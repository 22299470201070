import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { Collapsible } from "app/components/Common/Collapsible";
import { Constants } from "utils/constants";
import { deleteConfirmationMessage } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import { useDispatch } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const headings = [
  { label: "Transaction Id", id: "transaction_id" },
  { label: "User Id", id: "user_id" },
  { label: "Order Id", id: "order_id" },
  { label: "Request Refund Date", id: "request_refund_date", type: "date" },
  { label: "Provider", id: "provider" },
  { label: "Status", id: "status" },
];

const extraInfo = [
  { label: "Payment Gateway", id: "payment_gateway" },
  { label: "Venue Id", id: "venue_id" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const GetRefundDetails = ({
  match,
  history,
  location,
  getRefundDetails,
  refundData,
  dataCount,
  isLoading,
  access_type,
}) => {
  const { READ_ONLY } = Constants;

  //States
  const [refundObj, setRefundObj] = useState(null);
  const [dataId, setUid] = useState("");
  const [selected, setSelected] = useState([]); //for checkboxes

  //handle modals
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  // Params
  const { sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { page, skip, limit, sort, value, searchText } = useQueryParams();

  // Functions
  // Handle Single Delete  (Delete Icon under Actions)
  const handleModalClose = event => {
    closeModalHandler();
    setUid("");
    setRefundObj({});
  };

  const handleDelete = async (event, uid) => {
    event.stopPropagation();
    await dispatch(
      deleteRecord({
        url: `/v1/admin/order/refund/${uid}`,
      }),
    );
    setSelected([]);
    handleModalClose(event);
    getRefundDetails();
  };

  // Handle Multi Delete
  const multipleDeleteHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const deleteMultiple = async () => {
    await dispatch(
      deleteRecords({
        url: `/v1/admin/order/refund`,
        uids: selected,
      }),
    );
    setSelected([]);
    getRefundDetails();
    handleModalClose();
  };

  //Checkbox
  const handleSingleClick = (event, dataId) => {
    const newSingleSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSingleSelected);
  };

  const handleSelectAllClick = event => {
    const newMultiSelected = checkboxSelectAllClick({
      event,
      data: refundData,
    });
    setSelected(newMultiSelected);
  };

  // Component Life Cycle
  useEffect(() => {
    setSelected([]);
    getRefundDetails();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, skip, limit, sort, value, searchText]);

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="edit_btn" />,
      handler: (event, uid) => {
        push({
          pathname: `/dashboard/${sport_key}/${env_key}/orders/refund/update/${uid}`,
          state: { from: location },
        });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete_btn" />,
      handler: (event, uid, dataItem) => {
        setRefundObj(dataItem);
        setUid(uid);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      {isLoading && (
        <SkeletonTable
          headings={headings}
          name="Refund"
          actions={[
            {
              id: "edit",
              label: "Edit",
              icon: <EditIcon fontSize="small" data-testid="edit-btn" />,
              handler: () => {},
            },
            {
              id: "delete",
              label: "Delete",
              icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
              handler: () => {},
            },
          ]}
          checkbox={true}
        />
      )}
      {refundData.length >= 1 && isLoading === false ? (
        <Table
          data-testid="table"
          name="Refund"
          headings={headings}
          actions={actions}
          data={refundData}
          isSorting
          limit={10}
          totalCount={dataCount}
          checkbox={access_type !== READ_ONLY}
          handleSelectAllClick={handleSelectAllClick}
          handleSingleClick={handleSingleClick}
          selectedList={selected}
          numSelected={selected.length}
          multipleDeleteHandler={multipleDeleteHandler}
          extraInfo={extraInfo}
          collapsibleComponent={<Collapsible />}
        />
      ) : (
        !isLoading && <NoData data-testid="empty-data">No Data Found</NoData>
      )}
      <Modal
        className="small"
        data-testid="delete-modal"
        open={showDelete}
        handleClose={handleModalClose}
        title="Delete Order Refund"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "No",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "no_btn",
            },
          },
          {
            id: "2",
            name: "Yes",
            handler: event => {
              return handleDelete(event, dataId);
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "yes_btn",
            },
          },
        ]}
      >
        {refundObj &&
          deleteConfirmationMessage({
            name: refundObj?.user_id,
            module: "Order Refund",
          })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        handleClose={handleModalClose}
        title="Delete Order Refund"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "confirm_multi_del",
            },
          },
        ]}
      >
        Are you sure want to delete <strong>{selected.length}</strong> items?
      </Modal>
    </>
  );
};

export default GetRefundDetails;
