import { connect } from "react-redux";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import React, { useEffect, useState } from "react";
import UserForm from "./indexView";

function UpdateUser(props) {
  const {
    history,
    match,
    location: { state },
  } = props;
  const [usersData, setUsersData] = useState();
  const { sport_key, env_key, uid: userId } = match.params;

  const getDataById = async () => {
    const {
      data: { user },
    } = await props.getUser({ url: `/v1/app_user/admin/user/${userId}` });
    setUsersData(user);
  };

  const upadteDataById = async form => {
    const data = await props.updateUser({
      url: `/v1/app_user/admin/user/${userId}`,
      key: "user",
      form,
    });

    if (data.status === 200) {
      UsersTable();
    }
  };

  const UsersTable = async () => {
    history.push(`/dashboard/${sport_key}/${env_key}/push-notification/users`);
  };

  const cancelForm = async () => {
    history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/push-notification/users`,
    );
  };

  useEffect(() => {
    getDataById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {usersData && (
        <UserForm
          usersData={usersData}
          apiCallFunction={upadteDataById}
          cancelForm={cancelForm}
          sport_key={sport_key}
          env_key={env_key}
        />
      )}
    </>
  );
}

// export default UpdateUser;

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(get(payload)),
    updateUser: payload => dispatch(updateRecord(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(UpdateUser);

export { Container, UpdateUser as Component };
