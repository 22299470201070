import { Button } from "@dfep/ui-component";
import { FormWrapper } from "styles/global.style";
import { useForm } from "react-hook-form";
import Form from "app/components/Common/Form";
import PanelFooter from "app/components/Common/PanelFooter";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useState } from "react";
// import uniqueId from "lodash/uniqueId";

const UserForm = props => {
  const { apiCallFunction, cancelForm } = props;

  const UserUseForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all", // It will Focus on every field  where error is there not on first error field which is by default
  });
  const {
    formState: { dirtyFields, errors },
  } = UserUseForm;

  const [userSchema] = useState({
    title: "",
    name: "user",
    uid: "user",
    field_metadata: {
      options: [],
    },
    defaultValue: "",
    validations: {},
    type: "group",
    _schema: [
      {
        title: "Personal Id",
        uid: "personal_id",
        name: "personal_id",
        type: "text",
        defaultValue: props?.usersData?.personal_id ?? "",
        validations: {},
        field_metadata: {
          isBorder: false,
          options: [],
          textFieldProps: {
            disabled: true,
          },
        },
        _schema: [],
      },
      {
        title: "Custom Fields",
        uid: "customField",
        name: "customField",
        type: "jsonEditor",
        defaultValue:
          JSON.stringify(props?.usersData?.custom_fields, null, "\t") ?? "{}",
        validations: {},
        field_metadata: {
          isBorder: true,
        },
        _schema: [],
      },
    ],
  });

  const submitFormHandler = async data => {
    const form = {
      ...props?.usersData,
      personal_id: data.personal_id,
      custom_fields: JSON.parse(`${data.customField}`),
    };

    await apiCallFunction(form);
  };
  return (
    <>
      <PanelHeader
        title="Update User"
        backUrl={`/dashboard/${props.sport_key}/${props.env_key}/push-notification/users`}
        breadCrumbItems={[
          {
            title: "Users",
          },
        ]}
      />

      <FormWrapper elevation={4} variant="outlined">
        <Form
          formId="userForm"
          customSchema={userSchema}
          onSubmit={submitFormHandler}
          additionalFormAttributes={{
            className: "maxWidthSm",
            "data-testid": "userForm",
          }}
          form={UserUseForm}
        />

        <PanelFooter>
          <div>
            <Button
              data-testid="cancel-btn"
              onClick={cancelForm}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={
                !Object.keys(dirtyFields).length || !!Object.keys(errors).length
              }
              form="userForm"
              variant="contained"
              color="primary"
              type="submit"
              data-testid="userFormSubmit"
            >
              {props?.usersData ? "Save" : "Add"}
            </Button>
          </div>
        </PanelFooter>
      </FormWrapper>
    </>
  );
};

export default UserForm;
