/* istanbul ignore file */
/* eslint-disable react-hooks/exhaustive-deps */
import { a11yProps, Tab, Tabs } from "app/components/Common/Tabs";
import { Container as AddAsset } from "app/pages/Dashboard/assets/AddAssets";
import { applicationAction } from "store/reducers/applicationSlice";
import { Container as Assets } from "app/pages/Dashboard/assets";
import { getAssets, getEnvironment } from "services/getObjects";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Container as Settings } from "app/pages/Dashboard/settings/";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddEnvironments from "app/pages/Dashboard/envs/AddEnvironments";
import Environment from "app/pages/Dashboard/envs";
import React, { useCallback, useEffect, useState } from "react";

const TabIndex = props => {
  const active = () => {
    if (props.location.pathname === `${props.match.url}/envs`) {
      return 0;
    } else if (props.location.pathname === `${props.match.url}/assets`) {
      return 1;
    } else if (props.location.pathname === `${props.match.url}/settings`) {
      return 2;
    } else {
      return 0;
    }
  };

  const [value, setValue] = useState(active());
  const [leagues, setLeagues] = useState([]);
  const { isOwner, sport_type } = useSelector(state => state.application);
  const { sports } = useSelector(state => state.constants);

  const [envs, setEnvs] = useState([]);
  const [assests, setAssests] = useState([]);
  const [totalAssetsCount, setTotalAssetsCount] = useState();
  const [assestLoading, setAssestLoading] = useState(false);

  const dispatch = useDispatch();
  const { sort, value: sortValue, skip, limit } = useQueryParams();
  const { sport_key } = props.match.params;

  // Fetch Sports Constants from Redux (Specify to leagues)
  const getConstants = sport_type => {
    const sport = sports?.find(field => field.value === sport_type);
    setLeagues(sport ? sport["leagues"] : []);
  };

  // Fetch Environment Listing
  const fetchEnvironment = useCallback(async () => {
    // setKey(Constants.SPORT_KEY, sport_key);
    setEnvs([]);
    const { data } = await dispatch(getEnvironment({ sport_key }));
    setEnvs(data.envs);
  }, [sport_key]);

  const getAssetData = useCallback(async () => {
    // setKey(Constants.SPORT_KEY, sport_key);
    try {
      setAssestLoading(true);
      const {
        data: { assets, count },
      } = await dispatch(
        getAssets({ sport_key, sort, value: sortValue, skip, limit }),
      );
      setAssestLoading(false);
      setAssests(assets);
      setTotalAssetsCount(count);
    } catch (e) {
      /*istanbul ignore next*/
      setAssestLoading(false);
    }
  }, [sport_key, sort, sortValue, skip, limit]);

  useEffect(() => {
    dispatch(
      applicationAction({
        sport_key: sport_key,
      }),
    );
    props.getApplication(sport_key);
    fetchEnvironment();
  }, []);

  useEffect(() => {
    getConstants(sport_type);
  }, [sports, sport_type]);

  useEffect(() => {
    setValue(active());
  }, [props.location.pathname]);

  return (
    <>
      {/* <TabWrapper
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      > */}
      {/* <div className="tabs"> */}
      <Tabs
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        // variant="scrollable"
        // scrollButtons="on"
        // indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label="Environments"
          component={Link}
          to={`${props.match.url}/envs`}
          {...a11yProps(0)}
        />
        <Tab
          label="Assets"
          component={Link}
          to={`${props.match.url}/assets`}
          {...a11yProps(1)}
        />
        {isOwner && (
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(4)}
          />
        )}
        {isOwner && value === 0 && (
          <AddEnvironments
            fetchEnvironment={fetchEnvironment}
            leagues={leagues}
          />
        )}
        {isOwner && value === 1 && (
          <AddAsset {...props} getAssetData={getAssetData} />
        )}
      </Tabs>
      {/* </div> */}
      {/* {isOwner && value === 0 && (
          <AddEnvironments
            fetchEnvironment={fetchEnvironment}
            leagues={leagues}
          />
        )}
        {isOwner && value === 1 && <AddAsset getAssetData={getAssetData} />} */}
      {/* </TabWrapper> */}
      <Switch>
        <Redirect
          from={`${props.match.path}`}
          to={`${props.match.path}/envs`}
          exact
        />
        <Route
          path={`${props.match.path}/envs`}
          render={props => (
            <Environment
              {...props}
              envs={envs}
              fetchEnvironment={fetchEnvironment}
              leagues={leagues}
            />
          )}
          exact
        />
        <Route
          path={`${props.match.path}/assets`}
          render={props => (
            <Assets
              {...props}
              assests={assests}
              assestLoading={assestLoading}
              totalAssetsCount={totalAssetsCount}
              getAssetData={getAssetData}
            />
          )}
          exact
        />
        {/* <Route
          path={`${props.match.path}/deeplinks`}
          component={Deeplink}
          exact
        /> */}
        {isOwner && (
          <Route
            path={`${props.match.path}/settings`}
            component={Settings}
            exact
          />
        )}
      </Switch>
    </>
  );
};

export default TabIndex;
