const schema = {
  class: {
    title: "Image POI",
    uid: "poi_image",
    _schema: [
      {
        display_name: "Name",
        uid: "name",
        mandatory: true,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Draw layer",
        uid: "draw_layer",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Expandable",
        uid: "expandable",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Hidden",
        uid: "hidden",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Size",
        uid: "size",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Rotation",
        uid: "rotation",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Levels",
        uid: "level_ids",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          multiple: true,
          options: [{ label: "Select", value: "" }],
        },
        _schema: [],
      },
    ],
  },
};

export default schema;
