import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdatePlayByPlay = props => {
  const [gameData, setGameData] = useState();
  const { team_name } = useSelector(state => state.application);
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/game/playByPlay/${team_name}`;

  useEffect(() => {
    getPlayerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPlayerData = useCallback(async () => {
    const url = `/v1/nba/admin/data/play_by_play/${uid}`;
    const {
      data: { play_by_play },
    } = await dispatch(get({ url }));
    setGameData(play_by_play);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          url: `/v1/nba/admin/data/play_by_play/${uid}`,
          key: "play_by_play",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Play By Play"
        breadCrumbItems={[
          {
            title: "Update Play By Play",
          },
        ]}
        backUrl={getURL}
      />
      {gameData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={gameData}
          schema={schema}
          modelName="playByPlays"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdatePlayByPlay;
