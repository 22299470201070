const schema = {
  class: {
    title: "Profile",
    uid: "profile",
    _schema: [
      {
        uid: "user_id",
        display_name: "User Id",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        display_name: "Last Login At",
        uid: "last_login_at",
        mandatory: false,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
    ],
  },
};
export default schema;
