import { FormWrapper } from "styles/global.style";
import TemplateMapper from "app/components/Common/CustomFieldForm/CustomFieldMapper";

const SeasonSettings = () => {
  // States

  // Params

  // Hooks

  // Functions

  // LifeCycle

  // Render
  return (
    <FormWrapper className="settings-wrap" data-testid="form-wrapper">
      <TemplateMapper
        modelName="team_season_averages"
        title="Team Season Averages Template"
        uid="team_season_averages_template"
      />
    </FormWrapper>
  );
};

export default SeasonSettings;
