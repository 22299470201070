import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import AppVersion from "app/pages/Dashboard/Data/Arena/Policies/get/AppVersions";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";

import Settings from "app/pages/Dashboard/Data/Arena/Policies/get/Settings";

import { connect, useSelector } from "react-redux";
import { deleteRecord } from "services/deleteObject";
import { getArenaPolicies } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { Link, Redirect, Route, Switch } from "react-router-dom";

const PolicyTabs = props => {
  const { sport_key, env_key } = props.match.params;

  const { access_type } = useSelector(state => state.application);

  let tabObj = { appVersion: 0, settings: 1 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const [data, setData] = useState([]);
  const [count, setCount] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const limit = 12;
  const { page, skip, searchText } = useQueryParams({ limit });

  const getPolicies = useCallback(async () => {
    setIsLoading(true);
    const {
      data: { policies, count },
    } = await props.getData({ skip, limit, searchText });

    if (count !== 0 && policies.length === 0) {
      initialRoute(props.history);
    } else {
      setData(policies);
      setCount(count);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, page, skip, activeTab, searchText]);

  return (
    <>
      <PanelHeader
        title="Policies"
        refreshHandler={getPolicies}
        breadCrumbItems={[
          {
            title: "Policies",
          },
        ]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            data-testid="add-btn"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/arena/policies/add`,
                state: { from: props.location },
              });
            }}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label="App Versions"
            component={Link}
            to={`${props.match.url}/appVersion`}
            {...a11yProps(0)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/appVersion`}
            exact
          />
          <Route
            path={`${props.match.path}/appVersion`}
            /*  istanbul ignore next   */
            render={() => {
              /*  istanbul ignore next   */
              return (
                <AppVersion
                  data={data}
                  isLoading={isLoading}
                  count={count}
                  limit={limit}
                  getPolicies={getPolicies}
                  name="Policy"
                  isOwner={props.isOwner}
                  access_type={props.access_type}
                  {...props}
                />
              );
            }}
            exact
          />
          <Route
            path={`${props.match.path}/settings`}
            /*  istanbul ignore next   */
            render={() => {
              /*  istanbul ignore next   */
              return <Settings {...props} />;
            }}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
};
const mapStateToProps = state => {
  return {
    access_type: state.application.access_type,
    isOwner: state.application.isOwner,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getData: payload => dispatch(getArenaPolicies(payload)),
    deleteData: payload => dispatch(deleteRecord(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(PolicyTabs);

export { Container, PolicyTabs as Component };
