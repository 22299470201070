import React from "react";

const BroadcastMsg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M1.86646 12.4369C1.32531 12.3265 0.797159 12.6757 0.686795 13.2169C0.576432 13.758 0.92565 14.2862 1.4668 14.3965L1.86646 12.4369ZM3.9368 16.8665C4.04716 17.4077 4.57531 17.7569 5.11646 17.6465C5.6576 17.5362 6.00682 17.008 5.89646 16.4669L3.9368 16.8665ZM1.77716 9.04784C1.22826 8.98679 0.733803 9.38227 0.672754 9.93117C0.611705 10.4801 1.00719 10.9745 1.55609 11.0356L1.77716 9.04784ZM7.29775 16.7772C7.3588 17.3261 7.85326 17.7216 8.40216 17.6606C8.95106 17.5995 9.34655 17.1051 9.2855 16.5562L7.29775 16.7772ZM0.666626 6.66671C0.666626 7.21899 1.11434 7.66671 1.66663 7.66671C2.21891 7.66671 2.66663 7.21899 2.66663 6.66671H0.666626ZM11.6666 15.6667C11.1143 15.6667 10.6666 16.1144 10.6666 16.6667C10.6666 17.219 11.1143 17.6667 11.6666 17.6667V15.6667ZM1.4668 14.3965C2.71085 14.6503 3.68308 15.6225 3.9368 16.8665L5.89646 16.4669C5.4825 14.4371 3.89622 12.8508 1.86646 12.4369L1.4668 14.3965ZM1.55609 11.0356C4.57704 11.3716 6.96176 13.7563 7.29775 16.7772L9.2855 16.5562C8.84612 12.6057 5.72764 9.48721 1.77716 9.04784L1.55609 11.0356ZM2.66663 6.66671V5.00004H0.666626V6.66671H2.66663ZM2.66663 5.00004C2.66663 4.63185 2.9651 4.33337 3.33329 4.33337V2.33337C1.86053 2.33337 0.666626 3.52728 0.666626 5.00004H2.66663ZM3.33329 4.33337H16.6666V2.33337H3.33329V4.33337ZM16.6666 4.33337C17.0348 4.33337 17.3333 4.63185 17.3333 5.00004H19.3333C19.3333 3.52728 18.1394 2.33337 16.6666 2.33337V4.33337ZM17.3333 5.00004V15H19.3333V5.00004H17.3333ZM17.3333 15C17.3333 15.3682 17.0348 15.6667 16.6666 15.6667V17.6667C18.1394 17.6667 19.3333 16.4728 19.3333 15H17.3333ZM16.6666 15.6667H11.6666V17.6667H16.6666V15.6667Z"
          fill="#EEEEEE"
        />
      </g>
    </svg>
  );
};

export default BroadcastMsg;
