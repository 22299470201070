import { Box, Grid, Typography } from "@material-ui/core";
import { CardHeader, CardLink, DashboardCard } from "./Card.style";
import { TeamColorMapper } from "utils/functions";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import SportsLogo from "assets/sport_logo";
import TeamLogo from "assets/team_logo";
// import Tooltip from "@material-ui/core/Tooltip";
// import UserIcon from "assets/user.svg";

// function showOtherUsers(count) {
//   if (count === 2 || count === 3) {
//     return "";
//   }
//   return `+ ${count - 3} others`;
// }

/* function tooltipList(users) {
  const data = users?.slice(3).map(item => `${item.first_name || item.email}`);
  return data?.join("\n");
} */

function CardContent(props) {
  // const { team_name, tid, name, sport_type, sport_key, users } = props.data;

  const { name, sport_key } = props.data;

  const team_name = props.data.plugins ? props.data.plugins.team_name : null;
  const team_id = props.data.plugins ? props.data.plugins.team_id : null;

  const BorderColor = useMemo(() => {
    return TeamColorMapper(team_name, team_id, props.leagues);
  }, [team_name, team_id, props.leagues]);

  const CardComp = {
    cardBorderColor: `4px solid ${
      props.data.plugins ? BorderColor : "#232f3e"
    }`,
    cardContent: () => (
      <>
        <CardHeader
          subheader={
            props.data.plugins && (
              <Avatar
                className="headerLogo"
                variant="square"
                src={SportsLogo("nba")}
              ></Avatar>
            )
          }
        />
        <Box display="flex" alignItems="center" className="appInfo">
          <Avatar
            src={TeamLogo(
              props.data.plugins ? props.data.plugins.team_name : "dfe",
            )}
            alt={props.data.plugins ? props.data.plugins.team_name : "DFE"}
          ></Avatar>
          <Typography variant="h6">{name}</Typography>
        </Box>
      </>
    ),
    cardFooter: () => (
      <>
        <Box
          component="div"
          alignItems="center"
          display="flex"
          className="timestamp"
        >
          <AccessTimeIcon className="timeIcon" />
          <Typography>20h ago</Typography>
        </Box>
        {/* <Box alignItems="center" display="flex">
          <Box className="userList">
            {React.Children.toArray(
              users.slice(0, 3).map(item => (
                <Tooltip title={item.first_name || item.email} arrow>
                  <Avatar alt={item.first_name || item.email} src={UserIcon} />
                </Tooltip>
              )),
            )}
          </Box>
          {users.length > 3 && (
            <Typography>{showOtherUsers(users.length)}</Typography>
          )}
        </Box> */}
      </>
    ),
  };

  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <CardLink
          data-testid="card-link"
          to={{
            pathname: `${props.path}/${sport_key}/envs`,
          }}
        >
          <DashboardCard {...CardComp} />
        </CardLink>
      </Grid>
    </>
  );
}

CardContent.propTypes = {
  data: PropTypes.shape({
    team_name: PropTypes.string,
    name: PropTypes.string,
    sport_type: PropTypes.string,
    sport_key: PropTypes.string,
  }),
  path: PropTypes.string,
};

export default CardContent;
