import loginLeft from "../../../assets/DFEP-left-graphic.svg";
import loginRight from "../../../assets/DFEP-right-graphic.svg";
import MuiContainer from "@material-ui/core/Container";
import styled from "styled-components";

export const AuthContainer = styled(MuiContainer)`
  &.MuiContainer-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.palette.white.white100};
    background-repeat: no-repeat, no-repeat;
    background-attachment: fixed, fixed;
    background-position: left bottom, right bottom;
    background-image: url(${loginLeft}), url(${loginRight});
    justify-content: space-between;
    padding: 40px 0 10px;
    position: relative;
    max-width: 100%;
    height: 100vh;
    h5 {
      font-size: 18px;
      margin-top: 20px;
    }
    form {
      flex-direction: column;
      display: flex;
    }
    p {
      font-size: 14px;
      margin-bottom: 8px;
    }
    .field-wrap {
      display: flex;
      margin: 10px 0 15px;
    }
    .MuiFormControl-root,
    .MuiButton-contained {
      width: 100%;
    }
    .link-btn {
      margin: -10px 0 12px;
      .MuiBox-root {
        width: 100%;
      }
      a {
        text-decoration: none;
      }
      .MuiButton-textPrimary {
        font-size: 12px;
        padding: 0;
      }
      button {
        &:hover,
        &:focus {
          background: transparent;
        }
      }
    }
    .login-logo {
      height: 38px;
      margin-bottom: 30px;
    }
    .MuiIconButton-root {
      background: none;
      padding: 12px 0;
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      flex-direction: column;
      padding: 30px 20px;
    }
    /* inside login box footerLogo */
    .info {
      margin-top: 12px;
      .footer-logo {
        height: 35px;
      }
    }
  }
  .MuiFormLabel-asterisk {
    color: ${props => props.theme.palette.red.main};
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const AuthFormWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .auth-form {
    padding: 32px 24px 22px;
    width: 400px;
    border-radius: 4px;
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.1);
    /* inside login box footerLogo */
    text-align: center;
    h1 {
      color: ${props => props.theme.palette.black.main};
      font-weight: 600;
      text-align: center;
      font-size: 17px;
      margin-bottom: 6px;
      line-height: 20.4px;
    }
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
    .MuiFormControlLabel-label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
    .MuiCheckbox-root {
      margin-right: 10px;
      padding: 0;
    }
  }
  h5 {
    color: ${({ theme }) => theme.palette.white.main};
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    .auth-form {
      margin-top: 20px;
    }
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 100%;
    .auth-form {
      width: 100%;
    }
  }
`;
export const PasswordWrap = styled.div`
  position: relative;
  .field {
    padding-bottom: 15px;
  }
  .correct {
    color: green;
  }
  .correct::before {
    position: relative;
    left: -10px;
    content: "✔";
  }
  .incorrect {
    color: red;
  }
  .incorrect::before {
    position: relative;
    left: -10px;
    content: "✖";
  }
  .password-requirement {
    position: absolute;
    z-index: 2;
    background: ${props => props.theme.palette.white.main};
    border-radius: 3px;
    padding: 15px;
    box-shadow: 0 0 5px ${props => props.theme.palette.grey[500]};
    min-width: 230px;
    top: 77px;
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: ${props => props.theme.palette.white.main};
      border-width: 12px;
      margin-left: -12px;
    }
    &:before {
      border-color: rgba(204, 204, 204, 0);
      border-bottom-color: #ccc;
      border-width: 13px;
      margin-left: -13px;
    }
  }
  ul {
    padding-left: 15px;
    margin: 10px 0 0;
    li {
      list-style: none;
      line-height: 24px;
    }
    span {
      font-size: 14px;
    }
  }
`;
