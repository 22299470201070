import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { useModal } from "app/hooks/modal";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import TableView from "./GetSplitsView.jsx";

const GetSplits = ({
  isLoading,
  teamSplits,
  splitsCount,
  getTeamSplits,
  match: {
    params: { team_name, sport_key, env_key },
  },
  deleteSplits,
  deleteMultiSplits,
  access_type,
  ...props
}) => {
  const [deleteData, setDeleteDate] = useState({});
  const [uid, setUid] = useState();
  const [selected, setSelected] = useState([]);
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  useEffect(() => {
    setSelected([]);
    getTeamSplits(team_name);
  }, [getTeamSplits, team_name]);

  const deleteModalHandler = (event, uid, data) => {
    setDeleteDate(data);
    setUid(uid);
    openModalHandler("showDelete");
  };

  const modalHandleClose = () => {
    setDeleteDate({});
    setUid();
    closeModalHandler();
  };

  const handleModalDelete = async () => {
    await deleteSplits({ url: `/v1/nba/admin/data/team_splits/${uid}` });
    setSelected([]);
    getTeamSplits(team_name);
    modalHandleClose();
    setDeleteDate({});
    setUid();
  };

  const UpdateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/team/splits/update/${uid}`,
      state: { from: props.location },
    });
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: deleteModalHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const headings = [
    { label: "Team ID", id: "tid" },
    { label: "League ID", id: "league_id" },
    { label: "Year", id: "year" },
    { label: "Month", id: "month" },
    { label: "Season ID", id: "season_id" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const deleteMultipleHandler = () => {
    openModalHandler("showMultipleDelete");
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: teamSplits });
    setSelected(newSelecteds);
  };

  const handleMultiDelete = async () => {
    await deleteMultiSplits({
      url: `/v1/nba/admin/data/team_splits`,
      uids: selected,
    });
    setSelected([]);
    getTeamSplits(team_name);
    modalHandleClose();
  };
  return (
    <TableView
      name="Team Splits"
      data={teamSplits}
      isLoading={isLoading}
      actions={actions}
      totalCount={splitsCount}
      handleModalDelete={handleModalDelete}
      handleModalClose={modalHandleClose}
      openModal={showDelete}
      multipleModal={showMultipleDelete}
      multipleDeleteHandler={deleteMultipleHandler}
      handleSingleClick={handleSingleClick}
      handleSelectAllClick={handleSelectAllClick}
      handleMultiDelete={handleMultiDelete}
      selectedList={selected}
      numSelected={selected.length}
      isSorting
      headings={headings}
      checkbox={true}
      actionMenu={false}
      deleteData={deleteData}
      {...props}
    />
  );
};

/* istanbul ignore next */
const mapDisptachToProps = dispatch => {
  return {
    deleteSplits: payload => dispatch(deleteRecord(payload)),
    deleteMultiSplits: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDisptachToProps)(GetSplits);
export { Container, GetSplits as Component };
