/* eslint-disable no-useless-escape */
// Generic Functions to be used in @dfep/auth MF
import { applicationAction } from "store/reducers/applicationSlice";
import { showNotifier } from "store/reducers/notifierSlice";
import queryString from "query-string";

const assignError = (obj, [first, ...rest], message) => ({
  ...obj,
  [first]: rest.length
    ? assignError(obj[first], rest, message)
    : {
        hasError: true,
        message: message,
      },
});

export const setError = (errorObj, uid, message) => {
  return assignError(errorObj, uid.split("."), message);
};

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = password => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$^~!%*?_&])[A-Za-z\d@#$^~!%*?_&]{8,16}$/.test(
    password,
  );
};

export const validateName = text => {
  if (text) {
    return /^\p{L}+[',.]{0,1}\p{L}*\s{0,1}(?:\p{L}+[',.]{0,1}\p{L}*\s*){0,1}$/gu.test(
      text,
    );
  }
  return false;
};

export const validateStartAndEndText = (start, end) => {};

/* istanbul ignore next */
export const validateLinkName = text => {
  if (text) {
    return /^[A-Za-z]+[-_+]{0,1}[A-Za-z0-9_-]*\s{0,1}(?:[A-Za-z0-9_-]*\s*)*$/.test(
      text,
    );
  }
  return false;
};

/* istanbul ignore next */
export const validateDeepLinkUrl = text => {
  if (text) {
    return /(?:(?:[\w-.]+):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi.test(
      text,
    );
  }
  return false;
};

/* istanbul ignore next */
export const validateKey = text => {
  if (text) {
    return /^[\w-]+$/.test(text);
  }
  return false;
};

//  Latitude and Longitude Regex link--->
// https://stackoverflow.com/a/31408260/1476784

/* istanbul ignore next */
export const validateLatitude = text => {
  if (text) {
    return /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(
      text,
    );
  }
  return false;
};

/* istanbul ignore next */
export const validateLongitude = text => {
  if (text) {
    return /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(
      text,
    );
  }
  return false;
};

/* istanbul ignore next */
export const validateUrl = text => {
  if (text) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
      text,
    );
  }
  return false;
};

/* istanbul ignore next */
export const validateAppName = text => {
  if (text) {
    return /^[\w\s]+$/.test(text);
  }
  return false;
};

/* istanbul ignore next */
export const snakeCase = text => {
  if (text) {
    return / |B(?=[A-Z])/.test(text);
  }
  return false;
};

/* istanbul ignore next */
export const getYearArray = () => {
  const currentYear = new Date().getFullYear();
  const yearArray = [];
  for (let i = currentYear + 1; i > currentYear - 9; i--) {
    const obj = {
      label: i.toString(),
      value: i.toString(),
    };
    yearArray.push(obj);
  }
  return yearArray;
};

/* istanbul ignore next */
export function filterArray({
  searchValue,
  list,
  searchProperty = "id",
  arrayName = "child",
}) {
  var matches = [];
  if (!Array.isArray(list)) return matches;

  list.forEach(function (item) {
    if (item[searchProperty] !== searchValue) {
      const childResults = filterArray({
        list: item[arrayName],
        searchValue,
        searchProperty,
        arrayName,
      });
      matches.push(Object.assign({}, item, { [arrayName]: childResults }));
    }
  });
  return matches;
}

/* istanbul ignore next */
export const capitalize = s => {
  if (s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  return "";
};

/* istanbul ignore next */
export const bytesToSize = bytes => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

/* istanbul ignore next */
export const isString = function (object) {
  if (typeof object === "string") {
    return true;
  } else {
    return false;
  }
};

/* istanbul ignore next */
export const isNullOrEmpty = function (stringValue) {
  if (isString(stringValue)) {
    if (stringValue == null || stringValue === "") {
      return true;
    }
  } else {
    if (stringValue == null) {
      return true;
    }
  }
  return false;
};

/* istanbul ignore next */
export const saveSportsEnvKey = ({ dispatch, params }) => {
  let { sport_key, env_key } = params;
  sport_key = sport_key ? sport_key : "";
  env_key = env_key ? env_key : "";
  if (sport_key !== "" || env_key !== "") {
    dispatch(
      applicationAction({
        sport_key,
        env_key,
      }),
    );
  }
};

/* istanbul ignore next */
export const generateQueryParams = ({ obj, key, value }) => {
  let queryParams = "";
  for (const property in obj) {
    if (property !== key) {
      if (obj[property]) {
        queryParams = `${queryParams}${property}=${obj[property]}&`;
      }
    }
  }
  if (value) {
    return `?${queryParams}${key}=${value}`;
  } else {
    return `?${queryParams.slice(0, -1)}`;
  }
};

/* istanbul ignore next */
export const initialRoute = history => {
  const { location } = window;
  const { pathname, search } = location;
  const state = { ...queryString.parse(search), page: 1 };
  history.push(
    `${pathname}${generateQueryParams({
      obj: state,
      key: "random",
      value: "",
    })}`,
    {
      ...state,
    },
  );
};

/* istanbul ignore next */
export const convertToSlug = Text => {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
};

/* istanbul ignore next */
export const validateUid = text => {
  if (text) {
    const result = /^[a-z0-9_]*$/gm.test(text);
    return result;
  }
  return false;
};

/* istanbul ignore next */
export const validateDisplayName = text => {
  if (text) {
    const result = /^([\w]+\s{0,})+$/gm.test(text);
    return result;
  }
  return false;
};

export const TeamColorMapper = (team_name, tid, leagues = []) => {
  try {
    for (let i = 0; i < leagues.length; i++) {
      const filteredTeam = leagues[i].teams
        .filter(team => team.value === team_name && team.tid === tid)
        .map(team => team.color);
      if (filteredTeam.length) {
        return `#${filteredTeam.shift()}`;
      }
    }
    return "#232f3e";
  } catch (e) {
    // istanbul ignore next
    return "#232f3e";
  }
};

export const deleteConfirmationMessage = ({ name, module }) => {
  return (
    <>
      Deleting <strong>"{name}"</strong> will permanently remove it from your{" "}
      {module}. Are you sure you want to delete?
    </>
  );
};

/* istanbul ignore next */
export const slugToTitle = slug => {
  const words = slug.split("_");

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" + ");
};

export const isValidWholeNumber = input => {
  return /^\d*$/.test(input);
};

/* istanbul ignore next */
export const generateOptionsList = ({ itemArray, label, value }) => {
  const options = [];

  itemArray.forEach(item => {
    const obj = {
      label: item[label],
      value: item[value],
    };
    options.push(obj);
  });

  return options;
};

export const dobValidation = ({ data }) => {
  const inputDate = new Date(data.dob);
  const currentDate = new Date();
  if (inputDate > currentDate) {
    throw new Error("dobInvalidDate");
  }
  return;
};

export const dobErrorHandler = ({ error, dispatch }) => {
  switch (error.message) {
    case "dobInvalidDate":
      return dispatch(
        showNotifier({
          open: true,
          message: "Bummer. Date of Birth cannot be more than current date",
          errors: {},
          variant: "error",
        }),
      );
    default:
      return;
  }
};

export const removeGoogleMapsPackage = ({
  mapLoaderInstance,
  mapLoaderStatus,
  modulesToBeRemoved,
}) => {
  try {
    if (mapLoaderInstance && mapLoaderStatus === "SUCCESS") {
      const modulePath = require.resolve("@googlemaps/react-wrapper"),
        cachedModule = require.cache[modulePath];
      if (cachedModule && cachedModule.children) {
        cachedModule.children.forEach(child => {
          if (modulesToBeRemoved.some(module => String(child).includes(module)))
            delete require.cache[child];
        });

        delete require.cache[modulePath];
      }
      mapLoaderInstance.deleteScript();
    }
  } catch (error) {
    console.log(error);
  }
};

// Converting {"key": "value"} -> {screen: "key", contentstack_model_uid: "value"}
export const reverseTransformData = items => {
  return (items || []).reduce((acc, item) => {
    const keys = Object.keys(item);
    if (keys.length === 1) {
      acc.push({ screen: keys[0], contentstack_model_uid: item[keys[0]] });
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};
export const transformKeyValueData = items => {
  return (items || []).reduce((acc, item) => {
    if (item.screen && item.contentstack_model_uid) {
      acc.push({ [item.screen]: item.contentstack_model_uid });
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};
