import { Controller, useFormContext } from "react-hook-form";
import { CustomFlexBox, FieldGroup } from "styles/global.style";
import { Container as ImageUploadView } from "./ImageUploadView";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import Label from "app/components/Common/Label";
import React from "react";
import TextField from "app/components/Common/TextField";

const ImageUpload = props => {
  const {
    uid,
    mandatory,
    display_name,
    onFieldDelete,
    index,
    field_metadata,
  } = props;
  const { control, getValues } = useFormContext();

  const state = useSelector(state => state.formData);
  const getFieldData = name => get(state, name);

  return (
    <Controller
      name={uid}
      control={control}
      rules={{
        required: mandatory ? `${display_name} is required` : false,
      }}
      defaultValue={getValues(`${uid}`)}
      render={({ field, fieldState, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, `${uid}`);
        const fieldError = get(errors, `${uid}`);
        if (field.value && typeof field.value === "object") {
          field.onChange(getFieldData(field.name));
        }
        return (
          <FieldGroup className="img-upload-panel">
            {props.index !== undefined && props.index !== "" ? (
              <Label className="grp-label" required={mandatory}>
                {props.display_name}-{props.index + 1}
              </Label>
            ) : (
              <Label className="grp-label" required={props?.mandatory}>
                {props.display_name}
              </Label>
            )}
            <CustomFlexBox>
              {field.value ? (
                <div className="url-view">
                  <FieldBorder isBorder={false} isError={hasFieldError}>
                    <TextField
                      id={uid}
                      className="img_url"
                      type="text"
                      variant="outlined"
                      error={hasFieldError}
                      value={field.value}
                      helperText={hasFieldError && fieldError.message}
                      FormHelperTextProps={{
                        "data-testid": `${uid}-error`,
                      }}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <IconButton
                      className="close-icon"
                      onClick={() => {
                        field.onChange("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </FieldBorder>
                </div>
              ) : (
                <ImageUploadView
                  field={field}
                  field_metadata={field_metadata}
                />
              )}
              {onFieldDelete && (
                <IconButton onClick={event => onFieldDelete(event, index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </CustomFlexBox>
            {hasFieldError && (
              <FormHelperText error>{fieldError?.message}</FormHelperText>
            )}
          </FieldGroup>
        );
      }}
    />
  );
};

export default ImageUpload;
