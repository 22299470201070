import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Coaches",
    uid: "coaches",
    _schema: [
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Person ID",
        uid: "pid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "First Name",
        uid: "fn",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Last Name",
        uid: "ln",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Coach Type",
        uid: "ct",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "College",
        uid: "college",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Date of birth",
        uid: "dob",
        mandatory: false,
        multiple: false,
        data_type: "date",
        field_metadata: {
          timeFormat: false,
        },
      },
      {
        display_name: "Headshot Image",
        uid: "headshot_image_url",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Action Image",
        uid: "action_image_url",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Bio",
        uid: "bio",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Order",
        uid: "order",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Hide this Coach",
        uid: "hide",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
