import { get } from "services/getObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "../schemaForm";

const UpdateDropv2Content = props => {
  const [contentData, setContentData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/drops/v2/content`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/admin/drops/v2/portal/${uid}`;
    const {
      data: { drop },
    } = await dispatch(get({ url }));
    setContentData(drop);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <PanelHeader
        title="Update Drop v2 Content"
        breadCrumbItems={[
          {
            title: "Update Drop Content",
          },
        ]}
        backUrl={getURL}
      />

      {contentData && (
        <SchemaForm
          defaultValues={contentData}
          schema={schema}
          modelName="drops_v2"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateDropv2Content;
