import { Container as GetDetails } from "app/pages/Dashboard/Data/Team/Leader_Details/get";
import { Route, Switch } from "react-router-dom";
import AddLeaderDetails from "app/pages/Dashboard/Data/Team/Leader_Details/add";
import React from "react";
import UpdateLeaderDetails from "app/pages/Dashboard/Data/Team/Leader_Details/update";

const LeaderDetails = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/add`}
        component={AddLeaderDetails}
        exact
      />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateLeaderDetails}
        exact
      />
      <Route path={`${props.match.path}`} component={GetDetails} />
    </Switch>
  );
};

export default LeaderDetails;
