import { createSlice } from "@reduxjs/toolkit";

export const notifierSlice = createSlice({
  name: "notifier",
  initialState: {
    open: false,
    message: "",
    errors: {},
    variant: "",
  },
  reducers: {
    showNotifier(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearNotifier(state) {
      return {
        ...state,
        open: false,
        message: "",
        errors: {},
        variant: "",
      };
    },
  },
});

export const { showNotifier, clearNotifier } = notifierSlice.actions;

export default notifierSlice.reducer;
