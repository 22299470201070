import { authServices } from "services/auth";
import { Button } from "@dfep/ui-component";
import { Container as ChangePassword } from "../changePassword";
import { connect, useDispatch, useSelector } from "react-redux";
import { Paper } from "../account.style";
import { setError, validateName } from "utils/functions";
import { setProfile } from "store/reducers/adminSlice";
import { Typography } from "@material-ui/core";
import cloneDeep from "lodash/cloneDeep";
import Grid from "@material-ui/core/Grid";
import isEqual from "lodash/isEqual";
import PanelHeader from "app/components/Common/PanelHeader";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  first_name: {
    hasError: false,
    message: "",
  },
  last_name: {
    hasError: false,
    message: "",
  },
};

let initialUserForm = {
  first_name: "",
  last_name: "",
};

const Profile = props => {
  const dispatch = useDispatch();
  const fName = useSelector(state => state.admin.first_name);
  const lName = useSelector(state => state.admin.last_name);
  const [first_name, bindFirstName, setFirstName] = useInput(fName);
  const [last_name, bindLastName, setLastName] = useInput(lName);
  const [email, setEmail] = useState("");
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));
  const [userForm, setUserForm] = useState(cloneDeep(initialUserForm));

  // This useEffect is call when first_name/last_name is changed
  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [first_name, last_name]);

  //API to fetch Account Profile details
  useEffect(() => {
    props
      .getProfileService({
        url: "/v1/admin/auth/user",
        method: "GET",
      })
      .then(response => {
        const { first_name, last_name, email } = response.data.user;
        dispatch(setProfile({ first_name, last_name, email }));
        setUserForm({
          first_name,
          last_name,
        });
        setFirstName(first_name);
        setLastName(last_name);
        setEmail(email);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Profile Update API Call on Form Submit
  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      const form = await formValidation();
      props
        .updateProfileService({
          url: "/v1/admin/auth/user",
          form,
          method: "PUT",
          showToast: true,
        })
        .then(response => {
          const { user } = response.data;
          setUserForm({
            first_name: user.first_name,
            last_name: user.last_name,
          });
          // props.history.push("/");
          dispatch(setProfile({ first_name, last_name, email }));
        });
    } catch (error) {}
  };

  // Form Validation for Profile update
  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!first_name) {
        const error = setError(inputError, "first_name", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "First Name field is required" });
      } else if (!validateName(first_name)) {
        const error = setError(inputError, "first_name", "Invalid Format!");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid First Name!" });
      } else if (!last_name) {
        const error = setError(inputError, "last_name", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Last Name field is required" });
      } else if (!validateName(last_name)) {
        const error = setError(inputError, "last_name", "Invalid Format!");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid Last Name!" });
      } else {
        const form = { first_name, last_name };
        resolve(form);
      }
    });
  };

  const onCancel = () => {
    setFirstName();
    setLastName();
  };

  return (
    <Fragment>
      <PanelHeader title={fName + " " + lName} backUrl={`/dashboard`} />
      <Paper>
        <Typography variant="h6">Personal Details</Typography>
        <form data-testid="profile-form" noValidate onSubmit={onFormSubmit}>
          <Grid container className="grid-wrap">
            <Grid item md={6} sm={12} xs={12} className="grid-col">
              <TextField
                label="First Name"
                id="first_name"
                type="text"
                // inputProps={{ maxLength: 36 }}
                error={inputError.first_name.hasError}
                helperText={inputError.first_name.message}
                {...bindFirstName}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="grid-col">
              <TextField
                label="Last Name"
                id="last_name"
                type="text"
                error={inputError.last_name.hasError}
                helperText={inputError.last_name.message}
                {...bindLastName}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="grid-col">
              <TextField
                label="Email"
                id="email"
                type="email"
                disabled
                value={email || ""}
              />
            </Grid>
          </Grid>
          <div className="field-wrap">
            <Button
              data-testid="profile-form-cancel-btn"
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              data-testid="profile-form-submit-btn"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isEqual(userForm, {
                first_name,
                last_name,
              })}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </Paper>
      <Paper>
        <ChangePassword />
      </Paper>
    </Fragment>
  );
};

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

/*istanbul ignore next*/
const mapDispatchToProps = dispatch => {
  return {
    updateProfileService: payload => dispatch(authServices(payload)),
    getProfileService: payload => dispatch(authServices(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Profile);

export { Container, Profile as Component };
