import { Link } from "react-router-dom";
import { Card as MuiCard } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import MuiBox from "@material-ui/core/Box";
import MuiGrid from "@material-ui/core/Grid";
import MuiTypography from "@material-ui/core/Typography";
import styled from "styled-components";

export const SyncForm = styled.form`
  .section {
    margin-bottom: 30px;
  }
`;

export const NoPluginsData = styled(NoData)`
  width: 100%;
`;

export const BoxWrap = styled(MuiBox)`
  /* min-height: 45px; */
  .version-update {
    display: flex;
    padding-right: 32px;
    margin-bottom: 10px;
    .lbl {
      width: 90px;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;
export const Box = styled(MuiBox)`
  &.MuiBox-root {
    &.add-btn {
      margin-bottom: 10px;
      .MuiButton-root {
        padding: 0;
        &:hover,
        &:focus {
          background: none;
        }
      }
    }
    &.cred-form {
      padding: 15px 0;
      .field {
        padding: 0;
        .MuiTextField-root {
          margin-right: 24px;
        }
        &:last-child {
          .MuiTextField-root {
            margin-right: 0;
          }
        }
      }
      .cred-inputs {
        display: flex;
        flex-grow: 1;
        width: 100%;
        .field {
          width: 100%;
        }
      }
      .cred-icons {
        display: flex;
        flex-grow: 1;
        .MuiIconButton-root {
          padding: 0;
          margin: 10px 0;
          width: 25px;
          height: 25px;
          &:first-child {
            margin-left: 12px;
          }
          &:last-child {
            margin-right: 0;
          }
          svg {
            width: 16px;
          }
        }
      }
    }
   }

    &.form-footer {
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.06);
      padding: 8px 24px;
      margin: 20px -24px 0;

      .MuiButton-root {
        margin-left: 20px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`;

export const MultiChipBox = styled(MuiBox)`
  &.MuiBox-root {
    &.multi-chip-form {
      padding: 15px 0;
      .field {
        flex: 100%;
        &:first-child {
          width: 100%;
          flex: 100%;        
        }
        &:last-child {
          flex: 100%;
          width: 100%;
          .MuiTextField-root {
            margin-right: 0;
          }
        } 
        }
      }
      .multi-chip-inputs {
        display: flex;
        flex-grow: 1;
        width: 90%;
        flex-direction: column;
        .field {
          width: 100%;
          flex: 50%;
        }
      .MuiAutocomplete-root {
         width: 100%;
         flex: 50%;
      }  
      }
      .multi-chip-icons {
        display: flex;
        flex-grow: 1;
        .MuiIconButton-root {
          margin: 10px 0;
          width: 25px;
          height: 25px;
          &:first-child {
            margin-left: 12px;
          }
          &:last-child {
            margin-right: 0;
          }
          svg {
            width: 16px;
          }
        }
      }
    }
   }
  }
`;

export const MultiFieldBox = styled(MuiBox)`
  &.MuiBox-root {
      &.multi-cred-form {
        padding: 15px 0;
        .field {
          padding: 0;
          .MuiTextField-root {
            margin: 10px;
          }
        }
        .multi-cred-inputs {
          display: flex;
          flex-grow: 1;
          flex-wrap: wrap;
          width: 100%;
          .checkbox-field {
            flex-grow: 1.5;
            margin: 0;
          }
          .field {
            flex-grow: 0.5;
          }
          .MuiTypography-root {
            font-size: 16px;
          }
        }
        .multi-cred-icons {
          display: flex;
          flex-grow: 1;
          .MuiIconButton-root {
            padding: 0;
            margin: 10px 0;
            width: 25px;
            height: 25px;
            svg {
              width: 16px;
            }
          }
        }
      }
    }
  }
`;

export const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    .card {
      border: 10px solid ${props => props.theme.palette.grey.grey100};
      border-radius: 4px;
      background: ${props => props.theme.palette.white.main};
      &:hover,
      &:focus {
        background: ${props => props.theme.palette.white.white200};
        .MuiCard-root {
          background: ${props => props.theme.palette.white.white200};
        }
      }
      .MuiCard-root {
        box-shadow: none;
      }
      .MuiTypography-h4 {
        /* -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
        overflow: hidden;
        text-overflow: ellipsis;
        width: 166px;
        white-space: nowrap;
        /* display: -webkit-box;
        word-break: break-word; */
      }
      .update-holder {
        align-items: flex-start;
        flex-direction: column;
        ${({ theme }) => theme.breakpoints.down("md")} {
          display: block;
        }
      }
    }
    &.feature-wrapper {
      /* margin-bottom: 15px; */
      .MuiTextField-root {
        width: 100%;
      }
      .access-input {
        text-align: right;
      }
      /* &.team-form {
        margin-top: -15px;
      } */
    }
  }
  .skeleton-loader {
    .MuiBox-root {
      width: 100%;
    }
    .version-update {
      width: 50%;
    }
  }
`;

export const Card = styled(MuiCard)`
  .deviceCard {
    padding: 21px 16px 16px;
    height: 168px;
    .MuiSwitch-root {
      margin-right: -12px;
    }
  }
`;

export const Typography = styled(MuiTypography)`
  span {
    color: rgba(0, 0, 0, 0.54);
  }
`;
export const CardLink = styled(Link)`
  text-decoration: none;
`;
