import { capitalize, slugToTitle } from "utils/functions";
import { connect } from "react-redux";
import { createRecord } from "services/createObject";
import { get } from "services/getObject";
import { useLocation } from "react-router";
import MessageForm from "app/pages/Dashboard/pushNotification/Messages/form/MessagesForm";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useEffect, useState } from "react";

const AddMessage = props => {
  const {
    location: { state },
  } = props;
  const { sport_key, env_key, uid } = props.match.params;
  const [data, setData] = useState({});
  const [push_provider, setPush_provider] = useState();

  const type = new URLSearchParams(useLocation().search).get("type");

  const isTemp = new URLSearchParams(useLocation().search).get("isTemp")
    ? true
    : false;

  const getData = async props => {
    const {
      data: { push_template },
    } = await props.getUpdateForm({
      url: `/v1/push_notification/admin/push_template/${uid}`,
    });

    setData(push_template);
  };

  const getSettings = async props => {
    const { data } = await props.getSettings({
      url: `/v1/admin/applications/${sport_key}/envs/${env_key}/settings`,
    });
    setPush_provider(data.settings.push_notification.service_name);
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (!type) {
      CancelForm();
    }
    getSettings(props);
    /* istanbul ignore else */
    if (isTemp) {
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddPushMsg = async form => {
    await props.AddPushMessages({
      url: `/v1/push_notification/admin/push`,
      key: "push_notification",
      form,
    });

    props.history.push(
      `/dashboard/${sport_key}/${env_key}/push-notification/messages`,
    );
  };

  const DraftPushMsg = async form => {
    const { data } = await props.DraftPushMessages({
      url: `/v1/push_notification/admin/push/draft`,
      key: "draft",
      form,
    });
    /* istanbul ignore else */
    if (data.draft.uid) {
      const { uid } = data.draft;
      props.history.push(
        `/dashboard/${sport_key}/${env_key}/push-notification/messages/update/${uid}?type=${type}`,
      );
    }
  };

  const CancelForm = () => {
    /* istanbul ignore else */
    if (state) {
      /* istanbul ignore next */
      props.history.push(state.from.pathname + state.from.search);
    } else if (isTemp) {
      props.history.push(
        `/dashboard/${sport_key}/${env_key}/push-notification/template`,
      );
    } else {
      props.history.push(
        `/dashboard/${sport_key}/${env_key}/push-notification/messages`,
      );
    }
  };

  return (
    <Fragment>
      <PanelHeader
        title={`Add ${capitalize(slugToTitle(type))}`}
        backUrl={`/dashboard/${sport_key}/${env_key}/push-notification/messages/completed`}
        breadCrumbItems={[
          {
            title: "Push Notification",
          },
        ]}
      />
      {isTemp ? (
        Object.keys(data).length > 0 && (
          <MessageForm
            type={type}
            push_notification={data}
            AddPushMsg={AddPushMsg}
            DraftPushMsg={DraftPushMsg}
            CancelForm={CancelForm}
            uid={uid}
            isTemp={isTemp}
            push_provider={push_provider}
            team_name={props.team_name}
          />
        )
      ) : (
        <MessageForm
          type={type}
          AddPushMsg={AddPushMsg}
          DraftPushMsg={DraftPushMsg}
          CancelForm={CancelForm}
          push_provider={push_provider}
          team_name={props.team_name}
        />
      )}
    </Fragment>
  );
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    team_name: state.application.team_name,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getUpdateForm: payload => dispatch(get(payload)),
    AddPushMessages: payload => dispatch(createRecord(payload)),
    DraftPushMessages: payload => dispatch(createRecord(payload)),
    getSettings: payload => dispatch(get(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(AddMessage);

export { Container, AddMessage as Component };
