import { Button as MuiButton, Card as MuiCard } from "@dfep/ui-component";
import MuiBox from "@material-ui/core/Box";
import MuiGrid from "@material-ui/core/Grid";
import styled from "styled-components";

export const Content = styled(MuiBox)`
  min-height: 70px;
  margin-bottom: 6px;
`;

export const ModalActionBoxWrap = styled(MuiBox)`
  .MuiButton-label {
    font-weight: 600;
  }
  display: flex;
  justify-content: space-between;
  text-align: center;
  div:last-child {
    justify-content: flex-end;
    width: 100%;
  }
`;

export const ModalActionBox = styled(MuiBox)`
  padding-bottom: 24px;
  .MuiButtonBase-root {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    .MuiInputBase-root {
      margin-bottom: 13px;
    }
  }
  .skeleton-loader {
    .timestamp {
      width: 100%;
    }
  }
`;

export const Card = styled(MuiCard)`
  .envCard {
    padding: 8px 16px 0;
    &:last-child {
      padding-bottom: 0;
    }
    .MuiIconButton-root {
      padding: 6px;
      &:hover,
      &:focus {
        background: ${props => props.theme.palette.white.main};
      }
    }
  }
`;

export const Button = styled(MuiButton)`
  margin-left: auto;
`;
