import ListItemChild from "./ListItemChild";
import ListItemWrapper from "./ListItemWrapper";
import React from "react";

const ListItem = ({
  icon,
  text,
  child,
  url,
  isOpen,
  handleClick,
  isSidebarOpen,
  handledraweropen,
  id,
}) => {
  return (
    <li onClick={handleClick} data-testid={`sidebar_content-${id}`}>
      <ListItemChild
        url={url}
        isExpandable={child?.length > 0}
        isOpen={isOpen}
        text={text}
        icon={icon}
      />
      {isOpen &&
        child?.length > 0 &&
        child?.map(({ name, child, id, url }, index) => (
          <ul
            key={id}
            style={{
              paddingLeft: index >= 0 && "21px",
            }}
          >
            <ListItemWrapper
              isSidebarOpen={isSidebarOpen}
              handledraweropen={handledraweropen}
              listItem={({
                isOpen,
                handleClick,
                isSidebarOpen,
                handledraweropen,
              }) => (
                <ListItem
                  url={url}
                  handledraweropen={handledraweropen}
                  isSidebarOpen={isSidebarOpen}
                  index={index}
                  isOpen={isOpen}
                  handleClick={handleClick}
                  text={name}
                  isExpandable={child && child.length > 0}
                  child={child}
                  id={id}
                />
              )}
            />
          </ul>
        ))}
    </li>
  );
};

export default ListItem;
