import { connect } from "react-redux";
import { createRecord } from "services/createObject";
import { getDataOptions } from "services/getObjects";
import AddSegmentView from "./indexView";
import React from "react";

const AddSegment = props => {
  const {
    location: { state },
  } = props;
  const { sport_key, env_key } = props.match.params;
  const submitFormHandler = async data => {
    let uids = [];
    data.data.forEach(object => {
      uids.push(object.uid);
    });
    const form = {
      name: data.name,
      type: data.type,
      data: uids,
    };

    const { status } = await props.addSegment({
      url: "/v1/push_notification/admin/segment",
      key: "segment_data",
      form,
    });
    /* istanbul ignore else */
    if (status === 201)
      props.history.push(
        `/dashboard/${sport_key}/${env_key}/push-notification/segment`,
      );
  };

  const cancelHandler = event => {
    props.history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/push-notification/segment`,
    );
  };

  const getOptionsData = async (value, type) => {
    switch (type) {
      case "personal_id":
        const {
          data: { users },
        } = await props.dataSearch({
          url: "/v1/app_user/admin/user",
          query: {
            personal_id: { $regex: `${value}`, $options: "i" },
          },
        });
        return users;

      case "topic":
        const {
          data: { topics },
        } = await props.dataSearch({
          url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics`,
          query: {
            $or: [
              { key: { $regex: `${value}`, $options: "i" } },
              { name: { $regex: `${value}`, $options: "i" } },
            ],
            service: "push",
          },
        });
        return topics;
      case "device_token":
        const {
          data: { installation_data },
        } = await props.dataSearch({
          url: "/v1/push_notification/admin/installation_data",
          query: {
            $or: [{ device_token: { $regex: `${value}`, $options: "i" } }],
          },
        });
        return installation_data;
      /* istanbul ignore next */
      default:
        return [];
    }
  };

  return (
    <AddSegmentView
      cancelHandler={cancelHandler}
      submitFormHandler={submitFormHandler}
      dataSearch={props.dataSearch}
      getOptionsData={getOptionsData}
      sport_key={sport_key}
      env_key={env_key}
    />
  );
};

/* istanbul ignore next */
const mapDispatchToProps = Dispatch => {
  return {
    addSegment: payload => Dispatch(createRecord(payload)),
    dataSearch: payload => Dispatch(getDataOptions(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(AddSegment);

export { Container, AddSegment as Component };
