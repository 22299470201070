import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import PropTypes from "prop-types";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "app/components/Common/Table";

export default function AssetView({
  copySnackbar,
  handleSnackbarClose,
  deleteModal,
  handleModalClose,
  deleteHandler,
  deleteFileName,
  totalCount,
  isLoading,
  headings,
  actions,
  checkbox,
  name,
  ...restProps
}) {
  return (
    <>
      {isLoading ? (
        <SkeletonTable
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
        />
      ) : totalCount > 0 ? (
        <Table
          totalCount={totalCount}
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
          {...restProps}
        />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <Snackbar
        className="copied-snackbar-popup"
        open={copySnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="URL has been copied"
      />

      <Modal
        className="small"
        open={deleteModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: deleteFileName,
          module: name,
        })}
      </Modal>
    </>
  );
}
AssetView.propTypes = {
  numSelected: PropTypes.number,
  deleteHandler: PropTypes.func,
};
