/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionWrap,
  FieldCol,
  FieldsWrap,
  IconWrap,
  Inner,
  InnerWrap,
  RowWrap,
} from "./customFieldForm.style";
import { customFieldsOptions as c_options } from "utils/constants";
import { Modal } from "@dfep/ui-component";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useModal } from "app/hooks/modal";
import { validateDisplayName, validateUid } from "utils/functions";
import AddIcon from "@material-ui/icons/Add";
import AutoComplete from "./CustomFields/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "./CustomFields/Select";
import SettingsIcon from "@material-ui/icons/Settings";
import Text from "./CustomFields/Text";

const ChildFields = props => {
  const [options, setOptions] = useState(
    props.field_metadata?.options ?? c_options,
  );
  const [hide, setHide] = useState(false);

  const { show, showDelete, openModalHandler, closeModalHandler } = useModal();

  const { getValues, register, setValue, unregister } = useFormContext();

  const handleDeleteModalOpen = () => {
    openModalHandler("showDelete");
  };

  const handleMoreFieldsModalOpen = () => {
    openModalHandler("show");
  };

  const data_type = useWatch({
    control: props?.control,
    name: `${props.name}.data_type`,
    defaultValue: props.data_type ?? "",
  });

  const deleteCustomField = () => {
    props.remove(props.index);
  };

  const appendNestedCustomField = () => {
    setHide(true);
    append({
      uid: "",
      display_name: "",
      data_type: "string",
      field_metadata: {
        additional_options: [],
        options: c_options,
      },
      _schema: [],
    });
  };

  useEffect(() => {
    register(`${props.name}.mandatory`);
    register(`${props.name}.multiple`);
    register(`${props.name}.min`);
    register(`${props.name}.max`);
    register(`${props.name}.format`);
    register(`${props.name}.field_metadata.multiline`);
    register(`${props.name}.field_metadata.options`);
  }, []);

  useEffect(() => {
    if (data_type === "group") {
      setOptions([...c_options]);
      setValue(`${props.name}.field_metadata.options`, c_options);
      setHide(true);
      if (getValues(`${props.name}._schema`)?.length === 0) {
        appendNestedCustomField();
      }
    } else {
      setHide(true);
      if (getValues(`${props.name}._schema`)?.length > 0) {
        unregister([`${props.name}._schema`]);
      }
    }
    if (data_type === "file") {
      setHide(false);
      setOptions(c_options);
      setValue(`${props.name}.field_metadata.options`, c_options);
    } else if (data_type === "string") {
      setHide(false);
      setValue(`${props.name}.field_metadata.options`, [
        ...c_options,
        { label: "Multiline", value: "multiline" },
      ]);
      setOptions([...c_options, { label: "Multiline", value: "multiline" }]);
    } else if (data_type === "boolean") {
      setHide(false);
      setOptions([]);
      setValue(`${props.name}.field_metadata.options`, []);
      unregister(`${props.name}.field_metadata.additional_options`);
      setValue(`${props.name}.mandatory`, false);
      setValue(`${props.name}.multiple`, false);
      setValue(`${props.name}.field_metadata.multiple`, false);
    } else if (data_type === "date") {
      setHide(false);
      setOptions(c_options);
      setValue(`${props.name}.field_metadata.options`, c_options);
    } else if (data_type === "number") {
      setHide(false);
      setOptions(c_options);
      setValue(`${props.name}.field_metadata.options`, c_options);
    } else if (data_type === "mixed") {
      setHide(false);
      setOptions([{ label: "Mandatory", value: "mandatory" }]);
      setValue(`${props.name}.field_metadata.options`, [
        { label: "Mandatory", value: "mandatory" },
      ]);
    } else if (data_type === "rte") {
      setHide(false);
      setOptions(c_options);
      setValue(`${props.name}.field_metadata.options`, c_options);
    }
  }, [data_type]);

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: `${props.name}._schema`, // _schema[1]._schema
  });

  return (
    <>
      <FieldsWrap data-testid={`childfield-${props.name}`}>
        <InnerWrap>
          <RowWrap>
            <Inner>
              {getValues(`${props.name}.data_type`) === "group" && (
                <span className="arrow">
                  {hide ? (
                    <ExpandLessIcon
                      onClick={() => setHide(false)}
                      data-testid="expandless"
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={() => setHide(true)}
                      data-testid="expandmore"
                    />
                  )}
                </span>
              )}
              <FieldCol>
                <Text
                  name={`${props.name}.uid`}
                  display_name="Field"
                  data_type="text"
                  validations={{
                    required: "Field is Required",
                    validate: {
                      pattern: val => validateUid(val) || "Invalid UID",
                    },
                  }}
                  defaultValue={getValues(`${props.name}.uid`)}
                />
              </FieldCol>
              <FieldCol>
                <Text
                  name={`${props.name}.display_name`}
                  display_name="Display Name"
                  data_type="text"
                  validations={{
                    required: "Display Name is Required",
                    validate: {
                      pattern: val =>
                        validateDisplayName(val) || "Invalid Display Name",
                    },
                  }}
                  defaultValue={getValues(`${props.name}.display_name`)}
                />
              </FieldCol>
              <FieldCol>
                <Select
                  name={`${props.name}.data_type`}
                  display_name="Type"
                  validations={{
                    required: "Data Type is Required!",
                  }}
                  parentName={props.name}
                  defaultValue={getValues(`${props.name}.data_type`)}
                  field_metadata={{
                    options: [
                      { label: "Group", value: "group" },
                      { label: "File", value: "file" },
                      { label: "Text", value: "string" },
                      { label: "Boolean", value: "boolean" },
                      { label: "Date", value: "date" },
                      { label: "Number", value: "number" },
                      { label: "Mixed", value: "mixed" },
                      { label: "RTE", value: "rte" },
                    ],
                  }}
                />
              </FieldCol>

              {getValues(`${props.name}.data_type`) !== "boolean" && (
                <FieldCol className="additional-options-field">
                  <AutoComplete
                    parentName={props.name}
                    name={`${props.name}.field_metadata.additional_options`}
                    display_name="Additional Options"
                    data_type="text"
                    options={options}
                    validations={{}}
                    defaultValue={getValues(
                      `${props.name}.field_metadata.additional_options`,
                    )}
                  />
                </FieldCol>
              )}
              <FieldCol>
                <ActionWrap>
                  <span className="rmv-icon">
                    <DeleteIcon
                      onClick={handleDeleteModalOpen}
                      data-testid="delete-btn"
                    />
                  </span>
                  {(getValues(`${props.name}.data_type`) === "string" ||
                    getValues(`${props.name}.data_type`) === "number") && (
                    <span className="icon icon-settings">
                      <SettingsIcon
                        onClick={handleMoreFieldsModalOpen}
                        data-testid="more-settings-btn"
                      />
                    </span>
                  )}
                </ActionWrap>
              </FieldCol>
            </Inner>
            {/**
             * Group init
             */}
            {hide &&
              fields?.map((data, index) => (
                <ChildFields
                  {...data}
                  name={`${props.name}._schema[${index}]`}
                  index={index}
                  key={data.id}
                  control={props.control}
                  remove={remove}
                />
              ))}
          </RowWrap>
          {getValues(`${props.name}.data_type`) === "group" && hide && (
            <IconWrap>
              <span className="icon-path">
                <AddIcon
                  onClick={appendNestedCustomField}
                  data-testid="append-nested"
                />
              </span>
            </IconWrap>
          )}
        </InnerWrap>
      </FieldsWrap>
      {/* Delete Field Modal */}
      <Modal
        data-testid="delete-modal"
        open={showDelete}
        handleClose={closeModalHandler}
        title="Delete Field"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: closeModalHandler },
          {
            id: "2",
            name: "Delete",
            handler: deleteCustomField,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "remove-field",
            },
          },
        ]}
      >
        Are you sure you want to delete this Field ?
      </Modal>
      {/* More Settings Modal */}
      <Modal
        data-testid="more-settings"
        open={show}
        handleClose={closeModalHandler}
        title="More Fields"
        actions={[
          { id: "1", name: "Cancel", handler: closeModalHandler },
          {
            id: "2",
            name: "Save",
            handler: closeModalHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {getValues(`${props.name}.data_type`) === "string" && (
          <Text
            name={`${props.name}.format`}
            display_name="Format"
            data_type="text"
            defaultValue={getValues(`${props.name}.format`)}
            validations={{ required: false }}
          />
        )}
        <Text
          name={`${props.name}.min`}
          display_name="Min"
          data_type="number"
          defaultValue={getValues(`${props.name}.min`)}
          validations={{ required: false }}
        />
        <Text
          name={`${props.name}.max`}
          display_name="Max"
          data_type="number"
          defaultValue={getValues(`${props.name}.max`)}
          validations={{ required: false }}
        />
        {/* <CheckBox
          key={props.id + props.max}
          name={`${props.name}.display`}
          defaultValue={props.field_metadata?.display ?? false}
        /> */}
      </Modal>
    </>
  );
};

export default ChildFields;
