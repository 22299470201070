import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { useModal } from "app/hooks/modal";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import React, { Fragment, useEffect, useState } from "react";
import TableView from "./teamsView";

const Teams = props => {
  const [selected, setSelected] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [uid, setUid] = useState();
  const { getTeamsData, teamsData, isLoading, teamsCount, access_type } = props;
  const { sport_key, env_key } = props.match.params;
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  const headings = [
    { label: "Team Name", id: "tn" },
    { label: "Team ID", id: "tid" },
    { label: "League ID", id: "league_id" },
    { label: "Year", id: "year" },
    { label: "Season ID", id: "season_id" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  useEffect(() => {
    setSelected([]);
  }, [getTeamsData]);

  const UpdateHandler = (event, uid, data) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/team/teams/update/${uid}`,
      state: { from: props.location },
    });
  };

  const DeleteHandler = (event, uid, data) => {
    openModalHandler("showDelete");
    setDeleteData(data);
    setUid(uid);
  };

  const modalHandleClose = event => {
    closeModalHandler();
    setUid();
    setDeleteData({});
  };

  const handleModalDelete = async event => {
    await props.deleteTeams({ url: `/v1/nba/admin/data/teams/${uid}` });
    setSelected([]);
    getTeamsData();
    modalHandleClose();
    setUid();
    setDeleteData({});
  };

  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: teamsData });
    setSelected(newSelecteds);
  };

  const handleMultiDelete = async event => {
    await props.deleteMultipleTeams({
      url: "/v1/nba/admin/data/teams",
      uids: selected,
    });
    setSelected([]);
    getTeamsData();
    modalHandleClose();
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />,
      handler: DeleteHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <Fragment>
      <TableView
        name="Teams"
        data={teamsData}
        isLoading={isLoading}
        actions={actions}
        totalCount={teamsCount}
        handleModalClose={modalHandleClose}
        handleModalDelete={handleModalDelete}
        multipleDeleteHandler={deleteMultipleHandler}
        handleSelectAllClick={handleSelectAllClick}
        handleSingleClick={handleSingleClick}
        openModal={showDelete}
        handleMultiDelete={handleMultiDelete}
        multipleModal={showMultipleDelete}
        isSorting
        headings={headings}
        checkbox={true}
        actionMenu={false}
        selectedList={selected}
        numSelected={selected.length}
        openSync={props.openSync}
        closeSync={props.closeSync}
        sendSync={props.sendSync}
        deleteData={deleteData}
      />
    </Fragment>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    deleteTeams: payload => dispatch(deleteRecord(payload)),
    deleteMultipleTeams: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Teams);

export { Container, Teams as Component };
