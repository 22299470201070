import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const StartingLineupView = props => {
  const {
    deleteModal,
    handleModalClose,
    deleteSingleData,
    isLoading,
    headings,
    checkbox,
    name,
    lineupObj,
    actions,
    totalCount,
    openSyncModal,
    syncModalClose,
    syncDetails,
    numSelected,
    showMultipleDelete,
    deleteMultipleData,
  } = props;
  return (
    <>
      {isLoading ? (
        <SkeletonTable
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
        />
      ) : totalCount > 0 ? (
        <Table {...props} />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <Modal
        className="small"
        open={deleteModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "delete-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteSingleData,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "single-delete",
            },
          },
        ]}
      >
        {lineupObj &&
          deleteConfirmationMessage({
            name: lineupObj?.gid,
            module: name,
          })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "multidel-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultipleData,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "multi-delete",
            },
          },
        ]}
      >
        Are you sure you want to delete <strong>{numSelected}</strong> items?
      </Modal>
      <Modal
        className="small"
        open={openSyncModal}
        handleClose={syncModalClose}
        title="Sync"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: syncModalClose,
            additionalAttributes: {
              "data-testid": "sync-cancel",
            },
          },
          {
            id: "2",
            name: "Start",
            handler: syncDetails,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-sync",
            },
          },
        ]}
      >
        Are you sure you want to start Starting Lineup sync?
      </Modal>
    </>
  );
};

export default StartingLineupView;
