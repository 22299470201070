import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdatePlayerAvg = props => {
  const [playerAvgData, setPlayerAvgData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getPlayerAvgUrl = `/dashboard/${sport_key}/${env_key}/data/rosters/playerAverage`;

  useEffect(() => {
    getPlayersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPlayersData = useCallback(async () => {
    const url = `/v1/nba/admin/data/player_average/${uid}`;
    const {
      data: { player_average },
    } = await dispatch(get({ url }));
    setPlayerAvgData(player_average);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/data/player_average/${uid}`,
          key: "player_average",
        }),
      );
      props.history.push(getPlayerAvgUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Player Average"
        breadCrumbItems={[
          {
            title: "Update Player Average",
          },
        ]}
        backUrl={getPlayerAvgUrl}
      />
      {playerAvgData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={playerAvgData}
          schema={schema}
          modelName="player_averages"
          backUrl={getPlayerAvgUrl}
        />
      )}
    </>
  );
};

export default UpdatePlayerAvg;
