/* istanbul ignore file */
import { handleError } from "../helper";
import { httpRequest } from "../index";
import { showLoader } from "store/reducers/loaderSlice";
import { showNotifier } from "store/reducers/notifierSlice";

export const uploadObject = ({
  url,
  formData,
  showToast = true,
  loader = true,
}) => (dispatch, getState) => {
  const { env_key, sport_key } = getState().application;
  const httpObject = {
    url,
    method: "post",
    data: formData,
    headers: {
      sport_key,
      env_key,
    },
  };
  return new Promise(function (resolve, reject) {
    if (loader) {
      dispatch(showLoader({ show: true }));
    }
    httpRequest(httpObject)
      .then(response => {
        if (loader) {
          dispatch(showLoader({ show: false }));
        }
        if (showToast) {
          dispatch(
            showNotifier({
              open: true,
              message: response.data.notice,
              variant: "success",
            }),
          );
        }
        resolve(response);
      })
      .catch(error => handleError(error, dispatch));
  });
};
