import { deleteConfirmationMessage } from "utils/functions";
import { Fragment } from "react";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const TableView = props => {
  const {
    isLoading,
    openModal,
    handleModalClose,
    handleModalDelete,
    multiDeleteReq,
    refresh,
    deleteDataObj,
    ...restProps
  } = props;

  return (
    <Fragment>
      {isLoading || refresh ? (
        <SkeletonTable
          headings={props.headings}
          name={props.topicName}
          actions={props.actions}
        />
      ) : null}

      {props.data.length > 0 && !refresh && !isLoading ? (
        <Table {...restProps} isSorting />
      ) : (
        !isLoading && !refresh && <NoData>No Data Found</NoData>
      )}

      <Modal
        className="small"
        open={openModal.delModal}
        handleClose={handleModalClose}
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: handleModalDelete,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: deleteDataObj.title,
          module: restProps.name,
        })}
      </Modal>
      <Modal
        className="small"
        open={openModal.multiDelModal}
        handleClose={handleModalClose}
        data-testid="delete-modal"
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: multiDeleteReq,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {props.numSelected > 0 && (
          <>
            Are you sure you want to delete <strong>{props.numSelected}</strong>{" "}
            items?
          </>
        )}
      </Modal>
    </Fragment>
  );
};

export default TableView;
