import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";

const ActionMenu = ({
  anchorEl,
  menuHandler,
  handleMenuClose,
  openMenu,
  actions,
  dataItem,
  showActionIcon,
}) => {
  if (showActionIcon()) {
    return (
      <div data-testid={`action_menu_click_${dataItem.name}`}>
        <IconButton aria-label="expand row" size="small" onClick={menuHandler}>
          <MoreVertIcon />
        </IconButton>
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleMenuClose}>
          {actions &&
            actions.map(
              action =>
                (action.hasOwnProperty("showAction")
                  ? action.showAction(dataItem)
                  : true) && (
                  <MenuItem
                    key={action.id}
                    data-testid="unique"
                    onClick={event =>
                      action.handler(event, dataItem.uid, dataItem)
                    }
                  >
                    <ListItemIcon onClick={handleMenuClose}>
                      {action.icon}
                      <Typography className="menu-data" variant="inherit">
                        {action.label}
                      </Typography>
                    </ListItemIcon>
                  </MenuItem>
                ),
            )}
        </Menu>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ActionMenu;
