import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { deleteRecord } from "services/deleteObject";
import { useModal } from "app/hooks/modal";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import StartingLineupView from "./GetStartingLineupView";

const headings = [
  { label: "Game ID", id: "gid" },
  { label: "League ID", id: "league_id" },
  { label: "Season ID", id: "season_id" },
  { label: "Year", id: "year" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const StartingLineupData = props => {
  const {
    dispatch,
    getStartingLineups,
    data,
    setData,
    totalDataCount,
    isLoading,
    openSyncModal,
    syncModalClose,
    syncDetails,
    access_type,
    READ_ONLY,
    history,
  } = props;

  const { team_name, sport_key, env_key } = props.match.params;
  const [dataUid, setUid] = useState(null);
  const [lineupObj, setLineupObj] = useState(null);
  const [selected, setSelected] = useState([]);

  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  useEffect(() => {
    getStartingLineups();
    return () => {
      setSelected([]);
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStartingLineups, team_name]);

  // Delete Single Api call
  const deleteSingleData = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/line_up/${dataUid}`,
      }),
    );
    handleModalClose();
    setSelected([]);
    getStartingLineups();
  };
  // Delete Multiple Api call
  const deleteMultipleData = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/line_up`,
        key: "uids",
        form: selected,
      }),
    );
    handleModalClose();
    setSelected([]);
    getStartingLineups();
  };
  const handleModalClose = event => {
    closeModalHandler();
    setUid(null);
  };

  const multipleDeleteHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };
  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  const UpdateHandler = (event, uid) => {
    history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/game/startingLineup/update/${uid}`,
      state: { from: props.location },
    });
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-icon" />,
      // handler: () => props.history.push(),
      handler: UpdateHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-icon" />,
      handler: (event, uid, dataItem) => {
        setLineupObj(dataItem);
        setUid(uid);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== READ_ONLY,
    },
  ];
  return (
    <>
      <StartingLineupView
        name="Starting Lineup"
        data={data}
        headings={headings}
        checkbox={access_type !== READ_ONLY}
        actions={actions}
        handleSelectAllClick={handleSelectAllClick}
        handleSingleClick={handleSingleClick}
        selectedList={selected}
        numSelected={selected.length}
        lineupObj={lineupObj}
        isSorting
        totalCount={totalDataCount}
        //delete
        deleteModal={showDelete}
        handleModalClose={handleModalClose}
        deleteSingleData={deleteSingleData}
        showMultipleDelete={showMultipleDelete}
        multipleDeleteHandler={multipleDeleteHandler}
        deleteMultipleData={deleteMultipleData}
        //sync
        openSyncModal={openSyncModal}
        syncModalClose={syncModalClose}
        syncDetails={syncDetails}
        isLoading={isLoading}
      />
    </>
  );
};

export default StartingLineupData;
