import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import schema from "../schema";
import SchemaForm from "../schemaForm";

const AddWscFeeds = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/wsc`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/wsc_feeds",
          key: "wsc_feeds",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add WSC Feeds"
        breadCrumbItems={[
          {
            title: "Add WSC Feeds",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm onSubmit={onSubmit} schema={schema} backUrl={getURL} />
    </>
  );
};

export default AddWscFeeds;
