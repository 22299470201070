const schema = {
  class: {
    title: "Circle",
    uid: "circle",
    _schema: [
      {
        display_name: "Name",
        uid: "name",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Push Notification Tag",
        uid: "push_notification_tag",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Radius (in meters)",
        uid: "radius",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Center",
        uid: "center",
        mandatory: true,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Longitude",
            uid: "lng",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Latitude",
            uid: "lat",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Priority",
        uid: "priority",
        mandatory: false,
        multiple: false,
        min: 0,
        data_type: "number",
        field_metadata: {},
      },
    ],
  },
};

export default schema;
