const DISPLAY = [
  { label: "Once", value: "once" },
  { label: "Every", value: "every" },
];

const schema = {
  class: {
    title: "Onboarding screens",
    uid: "screens",
    _schema: [
      {
        display_name: "Title",
        uid: "title",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Background Image",
        uid: "bg_image",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Background Color",
        uid: "bg_color",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Display",
        uid: "display",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...DISPLAY],
        },
      },
      {
        display_name: "Content",
        uid: "content",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Buttons",
        uid: "buttons",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Title",
            uid: "title",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Action",
            uid: "action",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Position",
            uid: "position",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Internal Link Url",
            uid: "internal_link_url",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Custom Fields",
            uid: "custom_fields",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
        ],
      },
      {
        display_name: "Alerts",
        uid: "alerts",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Title",
            uid: "title",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Content",
            uid: "content",
            mandatory: false,
            multiple: false,
            data_type: "rte",
            field_metadata: {},
          },
          {
            display_name: "Position",
            uid: "position",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Permission Type",
            uid: "permission_type",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Buttons",
            uid: "buttons",
            mandatory: false,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Title",
                uid: "title",
                mandatory: true,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Action",
                uid: "action",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Position",
                uid: "position",
                mandatory: true,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Custom Fields",
                uid: "custom_fields",
                mandatory: false,
                multiple: false,
                data_type: "mixed",
                field_metadata: {},
                _schema: [],
              },
            ],
          },
        ],
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
