const schema = {
  class: {
    title: "Onboarding Core",
    uid: "onboarding_core",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Screens",
        uid: "screens",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Position",
            uid: "position",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Screen",
            uid: "screen",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {
              select: true,
              options: [{ label: "Select", value: "" }],
            },
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};
export default schema;
