import { Controller } from "react-hook-form";
import { MenuItem } from "@material-ui/core";
import get from "lodash/get";
import has from "lodash/has";
import React from "react";
import TextField from "../TextField";

export default function SelectField({
  uid,
  title,
  control,
  fieldName,
  validations,
  className,
  readOnly,
  options,
  watchOn,
}) {
  return (
    <Controller
      key={uid}
      name={fieldName}
      control={control}
      rules={validations}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, fieldName);
        const fieldError = get(errors, fieldName);
        return (
          <>
            <TextField
              label={title}
              variant="outlined"
              error={hasFieldError}
              select
              SelectProps={{
                readOnly: readOnly,
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              className={className}
              helperText={hasFieldError && fieldError.message}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              inputRef={field.ref}
              InputLabelProps={{
                required: !!validations?.required,
              }}
              inputProps={{
                "data-testid": uid,
              }}
              FormHelperTextProps={{
                "data-testid": `${uid}-error`,
              }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        );
      }}
    />
  );
}
