import { Panel } from "./index.style";
import { useSelector } from "react-redux";
import React from "react";

export default function PanelFooter({ children, ...restProps }) {
  const { open } = useSelector(state => state.sidebar);
  return (
    <Panel
      {...restProps}
      className={
        open ? "panelShift panelShiftMini" : "panelShift panelShiftFull"
      }
    >
      {children}
    </Panel>
  );
}

PanelFooter.propTypes = {};
