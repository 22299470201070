import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Starting Lineup",
    uid: "startingLineup",
    _schema: [
      {
        uid: "gid",
        display_name: "Game ID",
        data_type: "string",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        uid: "officials",
        display_name: "Officials",
        data_type: "group",
        field_metadata: {
          additional_options: ["Multiple"],
          options: [],
          multiline: false,
        },
        mandatory: false,
        multiple: true,
        _schema: [
          {
            uid: "personId",
            display_name: "Person ID",
            data_type: "string",
            field_metadata: {},
            mandatory: true,
            _schema: [],
          },
          {
            uid: "firstName",
            display_name: "First Name",
            data_type: "string",
            field_metadata: {},
            _schema: [],
          },
          {
            uid: "lastName",
            display_name: "Last Name",
            data_type: "string",
            field_metadata: {},
            _schema: [],
          },
          {
            uid: "jerseyNumber",
            display_name: "Jersey Number",
            data_type: "string",
            field_metadata: {},
            mandatory: false,
            multiple: false,
            _schema: [],
          },
        ],
      },
      {
        uid: "home",
        display_name: "Home",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            uid: "teamId",
            display_name: "Team ID",
            data_type: "string",
            field_metadata: {},
            mandatory: true,
            _schema: [],
          },
          {
            uid: "staff",
            display_name: "Staff",
            data_type: "group",
            field_metadata: {
              additional_options: ["Multiple"],
              options: [],
              multiline: false,
            },
            mandatory: false,
            multiple: true,
            _schema: [
              {
                uid: "personId",
                display_name: "Person ID",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
              {
                uid: "firstName",
                display_name: "First Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "lastName",
                display_name: "Last Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
            ],
          },
          {
            uid: "players",
            display_name: "Players",
            data_type: "group",
            field_metadata: {
              additional_options: ["Multiple"],
              options: [],
              multiline: false,
            },
            mandatory: false,
            multiple: true,
            _schema: [
              {
                uid: "personId",
                display_name: "Person ID",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
              {
                uid: "firstName",
                display_name: "First Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "lastName",
                display_name: "Last Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "jerseyNumber",
                display_name: "Jersey Number",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "status",
                display_name: "Status",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
              {
                uid: "statusText",
                display_name: "Status Text",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "playerCode",
                display_name: "Player Code",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
            ],
          },
        ],
      },
      {
        uid: "visitor",
        display_name: "Visitor",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            uid: "teamId",
            display_name: "Team ID",
            data_type: "string",
            field_metadata: {},
            mandatory: true,
            _schema: [],
          },
          {
            uid: "staff",
            display_name: "Staff",
            data_type: "group",
            field_metadata: {
              additional_options: ["Multiple"],
              options: [],
              multiline: false,
            },
            mandatory: false,
            multiple: true,
            _schema: [
              {
                uid: "personId",
                display_name: "Person ID",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
              {
                uid: "firstName",
                display_name: "First Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "lastName",
                display_name: "Last Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
            ],
          },
          {
            uid: "players",
            display_name: "Players",
            data_type: "group",
            field_metadata: {
              additional_options: ["Multiple"],
              options: [],
              multiline: false,
            },
            mandatory: false,
            multiple: true,
            _schema: [
              {
                uid: "personId",
                display_name: "Person ID",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
              {
                uid: "firstName",
                display_name: "First Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "lastName",
                display_name: "Last Name",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "jerseyNumber",
                display_name: "Jersey Number",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "status",
                display_name: "Status",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
              {
                uid: "statusText",
                display_name: "Status Text",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "playerCode",
                display_name: "Player Code",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
            ],
          },
        ],
      },
      {
        uid: "lineups",
        display_name: "Lineups",
        data_type: "group",
        field_metadata: {
          additional_options: ["Multiple"],
          options: [],
          multiline: false,
        },
        mandatory: false,
        multiple: true,
        _schema: [
          {
            uid: "period",
            display_name: "Period",
            data_type: "number",
            field_metadata: {},
            mandatory: true,
            multiple: false,
            _schema: [],
          },
          {
            uid: "home",
            display_name: "Home",
            data_type: "group",
            field_metadata: {
              additional_options: ["Multiple"],
              options: [],
              multiline: false,
            },
            mandatory: false,
            multiple: true,
            _schema: [
              {
                uid: "position",
                display_name: "Position",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "personId",
                display_name: "Person Id",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
            ],
          },
          {
            uid: "visitor",
            display_name: "Visitor",
            data_type: "group",
            field_metadata: {
              additional_options: ["Multiple"],
              options: [],
              multiline: false,
            },
            mandatory: false,
            multiple: true,
            _schema: [
              {
                uid: "position",
                display_name: "Position",
                data_type: "string",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "personId",
                display_name: "Person Id",
                data_type: "string",
                field_metadata: {},
                mandatory: true,
                _schema: [],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default schema;
