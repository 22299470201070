const DISPLAY = [
  { label: "Enabled", value: "enabled" },
  { label: "Disabled", value: "disabled" },
  { label: "Hide", value: "hide" },
];

const ITEM_TYPE = [
  { label: "Toggle", value: "toggle" },
  { label: "Action", value: "action" },
];

const schema = {
  class: {
    title: "Device Settings",
    uid: "settings",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Title",
        uid: "title",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Menus",
        uid: "menus",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Name",
            uid: "name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Key",
            uid: "key",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Items",
            uid: "items",
            mandatory: false,
            multiple: true,
            data_type: "group",
            _schema: [
              {
                display_name: "Name",
                uid: "name",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Key",
                uid: "key",
                mandatory: true,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Item Type",
                uid: "item_type",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {
                  select: true,
                  options: [{ label: "None", value: "" }, ...ITEM_TYPE],
                },
              },
              {
                display_name: "Link Url",
                uid: "link_url",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Content",
                uid: "content",
                mandatory: false,
                multiple: false,
                data_type: "rte",
                field_metadata: {},
              },
              {
                display_name: "Position",
                uid: "position",
                mandatory: true,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Display",
                uid: "display",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {
                  select: true,
                  options: [{ label: "Select", value: "" }, ...DISPLAY],
                },
              },
              {
                display_name: "Custom Fields",
                uid: "custom_fields",
                mandatory: false,
                multiple: false,
                data_type: "mixed",
                field_metadata: {},
                _schema: [],
              },
            ],
            field_metadata: {},
          },
          {
            display_name: "Position",
            uid: "position",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Custom Fields",
            uid: "custom_fields",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};
export default schema;
