import { Button, Modal as MuiModal } from "@dfep/ui-component";
import MuiTypography from "@material-ui/core/Typography";
import styled from "styled-components";

export const UploadedAssetsModal = styled(MuiModal)`
  .MuiFormLabel-root {
    font-size: 14px;
  }
  .MuiTextField-root {
    width: 300px;
  }
  .inner-content {
    margin: 0 3px;
  }
  .MuiInputBase-input {
    padding: 13px 9px;
    height: auto;
  }
  .MuiDialogContent-root {
    overflow-y: auto;
    padding-top: 0;
  }
  .MuiCardMedia-img {
    object-fit: contain;
    margin: 8px 0;
  }
  .MuiIconButton-root:hover {
    background: none;
    svg {
      color: ${props => props.theme.palette.grey.grey9E9E9E};
      &:hover {
        color: ${props => props.theme.palette.black.blackDark};
      }
    }
  }
  .MuiCard-root {
    background: ${props => props.theme.palette.white.main};
    border: 3px solid transparent;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .MuiCardContent-root {
    border-top: 1px solid ${props => props.theme.palette.grey.greyF1F1F1};
    p {
      font-size: 14px;
      color: ${props => props.theme.palette.grey.grey222222};
      text-transform: capitalize;
    }
  }
  .active {
    border: 3px solid ${props => props.theme.palette.blue.blue08C};
  }
`;

export const ModalSubheading = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.palette.white.main};
  padding: 10px 0 30px;
  display: flex;
  align-items: center;
  .field {
    padding: 0;
  }
`;

export const StyledUploadButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    &.choose-btn {
      max-height: 100%;
      padding: 0.53rem;
    }
  }
`;

export const ModalMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const ModalMessage = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.black.blackLighter};
  }
`;
