import "./suneditor.min.css";
import { customButtonList } from "./RTEOptions";
import { httpRequest } from "services";
import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";
import SunEditor from "suneditor-react";

export const SunEditorWrap = styled.div`
  .sun-editor-editable {
    .__se__float-left {
      float: left !important;
    }
    .__se__float-right {
      float: right !important;
    }
    .__se__float-none {
      float: none !important;
    }
    p {
      ${({ theme }) => theme.breakpoints.down("md")} {
        max-width: 747px;
      }
    }
  }
`;

const RTEditor = ({ onChange, onBlur, value, name, ref }) => {
  const { sport_key, env_key } = useSelector(state => state.application);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData();
    formData.append("asset[upload]", files[0]);

    const httpObject = {
      url: `v1/admin/applications/${sport_key}/assets`,
      method: "post",
      data: formData,
      headers: {
        sport_key,
        env_key,
      },
    };
    httpRequest(httpObject)
      .then(responseData => {
        const { data } = responseData;
        const response = {
          result: [
            {
              url: data.asset.url,
              name: data.asset.filename,
              size: data.asset.filesize,
            },
          ],
        };
        uploadHandler(response);
      })
      .catch(error => {
        /* const errorResponse = {
          errorMessage: "Insert Image Error",
          result: [
            {
              url: "",
              name: "",
              size: "",
            },
          ],
        }; */
        try {
          uploadHandler("Image Upload Failed");
        } catch (error) {
          /*istanbul ignore next*/
          // console.log(error);
        }
      });
  };

  return (
    <SunEditorWrap id="suneditor" className="suneditor">
      <SunEditor
        name={name}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
        // ref={ref}
        setDefaultStyle="font-weight:400;"
        onImageUploadBefore={handleImageUploadBefore}
        setOptions={{
          maxHeight: "400px",
          minHeight: "250px",
          maxWidth: "1113px",
          buttonList: customButtonList,
        }}
      />
    </SunEditorWrap>
  );
};

export default RTEditor;
