import { FormWrapper } from "styles/global.style";
import React from "react";
import TemplateMapper from "app/components/Common/CustomFieldForm/CustomFieldMapper";

const Settings = () => {
  return (
    <FormWrapper data-testid="form-wrapper" className="settings-wrap">
      <TemplateMapper
        modelName="app_settings"
        title="App Setting Template"
        uid="app_setting_template"
      />
    </FormWrapper>
  );
};

export default Settings;
