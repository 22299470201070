import { StyledBreadcrumbs, StyledLink } from "./Bredcrumb.style";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
const Breadcrumb = ({ breadCrumbItems, name }) => {
  const { nbaEnable } = useSelector(state => state.application);
  const { sport_key, env_key } = useParams({});

  let commonItems = [
    {
      title: name,
      link: `/dashboard/application/${sport_key}/envs`,
    },
    {
      title: env_key,
      link: `/dashboard/${sport_key}/${env_key}${
        !nbaEnable ? "/customField" : "/data/device/config"
      }`,
    },
  ];
  if (breadCrumbItems && breadCrumbItems.length) {
    commonItems = [...commonItems, ...breadCrumbItems];
  }
  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      {commonItems &&
        commonItems.map((crumb, index) => {
          if (index === commonItems.length - 1) {
            return <p key={index}>{crumb.title}</p>;
          }
          return (
            <StyledLink key={index} to={crumb.link}>
              {crumb.title}
            </StyledLink>
          );
        })}
    </StyledBreadcrumbs>
  );
};
Breadcrumb.propTypes = {
  breadCrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
};
export default Breadcrumb;
Breadcrumb.displayName = Breadcrumb;
