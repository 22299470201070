const schema = {
  class: {
    title: "Orders",
    uid: "order_history",
    _schema: [
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "order_id",
        display_name: "Order Id",
        data_type: "string",
        field_metadata: {},
        min: 0,
      },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "user_id",
        display_name: "User Id",
        data_type: "string",
        field_metadata: {},
      },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "transaction_id",
        display_name: "Transaction Id",
        data_type: "string",
        field_metadata: {},
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [],
        uid: "order_date",
        display_name: "Order Date",
        data_type: "date",
        field_metadata: {},
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [],
        uid: "concession_stand_name",
        display_name: "Concession Stand Name",
        data_type: "string",
        field_metadata: {},
      },
      {
        uid: "total_amt",
        display_name: "Total Amount",
        data_type: "number",
        field_metadata: {},
        mandatory: false,
        _schema: [],
      },
      {
        uid: "item_cost",
        display_name: "Item Cost",
        data_type: "number",
        field_metadata: {},
        mandatory: false,
        _schema: [],
      },
      {
        uid: "tax_amt",
        display_name: "Tax Amount",
        data_type: "number",
        field_metadata: {},
        mandatory: false,
        _schema: [],
      },
      {
        uid: "paid_with",
        display_name: "Paid With",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        _schema: [],
      },
      {
        uid: "items",
        display_name: "Items",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: true,
        _schema: [
          {
            uid: "item_name",
            display_name: "Item Name",
            data_type: "string",
            field_metadata: {},
            mandatory: false,
            _schema: [],
          },
          {
            uid: "qty",
            display_name: "Quantity",
            data_type: "number",
            field_metadata: {},
            mandatory: false,
            _schema: [],
          },
          {
            uid: "item_cost",
            display_name: "Item Cost",
            data_type: "number",
            field_metadata: {},
            mandatory: false,
            _schema: [],
          },
          {
            uid: "tax_amt",
            display_name: "Tax Amount",
            data_type: "number",
            field_metadata: {},
            mandatory: false,
            _schema: [],
          },
          {
            uid: "modifiers",
            display_name: "Modifiers",
            data_type: "group",
            field_metadata: {},
            mandatory: false,
            multiple: false,
            _schema: [
              {
                uid: "name",
                display_name: "Name",
                data_type: "string",
                field_metadata: {},
                mandatory: false,
                _schema: [],
              },
              {
                uid: "cost",
                display_name: "Cost",
                data_type: "number",
                field_metadata: {},
                _schema: [],
              },
              {
                uid: "tax_amt",
                display_name: "Tax Amount",
                data_type: "number",
                field_metadata: {},
                mandatory: false,
                _schema: [],
              },
            ],
          },
        ],
      },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "order_status",
        display_name: "Order Status",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
      },
      {
        uid: "custom_1",
        display_name: "Custom 1",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        _schema: [],
      },
      {
        uid: "custom_2",
        display_name: "Custom 2",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        _schema: [],
      },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "provider",
        display_name: "Provider",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
      },
    ],
  },
};

export default schema;
