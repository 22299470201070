import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { connect, useDispatch, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { getEvents } from "services/getObjects";
import { Container as GetEvents } from "./GetEvents";
import { initialRoute } from "utils/functions";
import { Link, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import Settings from "./Settings";

function Events(props) {
  let tabObj = { events: 0, settings: 1 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [eventsCount, setEventsCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const [isLoading, setIsLoading] = useState(true);
  const { show, closeModalHandler, openModalHandler } = useModal();
  const dispatch = useDispatch();
  const { sort, value, skip, limit, searchText } = useQueryParams();
  const { sport_key, env_key } = props.match.params;
  const { access_type } = useSelector(state => state.application);

  const syncModalClose = () => {
    closeModalHandler();
  };

  const syncEvents = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/events" }));
    closeModalHandler();
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    const {
      data: { count, events },
    } = await props.getEvents({
      skip,
      limit,
      sort,
      sortValue: value,
      searchText,
    });

    if (count !== 0 && events.length === 0) {
      initialRoute(props.history);
    } else {
      setSelected([]);
      setData(events);
      setEventsCount(count);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit, sort, value, searchText]);

  useEffect(() => {
    getData(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  return (
    <div data-testid="table-loader">
      <PanelHeader
        title="Events"
        breadCrumbItems={[
          {
            title: "Events",
          },
        ]}
        refreshHandler={() => {
          getData();
        }}
        {...(access_type !== Constants.READ_ONLY && {
          sync: () => openModalHandler("show"),
        })}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            data-testid="add-btn"
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/arena/events/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
        >
          <Tab
            label="Events"
            component={Link}
            to={`${props.match.url}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}

      <TabPanel>
        <Switch>
          <Route
            path={`${props.match.path}/settings`}
            component={Settings}
            exact
          />
          <Route
            path={`${props.match.path}`}
            render={routeProps => (
              /* istanbul ignore next */
              <GetEvents
                {...routeProps}
                data={data}
                selected={selected}
                setSelected={setSelected}
                setIsLoading={setIsLoading}
                getData={getData}
                isLoading={isLoading}
                eventsCount={eventsCount}
                openSyncModal={show}
                syncEvents={syncEvents}
                syncModalClose={syncModalClose}
                access_type={access_type}
              />
            )}
          />
        </Switch>
      </TabPanel>
    </div>
  );
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getEvents: payload => dispatch(getEvents(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Events);

export { Container, Events as Component };
