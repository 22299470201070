const Feed_Type = [
  { label: "Article", value: "article" },
  { label: "Video", value: "video" },
  { label: "Gallery", value: "gallery" },
  { label: "Ads", value: "ads" },
];

const Category = [
  { label: "NBA", value: "nba" },
  { label: "WNBA", value: "wnba" },
  { label: "Arena", value: "arena" },
  { label: "Others", value: "others" },
];

const schema = {
  class: {
    title: "New Feeds",
    uid: "feeds",
    _schema: [
      {
        display_name: "NID",
        uid: "nid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Title",
        uid: "title",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Published Date",
        uid: "published_date",
        mandatory: true,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Headline",
        uid: "headline",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Sub HeadLine",
        uid: "subheadline",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Teaser",
        uid: "teaser",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Web Url",
        uid: "web_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Shareable Url",
        uid: "shareable_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "FeedType",
        uid: "feed_type",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...Feed_Type],
        },
      },
      {
        display_name: "Category",
        uid: "category",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...Category],
        },
      },
      {
        display_name: "Content",
        uid: "content",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Additional Content",
        uid: "additional_content",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Media",
        uid: "media",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Caption",
            uid: "caption",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Thumbnail",
            uid: "thumbnail",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Large",
            uid: "large",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Alt Text",
            uid: "alt_text",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Tile",
            uid: "tile",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Mobile",
            uid: "mobile",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Source",
            uid: "source",
            mandatory: true,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Type",
            uid: "type",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Portrait",
            uid: "portrait",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Credit",
            uid: "credit",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "LandScape",
            uid: "landscape",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Raw",
            uid: "raw",
            mandatory: false,
            multiple: false,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Size",
                uid: "size",
                mandatory: false,
                multiple: false,
                data_type: "group",
                field_metadata: {},
                _schema: [
                  {
                    display_name: "Width",
                    uid: "w",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Height",
                    uid: "h",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                ],
              },
              {
                display_name: "Focal Point",
                uid: "focal_point",
                mandatory: false,
                multiple: false,
                data_type: "group",
                field_metadata: {},
                _schema: [
                  {
                    display_name: "Y Offset",
                    uid: "yoffset",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                  {
                    display_name: "X Offset",
                    uid: "xoffset",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                ],
              },
              {
                display_name: "URL",
                uid: "url",
                mandatory: false,
                multiple: false,
                data_type: "file",
                field_metadata: {},
              },
            ],
          },
        ],
      },
      {
        display_name: "Video",
        uid: "video",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Video Type",
            uid: "video_type",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "URL",
            uid: "url",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "bitrate",
            uid: "bitrate",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Subtitle CC",
            uid: "cc",
            mandatory: false,
            multiple: false,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "srt",
                uid: "srt",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "vtt",
                uid: "vtt",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
            ],
          },
        ],
      },
      {
        display_name: "Taxonomy",
        uid: "taxonomy",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Coaches",
            uid: "coaches",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Teams",
            uid: "teams",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Players",
            uid: "players",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Freeform",
            uid: "freeform",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Games",
            uid: "games",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Sections",
            uid: "section",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Channels",
            uid: "channels",
            mandatory: false,
            multiple: true,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Writer",
            uid: "writer",
            mandatory: false,
            multiple: false,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Email",
                uid: "email",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Value",
                uid: "value",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Id",
                uid: "id",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Title",
                uid: "title",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
            ],
          },
        ],
      },
      {
        display_name: "Author",
        uid: "author",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "ID",
            uid: "id",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Slug",
            uid: "slug",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Name",
            uid: "name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "First Name",
            uid: "first_name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Last Name",
            uid: "last_name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Organization",
            uid: "organization",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Is NBA staff",
            uid: "is_nba_staff",
            mandatory: false,
            multiple: false,
            data_type: "boolean",
            field_metadata: {},
          },
          {
            display_name: "Author Photo",
            uid: "author_photo",
            mandatory: false,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Social Handle",
            uid: "social_handle",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Social Link",
            uid: "social_link",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "WPSEO Title",
            uid: "wpseo_title",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "WPSEO Meta Description",
            uid: "wpseo_metadesc",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Description",
            uid: "description",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "User URL",
            uid: "user_url",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Featured Author",
            uid: "featured_author",
            mandatory: false,
            multiple: false,
            data_type: "boolean",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Sponser Ads",
        uid: "sponser_ads",
        mandatory: false,
        multiple: true,
        data_type: "string",
        field_metadata: {},
      },
    ],
  },
};

export default schema;
