import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const GetSchedulesView = props => {
  return (
    <>
      {props.isLoading ? (
        <SkeletonTable
          headings={props.headings}
          checkbox={props.checkbox}
          name={props.name}
          actions={props.actions}
        />
      ) : props.totalCount > 0 ? (
        <Table {...props} />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <Modal
        className="small"
        open={props.deleteModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: props.deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: props?.deleteData?.gcode,
          module: props?.name,
        })}
      </Modal>
      <Modal
        className="small"
        open={props.multiDeleteModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: props.deleteMultiple,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        Are you sure want to delete <strong>{props.numSelected}</strong> items?
      </Modal>
      <Modal
        className="small"
        open={props.openSyncModal}
        handleClose={props.syncModalClose}
        data-testid="sync-modal"
        title="Sync"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.syncModalClose },
          {
            id: "2",
            name: "Start",
            handler: props.syncDetails,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-btn",
            },
          },
        ]}
      >
        Are you sure you want to start schedule sync?
      </Modal>
    </>
  );
};

export default GetSchedulesView;
