import Nba from "./nba.svg";
import Nfl from "./nfl.svg";

const SportsLogo = name => {
  switch (name) {
    case "nba":
      return Nba;
    case "nfl":
      return Nfl;
    default:
      return Nba;
  }
};

export default SportsLogo;
