import {
  Content,
  ContentChildren,
  ContentToolbar,
  Footer,
  LayoutWrap,
} from "./index.style";
import { createGlobalStyle } from "styled-components/macro";
import { FEATURES_ARRAY } from "utils/constants";
import { filterArray, isNullOrEmpty } from "utils/functions";
import { Header, SubHeader } from "@dfep/ui-component";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import Sidebar from "app/components/Common/Sidebar";
import sidebarContent from "app/components/Common/Sidebar/Sidebar-data";
import Typography from "@material-ui/core/Typography";
import UserIcon from "assets/user.svg";

const GlobalStyle = createGlobalStyle`
  .with-sidebar {
    .panelShift {
      transition: ${props =>
        props.theme.transitions.create(["margin", "width"], {
          easing: props.theme.transitions.easing.easeOut,
          duration: props.theme.transitions.duration.enteringScreen,
        })};
    }
    .panelShiftMini {
      width: calc(100% - ${props => props.theme.sidebar.drawerwidth});
      margin-left: ${props => props.theme.sidebar.drawerwidth};
    }
    .panelShiftFull {
      width: calc(100% - ${props => props.theme.sidebar.drawerwidthsmall});
      margin-left: ${props => props.theme.sidebar.drawerwidthsmall};
    }
    .team-name {
      border-left: 0;
      margin-left: 0;
    }
  }
`;

const handleContentVisibilityInSidebar = (applicationState, sidebarData) => {
  const featureNames = Object.keys(applicationState).filter(key =>
    key.endsWith("Enable"),
  );
  for (const name of featureNames) {
    const featureIndex = FEATURES_ARRAY.findIndex(
      feature => feature.featureName === name.slice(0, -6),
    );
    //if featureIndex exists and feature state in applicationState is false
    //then remove content to handle visibility in sidebar
    if (featureIndex > -1 && !applicationState[name]) {
      sidebarData = filterArray({
        searchValue: FEATURES_ARRAY[featureIndex].sidebarId,
        list: sidebarData,
      });
    }
  }
  let sidebar_data = sidebarData.filter(
    obj => !(obj.isChildMandatory && obj.child.length === 0),
  );
  return sidebar_data;
};

const LayoutWithSidebar = props => {
  const { first_name, email } = useSelector(state => state.admin);
  const { open } = useSelector(state => state.sidebar);
  const { name: applicationName, nbaEnable, ...restProps } = useSelector(
    state => state.application,
  );
  const { history, match } = props;
  const { env_key } = match.params;

  const headerProps = {
    userName: first_name || email,
    actions: [
      {
        id: 1,
        name: "Profile",
        handler: () => history.push("/dashboard/account/profile"),
      },
      { id: 2, name: "Sign Out", handler: props.logout },
    ],
    avatar: UserIcon,
    logoAction: () => history.push("/"),
  };

  // Sidebar data is initialize to manipulate links on environment basis
  let sidebarData = cloneDeep(sidebarContent);
  /* istanbul ignore else */
  if (env_key !== "dev") {
    // Mock Game Link is only shown for dev (default environment)
    sidebarData = filterArray({ searchValue: "mockgame", list: sidebarData });
  }

  if (!nbaEnable) {
    sidebarData = filterArray({ searchValue: "Setup", list: sidebarData });
    sidebarData = filterArray({ searchValue: "Content", list: sidebarData });
    sidebarData = filterArray({ searchValue: "syncData", list: sidebarData });
  }

  sidebarData = handleContentVisibilityInSidebar(restProps, sidebarData);

  return (
    <>
      <GlobalStyle />
      <LayoutWrap className="with-sidebar">
        <Header {...headerProps} />
        <SubHeader
          showDashboardIcon={false}
          heading={
            applicationName || (
              <Typography component="div" variant="h6">
                <Skeleton width={150} />
              </Typography>
            )
          }
          className={
            open ? "panelShift panelShiftMini" : "panelShift panelShiftFull"
          }
        />
        <Sidebar sidebarContent={sidebarData} />
        <Content className={open ? "content contentShift" : "content"}>
          <ContentToolbar />
          {/* Content children will come here after Toolbar Div */}

          <ContentChildren className="innerContent">
            {props.children}
          </ContentChildren>
          <Footer
            className={
              open ? "panelShift panelShiftMini" : "panelShift panelShiftFull"
            }
          />
        </Content>
      </LayoutWrap>
    </>
  );
};

export default LayoutWithSidebar;
