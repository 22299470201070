const Content_Type = [
  { label: "PBP", value: "pbp" },
  { label: "Video", value: "video" },
];

const schema = {
  class: {
    title: "Wsc Feeds",
    uid: "feeds",
    _schema: [
      {
        display_name: "Content Type",
        uid: "content_type",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...Content_Type],
        },
      },
      {
        display_name: "NID",
        uid: "nid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "GID",
        uid: "gid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Event ID",
        uid: "event_id",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Period",
        uid: "period",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Event Type",
        uid: "event_type",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Action Type",
        uid: "action_type",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Players",
        uid: "players",
        mandatory: false,
        multiple: true,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Teams",
        uid: "teams",
        mandatory: false,
        multiple: true,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Title",
        uid: "title",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Event Clips",
        uid: "event_clips",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Video URL",
            uid: "video_url",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Video Thumbnail URL",
            uid: "video_thumbnail_url",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Stream Name",
            uid: "stream_name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "WSC Tags",
        uid: "wsc_tags",
        mandatory: false,
        multiple: true,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Publish Date",
        uid: "publish_date",
        mandatory: false,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Rating",
        uid: "rating",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Creation Settings",
        uid: "creation_settings",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Rule Id",
            uid: "rule_id",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Rule Name",
            uid: "rule_name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Creation Time Period",
            uid: "creation_time_period",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Video Type",
            uid: "video_type",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Custom Data",
        uid: "custom_data",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Publish Point name",
            uid: "publish_point_name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
    ],
  },
};

export default schema;
