import { Redirect, Route, Switch } from "react-router-dom";
import Core from "app/pages/Dashboard/Data/Device/Onboardings/Core";
import React from "react";
import Screens from "app/pages/Dashboard/Data/Device/Onboardings/Screens";

const onBoardings = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/screens`}
        exact
      />
      <Route path={`${props.match.path}/screens`} component={Screens} />
      <Route path={`${props.match.path}/core`} component={Core} />
    </Switch>
  );
};

export default onBoardings;
