import {
  BroadcastMessages,
  Content,
  CustomField,
  CustomServices,
  Dashboard,
  GeoFences,
  PushNotification,
  Reports,
  Settings,
  Setup,
  Users,
  Webhooks,
} from ".";
import React from "react";

const IconsFormatted = ({ name }) => {
  switch (name) {
    case "Dashboard":
      return <Dashboard />;
    case "Setup":
      return <Setup />;
    case "Content":
      return <Content />;
    case "CustomField":
      return <CustomField />;
    case "CustomServices":
      return <CustomServices />;
    case "Settings":
      return <Settings />;
    case "Users":
      return <Users />;
    case "BroadcastMessages":
      return <BroadcastMessages />;
    case "PushNotification":
      return <PushNotification />;
    case "Webhooks":
      return <Webhooks />;
    case "Reports":
      return <Reports />;
    case "GeoFences":
      return <GeoFences />;
    default:
      return <Setup />;
  }
};

export default IconsFormatted;
