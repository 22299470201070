import { ButtonSkeleton } from "./skeletonPanelHader.style";
import { IconButton, Typography } from "@material-ui/core";
import {
  Panel,
  PanelActions,
  PanelHeaderWrap,
  PanelInner,
} from "../../../components/Common/PanelHeader/index.style";
import { StyledBreadcrumbs } from "app/components/Common/Breadcrums/Bredcrumb.style";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function BreadCrumbSkeleton() {
  return (
    <StyledBreadcrumbs aria-label="breadcrumb-skeleton">
      {[
        <Skeleton key={1} width={70} />,
        <Skeleton key={2} width={70} />,
        <Skeleton key={3} width={70} />,
      ]}
    </StyledBreadcrumbs>
  );
}

export default function SkeletonPanelHeader() {
  return (
    <Panel>
      <PanelHeaderWrap>
        <PanelInner>
          <Typography component="div" variant="h2">
            <Skeleton width={180} />
          </Typography>
          <PanelActions>
            <IconButton aria-label="Refresh-skeleton">
              <Skeleton variant="circle" width={32} height={32} />
            </IconButton>
            <ButtonSkeleton
              variant="rect"
              width={70}
              height={32}
              marginleft={"10px"}
            />
          </PanelActions>
        </PanelInner>
        <BreadCrumbSkeleton />
      </PanelHeaderWrap>
    </Panel>
  );
}

SkeletonPanelHeader.propTypes = {};
