/* istanbul ignore file */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import FontFaceObserver from "fontfaceobserver";

// Import root app
import { App } from "app";
import { configureAppStore } from "store/configureStore";
import reportWebVitals from "reportWebVitals";

// Observe loading of Nunito (to remove 'Nunito', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver("Nunito", {});

// When Nunito is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add("fontLoaded");
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById("root") as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept([], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
