import { Button, Modal } from "@dfep/ui-component";
import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { Constants } from "utils/constants";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { getGeoLocationsData } from "services/getObjects";
import { Link } from "react-router-dom";
import { NoData } from "styles/global.style";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditLocationSharpIcon from "@material-ui/icons/EditLocationSharp";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

function limitWords(text, limit) {
  const words = text.split(" ");
  if (words.length <= limit) {
    return text;
  }
  return words.slice(0, limit).join(" ") + "...";
}

const headings = [
  {
    label: "Display Name",
    id: "properties.name",
    formator: data => limitWords(data.properties.name, 10),
  },
  {
    label: "Type",
    id: "properties.type",
    formator: data => data.properties.type,
  },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const GetGeoLocations = ({ match, history, location }) => {
  const { access_type } = useSelector(state => state.application);
  const { READ_ONLY } = Constants;

  // States
  const [geoLocations, setGeoLocations] = useState([]);
  const [geoLocationObj, setGeoLocationObj] = useState(null);
  const [dataId, setUid] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [geoLocationCount, setGeoLocationCount] = useState(0);
  const [selected, setSelected] = useState([]); //for checkboxes

  // handle modals
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  // Params
  const { sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { page, skip, limit, sort, value, searchText } = useQueryParams();

  // Functions

  // Handle Single Delete
  const handleModalClose = () => {
    closeModalHandler();
    setUid("");
    setGeoLocationObj();
  };

  const handleDelete = async (event, uid) => {
    event.stopPropagation();
    await dispatch(
      deleteRecord({
        url: `/v1/admin/geo_fencing/geo_location/${uid}`,
      }),
    );
    setSelected([]);
    handleModalClose(event);
    getGeoLocations();
  };

  // Handle Multi Delete
  const multipleDeleteHandler = event => {
    openModalHandler("showMultipleDelete");
  };
  const deleteMultiple = async () => {
    await dispatch(
      deleteRecords({
        url: `/v1/admin/geo_fencing/geo_location`,
        uids: selected,
      }),
    );
    setSelected([]);
    getGeoLocations();
    handleModalClose();
  };

  // Handle checkboxes
  const handleSingleClick = (event, dataId) => {
    const newSingleSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSingleSelected);
  };

  const handleSelectAllClick = event => {
    const newMultiSelected = checkboxSelectAllClick({
      event,
      data: geoLocations,
    });
    setSelected(newMultiSelected);
  };

  // Get Geo Locations API Call
  const getGeoLocations = async () => {
    setLoading(true);
    const {
      data: { geo_locations, count },
    } = await dispatch(
      getGeoLocationsData({
        sport_key,
        env_key,
        skip,
        limit,
        sort,
        sortValue: value,
        searchText,
      }),
    );
    if (count !== 0 && geo_locations.length === 0) {
      initialRoute(history);
    } else {
      setGeoLocations([...geo_locations]);
      setGeoLocationCount(count);
      setLoading(false);
    }
  };

  // Component Life Cycle
  useEffect(() => {
    setSelected([]);
    getGeoLocations();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, skip, limit, sort, value, searchText]);

  const actions = [
    {
      id: "editUsingMap",
      label: "Edit with Map",
      icon: (
        <EditLocationSharpIcon
          fontSize="small"
          data-testid="editUsingMap_btn"
        />
      ),
      handler: (event, uid) => {
        push({
          pathname: `/dashboard/${sport_key}/${env_key}/geo_fencing/geo_location/update/${uid}`,
          state: { from: location },
        });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete_btn" />,
      handler: (event, uid, dataItem) => {
        setGeoLocationObj(dataItem);
        setUid(uid);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      <PanelHeader
        title="Geo Fences Content"
        refreshHandler={getGeoLocations}
        data-testid="panel-header"
        breadCrumbItems={[
          {
            title: "Geo Fences Content",
          },
        ]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            data-testid="add-content"
            color="primary"
            variant="contained"
            component={Link}
            to={{
              pathname: `/dashboard/${sport_key}/${env_key}/geo_fencing/geo_location/add`,
              state: { from: location },
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {isLoading && (
        <SkeletonTable
          headings={headings}
          name="Geo Fences Content"
          actions={[
            {
              id: "editUsingMap",
              label: "Edit",
              icon: (
                <EditLocationSharpIcon
                  fontSize="small"
                  data-testid="editUsingMap_btn"
                />
              ),
              handler: (event, uid, dataItem) => {
                push({
                  pathname: `/dashboard/${sport_key}/${env_key}/geo_fencing/geo_location/update/${uid}`,
                  state: { from: location },
                });
              },
            },
            {
              id: "delete",
              label: "Delete",
              icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
              handler: () => {},
            },
          ]}
          checkbox={true}
        />
      )}
      {geoLocations.length >= 1 && isLoading === false ? (
        <Table
          data-testid="table"
          name="Geo Fences Content"
          headings={headings}
          actions={actions}
          data={geoLocations}
          isSorting
          limit={10}
          totalCount={geoLocationCount}
          checkbox={access_type !== READ_ONLY}
          handleSelectAllClick={handleSelectAllClick}
          handleSingleClick={handleSingleClick}
          selectedList={selected}
          numSelected={selected.length}
          multipleDeleteHandler={multipleDeleteHandler}
        />
      ) : (
        !isLoading && <NoData data-testid="empty-data">No Data Found</NoData>
      )}
      <Modal
        className="small"
        data-testid="delete-modal"
        open={showDelete}
        handleClose={handleModalClose}
        title="Delete Geo Fence Content"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "No",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "no_btn",
            },
          },
          {
            id: "2",
            name: "Yes",
            handler: event => {
              return handleDelete(event, dataId);
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "yes_btn",
            },
          },
        ]}
      >
        {geoLocationObj &&
          deleteConfirmationMessage({
            name: geoLocationObj.properties.type
              ? geoLocationObj.properties.type
              : geoLocationObj.geometry.type,
            module: "Geo Fences Content",
          })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        handleClose={handleModalClose}
        title="Delete Geo Fences Contents"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "confirm_multi_del",
            },
          },
        ]}
      >
        Are you sure want to delete <strong>{selected.length}</strong> items?
      </Modal>
    </>
  );
};

export default GetGeoLocations;
