import { Button } from "@dfep/ui-component";
import { FormProvider, useForm } from "react-hook-form";
import { FormWrapper } from "styles/global.style";
import { getFieldComponent, identifyComponent } from "utils/formFunctions";
import React, { Fragment } from "react";

const formDatatypeConversion = ({ schema, data }) => {
  schema?.class?._schema.forEach(field => {
    switch (field.data_type) {
      case "boolean":
      case "number":
        data[field.uid] = JSON.parse(data[field.uid]);
        break;
      default:
        break;
    }
  });
  return data;
};

const SchemaForm = ({ schema, defaultValues, onSubmit, onCancel }) => {
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all",
    shouldFocusError: true,
    defaultValues: defaultValues,
  });

  const formSubmit = data => {
    return onSubmit(
      formDatatypeConversion({
        schema,
        data,
      }),
    );
  };

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(formSubmit)}
          data-testid="schema-form"
        >
          {schema?.class?._schema.map(item => {
            return (
              <Fragment key={item.uid}>
                {getFieldComponent(identifyComponent(item))}
              </Fragment>
            );
          })}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={onCancel}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export default SchemaForm;
