import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect, useDispatch } from "react-redux";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { saveSportsEnvKey } from "utils/functions";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import React, { useEffect, useState } from "react";
import TableView from "./GetEventsView";

const intialModalState = {
  delModal: false,
  multiDelModal: false,
};

const GetEvents = props => {
  const [uid, setUid] = useState("");
  const { data, getData, isLoading, eventsCount } = props;
  const [modal, setOpenModal] = useState(intialModalState);
  const [eventObj, setEventObj] = useState(null);
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;

  const handleModalDelete = () => {
    const url = `/v1/nba/admin/data/events/${uid}`;
    props.deleteEvent({ url }).then(res => {
      setUid("");
      setOpenModal(intialModalState);
      getData(props);
    });
  };

  const multiDeleteReq = () => {
    const url = `/v1/nba/admin/data/events`;
    props.deleteMultiEvent({ url, uids: props.selected }).then(res => {
      setOpenModal(intialModalState);
      getData(props);
    });
  };

  useEffect(() => {
    saveSportsEnvKey({ dispatch, params: props.match.params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/arena/events/update/${uid}`,
      state: {
        from: props.location,
      },
    });
  };

  const multipleDeleteHandler = () => {
    setOpenModal({ ...modal, multiDelModal: true });
  };

  const handleModalClose = () => {
    setUid("");
    setOpenModal(intialModalState);
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({
      selected: props.selected,
      dataId,
    });
    props.setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    props.setSelected(newSelecteds);
  };

  const headings = [
    { label: "Name", id: "name" },
    { label: "Event Date", id: "event_date", type: "date" },
    { label: "Event ID", id: "event_id" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },

    {
      id: "delete",
      label: "Delete",
      icon: <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />,
      handler: (event, uid, dataItem) => {
        setEventObj(dataItem);
        setUid(uid);
        setOpenModal({ ...modal, delModal: true });
      },
    },
  ];

  return (
    <>
      <TableView
        isLoading={isLoading}
        data={data}
        headings={headings}
        actions={actions}
        checkbox={true}
        handleModalDelete={handleModalDelete}
        handleModalClose={handleModalClose}
        openModal={modal}
        name="Events"
        eventObj={eventObj}
        selectedList={props.selected}
        handleSingleClick={handleSingleClick}
        handleSelectAllClick={handleSelectAllClick}
        numSelected={props.selected.length}
        totalCount={eventsCount}
        multipleDeleteHandler={multipleDeleteHandler}
        multiDeleteReq={multiDeleteReq}
        syncEvents={props.syncEvents}
        openSyncModal={props.openSyncModal}
        syncModalClose={props.syncModalClose}
      />
    </>
  );
};
/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    deleteEvent: payload => dispatch(deleteRecord(payload)),
    deleteMultiEvent: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(GetEvents);

export { Container, GetEvents as Component };
