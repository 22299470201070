import { Route, Switch } from "react-router-dom";
import AddScreen from "./add";
import GetScreen from "app/pages/Dashboard/Data/Device/Onboardings/Screens/get";
import React from "react";
import UpdateScreen from "./update";

const Screens = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddScreen} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateScreen}
        exact
      />
      <Route path={props.match.path} component={GetScreen} />
    </Switch>
  );
};

export default Screens;
