import {
  AuthContainer,
  AuthFormWrap,
  FooterInfo,
  PasswordWrap,
} from "../authGlobal.style";
import { authServices } from "services/auth";
import { Box, Paper, Typography } from "@material-ui/core";
import { Button } from "@dfep/ui-component";
import { connect } from "react-redux";
import { encryptPassword } from "utils/crypto";
import { Link } from "react-router-dom";
import { provideValidationToggle } from "utils/passwordValidation";
import { setError, validateName, validatePassword } from "utils/functions";
import cloneDeep from "lodash/cloneDeep";
import loginLogo from "assets/DFEP-login-logo.svg";
import PasswordValidator from "app/components/Common/PasswordValidator";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  first_name: {
    hasError: false,
    message: "",
  },
  last_name: {
    hasError: false,
    message: "",
  },
  password: {
    hasError: false,
    message: "",
  },
};

const Register = props => {
  const [first_name, bindFirstName] = useInput("");
  const [last_name, bindLastName] = useInput("");
  const [password, bindPassword] = useInput("");
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));
  const [openValidationBar, setPasswordValidationBar] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  // Ref for password input field
  const passwordRef = useRef(null);

  // This useEffect is call when first_name/last_name/password is changed
  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [first_name, last_name, password]);

  // useEffect for openValidationBar callback
  useEffect(() => {
    if (openValidationBar) {
      provideValidationToggle(password);
    }
  }, [password, openValidationBar]);

  // Register API Call on Form Submit
  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      const { account_token } = props.match.params;
      const form = await formValidation();
      props
        .registerService({
          url: `/v1/admin/auth/register/${account_token}`,
          form,
          method: "PUT",
          showToast: true,
        })
        .then(result => {
          setRegisterSuccess(true);
        });
    } catch (error) {}
  };

  // Form Validation for Name and Password
  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!first_name) {
        const error = setError(inputError, "first_name", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Field is required" });
      } else if (!validateName(first_name)) {
        const error = setError(inputError, "first_name", "Invalid Format!");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid name Format!" });
      } else if (!last_name) {
        const error = setError(inputError, "last_name", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Field is required" });
      } else if (!validateName(last_name)) {
        const error = setError(inputError, "last_name", "Invalid Format!");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid name Format!" });
      } else if (!password) {
        const error = setError(inputError, "password", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Field is required" });
      } else if (!validatePassword(password)) {
        const error = setError(
          inputError,
          "password",
          "Invalid Password Format!",
        );
        setInputError(cloneDeep(error));
        passwordRef.current.focus();
        reject({ error: "Invalid Password Format" });
      } else {
        const form = {
          first_name,
          last_name,
          ...encryptPassword(password),
        };
        resolve(form);
      }
    });
  };

  return (
    <AuthContainer>
      <AuthFormWrap>
        <img alt="logo" className="login-logo" src={loginLogo} />

        <Paper className="auth-form">
          {registerSuccess ? (
            <>
              <Box>
                <Typography variant="h5">Successful Account Created</Typography>
                <Typography>
                  You can use your password to log in to your account
                </Typography>
                <div className="field-wrap">
                  <Button
                    data-testid="login-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => props.history.push("/auth/login")}
                  >
                    Login
                  </Button>
                </div>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h1">Sign Up</Typography>
              <form
                data-testid="register-form"
                noValidate
                onSubmit={onFormSubmit}
              >
                <TextField
                  label="First Name"
                  id="first_name"
                  type="text"
                  required={true}
                  error={inputError.first_name.hasError}
                  helperText={inputError.first_name.message}
                  {...bindFirstName}
                />
                <TextField
                  label="Last Name"
                  id="last_name"
                  type="text"
                  required={true}
                  error={inputError.last_name.hasError}
                  helperText={inputError.last_name.message}
                  {...bindLastName}
                />
                <PasswordWrap>
                  <TextField
                    label="Password"
                    id="password"
                    type="password"
                    required={true}
                    error={inputError.password.hasError}
                    inputRef={passwordRef}
                    onFocus={() => setPasswordValidationBar(true)}
                    onBlur={() => setPasswordValidationBar(false)}
                    helperText={inputError.password.message}
                    {...bindPassword}
                  />
                  {openValidationBar && <PasswordValidator />}
                </PasswordWrap>
                <div className="field-wrap">
                  <Button type="submit" variant="contained" color="primary">
                    Sign Up
                  </Button>
                </div>
              </form>
              <div className="field-wrap link-btn">
                <Box justifyContent="center" display="flex">
                  <Link to="/auth/login">
                    <Button color="primary">Back to Signin</Button>
                  </Link>
                </Box>
              </div>
            </>
          )}
        </Paper>
      </AuthFormWrap>
      <FooterInfo>
        <Typography variant="body1" component="h6">
          © 2021 DFEP. All rights reserved.
        </Typography>
      </FooterInfo>
    </AuthContainer>
  );
};

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      account_token: PropTypes.string,
    }),
  }),
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    registerService: payload => dispatch(authServices(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Register);

export { Container, Register as Component };
