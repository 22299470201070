import { format } from "date-fns";
import { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import { MUIGrid } from "app/components/Common/Collapsible/Collapsible.style.js";
import React from "react";

export const Collapsible = props => {
  const { dataItem, extraDateInfo, extraInfo } = props;
  let DateInfo = extraDateInfo.filter(extraDateInfo => extraDateInfo.id !== "");

  return (
    <Fragment>
      <Grid container>
        {extraInfo && (
          <MUIGrid item xs={3}>
            {extraInfo.map(item => (
              <Typography variant="body1" key={item.id}>
                {item.type === "date" ? (
                  <span>
                    <span className="labelTitle">{`${item.label}: `}</span>
                    <span className="date">
                      {`${format(new Date(dataItem[item.id]), "MMM dd yyyy")}`}
                    </span>
                    <span> {format(new Date(dataItem[item.id]), "p")}</span>
                  </span>
                ) : (
                  <span>
                    <span className="labelTitle">{`${item.label}: `}</span>
                    <span>{`${dataItem[item.id]}`}</span>
                  </span>
                )}
              </Typography>
            ))}
          </MUIGrid>
        )}
        {DateInfo.length > 0 ? (
          <MUIGrid item xs={3}>
            {extraDateInfo.map(item => (
              <Typography variant="body1" key={item.id}>
                {item.type === "date" ? (
                  <span>
                    <span className="labelTitle">{`${item.label}: `}</span>
                    <span className="date">
                      {`${format(new Date(dataItem[item.id]), "MMM dd yyyy")}`}
                    </span>
                    <span> {format(new Date(dataItem[item.id]), "p")}</span>
                  </span>
                ) : (
                  <span>
                    <span className="labelTitle">{`${item.label}: `}</span>
                    <span>{`${dataItem[item.id]}`}</span>
                  </span>
                )}
              </Typography>
            ))}
          </MUIGrid>
        ) : null}
      </Grid>
    </Fragment>
  );
};
