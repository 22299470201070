import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateVenue = props => {
  const [venueData, setVenueData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/interactive-maps/venues`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/admin/interactive_maps/venues/${uid}`;
    const {
      data: { venue },
    } = await dispatch(get({ url }));

    for (const prop in venue) {
      if (
        [
          "center_coordinate",
          "northeast_coordinate",
          "southwest_coordinate",
        ].includes(prop)
      ) {
        Object.assign(venue[prop], {
          latitude: venue[prop].coordinates[0],
          longitude: venue[prop].coordinates[1],
        });
      }
    }

    setVenueData(venue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    for (const prop in data) {
      if (
        [
          "center_coordinate",
          "northeast_coordinate",
          "southwest_coordinate",
        ].includes(prop)
      ) {
        Object.assign(data[prop], {
          type: "Point",
          coordinates: [data[prop].latitude, data[prop].longitude],
        });
        delete data[prop].latitude;
        delete data[prop].longitude;
      }
    }

    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/admin/interactive_maps/venues/${uid}`,
          key: "venue",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Venue"
        breadCrumbItems={[
          {
            title: "Update Venue",
          },
        ]}
        backUrl={getURL}
      />

      {venueData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={venueData}
          schema={schema}
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateVenue;
