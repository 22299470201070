import { a11yProps, Tab, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize } from "utils/functions";
import { connect, useDispatch, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { Container as GetLeaders } from "./GetLeaders";
import { getTeamsLeaders } from "services/getObjects";
import { initialRoute } from "utils/functions.js";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";
import settings from "./setting.js";

const SeasonTabs = props => {
  const { tid, team_name, access_type } = useSelector(
    state => state.application,
  );

  let tabObj = {};
  tabObj[team_name] = 0;

  tabObj = { ...tabObj, others: 1, settings: 2 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const { sport_key, env_key } = props.match.params;
  const { show, closeModalHandler, openModalHandler } = useModal();
  const breadCrumbItems = [
    {
      title: "Team Leaders",
    },
  ];

  //sync Teams
  const synToTeams = () => {
    openModalHandler("show");
  };

  //sync Teams
  const sendSync = async () => {
    await props.syncTeams({
      url: "/v1/nba/admin/sync/team_leaders",
      method: "post",
    });
    closeModalHandler();
  };

  // get api call
  const dispatch = useDispatch();
  const { sort, limit, value, skip, searchText } = useQueryParams();
  const [totalCount, setTotalDataCount] = useState();
  const [leadersData, setLeadersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLeaders = useCallback(
    async team_name => {
      setLeadersData([]);
      setIsLoading(true);

      const {
        data: { team_leaders, count },
      } = await dispatch(
        getTeamsLeaders({
          team_name,
          tid,
          sort,
          limit,
          value,
          skip,
          searchText,
        }),
      );

      if (count !== 0 && team_leaders.length === 0) {
        initialRoute(props.history);
      } else {
        setLeadersData(team_leaders);
        setTotalDataCount(count);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team_name, sort, limit, value, skip, searchText],
  );

  return (
    <>
      <PanelHeader
        title="Team Leaders"
        breadCrumbItems={breadCrumbItems}
        refreshHandler={() => {
          getLeaders(tab === 0 ? team_name : "others");
        }}
        sync={synToTeams}
        hasSyncCMS={false}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            data-testid="add-btn"
            onClick={() => {
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/team/leaders/add`,
                state: { from: props.location },
              });
            }}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${props.match.url}/${team_name}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${props.match.url}/others`}
            {...a11yProps(1)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <Switch>
        <Redirect
          from={`${props.match.path}`}
          to={`${props.match.url}/${team_name}`}
          exact
        />

        <Route
          path={`${props.match.path}/settings`}
          component={settings}
          exact
        />
        <Route
          path={`${props.match.path}/:team_name`}
          /*  istanbul ignore next   */
          render={routeProps => {
            /*  istanbul ignore next   */
            return (
              <GetLeaders
                tid={tid}
                openSync={show}
                closeSync={closeModalHandler}
                sendSync={sendSync}
                access_type={access_type}
                totalCount={totalCount}
                leadersData={leadersData}
                isLoading={isLoading}
                getLeaders={getLeaders}
                {...routeProps}
              />
            );
          }}
          exact
        />
      </Switch>
    </>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    syncTeams: payload => dispatch(requestAPI(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(SeasonTabs);

export { Container, SeasonTabs as Component };
