import { Link, useRouteMatch } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconsFormatted from "./Sidebar-icons/IconsFormatted";
import React from "react";

const ListItemChild = ({ isExpandable, isOpen, text, icon, url }) => {
  const { url: routeUrl } = useRouteMatch();
  const child = (
    <span className="menu-item">
      <span className="menu-item-data">
        {icon && <IconsFormatted name={icon} />}
        <span className="menu-name">{text}</span>
      </span>
      {isExpandable && isOpen && <ExpandLess className="arrow-icon" />}
      {isExpandable && !isOpen && <ExpandMore className="arrow-icon" />}
    </span>
  );
  return isExpandable ? child : <Link to={`${routeUrl}${url}`}>{child}</Link>;
};

export default ListItemChild;
