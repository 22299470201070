import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "app/components/Common/Accordian";
import { AccordionIconWrap, NoData } from "styles/global.style";
import { Button } from "@dfep/ui-component";
import { getFieldComponent, identifyComponent } from "utils/formFunctions";
import { useFieldArray, useFormContext } from "react-hook-form";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldBorder from "app/components/Common/FieldBorder ";
import FormGroup from "app/components/Common/schemaForm/group";
import get from "lodash/get";
import IconButton from "@material-ui/core/IconButton";
import isNull from "lodash/isNull";
import Label from "app/components/Common/Label";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Styled from "styled-components";

const AccordionSummary = Styled(MuiAccordionSummary)`
.MuiAccordionSummary-content{
  display: flex;
  justify-content: space-between;
  align-items:center;
}
`;

const getSchemaObject = schemaArray => {
  let schemaClass = {};
  schemaArray.forEach(schemaObject => {
    switch (schemaObject.data_type) {
      case "string":
      case "number":
        return schemaObject.multiple
          ? (schemaClass[`${schemaObject.uid}`] = [])
          : (schemaClass[`${schemaObject.uid}`] = "");
      case "date":
        return schemaObject.multiple
          ? (schemaClass[`${schemaObject.uid}`] = [])
          : (schemaClass[`${schemaObject.uid}`] = null);
      case "group":
        return schemaObject.multiple
          ? (schemaClass[`${schemaObject.uid}`] = [])
          : (schemaClass[`${schemaObject.uid}`] = getSchemaObject(
              schemaObject._schema,
            ));
      case "boolean":
        return (schemaClass[`${schemaObject.uid}`] = false);
      case "mixed":
        return (schemaClass[`${schemaObject.uid}`] = "");
      case "rte":
        return schemaObject.multiple
          ? (schemaClass[`${schemaObject.uid}`] = [])
          : (schemaClass[`${schemaObject.uid}`] = "");
      case "file":
        return schemaObject.multiple
          ? (schemaClass[`${schemaObject.uid}`] = [])
          : (schemaClass[`${schemaObject.uid}`] = "");
      default:
        return schemaObject.multiple
          ? (schemaClass[`${schemaObject.uid}`] = [])
          : (schemaClass[`${schemaObject.uid}`] = "");
    }
  });
  return schemaClass;
};

const MultipleGroup = schema => {
  const { isBorder = true } = schema;
  const [open, setOpen] = useState(
    schema.field_metadata?.multiple_open || false,
  );

  const addField = (event, schemaObject) => {
    event.preventDefault();
    event.stopPropagation();
    let schemaClass = {};
    if (schemaObject.data_type === "group") {
      schemaClass = getSchemaObject(schemaObject._schema);
    } else if (schemaObject.data_type === "date") {
      schemaClass[`[]`] = null;
    } else {
      schemaClass[`[]`] = "";
    }
    setOpen(true);
    prepend(schemaClass);
  };
  const onFieldDelete = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    remove(index);
  };

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  if (isNull(getValues(schema.uid))) {
    setValue(schema.uid, []);
  }

  // for multiple inputs
  const { fields, prepend, remove } = useFieldArray({
    name: `${schema.uid}`,
    control,
  });

  const setDefaultValue = useCallback(() => {
    if (getValues(schema.uid)?.length && !fields?.length) {
      setValue(schema.uid, getValues(schema.uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema.uid]);

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue]);

  const groupAccordianError = !!get(errors, schema.uid);
  useEffect(() => {
    if (groupAccordianError) setOpen(true);
  }, [groupAccordianError]);

  return (
    <FieldBorder isBorder={isBorder} isError={!!get(errors, schema.uid)}>
      <Accordion defaultExpanded={false} expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-testid={schema.uid}
          onClick={() => setOpen(!open)}
        >
          <Label>{schema.display_name}</Label>
          <AccordionIconWrap>
            <IconButton
              data-testid={`${schema.uid}-add-field`}
              className="add-icon"
              onClick={event => addField(event, schema)}
            >
              <AddCircleOutlineIcon fontSize="small" htmlColor="black" />
            </IconButton>
          </AccordionIconWrap>
        </AccordionSummary>
        <AccordionDetails>
          {fields?.length > 0 ? (
            fields?.map((field, index) => {
              let schemaClass = {
                ...schema,
                uid: `${schema.uid}[${index}]`,
                onFieldDelete,
                index,
                field,
              };
              if (schema.data_type === "group") {
                return (
                  <Fragment key={field.id}>
                    <FormGroup {...schemaClass} />
                  </Fragment>
                );
              }

              schemaClass.multiple = false;
              return (
                <Fragment key={field.id}>
                  {getFieldComponent(identifyComponent(schemaClass))}
                </Fragment>
              );
            })
          ) : (
            <NoData className="no-data">
              <p>No Data Added Yet</p>
              <Button
                data-testid="add-field"
                onClick={event => addField(event, schema)}
              >
                Add Data
              </Button>
            </NoData>
          )}
        </AccordionDetails>
      </Accordion>
    </FieldBorder>
  );
};

export default MultipleGroup;
