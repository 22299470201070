import "./Map.css";
import { createRecord } from "services/createObject";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { deleteRecord } from "services/deleteObject";
import { Modal } from "@dfep/ui-component";
import { updateRecord } from "services/updateObject";
import { useModal } from "app/hooks/modal";

import _ from "lodash";
import FeatureModal from "../Modal";
import Loader from "../Loader";
import React from "react";
import ReactDOM from "react-dom";
import Search from "app/components/Common/Search/index";

export const MapMode = {
  ADD_POLYGON: "ADD_POLYGON",
  ADD_CIRCLE: "ADD_CIRCLE",
  DEFAULT: "DEFAULT",
};

const initialModalState = {
  defaultValues: null,
  isOpenModal: false,
  mapMode: MapMode.DEFAULT,
  modalMode: "Add",
};

const mapStyle = [
  {
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
];

const mapDivStyle = { height: "76vh", position: "relative" };

function EditClearDeleteButtonControl({ geoJSON }) {
  const isGeoJSONEmpty = !geoJSON || Object.keys(geoJSON).length === 0;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
      }}
    >
      <button
        className="geoFence-submit-button primary"
        id="edit-button"
        disabled={isGeoJSONEmpty}
      >
        Edit Geo fence
      </button>
      <button
        className="geofence-clear-button"
        id="clear-button"
        disabled={isGeoJSONEmpty}
      >
        Clear
      </button>
      <button
        className="map-submit-button danger"
        id="delete-button"
        disabled={isGeoJSONEmpty}
      >
        Delete Geo fence
      </button>
    </div>
  );
}

function SaveCancelGeoFenceButtonControl({ geoJSON }) {
  const isGeoJSONEmpty = !geoJSON || Object.keys(geoJSON).length === 0;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
      }}
    >
      <button
        className="geoFence-submit-button primary"
        id="save-geoFence-button"
        disabled={isGeoJSONEmpty}
      >
        Save
      </button>
      <button
        className="geoFence-submit-button danger"
        id="cancel-geoFence-button"
      >
        Cancel
      </button>
    </div>
  );
}

function copyToClipboardEventListner(event) {
  switch (event.code) {
    case "KeyC": // c key
      navigator.clipboard.writeText(
        document.getElementById("coordinates").innerHTML.replace("<br>", "\n"),
      );
      break;
    default:
      break;
  }
}

function createLatitudeAndLongitudeControl({ map }) {
  const controlDiv = document.createElement("div");
  controlDiv.id = "coordinates";
  controlDiv.style = "z-index: 2; font-size: 10px;";

  const overlay = new window.google.maps.OverlayView();
  overlay.draw = function () {};
  overlay.setMap(map);

  const mapDOM = document.getElementById("map");
  mapDOM.addEventListener("mousemove", function (event) {
    const { left, top } = mapDOM.getBoundingClientRect();
    const x = event.pageX - left;
    const y = event.pageY - top;

    if (!overlay.getProjection()) return;

    const coordinate = overlay
      .getProjection()
      .fromContainerPixelToLatLng(new window.google.maps.Point(x, y));
    document.getElementById("coordinates") &&
      (document.getElementById("coordinates").innerHTML =
        "Longitude = " +
        coordinate.lng().toFixed(6) +
        "<br/>Latitude = " +
        coordinate.lat().toFixed(6));
  });

  document.addEventListener("keydown", copyToClipboardEventListner);

  return controlDiv;
}

function Map({ zoom, dispatch, sport_key, env_key, geoJSON, ...rest }) {
  const getURL = `/dashboard/${sport_key}/${env_key}/geo_fencing/geo_location`;

  const mapOptions = {
    center: { lat: 37.09, lng: -95.71 },
    zoom: 5,
  };

  // handle modals
  const { showDelete, closeModalHandler, openModalHandler } = useModal();

  const mapRef = createRef(null);
  const autocompleteRef = useRef(null);
  const mapMode = useRef(MapMode.DEFAULT);
  const drawingManagerRef = useRef(null);

  const selectedCoordinates = useRef(null);

  const [map, setMap] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [drawingMode, setDrawingMode] = useState(null);

  // State variable to store the drawn shape data in GeoJSON Format (circle or polygon)
  const drawnShapeData = useRef(geoJSON);
  const setDrawnShapes = useRef([]);

  const setUid = useRef("");

  const [featureModalState, setModalState] = useState({
    defaultValues: null,
    isOpenModal: false,
    mapMode: MapMode.DEFAULT,
    modalMode: "Add",
  });

  // Define state variables to track button states
  const [isClearButtonDisabled, setIsClearButtonDisabled] = useState(
    !drawnShapeData.current && setDrawnShapes.current.length === 0,
  );

  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(
    !drawnShapeData.current || setDrawnShapes.current.length === 0,
  );

  function clearButtonBackgroundColors() {
    document.querySelectorAll(".action-button").forEach(node => {
      node.style.backgroundColor = "";
    });
  }

  function changeMapMode({ mode, mapMode }) {
    mapMode.current = mode;

    clearButtonBackgroundColors(); // Clear all button background colors

    const activeButton = document.querySelector(
      `.action-button[map-mode=${mode}]`,
    );
    if (activeButton) {
      activeButton.style.backgroundColor = "rgb(0, 110, 0)";
    }

    setDrawingMode(MapMode[mode]);
  }

  function AddPolygonButton({ geoJSON, onToggleClick }) {
    const isGeoJSONEmpty = !geoJSON || Object.keys(geoJSON).length === 0;

    const buttonClassName = isGeoJSONEmpty
      ? "action-button"
      : "action-button disabled";

    const handleToggleClick = () => {
      const mode = "ADD_POLYGON";
      const args = {
        mode,
        map,
        drawingMode,
        mapMode,
      };
      onToggleClick(args);
    };

    return (
      <div
        title="Add Polygon"
        id="add-polygon"
        map-mode="ADD_POLYGON"
        expanded="false"
        className={buttonClassName}
        style={{
          height: "66px",
          width: "66px",
          textAlign: "center",
          verticalAlign: "middle",
          background:
            "url('/static/map/admin/icon_shape_tool.1975a914b299.png') no-repeat #3f9dff",
          backgroundSize: "30%",
          backgroundPosition: "center",
          display: "block",
          marginBottom: "10px",
        }}
        onClick={handleToggleClick}
      />
    );
  }

  function AddCircleButton({ geoJSON, onToggleClick }) {
    const isGeoJSONEmpty = !geoJSON || Object.keys(geoJSON).length === 0;

    const buttonClassName = isGeoJSONEmpty
      ? "action-button"
      : "action-button disabled";

    const handleToggleClick = () => {
      const mode = "ADD_CIRCLE";
      const args = {
        mode,
        map,
        drawingMode,
        mapMode,
      };
      onToggleClick(args);
    };

    return (
      <div
        title="Add Circle"
        id="add-circle"
        map-mode="ADD_CIRCLE"
        expanded="false"
        className={buttonClassName}
        style={{
          height: "66px",
          width: "66px",
          textAlign: "center",
          verticalAlign: "middle",
          background:
            "url('/static/map/admin/icon_circle.png') no-repeat #3f9dff",
          backgroundSize: "25%",
          backgroundPosition: "center",
          display: "block",
        }}
        onClick={handleToggleClick}
      />
    );
  }

  const cancelModalHandler = useCallback(async () => {
    if (drawnShapeData.current.properties.name) {
      setDrawingMode(MapMode.DEFAULT);
      mapMode.current = MapMode.DEFAULT;
      setModalState(initialModalState);
    } else {
      clearGeoFenceHandler();
      setModalState(initialModalState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Convert to GeoJSON and Save Data in Modal State
  const submitModalStateHandler = async ({ form, type }) => {
    const geoJson = _.cloneDeep(drawnShapeData.current);
    switch (type) {
      case MapMode.ADD_POLYGON:
        const propertyPolygonMapping = {
          name: "name",
          push_notification_tag: "push_notification_tag",
          priority: "priority",
        };

        for (const key in propertyPolygonMapping) {
          if (form.hasOwnProperty(key)) {
            const targetKey = propertyPolygonMapping[key];
            geoJson.properties[targetKey] = form[key];
          }
        }
        drawnShapeData.current = geoJson;
        setIsClearButtonDisabled(false);
        setIsEditButtonDisabled(false);
        mapMode.current = MapMode.DEFAULT;
        setDrawingMode(MapMode.DEFAULT);
        break;
      case MapMode.ADD_CIRCLE:
        const propertyCircleMapping = {
          name: "name",
          push_notification_tag: "push_notification_tag",
          radius: "radius",
          center: "center",
          priority: "priority",
        };

        for (const key in propertyCircleMapping) {
          if (form.hasOwnProperty(key)) {
            const targetKey = propertyCircleMapping[key];
            geoJson.properties[targetKey] = form[key];
          }
        }
        if (form.center) {
          geoJson.geometry.coordinates[0] = form.center.lng;
          geoJson.geometry.coordinates[1] = form.center.lat;
        }
        drawnShapeData.current = geoJson;
        setIsClearButtonDisabled(false);
        setIsEditButtonDisabled(false);
        mapMode.current = MapMode.DEFAULT;
        setDrawingMode(MapMode.DEFAULT);
        break;
      default:
        break;
    }
    setModalState(initialModalState);
  };

  //Edit the current shape drawn handler
  const editGeoFenceHandler = () => {
    if (
      drawnShapeData.current &&
      drawnShapeData.current.properties.name &&
      !drawnShapeData.current.uid
    ) {
      switch (drawnShapeData.current.geometry.type) {
        case "Point":
          switch (drawnShapeData.current.properties.type) {
            case "Circle":
              setModalState({
                defaultValues: drawnShapeData.current.properties,
                isOpenModal: true,
                mapMode: MapMode.ADD_CIRCLE,
                modalMode: "Add",
              });
              break;
            default:
              break;
          }
          break;
        case "Polygon":
          setModalState({
            defaultValues: drawnShapeData.current.properties,
            isOpenModal: true,
            mapMode: MapMode.ADD_POLYGON,
            modalMode: "Add",
          });
          break;
        default:
          break;
      }
    } else if (drawnShapeData.current && drawnShapeData.current.uid) {
      switch (drawnShapeData.current.geometry.type) {
        case "Point":
          switch (drawnShapeData.current.properties.type) {
            case "Circle":
              setModalState({
                defaultValues: drawnShapeData.current.properties,
                isOpenModal: true,
                mapMode: MapMode.ADD_CIRCLE,
                modalMode: "Update",
              });
              break;
            default:
              break;
          }
          break;
        case "Polygon":
          setModalState({
            defaultValues: drawnShapeData.current.properties,
            isOpenModal: true,
            mapMode: MapMode.ADD_POLYGON,
            modalMode: "Update",
          });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //Clear the shapes on the map
  const clearGeoFenceHandler = () => {
    if (drawnShapeData.current && setDrawnShapes.current) {
      if (drawnShapeData.current.hasOwnProperty("uid")) {
        setUid.current = drawnShapeData.current.uid;
      }

      setDrawnShapes.current.forEach(shape => shape.setMap(null));
      setDrawnShapes.current = [];
      drawnShapeData.current = {};

      setIsClearButtonDisabled(true);
      setIsEditButtonDisabled(true);

      setDrawingMode(MapMode.DEFAULT);
      mapMode.current = MapMode.DEFAULT;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleModalClose = () => {
    closeModalHandler();
  };

  //GeoFence API DELETE HERE
  const deleteGeoFenceHandler = async event => {
    try {
      if (!drawnShapeData.current.uid && setUid.current !== "") {
        drawnShapeData.current.uid = setUid.current;
      }

      if (drawnShapeData.current.uid || setUid.current) {
        await dispatch(
          deleteRecord({
            url: `/v1/admin/geo_fencing/geo_location/${drawnShapeData.current.uid}`,
          }),
        );
        handleModalClose(event);
        rest.history.push(getURL);
        // Remove the shape from the map (if you have a reference to the shape)
        clearGeoFenceHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };

  //GeoFence API SAVE HERE
  const saveGeoFenceHandler = useCallback(async () => {
    try {
      if (drawnShapeData.current.uid) {
        await dispatch(
          updateRecord({
            form: drawnShapeData.current,
            url: `/v1/admin/geo_fencing/geo_location/${drawnShapeData.current.uid}`,
            key: "geo_location",
          }),
        );
        rest.history.push(getURL);
      } else {
        await dispatch(
          createRecord({
            form: drawnShapeData.current,
            url: "/v1/admin/geo_fencing/geo_location",
            key: "geo_location",
          }),
        );
        rest.history.push(getURL);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Cancel Btn renders back to the Get table
  const cancelGeoFenceHandler = async () => {
    try {
      await rest.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  //Setting Map & handling Zoom
  useEffect(() => {
    setMap(
      new window.google.maps.Map(mapRef.current, {
        center: mapOptions.center,
        styles: mapStyle,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoom: mapOptions.zoom,
      }),
    );
    setShowLoader(false);
    //to particularly handle map's zoom functionality
    //by setting its parent's padding to 0
    document.getElementsByClassName("innerContent")[0] &&
      document
        .getElementsByClassName("innerContent")[0]
        .classList.add("innerContentMap");
    return () => {
      document.getElementsByClassName("innerContent")[0] &&
        document
          .getElementsByClassName("innerContent")[0]
          .classList.remove("innerContentMap");

      document.removeEventListener("keydown", copyToClipboardEventListner);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Setting the co ordinates for already existing fence under update on the map
  useEffect(() => {
    if (geoJSON && geoJSON.geometry && geoJSON.geometry.coordinates) {
      const coordinates = geoJSON.geometry.coordinates;
      const parsedCoordinates = Array.isArray(coordinates[0])
        ? coordinates[0].map(coord => ({
            lat: parseFloat(coord[1]),
            lng: parseFloat(coord[0]),
          }))
        : [
            {
              lat: parseFloat(coordinates[1]),
              lng: parseFloat(coordinates[0]),
            },
          ];

      selectedCoordinates.current = parsedCoordinates;

      if (map) {
        const newBounds = new window.google.maps.LatLngBounds();
        parsedCoordinates.forEach(coord => {
          newBounds.extend(coord);
        });

        map.fitBounds(newBounds);
        if (map && parsedCoordinates.length === 1) {
          map.setCenter(parsedCoordinates[0].lng, parsedCoordinates[0].lat);
          map.setZoom(18);
        }
      }
    }
  }, [geoJSON, map]);

  // Initialize the Autocomplete once the map is available
  useEffect(() => {
    const defaultBounds = {
      north: mapOptions.center.lat + 0.1,
      south: mapOptions.center.lat - 0.1,
      east: mapOptions.center.lng + 0.1,
      west: mapOptions.center.lng - 0.1,
    };
    if (map) {
      const input = document.getElementById("search");
      const options = {
        bounds: defaultBounds,
        types: ["(cities)"],
        //componentRestrictions: { country: ["us", "in"] },
        strictBounds: false,
      };
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        input,
        options,
      );

      // Listen to place changes from the autocomplete
      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();

        if (place && place.geometry) {
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            //map.setCenter(place.geometry.location);
            map.setZoom(18);
          }
        }
      });
    }
    if (map && selectedCoordinates.current !== null) {
      const newBounds = new window.google.maps.LatLngBounds();

      if (Array.isArray(selectedCoordinates.current[0])) {
        // For polygons with multiple arrays of coordinates
        selectedCoordinates.current.forEach(coordinates => {
          coordinates.forEach(coord => {
            const latLng = new window.google.maps.LatLng(coord.lat, coord.lng);
            newBounds.extend(latLng);
          });
        });
      } else {
        // For circles with a single array of coordinates
        selectedCoordinates.current.forEach(coord => {
          const latLng = new window.google.maps.LatLng(coord.lat, coord.lng);
          newBounds.extend(latLng);
        });
      }

      map.fitBounds(newBounds);
      map.setCenter(newBounds.getCenter().lng(), newBounds.getCenter().lat());
      // Delay the setZoom call by a short duration (e.g., 100ms)
      setTimeout(() => {
        map.setZoom(18);
      }, 100);
    }
  }, [mapOptions.center.lng, mapOptions.center.lat, map, selectedCoordinates]);

  //Drawing Manager Controls
  useEffect(() => {
    if (map) {
      // Set up the DrawingManager
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: true,
        drawingControl: false,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
        },
        circleOptions: {
          editable: false,
          draggable: false,
        },
      });

      // Add the DrawingManager to the map
      drawingManager.setMap(map);

      // Store the DrawingManager reference
      drawingManagerRef.current = drawingManager;
    }
  }, [map]);

  // Handle drawing mode changes
  useEffect(() => {
    if (drawingManagerRef.current) {
      switch (drawingMode) {
        case MapMode.ADD_POLYGON:
          // Attach an event listener to the DrawingManager's overlaycomplete event
          window.google.maps.event.addListener(
            drawingManagerRef.current,
            "overlaycomplete",
            function (event) {
              if (
                event.type === window.google.maps.drawing.OverlayType.POLYGON
              ) {
                const shape = event.overlay;
                setDrawnShapes.current.push(shape);

                // Get the coordinates of the polygon
                const polygonCoordinates = shape
                  .getPath()
                  .getArray()
                  .map(latLng => ({ lng: latLng.lng(), lat: latLng.lat() }));

                //convert to GeoJSON
                const convertPolygonToGeoJSON = polygonCoordinates => {
                  return {
                    type: "Feature",
                    geometry: {
                      type: "Polygon",
                      coordinates: [
                        polygonCoordinates.map(coord => [coord.lng, coord.lat]),
                      ],
                    },
                    properties: {
                      name: "",
                      push_notification_tag: "",
                      type: "Polygon",
                      priority: 0,
                    },
                    uid: setUid.current,
                  };
                };

                // Update the polygonCoordinates state as GeoJSON
                drawnShapeData.current = convertPolygonToGeoJSON(
                  polygonCoordinates,
                );

                setModalState({
                  defaultValues: {
                    name: "",
                    push_notification_tag: "",
                    priority: 0,
                  },
                  isOpenModal: true,
                  mapMode: MapMode.ADD_POLYGON,
                  modalMode: "Add",
                });
              }
            },
          );
          drawingManagerRef.current.setDrawingMode(
            window.google.maps.drawing.OverlayType.POLYGON,
          );
          break;
        case MapMode.ADD_CIRCLE:
          // Attach an event listener to the DrawingManager's overlaycomplete event
          window.google.maps.event.addListener(
            drawingManagerRef.current,
            "overlaycomplete",
            function (event) {
              if (
                event.type === window.google.maps.drawing.OverlayType.CIRCLE
              ) {
                const shape = event.overlay;
                setDrawnShapes.current.push(shape);

                // Get the center and radius of the circle
                const circleCenter = {
                  lng: shape.center.lng(),
                  lat: shape.center.lat(),
                };
                const circleRadius = shape.getRadius().toFixed(2);

                //convert to GeoJSON
                const convertCircleToGeoJSON = (center, radius) => {
                  return {
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [center.lng, center.lat],
                    },
                    properties: {
                      name: "",
                      push_notification_tag: "",
                      radius: radius,
                      center: { lng: center.lng, lat: center.lat },
                      type: "Circle",
                      priority: 0,
                    },
                    uid: setUid.current,
                  };
                };

                // Update the circleData state in GeoJSON
                drawnShapeData.current = convertCircleToGeoJSON(
                  circleCenter,
                  circleRadius,
                );
                setModalState({
                  defaultValues: {
                    name: "",
                    push_notification_tag: "",
                    radius: circleRadius,
                    center: { lng: circleCenter.lng, lat: circleCenter.lat },
                    priority: 0,
                  },
                  isOpenModal: true,
                  mapMode: MapMode.ADD_CIRCLE,
                  modalMode: "Add",
                });
              }
            },
          );
          drawingManagerRef.current.setDrawingMode(
            window.google.maps.drawing.OverlayType.CIRCLE,
          );
          break;
        case MapMode.DEFAULT:
          drawingManagerRef.current.setDrawingMode(
            window.google.maps.drawing.OverlayType.null,
          );
          break;
        default:
          break;
      }
    }
  }, [drawingMode]);

  //Plotting Circle or Polygon under Update Mode
  useEffect(() => {
    if (map) {
      if (geoJSON && geoJSON.geometry && geoJSON.geometry.coordinates) {
        const { type, coordinates } = geoJSON.geometry;

        if (type === "Point") {
          // Handle drawing a circle
          const center = {
            lat: coordinates[1],
            lng: coordinates[0],
          };
          const radius = geoJSON.properties.radius;

          // Create a circle on the map
          const shape = new window.google.maps.Circle({
            center: { lng: center.lng, lat: center.lat },
            radius: radius,
            editable: false,
            draggable: false,
            map: map,
          });

          // Set the drawnShapeData for update mode since center is not saved while post/save
          drawnShapeData.current = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [center.lng, center.lat],
            },
            properties: {
              name: geoJSON.properties.name,
              push_notification_tag: geoJSON.properties.push_notification_tag,
              radius: radius,
              center: { lng: center.lng, lat: center.lat },
              type: "Circle",
              priority: geoJSON.properties.priority,
            },
            uid: geoJSON.uid,
          };

          // Add the shape to the update mode shapes state
          setDrawnShapes.current.push(shape);
        } else if (type === "Polygon") {
          // Handle drawing a polygon
          const polygonCoordinates = coordinates[0].map(coord => ({
            lat: coord[1],
            lng: coord[0],
          }));

          // Create a polygon on the map
          const shape = new window.google.maps.Polygon({
            paths: polygonCoordinates,
            editable: false,
            draggable: false,
            map: map,
          });
          // Add the shape to the update mode shapes state
          setDrawnShapes.current.push(shape);
        }
      }
    }
  }, [geoJSON, map]);

  // useEffect for Add Circle & Polygon
  useEffect(() => {
    const addButtonPolygon = document.getElementById("add-polygon");
    const addButtonCircle = document.getElementById("add-circle");

    if (addButtonPolygon && addButtonCircle) {
      const isDisabled =
        map && drawnShapeData.current && drawnShapeData.current.properties;

      addButtonPolygon.classList.toggle("disabled", isDisabled);
      addButtonCircle.classList.toggle("disabled", isDisabled);

      addButtonPolygon.setAttribute("disabled", isDisabled);
      addButtonCircle.setAttribute("disabled", isDisabled);

      if (!isDisabled) {
        document.querySelectorAll(".action-button").forEach(node => {
          node.style.backgroundColor = "";
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawnShapeData.current, map, geoJSON]);

  // Delete button useEffect
  useEffect(() => {
    const deleteButton = document.getElementById("delete-button");

    if (deleteButton) {
      const shouldDisable = !(
        map &&
        drawnShapeData.current &&
        drawnShapeData.current.uid &&
        drawnShapeData.current.properties
      );
      deleteButton.disabled = shouldDisable;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawnShapeData, map]);

  //useEffect for Clear & Edit Btn
  useEffect(() => {
    const updateButtonState = (buttonId, isDisabled) => {
      const button = document.getElementById(buttonId);
      if (button) {
        if (isDisabled) {
          button.setAttribute("disabled", "disabled");
        } else {
          button.removeAttribute("disabled");
        }
      }
    };

    // Update clear button state
    updateButtonState("clear-button", isClearButtonDisabled);

    // Update edit button state
    updateButtonState("edit-button", isEditButtonDisabled);
  }, [isClearButtonDisabled, isEditButtonDisabled]);

  // Save Button useEffect
  useEffect(() => {
    const saveButton = document.getElementById("save-geoFence-button");

    if (map && saveButton) {
      const isDisabled =
        !drawnShapeData.current ||
        (!drawnShapeData.current.uid && !drawnShapeData.current.properties);
      saveButton.disabled = isDisabled;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawnShapeData.current, map]);

  //Actions
  useEffect(() => {
    if (map) {
      //Lat Lng Calculate on map
      const coordinateControl = createLatitudeAndLongitudeControl({ map });
      coordinateControl.index = 1;
      map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(
        coordinateControl,
      );

      //Add Polygon Button
      const polygonDiv = document.createElement("div");
      ReactDOM.render(
        <AddPolygonButton geoJSON={geoJSON} onToggleClick={changeMapMode} />,
        polygonDiv,
      );
      polygonDiv.style.display = "block"; // Set display to block
      polygonDiv.style.marginTop = "10px";
      polygonDiv.style.marginLeft = "10px";
      polygonDiv.index = 1;
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        polygonDiv,
      );

      //Add Circle Button
      const circleDiv = document.createElement("div");
      ReactDOM.render(
        <AddCircleButton geoJSON={geoJSON} onToggleClick={changeMapMode} />,
        circleDiv,
      );
      circleDiv.style.display = "block"; // Set display to block
      circleDiv.style.marginLeft = "10px";
      circleDiv.index = 1;
      map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(circleDiv);

      //Edit, Delete & Clear Buttons
      const controlDiv = document.createElement("div");
      ReactDOM.render(
        <EditClearDeleteButtonControl geoJSON={geoJSON} />,
        controlDiv,
        function () {
          controlDiv
            .querySelector("#edit-button")
            .addEventListener("click", function (e) {
              e.preventDefault();
              editGeoFenceHandler();
            });
          controlDiv
            .querySelector("#clear-button")
            .addEventListener("click", function (e) {
              e.preventDefault();
              clearGeoFenceHandler();
            });
          controlDiv
            .querySelector("#delete-button")
            .addEventListener("click", function (e) {
              e.preventDefault();
              openModalHandler("showDelete");
            });
        },
      );
      controlDiv.index = 1;
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        controlDiv,
      );

      //Geofence API Save & Cancel Buttons
      const controlSaveCancel = document.createElement("div");
      ReactDOM.render(
        <SaveCancelGeoFenceButtonControl geoJSON={geoJSON} />,
        controlSaveCancel,
        function () {
          controlSaveCancel
            .querySelector("#save-geoFence-button")
            .addEventListener("click", function (e) {
              e.preventDefault();
              saveGeoFenceHandler();
            });
          controlSaveCancel
            .querySelector("#cancel-geoFence-button")
            .addEventListener("click", function (e) {
              e.preventDefault();
              cancelGeoFenceHandler();
            });
        },
      );
      controlSaveCancel.index = 1;
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        controlSaveCancel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <>
      {showLoader && <Loader />}
      <div className="search-bar">
        <Search placeholder="Search for a place" />
      </div>
      <div ref={mapRef} style={mapDivStyle} id="map" />
      <Modal
        className="small"
        data-testid="delete-modal"
        open={showDelete}
        handleClose={handleModalClose}
        title="Are you sure you want to Delete the 'GeoFence'?"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "No",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "no_btn",
            },
          },
          {
            id: "2",
            name: "Yes",
            handler: event => {
              return deleteGeoFenceHandler(event);
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "yes_btn",
            },
          },
        ]}
      ></Modal>
      <FeatureModal
        cancelModalHandler={cancelModalHandler}
        onSubmitHandler={submitModalStateHandler}
        modalState={featureModalState}
      />
    </>
  );
}
export default Map;
