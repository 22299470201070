import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import MuiBox from "@material-ui/core/Box";
import MuiDivider from "@material-ui/core/Divider";
import MuiSwitch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import styled, { keyframes } from "styled-components";

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
 `;

export const FieldWrap = styled.div`
  display: flex;
  position: relative;
  padding: 15px 0;
  &:last-child {
    padding-bottom: 0;
  }
  .MuiFormLabel-root {
    font-size: 16px;
    line-height: 19px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
  }
  .MuiInputLabel-shrink {
    font-size: 13px;
    transform: translate(14px, -6px) scale(0.75);
  }
  &.field {
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 13px 9px;
      height: auto;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      line-height: 1.3;
    }
    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.38);
      border-radius: 8px;
    }
    &.Mui-disabled {
      color: ${props => props.theme.palette.grey.grey9E9E9E};
    }
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .MuiRadio-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grp-label {
    display: flex;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 26px;
    font-weight: 400;
    margin: 0 0 20px;
  }
  .sub-label {
    display: flex;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0 0 15px;
  }
  .radio-fields {
    margin-bottom: 25px;
    .radio-content {
      margin-top: 10px;
      margin-left: 30px;
      .datetime-picker {
        width: 100%;
      }
    }
    .field {
      padding-bottom: 0;
      padding-top: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .preview {
    margin: 0 auto;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 200px;
      height: 400px;
    }
  }
`;

export const DashedDivider = styled(MuiDivider)`
  background-color: transparent;
  border-bottom: 1px dashed ${props => props.theme.palette.grey.grey200};
  margin: 28px 0;
`;

export const Badge = styled.span`
  width: 20px;
  height: 20px;
  background: ${props => props.theme.palette.blue.blue100};
  border-radius: 50%;
  color: ${props => props.theme.sidebar.background};
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
`;

export const NoData = styled.div`
  background: ${props => props.theme.palette.white.main};
  padding: 50px;
  text-align: center;
  box-shadow: 0px 0px 14px rgb(53 64 82 / 5%);
  border-radius: 6px;
  font-size: 16px;
  &.no-data {
    background: ${props => props.theme.palette.white.white100};
    padding: 27px;
    box-shadow: none;
    border-radius: 0;
  }
  p {
    margin: 0;
  }
  .MuiButton-root {
    color: ${props => props.theme.palette.black.blackDark};
    font-size: 14px;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const FormWrapper = styled(Paper)`
  border: 0;
  padding: 24px;
  box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  margin-bottom: 10px;
  .maxWidthSm {
    width: 65%;
    > div:last-child {
      margin-bottom: 0;
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 100%;
    }
  }
  &.feature-modal-form {
    padding: 24px 24px 0;
    form {
      padding: 0;
    }
  }
  .MuiFormLabel-asterisk {
    color: ${props => props.theme.palette.red.main};
  }
  .button {
    margin: 5px;
  }
  .error-text {
    color: ${props => props.theme.palette.red.main};
  }
  .d-flex {
    display: flex;
    flex-direction: row;
  }
  .accordion-wrap {
    margin-bottom: 0;
  }
  .url-view {
    position: relative;
    flex: 1;
    .MuiInputBase-input {
      padding-right: 43px;
    }
    .close-icon {
      position: absolute;
      right: 6px;
      top: 9px;
      padding: 4px;
    }
  }
  .img-upload-panel {
    margin-bottom: 30px;
    .url-view {
      > div {
        margin-bottom: 0;
      }
    }
  }
  .editor-wrapper {
    .grp-heading {
      background: ${props => props.theme.palette.white.white200};
      min-height: 60px;
      margin: 0 0 0 -12px;
      width: calc(100% + 12px);
      padding: 0 12px;
    }
    .grp-content {
      margin: 14px 0 0;
      width: 100%;
    }
  }
  .suneditor {
    width: 100%;
    .se-toolbar {
      outline: 0;
      border-bottom: 1px solid rgb(218, 218, 218);
    }
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
    padding: 4px 0 8px;
    > div {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .field-wrapper {
      animation: ${slideDown} 0.2s ease-in-out;
    }
  }
  .MuiAccordion-root {
    box-shadow: none;
    .MuiCollapse-wrapper {
      margin-top: 20px;
    }
    .MuiFormControl-marginNormal {
      margin-top: 0;
    }
  }
  .MuiAccordionSummary-root {
    min-height: inherit;
    margin-left: 0;
    padding-left: 12px;
    padding-right: 20px;
    border: 0;
    border-radius: 0;
    button.MuiButtonBase-root,
    .MuiAccordionSummary-expandIcon {
      margin-left: 16px;
      padding: 0;
      color: rgba(0, 0, 0, 0.87);
      &.add-icon {
        svg {
          font-size: 16px;
        }
      }
      svg {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .MuiAccordionSummary-content {
      padding: 19px 0;
      margin: 0;
      .MuiFormLabel-root {
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
        -webkit-line-clamp: 1;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .delete-icon {
        height: 18px;
      }
      &.Mui-expanded {
        padding: 20px 0;
      }
    }
  }
  &.settings-wrap {
    > div:last-child {
      margin-bottom: 0;
    }
  }
  .tree-wrapper {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    .MuiOutlinedInput-root.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.palette.red.main};
      }
    }
    .Mui-error.MuiFormLabel-root {
      color: ${props => props.theme.palette.red.main};
    }
    .MuiFormHelperText-root.Mui-error {
      color: ${props => props.theme.palette.red.main};
    }
    .Mui-focused .MuiFormLabel-asterisk {
      color: ${props => props.theme.palette.red.main};
    }
  }
  .MuiAutocomplete-tag {
    .MuiChip-label {
      max-width: 450px;
      ${({ theme }) => theme.breakpoints.down("sm")} {
        max-width: 330px;
      }
    }
  }
`;

export const Autocomplete = styled(MuiAutocomplete)`
  &.MuiAutocomplete-root {
    .MuiChip-root {
      svg {
        width: 15px;
        height: 15px;
      }
    }
    .MuiInputBase-input {
      padding: 5px 9px;
    }
    .MuiAutocomplete-tag {
      max-width: 100%;
      height: auto;
      background: ${props => props.theme.palette.blue.blue100};
      color: ${props => props.theme.sidebar.background};
      border-radius: 20px;
      margin: 4px 8px 4px 0;
      padding: 2px 8px 3px;
      border: 0;
      font-size: 10px;
      line-height: 14px;
      position: relative;
      &:after {
        content: "×";
        font-size: 16px;
        color: ${props => props.theme.sidebar.background};
        position: absolute;
        right: 11px;
        top: 2px;
      }
      span {
        white-space: normal;
        padding-left: 0;
        color: ${props => props.theme.sidebar.background};
        font-weight: 600;
      }
      .MuiSvgIcon-root {
        margin-right: 0;
        width: 16px;
        position: relative;
        z-index: 9;
        path {
          display: none;
        }
      }
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 10px 45px 10px 7px;
      .MuiAutocomplete-input {
        padding: 3px 0;
      }
    }
  }
`;

export const TabBox = styled(MuiBox)``;

export const AccordionIconWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadImgPanel = styled(MuiBox)`
  display: flex;
  align-items: center;
  width: 100%;
  .field {
    padding: 0;
    max-width: 100%;
    margin-left: 16px;
  }
  span {
    color: rgba(0, 0, 0, 0.87);
  }
  fieldset,
  .choose-btn {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
  }
  .choose-btn {
    font-size: 16px;
    padding: 12px 9px;
    color: rgba(0, 0, 0, 0.54);
    min-width: 177px;
    margin-right: 15px;
  }
  .upload-btn {
    font-weight: 600;
    padding: 0;
    margin: 0 15px;
    line-height: inherit;
    &:hover {
      background-color: transparent;
    }
    span {
      color: ${props => props.theme.palette.grey.grey800};
    }
  }
  .MuiInputBase-input {
    padding: 12px 9px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    line-height: 20px;
  }
  .input-actions {
    position: absolute;
    background: ${props => props.theme.palette.white.white100};
    z-index: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    right: 0;
    height: 32px;
    display: flex;
    min-width: 64px;
    justify-content: space-between;
    .MuiIconButton-root {
      padding: 8px 6px;
      &:hover {
        background-color: transparent;
        svg {
          color: ${props => props.theme.palette.black.blackDark};
        }
      }
    }
  }
  .img_url_input {
    position: relative;
    flex-grow: 1;
    div:first-child {
      margin-bottom: 0;
    }
    .MuiTextField-root {
      width: 100%;
    }
    svg {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.54);
      width: 19px;
      height: 19px;
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    .choose-btn,
    .upload-btn,
    .field {
      margin: 0;
      min-width: 100%;
    }
  }
`;

export const FluidTabs = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  form {
    padding: 0 24px;
  }
  .multi-cred-main {
    hr {
      border: 0.5px solid ${({ theme }) => theme.palette.grey.grey300};
    }
    div:first-child hr {
      display: none;
    }

    .multi-cred-field-title {
      padding: 15px 0px 0px 15px;
      .MuiTypography-root {
        font-size: 16px;
      }
    }
  }
`;

export const CustomFlexBox = styled(MuiBox)`
  display: flex;
  align-items: center;
  width: 100%;
  .MuiIconButton-root {
    padding-top: 0;
    padding-bottom: 0;
    &:hover {
      background-color: transparent;
    }
  }
  &.alignItemsTop {
    align-items: flex-start;
  }
  &.grp-heading {
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    .lbl {
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const Switch = styled(MuiSwitch)`
  &.MuiSwitch-root {
    &.read-only {
      .Mui-checked {
        &.Mui-disabled {
          color: ${props => props.theme.palette.grey.grey800};
          opacity: 0.5;
        }
        &.Mui-disabled + .MuiSwitch-track {
          background-color: ${props => props.theme.palette.grey.grey800};
        }
      }
    }
    .MuiSwitch-thumb {
      box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const ImageUploaderWrap = styled.div`
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  .url-view {
    > div {
      margin-bottom: 0;
    }
  }
`;
export const KeyValueWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .wrap {
    flex: 1;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
  .del-icon {
    margin-bottom: 30px;
    margin-left: 5px;
  }
  > div {
    width: 100%;
  }
  &:last-child {
    .field-wrapper {
      margin-bottom: 0;
    }
    .del-icon {
      margin-bottom: 0;
    }
  }
`;
