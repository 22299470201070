import AceEditor from "react-ace";
import React from "react";
import styled from "styled-components";

import "ace-builds/webpack-resolver";
import Ace from "ace-builds/src-noconflict/ace";
Ace.config.set("basePath", "path");

const StyledAceEditor = styled(AceEditor)`
  &.ace_editor {
    border: 1px solid #e0e0e0;
    border-top: 12px solid #e0e0e0;
    border-radius: 4px;
  }
`;

export default function JsonEditor({ value, onChange, onError, ...restProps }) {
  const handleError = node => {
    if (node.length) {
      onError(node.filter(err => err.type === "error"));
    }
    onError([]);
  };

  return (
    <StyledAceEditor
      style={{ maxHeight: "200px" }}
      width="100%"
      mode="json"
      theme="xcode"
      name="json_code_editor"
      onValidate={handleError}
      fontSize={14}
      editorProps={{ $blockScrolling: true }}
      value={value}
      onChange={onChange}
      showPrintMargin={false}
      showGutter={true}
      setOptions={{ tabSize: 2 }}
      highlightActiveLine={true}
      {...restProps}
    />
  );
}
