import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={59}
      height={65}
      viewBox="0 0 59 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.521 42.204v15.014c0 4.303-3.487 7.782-7.8 7.782H7.8C3.487 65 0 61.521 0 57.218V7.782C0 3.479 3.487 0 7.8 0h31.013c1.835 0 3.67.64 5.047 1.923.826.732 1.468 1.647 1.927 2.563.367.824 0 1.74-.734 2.106-.184.091-.459.183-.642.183a1.635 1.635 0 01-1.468-.916c-.276-.55-.643-1.098-1.193-1.556-.826-.733-1.927-1.099-3.028-1.099H7.799c-2.569 0-4.587 2.106-4.587 4.578v49.436c0 2.564 2.11 4.578 4.587 4.578h31.014c2.57 0 4.588-2.106 4.588-4.578V42.204c0-.915.734-1.556 1.56-1.556.826 0 1.56.732 1.56 1.556zM23.306 59.232a3.481 3.481 0 003.487-3.478 3.481 3.481 0 00-3.487-3.48 3.481 3.481 0 00-3.486 3.48 3.481 3.481 0 003.486 3.478zM59 14.922v15.38c0 2.564-2.294 4.67-5.047 4.67H33.95l-6.423 5.767c-.275.275-.642.367-1.1.367-.184 0-.46 0-.643-.184a1.63 1.63 0 01-.918-1.464V14.923c0-2.564 2.294-4.67 5.047-4.67h24.132c2.661.092 4.955 2.106 4.955 4.67zm-3.212 0c0-.823-.825-1.464-1.835-1.464h-24.04c-1.01 0-1.835.64-1.835 1.464V35.98l4.22-3.754c.276-.274.643-.366 1.102-.366h20.645c1.01 0 1.835-.64 1.835-1.465V14.923h-.092z"
        fill="#1E88E5"
      />
    </svg>
  );
}

export default SvgComponent;
