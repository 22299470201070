import React from "react";

const PushNotification = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M20.1667 8.23171V16.5C20.1667 17.5084 19.3417 18.3334 18.3334 18.3334H3.66671C2.65837 18.3334 1.83337 17.5084 1.83337 16.5L1.84254 5.50004C1.84254 4.49171 2.65837 3.66671 3.66671 3.66671H12.925C12.87 3.96004 12.8334 4.27171 12.8334 4.58337C12.8334 4.89504 12.87 5.20671 12.925 5.50004H3.66671L11 10.0834L14.3642 7.98421C14.795 8.37837 15.2992 8.68087 15.8584 8.88254L11 11.9167L3.66671 7.33337V16.5H18.3334V9.07504C19.0117 8.93754 19.635 8.63504 20.1667 8.23171ZM14.6667 4.58337C14.6667 6.10504 15.895 7.33337 17.4167 7.33337C18.9384 7.33337 20.1667 6.10504 20.1667 4.58337C20.1667 3.06171 18.9384 1.83337 17.4167 1.83337C15.895 1.83337 14.6667 3.06171 14.6667 4.58337Z"
          fill="#EEEEEE"
        />
      </g>
    </svg>
  );
};
export default PushNotification;
