import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Team Stats",
    uid: "teamStats",
    _schema: [
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "Games Played",
        uid: "gp",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Minutes",
        uid: "min",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Minutes per game",
        uid: "mpg",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Points",
        uid: "pts",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Assists",
        uid: "ast",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Rebounds",
        uid: "reb",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Field Goal Percentage",
        uid: "fgp",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Value Made",
            uid: "ma",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Value attempted",
            uid: "at",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value per game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value made",
            uid: "oma",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value attempted",
            uid: "oat",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Three Pointers Percentage",
        uid: "tpp",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Value Made",
            uid: "ma",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Value attempted",
            uid: "at",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value per game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value made",
            uid: "oma",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value attempted",
            uid: "oat",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Free throw Percentage",
        uid: "ftp",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Value Made",
            uid: "ma",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Value attempted",
            uid: "at",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value per game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value made",
            uid: "oma",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent value attempted",
            uid: "oat",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Blocks",
        uid: "blk",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Steals",
        uid: "stl",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Turnovers",
        uid: "tov",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Fouls",
        uid: "pf",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Offensive Rebounds",
        uid: "oreb",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Defensive Rebounds",
        uid: "dreb",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Value",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "League Rank",
            uid: "lr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Conference Rank",
            uid: "cr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Division Rank",
            uid: "dr",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Total Team value",
            uid: "tot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Team Value Per Game",
            uid: "oval",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opponent Total Team Value",
            uid: "otot",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
