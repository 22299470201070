import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const MUIGrid = styled(Grid)`
  &.MuiGrid-item {
    border-right: 1px solid ${({ theme }) => theme.palette.grey.grey300};
    padding: 10px 15px;
    padding-left: 30px;
    &:first-child {
      padding-left: 15px;
      padding-right: 30px;
    }
    &:last-child {
      border-right: 0;
    }
    .labelTitle {
      text-transform: capitalize;
    }
  }
`;
