import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { Link, Route, Switch } from "react-router-dom";
import { Container as ScreenTable } from "app/pages/Dashboard/Data/Device/Onboardings/Screens/get/ScreenTable";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useState } from "react";
import Settings from "./Settings";

import { Constants } from "utils/constants";
import { useQueryParams } from "app/hooks";
import { useSelector } from "react-redux";

export default function Screen({ match, history, location }) {
  let tabObj = { screens: 0, settings: 1 };
  const tab = tabObj[location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }
  const [refresh, setRefresh] = useState(false);
  const { sport_key, env_key } = match.params;
  const { access_type } = useSelector(state => state.application);
  const { searchtext } = useQueryParams();

  return (
    <>
      <PanelHeader
        title="Device Onboarding Screens"
        breadCrumbItems={[
          {
            title: "Device Onboarding Screens",
          },
        ]}
        refreshHandler={() => {
          setRefresh(true);
        }}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            data-testid="add-btn"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/device/onBoarding/screens/add`,
                state: { from: location },
              });
            }}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchtext && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
        >
          <Tab
            label="Screens"
            component={Link}
            to={`${match.url}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <Switch>
        <>
          <TabPanel>
            <Route path={`${match.path}/settings`} component={Settings} exact />
            <Route
              path={`${match.path}`}
              render={routeProps => (
                <ScreenTable
                  {...routeProps}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  access_type={access_type}
                />
              )}
              exact
            />
          </TabPanel>
        </>
      </Switch>
    </>
  );
}
