import React from "react";

const Reports = () => {
  return (
    <svg
      width={17}
      height={20}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={0.5}>
        <path
          d="M14.546 2.222v15.556H1.818V2.222h12.728zm0-2.222H1.818C.818 0 0 1 0 2.222v15.556C0 19 .818 20 1.818 20h12.728c1 0 1.818-1 1.818-2.222V2.222C16.364 1 15.545 0 14.545 0z"
          fill="#EEE"
        />
        <path
          d="M11.83 5.028L8.196 9.574 6.8 7.677 4.5 10"
          stroke="#EEE"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.558 15.028a1 1 0 102 0h-2zm2-.909a1 1 0 00-2 0h2zm0 .91v-.91h-2v.91h2zM7.195 15.028a1 1 0 102 0h-2zm2-2.028a1 1 0 10-2 0h2zm0 2.028V13h-2v2.028h2zM10.831 15.028a1 1 0 102 0h-2zm2-4.26a1 1 0 00-2 0h2zm0 4.26v-4.26h-2v4.26h2z"
          fill="#EEE"
        />
      </g>
    </svg>
  );
};
export default Reports;
