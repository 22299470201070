import {
  Box,
  Card,
  CardContent,
  MUITypography,
  SelectMsg,
  TopGrid,
} from "app/pages/Dashboard/pushNotification/Messages/get/SelectMessage.style.js";
import { deleteConfirmationMessage } from "utils/functions";
import { Grid } from "@material-ui/core";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import React, { Fragment } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

export const TableView = props => {
  return (
    <Fragment>
      {props.isLoading ? (
        <SkeletonTable
          headings={props.headings}
          checkbox={props.checkbox}
          name={props.name}
          actions={props.actions}
        />
      ) : props.totalCount > 0 ? (
        <Table {...props} />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <SelectMsg
        open={props.dialogOpen}
        handleClose={props.handleModalClose}
        title="Select Message Type"
        className="text"
      >
        <IconButton aria-label="close" onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
        <TopGrid container className="cards-container">
          {props.messageModal.map(item => {
            return (
              <Grid item md={4} sm={4} xs={12} className="cards" key={item.id}>
                <Card
                  onClick={item.handler}
                  data-testid={item.testid}
                  variant="outlined"
                >
                  <CardContent>
                    <Box component="div">{item.icon}</Box>
                    <MUITypography
                      className="card-title"
                      gutterBottom
                      variant="h5"
                    >
                      {item.title}
                    </MUITypography>
                    <MUITypography gutterBottom variant="body1" align="center">
                      {item.content}
                    </MUITypography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </TopGrid>
      </SelectMsg>
      <Modal
        className="small"
        open={props.openModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: props.handleModalDelete,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: props?.deleteData?.title,
          module: props?.name,
        })}
      </Modal>
      <Modal
        className="small"
        open={props.archiveModal}
        handleClose={props.archiveModalClose}
        title="Archive Inbox"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.archiveModalClose },
          {
            id: "2",
            name: "Archive",
            handler: props.handleArchiveModal,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        Are you sure, you want to archive{" "}
        <strong>"{props?.deleteData?.title}"</strong>?
      </Modal>
    </Fragment>
  );
};
