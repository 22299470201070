import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import WebhookForm from "../WebhookForm";

const AddWebhooks = props => {
  // redux hooks
  const dispatch = useDispatch();
  // react router props
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/webhooks`;

  const handleSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: `/v1/admin/webhooks/?sport_key=${sport_key}&env_key=${env_key}`,
          key: "webhook",
          form: {
            url: data.url,
            method: data.method.toUpperCase(),
            headers: JSON.parse(data.headers),
            channels: data.webhookgroup.channel,
          },
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Webhook"
        backUrl={getURL}
        breadCrumbItems={[
          {
            title: "Webhooks",
          },
        ]}
      />
      <WebhookForm
        onSubmit={handleSubmit}
        sport_key={sport_key}
        env_key={env_key}
        location={props.location}
      />
    </>
  );
};

export default AddWebhooks;
