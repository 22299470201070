import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddParkingAndDirections = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getUrl = `/dashboard/${sport_key}/${env_key}/data/arena/parkingAndDirections`;

  const onSubmit = async data => {
    try {
      const form = {
        ...data,
        device_type: "ios",
      };
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/app/parking_and_directions/",
          key: "parking_and_direction",
          form,
        }),
      );
      props.history.push(getUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Parking And Directions"
        breadCrumbItems={[
          {
            title: "Add Parking And Directions",
          },
        ]}
        backUrl={getUrl}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="parking_and_directions"
        backUrl={getUrl}
      />
    </>
  );
};

export default AddParkingAndDirections;
