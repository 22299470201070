import React from "react";

const Content = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M16.5455 2.22222V17.7778H3.81818V2.22222H16.5455ZM16.5455 0H3.81818C2.81818 0 2 1 2 2.22222V17.7778C2 19 2.81818 20 3.81818 20H16.5455C17.5455 20 18.3636 19 18.3636 17.7778V2.22222C18.3636 1 17.5455 0 16.5455 0Z"
          fill="white"
        />
        <path
          d="M7.45453 6.36368C7.45453 6.86576 7.04752 7.27277 6.54544 7.27277C6.04337 7.27277 5.63635 6.86576 5.63635 6.36368C5.63635 5.8616 6.04337 5.45459 6.54544 5.45459C7.04752 5.45459 7.45453 5.8616 7.45453 6.36368Z"
          fill="white"
        />
        <path
          d="M7.45453 10C7.45453 10.5021 7.04752 10.9091 6.54544 10.9091C6.04337 10.9091 5.63635 10.5021 5.63635 10C5.63635 9.49796 6.04337 9.09094 6.54544 9.09094C7.04752 9.09094 7.45453 9.49796 7.45453 10Z"
          fill="white"
        />
        <path
          d="M7.45453 13.6364C7.45453 14.1385 7.04752 14.5455 6.54544 14.5455C6.04337 14.5455 5.63635 14.1385 5.63635 13.6364C5.63635 13.1343 6.04337 12.7273 6.54544 12.7273C7.04752 12.7273 7.45453 13.1343 7.45453 13.6364Z"
          fill="white"
        />
        <path
          d="M14.7273 6.36368C14.7273 6.86576 14.3202 7.27277 13.8182 7.27277H10.1818C9.67972 7.27277 9.27271 6.86576 9.27271 6.36368C9.27271 5.8616 9.67972 5.45459 10.1818 5.45459H13.8182C14.3202 5.45459 14.7273 5.8616 14.7273 6.36368Z"
          fill="white"
        />
        <path
          d="M14.7273 10C14.7273 10.5021 14.3202 10.9091 13.8182 10.9091H10.1818C9.67972 10.9091 9.27271 10.5021 9.27271 10C9.27271 9.49796 9.67972 9.09094 10.1818 9.09094H13.8182C14.3202 9.09094 14.7273 9.49796 14.7273 10Z"
          fill="white"
        />
        <path
          d="M14.7273 13.6364C14.7273 14.1385 14.3202 14.5455 13.8182 14.5455H10.1818C9.67972 14.5455 9.27271 14.1385 9.27271 13.6364C9.27271 13.1343 9.67972 12.7273 10.1818 12.7273H13.8182C14.3202 12.7273 14.7273 13.1343 14.7273 13.6364Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default Content;
