export const checkboxSingleClick = ({ selected, dataId }) => {
  const selectedIndex = selected.indexOf(dataId);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, dataId);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  return newSelected;
};

export const checkboxSelectAllClick = ({ event, data }) => {
  if (event.target.checked) {
    const newSelecteds = data.map(dataItem => dataItem.uid);
    return newSelecteds;
  }
  return [];
};
