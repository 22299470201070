import { createRecord } from "services/createObject";
import { dobErrorHandler, dobValidation } from "utils/functions";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddCoaches = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/rosters/coaches`;

  const onSubmit = async data => {
    try {
      let form = data;
      /* istanbul ignore else */
      if (form.dob && typeof data.dob !== "string") {
        dobValidation({ data });
        form = {
          ...form,
          dob: format(data.dob, "yyyy-MM-dd"),
        };
      }
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/coaches",
          key: "coach",
          form,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*  istanbul ignore next   */
      dobErrorHandler({ error, dispatch });
      console.log(error.message);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Coaches"
        breadCrumbItems={[
          {
            title: "Add Coaches",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="coaches"
        backUrl={getURL}
      />
    </>
  );
};

export default AddCoaches;
