import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "../schemaForm";

const UpdateDropAnnouncement = props => {
  const [announcementData, setAnnouncementData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/drops/v1/announcements`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/admin/drops/portal/announcements/${uid}`;
    const {
      data: { announcements },
    } = await dispatch(get({ url }));
    setAnnouncementData(announcements);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/admin/drops/portal/announcements/${uid}`,
          key: "announcements",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Drop Announcement"
        breadCrumbItems={[
          {
            title: "Update Drop Announcement",
          },
        ]}
        backUrl={getURL}
      />

      {announcementData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={announcementData}
          schema={schema}
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateDropAnnouncement;
