import { Button, Preview } from "@dfep/ui-component";
import { capitalize } from "utils/functions";
import {
  DashedDivider,
  FieldGroup,
  FormWrapper,
  ImageUploaderWrap,
} from "styles/global.style";
import { Container as ImageUploader } from "./upload/imageUpload";
import { PUSH_AUDIENCE, PUSH_STATUS, PUSH_TYPE } from "utils/constants";
import { useForm, useWatch } from "react-hook-form";
import Accordin from "app/components/Common/Form/Accordion";
import CheckBox from "app/components/Common/Form/Checkbox";
import computedStyleToInlineStyle from "utils/cssClassToInline";
// import Drawer from "app/pages/Dashboard/pushNotification/Messages/reports/Drawer";
import FieldBorder from "app/components/Common/FieldBorder ";
import JsonEditor from "app/components/Common/Form/jsonEditor";
import Label from "app/components/Common/Label";
import PanelFooter from "app/components/Common/PanelFooter";
import RadioGroup from "./RadioGroup";
import React, { Fragment, useState } from "react";
import RTEEditor from "app/pages/Dashboard/pushNotification/Messages/form/RichTextEditor";
import SelectField from "app/components/Common/Form/SelectField";
import TextField from "app/components/Common/Form/TextField";

const IsolateReRender = ({ control, imageUrl, team_name, title, body }) => {
  const meaageTitle = useWatch({
    control,
    name: "message.title",
    defaultValue: title ?? "",
  });
  const messageBody = useWatch({
    control,
    name: "message.body",
    defaultValue: body ?? "",
  });

  return (
    <Preview
      team={capitalize(team_name)}
      title={meaageTitle}
      body={messageBody}
      avatar={imageUrl}
    />
  ); // only re-render at the component level, when message changes
};

const MessageForm = props => {
  const [imageUrl, setImageUrl] = useState();
  const [iconUrl, setIconUrl] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const { type, uid, push_provider } = props;

  const MessageForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      schedule_date: props?.push_notification?.payload?.schedule_date ?? null,

      message: {
        title:
          props?.push_notification?.payload?.message?.title ??
          props?.push_notification?.title?.data ??
          "",
        subtitle:
          props?.push_notification?.payload?.message?.subtitle ??
          props?.push_notification?.subtitle?.data ??
          "",
        body:
          props?.push_notification?.payload?.message?.body ??
          props?.push_notification?.body?.data ??
          "",
        click_action: {
          type:
            props?.push_notification?.payload?.message?.click_action?.type ??
            "web_link",
          data:
            props?.push_notification?.payload?.message?.click_action?.data ??
            props?.push_notification?.click_action ??
            "",
        },
      },
      settings: {
        badge:
          props?.push_notification?.payload?.message?.badge ??
          props?.push_notification?.badge ??
          "",
        sound:
          props?.push_notification?.payload?.message?.sound ??
          props?.push_notification?.sound ??
          "",
        icon_color:
          props?.push_notification?.payload?.message?.icon_color ??
          props?.push_notification?.icon_color ??
          "",
        deeplink_url:
          props?.push_notification?.payload?.message?.deeplink_url ??
          props?.push_notification?.deeplink_url ??
          "",
        collapse_key:
          props?.push_notification?.payload?.message?.collapse_key ??
          props?.push_notification?.collapse_key ??
          "",
      },
      inbox: {
        title: props?.push_notification?.payload?.inbox?.title ?? "",
      },
    },
  });
  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = MessageForm;

  const ImageState = img => {
    setImageUrl(img);
  };

  const IconState = img => {
    setIconUrl(img);
  };

  const submitFormHandler = async data => {
    /* istanbul ignore next */
    let RTEElement;
    /* istanbul ignore next */
    if (document.querySelector("#suneditor .sun-editor-editable")) {
      RTEElement = computedStyleToInlineStyle(
        document.querySelector("#suneditor .sun-editor-editable"),
        {
          recursive: true,
          properties: ["float"],
        },
      );
    }
    const {
      schedule_date,
      audience,
      AddToken,
      inbox,
      message,
      settings,
    } = data;

    const form = {
      payload: {
        uid:
          uid && props?.push_notification?.payload?.status === PUSH_STATUS.draft
            ? uid
            : undefined,

        message: {
          title: message?.title,
          subtitle: message?.subtitle,
          body: message?.body,
          click_action: message?.click_action?.type
            ? {
                type: message?.click_action?.type,
                data: message?.click_action?.data ?? "",
              }
            : undefined,
          badge: settings?.badge,
          collapse_key: settings?.collapse_key,
          deeplink_url: settings?.deeplink_url,
          icon_color: settings?.icon_color,
          silent_push: settings?.silent_push,
          sound: settings?.sound,
          data: settings?.jsonEditor
            ? JSON.parse(`${settings?.jsonEditor}`)
            : undefined,
          image_url: message?.image_url,
          icon_url: message?.icon_url,
          buttons: [],
        },
        inbox: {
          title: inbox?.title,
          body: RTEElement?.innerHTML,
        },
        type,
        schedule_date: schedule_date ? Date.parse(schedule_date) : null,
      },
      audience: {
        type: audience === PUSH_AUDIENCE.default ? AddToken?.set : audience,
        data: AddToken.uid,
      },
    };

    /* istanbul ignore else */
    if (type === PUSH_TYPE.inbox) {
      delete form.payload.message;
    } else if (type === PUSH_TYPE.message) {
      delete form.payload.inbox;
    }

    if (isDraft) {
      await props.DraftPushMsg(form);
      setIsDraft(false);
    } else {
      await props.AddPushMsg(form);
    }
  };

  const radioProps = {
    name: "audience",

    validations: {
      required: "Select Audience required",
    },
    options: [
      {
        label: "Send To All (default topic)",
        value: "default",
        uid: "test-default",
        auto: true,
      },
      {
        label: "Send to Segment",
        value: "segment",
        uid: "test-segment",
        auto: true,
      },
      {
        label: "Send to Topic",
        value: "topic",
        uid: "test-topic",
        auto: true,
      },

      {
        label: "Send to User",
        value: "personal_id",
        uid: "test-personal_id",
        auto: true,
      },
      {
        label: "Send to Device",
        value: "device_token",
        uid: "test-device_token",
        auto: true,
      },
    ],
    uid: "test-radio",
    showBorder: false,
    readOnly: false,
    defaultValue: props?.push_notification?.audience?.type ?? "default",
  };

  const TitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Title is required",
      maxLength: {
        value: 120,
        message: "exceeds max length. [120 MAX CHARACTER]",
      },
    },
    fieldName: "message.title",
    uid: "Title-textFiled",
    title: "Title",
    type: "text",
    className: "title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const SubTitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Sub Title is required",
      maxLength: {
        value: 200,
        message: "exceeds max length. [200 MAX CHARACTER]",
      },
    },
    fieldName: "message.subtitle",
    uid: "sub-title-textFiled",
    title: " Sub Title",
    type: "text",
    className: "sub-title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const BodyTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Body is required",
      maxLength: {
        value: 300,
        message: "exceeds max length. [300 MAX CHARACTER]",
      },
    },
    fieldName: "message.body",
    uid: "body-textFiled",
    title: "Body",
    type: "text",
    className: "body",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const SelectTextField = {
    uid: "test-select",
    title: "Click Action",
    control,
    fieldName: "message.click_action.type",
    validations: {},
    className: "select-field",
    readOnly: type === PUSH_TYPE.message ? true : false,
    options: [
      {
        label: "Inbox",
        value: "inbox",
      },
      {
        label: "WebLink",
        value: "web_link",
      },
    ],
  };

  const WebLinkProps = {
    watchInstance: "",
    watchOn: "",
    showBorder: false,
    control,
    validations: {},
    fieldName: "message.click_action.data",
    uid: "web_link-textFiled",
    title: "Enter WebLink",
    type: "text",
    className: "web_link-textFiled",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const AccordinProps = {
    uid: "test-accordion",
    title: "Advanced Settings",
    showBorder: true,
    fieldName: "settings",
    errors,
  };

  const SoundTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.sound",
    uid: "sound-textFiled",
    title: "Sound",
    type: "text",
    showBorder: false,
    className: "sound",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const CheckBoxProps = {
    watchInstance: {},
    watchOn: {},
    control,
    fieldName: "settings.silent_push",
    validations: {},
    showBorder: false,
    defaultValue:
      props?.push_notification?.payload?.message?.silent_push ??
      props?.push_notification?.silent_push ??
      false,
    className: "check",
    disabled: false,
    uid: "test-checkBox",
    title: "Silent Push",
  };

  const IconTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.icon_color",
    uid: "icon-textFiled",
    title: "Icon Color",
    type: "text",
    className: "icon",
    showBorder: false,
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const BadgeTextField = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.badge",
    uid: "Badge-textFiled",
    title: "Badge",
    type: "number",
    showBorder: false,
    className: "badge",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const DeepLinkTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.deeplink_url",
    uid: "deep-textFiled",
    title: "DeepLink",
    type: "text",
    showBorder: false,
    className: "deep_link",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const CollapseTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.collapse_key",
    uid: "collapse-textFiled",
    title: "Collapse key",
    type: "text",
    showBorder: false,
    className: "collapse_key",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const JsonEditorProps = {
    fieldName: "settings.jsonEditor",
    showBorder: true,
    defaultValue:
      JSON.stringify(
        props?.push_notification?.payload?.message?.data,
        null,
        "\t",
      ) ??
      JSON.stringify(props?.push_notification?.data, null, "\t") ??
      "{}",
    validations: {},
    control,
    uid: "test-json",
    title: "Data",
    setError,
  };

  const ScheduleProps = {
    name: "schedule",

    validations: {},
    options: [
      {
        label: "Begin sending immediately",
        value: "send",
        auto: false,
        uid: "test-send",
      },

      {
        label: "Begin sending at a particular time",
        value: "scheduled",
        uid: "test-schedule",
        date: true,
        auto: false,
      },
    ],
    uid: "test-schedule-radio",
    showBorder: false,
    readOnly: false,
    defaultValue:
      props?.push_notification?.payload?.schedule_date > new Date().getTime()
        ? PUSH_STATUS.scheduled
        : "send" ?? "send",
  };

  const InboxTitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Title is required",
      maxLength: {
        value: 200,
        message: "exceeds max length. [200 MAX CHARACTER]",
      },
    },
    fieldName: "inbox.title",
    uid: "inboxTitle-textFiled",
    title: "Enter Title",
    type: "text",
    showBorder: false,
    className: "Inbox-Title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  return (
    <Fragment>
      <FormWrapper>
        <form
          id="add_message"
          // className="maxWidthSm"
          noValidate
          data-testid="message_form"
          onSubmit={handleSubmit(submitFormHandler)}
        >
          <FieldGroup className="maxWidthSm">
            <Label className="grp-label">Audience</Label>
            <RadioGroup
              useForm={MessageForm}
              {...radioProps}
              push_provider={push_provider}
              uids={props?.push_notification?.audience?.data}
            />
          </FieldGroup>
          <DashedDivider />

          {type === PUSH_TYPE.inbox || type === PUSH_TYPE.message_inbox ? (
            <>
              <FieldGroup className="maxWidthSm">
                <Label className="grp-label">Inbox Setting</Label>
                <TextField {...InboxTitleTextfield} />
                {/* istanbul ignore next */}
                <RTEEditor
                  name="RTE"
                  control={control}
                  defaultValue={props?.push_notification?.payload?.inbox?.body}
                  placeholder="RTE Editor"
                  validations={{
                    required: "Field is Required",
                    validate: data => {
                      /* istanbul ignore next */
                      if (
                        data === "<p><br></p>" ||
                        data ===
                          `<p style="float: none;"><br style="float: none;"></p>`
                      ) {
                        return "Field is Required";
                      }
                    },
                  }}
                />
              </FieldGroup>
              <DashedDivider />
            </>
          ) : null}

          {type === PUSH_TYPE.message || type === PUSH_TYPE.message_inbox ? (
            <>
              <FieldGroup className="d-flex">
                <div className="maxWidthSm">
                  <Label className="grp-label">Message Content</Label>

                  <TextField {...TitleTextfield} />
                  <TextField {...SubTitleTextfield} />
                  <TextField {...BodyTextfield} />
                  <FieldBorder>
                    <SelectField {...SelectTextField} />
                  </FieldBorder>
                  {getValues("message")?.click_action?.type === "web_link" && (
                    <TextField {...WebLinkProps} />
                  )}
                  <FieldGroup>
                    <ImageUploaderWrap>
                      <Label className="grp-label">Image Url</Label>
                      <ImageUploader
                        ImageState={ImageState}
                        control={control}
                        setValue={setValue}
                        uid="image_url"
                        title="Enter Image"
                        fieldName="message.image_url"
                        image_url={
                          props?.push_notification?.payload?.message
                            ?.image_url ?? props?.push_notification?.image_url
                        }
                      />
                    </ImageUploaderWrap>
                    <ImageUploaderWrap>
                      <Label className="grp-label">Icon Url</Label>
                      <ImageUploader
                        ImageState={IconState}
                        control={control}
                        setValue={setValue}
                        title="Enter Icon"
                        fieldName="message.icon_url"
                        uid="icon_urls"
                        image_url={
                          props?.push_notification?.payload?.message
                            ?.icon_url ?? props?.push_notification?.icon_url
                        }
                      />
                    </ImageUploaderWrap>
                  </FieldGroup>
                </div>
                <IsolateReRender
                  control={control}
                  imageUrl={imageUrl}
                  iconUrl={iconUrl}
                  team_name={props.team_name}
                  title={
                    props?.push_notification?.payload?.message?.title ??
                    props?.push_notification?.title?.data
                  }
                  body={
                    props?.push_notification?.payload?.message?.body ??
                    props?.push_notification?.body?.data
                  }
                />
              </FieldGroup>
              <DashedDivider />
              <FieldGroup className="maxWidthSm">
                <Accordin {...AccordinProps}>
                  <TextField {...SoundTextfield} />
                  <CheckBox {...CheckBoxProps} />
                  <TextField {...IconTextfield} />
                  <TextField {...BadgeTextField} />
                  <TextField {...DeepLinkTextFiled} />
                  <TextField {...CollapseTextFiled} />
                  <JsonEditor {...JsonEditorProps} />
                </Accordin>
              </FieldGroup>
              <DashedDivider />
            </>
          ) : null}
          <FieldGroup className="maxWidthSm">
            <Label className="grp-label">Schedule</Label>
            <RadioGroup useForm={MessageForm} {...ScheduleProps} />
          </FieldGroup>
        </form>
      </FormWrapper>

      {/* {props?.push_notification?.payload?.status && (
        <Drawer
          drawer_info={props?.drawer_info}
          handleCloseDrawer={props?.handleCloseDrawer}
          openDrawer={props?.openDrawer}
        />
      )} */}

      <PanelFooter>
        <div>
          {props?.push_notification?.payload?.status ? (
            <>
              {props?.push_notification?.payload?.status ===
              PUSH_STATUS.draft ? (
                <>
                  <Button
                    onClick={props.CancelForm}
                    variant="contained"
                    color="primary"
                    data-testid="cancel-btn"
                  >
                    Cancel
                  </Button>

                  <Button
                    // disabled={Boolean(!Object.keys(dirtyFields).length)}
                    form="add_message"
                    variant="contained"
                    color="primary"
                    data-testid="draft-btn"
                    type="submit"
                    onClick={() => {
                      setIsDraft(true);
                    }}
                  >
                    Save As Draft
                  </Button>

                  <Button
                    form="add_message"
                    variant="contained"
                    color="primary"
                    data-testid="send-btn"
                    type="submit"
                  >
                    Send
                  </Button>
                </>
              ) : (
                <>
                  {props?.push_notification?.payload?.status ===
                  PUSH_STATUS.scheduled ? (
                    <Button
                      onClick={props.CancelForm}
                      variant="contained"
                      color="primary"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={props.CancelForm}
                        variant="contained"
                        color="primary"
                        data-testid="cancel-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        // disabled={Boolean(!Object.keys(dirtyFields).length)}
                        form="add_message"
                        variant="contained"
                        color="primary"
                        data-testid="send-btn"
                        type="submit"
                      >
                        Send
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Button
                onClick={props.CancelForm}
                variant="contained"
                color="primary"
                data-testid="cancel-btn"
              >
                Cancel
              </Button>

              <Button
                /* disabled={
                  props?.isTemp
                    ? false
                    : Boolean(!Object.keys(dirtyFields).length)
                } */
                form="add_message"
                variant="contained"
                color="primary"
                data-testid="draftComp-btn"
                type="submit"
                onClick={() => {
                  setIsDraft(true);
                }}
              >
                Save As Draft
              </Button>

              <Button
                /*   disabled={
                  props?.isTemp
                    ? false
                    : Boolean(!Object.keys(dirtyFields).length)
                } */
                form="add_message"
                variant="contained"
                color="primary"
                data-testid="sendcomp-btn"
                type="submit"
              >
                Send
              </Button>
            </>
          )}
        </div>
      </PanelFooter>
    </Fragment>
  );
};

export default MessageForm;
