import { Button } from "@dfep/ui-component";
import { capitalize } from "utils/functions";
import {
  DashedDivider,
  FieldGroup,
  FormWrapper,
  ImageUploaderWrap,
} from "styles/global.style";
import { Container as ImageUploader } from "app/pages/Dashboard/pushNotification/Messages/form/upload/imageUpload";
import { Preview } from "@dfep/ui-component";
import { useForm, useWatch } from "react-hook-form";
import Accordin from "app/components/Common/Form/Accordion";
import CheckBox from "app/components/Common/Form/Checkbox";
import JsonEditor from "app/components/Common/Form/jsonEditor";
import Label from "app/components/Common/Label";
import PanelFooter from "app/components/Common/PanelFooter";
import React, { Fragment, useState } from "react";
import TextField from "app/components/Common/Form/TextField";

const IsolateReRender = ({ control, imageUrl, team_name }) => {
  const message = useWatch({
    control,
    name: "message",
    defaultValue: {
      title: "",
      body: "",
    },
  });

  return (
    <Preview
      team={capitalize(team_name)}
      title={message.title}
      body={message.body}
      avatar={imageUrl}
    />
  ); // only re-render at the component level, when message changes
};

const TemplateForm = props => {
  const [imageUrl, setImageUrl] = useState();
  const [iconUrl, setIconUrl] = useState();

  const TemplateForm = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
  });
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { dirtyFields, errors },
  } = TemplateForm;

  const submitFormHandler = ({ message, settings, name, ...data }) => {
    const form = {
      name,
      type: "others",
      title: {
        isDefault: false,
        type: "string",
        data: message.title,
      },
      subtitle: {
        isDefault: false,
        type: "string",
        data: message.subtitle,
      },
      body: {
        isDefault: false,
        type: "string",
        data: message.body,
      },
      buttons: [],
      click_action: message.click_action,
      image_url: message?.image_url,
      icon_url: message?.icon_url,
      badge: settings?.badge,
      collapse_key: settings?.collapse_key,
      deeplink_url: settings?.deeplink_url,
      icon_color: settings?.icon_color,
      silent_push: settings?.silent_push,
      sound: settings?.sound,
      data: settings?.jsonEditor ? JSON.parse(`${settings?.jsonEditor}`) : "",
    };

    props.createTemplate(form);
  };

  const ImageState = img => {
    setImageUrl(img);
  };

  const IconState = img => {
    setIconUrl(img);
  };

  const NameTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Name is required",
      maxLength: {
        value: 200,
        message: "exceeds max length. [200 MAX CHARACTER]",
      },
    },
    fieldName: "name",
    uid: "name-textFiled",
    title: "Name",
    type: "text",
    className: "name-title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const TitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Title is required",
    },
    fieldName: "message.title",
    uid: "Title-textFiled",
    title: "Title",
    type: "text",
    className: "title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const SubTitleTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Sub Title is required",
    },
    fieldName: "message.subtitle",
    uid: "sub-title-textFiled",
    title: " Sub Title",
    type: "text",
    className: "sub-title",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const BodyTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {
      required: "Body is required",
    },
    fieldName: "message.body",
    uid: "body-textFiled",
    title: "Body",
    type: "text",
    className: "body",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const WebLinkProps = {
    watchInstance: "",
    watchOn: "",
    showBorder: false,
    control,
    validations: {},
    fieldName: "message.click_action",
    uid: "click-textFiled",
    title: "Enter Click Action",
    type: "text",
    className: "click-textFiled",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const AccordinProps = {
    uid: "test-accordion",
    title: "Advanced Settings",
    showBorder: true,
    fieldName: "settings",
    errors,
  };

  const SoundTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.sound",
    uid: "sound-textFiled",
    title: "Sound",
    type: "text",
    showBorder: false,
    className: "sound",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const CheckBoxProps = {
    watchInstance: {},
    watchOn: {},
    control,
    fieldName: "settings.silent_push",
    validations: {},
    showBorder: false,
    defaultValue:
      props?.push_notification?.payload?.message?.silent_push ?? false,
    className: "check",
    disabled: false,
    uid: "test-checkBox",
    title: "Silent Push",
  };

  const IconTextfield = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.icon_color",
    uid: "icon-textFiled",
    title: "Icon Color",
    type: "text",
    className: "icon",
    showBorder: false,
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const BadgeTextField = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.badge",
    uid: "Badge-textFiled",
    title: "Badge",
    type: "number",
    showBorder: false,
    className: "badge",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const DeepLinkTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.deeplink_url",
    uid: "deep-textFiled",
    title: "DeepLink",
    type: "text",
    showBorder: false,
    className: "deep_link",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const CollapseTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: "settings.collapse_key",
    uid: "collapse-textFiled",
    title: "Collapse key",
    type: "text",
    showBorder: false,
    className: "collapse_key",
    readOnly: false,
    disabled: false,
    doNotTrim: false,
  };

  const JsonEditorProps = {
    fieldName: "settings.jsonEditor",
    showBorder: true,

    control,
    uid: "test-json",
    title: "Data",
    setError,
    defaultValue: "{}",
  };

  return (
    <Fragment>
      <FormWrapper>
        <form
          id="add_template"
          noValidate
          data-testid="add_template"
          onSubmit={handleSubmit(submitFormHandler)}
        >
          <FieldGroup className="maxWidthSm">
            <TextField {...NameTextfield} />
          </FieldGroup>
          <DashedDivider />
          <FieldGroup className="d-flex">
            <div className="maxWidthSm">
              <Label className="grp-label">Message Content</Label>
              <TextField {...TitleTextfield} />
              <TextField {...SubTitleTextfield} />
              <TextField {...BodyTextfield} />
              <TextField {...WebLinkProps} />
              <FieldGroup>
                <ImageUploaderWrap>
                  <Label className="grp-label">Image Url</Label>
                  <ImageUploader
                    ImageState={ImageState}
                    control={control}
                    setValue={setValue}
                    title="Enter Image"
                    fieldName="message.image_url"
                    uid="image_Url"
                  />
                </ImageUploaderWrap>
                <ImageUploaderWrap>
                  <Label className="grp-label">Icon Url</Label>
                  <ImageUploader
                    ImageState={IconState}
                    control={control}
                    setValue={setValue}
                    title="Enter Icon"
                    fieldName="message.icon_url"
                    uid="icon_Url"
                  />
                </ImageUploaderWrap>
              </FieldGroup>
            </div>
            <IsolateReRender
              control={control}
              iconUrl={iconUrl}
              imageUrl={imageUrl}
              team_name={props.team_name}
            />
          </FieldGroup>
          <DashedDivider />
          <FieldGroup className="maxWidthSm">
            <Accordin {...AccordinProps}>
              <TextField {...SoundTextfield} />
              <CheckBox {...CheckBoxProps} />
              <TextField {...IconTextfield} />
              <TextField {...BadgeTextField} />
              <TextField {...DeepLinkTextFiled} />
              <TextField {...CollapseTextFiled} />
              <JsonEditor {...JsonEditorProps} />
            </Accordin>
          </FieldGroup>
        </form>
      </FormWrapper>

      <PanelFooter>
        <div>
          <Button
            onClick={props.CancelForm}
            variant="contained"
            color="primary"
            data-testid="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            disabled={Boolean(!Object.keys(dirtyFields).length)}
            form="add_template"
            variant="contained"
            data-testid="save-btn"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </div>
      </PanelFooter>
    </Fragment>
  );
};

export default TemplateForm;
