/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@dfep/ui-component";
import { Chip } from "./collaborators.style";
import { createRecord } from "services/createObject";
import { deleteConfirmationMessage } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { getCollaborators } from "services/getObjects";
import { Modal } from "@dfep/ui-component";
import { Typography } from "@material-ui/core";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useModal } from "app/hooks/modal";
import { validateEmail } from "utils/functions";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import Box from "@material-ui/core/Box";
import Form from "app/components/Common/Form";
import isEmpty from "lodash/isEmpty";
import PanelHeader from "app/components/Common/PanelHeader";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import React, { useCallback, useEffect, useState } from "react";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const Collaborators = ({ match: { url, params } }) => {
  const { show, openModalHandler, closeModalHandler } = useModal();
  const [userEmail, setUserEmail] = useState("");
  const { sport_key, env_key } = params;
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const { isOwner } = useSelector(state => state.application);

  const { nbaEnable } = useSelector(state => state.application);
  const settingsURL = nbaEnable ? `${url.split("/collaborators")[0]}` : url;

  const reactHookForm = useForm({
    mode: "all",
    reValidateMode: "all",
    resolver: undefined,
    context: undefined,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const formSchema = {
    title: "",
    name: "user",
    uid: "userForm",
    field_metadata: { options: [], readOnly: true },
    defaultValue: "",
    validations: {},
    type: "group",
    _schema: [
      {
        title: "Email",
        uid: "user-email",
        name: "user.email",
        type: "text",
        defaultValue: "",
        validations: {
          validate: email => {
            return validateEmail(email) ? true : "Invalid Email Address!";
          },
          required: "Email is required",
        },
        field_metadata: {
          options: [],
          readOnly: false,
        },
        _schema: [],
      },
      {
        title: "Role",
        uid: "user-access_type",
        name: "user.access_type",
        type: "select",
        defaultValue: "",
        validations: {
          required: "Assign Role",
        },
        field_metadata: {
          options: [
            { label: "Full Access", value: "full_access" },
            { label: "Read Only", value: "read_only" },
          ],
          readOnly: false,
        },
        _schema: [],
      },
    ],
  };

  const [formProps, setFormProps] = useState({});
  const [modalProps, setModalProps] = useState({});
  const toggleInviteForm = () => {
    setFormProps({
      form: reactHookForm,
      customSchema: formSchema,
      formId: "collaboratorsInviteForm",
      onSubmit: inviteUser,
    });
    setModalProps({
      open: true,
      actions: [
        { id: "inviteCancel", name: "Cancel", handler: closeModal },
        {
          id: "inviteDone",
          name: "Invite",
          handler: null,
          additionalAttributes: {
            form: "collaboratorsInviteForm",
            type: "submit",
            variant: "contained",
            color: "primary",
          },
        },
      ],
      title: "Invite Users",
    });
  };

  const toggleAssignForm = (e, uid, { email, access_type }) => {
    const { _schema } = formSchema;
    _schema[0].defaultValue = email;
    _schema[0].field_metadata.readOnly = true;
    _schema[1].defaultValue = access_type;
    setFormProps({
      form: reactHookForm,
      customSchema: formSchema,
      formId: "collaboratorsAssignForm",
      onSubmit: assignRole,
    });
    setModalProps({
      open: true,
      actions: [
        { id: "assignCancel", name: "Cancel", handler: closeModal },
        {
          id: "assignDone",
          name: "Assign",
          handler: null,
          additionalAttributes: {
            form: "collaboratorsAssignForm",
            type: "submit",
            variant: "contained",
            color: "primary",
          },
        },
      ],
      title: "Assign Roles",
    });
  };

  // Table cell formators
  const formatEmail = ({ email, user_type }) => {
    if (user_type === "owner") {
      return (
        <Box
          display="flex"
          justify="flex-start"
          alignItems="center"
          flexWrap="wrap"
        >
          <Typography>{email}</Typography>
          <Box m={2}>
            <Chip size="small" label="Owner" color="primary" />
          </Box>
        </Box>
      );
    } else {
      return <Typography>{email}</Typography>;
    }
  };

  const formatStatus = ({ active_status }) => {
    const status = active_status ? "Active" : "In-active";
    return (
      <Chip label={status} size="small" className={`${status.toLowerCase()}`} />
    );
  };

  const formatRoles = ({ access_type }) => {
    const activeStatus =
      access_type === "full_access" ? "Full Access" : "Read Only";
    return (
      <Chip
        label={activeStatus}
        size="small"
        className={`${access_type.toLowerCase()}`}
      />
    );
  };
  const tableHeadings = [
    { label: "Emails", id: "email", formator: formatEmail },
    { label: "Status", id: "active_status", formator: formatStatus },
    { label: "Roles", id: "access_type", formator: formatRoles },
  ];

  const fetchUsers = useCallback(async () => {
    setUsers([]);
    const {
      data: { users },
    } = await dispatch(
      getCollaborators({
        sport_key,
        env_key,
      }),
    );
    setUsers(users);
  }, [env_key, sport_key]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const closeModal = async () => {
    setModalProps({});
    setFormProps({});
  };

  // Remove Collaborator
  const removeCollaborator = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/remove_collaborator`,
        key: "collaborator",
        form: {
          email: userEmail,
        },
      }),
    );
    closeModalHandler();
    fetchUsers();
  };

  // Invite Form Submit
  const inviteUser = async ({ user: { email, access_type } }) => {
    await dispatch(
      createRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/add_collaborator`,
        key: "collaborator",
        form: {
          email: email,
          access_type: access_type,
        },
      }),
    );
    closeModal();
    fetchUsers();
  };

  // Assign Role Form Submit
  const assignRole = async ({ user: { email, access_type } }) => {
    await dispatch(
      updateRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/change_access_type`,
        key: "collaborator",
        form: {
          email: email,
          access_type: access_type,
        },
      }),
    );
    closeModal();
    fetchUsers();
  };

  // Resend Invite
  const resendInvite = async (event, userId, { email }) => {
    await dispatch(
      updateRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/resend_collaborator_invite`,
        key: "collaborator",
        form: {
          email,
        },
      }),
    );
    closeModal();
    fetchUsers();
  };

  const removeModalHandler = (e, uid, { email }) => {
    setUserEmail(email);
    openModalHandler("show");
  };

  const tableActions = [
    {
      id: "resendInvite",
      label: "Resend Invite",
      icon: <RefreshOutlinedIcon data-testid="resendInvite_iconBtn" />,
      handler: resendInvite,
      showAction: ({ user_type, active_status }) =>
        user_type !== "owner" && !active_status,
    },
    {
      id: "assign",
      label: "Assign Roles",
      icon: <AssignmentIndOutlinedIcon data-testid="assign_roles" />,
      handler: toggleAssignForm,
      showAction: ({ user_type }) => user_type !== "owner",
    },
    {
      id: "remove",
      label: "Remove Collaborators",
      icon: <RemoveCircleOutlineOutlinedIcon data-testid="remove_iconBtn" />,
      handler: removeModalHandler,
      showAction: ({ user_type }) => user_type !== "owner",
    },
  ];

  return (
    <div data-testid="collaborators-container">
      <PanelHeader
        refreshHandler={fetchUsers}
        breadCrumbItems={[
          {
            title: "Settings",
            link: settingsURL,
          },
          {
            title: "Collaborators",
          },
        ]}
        title="Collaborators"
      >
        {isOwner && (
          <Button
            data-testid="collaborators-inviteButton"
            onClick={toggleInviteForm}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PersonAddIcon />}
          >
            Invite
          </Button>
        )}
      </PanelHeader>
      {users.length ? (
        <Table
          data-testid="collaborators-table"
          name="Collaborators"
          data={users}
          headings={tableHeadings}
          actions={isOwner ? tableActions : []}
        />
      ) : (
        <SkeletonTable
          name="Collaborators"
          headings={tableHeadings}
          actions={tableActions}
        />
      )}
      {!isEmpty(modalProps) && (
        <Modal
          className="small"
          data-testid="collaborators-modal"
          {...modalProps}
        >
          {!isEmpty(formProps) && <Form {...formProps} />}
        </Modal>
      )}
      <Modal
        className="small"
        data-testid="collaborators-modal"
        open={show}
        actions={[
          {
            id: "cancel",
            name: "Cancel",
            handler: () => closeModalHandler("show"),
          },
          {
            id: "remove",
            name: "Remove",
            handler: removeCollaborator,
            additionalAttributes: {
              form: "collaboratorsInviteForm",
              type: "submit",
              variant: "contained",
              color: "primary",
              "data-testid": "remove_btn",
            },
          },
        ]}
        title="Remove Collaborator"
      >
        {deleteConfirmationMessage({
          name: userEmail,
          module: "Collaborators",
        })}
      </Modal>
    </div>
  );
};

export default Collaborators;
