import {
  Addv2Config,
  Getv2Config,
  Updatev2Config,
} from "app/pages/Dashboard/drops/drops_v2/Config";
import {
  GetContent,
  UpdateContent,
} from "app/pages/Dashboard/drops/drops_v1/Content";
import {
  Getv2Content,
  Updatev2Content,
} from "app/pages/Dashboard/drops/drops_v2/Content";
import { Redirect, Route, Switch } from "react-router-dom";
import AddDropAnnouncement from "app/pages/Dashboard/drops/drops_v1/Announcements/add";
import AddEvent from "app/pages/Dashboard/drops/drops_v1/Events/add";
// import GetContent from "app/pages/Dashboard/drops/Content";
import GetEvents from "app/pages/Dashboard/drops/drops_v1/Events";
// import UpdateContent from "app/pages/Dashboard/drops/Content/update";
import DropsAnnouncement from "app/pages/Dashboard/drops/drops_v1/Announcements";
import UpdateDropAnnouncement from "app/pages/Dashboard/drops/drops_v1/Announcements/update/index";
import UpdateEvent from "app/pages/Dashboard/drops/drops_v1/Events/update";

const Drops = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/drop-content`}
        exact
      />

      {/* content Routes */}
      {/* <Route
        path={`${props.match.path}/drop-content/add/:uid`}
        component={AddContent}
        exact
      />
      <Route
        path={`${props.match.path}/drop-content/add`}
        component={AddContent}
      /> */}
      <Route
        path={`${props.match.path}/v1/content/update/:uid`}
        component={UpdateContent}
        exact
      />

      <Route path={`${props.match.path}/v1/content`} component={GetContent} />

      {/* event Routes */}
      <Route path={`${props.match.path}/v1/events/add`} component={AddEvent} />
      <Route
        path={`${props.match.path}/v1/events/update/:uid`}
        component={UpdateEvent}
        exact
      />
      <Route path={`${props.match.path}/v1/events`} component={GetEvents} />

      {/* announcements Routes */}
      <Route
        path={`${props.match.path}/v1/announcements/add`}
        component={AddDropAnnouncement}
      />
      <Route
        path={`${props.match.path}/v1/announcements/update/:uid`}
        component={UpdateDropAnnouncement}
        exact
      />
      <Route
        path={`${props.match.path}/v1/announcements`}
        component={DropsAnnouncement}
      />

      {/* Drops v2 Routes */}
      <Route
        path={`${props.match.path}/v2/content/update/:uid`}
        component={Updatev2Content}
        exact
      />

      <Route path={`${props.match.path}/v2/content`} component={Getv2Content} />

      <Route
        path={`${props.match.path}/v2/config/update/:uid`}
        component={Updatev2Config}
        exact
      />
      <Route
        path={`${props.match.path}/v2/config/add`}
        component={Addv2Config}
        exact
      />
      <Route path={`${props.match.path}/v2/config`} component={Getv2Config} />
    </Switch>
  );
};

export default Drops;
