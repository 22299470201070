import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const ANIMATION_TIME_PERIOD_AFTER_WIN = "animation_time_period_after_win";

const AddLightTheBeamInfo = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/light-the-beam`;

  const onSubmit = async data => {
    if (data.hasOwnProperty(ANIMATION_TIME_PERIOD_AFTER_WIN)) {
      const { hours, minutes } = data[ANIMATION_TIME_PERIOD_AFTER_WIN];
      data[ANIMATION_TIME_PERIOD_AFTER_WIN] = `${hours}:${minutes}`;
    }

    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/light_the_beam_info",
          key: "light_the_beam_info",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Light The Beam"
        breadCrumbItems={[
          {
            title: "Add Light The Beam",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm onSubmit={onSubmit} schema={schema} backUrl={getURL} />
    </>
  );
};

export default AddLightTheBeamInfo;
