import { generateQueryParams } from "utils/functions";
import { TableHead as Head, TableCell, TableRow } from "@dfep/ui-component";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "app/hooks";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function TableHead({
  checkbox,
  none,
  data,
  handleSelectAllClick,
  selected,
  headings,
  actions,
  isSorting,
}) {
  const history = useHistory();
  const { page, sort, value } = useQueryParams();
  const [order, setOrder] = useState({ id: sort, value });
  useEffect(() => {
    setOrder({ id: sort, value });
  }, [sort, value]);

  const handleSorting = id => {
    const orderValue = order.id !== id ? -1 : order.value === 1 ? -1 : 1;
    setOrder({ id, value: orderValue });
    const state = {
      ...queryString.parse(window.location.search),
      page: page,
      sort: id,
      value: orderValue,
    };
    const url = `${window.location.pathname}${generateQueryParams({
      obj: state,
    })}`;
    history.push(url, { ...state });
  };

  return (
    <Head>
      <TableRow>
        {checkbox && (
          <TableCell className="checkbox" padding="checkbox">
            <Checkbox
              indeterminate={
                selected.length > 0 && selected.length < data.length
              }
              checked={data.length > 0 && selected.length === data.length}
              onChange={handleSelectAllClick}
            />
          </TableCell>
        )}
        {headings?.map(({ id, label }) => (
          <TableCell
            id={id}
            className="table-cell-head"
            key={id}
            align="left"
            padding="none"
          >
            {isSorting ? (
              <TableSortLabel
                data-testid={`sortingbtn_${id}`}
                active={order.id === id}
                direction={
                  id === order.id && order.value === 1 ? "asc" : "desc"
                }
                onClick={event => handleSorting(id, event)}
              >
                {label}
              </TableSortLabel>
            ) : (
              <>{label}</>
            )}
          </TableCell>
        ))}
        {actions.length > 0 && (
          <TableCell className="table-cell-head actions-head" padding="none">
            Actions
          </TableCell>
        )}
      </TableRow>
    </Head>
  );
}

TableHead.defaultProps = {
  data: [{ uid: "" }],
  headings: [{ label: "", id: "" }],
  checkbox: false,
  isSorting: false,
  actions: [
    {
      id: "",
      label: "",
      icon: "",
      handler: "",
    },
  ],
};

TableHead.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
    }),
  ),
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string,
      icon: PropTypes.object,
      handler: PropTypes.func,
    }),
  ),
  checkbox: PropTypes.bool,
  handleSelectAllClick: PropTypes.func,
  isSorting: PropTypes.bool,
};
