import { Route, Switch } from "react-router-dom";
import AddGameDetails from "app/pages/Dashboard/Data/Game/Details/add";
import GetDetails from "app/pages/Dashboard/Data/Game/Details/get";
import React from "react";
import UpdateGameDetails from "app/pages/Dashboard/Data/Game/Details/update";

const PlayerLogs = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/add`}
        component={AddGameDetails}
        exact
      />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateGameDetails}
        exact
      />
      <Route path={`${props.match.path}`} component={GetDetails} />
    </Switch>
  );
};

export default PlayerLogs;
