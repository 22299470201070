import { createRecord } from "services/createObject";
import { Form_Mode } from "utils/constants";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import SchemaForm from "../schemaForm";

const AddCategory = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/interactive-maps/categories`;

  const onSubmit = async data => {
    let arr = [];
    for (const prop in data) {
      if (["child_categories"].includes(prop)) {
        data[prop].forEach(obj => {
          arr.push(obj.id);
        });
        Object.assign(data, { child_categories: arr });
      }
    }

    try {
      await dispatch(
        createRecord({
          url: `/v1/admin/interactive_maps/categories`,
          key: "category",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Category"
        breadCrumbItems={[
          {
            title: "Add Category",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        backUrl={getURL}
        props={props}
        mode={Form_Mode.ADD}
      />
    </>
  );
};

export default AddCategory;
