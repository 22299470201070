import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import SchemaForm from "../schemaForm";

const AddRefundDetail = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/orders/refund`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: `/v1/admin/order/refund`,
          key: "refund_data",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Refund Detail"
        breadCrumbItems={[
          {
            title: "Add Refund Detail",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        backUrl={getURL}
        props={props}
        modelName="order_refund"
      />
    </>
  );
};

export default AddRefundDetail;
