/* istanbul ignore file */
import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { create } from "jss";
import { createGlobalStyle, ThemeProvider } from "styled-components/macro";
import { CssBaseline } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import {
  jssPreset,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";

import { Dashboard } from "app/pages/Dashboard";
// import { HomePage } from "./pages/HomePage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { PrivateRoute, PublicRoute } from "./route";
import Auth from "./pages/Auth";
import createTheme from "../styles/theme";
import Loader from "app/components/Common/Loader";
import MapApp from "app/pages/Maps";
import Notifier from "app/components/Common/notifier";

const jss = create({
  ...jssPreset(),
});

const theme = createTheme("DEFAULT");

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => theme.palette.background.default};
  }
  a {
    text-decoration: none;
  }
  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
  .MuiPaper-root {
    box-shadow: 0px 0px 14px rgba(53,64,82,0.05);
  }
  .MuiPopover-paper, 
  .MuiAutocomplete-popper {
    border-radius: 4px;
    background:#fff;
    max-height: 250px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)
  }
  .envActionPopover {
    .MuiListItemIcon-root {
      min-width: 30px;
    }
    .MuiListItem-root {
      min-width: 135px;
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  .action-tooltip {
    .MuiTooltip-tooltip {
      text-transform: capitalize;
    }
  }
  .copied-snackbar-popup {
    .MuiSnackbarContent-message {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .disabled-link{
    pointer-events: none;
  }
`;

export function App() {
  return (
    <>
      <Helmet title="DFE Admin Portal">
        <meta name="description" content="DFE Admin Portal" />
      </Helmet>
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyle />
            <Notifier />
            <Loader />
            <BrowserRouter>
              <Switch>
                {/* <Route path="/" component={Dashboard} exact /> */}
                <Redirect from="/" to="/dashboard/application" exact />
                <Redirect from="/dashboard" to="/dashboard/application" exact />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PublicRoute restricted path="/auth" component={Auth} />
                <PublicRoute
                  restricted
                  path="/interactive_maps"
                  component={MapApp}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  );
}
