import {
  DashboardCard,
  DashboardContainer,
  Divider,
  Skeleton,
} from "./Card.style";
import { Grid } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";
import PanelHeader from "../../../components/Common/PanelHeader";
import PropTypes from "prop-types";
import React from "react";

const Loader = ({ count }) => {
  return (
    <DashboardContainer>
      <PanelHeader title="Applications" className="panel-header" />
      <Divider />
      <Grid container direction="row" spacing={5} data-testid="loading">
        {[...Array(count)].map((_, index) => {
          return (
            <Grid key={count + index} item lg={3} md={4} sm={6} xs={12}>
              <DashboardCard
                cardBorderColor="4px solid grey"
                className="editDetails skeleton-loader"
                cardContentclassName="cardHover"
                cardHeader={
                  <Box
                    display="flex"
                    alignItems="center"
                    className="headerLogo"
                  >
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="60%"
                      height={25}
                    />
                  </Box>
                }
                cardContent={() => (
                  <Box display="flex" alignItems="center" className="appInfo">
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width="40px"
                      height="40px"
                    />
                    <Skeleton variant="text" width="70%" height={25} />
                  </Box>
                )}
                cardFooter={() => (
                  <Box alignItems="center" display="flex" className="timestamp">
                    <AccessTimeIcon className="timeIcon" />
                    <Skeleton variant="text" width="30%" height={25} />
                  </Box>
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    </DashboardContainer>
  );
};

Loader.propTypes = {
  count: PropTypes.number,
};

export default Loader;
