import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import has from "lodash/has";
import TextField from "app/components/Common/TextField";

const Text = ({
  name,
  display_name,
  data_type,
  readOnly,
  validations,
  defaultValue,
  disabled,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, `${field.name}`);
        const fieldError = get(errors, `${field.name}`);
        return (
          <TextField
            label={display_name}
            type={data_type === "string" ? "text" : data_type} // number
            variant="outlined"
            value={field.value}
            error={hasFieldError}
            onChange={field.onChange}
            onBlur={field.onBlur}
            helperText={hasFieldError && fieldError?.message}
            inputRef={field.ref}
            InputProps={{
              readOnly: !!readOnly,
            }}
            inputProps={{
              "data-testid": `${name}`,
              maxLength: 50,
            }}
            InputLabelProps={{
              required: !!validations.required,
            }}
            disabled={!!disabled}
          />
        );
      }}
    />
  );
};

export default Text;
