import { AuthContainer, AuthFormWrap, FooterInfo } from "../authGlobal.style";
import { authServices } from "services/auth";
import { Box, Paper, Typography } from "@material-ui/core";
import { Button } from "@dfep/ui-component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setError, validateEmail } from "utils/functions";
import cloneDeep from "lodash/cloneDeep";
import loginLogo from "assets/DFEP-login-logo.svg";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  email: {
    hasError: false,
    message: "",
  },
};

const ForgotPassword = props => {
  const [email, bindEmail] = useInput("");
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [email]);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      const form = await formValidation();
      props
        .forgotPasswordService({
          url: "/v1/admin/auth/reset_password",
          form,
          showToast: true,
        })
        .then(result => {
          setEmailSent(true);
        });
    } catch (error) {}
  };

  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!email) {
        const error = setError(inputError, "email", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Email field is required" });
      } else if (!validateEmail(email)) {
        const error = setError(inputError, "email", "Invalid Email Address!");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid Email Address!" });
      } else {
        const form = { email };
        resolve(form);
      }
    });
  };

  return (
    <React.Fragment>
      <AuthContainer>
        <AuthFormWrap>
          <img alt="logo" className="login-logo" src={loginLogo} />
          <Paper className="auth-form">
            {emailSent ? (
              <>
                <Box>
                  <Typography variant="h1">Email sent!</Typography>
                  <Typography>
                    Check your email inbox for link to reset your password. If
                    it does not appear check your spam folder.
                  </Typography>
                  <div className="field-wrap">
                    <Button
                      data-testid="login-btn"
                      variant="contained"
                      color="primary"
                      onClick={() => props.history.push("/auth/login")}
                    >
                      Return to Signin
                    </Button>
                  </div>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h1">Forgot Password?</Typography>
                <Typography>
                  Enter the email address associated with your account and we
                  will send you a link to reset your password.
                </Typography>
                <form data-testid="fp-form" noValidate onSubmit={onFormSubmit}>
                  <TextField
                    label="Email"
                    id="email"
                    type="email"
                    required={true}
                    error={inputError.email.hasError}
                    helperText={inputError.email.message}
                    {...bindEmail}
                  />
                  <div className="field-wrap">
                    <Button type="submit" variant="contained" color="primary">
                      Send Mail
                    </Button>
                  </div>
                  <div className="field-wrap link-btn">
                    <Box justifyContent="center" display="flex">
                      <Link to="/auth/login">
                        <Button color="primary">Back to Sign in</Button>
                      </Link>
                    </Box>
                  </div>
                </form>
              </>
            )}
          </Paper>
        </AuthFormWrap>
        <FooterInfo>
          <Typography variant="body1" component="h6">
            © 2021 DFEP. All rights reserved.
          </Typography>
        </FooterInfo>
      </AuthContainer>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    forgotPasswordService: payload => dispatch(authServices(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(ForgotPassword);

export { Container, ForgotPassword as Component };
