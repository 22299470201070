import { capitalize, slugToTitle } from "utils/functions";
import { connect } from "react-redux";
import { createRecord } from "services/createObject";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useLocation } from "react-router";
// import IconButton from "@material-ui/core/IconButton";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MessageForm from "app/pages/Dashboard/pushNotification/Messages/form/MessagesForm";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useEffect, useState } from "react";

const UpdateMessages = props => {
  const {
    location: { state },
  } = props;
  const { uid, sport_key, env_key } = props.match.params;
  // const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState({});
  const [push_provider, setPush_provider] = useState();
  const type = new URLSearchParams(useLocation().search).get("type");

  const getData = async props => {
    setData({});
    const {
      data: { push_notification_data },
    } = await props.getUpdateForm({
      url: `/v1/push_notification/admin/push/${uid}`,
    });
    setData(push_notification_data);
  };

  const getSettings = async props => {
    const { data } = await props.getSettings({
      url: `/v1/admin/applications/${sport_key}/envs/${env_key}/settings`,
    });
    setPush_provider(data.settings.push_notification.service_name);
  };

  const CancelForm = () => {
    props.history.push(
      /* istanbul ignore next */
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/push-notification/messages`,
    );
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (!type) {
      CancelForm();
    }
    getSettings(props);
    getData(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const DraftPushMsg = async form => {
    await props.DraftPushMessages({
      url: `/v1/push_notification/admin/push/draft/${uid}`,
      key: "draft",
      form,
    });
  };

  const AddPushMsg = async form => {
    await props.AddPushMessages({
      url: `/v1/push_notification/admin/push`,
      key: "push_notification",
      form,
    });

    props.history.push(
      `/dashboard/${sport_key}/${env_key}/push-notification/messages`,
    );
  };

  // const drawer_info = {
  //   data: [
  //     {
  //       label: "Delivery",
  //       value: data?.payload?.schedule_date,
  //       type: "date",
  //       id: 1,
  //     },
  //     {
  //       label: "Scheduled",
  //       value: data?.payload?.schedule_date,
  //       type: "date",
  //       id: 2,
  //     },
  //     {
  //       label: "Audience",
  //       value: data?.audience?.type,
  //       id: 3,
  //     },
  //     {
  //       label: "Status",
  //       value: data?.payload?.status,
  //       id: 4,
  //       testid: "status",
  //     },
  //     {
  //       label: "Channels",
  //       value: "iOS, Android",
  //       id: 5,
  //     },
  //     {
  //       label: "Created By",
  //       value: "",
  //       id: 6,
  //     },
  //     {
  //       label: "Created",
  //       value: data?.created_at,
  //       type: "date",
  //       id: 7,
  //     },
  //   ],
  //   messageInfo: "Message Information",
  //   sideOfDrawer: "right",
  // };

  /* istanbul ignore next */
  // const handleCloseDrawer = () => {
  //   setOpenDrawer(false);
  // };

  return (
    <Fragment>
      <PanelHeader
        title={`Add ${capitalize(slugToTitle(type))}`}
        backUrl={`/dashboard/${sport_key}/${env_key}/push-notification/messages/completed`}
        breadCrumbItems={[
          {
            title: "Push Notification",
          },
        ]}
      >
        {/* <IconButton
          data-testid="info-icon"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          <InfoOutlinedIcon />
        </IconButton> */}
      </PanelHeader>

      {Object.keys(data).length > 0 && (
        <MessageForm
          // handleCloseDrawer={handleCloseDrawer}
          // drawer_info={drawer_info}
          // openDrawer={openDrawer}
          DraftPushMsg={DraftPushMsg}
          AddPushMsg={AddPushMsg}
          push_notification={data}
          CancelForm={CancelForm}
          type={type}
          push_provider={push_provider}
          uid={uid}
          team_name={props.team_name}
        />
      )}
    </Fragment>
  );
};

/*istanbul ignore next*/
const mapStateToProps = state => {
  return {
    team_name: state.application.team_name,
  };
};

/*istanbul ignore next*/
const mapDispatchToProps = dispatch => {
  return {
    getUpdateForm: payload => dispatch(get(payload)),
    getSettings: payload => dispatch(get(payload)),
    AddPushMessages: payload => dispatch(createRecord(payload)),
    DraftPushMessages: payload => dispatch(updateRecord(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(UpdateMessages);

export { Container, UpdateMessages as Component };
