import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";

import { useModal } from "app/hooks/modal";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetLeaderView from "./GetLeaderView";
import React, { useEffect, useState } from "react";

const headings = [
  { label: "Game ID", id: "gid" },
  { label: "Team ID", id: "tid" },
  { label: "League ID", id: "league_id" },
  { label: "Season ID", id: "season_id" },
  { label: "Year", id: "year" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const GetGameLeaders = props => {
  const {
    openSyncModal,
    syncDetails,
    syncModalClose,
    getLeaders,
    data,
    totalDataCount,
    isLoading,
  } = props;
  const { sport_key, env_key, team_name } = props.match.params;
  const [selected, setSelected] = useState([]);

  const [leaderObj, setLeaderObj] = useState(null);
  const {
    showDelete,
    showMultipleDelete,
    openModalHandler,
    closeModalHandler,
  } = useModal();

  useEffect(() => {
    setSelected([]);
    getLeaders(team_name);
  }, [team_name, getLeaders]);

  const deleteSingle = async event => {
    await props.deleteData({
      url: `/v1/nba/admin/data/game_leaders/${leaderObj.uid}`,
    });
    handleModalClose();
    setSelected([]);
    getLeaders(team_name);
  };

  const deleteMultiple = async () => {
    await props.deleteData({
      url: "/v1/nba/admin/data/game_leaders",
      key: "uids",
      form: selected,
    });
    handleModalClose();
    setSelected([]);
    getLeaders(team_name);
  };
  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleModalClose = () => {
    closeModalHandler();
    setLeaderObj(null);
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };
  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };
  const UpdateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/game/leader/update/${uid}`,
      state: { from: props.location },
    });
  };
  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
      handler: (event, uid, dataItem) => {
        setLeaderObj(dataItem);
        openModalHandler("showDelete");
      },
      showAction: () => props.access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <GetLeaderView
      name="Leaders"
      data={data}
      headings={headings}
      checkbox={true}
      actions={actions}
      handleSelectAllClick={handleSelectAllClick}
      handleSingleClick={handleSingleClick}
      selectedList={selected}
      numSelected={selected.length}
      leaderObj={leaderObj}
      isSorting
      totalCount={totalDataCount}
      deleteModal={showDelete}
      handleModalClose={handleModalClose}
      deleteHandler={deleteSingle}
      isLoading={isLoading}
      showMultipleDelete={showMultipleDelete}
      deleteMultiple={deleteMultiple}
      multipleDeleteHandler={deleteMultipleHandler}
      openSyncModal={openSyncModal}
      syncModalClose={syncModalClose}
      syncDetails={syncDetails}
    />
  );
};

const mapDisptachToProps = dispatch => {
  return {
    deleteData: payload => dispatch(deleteRecord(payload)),
  };
};

const Container = connect(null, mapDisptachToProps)(GetGameLeaders);
export { Container, GetGameLeaders as Component };
