import { cloneDeep } from "lodash";
import { MapMode } from "../Map";
import { Modal } from "@dfep/ui-component";
import circleSchema from "./Schema/Circle/Schema";
import polygonSchema from "./Schema/Polygon/Schema";
import React, { useCallback, useEffect, useState } from "react";
import SchemaForm from "./SchemaForm";

function FeatureModal({
  modalState: { isOpenModal, mapMode, modalMode, defaultValues },
  cancelModalHandler,
  onSubmitHandler,
}) {
  const [schemaState, setSchemaState] = useState(null);
  const [modalLabel, setModalLabel] = useState("");
  const onModalMapModeSubmitHandler = useCallback(
    form => {
      switch (mapMode) {
        case MapMode.ADD_POLYGON:
          return onSubmitHandler({ form, type: MapMode.ADD_POLYGON });
        case MapMode.ADD_CIRCLE:
          return onSubmitHandler({ form, type: MapMode.ADD_CIRCLE });
        /* istanbul ignore next */
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapMode],
  );
  useEffect(() => {
    let schema = null;
    switch (mapMode) {
      case MapMode.ADD_CIRCLE:
        schema = cloneDeep(circleSchema);
        setSchemaState(schema);
        setModalLabel(modalMode + " Circle");
        break;
      case MapMode.ADD_POLYGON:
        schema = cloneDeep(polygonSchema);
        setSchemaState(schema);
        setModalLabel(modalMode + " Polygon");
        break;
      /* istanbul ignore next */
      default:
        setSchemaState(null);
        setModalLabel("");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMode]);
  return (
    <Modal
      data-testid={`${modalMode}-modal`}
      open={isOpenModal}
      handleClose={cancelModalHandler}
      title={modalLabel}
    >
      <SchemaForm
        schema={schemaState}
        defaultValues={defaultValues}
        onCancel={cancelModalHandler}
        onSubmit={onModalMapModeSubmitHandler}
      />
    </Modal>
  );
}

export default FeatureModal;
