/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Grid } from "./Environment.style";
import { deleteRecord } from "services/deleteObject";
import { Link } from "react-router-dom";
import { Modal } from "@dfep/ui-component";
import { TeamColorMapper } from "utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useState } from "react";
import CardLoader from "./EnvironmentLoader";
import EnvCardContent from "./EnvCardContent";
import EnvCardFooter from "./EnvCardFooter";

const Environment = ({ envs, match, history, leagues, fetchEnvironment }) => {
  const { team_name, tid } = useSelector(state => state.application);
  const BorderColor = TeamColorMapper(team_name, tid, leagues);
  const { sport_key } = match.params;

  const { showDelete, openModalHandler, closeModalHandler } = useModal();

  const [selectedEnv, setSelectedEnv] = useState("");
  // const [isEditActive, setIsEdit] = useState(true);

  const dispatch = useDispatch();

  const handleEnvDelete = data => {
    setSelectedEnv(data);
    openModalHandler("showDelete");
  };

  const handleDelete = async (event, env_key) => {
    event.preventDefault();
    await dispatch(
      deleteRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}`,
      }),
    );
    closeModalHandler();
    fetchEnvironment();
  };

  // const isActive = key => setIsEdit(key);
  if (envs.length >= 1) {
    return (
      <Grid container direction="row" spacing={5} data-testid="resolved">
        {envs.map((env, index) => {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
              <Link
                data-testid="resolved-card"
                to={`/dashboard/${sport_key}/${env?.env_key}`}
                // to={
                //   !isEditActive
                //     ? `/dashboard/${sport_key}/${env?.env_key}`
                //     : `#`
                // }
                // onClick={e => {
                //   if (isEditActive === true) {
                //     e.preventDefault();
                //     e.stopPropagation();
                //   }
                // }}
              >
                <Card
                  cardBorderColor={`4px solid ${BorderColor}`}
                  className="editDetails"
                  cardContentclassName="cardHover envCard"
                  cardContent={() => (
                    <EnvCardContent
                      fetchEnvironment={fetchEnvironment}
                      // leagues={leagues}
                      sport_key={sport_key}
                      env={env}
                      history={history}
                      handleEnvDelete={handleEnvDelete}
                      // isActive={isActive}
                    />
                  )}
                  cardFooter={() => <EnvCardFooter time="20h ago" />}
                />
              </Link>
            </Grid>
          );
        })}
        <Modal
          data-testid="delete-env-modal"
          open={showDelete}
          handleClose={closeModalHandler}
          title="Delete Environment"
          actions={[
            {
              id: "1",
              name: "No",
              handler: closeModalHandler,
              additionalAttributes: {
                "data-testid": "cancel-modal",
              },
            },
            {
              id: "2",
              name: "Yes",
              handler: event => handleDelete(event, selectedEnv.env_key),
              additionalAttributes: {
                variant: "contained",
                color: "primary",
              },
            },
          ]}
        >
          <p>
            Are you sure you want to delete <strong>{selectedEnv?.name}</strong>
            ?
          </p>
        </Modal>
      </Grid>
    );
  }

  return <CardLoader count={1} />;
};

export default Environment;
