import { Collapsible } from "app/components/Common/Collapsible";
import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

function AdvPlayerStatTableView({
  // sync Modal
  openSyncModal,
  syncModalClose,
  syncDetails,
  // Api Call
  deleteAdvPlayerStatReq,
  deleteMultiReq,
  //  modal props
  showDelete,
  showMultipleDelete,
  CloseModal,
  playerObj,
  // table props
  isLoading,
  ...restTableProps
}) {
  return (
    <>
      {isLoading ? (
        <SkeletonTable
          headings={restTableProps.headings}
          name={restTableProps.name}
          actions={restTableProps.actions}
        />
      ) : null}

      {restTableProps.data?.length > 0 && !isLoading ? (
        <Table
          {...restTableProps}
          isSorting
          collapsibleComponent={<Collapsible />}
        />
      ) : (
        !isLoading && <NoData> No record is found </NoData>
      )}

      <Modal
        className="small"
        open={showDelete}
        handleClose={CloseModal}
        title={`Delete ${restTableProps?.name}`}
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: CloseModal,
            additionalAttributes: {
              "data-testid": "del-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteAdvPlayerStatReq,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "del",
            },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: playerObj?.pid,
          module: restTableProps?.name,
        })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        handleClose={CloseModal}
        title={`Delete ${restTableProps?.name}`}
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: CloseModal,
            additionalAttributes: {
              "data-testid": "multidel-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiReq,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "mult-delete",
            },
          },
        ]}
      >
        Are you sure you want to delete{" "}
        <strong>{restTableProps.numSelected}</strong> items?
      </Modal>
      <Modal
        className="small"
        open={openSyncModal}
        handleClose={syncModalClose}
        title="Sync"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: syncModalClose,
            additionalAttributes: {
              "data-testid": "sync-cancel",
            },
          },
          {
            id: "2",
            name: "Start",
            handler: syncDetails,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "sync",
            },
          },
        ]}
      >
        Are you sure you want to start advance player stats sync?
      </Modal>
    </>
  );
}

export default AdvPlayerStatTableView;
