export const handleStateForContentVisibilityInSidebar = (
  integrations,
  featureIds,
) => {
  const visibilityInSidebarObj = {};
  for (const integration in integrations.data) {
    if (featureIds.includes(integration)) {
      if (
        //for pushNotification
        integration === "firebase" ||
        integration === "airship" ||
        integration === "test_adaptor"
      ) {
        const enableFlag =
          Boolean(
            integrations.data["firebase"] &&
              integrations.data["firebase"].enabled,
          ) ||
          Boolean(
            integrations.data["airship"] &&
              integrations.data["airship"].enabled,
          ) ||
          Boolean(
            integrations.data["test_adaptor"] &&
              integrations.data["test_adaptor"].enabled,
          );
        Object.assign(visibilityInSidebarObj, {
          push_notificationEnable: enableFlag,
        });
      } else {
        //generic
        Object.assign(visibilityInSidebarObj, {
          [`${integration}Enable`]: Boolean(
            integrations.data[integration].enabled,
          ),
        });
      }
    }
  }
  return visibilityInSidebarObj;
};

export const handleVisibilityByCategoryInSidebar = (
  featuresByCategory,
  integrations,
) => {
  const visibilityInSidebarObj = {};
  const featureIds = featuresByCategory.map(feature => feature.featureName);

  for (const integration in integrations) {
    if (
      featureIds.includes(integration) &&
      integrations[integration].enabled === true
    ) {
      Object.assign(visibilityInSidebarObj, {
        [`${integration}Enable`]: true,
      });
    }
  }
  return visibilityInSidebarObj;
};
