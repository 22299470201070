import { Collapse } from "./Table.style";
import { format } from "date-fns";
import { StyledTypography } from "./Table.style";
import { TableCell, TableRow } from "@dfep/ui-component";
import ActionBar from "./ActionBar";
import ActionMenu from "./ActionMenu";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import React, { useState } from "react";
export default function TableBodyCell({
  checkbox,
  none,
  dataItem,
  extraInfo,
  extraDateInfo,
  headings,
  isItemSelected,
  handleSingleClick,
  actionMenu,
  actions,
  collapsibleComponent,
  isCheckboxDisabled,
}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const menuHandler = event => {
    setAnchorEl(event.target);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const tableCellIdentifier = (id, type, formator) => {
    if (type === "date") {
      return (
        <StyledTypography className="table-row">
          {dataItem[id] ? (
            <span className="date-container">
              <span className="date">
                {format(new Date(dataItem[id]), "MMM dd yyyy")}
              </span>
              <span className="time">
                {format(new Date(dataItem[id]), "p")}
              </span>
            </span>
          ) : (
            <span>-</span>
          )}
        </StyledTypography>
      );
    } else if (type === "boolean") {
      return (
        <StyledTypography className="table-row">
          {dataItem[id] ? dataItem[id].toString() : "-"}
        </StyledTypography>
      );
    } else if (formator) {
      return <div className="table-row">{formator(dataItem)}</div>;
    } else {
      return (
        <StyledTypography className="table-row">
          {dataItem[id] ? dataItem[id] : "-"}
        </StyledTypography>
      );
    }
  };

  const showActionIcon = () => {
    let menu = false;
    for (let index = 0; index < actions.length; index++) {
      const action = actions[index];
      if (action.hasOwnProperty("showAction")) {
        if (action.showAction(dataItem)) {
          return true;
        }
      } else {
        return true;
      }
    }
    return menu;
  };

  return (
    <>
      <TableRow>
        {checkbox && (
          <TableCell
            data-testid={`handle_single_click_${dataItem.name}`}
            className="checkbox"
            padding="checkbox"
            onClick={event => handleSingleClick(event, dataItem.uid)}
          >
            <Checkbox
              checked={isItemSelected}
              disabled={isCheckboxDisabled(dataItem)}
            />
          </TableCell>
        )}
        {headings?.map(({ id, type, formator }) => (
          <TableCell key={id} id={id} className="table-cell-row" padding="none">
            {tableCellIdentifier(id, type, formator)}
          </TableCell>
        ))}

        {actions.length > 0 && (
          <TableCell className="table-cell-row actions" padding="none">
            <>
              {actionMenu ? (
                <ActionMenu
                  data-testid={`action_menu_click_${dataItem.name}`}
                  anchorEl={anchorEl}
                  menuHandler={menuHandler}
                  handleMenuClose={handleMenuClose}
                  openMenu={openMenu}
                  actions={actions}
                  dataItem={dataItem}
                  showActionIcon={showActionIcon}
                />
              ) : (
                <ActionBar
                  actions={actions}
                  dataItem={dataItem}
                  showActionIcon={showActionIcon}
                />
              )}
            </>

            {collapsibleComponent && (
              <IconButton
                data-testid={`open_handler_${dataItem.name}`}
                aria-label="open_handler"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      {open && (
        <TableRow data-testid="open_open_tableraw">
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headings.length + Number(checkbox) + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {React.cloneElement(collapsibleComponent, {
                extraInfo,
                extraDateInfo,
                dataItem,
                ...collapsibleComponent.props,
              })}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

TableBodyCell.defaultProps = {
  headings: [{ label: "", id: "" }],
  extraInfo: [{ label: "", id: "" }],
  extraDateInfo: [{ label: "", id: "" }],
  actionMenu: false,
  checkbox: false,
  actions: [
    {
      id: "",
      label: "",
      icon: "",
      handler: "",
      showAction: () => true,
    },
  ],
  limit: 10,
  isCheckboxDisabled: () => false,
};

TableBodyCell.propTypes = {
  checkbox: PropTypes.bool,
  isItemSelected: PropTypes.bool,
  extraInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  extraDateInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  handleSingleClick: PropTypes.func,
  actionMenu: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string,
      icon: PropTypes.object,
      handler: PropTypes.func,
      showAction: PropTypes.func,
    }),
  ),
  isCheckboxDisabled: PropTypes.func,
};
