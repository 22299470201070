const sidebar = [
  {
    name: "Setup",
    id: "Setup",
    icon: "Setup",
    child: [
      {
        name: "Config",
        id: "config",
        url: "/data/device/config",
      },
      {
        name: "Menu",
        id: "menu",
        url: "/data/device/menu",
      },
      {
        name: "Settings",
        id: "setting",
        url: "/data/device/setting",
      },
      {
        name: "Eula",
        id: "eula",
        url: "/data/device/eula",
      },
      {
        name: "Onboardings",
        id: "onBoarding",
        // icon : "icon-data",
        child: [
          {
            name: "Screens",
            id: "screens",
            url: "/data/device/onBoarding/screens",
          },
          {
            name: "Core",
            id: "core",
            url: "/data/device/onBoarding/core",
          },
        ],
      },
      {
        name: "Messages",
        id: "messages",
        url: "/data/device/messages",
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "Content",
    id: "Content",
    icon: "Content",
    child: [
      {
        name: "News Feed",
        id: "feed",
        url: "/data/news",
      },
      {
        name: "Teams",
        id: "teams",
        child: [
          {
            name: "Schedules",
            id: "schedule",
            url: "/data/team/schedules",
          },
          {
            name: "Teams",
            id: "team",
            url: "/data/team/teams",
          },
          {
            name: "Standing",
            id: "standing",
            url: "/data/team/standing",
          },
          {
            name: "Season Average",
            id: "seasonAverage",
            url: "/data/team/season_average",
          },
          {
            name: "Leaders",
            id: "leaders",
            url: "/data/team/leaders",
          },
          {
            name: "Leader Details",
            id: "leaderDetails",
            url: "/data/team/leader_details",
          },
          {
            name: "Stats",
            id: "stats",
            url: "/data/team/stats",
          },
          {
            name: "Advance Stats",
            id: "advanceStats",
            url: "/data/team/advanceStats",
          },
          {
            name: "Splits",
            id: "splits",
            url: "/data/team/splits",
          },
        ],
      },
      {
        name: "Rosters",
        id: "rosters",
        child: [
          {
            name: "Players",
            id: "players",
            url: "/data/rosters/players",
          },
          {
            name: "Player Stats",
            id: "playerStats",
            url: "/data/rosters/playerStats",
          },
          {
            name: "Advance Player Stats",
            id: "advancedPlayerStats",
            url: "/data/rosters/advancedPlayerStats",
          },
          {
            name: "Player Average",
            id: "playerAverage",
            url: "/data/rosters/playerAverage",
          },
          {
            name: "Coaches",
            id: "coaches",
            url: "/data/rosters/coaches",
          },
        ],
      },
      {
        name: "Game",
        id: "game",
        child: [
          {
            name: "Details",
            id: "details",
            url: "/data/game/details",
          },
          {
            name: "Play by Play",
            id: "playByPlay",
            url: "/data/game/playByPlay",
          },
          {
            name: "Player Logs",
            id: "playerLogs",
            url: "/data/game/playerLogs",
          },
          {
            name: "Leader",
            id: "leader",
            url: "/data/game/leader",
          },
          {
            name: "Starting Lineup",
            id: "startingLineup",
            url: "/data/game/startingLineup",
          },
          {
            name: "Mock Game",
            id: "mockgame",
            url: "/data/game/mockgame",
          },
        ],
      },
      {
        name: "Arena",
        id: "arena",
        child: [
          {
            name: "Events",
            id: "events",
            url: "/data/arena/events",
          },
          {
            name: "Parking And Directions",
            id: "parkingAndDirections",
            url: "/data/arena/parkingAndDirections",
          },
          {
            name: "Policies",
            id: "policies",
            url: "/data/arena/policies",
          },
        ],
      },
      {
        name: "Light The Beam",
        id: "lightTheBeam",
        url: "/data/light-the-beam",
      },
      {
        name: "WSC Feed",
        id: "wscFeed",
        url: "/data/wsc",
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "Custom Fields",
    id: "customFields",
    icon: "CustomField",
    url: "/customField",
    child: [],
  },
  {
    name: "Custom Services",
    id: "customServices",
    icon: "CustomServices",
    url: "/customServer",
    child: [],
  },
  {
    name: "Settings",
    id: "settings",
    icon: "Settings",
    child: [
      {
        name: "Data Sync",
        id: "syncData",
        url: "/settings/syncData",
      },
      {
        name: "Features",
        id: "features",
        url: "/settings/features",
      },
      {
        name: "Collaborators",
        id: "collaborators",
        url: "/settings/collaborators",
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "Broadcast Messages",
    id: "broadcastMessages",
    icon: "BroadcastMessages",
    url: "/broadcast-topics",
    child: [],
  },
  {
    name: "Push Notification",
    id: "pushNotification",
    icon: "PushNotification",
    child: [
      {
        name: "Messages",
        id: "messages",
        url: "/push-notification/messages",
      },
      {
        name: "Segments",
        id: "segment",
        url: "/push-notification/segment",
      },
      {
        name: "Templates",
        id: "template",
        url: "/push-notification/template",
      },
      {
        name: "Users",
        id: "users",
        icon: "Users",
        url: "/push-notification/users",
        child: [],
      },
    ],
    isChildMandatory: true,
  },

  {
    name: "Drops",
    id: "drops",
    icon: "Drops",
    child: [
      {
        name: "Version 1",
        id: "drops_v1",
        icon: "Drops",
        child: [
          {
            name: "Content",
            id: "dropContent",
            url: "/drops/v1/content",
          },
          {
            name: "Events",
            id: "dropEvents",
            url: "/drops/v1/events",
          },
          {
            name: "Announcements",
            id: "dropAnnouncements",
            url: "/drops/v1/announcements",
          },
        ],
      },
      {
        name: "Version 2",
        id: "drops_v2",
        icon: "Drops v2",
        child: [
          {
            name: "Content",
            id: "dropv2Content",
            url: "/drops/v2/content",
          },
          {
            name: "Config",
            id: "config",
            url: "/drops/v2/config",
          },
        ],
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "Stories",
    id: "stories",
    icon: "Stories",
    url: "/stories",
    child: [],
  },
  {
    name: "Interactive Maps",
    id: "interactiveMaps",
    icon: "Interactive Maps",
    child: [
      {
        name: "Venues",
        id: "venues",
        url: "/interactive-maps/venues",
      },
      {
        name: "Categories",
        id: "categories",
        url: "/interactive-maps/categories",
      },

      {
        name: "Levels",
        id: "levels",
        url: "/interactive-maps/levels",
      },
      {
        name: "Features",
        id: "features",
        url: "/interactive-maps/features",
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "Webhooks",
    id: "webhooks",
    icon: "Webhooks",
    url: "/webhooks",
    child: [],
  },
  {
    name: "Reports",
    id: "reports",
    icon: "Reports",
    child: [
      {
        name: "Devices",
        id: "devices",
        url: "/reports/devices",
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "Orders",
    id: "orders",
    icon: "Orders",
    child: [
      {
        name: "History",
        id: "order_history",
        url: "/orders/history",
      },
      {
        name: "Refund",
        id: "refund",
        url: "/orders/refund",
      },
    ],
    isChildMandatory: true,
  },
  {
    name: "App Users",
    id: "appUsers",
    icon: "appUsers",
    url: "/app-users",
    child: [],
  },
  {
    name: "Geo Fences",
    id: "GeoFences",
    icon: "GeoFences",
    child: [
      {
        name: "Content",
        id: "geoLocations",
        url: "/geo_fencing/geo_location",
      },
    ],
    isChildMandatory: true,
  },
];

export default sidebar;
