const schema = {
  class: {
    title: "Categories",
    uid: "categories",
    _schema: [
      {
        uid: "venue_id",
        display_name: "Venue Id",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "id",
        display_name: "Category Id",
        data_type: "number",
        field_metadata: {
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "display_name",
        display_name: "Display Name",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "default_icon",
        display_name: "Default Icon",
        data_type: "file",
        field_metadata: {},
        mandatory: true,
        _schema: [],
      },
      {
        uid: "selected_icon",
        display_name: "Selected Icon",
        data_type: "file",
        field_metadata: {},
        _schema: [],
      },
      {
        uid: "filter_icon",
        display_name: "Filter Icon",
        data_type: "file",
        field_metadata: {},
        _schema: [],
      },
      {
        uid: "expandable",
        display_name: "Expandable",
        data_type: "boolean",
        field_metadata: {
          additional_options: [],
          options: [],
          multiline: false,
          multiple: false,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "primary_color",
        display_name: "Primary Color",
        data_type: "color",
        field_metadata: {
          deferred: true,
          formats: ["hex"],
          disableAlpha: true,
          disablePlainColor: true,
        },
        mandatory: false,
        multiple: false,
      },
      {
        uid: "child_categories",
        display_name: "Child Categories",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: true,
        _schema: [],
      },
      {
        uid: "search",
        display_name: "Search",
        data_type: "boolean",
        field_metadata: {
          additional_options: [],
          options: [],
          multiline: false,
          multiple: false,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
    ],
  },
};
export default schema;
