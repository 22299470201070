// import { Container as ChangePassword } from "./changePassword";
// import { Footer, Header } from "@dfep/ui-component";
import { Container as Profile } from "./profile";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserAccount } from "./account.style";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";

const Account = props => {
  return (
    <>
      <UserAccount>
        <Grid container>
          <Grid item md={12}>
            <Switch>
              <Redirect
                from={`${props.match.path}`}
                to={`${props.match.path}/profile`}
                exact
              />
              <Route
                path={`${props.match.path}/profile`}
                component={Profile}
                exact
              />
            </Switch>
          </Grid>
        </Grid>
      </UserAccount>
    </>
  );
};

Account.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
    path: PropTypes.string,
  }),
};

export default Account;
