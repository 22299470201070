import { Button, Modal } from "@dfep/ui-component";
import { bytesToSize, setError } from "utils/functions";
import { connect } from "react-redux";
import { uploadObject } from "services/uploadObjects";
import cloneDeep from "lodash/cloneDeep";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileUpload from "app/components/Common/FileUpload";
import React, { useEffect, useRef, useState } from "react";
import TextInputField from "app/components/Common/TextField";

const initialErrorState = {
  file_name: {
    hasError: false,
    message: "",
  },
};

const UploadCsv = props => {
  const [openCsvUploadModal, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const fileInputRef = useRef();

  const openModalHandler = event => {
    setModalOpen(true);
  };
  const closeModalHandler = event => {
    setModalOpen(false);
    setSelectedFiles([]);
    setValidFiles([]);
    setInputError(initialErrorState);
  };

  const [file_name, setName] = useState("");
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));

  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [file_name]);

  const handleChange = event => {
    setName(event.target.value);
  };

  // Form Validation for File Name
  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!file_name) {
        const error = setError(inputError, "file_name", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "File Name field is required" });
      } else {
        const form = {
          name: file_name,
        };
        resolve(form);
      }
    });
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (!validFiles.length) setValidFiles(selectedFiles);
    // eslint-disable-next-line
  }, [selectedFiles]);

  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    /* istanbul ignore else */
    if (files.length) {
      //Drag & drop upload restricted to one file
      /* istanbul ignore else */
      if (!validFiles.length) handleFiles(files);
    }
  };

  const handleFiles = files => {
    /* istanbul ignore else */
    if (files.length && validateFile(files[0])) setSelectedFiles([files[0]]);
  };

  const validateFile = file => {
    const nameArr = file.name.split(".");
    /* istanbul ignore else */
    if (nameArr[nameArr.length - 1] !== "csv") return false;

    return true;
  };

  const removeFile = name => {
    const validFileIndex = validFiles.findIndex(e => e.name === name);
    validFiles.splice(validFileIndex, 1);

    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);

    setSelectedFiles([...selectedFiles]);
  };
  const fileInputClicked = () => {
    /* istanbul ignore else */
    if (!validFiles.length) fileInputRef.current.click();
  };
  const filesSelected = () => {
    /* istanbul ignore else */
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };
  const uploadCsvHandler = async event => {
    try {
      const form = await formValidation();
      startUpload(selectedFiles, form);
    } catch (error) {}
  };

  const startUpload = (files, form) => {
    let counter = 0;
    files.forEach(async (file, index) => {
      const formData = new FormData();
      formData.append("segment_data", file);
      formData.append("name", form.name);
      const response = await props.uploadCsvFile({
        url: `v1/push_notification/admin/segment/upload`,
        formData,
      });
      counter++;
      /* istanbul ignore else */
      if (response && counter === files.length) {
        closeModalHandler();
        setName("");
        setInputError(cloneDeep(initialErrorState));
        props.getSegmentData();
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={openModalHandler}
        data-testid="upload-csv-btn"
      >
        Upload CSV
      </Button>
      <Modal
        data-testid="upload-csv-modal"
        className="small"
        open={openCsvUploadModal}
        handleClose={closeModalHandler}
        title="Upload CSV File"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: closeModalHandler },
          {
            id: "2",
            name: "Submit",
            handler: uploadCsvHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        <TextInputField
          id="file_name"
          inputProps={{ "data-testid": "file_name-input" }}
          label="Name"
          type="text"
          placeholder="Enter File Name"
          variant="outlined"
          fullWidth
          error={inputError.file_name.hasError}
          helperText={inputError.file_name.message}
          onChange={handleChange}
          InputLabelProps={{
            required: true,
          }}
        />
        <FileUpload
          acceptFileType=".csv"
          selectMultiple={false}
          dragOver={dragOver}
          dragEnter={dragEnter}
          dragLeave={dragLeave}
          fileDrop={fileDrop}
          selectedFiles={selectedFiles}
          fileSize={bytesToSize}
          validFiles={validFiles}
          removeFile={removeFile}
          fileInputClicked={fileInputClicked}
          fileInputRef={fileInputRef}
          filesSelected={filesSelected}
        />
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    uploadCsvFile: payload => dispatch(uploadObject(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(UploadCsv);
export { Container, UploadCsv as Component };
