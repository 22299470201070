import { Button } from "@dfep/ui-component";
import { cloneDeep } from "lodash";
import {
  convertValues,
  getFieldComponent,
  identifyComponent,
} from "utils/formFunctions";
import { Form_Mode } from "utils/constants";
import { FormProvider, useForm } from "react-hook-form";
import { FormWrapper } from "styles/global.style";
import { useHistory } from "react-router-dom";
import PanelFooter from "app/components/Common/PanelFooter";
import React, { Fragment } from "react";

const Form = ({ schema, defaultValues, backUrl, onSubmit, mode }) => {
  const {
    push,
    location: { state },
  } = useHistory();

  const formMethods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: cloneDeep(defaultValues),
  });

  const formSubmit = data => {
    onSubmit(
      convertValues({
        data,
      }),
    );
  };
  return (
    <FormWrapper>
      <FormProvider {...formMethods}>
        <form
          noValidate
          onSubmit={formMethods.handleSubmit(formSubmit)}
          className="maxWidthSm"
          data-testid="schema-form"
        >
          {schema?.class?._schema.map(schemaClass => {
            return (
              <Fragment key={schemaClass.uid}>
                {getFieldComponent(identifyComponent(schemaClass))}
              </Fragment>
            );
          })}
          <PanelFooter data-testid="panel-footer">
            <div>
              <Button
                onClick={() =>
                  push(
                    state ? state.from.pathname + state.from.search : backUrl,
                  )
                }
                variant="contained"
                color="primary"
                data-testid="cancel-form"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={mode === Form_Mode.UPDATE ? true : false}
              >
                Save
              </Button>
            </div>
          </PanelFooter>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export default Form;
