import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { useDispatch } from "react-redux";
import { useModal } from "app/hooks/modal";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import React, { useEffect, useState } from "react";
import TableView from "./indexView";

const LightTheBeam = ({
  data,
  isLoading,
  count,
  getData,
  match,
  location,
  history,
  access_type,
}) => {
  const [selected, setSelected] = useState([]);
  const [deleteItem, setDeleteItem] = useState([]);

  const [uid, setUid] = useState("");
  const { sport_key, env_key } = match.params;
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();
  const dispatch = useDispatch();

  const booleanToStringFormat = dataItem => {
    return dataItem.enable.toString();
  };

  const headings = [
    { label: "Text", id: "text" },
    { label: "Enable", id: "enable", formator: booleanToStringFormat },
    {
      label: "Animation Time Period After Win",
      id: "animation_time_period_after_win",
    },
    { label: "Animation Navigation", id: "animation_navigation" },
    { label: "When", id: "when" },
    { label: "Created at", id: "created_at", type: "date" },
    { label: "Updated at", id: "updated_at", type: "date" },
  ];

  useEffect(() => {
    setSelected([]);
  }, [getData]);

  const UpdateHandler = (event, uid) => {
    history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/light-the-beam/update/${uid}`,
      state: { from: location },
    });
  };

  const DeleteHandler = (event, uid, data) => {
    setDeleteItem(data);
    setUid(uid);
    openModalHandler("showDelete");
  };

  const handleModalClose = () => {
    setUid("");
    closeModalHandler();
    setDeleteItem({});
  };

  const handleModalDelete = async () => {
    await dispatch(
      deleteRecord({
        url: `v1/nba/admin/data/light_the_beam_info/${uid}`,
      }),
    );
    setSelected([]);
    getData();
    handleModalClose();
    setDeleteItem({});
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },

    {
      id: "delete",
      label: "Delete",
      icon: <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />,
      handler: DeleteHandler,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const deleteMultipleHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data: data });
    setSelected(newSelecteds);
  };

  const handleMultiDelete = async event => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/light_the_beam_info`,
        key: "uids",
        form: selected,
      }),
    );
    setSelected([]);
    getData();
    handleModalClose();
  };

  return (
    <TableView
      data={data}
      actions={actions}
      headings={headings}
      openModal={showDelete}
      checkbox={true}
      selectedList={selected}
      handleSingleClick={handleSingleClick}
      handleSelectAllClick={handleSelectAllClick}
      multipleDeleteHandler={deleteMultipleHandler}
      handleModalClose={handleModalClose}
      handleModalDelete={handleModalDelete}
      multipleModal={showMultipleDelete}
      handleMultiDelete={handleMultiDelete}
      numSelected={selected.length}
      name="Light The Beam"
      isSorting
      totalCount={count}
      isLoading={isLoading}
      deleteItem={deleteItem}
    />
  );
};

export default LightTheBeam;
