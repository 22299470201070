import { Card } from "@dfep/ui-component";
import { Content, Grid } from "./Environment.style";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MuiSkeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Skeleton = styled(MuiSkeleton)`
  &.MuiSkeleton-text {
    border-radius: 10px;
  }
`;

const Loader = ({ count }) => {
  return (
    <Grid container direction="row" spacing={5} data-testid="loading">
      {[...Array(count)].map((_, index) => {
        return (
          <Grid key={count + index} item lg={3} md={4} sm={6} xs={12}>
            <Card
              cardBorderColor="4px solid grey"
              className="editDetails skeleton-loader"
              cardContentclassName="cardHover"
              cardContent={() => (
                <>
                  <Content
                    mb={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Skeleton variant="text" width="100%" height={25} />
                  </Content>
                  {/* <CardLoader /> */}
                </>
              )}
              cardFooter={() => (
                <Box
                  component="div"
                  alignItems="center"
                  display="flex"
                  className="timestamp"
                >
                  <AccessTimeIcon className="timeIcon" />
                  <Skeleton variant="text" width="30%" />
                </Box>
              )}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const CardLoader = () => (
  <Grid container spacing={4} data-testid="card-content-loading">
    <Grid item xs={12}>
      <InputLabel shrink id="league-native-label-placeholder" className="lbl">
        League
      </InputLabel>
      <Skeleton variant="text" height={25} width="70%" />
    </Grid>
    <Grid item xs={8}>
      <InputLabel shrink id="league-native-label-placeholder" className="lbl">
        Season
        <Skeleton variant="text" height={30} width="100%" />
      </InputLabel>
    </Grid>
    <Grid item xs={4}>
      <InputLabel shrink id="league-native-label-placeholder">
        Year
        <Skeleton variant="text" height={30} width="100%" />
      </InputLabel>
    </Grid>
  </Grid>
);

Loader.propTypes = {
  count: PropTypes.number,
};

export default Loader;
