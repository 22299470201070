const schema = {
  class: {
    title: "Polygon",
    uid: "polygon",
    _schema: [
      {
        display_name: "Name",
        uid: "name",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Draw layer",
        uid: "draw_layer",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Expandable",
        uid: "expandable",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Hidden",
        uid: "hidden",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Fill color",
        uid: "fill_color",
        mandatory: true,
        multiple: false,
        data_type: "color",
        field_metadata: {
          deferred: true,
          formats: ["hex"],
          disableAlpha: true,
          disablePlainColor: true,
        },
      },
      {
        display_name: "Fill opacity",
        uid: "fill_opacity",
        mandatory: true,
        multiple: false,
        data_type: "number",
        min: 0,
        max: 1,
        field_metadata: {},
      },
      {
        display_name: "Stroke Color",
        uid: "stroke_color",
        mandatory: false,
        multiple: false,
        data_type: "color",
        field_metadata: {
          deferred: true,
          formats: ["hex"],
          disableAlpha: true,
          disablePlainColor: true,
        },
      },
      {
        display_name: "Stroke opacity",
        uid: "stroke_opacity",
        mandatory: true,
        multiple: false,
        data_type: "number",
        min: 0,
        max: 1,
        field_metadata: {},
      },
      {
        display_name: "Stroke weight",
        uid: "stroke_weight",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Levels",
        uid: "level_ids",
        data_type: "string",
        mandatory: true,
        multiple: false,
        field_metadata: {
          select: true,
          multiple: true,
          options: [{ label: "Select", value: "" }],
        },
        _schema: [],
      },
    ],
  },
};

export default schema;
