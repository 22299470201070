import MuiBox from "@material-ui/core/Box";
import MuiTab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";
import styled from "styled-components";

export const Tabs = styled(MuiTabs)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
    margin: 0 0 23px;
    .MuiBox-root {
      margin-left: auto;
    }
  }
`;

export const TabWrapper = styled(MuiBox)`
  //tabs-scrollable related style changes. Remove comment once done
  /* &.MuiBox-root {
    border-bottom: 1px solid ${({ theme }) =>
    theme.palette
      .grey[300]};
    margin: 0 0 23px;
    .tabs {
      max-width: 600px;
      ${({
    theme,
  }) =>
    theme.breakpoints.down("sm")} {
        max-width: 440px;
      }
    }
  } */
`;

export const Tab = styled(MuiTab)``;
