import { Container as GetConfig } from "app/pages/Dashboard/Data/Device/Config/get";
import { Route, Switch } from "react-router-dom";
import AddConfig from "app/pages/Dashboard/Data/Device/Config/add";
import React from "react";
import UpdateConfig from "app/pages/Dashboard/Data/Device/Config/update";

const Config = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddConfig} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateConfig}
        exact
      />
      <Route path={`${props.match.path}`} component={GetConfig} />
    </Switch>
  );
};

export default Config;
