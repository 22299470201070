import { Route, Switch } from "react-router-dom";
import { Container as UpdateDeviceData } from "./update";
import GetDeviceData from "./get";
import React from "react";

const DevicesData = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateDeviceData}
        exact
      />
      <Route path={`${props.match.path}`} component={GetDeviceData} />
    </Switch>
  );
};

export default DevicesData;
