import { Container as GetStats } from "app/pages/Dashboard/Data/Team/stats/get";
import { Route, Switch } from "react-router-dom";
import AddStats from "app/pages/Dashboard/Data/Team/stats/add";
import React from "react";
import UpdateStats from "app/pages/Dashboard/Data/Team/stats/update";

const Stats = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddStats} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateStats}
        exact
      />
      <Route path={`${props.match.path}`} component={GetStats} />
    </Switch>
  );
};

export default Stats;
