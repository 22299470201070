import React from "react";

const Webhooks = () => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.324 4.872a4.872 4.872 0 118.768 2.924 1 1 0 01-1.599-1.202 2.872 2.872 0 10-4.786-.288 1 1 0 01-.631 1.474l-4.627 3.393A1.549 1.549 0 115.266 9.56L9.57 6.404a4.867 4.867 0 01-.246-1.532zm6.42 0c0 .474-.213.898-.548 1.182v4.748a4.872 4.872 0 11-5.409 6.762 1 1 0 011.827-.814 2.872 2.872 0 102.577-4.043 1 1 0 01-.995-1.21V6.053a1.549 1.549 0 112.549-1.183zM14.196 17.26a1.549 1.549 0 10-.574-2.988l-4.579-2.442a.999.999 0 00-1.573.32 2.872 2.872 0 11-2.494-4.095 1 1 0 00.074-2 4.872 4.872 0 103.664 7.865l3.967 2.117a1.55 1.55 0 001.515 1.223z"
        fill="#EEE"
      />
    </svg>
  );
};
export default Webhooks;
