import { createRecord } from "services/createObject";
import { Form_Mode } from "utils/constants";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import SchemaForm from "../schemaForm";

const AddCore = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/device/onBoarding/core`;

  const onSubmit = async data => {
    const form = {
      ...data,
      device_type: "ios",
    };

    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/app/onboard",
          key: "onboarding",
          form,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Core"
        breadCrumbItems={[
          {
            title: "Add Core",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        modelName="onboardings"
        backUrl={getURL}
        props={props}
        mode={Form_Mode.ADD}
      />
    </>
  );
};

export default AddCore;
