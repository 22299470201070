import { FormWrapper } from "styles/global.style";
import React from "react";
import TemplateMapper from "app/components/Common/CustomFieldForm/CustomFieldMapper";
const MenuSettings = () => {
  return (
    <FormWrapper data-testid="form-wrapper" className="settings-wrap">
      <TemplateMapper
        modelName="app_menus"
        title="App Menu Template"
        uid="app_menu_template"
      />
    </FormWrapper>
  );
};

export default MenuSettings;
