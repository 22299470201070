/* istanbul ignore file */
import { Constants } from "utils/constants";
import { deleteKey, getKey, setKey } from "utils/cookies";
import axios from "axios";
const axiosInstance = axios.create();

// Http call to regenerate Token with REFRESH_TOKEN
function reGenerateToken() {
  // showLoader(true);
  const httpObject = {
    url: "/v1/admin/auth/regenerate_token",
    data: {
      _method: "POST",
      user: {
        refresh_token: getKey(Constants.REFRESH_TOKEN),
      },
    },
  };
  httpRequest(httpObject)
    .then(result => {
      setKey(Constants.ACCESS_TOKEN, result.data.tokens.access_token);
      setKey(Constants.REFRESH_TOKEN, result.data.tokens.refresh_token);
      // showLoader(false);
      window.location.reload();
    })
    .catch(err => {
      deleteKey(Constants.ACCESS_TOKEN);
      deleteKey(Constants.REFRESH_TOKEN);
      // showLoader(false);
      // history.push("/auth/login");
      window.location.replace("/auth/login");
    });
}

// Response Interceptors
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.config.url !== "/v1/admin/auth/regenerate_token" &&
        error.response.config.url !== "/v1/admin/auth/login"
      ) {
        reGenerateToken();
      }
      return Promise.reject(error);
    }
  },
);

/**
 * API calling module
 * @param  {Object} httpObj             [HTTP confirguration object]
 * @param  {Function} successHandler    [Success Callback]
 * @param  {Function} errorHandler      [Failure Callback]
 * @return {Object Callback}            [Return Object Callback]
 */
function httpRequest(httpObj, successHandler, errorHandler) {
  const serverUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`;

  return axiosInstance.request({
    url: httpObj.url,
    method: httpObj.method || "post",
    baseURL: httpObj.baseURL || serverUrl,
    headers: {
      ...{ "Content-Type": "application/json" },
      ...httpObj.headers,
      Authorization: `Bearer ${getKey(Constants.ACCESS_TOKEN)}`,
    },
    params: httpObj.params,
    timeout: 40000,
    data: httpObj.data,
    responseType: httpObj.responseType ? httpObj.responseType : "json",
  });
  // .then(successHandler)
  // .catch(errorHandler);
}

export { httpRequest, axiosInstance };
