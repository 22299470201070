import { Add, Get, Update } from "app/pages/Dashboard/Stories";
import { Route, Switch } from "react-router-dom";
// import ErrorBoundary from "../../Components/ErrorBoundary/errorBoundary";
import React from "react";

const StoriesRoute = props => {
  return (
    <>
      <Switch>
        <Route exact path={`${props.match.path}/add`} component={Add} />
        <Route
          exact
          path={`${props.match.path}/update/:uid`}
          component={Update}
        />
        <Route path={`${props.match.path}`} component={Get} />
      </Switch>
    </>
  );
};

export default StoriesRoute;
