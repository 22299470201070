import { httpRequest } from "./";
import { showLoader } from "store/reducers/loaderSlice";
import { showNotifier } from "store/reducers/notifierSlice";

export const requestAPI = ({
  url,
  method = "post",
  showToast = true,
  loader = true,
  returnError = false,
  data,
}) => (dispatch, getState) => {
  const { env_key, sport_key } = getState().application;
  const httpObject = {
    url,
    method,
    headers: {
      sport_key,
      env_key,
    },
    data,
  };
  return new Promise(function (resolve, reject) {
    if (loader) {
      dispatch(showLoader({ show: true }));
    }
    httpRequest(httpObject)
      .then(response => {
        dispatch(showLoader({ show: false }));
        if (showToast) {
          dispatch(
            showNotifier({
              open: true,
              message: response.data.notice ?? response.data.message,
              variant: "success",
            }),
          );
        }
        resolve(response);
      })
      .catch(error => {
        if (returnError) {
          reject(error);
          dispatch(showLoader({ show: false }));
        } else {
          handleError(error, dispatch);
        }
      });
  });
};

export function handleError(error, dispatch) {
  dispatch(showLoader({ show: false }));
  const { status: errorStatus, data, config } = error.response;
  switch (errorStatus) {
    case 404:
      dispatch(
        showNotifier({
          open: true,
          message: "API not found",
          errors: {},
          variant: "error",
        }),
      );
      break;
    case 401:
      dispatch(
        showNotifier({
          open: config.url === "/v1/admin/auth/login" ? true : false,
          message: error.response.data.error_message,
          errors: error.response.data.errors,
          variant: "error",
        }),
      );
      break;
    default:
      data
        ? dispatch(
            showNotifier({
              open: true,
              message: error.response.data.error_message,
              errors: error.response.data.errors,
              variant: "error",
            }),
          )
        : dispatch(
            showNotifier({
              open: true,
              message: "Oops! Something went wrong",
              errors: {},
              variant: "error",
            }),
          );
  }
}

export const query = searchText => {
  const searchQuery = {
    applications: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          team_name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          sport_key: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          sport_type: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    device_config: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    device_menu: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    device_setting: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    device_eula: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    device_messages: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          key: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    onboarding_screen: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    onboarding_core: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    newsFeed: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          nid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    wscFeed: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          content_type: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teams: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tn: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    players: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          pid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          fn: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          ln: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    events: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    schedules: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gcode: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          game_state: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    mockGame: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gcode: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    playerStats: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          pid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    playerAverage: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          pid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teamStanding: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teamSeasonAverage: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teamLeader: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teamLeaderDetails: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    gameLeader: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teamStats: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    teamSplits: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          month: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    gameDetails: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gcode: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    gamePlayerLog: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          pid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    playByPlay: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          gcode: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          p: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    startingLineup: {
      $or: [
        {
          gid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    parkingAndDirection: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    policies: {
      version: {
        $regex: searchText,
        $options: "i",
      },
      device_type: "ios",
    },
    coaches: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          pid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          league_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          season_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    messages: {
      $or: [
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          message_type: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          user_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          conv_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    webhooks: {
      channels: {
        $regex: searchText,
        $options: "i",
      },
    },
    deepLinks: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          link: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    users: {
      personal_id: {
        $regex: searchText,
        $options: "i",
      },
    },
    topics: {
      $or: [
        {
          key: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    classes: {
      title: {
        $regex: searchText,
        $options: "i",
      },
    },
    objects: {
      uid: {
        $regex: searchText,
        $options: "i",
      },
    },
    assets: {
      $or: [
        {
          content_type: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          filename: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    advancedTeamStats: {
      tid: {
        $regex: searchText,
        $options: "i",
      },
    },
    advancedPlayerStats: {
      $or: [
        {
          tid: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          pid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    appUsers: {
      email: {
        $regex: searchText,
        $options: "i",
      },
    },
    installationData: {
      $or: [
        {
          device_type: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          device_token: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          user_uid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    segment: {
      $or: [
        {
          type: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    segmentDropDown: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    installationDropDown: {
      $or: [
        {
          device_token: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    pushMessages: {
      $or: [
        {
          "message.title": {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          type: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    pushTemplates: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          uid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    assetImageSearch: {
      $or: [
        {
          filename: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    integrations: {
      $or: [
        {
          display_name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          category: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    stories: {
      $or: [
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          story_type: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          category: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    dropEvents: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    dropContent: {
      $or: [
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          status: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    dropAnnouncements: {
      $or: [
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          cs_uid: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    dropv2Content: {
      $or: [
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          status: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    dropv2Config: {
      $or: [
        {
          title: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    interactiveMapVenues: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    interactiveMapFeatures: {
      $or: [
        {
          "properties.type": {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          "geometry.type": {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          "properties.draw_layer": parseInt(searchText),
        },
        {
          "properties.venue_id": {
            $regex: searchText,
            $options: "i",
          },
        },
        // {
        //   "properties.id": parseInt(searchText),
        // },
        // {
        //   "properties.name": {
        //     $regex: searchText,
        //     $options: "i",
        //   },
        // },
        // {
        //   "properties.size": parseFloat(searchText),
        // },
        // {
        //   "properties.keywords": {
        //     $regex: searchText,
        //     $options: "i",
        //   },
        // },
        // {
        //   "properties.location": {
        //     $regex: searchText,
        //     $options: "i",
        //   },
        // },
      ],
    },
    interactiveMapCategories: {
      $or: [
        {
          display_name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          venue_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    interactiveMapLevels: {
      $or: [
        {
          name: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          venue_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    history: {
      $or: [
        {
          order_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          user_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          transaction_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          order_status: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          provider: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    refund: {
      $or: [
        {
          user_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          order_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          transaction_id: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          status: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          provider: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    userProfile: {
      $or: [
        {
          user_id: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    geoLocations: {
      $or: [
        {
          "properties.name": {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          "properties.type": {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
    lightTheBeam: {
      $or: [
        {
          text: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          when: {
            $regex: searchText,
            $options: "i",
          },
        },
        {
          animation_navigation: {
            $regex: searchText,
            $options: "i",
          },
        },
      ],
    },
  };

  if (!isNaN(searchText)) {
    // For Teams
    searchQuery.teams.$or.push({ year: parseInt(searchText) });

    // For Players
    searchQuery.players.$or.push({ year: parseInt(searchText) });

    // For Schedules
    searchQuery.schedules.$or.push({ year: parseInt(searchText) });

    // For Player Stats
    searchQuery.playerStats.$or.push({ year: parseInt(searchText) });

    // For Player Average
    searchQuery.playerAverage.$or.push({ year: parseInt(searchText) });

    // For Team Standing
    searchQuery.teamStanding.$or.push({ year: parseInt(searchText) });

    // For Team Season Average
    searchQuery.teamSeasonAverage.$or.push({ year: parseInt(searchText) });

    // For Team leader
    searchQuery.teamLeader.$or.push({ year: parseInt(searchText) });

    // For Team leader details
    searchQuery.teamLeaderDetails.$or.push({ year: parseInt(searchText) });

    // For game leader
    searchQuery.gameLeader.$or.push({ year: parseInt(searchText) });

    // For Team Stats
    searchQuery.teamStats.$or.push({ year: parseInt(searchText) });

    // For Team Splits
    searchQuery.teamSplits.$or.push({ year: parseInt(searchText) });

    // For Game Details
    searchQuery.gameDetails.$or.push({ year: parseInt(searchText) });

    // For Game Player Log
    searchQuery.gamePlayerLog.$or.push({ year: parseInt(searchText) });

    // For Game Player Log
    searchQuery.playByPlay.$or.push({ evt: parseInt(searchText) });
    searchQuery.playByPlay.$or.push({ year: parseInt(searchText) });

    // For Coaches
    searchQuery.coaches.$or.push({ year: parseInt(searchText) });

    // For DropEvents
    searchQuery.dropEvents.$or.push({ entry_value: parseInt(searchText) });

    //For InteractiveMap Categories
    searchQuery.interactiveMapCategories.$or.push({
      id: parseInt(searchText),
    });

    //For InteractiveMap Levels
    searchQuery.interactiveMapLevels.$or.push({
      id: parseInt(searchText),
    });
    searchQuery.interactiveMapLevels.$or.push({
      ordinal: parseInt(searchText),
    });

    // For Game Starting Lineup
    searchQuery.startingLineup.$or.push({ year: parseInt(searchText) });
  }
  return searchQuery;
};
