import { dobErrorHandler, dobValidation } from "utils/functions";
import { format } from "date-fns";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateCoaches = props => {
  const [coachesData, setCoachesData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/rosters/coaches`;

  useEffect(() => {
    getCoachesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCoachesData = useCallback(async () => {
    const url = `/v1/nba/admin/data/coaches/${uid}`;
    const {
      data: { coach },
    } = await dispatch(get({ url }));
    setCoachesData(coach);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    // console.log("On Submit", data);
    try {
      let form = data;
      if (form.dob && typeof data.dob !== "string") {
        dobValidation({ data });
        form = {
          ...form,
          dob: format(data.dob, "yyyy-MM-dd"),
        };
      }

      await dispatch(
        updateRecord({
          form,
          url: `/v1/nba/admin/data/coaches/${uid}`,
          key: "coach",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*  istanbul ignore next   */
      dobErrorHandler({ error, dispatch });
      console.log(error.message);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Coaches"
        breadCrumbItems={[
          {
            title: "Update Coaches",
          },
        ]}
        backUrl={getURL}
      />
      {coachesData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={coachesData}
          schema={schema}
          modelName="coaches"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateCoaches;
