import { Button, Modal } from "@dfep/ui-component";
import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { getDeviceMeassage } from "services/getObjects";
import { NoData } from "styles/global.style";
import { useDispatch } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

import { Constants } from "utils/constants";
import { useModal } from "app/hooks/modal";
import { useSelector } from "react-redux";

const headings = [
  { label: "Key", id: "key" },
  { label: "UID", id: "uid" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const tableName = "Messages";

function Messages({ match, history, location }) {
  const [messageData, setMessageData] = useState([]);
  const [msgDataCount, setMsgDataCount] = useState(0);
  const [selectedMsg, setSelectedMsg] = useState({});
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { sport_key, env_key } = match.params;
  const { access_type } = useSelector(state => state.application);

  const { limit, sort, skip, value: sortValue, searchText } = useQueryParams();
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setSelected([]);
    const {
      data: { messages, count },
    } = await dispatch(
      getDeviceMeassage({ limit, sort, skip, sortValue, searchText }),
    );

    if (count !== 0 && messages.length === 0) {
      initialRoute(history);
    } else {
      setMessageData(messages);
      setMsgDataCount(count);
      setIsLoading(false);
      setSelectedMsg({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip, sortValue, searchText]);

  const deleteMsg = async () => {
    await dispatch(
      deleteRecord({
        url: `/v1/nba/admin/data/messages/${selectedMsg.uid}`,
      }),
    );

    closeModalHandler();
    fetchData();
  };

  const deleteMultiMsg = async () => {
    await dispatch(
      deleteRecords({
        url: `/v1/nba/admin/data/messages`,
        uids: selected,
      }),
    );

    closeModalHandler();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const modalClose = () => {
    setSelectedMsg({});
    closeModalHandler();
  };

  const multipleDeleteHandler = event => {
    openModalHandler("showMultipleDelete");
  };

  const handleSingleClick = (event, dataId) => {
    const newSelectedData = checkboxSingleClick({
      selected,
      dataId,
    });

    setSelected(newSelectedData);
  };

  const handleSelectAllClick = event => {
    const newSelected = checkboxSelectAllClick({ event, data: messageData });
    setSelected(newSelected);
  };

  const tableActions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon data-testid="edit-btn" />,
      handler: (event, uid) =>
        history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/data/device/messages/update/${uid}`,
          state: {
            from: location,
          },
        }),
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon data-testid="delete-btn" />,
      handler: (event, uid, dataItem) => {
        openModalHandler("showDelete");
        setSelectedMsg(dataItem);
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      <PanelHeader
        title="Device Messages"
        refreshHandler={() => {
          setIsLoading(true);
          fetchData();
        }}
        breadCrumbItems={[{ title: "Device Messages" }]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            onClick={() => {
              history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/device/messages/add`,
                state: { from: location },
              });
            }}
            data-testid="add-btn"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>

      {isLoading ? (
        <SkeletonTable
          headings={headings}
          name={tableName}
          actions={tableActions}
        />
      ) : null}
      {messageData.length > 0 && !isLoading ? (
        <Table
          data-testid="resolved"
          name={tableName}
          checkbox={true}
          headings={headings}
          data={messageData}
          actions={tableActions}
          isSorting
          handleSingleClick={handleSingleClick}
          handleSelectAllClick={handleSelectAllClick}
          selectedList={selected}
          numSelected={selected.length}
          totalCount={msgDataCount}
          multipleDeleteHandler={multipleDeleteHandler}
        />
      ) : (
        !isLoading && <NoData> No record is found </NoData>
      )}
      <Modal
        open={showDelete}
        handleClose={modalClose}
        title="Delete Device Message"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: modalClose,
          },
          {
            id: "2",
            name: "Delete",
            handler: () => deleteMsg(),
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: selectedMsg?.key,
          module: tableName,
        })}
      </Modal>

      <Modal
        open={showMultipleDelete}
        data-testid="mul-delete-modal"
        handleClose={modalClose}
        title="Delete Device Message"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: modalClose,
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiMsg,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {selected.length > 0 && (
          <>
            {" "}
            Are you sure you want to delete <strong>
              {selected.length}
            </strong>{" "}
            messages?
          </>
        )}
      </Modal>
    </>
  );
}

export default Messages;
