import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const GetLeadersView = ({
  deleteModal,
  handleModalClose,
  deleteHandler,
  openSyncModal,
  syncModalClose,
  syncDetails,
  totalCount,
  leaderObj,
  isLoading,
  headings,
  actions,
  checkbox,
  name,
  showMultipleDelete,
  deleteMultiple,
  ...restProps
}) => {
  return (
    <>
      {isLoading ? (
        <SkeletonTable
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
        />
      ) : totalCount > 0 ? (
        <Table
          totalCount={totalCount}
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
          {...restProps}
        />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <Modal
        className="small"
        open={deleteModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {leaderObj &&
          deleteConfirmationMessage({
            name: leaderObj?.tid,
            module: name,
          })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        data-testid="delete-modal"
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "multi-delete-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "multi-delete",
            },
          },
        ]}
      >
        Are you sure you want to delete <strong>{restProps.numSelected}</strong>{" "}
        items?
      </Modal>
      <Modal
        className="small"
        open={openSyncModal}
        handleClose={syncModalClose}
        title="Sync"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: syncModalClose,
            additionalAttributes: {
              "data-testid": "sync-cancel",
            },
          },
          {
            id: "2",
            name: "Start",
            handler: syncDetails,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-sync",
            },
          },
        ]}
      >
        Are you sure you want to start game leader sync?
      </Modal>
    </>
  );
};

export default GetLeadersView;
