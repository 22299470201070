import { Constants } from "utils/constants";
import {
  StyledTable as Table,
  TableBody,
  TableContainer,
} from "@dfep/ui-component";
import { TableWrap } from "app/components/Common/Table/Table.style";
import { useSelector } from "react-redux";
import React from "react";
import TableCell from "./SkeletonTableCell";
import TableHead from "./SkeletonTableHead";
import TableToolbar from "app/components/Common/Table/TableToolbar";

const SkeletonTable = ({ name, checkbox, headings, actionMenu, actions }) => {
  const { access_type } = useSelector(state => state.application);
  const data = [];
  for (var i = 0; i < 10; i++) {
    data.push(
      <TableCell
        key={i}
        checkbox={access_type !== Constants.READ_ONLY && checkbox}
        headings={headings}
        actionMenu={actionMenu}
        actions={actions}
      />,
    );
  }

  return (
    <TableWrap data-testid="table-loader">
      <TableToolbar name={name} />
      <TableContainer>
        <Table>
          <TableHead
            checkbox={access_type !== Constants.READ_ONLY && checkbox}
            headings={headings}
            actions={actions}
          />
          <TableBody>{data}</TableBody>
        </Table>
      </TableContainer>
    </TableWrap>
  );
};

export default SkeletonTable;
