import { Route, Switch } from "react-router-dom";
import AddAdvanceStats from "./add";
import GetAdvStats from "app/pages/Dashboard/Data/Team/AdvanceStats/get";
import React from "react";
import UpdateAdvanceStats from "./update";

const AdvStats = props => {
  return (
    <>
      <Switch>
        <Route
          path={`${props.match.path}/add`}
          component={AddAdvanceStats}
          exact
        />
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdateAdvanceStats}
          exact
        />
        <Route path={`${props.match.path}`} component={GetAdvStats} />
      </Switch>
    </>
  );
};

export default AdvStats;
