import { Route, Switch } from "react-router-dom";
import AddSchedules from "app/pages/Dashboard/Data/Team/Schedules/add";
import GetSchedules from "app/pages/Dashboard/Data/Team/Schedules/get";
import React from "react";
import UpdateSchedules from "app/pages/Dashboard/Data/Team/Schedules/update";

const Schedules = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddSchedules} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateSchedules}
        exact
      />
      <Route path={`${props.match.path}`} component={GetSchedules} />
    </Switch>
  );
};

export default Schedules;
