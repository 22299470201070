/* istanbul ignore file */
import "./Map.css";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { getLevelsData as getLevelDataRequest } from "services/getObjects";
import Levels from "app/pages/Dashboard/interactiveMaps/Maps/Levels";
import Loader from "../Loader";
import PoiDetail from "../PoiDetail";
import React from "react";
import ReactDOM from "react-dom";

const mapStyle = [
  {
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
];
const mapDivStyle = { height: "100vh" };

const TILE_SIZE = 256;

const initialPoiDetail = {
  id: null,
  showPoi: false,
  poiName: null,
  iconURL: null,
  categoryName: null,
  level: null,
  actionText: null,
  actionURL: null,
  description: null,
  location: null,
  primaryColor: null,
  feature: null,
};

function setBoundaries({
  map,
  coordinates: { southwest_coordinate, northeast_coordinate },
}) {
  const southWestLatLng = new window.google.maps.LatLng(
    southwest_coordinate.coordinates[0],
    southwest_coordinate.coordinates[1],
  );
  const northEastLatLng = new window.google.maps.LatLng(
    northeast_coordinate.coordinates[0],
    northeast_coordinate.coordinates[1],
  );
  map.fitBounds(
    new window.google.maps.LatLngBounds(southWestLatLng, northEastLatLng),
  );
  return null;
}

function addLayers({
  map,
  layers: { showTraffic = false, showTransit = false, showBicycling = false },
}) {
  if (showTraffic) {
    var trafficLayer = new window.google.maps.TrafficLayer();
    trafficLayer.setMap(map);
  }
  if (showTransit) {
    var transitLayer = new window.google.maps.TransitLayer();
    transitLayer.setMap(map);
  }
  if (showBicycling) {
    var bicyclingLayer = new window.google.maps.BicyclingLayer();
    bicyclingLayer.setMap(map);
  }
}

function initializeTextOverlay() {
  window.TextOverlay = function (feature, map) {
    // Initialize all properties.
    this.feature = feature;
    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    var division = document.createElement("div");
    division.style.borderStyle = "none";
    division.style.borderWidth = "0px";
    division.style.position = "absolute";
    division.style.width = "auto";
    division.style.cursor = "pointer";
    division.draggable = true;

    this.div = division;
    this.amenitiesMap = map;

    // Explicitly call setMap on this overlay.
    this.setMap(map);
  };

  window.TextOverlay.prototype = new window.google.maps.OverlayView();

  window.TextOverlay.prototype.onAdd = function () {
    // Add the element to the "overlayLayer" pane.
    var panes = this.getPanes();
    // TODO: try it with panes.overlayLayer.appendChild()
    panes.markerLayer.appendChild(this.div);
  };

  window.TextOverlay.prototype.draw = function () {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    var overlayProjection = this.getProjection();

    if (overlayProjection !== undefined) {
      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      var center = overlayProjection.fromLatLngToDivPixel(
        this.feature.getGeometry().get(),
      );
      var bottomBound = new window.google.maps.LatLng(
        this.feature.getGeometry().get().lat() +
          parseFloat(this.feature.getProperty("size")),
        this.feature.getGeometry().get().lng(),
      );
      var height = Math.round(
        center.y - overlayProjection.fromLatLngToDivPixel(bottomBound).y,
      );

      // Resize the image's div to fit the indicated dimensions.
      var div = this.div;
      div.textContent = this.feature.getProperty("name");
      div.style.fontSize = height + "px";
      div.style.whiteSpace = "nowrap";
      var width =
        Math.round(getTextWidth(div.textContent, height + "pt Arial")) + 1;
      div.style.height = height + "px";
      div.style.lineHeight = height + "px";
      div.style.width = width + "px";
      div.style.left = center.x - width / 2 + "px";
      div.style.top = center.y - height / 2 + "px";
      div.style.textAlign = "center";
      div.style.zIndex =
        -10000 + parseInt(this.feature.getProperty("draw_layer"));
      div.style.transform =
        "rotate(" + this.feature.getProperty("rotation") + "deg)";
      div.style.color = this.feature.getProperty("text_color");
      div.style.display = "block";
      div.style.visibility =
        this.feature.getProperty("hidden") === true ? "hidden" : "visible";
    }
  };

  window.TextOverlay.prototype.onRemove = function () {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  };

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  }
  return null;
}

function initializeImageOverlay() {
  window.ImageOverlay = function (feature, map) {
    // Initialize all properties.
    this.feature = feature;

    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    var division = document.createElement("div");
    division.style.borderStyle = "none";
    division.style.borderWidth = "0px";
    division.style.position = "absolute";
    division.style.cursor = "pointer";

    this.div = division;

    // Explicitly call setMap on this overlay.
    this.setMap(map);
  };
  window.ImageOverlay.prototype = new window.google.maps.OverlayView();

  window.ImageOverlay.prototype.onAdd = function () {
    this.reloadImage();
    // Add the element to the "overlayLayer" pane.
    var panes = this.getPanes();
    // TODO: try it with panes.overlayLayer.appendChild()
    panes.markerLayer.appendChild(this.div);
  };

  window.ImageOverlay.prototype.draw = function () {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    var overlayProjection = this.getProjection();

    if (overlayProjection) {
      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      var center = overlayProjection.fromLatLngToDivPixel(
        this.feature.getGeometry().get(),
      );
      var bottomBound = new window.google.maps.LatLng(
        this.feature.getGeometry().get().lat() +
          parseFloat(this.feature.getProperty("size")),
        this.feature.getGeometry().get().lng(),
      );
      var height = Math.round(
        center.y - overlayProjection.fromLatLngToDivPixel(bottomBound).y,
      );

      // Resize the image's div to fit the indicated dimensions.
      var div = this.div;

      var self = this;
      if (this.image_loaded) {
        var width = Math.round(height * this.imageAspectRatio);
        div.style.height = height + "px";
        div.style.width = width + "px";
        div.style.backgroundImage =
          "url('" + this.feature.getProperty("name") + "')";
        div.style.backgroundSize = "cover";
        div.style.backgroundRepeat = "no-repeat";
        div.style.left = center.x - width / 2 + "px";
        div.style.top = center.y - height / 2 + "px";
        div.style.zIndex =
          -10000 + parseInt(self.feature.getProperty("draw_layer"));
        div.style.transform =
          "rotate(" + self.feature.getProperty("rotation") + "deg)";
        div.style.display = "block";
        div.style.visibility =
          this.feature.getProperty("hidden") === true ? "hidden" : "visible";
      }
    }
  };

  window.ImageOverlay.prototype.reloadImage = function () {
    this.image_loaded = false;
    var self = this;
    var image = document.createElement("IMG");
    image.src = this.feature.getProperty("name");
    image.onload = function () {
      self.image_loaded = true;
      self.imageAspectRatio = image.naturalWidth / image.naturalHeight;
      self.draw();
    };
  };

  window.ImageOverlay.prototype.onRemove = function () {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  };
  return null;
}

// TODO: add comment from googlemaps sample project
function project(latLng) {
  var siny = Math.sin((latLng.lat() * Math.PI) / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  return new window.google.maps.Point(
    TILE_SIZE * (0.5 + latLng.lng() / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  );
}

function add_zoom_changed_listner(map) {
  map.addListener("zoom_changed", function () {
    map.data.forEach(function (feature) {
      if (
        feature.getGeometry().getType() === "Point" &&
        feature.getProperty("type") === "poi"
      ) {
        map.data.overrideStyle(feature, {});
      }
    });
  });
}

function add_feature_click_listner(map) {
  map.data.addListener("click", function (featureInfo) {
    featureInfo.feature.setProperty(
      "selected",
      !featureInfo.feature.getProperty("selected"),
    );
  });
}

function add_removefeature_listner(map) {
  map.data.addListener("removefeature", function (featureInfo) {
    featureInfo.feature.setProperty("selected", false);
  });
}

function add_removeAllFeatures_method({ map, nonGeoJsonFeatures }) {
  map.removeAllFeatures = function () {
    map.data.forEach(function (feature) {
      map.data.remove(feature);
    });
    nonGeoJsonFeatures.current.forEach(function (feature) {
      feature.overlay.setMap(null);
    });
    nonGeoJsonFeatures.current = [];
  };
}

function setMapStyle({ map, categoryMap, selectedIconMultiplier }) {
  map.data.setStyle(function (feature) {
    // inspired from https://developers.google.com/maps/documentation/javascript/datalayer#style_options
    switch (feature.getGeometry().getType()) {
      case "Polygon":
      case "MultiPolygon":
        return {
          fillOpacity: feature.getProperty("fill_opacity"),
          fillColor: feature.getProperty("fill_color"),
          strokeColor: feature.getProperty("stroke_color"),
          strokeOpacity: feature.getProperty("stroke_opacity"),
          strokeWeight: feature.getProperty("stroke_weight"),
          clickable: feature.getProperty("expandable") === true,
          visible: feature.getProperty("hidden") !== true,
          zIndex: -10000 + parseInt(feature.getProperty("draw_layer")),
        };
      case "LineString":
      case "MultiLineString":
        return {
          strokeColor: feature.getProperty("stroke_color"),
          strokeOpacity: feature.getProperty("stroke_opacity"),
          strokeWeight: feature.getProperty("stroke_weight"),
          clickable: false,
          visible: feature.getProperty("hidden") !== true,
          zIndex: -10000 + parseInt(feature.getProperty("draw_layer")),
        };
      case "Point":
        switch (feature.getProperty("type")) {
          case "poi":
            return {
              icon: getPoiMarker({
                feature,
                categoryMap,
                map,
                selectedIconMultiplier,
              }),
              title: feature.getProperty("name"),
              clickable: feature.getProperty("expandable") === true,
              visible:
                feature.getProperty("hidden") !== true &&
                feature.getProperty("client_hidden") !== true,
              zIndex:
                feature.getProperty("selected") === true
                  ? 0
                  : -10000 + parseInt(feature.getProperty("draw_layer")),
            };
          default:
            return {};
        }
      default:
        return {};
    }
  });
}

function addFeature({ map, geoJSON, nonGeoJsonFeatures }) {
  switch (geoJSON.geometry.type) {
    case "Polygon":
    case "MultiPolygon":
    case "LineString":
    case "MultiLineString":
      map.data.addGeoJson(geoJSON);
      break;
    case "Point":
      switch (geoJSON.properties.type) {
        case "poi":
          map.data.addGeoJson(geoJSON);
          break;
        case "text":
          var textFeature = new window.google.maps.Data.Feature({
            geometry: {
              lat: geoJSON.geometry.coordinates[1],
              lng: geoJSON.geometry.coordinates[0],
            },
            properties: geoJSON.properties,
          });
          textFeature.overlay = new window.TextOverlay(textFeature, map);
          nonGeoJsonFeatures.current.push(textFeature);
          break;
        case "image":
          var imageFeature = new window.google.maps.Data.Feature({
            geometry: {
              lat: geoJSON.geometry.coordinates[1],
              lng: geoJSON.geometry.coordinates[0],
            },
            properties: geoJSON.properties,
          });
          imageFeature.overlay = new window.ImageOverlay(imageFeature, map);
          nonGeoJsonFeatures.current.push(imageFeature);
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
}

function getPoiMarker({ feature, categoryMap, selectedIconMultiplier, map }) {
  const category = categoryMap[feature.getProperty("category_id")];
  const iconInfo =
    feature.getProperty("selected") === true
      ? category.selected_icon
        ? category.selected_icon
        : category.default_icon
      : category.default_icon;

  let height;
  if (feature.getProperty("size") != null) {
    let latitudeHeight = parseFloat(feature.getProperty("size"));

    if (feature.getProperty("selected") === true)
      latitudeHeight *= selectedIconMultiplier;

    const scale = 1 << map.getZoom();
    const startCoordinate = feature.getGeometry().get();
    const endCoordinate = new window.google.maps.LatLng(
      feature.getGeometry().get().lat() + latitudeHeight,
      feature.getGeometry().get().lng(),
    );
    const startPixel = project(startCoordinate);
    const endPixel = project(endCoordinate);
    height = Math.abs(Math.round(scale * (endPixel.y - startPixel.y)));
  } else {
    height = 30;

    if (feature.getProperty("selected") === true)
      height *= selectedIconMultiplier;
  }

  let size;
  let image;
  if (feature.getProperty("selected") === true) {
    if (category.selectedImage === undefined) {
      category.selectedImage = new Image();
      category.selectedImage.src = iconInfo;
    }
    image = category.selectedImage;
  } else {
    if (category.image === undefined) {
      category.image = new Image();
      category.image.src = iconInfo;
    }
    image = category.image;
  }

  if (image.complete) {
    const aspectRatio = image.width / image.height;
    size = new window.google.maps.Size(aspectRatio * height, height);
  } else {
    image.addEventListener("load", function () {
      map.data.overrideStyle(feature, {});
    });
    size = new window.google.maps.Size(0, 0);
  }

  const anchor =
    feature.getProperty("selected") === true
      ? null
      : new window.google.maps.Point(size.width / 2, size.height / 2);

  return {
    url: iconInfo,
    scaledSize: size,
    anchor: anchor,
  };
}

function getLevelName({ level_ids = [], levels = [] }) {
  const isExistInPoiLevel = level => level_ids.includes(level);
  if (levels.map(level => level.id).every(isExistInPoiLevel))
    return "All Levels";
  return level_ids
    .map(id => levels.find(level => level.id === id)?.name || null)
    .join(",");
}

function Map({
  zoom,
  venueData,
  venueID,
  searchData,
  dispatch,
  sport_key,
  env_key,
}) {
  const mapRef = createRef(null);
  const selectSearchFeature = useRef(null);
  const center = {
    lat: parseFloat(venueData.venue.center_coordinate.coordinates[0]),
    lng: parseFloat(venueData.venue.center_coordinate.coordinates[1]),
  };
  const [showLoader, setShowLoader] = useState(true);
  const [levelValue, setLevelValue] = useState({
    level: "",
    refreshControl: false,
  });
  const [features, setFeatures] = useState([]);
  const nonGeoJsonFeatures = useRef([]);
  const [map, setMap] = useState(null);
  const [poiDetail, setPoiDetail] = useState(initialPoiDetail);

  const urlQueryParams = new URLSearchParams();
  urlQueryParams.append("device", "ios");
  urlQueryParams.append("version", process.env.REACT_APP_GRAPHQL_VERSION);
  urlQueryParams.append("Content-Type", "application/json");
  urlQueryParams.append("sport_key", sport_key);
  urlQueryParams.append("env_key", env_key);

  const categoryParentMap = {};
  const categoryMap = {};

  function populateCategoryMap(category) {
    categoryMap[category.id] = category;
    if (category.child_categories) {
      category.child_categories.forEach(function (childCategory) {
        populateCategoryMap(childCategory);
        categoryParentMap[childCategory.id] = category;
      });
    }
  }
  if (venueData.categories) venueData.categories.forEach(populateCategoryMap);

  const getLevelData = useCallback(async () => {
    const {
      data: { features },
    } = await dispatch(
      getLevelDataRequest({
        url: `/v1/app/interactive_maps/venues/geojson/${venueID}/${
          levelValue.level
        }?${urlQueryParams.toString()}`,
      }),
    );
    features.forEach(feature => {
      feature.properties["id"] = feature.uid;
    });

    //<------remove those POIs from the features for whom category_id does not exist
    const categoryIds = Object.keys(categoryMap);
    const filteredFeatures = Array.from(features).filter(feature =>
      feature.properties.category_id
        ? categoryIds.includes(String(feature.properties.category_id))
        : true,
    );
    // ------>

    setFeatures(filteredFeatures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelValue]);

  useEffect(() => {
    if (map && levelValue.refreshControl) addMapLevelControl();
    if (
      venueData.levels
        .map(level => level.id)
        .includes(parseInt(levelValue.level))
    ) {
      setShowLoader(true);
      getLevelData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelValue]);

  const addMapLevelControl = () => {
    if (map.controls[window.google.maps.ControlPosition.TOP_LEFT].length > 0) {
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].forEach(
        (control, index) => {
          if (control.classList.contains("level-control"))
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].removeAt(
              index,
            );
        },
      );
    }
    const levelControlDiv = document.createElement("div");
    levelControlDiv.classList.add("level-control");
    levelControlDiv.style.marginTop = "5px";
    levelControlDiv.style.minWidth = "250px";
    levelControlDiv.style.width = "100%";
    ReactDOM.render(
      <Levels
        levels={venueData.levels}
        levelValue={levelValue}
        setLevelValue={setLevelValue}
      />,
      levelControlDiv,
    );
    levelControlDiv.index = 0;
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      levelControlDiv,
    );
  };

  // create Map, textOverlay, imageOverlay
  useEffect(() => {
    setMap(
      new window.google.maps.Map(mapRef.current, {
        center: center,
        styles: mapStyle,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoom: zoom,
      }),
    );
    initializeTextOverlay();
    initializeImageOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update control for PoiDetail
  useEffect(() => {
    if (map) {
      if (
        map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].length > 1
      ) {
        map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].forEach(
          (control, index) => {
            if (control.classList.contains("poi-control"))
              map.controls[
                window.google.maps.ControlPosition.LEFT_BOTTOM
              ].removeAt(index);
          },
        );
        // map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].pop();
      }
      if (poiDetail.showPoi) {
        const poiControlDiv = document.createElement("div");
        poiControlDiv.classList.add("poi-control");
        poiControlDiv.style.minWidth = "200px";
        poiControlDiv.style.width = "100%";
        poiControlDiv.style.zIndex = "10000";
        ReactDOM.render(<PoiDetail poiDetail={poiDetail} />, poiControlDiv);
        map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
          poiControlDiv,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poiDetail]);

  // initialize required features in map once its build
  useEffect(() => {
    if (map) {
      // Set boundaries of map viewport to see required entire area at once
      if (
        venueData.venue.southwest_coordinate &&
        venueData.venue.northeast_coordinate
      ) {
        setBoundaries({
          map,
          coordinates: {
            northeast_coordinate: venueData.venue.northeast_coordinate,
            southwest_coordinate: venueData.venue.southwest_coordinate,
          },
        });
      }
      // Add required layers to the map
      addLayers({
        map,
        layers: {
          showTraffic: venueData.venue.show_traffic,
          showBicycling: venueData.venue.show_bicycling,
          showTransit: venueData.venue.show_transit,
        },
      });

      //<------- [START] custom methods for map ---------->

      // Handle when zoom changes on map
      add_zoom_changed_listner(map);

      // Add handling for when we click on a feature
      add_feature_click_listner(map);

      // while removing any feature, set selected to false to remove "PoiDetail" component
      add_removefeature_listner(map);

      // function to clear all features at once
      add_removeAllFeatures_method({
        map,
        nonGeoJsonFeatures,
      });

      let selectedFeature = null;
      map.data.addListener("setproperty", function (featureInfo) {
        if (featureInfo.name === "selected") {
          var feature = featureInfo.feature;
          if (feature.getProperty("selected")) {
            var previouslySelected = selectedFeature;

            // If it is now selected, we want to expand the detail view
            map.data.overrideStyle(feature, {});
            selectedFeature = feature;

            if (previouslySelected)
              previouslySelected.setProperty("selected", false);

            setPoiDetail({
              showPoi: true,
              id: feature.getProperty("id"),
              poiName: feature.getProperty("name"),
              iconURL: feature.getProperty("category_id")
                ? categoryMap[feature.getProperty("category_id")].selected_icon
                  ? categoryMap[feature.getProperty("category_id")]
                      .selected_icon
                  : categoryMap[feature.getProperty("category_id")].default_icon
                : null,
              categoryName: feature.getProperty("category_id")
                ? categoryMap[feature.getProperty("category_id")].display_name
                : null,
              level: getLevelName({
                level_ids: feature.getProperty("level_ids"),
                levels: venueData.levels,
              }),
              actionText: feature.getProperty("action_text"),
              actionURL: feature.getProperty("action_url"),
              description: feature.getProperty("description"),
              location: feature.getProperty("location"),
              primaryColor: feature.getProperty("category_id")
                ? categoryMap[feature.getProperty("category_id")].primary_color
                : "rgb(3 32 214 / 30%)",
              feature: feature,
            });
          } else {
            if (feature === selectedFeature) {
              setPoiDetail(initialPoiDetail);
              selectedFeature = null;
            }
          }
        }
      });

      // function to add feature into the map
      map.addFeature = addFeature;

      // <------- [END] custom methods for map ---------->
      addMapLevelControl();

      setMapStyle({
        map,
        categoryMap,
        selectedIconMultiplier: venueData.venue.selected_icon_multiplier,
      });

      // Add search filter control to map
      const searchDiv = document.createElement("div");
      searchDiv.classList.add("map-search");
      searchDiv.innerHTML = `
      <div class="search-input-container">
        <div class="search-input">
          <img class="search-icon" src="static/map/icon_search.png" />
          <div class="selected-category">
            <img
              class="selected-category-delete"
              src="static/map/icon_category_cancel_white.png"
            />
            <div class="selected-category-name">Selected Category</div>
          </div>
          <input
            class="search-query-input"
            id="search-query"
            type="text"
            placeholder="Where do you want to go?"
          />
        </div>
        <img id="search-close" src="static/map/close.png" />
      </div>
      <div class="search-results">
        <div class="available-categories"></div>
        <div class="matching-search-entities"></div>
      </div>
      `;

      const searchEntitiesDiv = searchDiv.getElementsByClassName(
        "matching-search-entities",
      )[0];

      const searchEntityMap = {};
      // Add all entities to the "matching-search-entities" list
      searchData.features.forEach(poi => {
        searchEntityMap[poi.properties.type + "-" + poi.properties.id] = poi;
        const searchEntityDiv = document.createElement("div");
        searchEntityDiv.classList.add("search-entity");
        searchEntityDiv.innerHTML = `
        <div class="search-entity-title"></div>
        <div class="search-entity-details">
            <div class="search-entity-typeinfo">
                <div class="search-entity-subtitle"></div>
                <div class="search-entity-level"></div>
            </div>
            <div class="search-entity-location"></div>
        </div>
        `;
        searchEntityDiv.setAttribute("data-id", poi.properties.id);
        searchEntityDiv.setAttribute("data-type", poi.properties.type);
        searchEntityDiv.setAttribute(
          "data-latitude",
          poi.geometry.coordinates[1],
        );
        searchEntityDiv.setAttribute(
          "data-longitude",
          poi.geometry.coordinates[0],
        );
        searchEntityDiv.getElementsByClassName(
          "search-entity-title",
        )[0].textContent = poi.properties.name;
        searchEntityDiv.getElementsByClassName(
          "search-entity-subtitle",
        )[0].textContent =
          categoryMap[poi.properties.category_id].display_name || null;
        searchEntityDiv.getElementsByClassName(
          "search-entity-level",
        )[0].textContent = getLevelName({
          level_ids: poi.properties.level_ids,
          levels: venueData.levels,
        });
        searchEntityDiv.getElementsByClassName(
          "search-entity-location",
        )[0].textContent = poi.properties.location;
        searchEntitiesDiv.appendChild(searchEntityDiv);
      });

      // I have to add this stupid hack here to try to handle when the software keyboard shows/hides to make sure that we can still click on search entities after
      window.onresize = function () {
        searchEntitiesDiv.querySelectorAll(".search-entity").forEach(elem => {
          elem.hidden = !elem.hidden;
          elem.hidden = !elem.hidden;
        });
      };

      var selectedCategory = null;
      function refreshAvailableCategories() {
        var searchCategoriesDiv = searchDiv.getElementsByClassName(
          "available-categories",
        )[0];
        searchCategoriesDiv.innerHTML = "";

        function addCategory(category) {
          if (selectedCategory !== null && category.id === selectedCategory.id)
            return;

          const searchCategoryDiv = document.createElement("div");
          searchCategoryDiv.classList.add("search-category");
          searchCategoryDiv.innerHTML = `
          <img class="search-category-image" src=""/>
          <div class="search-category-name"></div>
          `;
          searchCategoryDiv.setAttribute("data-id", category.id);
          searchCategoryDiv
            .getElementsByClassName("search-category-image")[0]
            .setAttribute(
              "src",
              category.filter_icon
                ? category.filter_icon
                : category.default_icon,
            );
          searchCategoryDiv
            .getElementsByClassName("search-category-image")[0]
            .setAttribute("title", category.display_name);
          searchCategoryDiv.getElementsByClassName(
            "search-category-name",
          )[0].textContent = category.display_name;
          searchCategoriesDiv.appendChild(searchCategoryDiv);
        }

        function addCancelButton() {
          const searchCategoryDiv = document.createElement("div");
          searchCategoryDiv.classList.add("search-category");
          searchCategoryDiv.id = "search-category-cancel";
          searchCategoryDiv.innerHTML = `
          <img class="search-category-image" src="static/map/icon_category_cancel.png"/>
          <div class="search-category-name">Cancel</div>
          `;
          searchCategoriesDiv.appendChild(searchCategoryDiv);
        }

        if (selectedCategory == null) {
          searchData.categories.forEach(addCategory);
        } else if (
          selectedCategory.child_categories &&
          selectedCategory.child_categories.length > 0
        ) {
          addCancelButton();
          selectedCategory.child_categories.forEach(addCategory);
        } else {
          addCancelButton();
          if (categoryParentMap[selectedCategory.id])
            categoryParentMap[selectedCategory.id].child_categories.forEach(
              addCategory,
            );
          else searchData.categories.forEach(addCategory);
        }

        searchCategoriesDiv
          .querySelectorAll(".search-category:not(#search-category-cancel)")
          .forEach(elem => {
            elem.addEventListener("click", function (event) {
              var searchCategoryDiv = event.currentTarget;
              var category =
                categoryMap[searchCategoryDiv.getAttribute("data-id")];
              selectedCategory = category;
              var selectedCategoryDiv = searchDiv.getElementsByClassName(
                "selected-category",
              )[0];
              selectedCategoryDiv.classList.add("active");
              selectedCategoryDiv.getElementsByClassName(
                "selected-category-name",
              )[0].textContent = category.display_name;
              selectedCategoryDiv.style.backgroundColor =
                category.primary_color;
              refreshAvailableCategories();
              refreshSearchResults();
            });
          });

        if (searchCategoriesDiv.querySelector("#search-category-cancel"))
          searchCategoriesDiv
            .querySelector("#search-category-cancel")
            .addEventListener("click", function (event) {
              selectedCategory = null;
              searchDiv
                .getElementsByClassName("selected-category")[0]
                .classList.remove("active");
              refreshAvailableCategories();
              refreshSearchResults();
            });
      }

      searchDiv
        .getElementsByClassName("selected-category-delete")[0]
        .addEventListener("click", function () {
          selectedCategory = null;
          searchDiv
            .getElementsByClassName("selected-category")[0]
            .classList.remove("active");
          refreshAvailableCategories();
          map.data.forEach(function (feature) {
            checkFeatureValidity(feature);
          });
          refreshSearchResults();
        });

      searchDiv
        .querySelector("#search-query")
        .addEventListener("keypress", function (e) {
          if (e.key === "Enter")
            // Enter Key
            collapseSearchView();
        });

      refreshAvailableCategories();

      Array.prototype.forEach.call(
        searchEntitiesDiv.getElementsByClassName("search-entity"),
        function (entity) {
          entity.addEventListener("click", function (event) {
            const searchEntityDiv = event.currentTarget;
            const searchEntity =
              searchEntityMap[
                searchEntityDiv.getAttribute("data-type") +
                  "-" +
                  searchEntityDiv.getAttribute("data-id")
              ];
            collapseSearchView(function () {
              const levelSwitchCompletion = function () {
                map.data.forEach(function (feature) {
                  if (
                    searchEntity.properties.type === "poi" &&
                    feature.getGeometry().getType() === "Point" &&
                    feature.getProperty("type") === "poi" &&
                    feature.getProperty("id") === searchEntity.properties.id
                  ) {
                    feature.setProperty("selected", true);
                    map.setCenter(feature.getGeometry().get());
                  }
                });
              };

              if (
                searchEntity.properties.level_ids.includes(
                  parseInt(
                    document
                      .getElementById("venue_levels")
                      .nextSibling.getAttribute("value"),
                  ),
                )
              ) {
                levelSwitchCompletion();
              } else {
                selectSearchFeature.current = {
                  type: searchEntity.properties.type,
                  id: searchEntity.properties.id,
                };
                setLevelValue({
                  level: String(searchEntity.properties.level_ids[0]),
                  refreshControl: true,
                });
              }
            });
          });
        },
      );

      searchDiv.style.zIndex = 1;
      map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
        searchDiv,
      );

      searchDiv
        .getElementsByClassName("search-query-input")[0]
        .addEventListener("focus", function (event) {
          reorderSearchResults();
          expandSearchView();
        });
      searchDiv
        .getElementsByClassName("search-query-input")[0]
        .addEventListener("input", function (event) {
          refreshSearchResults();
        });
      searchDiv
        .querySelector("#search-close")
        .addEventListener("click", function (event) {
          collapseSearchView();
        });

      const animationTiming = {
        duration: 500,
        iterations: 1,
      };

      function expandSearchView() {
        const current_height = searchDiv.clientHeight;
        searchDiv.animate(
          [
            { height: current_height + "px" },
            { height: window.innerHeight + "px" },
          ],
          animationTiming,
        );
        searchDiv.classList.add("expanded");
      }

      function collapseSearchView(completion) {
        searchDiv.getElementsByClassName("search-query-input")[0].blur();
        const current_height = searchDiv.clientHeight;
        searchDiv.classList.remove("expanded");
        const newHeight = searchDiv.clientHeight;
        map.data.forEach(function (feature) {
          checkFeatureValidity(feature);
        });
        searchDiv.animate(
          [{ height: current_height + "px" }, { height: newHeight + "px" }],
          animationTiming,
        );
        if (completion) completion();
      }

      map.data.addListener("addfeature", function (featureInfo) {
        var feature = featureInfo.feature;
        if (
          feature.getGeometry().getType() === "Point" &&
          feature.getProperty("type") === "poi"
        ) {
          checkFeatureValidity(feature);
        }
      });

      function checkFeatureValidity(feature) {
        if (
          feature.getGeometry().getType() === "Point" &&
          feature.getProperty("type") === "poi"
        ) {
          const searchQuery = searchDiv.querySelector(".search-query-input")
            .value;
          let featureIsValid = true;
          featureIsValid &=
            searchQuery === null ||
            searchQuery.length === 0 ||
            feature
              .getProperty("name")
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (feature.getProperty("level_ids") &&
              getLevelName({
                level_ids: feature.getProperty("level_ids"),
                levels: venueData.levels,
              })
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (feature.getProperty("location") &&
              feature
                .getProperty("location")
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (feature.getProperty("keywords") &&
              feature.getProperty("keywords").some(function (keyword) {
                return keyword
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase());
              }));

          if (selectedCategory != null) {
            function matchesCategory(category) {
              if (category.id === feature.getProperty("category_id"))
                return true;
              else if (category.child_categories) {
                return category.child_categories.some(matchesCategory);
              }
            }

            featureIsValid &= matchesCategory(selectedCategory);
          }

          feature.setProperty("client_hidden", !featureIsValid);
        }
      }

      function reorderSearchResults() {
        if (currentLocation) {
          var searchEntities = searchEntitiesDiv.querySelectorAll(
            ".search-entity",
          );
          var searchEntitiesSorted = searchEntities.sort(function (a, b) {
            var distanceA = Math.sqrt(
              Math.pow(currentLocation.latitude - a.dataset.latitude, 2) +
                Math.pow(currentLocation.longitude - a.dataset.longitude, 2),
            );
            var distanceB = Math.sqrt(
              Math.pow(currentLocation.latitude - b.dataset.latitude, 2) +
                Math.pow(currentLocation.longitude - b.dataset.longitude, 2),
            );
            return distanceA > distanceB ? 1 : -1;
          });

          searchEntitiesDiv.append(searchEntitiesSorted);
        }
      }

      function refreshSearchResults() {
        const searchQuery = searchDiv.querySelector(".search-query-input")
          .value;
        searchEntitiesDiv
          .querySelectorAll(".search-entity")
          .forEach(function (searchEntityDiv) {
            const searchEntity =
              searchEntityMap[
                searchEntityDiv.dataset.type + "-" + searchEntityDiv.dataset.id
              ];
            let entityIsValid = true;
            entityIsValid &=
              searchQuery == null ||
              searchQuery.length === 0 ||
              searchEntity.properties.name
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              (categoryMap[searchEntity.properties.category_id] &&
                categoryMap[searchEntity.properties.category_id].display_name &&
                categoryMap[searchEntity.properties.category_id].display_name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (searchEntity.properties.level_ids &&
                getLevelName({
                  level_ids: searchEntity.properties.level_ids,
                  levels: venueData.levels,
                })
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (searchEntity.properties.location &&
                searchEntity.properties.location
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (searchEntity.properties.keywords &&
                searchEntity.properties.keywords.some(function (keyword) {
                  return keyword
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
                }));

            if (selectedCategory != null) {
              function matchesCategory(category) {
                if (category.id === searchEntity.properties.category_id)
                  return true;
                else if (category.child_categories) {
                  return category.child_categories.some(matchesCategory);
                }
              }

              entityIsValid &= matchesCategory(selectedCategory);
            }

            if (entityIsValid) {
              searchEntityDiv.hidden = false;
            } else {
              searchEntityDiv.hidden = true;
            }
          });
      }

      let currentLocation = null;
      if (venueData.ShowUserLocation === true) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            currentLocation = position.coords;
            reorderSearchResults();
          });
          navigator.geolocation.watchPosition(function () {
            reorderSearchResults();
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // update features on map
  useEffect(() => {
    if (map) {
      if (poiDetail.showPoi) setPoiDetail(initialPoiDetail);
      map.removeAllFeatures();
      features.forEach(feature =>
        map.addFeature({ map, geoJSON: feature, nonGeoJsonFeatures }),
      );
      if (selectSearchFeature.current) {
        map.data.forEach(function (feature) {
          if (
            selectSearchFeature.current.type === "poi" &&
            feature.getGeometry().getType() === "Point" &&
            feature.getProperty("type") === "poi" &&
            feature.getProperty("id") === selectSearchFeature.current.id
          ) {
            feature.setProperty("selected", true);
            map.setCenter(feature.getGeometry().get());
          }
        });
        selectSearchFeature.current = null;
      }
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);
  return (
    <>
      {showLoader && <Loader />}
      <div ref={mapRef} style={mapDivStyle} id="map" />
    </>
  );
}
export default Map;
