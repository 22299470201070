const Animation_Navigation = [
  { label: "Slide Up", value: "slide_up" },
  { label: "Slide Left", value: "slide_left" },
];

const Light_Beam_Timeframe = [
  { label: "Home Win", value: "home" },
  { label: "Away Win", value: "away" },
  { label: "Any", value: "any" },
];

const schema = {
  class: {
    title: "Light The Beam Info",
    uid: "light_the_beam_info",
    _schema: [
      {
        display_name: "Enable",
        uid: "enable",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        uid: "animation_time_period_after_win",
        display_name: "Animation Time Period After Win",
        data_type: "group",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [
          {
            uid: "hours",
            display_name: "Hours",
            data_type: "number",
            field_metadata: {},
            mandatory: true,
            multiple: false,
            min: 0,
            defaultValue: 0,
            _schema: [],
          },
          {
            uid: "minutes",
            display_name: "Minutes",
            data_type: "number",
            field_metadata: {},
            mandatory: true,
            multiple: false,
            min: 0,
            max: 59,
            defaultValue: 0,
            _schema: [],
          },
        ],
      },
      {
        display_name: "Text",
        uid: "text",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Sponsor Logo",
        uid: "sponsor_logo",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Animation Navigation",
        uid: "animation_navigation",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...Animation_Navigation],
        },
      },
      {
        display_name: "When",
        uid: "when",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...Light_Beam_Timeframe],
        },
      },
      {
        display_name: "Lottie File",
        uid: "lottie_file",
        mandatory: true,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
    ],
  },
};

export default schema;
