import { Route, Switch } from "react-router-dom";
import { Container as Teams } from "app/pages/Dashboard/Data/Team/Teams/get";
import Addteams from "app/pages/Dashboard/Data/Team/Teams/add";
import React from "react";
import Updateteams from "app/pages/Dashboard/Data/Team/Teams/update";

const Team = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={Addteams} />
      <Route path={`${props.match.path}/update/:uid`} component={Updateteams} />
      <Route path={`${props.match.path}`} component={Teams} />
    </Switch>
  );
};

export default Team;
