import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={71}
      height={64}
      viewBox="0 0 71 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M63.8 32.7v3.9c0 1.1.9 2 2 2s2-.9 2-2v-5.9-.5c0-.1-.1-.2-.1-.3L56.5 5c-1.3-3-4.3-5-7.7-5h-30c-3.3 0-6.3 2-7.7 5L.2 29.9c0 .1-.1.2-.1.3v16.4C0 51.2 3.8 55 8.4 55h29c1.1 0 2-.9 2-2s-.9-2-2-2h-29C6 51 4 49 4 46.6V32.7h16.1l5.8 8.7c.4.6 1 .9 1.7.9h12.8c.7 0 1.3-.3 1.7-.9l5.8-8.7h15.9zM45 29.6l-5.8 8.7H28.6l-5.8-8.7c-.4-.6-1-.9-1.7-.9h-16l9.8-22.1c.7-1.6 2.3-2.6 4-2.6h30c1.7 0 3.3 1 4 2.6l9.8 22.1h-16c-.7 0-1.4.3-1.7.9zm12.5 8.9c-7 0-12.6 5.7-12.6 12.7 0 7 5.7 12.7 12.6 12.7 7 0 12.7-5.7 12.7-12.7 0-7-5.8-12.7-12.7-12.7zm9.2 12.7c0 5.1-4.1 9.2-9.2 9.2s-9.2-4.1-9.2-9.2 4.1-9.2 9.2-9.2 9.2 4.1 9.2 9.2zm-3 3.5c.1.4 0 .7-.2 1.1-.2.4-.7.7-1.2.7-.2 0-.5-.1-.7-.2l-5-3c-.5-.3-.8-.9-.8-1.5V46c0-.8.6-1.4 1.4-1.4h.1c.8 0 1.4.6 1.4 1.4v5.2l4.5 2.6c.1.2.4.5.5.9z"
        fill="#1E88E5"
      />
    </svg>
  );
}

export default SvgComponent;
