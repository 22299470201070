import {
  AddCustomField,
  AddObject,
  GetClass,
  GetObjects,
  UpdateCustomField,
  UpdateObject,
} from "app/pages/Dashboard/CustomFields";
import { Route, Switch } from "react-router-dom";

const CustomSchemaRoute = props => {
  return (
    <>
      <Switch>
        {/* <Redirect
          exact
          from={`${props.match.path}`}
          to={`${props.match.url}/get`}
        /> */}
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdateCustomField}
          exact
        />
        <Route
          path={`${props.match.path}/add`}
          component={AddCustomField}
          exact
        />
        <Route
          path={`${props.match.path}/:schema_uid/objects`}
          component={GetObjects}
          exact
        />
        <Route
          path={`${props.match.path}/:schema_uid/objects/add`}
          component={AddObject}
          exact
        />
        <Route
          path={`${props.match.path}/:schema_uid/objects/update/:uid`}
          component={UpdateObject}
          exact
        />
        <Route path={`${props.match.path}`} component={GetClass} />
      </Switch>
    </>
  );
};

export default CustomSchemaRoute;
