import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-vscode";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-dracula";
import { Controller } from "react-hook-form";
import AceEditor from "react-ace";
import get from "lodash/get";
import has from "lodash/has";
import React, { Fragment } from "react";

const JavaScriptEditor = ({
  control,
  defaultValue,
  uid,
  fieldName,
  validations,
  fontSize,
}) => {
  return (
    <Fragment>
      <Controller
        key={uid}
        name={fieldName}
        control={control}
        rules={validations}
        defaultValue={defaultValue}
        render={({ field, formState }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, fieldName);
          const fieldError = get(errors, fieldName);
          return (
            <AceEditor
              style={{ minHeight: "300px" }}
              width="100%"
              mode="javascript"
              theme="dracula"
              name="javascript_code_editor"
              fontSize={fontSize}
              editorProps={{ $blockScrolling: true }}
              value={field.value}
              helperText={hasFieldError && fieldError.message}
              onChange={field.onChange}
              error={hasFieldError}
              inputRef={field.ref}
              showPrintMargin={false}
              showGutter={true}
              wrapEnabled={true}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          );
        }}
      />
    </Fragment>
  );
};

export default JavaScriptEditor;
