const schema = {
  class: {
    title: "Stories",
    uid: "stories",
    _schema: [
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "title",
        display_name: "Title",
        data_type: "string",
        field_metadata: {
          additional_options: ["Mandatory"],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
      },
      // {
      //   multiple: false,
      //   mandatory: true,
      //   _schema: [],
      //   uid: "category",
      //   display_name: "Category",
      //   data_type: "string",
      //   field_metadata: {
      //     additional_options: ["Mandatory"],
      //     options: [
      //       {
      //         label: "Mandatory",
      //         value: "mandatory",
      //       },
      //       {
      //         label: "Multiple",
      //         value: "multiple",
      //       },
      //       {
      //         label: "Multiline",
      //         value: "multiline",
      //       },
      //     ],
      //   },
      // },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "position",
        display_name: "Position",
        data_type: "number",
        field_metadata: {},
        min: 0,
      },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "section",
        display_name: "Section",
        data_type: "string",
        field_metadata: {},
      },
      {
        multiple: false,
        mandatory: true,
        _schema: [],
        uid: "story_type",
        display_name: "Story Type",
        data_type: "string",
        field_metadata: {
          additional_options: ["Mandatory"],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [],
        uid: "image",
        display_name: "Image",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [],
        uid: "image_overlay",
        display_name: "Image Overlay",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "title",
            display_name: "Title",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "cta_link",
            display_name: "CTA Link",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "bg_color",
            display_name: "Background Color",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "text_color",
            display_name: "Text Color",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
        ],
        uid: "cta",
        display_name: "CTA Button",
        data_type: "group",
        field_metadata: {
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
          additional_options: [],
        },
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [],
        uid: "cs_uid",
        display_name: "ContentStack Uid",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
          readOnly: true,
        },
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "uri",
            display_name: "URI",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "hls_link",
            display_name: "HLS Video Link",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "picture_id",
            display_name: "Picture ID",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "duration",
            display_name: "Duration",
            data_type: "number",
            field_metadata: {
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              additional_options: [],
            },
          },
          {
            multiple: false,
            mandatory: false,
            _schema: [],
            uid: "video_id",
            display_name: "Video ID",
            data_type: "string",
            field_metadata: {
              additional_options: [],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
                {
                  label: "Multiline",
                  value: "multiline",
                },
              ],
            },
          },
        ],
        uid: "video_detail",
        display_name: "Video details",
        data_type: "group",
        field_metadata: {
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
          additional_options: [],
        },
      },
      {
        multiple: false,
        mandatory: false,
        _schema: [],
        uid: "status",
        display_name: "Upload Status",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
      },
    ],
  },
};

export default schema;
