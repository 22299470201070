import { getYearArray } from "utils/functions";

const GAME_TYPE = [
  { label: "Home", value: "home" },
  { label: "Away", value: "away" },
];

const schema = {
  class: {
    title: "Game Player Logs",
    uid: "game_player_logs",
    _schema: [
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },

      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Person ID",
        uid: "pid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game ID",
        uid: "gid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Time",
        uid: "gametime",
        mandatory: true,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Opponent Team ID",
        uid: "opp_tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Opponent Team Abbreviation",
        uid: "opp_ta",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Type",
        uid: "game_type",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...GAME_TYPE],
        },
      },
      {
        display_name: "First Name",
        uid: "fn",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Last Name",
        uid: "ln",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Jersey Number",
        uid: "num",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Position",
        uid: "pos",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Minutes Per Game",
        uid: "min",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Sec",
        uid: "sec",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Total Sec",
        uid: "totsec",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Field Goals Attempted",
        uid: "fga",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Field Goals Made",
        uid: "fgm",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Three Pointers Attempted",
        uid: "tpa",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Three Pointers Made",
        uid: "tpm",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Free throws Attempted",
        uid: "fta",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Free throws Made",
        uid: "ftm",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Offensive Rebounds",
        uid: "oreb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Defensive Rebounds",
        uid: "dreb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Rebounds",
        uid: "reb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Assists",
        uid: "ast",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Steals",
        uid: "stl",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Blocks",
        uid: "blk",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Fouls",
        uid: "pf",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Points",
        uid: "pts",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Turnovers",
        uid: "tov",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Fastbreak Points",
        uid: "fbpts",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Fastbreak Points Attempted",
        uid: "fbptsa",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Fastbreak Points Made",
        uid: "fbptsm",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Points in the Paint",
        uid: "pip",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Points in the Paint Attempted",
        uid: "pipa",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Points in the Paint Made",
        uid: "pipm",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Court",
        uid: "court",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Plus/Minus",
        uid: "pm",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Blocks Against",
        uid: "blka",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Team Technical Fouls",
        uid: "tf",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Status",
        uid: "status",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Field Goals Percentage",
        uid: "fg_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Three Point Percentage",
        uid: "tp_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Free Throw Percentage",
        uid: "ft_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Result",
        uid: "res",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Result Difference",
        uid: "res_diff",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Memo",
        uid: "memo",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
