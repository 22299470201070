import { AuthForm, PasswordWrap } from "../account.style";
import { authServices } from "services/auth";
import { Button } from "@dfep/ui-component";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { encryptPassword } from "utils/crypto";
import { IconButton, InputAdornment, Typography } from "@material-ui/core";
import { provideValidationToggle } from "utils/passwordValidation";
import { setError, validatePassword } from "utils/functions";
import { setKey } from "utils/cookies";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import cloneDeep from "lodash/cloneDeep";
import Grid from "@material-ui/core/Grid";
import PasswordValidator from "app/components/Common/PasswordValidator";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  currentPassword: {
    hasError: false,
    message: "",
  },
  newPassword: {
    hasError: false,
    message: "",
  },
  confirmPassword: {
    hasError: false,
    message: "",
  },
};

const ChangePassword = props => {
  const [showPassword, setShowPassword] = useState({
    currentPass: false,
    newPass: false,
  });
  const [currentPassword, bindCurrentPasswrd, resetCurrentPasswrd] = useInput(
    "",
  );
  const [newPassword, bindNewPasswrd, resetNewPasswrd] = useInput("");
  const [confirmPassword, bindConfirmPasswrd, resetConfirmPasswrd] = useInput(
    "",
  );
  const [passwordError, setPasswordError] = useState(
    cloneDeep(initialErrorState),
  );
  const [openValidationBar, setPasswordValidationBar] = useState(false);

  const formValidation = () => {
    if (!currentPassword) {
      const error = setError(
        passwordError,
        "currentPassword",
        "Field is Required!",
      );
      setPasswordError(cloneDeep(error));
    } else if (!newPassword) {
      const error = setError(
        passwordError,
        "newPassword",
        "Field is Required!",
      );
      setPasswordError(cloneDeep(error));
    } else if (!confirmPassword) {
      const error = setError(
        passwordError,
        "confirmPassword",
        "Field is Required!",
      );
      setPasswordError(cloneDeep(error));
    } else if (!validatePassword(newPassword)) {
      const error = setError(
        passwordError,
        "newPassword",
        "Invalid Password Format!",
      );
      setPasswordError(cloneDeep(error));
    } else if (newPassword === currentPassword) {
      const error = setError(
        passwordError,
        "newPassword",
        "New Password matches Current Password!",
      );
      setPasswordError(cloneDeep(error));
    } else if (newPassword !== confirmPassword) {
      const error = setError(
        passwordError,
        "confirmPassword",
        "Password did not match!",
      );
      setPasswordError(cloneDeep(error));
    } else {
      const oldEncryptedPass = encryptPassword(currentPassword);
      const newEncryptedPass = encryptPassword(
        newPassword,
        new Buffer.from(oldEncryptedPass.iv, "hex"),
      );
      const form = {
        old_password: oldEncryptedPass.password,
        new_password: newEncryptedPass.password,
        iv: oldEncryptedPass.iv,
      };
      return form;
    }

    return false;
  };

  const onFormSubmit = async event => {
    event.preventDefault();
    try {
      const form = formValidation();
      if (form) {
        const result = await props.changePasswordService({
          url: "/v1/admin/auth/user/change_password",
          form,
          method: "PUT",
          showToast: true,
        });
        setKey(Constants.ACCESS_TOKEN, result.data.tokens.access_token);
        setKey(Constants.REFRESH_TOKEN, result.data.tokens.refresh_token);
        resetCurrentPasswrd();
        resetNewPasswrd();
        resetConfirmPasswrd();
      }
    } catch (err) {}
  };

  useEffect(() => {
    setPasswordError(cloneDeep(initialErrorState));
  }, [currentPassword, newPassword, confirmPassword]);

  useEffect(() => {
    if (openValidationBar) {
      provideValidationToggle(newPassword);
    }
  }, [newPassword, openValidationBar]);

  const onCancel = () => {
    resetCurrentPasswrd();
    resetConfirmPasswrd();
    resetNewPasswrd();
  };

  return (
    <AuthForm className="auth-form">
      <Typography variant="h6">Change Password</Typography>
      <form data-testid="cp-form" noValidate onSubmit={onFormSubmit}>
        <Grid container className="grid-wrap">
          <Grid item md={6} sm={12} xs={12} className="grid-col">
            <TextField
              label="Enter Current Password"
              id="current-password"
              type={showPassword.currentPass ? "text" : "password"}
              {...bindCurrentPasswrd}
              error={passwordError.currentPassword.hasError}
              helperText={passwordError.currentPassword.message}
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="current-password-eye-toggle"
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          currentPass: !showPassword.currentPass,
                        })
                      }
                    >
                      {showPassword.currentPass ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="grid-col">
            <PasswordWrap>
              <TextField
                label=" Enter New Password"
                id="new-password"
                type={showPassword.newPass ? "text" : "password"}
                {...bindNewPasswrd}
                error={passwordError.newPassword.hasError}
                helperText={passwordError.newPassword.message}
                onFocus={() => setPasswordValidationBar(true)}
                onBlur={() => {
                  setPasswordValidationBar(false);
                }}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="new-password-eye-toggle"
                        aria-label="toggle password visibility"
                        onClick={e => {
                          e.stopPropagation();
                          setShowPassword({
                            ...showPassword,
                            newPass: !showPassword.newPass,
                          });
                        }}
                      >
                        {showPassword.newPass ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {openValidationBar && <PasswordValidator />}
            </PasswordWrap>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="grid-col">
            <TextField
              label="Enter Confirm Password"
              id="cnf-password"
              type="password"
              required={true}
              {...bindConfirmPasswrd}
              error={passwordError.confirmPassword.hasError}
              helperText={passwordError.confirmPassword.message}
            />
          </Grid>
        </Grid>
        <div className="field-wrap">
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Update Password
          </Button>
        </div>
      </form>
    </AuthForm>
  );
};

ChangePassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapDispatchToProps = dispatch => {
  return {
    changePasswordService: payload => dispatch(authServices(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(ChangePassword);

export { Container, ChangePassword as Component };
