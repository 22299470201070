/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddFieldsTree,
  IconWrap,
  ParentFieldsWrap,
  TreeFormWrapper,
} from "./customFieldForm.style";
import { Button, Modal } from "@dfep/ui-component";
import { customFieldsOptions as c_options } from "utils/constants";
import { Constants } from "utils/constants";
import { convertToSlug } from "utils/functions";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useModal } from "app/hooks/modal";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import ChildFields from "./ChildFields";
import Grid from "@material-ui/core/Grid";
import PanelFooter from "app/components/Common/PanelFooter";
import PropTypes from "prop-types";
import Text from "./CustomFields/Text";

const UseWatchRender = ({ schema, control, setValue, getValues }) => {
  const title = useWatch({
    control,
    name: "title",
    defaultValue: schema.class.title,
  });
  useEffect(() => {
    if (schema.class.title !== getValues("title")) {
      setValue("uid", convertToSlug(title), {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [title]);

  return <></>;
};

export default function TreeView({
  showDownloadbtn,
  schema,
  onSubmit,
  schema_type,
  readOnly,
  backUrl,
  showErrors,
  exportSchema,
  retrieveFiles,
  ...restProps
}) {
  const { show, showDelete, openModalHandler, closeModalHandler } = useModal();
  //input ref
  const fileInputRef = useRef();

  const { access_type } = useSelector(state => state.application);

  const customFieldForm = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {
      schema_type,
      uid: schema.class.uid,
      title: schema.class.title,
      _schema: [...schema.class._schema],
    },
    shouldUnregister: false,
  });

  const {
    push,
    location: { state },
  } = useHistory();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { dirtyFields, errors },
  } = customFieldForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "_schema",
  });

  useEffect(() => {
    /*istanbul ignore else*/
    if (showErrors) {
      showErrors(errors);
    }
  }, [Object.keys(errors).length]);

  const appendCustomField = () => {
    append({
      uid: "",
      display_name: "",
      data_type: "string",
      field_metadata: {
        additional_options: [],
        options: [...c_options],
      },
      _schema: [],
    });
  };

  return (
    <>
      <TreeFormWrapper {...restProps}>
        <UseWatchRender
          schema={schema}
          setValue={setValue}
          getValues={getValues}
          control={control}
        />
        <FormProvider {...customFieldForm}>
          <form noValidate onSubmit={handleSubmit(onSubmit)} id="customFields">
            <AddFieldsTree>
              <ParentFieldsWrap>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Text
                      name="title"
                      display_name="Title"
                      data_type="text"
                      validations={{
                        required: "Title is Required!",
                      }}
                      defaultValue={getValues(`title`)}
                      readOnly={!!readOnly?.title}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Text
                      name="uid"
                      display_name="UID"
                      data_type="text"
                      validations={{
                        required: "UID is Required!",
                      }}
                      readOnly={!!readOnly?.uid}
                      defaultValue={getValues(`uid`)}
                    />
                  </Grid>
                </Grid>
              </ParentFieldsWrap>
              {fields.map((data, index) => {
                return (
                  <ChildFields
                    {...data}
                    key={data.id}
                    name={`_schema[${index}]`}
                    index={index}
                    control={control}
                    remove={remove}
                  />
                );
              })}
              <IconWrap className="main-icon-wrap">
                <span className="icon-path">
                  <AddIcon onClick={appendCustomField} data-testid="append" />
                </span>
              </IconWrap>
            </AddFieldsTree>
          </form>
        </FormProvider>
        <PanelFooter>
          <div>
            {schema_type === "template" && (
              <>
                {access_type !== Constants.READ_ONLY && (
                  <Button
                    data-testid="custom-fields-upload"
                    form="customFields"
                    variant="contained"
                    color="primary"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <input
                      ref={fileInputRef}
                      accept=".json"
                      data-testid="import-schema"
                      id="json-file-upload-input"
                      type="file"
                      onChange={e => {
                        /*istanbul ignore else*/
                        if (e.target.files.length > 0) {
                          openModalHandler("show");
                        }
                      }}
                      hidden
                    />
                    Upload
                  </Button>
                )}
                {showDownloadbtn && (
                  <Button
                    data-testid="custom-fields-download"
                    form="customFields"
                    variant="contained"
                    color="primary"
                    onClick={event => {
                      if (
                        access_type !== Constants.READ_ONLY &&
                        Object.keys(dirtyFields).length > 0
                      ) {
                        openModalHandler("showDelete");
                      } else {
                        exportSchema(event);
                      }
                    }}
                    // disabled={Object.keys(dirtyFields).length > 0}
                  >
                    Download
                  </Button>
                )}
              </>
            )}
            {backUrl && (
              <Button
                data-testid="cancel-button"
                onClick={() =>
                  push(
                    state
                      ? state.from.pathname + state.from.search
                      : `${backUrl}`,
                  )
                }
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            )}
            {access_type !== Constants.READ_ONLY && (
              <Button
                data-testid="custom-fields-submit"
                type="submit"
                form="customFields"
                variant="contained"
                color="primary"
                disabled={Object.keys(dirtyFields).length === 0}
              >
                Save
              </Button>
            )}
          </div>
        </PanelFooter>
      </TreeFormWrapper>
      <Modal
        data-testid="upload-modal"
        className="small"
        open={show}
        handleClose={closeModalHandler}
        title="Upload Template"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: () => {
              fileInputRef.current.value = "";
              closeModalHandler();
            },
            additionalAttributes: {
              "data-testid": "cancel-btn",
            },
          },
          {
            id: "2",
            name: "Upload File",
            handler: () => {
              retrieveFiles(fileInputRef.current.files);
              fileInputRef.current.value = "";
              closeModalHandler();
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "upload-file-btn",
            },
          },
        ]}
      >
        Uploading new schema file will override the existing "
        <strong>{schema?.class?.title}</strong>" permanently. Are you sure you
        want to upload new Template?
      </Modal>

      <Modal
        data-testid="download-modal"
        className="small"
        open={showDelete}
        handleClose={closeModalHandler}
        title="Download Template"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: () => {
              closeModalHandler();
            },
            additionalAttributes: {
              "data-testid": "cancel-down-btn",
            },
          },
          {
            id: "2",
            name: "Download Anyway",
            handler: event => {
              exportSchema(event);
              closeModalHandler();
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "download-file-btn",
            },
          },
        ]}
      >
        It looks like you have been editing something,{" "}
        <strong>first save the form then download</strong>.
      </Modal>
    </>
  );
}

TreeView.defaultProps = {
  schema: {
    class: {
      title: "",
      uid: "",
      _schema: [
        {
          uid: "",
          display_name: "",
          data_type: "string",
          field_metadata: {
            options: c_options,
            additional_options: [],
          },
          _schema: [],
        },
      ],
    },
  },
};

TreeView.propTypes = {
  schema: PropTypes.shape({
    class: PropTypes.shape({
      title: PropTypes.string,
      uid: PropTypes.string,
      _schema: PropTypes.array,
    }),
  }),
};
