import { a11yProps, Tab, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize, initialRoute } from "utils/functions";
import { connect, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { Container as GetStats } from "./GetStats";
import { getTeamStats } from "services/getObjects";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";
import Settings from "./settings";

const StatsTabs = props => {
  const { sport_key, env_key } = props.match.params;
  const { tid, team_name, access_type } = useSelector(
    state => state.application,
  );

  let tabObj = {};
  tabObj[team_name] = 0;

  tabObj = { ...tabObj, others: 1, settings: 2 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const [teamStats, setTeamStats] = useState([]);
  const [statsCount, setStatsCount] = useState(0);
  const { sort, sortValue, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const breadCrumbItems = [
    {
      title: "Team Stats",
    },
  ];

  const syncToTeams = () => {
    openModalHandler("show");
  };

  //sync Teams
  const sendSync = async () => {
    await props.syncTeams({
      url: "/v1/nba/admin/sync/team_stats",
      method: "post",
    });
    closeModalHandler();
  };

  const getTeamStats = useCallback(
    async teamName => {
      setIsLoading(true);
      setTeamStats([]);
      const {
        data: { team_stats, count },
      } = await props.getStats({
        team_name: teamName,
        tid,
        skip,
        limit,
        sort,
        value: sortValue,
        searchText,
      });

      if (count !== 0 && team_stats.length === 0) {
        initialRoute(props.history);
      } else {
        setTeamStats(team_stats);
        setStatsCount(count);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, tid, sortValue, skip, limit, searchText],
  );

  return (
    <>
      <PanelHeader
        title="Team Stats"
        breadCrumbItems={breadCrumbItems}
        refreshHandler={() => {
          activeTab === 0 ? getTeamStats(team_name) : getTeamStats("others");
        }}
        sync={syncToTeams}
        hasSyncCMS={false}
        syncToCMS={{}}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            data-testid="add-btn"
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/team/stats/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
            closeModalHandler();
          }}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${props.match.url}/${team_name}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${props.match.url}/others`}
            {...a11yProps(1)}
          />

          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(2)}
          />
        </Tabs>
      )}
      <Switch>
        {/* <TabPanel> */}
        <Redirect
          from={`${props.match.path}`}
          to={`${props.match.url}/${team_name}`}
          exact
        />
        <Route
          path={`${props.match.path}/settings`}
          component={Settings}
          exact
        />
        <Route
          path={`${props.match.path}/:team_name`}
          /*istanbul ignore next*/
          render={Routeprops => {
            /*istanbul ignore next*/
            return (
              <GetStats
                {...Routeprops}
                isLoading={isLoading}
                teamStats={teamStats}
                statsCount={statsCount}
                getTeamStats={getTeamStats}
                openSync={show}
                closeSync={closeModalHandler}
                sendSync={sendSync}
                access_type={access_type}
              />
            );
          }}
          exact
        />
        {/* </TabPanel> */}
      </Switch>
    </>
  );
};

/* istanbul ignore next */
const mapDisptachToProps = dispatch => {
  return {
    syncTeams: payload => dispatch(requestAPI(payload)),
    getStats: payload => dispatch(getTeamStats(payload)),
  };
};

const Container = connect(null, mapDisptachToProps)(StatsTabs);
export { Container, StatsTabs as Component };
