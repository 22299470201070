import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import TreeView from "app/components/Common/CustomFieldForm";

const AddCustomFields = ({ match, history }) => {
  // Params
  const { sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Functions
  const onSubmit = async data => {
    const form = { ...data };
    const { status } = await dispatch(
      createRecord({
        url: `/v1/custom_schema/admin/classes`,
        key: "class",
        form,
        loader: true,
      }),
    );
    status === 201 && push(`/dashboard/${sport_key}/${env_key}/customField`);
  };

  // LifeCycle

  // Render
  return (
    <>
      <PanelHeader
        data-testid="customfields-header"
        backUrl={`/dashboard/${sport_key}/${env_key}/customField`}
        title="Add Custom Field"
        breadCrumbItems={[{ title: "Custom Field" }]}
      />
      <TreeView
        data-testid="customfield-treeview"
        onSubmit={onSubmit}
        schema_type="collection"
        backUrl={`/dashboard/${sport_key}/${env_key}/customField`}
      />
    </>
  );
};

export default AddCustomFields;
