import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import React from "react";

const CheckboxField = ({
  uid,
  display_name,
  mandatory,
  isBorder = false,
  onFieldDelete,
  index,
  defaultValue,
  visible = true,
  field_metadata,
}) => {
  const { control, getValues } = useFormContext();

  return (
    <Grid item key={uid}>
      <Controller
        name={`${uid}`}
        control={control}
        rules={{
          required: mandatory ? `${display_name} is required` : false,
        }}
        defaultValue={defaultValue ?? getValues(`${uid}`)}
        render={({ field, formState }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, `${field.name}`);
          const fieldError = get(errors, `${field.name}`);
          return (
            <FieldBorder isBorder={isBorder} isError={hasFieldError}>
              {visible && (
                <FormControlLabel
                  label={display_name}
                  inputRef={field.ref}
                  control={
                    <Checkbox
                      checked={field.value}
                      value={field.value}
                      name={field.name}
                      inputProps={{
                        "data-testid": `${uid}-checkbox`,
                      }}
                      onChange={event => {
                        return field.onChange(event.target.checked);
                      }}
                      disabled={!!field_metadata?.readOnly}
                    />
                  }
                />
              )}
              {hasFieldError && (
                <FormHelperText data-testid={`${uid}-error`} error>
                  {fieldError?.message}
                </FormHelperText>
              )}
              {onFieldDelete && (
                <IconButton onClick={event => onFieldDelete(event, index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </FieldBorder>
          );
        }}
      />
    </Grid>
  );
};

export default CheckboxField;
