import { deleteConfirmationMessage } from "utils/functions";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React, { Fragment } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const TableView = props => {
  return (
    <Fragment>
      {props.isLoading ? (
        <SkeletonTable
          isSorting
          headings={props.headings}
          checkbox={props.checkbox}
          name={props.name}
          actions={props.actions}
        />
      ) : props.totalCount > 0 ? (
        <Table {...props} />
      ) : (
        <NoData>No Data Found</NoData>
      )}
      <Modal
        className="small"
        open={props.openModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: props.handleModalDelete,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: props?.deleteData?.tid,
          module: props?.name,
        })}
      </Modal>
      <Modal
        className="small"
        open={props.multipleModal}
        handleClose={props.handleModalClose}
        title={`Delete ${props?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: props.handleMultiDelete,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        Are you sure want to delete <strong>{props.numSelected}</strong> items?
      </Modal>
      <Modal
        className="small"
        open={props.openSync}
        handleClose={props.closeSync}
        title="Sync"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.closeSync },
          {
            id: "2",
            name: "Start",
            handler: props.sendSync,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-btn",
            },
          },
        ]}
      >
        Are you sure want to start team leaders sync?
      </Modal>
    </Fragment>
  );
};

export default TableView;
