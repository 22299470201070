import MuiTypography from "@material-ui/core/Typography";
import Styled from "styled-components";

export const Typography = Styled(MuiTypography)`
    &.MuiTypography-root{
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        &.message{
            margin-top: 12px;
            color: ${({ theme }) => theme.palette.black.blackLight};
        }
        &.small{
            font-size: 10px;
            line-height: 20px;
            color: rgba(158, 158, 158, 1);
            margin: 5px 0;
            align-self: flex-start;
        }
        &.empty-text{
            font-size: 14px;
            line-height: 21px;
            color: rgba(158, 158, 158, 1);
            margin: auto;
        }
    }
`;
export const FileUploadWrapper = Styled.div`
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .drop-container {
        text-align: center;
        width: 100%;
        height: 210px;    
        border: 2px dashed color: ${({ theme }) =>
          theme.palette.grey.greyLight};
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upload-icon {
        height: 34px;
        width: 51px;
        margin: 0 auto;
    }
    .file-display-container {
        background-color: ${({ theme }) => theme.palette.grey.greyLighter};
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y:auto;
        padding: 10px;
        margin: 12px 0 0;
    }
    .file{
        display: flex;
        align-items: center;
    }
    .file-status-bar{
        width: 100%;
        display: flex;
        align-items: center;   
        justify-content: space-between;
    }
    .file-name {
        vertical-align:top;
        margin-left: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 295px;
        word-break: break-word;
        overflow: hidden;
    }
    .file-type-logo {
        width: 30px;
        height: 30px;
    }
    .file-size {
        display:inline-block;
        vertical-align:top;
        margin:0 5px 0 10px;
        font-weight: 700;
        font-size: 14px;
    }
    .file-remove  {
        cursor: pointer;
        line-height: 0;
    }
    .file-input {
        display: none;
    }
`;
