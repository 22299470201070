import MuiChip from "@material-ui/core/Chip";
import MuiPaper from "@material-ui/core/Paper";
import styled from "styled-components";

export const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
    padding: 15px 0px 0;
    box-shadow: none;
  }
`;

export const Chip = styled(MuiChip)`
  &.MuiChip-root {
    margin: 5px 10px 5px 0;
    background: rgba(30, 136, 229, 0.6);
    border: 0;
    height: 100%;
    font-size: 13px;
    padding: 4px 8px;
    border-radius: 16.5px;
    &:focus {
      background: rgba(30, 136, 229, 0.6);
    }
    .MuiChip-label {
      padding-left: 0;
      color: ${props => props.theme.palette.white.main};
    }
    .MuiSvgIcon-root {
      margin-right: 0;
    }
  }
  &.MuiChip-icon {
    margin: 10px;
  }
`;
