import { Route, Switch } from "react-router-dom";
import AddFeature from "./add";
import GetFeatures from "./get";
import React from "react";
import UpdateFeature from "./update";

const Features = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddFeature} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateFeature}
        exact
      />
      <Route path={`${props.match.path}`} component={GetFeatures} />
    </Switch>
  );
};

export default Features;
