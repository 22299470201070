import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Advance player stats",
    uid: "advance_player_stats",
    _schema: [
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Player ID",
        uid: "pid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Offensive Rebounds",
        uid: "oreb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Defensive Rebounds",
        uid: "dreb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Total Rebounds",
        uid: "reb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Assists",
        uid: "ast",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Steals",
        uid: "stl",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Blocks",
        uid: "blk",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Points",
        uid: "pts",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Pie",
        uid: "pie",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Assist Ratio",
        uid: "astr",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Offensive Rebound Percentage",
        uid: "oreb_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Defensive Rebound Percentage",
        uid: "dreb_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Total Rebound Percentage",
        uid: "reb_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "True Shooting Percentage",
        uid: "ts",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Effective Field Goal Percentage",
        uid: "efg",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Offensive Rating",
        uid: "offr",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Defensive Rating",
        uid: "defr",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Net Rating",
        uid: "netr",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Pace",
        uid: "pace",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Team Turnover Percentage",
        uid: "tov",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Roster Status",
        uid: "rs",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
    ],
  },
};

export default schema;
