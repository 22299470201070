import { capitalize } from "utils/functions";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";

import { PUSH_STATUS } from "utils/constants";
import { TableView } from "./MessagesView";
import { updateRecord } from "services/updateObject";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import { useSelector } from "react-redux";
import AddToPhotosSharpIcon from "@material-ui/icons/AddToPhotosSharp";
import ArchiveRoundedIcon from "@material-ui/icons/ArchiveRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import InAppMessageIcon from "assets/message_modal/InAppMessageIcon.js";
import PageviewIcon from "@material-ui/icons/Pageview";
import PushIcon from "assets/message_modal/PushIcon.js";
import PushMessageIcon from "assets/message_modal/PushPlusMessageIcon.js";
import React, { Fragment, useEffect, useState } from "react";

const PushMessage = props => {
  const { access_type } = useSelector(state => state.application);
  const { status, sport_key, env_key } = props.match.params;
  const { data, Messagescount, isLoading, getData } = props;
  const [uid, setUId] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const { show, showDelete, openModalHandler, closeModalHandler } = useModal();
  const { searchText } = useQueryParams();

  useEffect(() => {
    getData(status);
  }, [getData, status]);

  const headings = [
    { label: "Title", id: "title" },
    { label: "Type", id: "type" },
    { label: "Provider", id: "pn_type" },
    searchText
      ? /* istanbul ignore next */
        { label: "Status", id: "status" }
      : { label: "Channels", id: "channels" },
    status === PUSH_STATUS.draft
      ? { label: "Updated At", id: "updated_at", type: "date" }
      : { label: "Created At", id: "created_at", type: "date" },
  ];

  // const reportHandler = (event, uid) => {
  //   props.history.push({
  //     pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/report/${uid}`,
  //   });
  // };

  const editHandler = (event, uid, data) => {
    const { type } = data;
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/update/${uid}`,
      search: `?type=${type}`,
      state: { from: props.location },
    });
  };

  const duplicateHandler = (event, uid, data) => {
    const { type } = data;
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/update/${uid}`,
      search: `?type=${type}`,
      state: { from: props.location },
    });
  };

  const PushHideHandler = (event, uid, data) => {
    const { type } = data;
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/update/${uid}`,
      search: `?type=${type}`,
      state: { from: props.location },
    });
  };

  const archiveHandler = (event, uid, data) => {
    setDeleteData(data);
    setUId(uid);
    openModalHandler("show");
  };

  const deleteHandler = (event, uid, data) => {
    /* istanbul ignore else */
    if (data.status === PUSH_STATUS.draft) {
      setDeleteData(data);
      setUId(uid);
      openModalHandler("showDelete");
    }
  };

  const handleModalClose = () => {
    closeModalHandler();
    setUId("");
    setDeleteData({});
  };

  const handleModalDelete = async () => {
    const { data } = await props.deletePushMessage({
      url: `/v1/push_notification/admin/push/draft/${uid}`,
    });
    /* istanbul ignore else */
    if (data.notice) {
      closeModalHandler();
      setDeleteData({});
      setUId("");
      getData(status);
    }
  };

  const archiveModalClose = () => {
    closeModalHandler();
    setUId("");
    setDeleteData({});
  };

  const handleArchiveModal = async () => {
    let form = { hide_status: true };
    const { data } = await props.archivePushMessage({
      url: `/v1/push_notification/admin/push/${uid}/hide_status`,
      key: "push_notification",
      form,
    });
    /* istanbul ignore else */
    if (data.push_notification_data.payload.status === PUSH_STATUS.hide) {
      closeModalHandler();
      setUId("");
      setDeleteData({});
      getData(status);
    }
  };

  const actions =
    status === PUSH_STATUS.completed
      ? [
          {
            id: "duplicate",
            label: "Duplicate",
            icon: (
              <AddToPhotosSharpIcon
                fontSize="small"
                data-testid="duplicate-btn"
              />
            ),
            handler: duplicateHandler,
          },
          // {
          //   id: "report",
          //   label: "Report",
          //   icon: (
          //     <EqualizerOutlinedIcon
          //       fontSize="small"
          //       data-testid="report-btn"
          //     />
          //   ),
          //   handler: reportHandler,
          // },
          {
            id: "archive",
            label: "Archive",
            icon: (
              <ArchiveRoundedIcon fontSize="small" data-testid="archive-btn" />
            ),
            handler: archiveHandler,
          },
        ]
      : status === PUSH_STATUS.hide
      ? [
          {
            id: "duplicate",
            label: "Duplicate",
            icon: (
              <AddToPhotosSharpIcon fontSize="small" data-testid="hide-btn" />
            ),
            handler: PushHideHandler,
          },
        ]
      : status === PUSH_STATUS.scheduled
      ? [
          {
            id: "view",
            label: "View",
            icon: <PageviewIcon fontSize="small" data-testid="edit-btn" />,
            handler: editHandler,
          },
        ]
      : [
          {
            id: "edit",
            label: "Edit",
            icon: <EditRoundedIcon fontSize="small" data-testid="edit-btn" />,
            handler: editHandler,
          },
          {
            id: "delete",
            label: "Delete",
            icon: (
              <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />
            ),
            handler: deleteHandler,
            showAction: () => access_type !== Constants.READ_ONLY,
          },
        ];

  const messageModal = [
    {
      id: 1,
      icon: PushIcon,
      title: "PUSH",
      testid: "push-btn",
      content: "Get instant Notification on your devices",
      handler: () => {
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/add`,
          search: "?type=message",
          state: { from: props.location },
        });
      },
    },
    {
      id: 2,
      icon: InAppMessageIcon,
      title: "IN APP MESSAGE",
      testid: "message-btn",
      content: "Get real time Meassages delivered to your inbox",
      handler: () => {
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/add`,
          search: "?type=inbox",
          state: { from: props.location },
        });
      },
    },
    {
      id: 3,
      icon: PushMessageIcon,
      title: "PUSH + MESSAGE",
      testid: "inbox_message-btn",
      content:
        "Receive simultaneous Notification and Message on your device and inbox",
      handler: () => {
        props.history.push({
          pathname: `/dashboard/${sport_key}/${env_key}/push-notification/messages/add`,
          search: "?type=message_inbox",
          state: { from: props.location },
        });
      },
    },
  ];
  return (
    <Fragment>
      <TableView
        name={
          searchText
            ? /* istanbul ignore next */ "Messages"
            : `${capitalize(status)} Messages`
        }
        data={data}
        actions={actions}
        headings={headings}
        checkbox={false}
        actionMenu={false}
        messageModal={messageModal}
        openModal={showDelete}
        handleModalClose={handleModalClose}
        handleModalDelete={handleModalDelete}
        isSorting
        totalCount={Messagescount}
        isLoading={isLoading}
        archiveModal={show}
        handleArchiveModal={handleArchiveModal}
        archiveModalClose={archiveModalClose}
        deleteData={deleteData}
        {...props}
      />
    </Fragment>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    deletePushMessage: payload => dispatch(deleteRecord(payload)),
    archivePushMessage: payload => dispatch(updateRecord(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(PushMessage);

export { Container, PushMessage as Component };
