import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateScreen = props => {
  const [screenData, setScreenData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getUrl = `/dashboard/${sport_key}/${env_key}/data/device/onBoarding/screens`;

  useEffect(() => {
    getScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScreenData = useCallback(async () => {
    const url = `/v1/nba/admin/app/onboard_screen/${uid}`;
    const {
      data: { onboarding_screen },
    } = await dispatch(get({ url }));
    setScreenData(onboarding_screen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/app/onboard_screen/${uid}`,
          key: "screen",
        }),
      );
      props.history.push(getUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Screen"
        breadCrumbItems={[
          {
            title: "Update Screen",
          },
        ]}
        backUrl={getUrl}
      />
      {screenData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={screenData}
          schema={schema}
          modelName="onboarding_screens"
          backUrl={getUrl}
        />
      )}
    </>
  );
};

export default UpdateScreen;
