import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdatePlayerLog = props => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const { team_name } = useSelector(state => state.application);
  const getURL = `/dashboard/${sport_key}/${env_key}/data/game/playerLogs/${team_name}`;

  useEffect(() => {
    getPlayerLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPlayerLogs = useCallback(async () => {
    const url = `/v1/nba/admin/data/game_player_logs/${uid}`;
    const {
      data: { game_player_logs },
    } = await dispatch(get({ url }));
    setData(game_player_logs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/data/game_player_logs/${uid}`,
          key: "game_player_logs",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Game Player Logs"
        breadCrumbItems={[
          {
            title: "Update Game Player Logs",
          },
        ]}
        backUrl={getURL}
      />
      {data && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={data}
          schema={schema}
          modelName="game_player_logs"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdatePlayerLog;
