import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "app/components/Common/schemaForm/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import React, { Fragment } from "react";
import TextField from "app/components/Common/TextField";

const useStyles = makeStyles({
  label: {
    backgroundColor: "white",
    paddingRight: "7px",
  },
});

const TextInput = React.memo(
  ({
    uid,
    validations,
    display_name,
    field_metadata,
    data_type,
    index,
    visible = true,
    onFieldDelete,
    isBorder = false,
    defaultValue,
  }) => {
    const classes = useStyles();
    const { control, getValues } = useFormContext();
    return (
      <Controller
        name={`${uid}`}
        control={control}
        defaultValue={defaultValue ?? getValues(`${uid}`)}
        rules={{
          required: validations?.required
            ? `${display_name} is required`
            : false,
          maxLength: {
            value: validations?.maxLength,
            message: `${display_name} has max length of ${validations?.maxLength}`,
          },
          minLength: {
            value: validations?.minLength,
            message: `${display_name} has min length of ${validations?.minLength}`,
          },
          max: {
            value: validations?.max,
            message: `${display_name} has max limit of ${validations?.max}`,
          },
          min: {
            value: validations?.min,
            message: `${display_name} has min limit of ${validations?.min}`,
          },
          pattern: {
            value: validations?.pattern,
            message: `Invalid ${display_name} format`,
          },
        }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, `${field.name}`);
          const fieldError = get(errors, `${field.name}`);
          return (
            <Fragment>
              {visible && (
                <FieldBorder isBorder={isBorder} isError={hasFieldError}>
                  <TextField
                    id={uid}
                    multiline={field_metadata?.multiline}
                    label={display_name}
                    type={data_type === "string" ? "text" : data_type} // number
                    variant="outlined"
                    error={hasFieldError}
                    value={field.value}
                    helperText={hasFieldError && fieldError?.message}
                    FormHelperTextProps={{
                      "data-testid": `${uid}-error`,
                    }}
                    onChange={field.onChange}
                    onBlur={e => {
                      e.target.value = e.target.value.trim();
                      field.onChange(e.target.value);
                    }}
                    inputRef={field.ref}
                    InputProps={{
                      readOnly: !!field_metadata?.readOnly,
                    }}
                    inputProps={{
                      "data-testid": `${uid}-input`,
                      ...validations,
                    }}
                    InputLabelProps={{
                      required: validations?.required,
                      classes: {
                        root: classes.label,
                      },
                    }}
                  />
                  {onFieldDelete && (
                    <IconButton onClick={event => onFieldDelete(event, index)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {/* protected field checkbox for Integration's config form  */}
                  {field_metadata?.hasOwnProperty("Protected") && (
                    <Checkbox
                      uid={`${uid}-protected-checkbox`}
                      display_name="Protected"
                      defaultValue={field_metadata.isProtected}
                    />
                  )}
                </FieldBorder>
              )}
            </Fragment>
          );
        }}
      />
    );
  },
);

export default TextInput;
