import React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.912 43.836v-5.183V38.214c0-.088-.089-.176-.089-.264l-5.655-12.738c-.442-.878-1.414-1.317-2.298-.878-.883.351-1.325 1.405-.883 2.284l4.418 10.278H41.267c-.619 0-1.15.264-1.503.79L34.64 45.33h-9.367l-5.125-7.642c-.353-.527-.883-.791-1.502-.791H4.507l4.771-10.717c.354-.879 0-1.933-.883-2.284-.884-.352-1.944 0-2.298.878L.177 37.95c0 .088-.089.176-.089.264V52.62C.088 56.66 3.446 60 7.511 60h25.626l18.38-.176c2.297 0 4.507-.966 6.097-2.635a8.909 8.909 0 002.474-6.15l-.176-7.203zm-4.949 10.893c-.972.967-2.209 1.493-3.623 1.581l-18.38.176H7.334c-2.12 0-3.888-1.757-3.888-3.865V40.41h14.227l5.125 7.643c.354.527.884.79 1.503.79h11.31c.619 0 1.149-.263 1.502-.79l5.126-7.643h14.226v3.426l.089 7.291c-.089 1.318-.619 2.636-1.59 3.602zM15.906 31.625h28.277c2.916 0 5.301-2.372 5.301-5.27V5.27C49.484 2.37 47.1 0 44.183 0H15.906c-2.916 0-5.302 2.372-5.302 5.183v21.084c0 2.986 2.386 5.358 5.302 5.358zm28.277-3.514H15.906c-.972 0-1.768-.79-1.768-1.757V8.61l14.934 10.366c.265.176.619.351.972.351.354 0 .707-.087.972-.35L45.95 8.608v17.745c0 .967-.795 1.757-1.767 1.757zM15.906 3.514h28.277c.707 0 1.325.44 1.59.966L30.044 15.461 14.315 4.481c.354-.528.884-.967 1.59-.967z"
        fill="#1E88E5"
      />
    </svg>
  );
}

export default SvgComponent;
