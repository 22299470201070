import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddVenue = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/interactive-maps/venues`;

  const onSubmit = async data => {
    for (const prop in data) {
      if (
        [
          "center_coordinate",
          "northeast_coordinate",
          "southwest_coordinate",
        ].includes(prop)
      ) {
        Object.assign(data[prop], {
          type: "Point",
          coordinates: [data[prop].latitude, data[prop].longitude],
        });
        delete data[prop].latitude;
        delete data[prop].longitude;
      }
    }

    try {
      await dispatch(
        createRecord({
          url: `/v1/admin/interactive_maps/venues`,
          key: "venue",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Venue"
        breadCrumbItems={[
          {
            title: "Add Venue",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm onSubmit={onSubmit} schema={schema} backUrl={getURL} />
    </>
  );
};

export default AddVenue;
