import { Route, Switch } from "react-router-dom";
import AddPlayersAvg from "./add";
import GetPlayersAvg from "app/pages/Dashboard/Data/Roster/PlayerAverage/get";
import React from "react";
import UpdatePlayersAvg from "./update";

const PlayersAvg = props => {
  return (
    <>
      <Switch>
        <Route
          path={`${props.match.path}/add`}
          component={AddPlayersAvg}
          exact
        />
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdatePlayersAvg}
          exact
        />
        <Route path={props.match.path} component={GetPlayersAvg} />
      </Switch>
    </>
  );
};

export default PlayersAvg;
