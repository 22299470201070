/* eslint-disable react-hooks/exhaustive-deps */
import { cloneDeep } from "lodash";
import { formData } from "store/reducers/formSlice";
import { getSchema } from "services/customObject/getSchema";
import { getTemplateMapper } from "services/customObject/getTemplate";
import { useDispatch } from "react-redux";
import Form from "./Form.js";
import React, { useCallback, useEffect, useState } from "react";

const FormSchemaTemplate = ({
  modelName,
  schema,
  defaultValues,
  backUrl,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [schemaState, setSchemaState] = useState(schema);
  const [loading, setLoading] = useState(true);

  const getTemplateName = useCallback(async () => {
    if (modelName) {
      try {
        const {
          data: { template_mapper },
        } = await dispatch(
          getTemplateMapper({
            modelName: modelName,
            handleError: false,
          }),
        );
        getTemplateSchema({ template_uid: template_mapper.template_name });
      } catch (error) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [modelName]);

  const getTemplateSchema = useCallback(async ({ template_uid }) => {
    try {
      const { data } = await dispatch(
        getSchema({ uid: template_uid, handleError: false }),
      );
      createTemplateGroup(data.class);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const createTemplateGroup = ({ _schema }) => {
    const template = {
      display_name: "Template Fields",
      uid: "template_fields",
      mandatory: false,
      multiple: false,
      data_type: "group",
      field_metadata: {},
      _schema,
    };
    const schemaObject = cloneDeep(schema);
    schemaObject.class._schema.push(template);
    setSchemaState(schemaObject);
    setLoading(false);
  };

  useEffect(() => {
    getTemplateName(modelName);
  }, [getTemplateName]);

  useEffect(() => {
    if (defaultValues) {
      dispatch(formData(cloneDeep(defaultValues)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  if (!loading)
    return (
      <Form
        schema={schemaState}
        defaultValues={defaultValues}
        backUrl={backUrl}
        onSubmit={onSubmit}
      />
    );
  return <></>;
};

export default FormSchemaTemplate;
