import { cloneDeep } from "lodash";
import { MapMode } from "../Map";
import { Modal } from "@dfep/ui-component";
import lineSchema from "./Line/Schema";
import poiImageSchema from "./Poi/Image/Schema";
import poiPointSchema from "./Poi/Point/Schema";
import poiTextSchema from "./Poi/Text/Schema";
import polygonSchema from "./Polygon/Schema";
import React, { useCallback, useEffect, useState } from "react";
import SchemaForm from "./SchemaForm";

function FeatureModal({
  modalState: { isOpenModal, mapMode, modalMode, defaultValues },
  cancelModalHandler,
  onSubmitHandler,
  categoryOptions,
  levelOptions,
}) {
  const [schemaState, setSchemaState] = useState(null);
  const [modalLabel, setModalLabel] = useState("");
  const onModalMapModeSubmitHandler = useCallback(
    form => {
      switch (mapMode) {
        case MapMode.ADD_POI:
          return onSubmitHandler({ form, type: MapMode.ADD_POI });
        case MapMode.ADD_TEXT:
          return onSubmitHandler({ form, type: MapMode.ADD_TEXT });
        case MapMode.ADD_IMAGE:
          return onSubmitHandler({ form, type: MapMode.ADD_IMAGE });
        case MapMode.ADD_LINE:
          return onSubmitHandler({ form, type: MapMode.ADD_LINE });
        case MapMode.ADD_POLYGON:
          return onSubmitHandler({ form, type: MapMode.ADD_POLYGON });
        /* istanbul ignore next */
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapMode],
  );

  useEffect(() => {
    let schema = null;
    switch (mapMode) {
      case MapMode.ADD_POI:
        schema = cloneDeep(poiPointSchema);
        schema.class._schema.forEach(field => {
          if (
            field.uid === "category_id" &&
            field.field_metadata.options.length <= 1
          )
            field.field_metadata.options = [
              ...field.field_metadata.options,
              ...categoryOptions.current,
            ];
          if (
            field.uid === "level_ids" &&
            field.field_metadata.options.length <= 1
          )
            field.field_metadata.options = [
              ...field.field_metadata.options,
              ...levelOptions.current,
            ];
        });
        setSchemaState(schema);
        setModalLabel(modalMode + " POI");
        break;
      case MapMode.ADD_TEXT:
        schema = cloneDeep(poiTextSchema);
        schema.class._schema.forEach(field => {
          if (
            field.uid === "level_ids" &&
            field.field_metadata.options.length <= 1
          )
            field.field_metadata.options = [
              ...field.field_metadata.options,
              ...levelOptions.current,
            ];
        });
        setSchemaState(schema);
        setModalLabel(modalMode + " Text");
        break;
      case MapMode.ADD_IMAGE:
        schema = cloneDeep(poiImageSchema);
        schema.class._schema.forEach(field => {
          if (
            field.uid === "level_ids" &&
            field.field_metadata.options.length <= 1
          )
            field.field_metadata.options = [
              ...field.field_metadata.options,
              ...levelOptions.current,
            ];
        });
        setSchemaState(schema);
        setModalLabel(modalMode + " Image");
        break;
      case MapMode.ADD_LINE:
        schema = cloneDeep(lineSchema);
        schema.class._schema.forEach(field => {
          if (
            field.uid === "level_ids" &&
            field.field_metadata.options.length <= 1
          )
            field.field_metadata.options = [
              ...field.field_metadata.options,
              ...levelOptions.current,
            ];
        });
        setSchemaState(schema);
        setModalLabel(modalMode + " Line");
        break;
      case MapMode.ADD_POLYGON:
        schema = cloneDeep(polygonSchema);
        schema.class._schema.forEach(field => {
          if (
            field.uid === "level_ids" &&
            field.field_metadata.options.length <= 1
          )
            field.field_metadata.options = [
              ...field.field_metadata.options,
              ...levelOptions.current,
            ];
        });
        setSchemaState(schema);
        setModalLabel(modalMode + " Polygon");
        break;
      /* istanbul ignore next */
      default:
        setSchemaState(null);
        setModalLabel("");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMode]);

  return (
    <Modal
      data-testid={`${modalMode}-modal`}
      open={isOpenModal}
      handleClose={cancelModalHandler}
      title={modalLabel}
    >
      <SchemaForm
        schema={schemaState}
        defaultValues={defaultValues}
        onCancel={cancelModalHandler}
        onSubmit={onModalMapModeSubmitHandler}
      />
    </Modal>
  );
}

export const FeatureRequestModal = ({
  open,
  cancelModalHandler,
  onSubmitHandler,
  type,
}) => {
  return (
    <Modal
      data-testid={`${type}-modal`}
      open={open}
      handleClose={cancelModalHandler}
      title={`${type === "save" ? "Save" : "Delete"} feature`}
      actions={[
        {
          id: "1",
          name: "Cancel",
          handler: cancelModalHandler,
          additionalAttributes: {
            "data-testid": "cancel-modal",
          },
        },
        {
          id: "2",
          name: type === "save" ? "Save changes" : "Delete feature",
          handler: onSubmitHandler,
          additionalAttributes: {
            "data-testid": "accept-modal",
            variant: "contained",
            color: "primary",
          },
        },
      ]}
    >
      {type === "save" ? (
        <>
          Are you sure you want to <strong>save changes</strong> made to the
          feature?
        </>
      ) : (
        <>
          Deleting <strong>selected feature</strong> will permanently remove it
          from the map. Are you sure you want to delete?
        </>
      )}
    </Modal>
  );
};

export default FeatureModal;
