import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { getTemplate } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Container as Templates } from "app/pages/Dashboard/pushNotification/Template/get/Template";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";

const Template = props => {
  const { access_type } = useSelector(state => state.application);
  let tabObj = { predefined: 0, others: 1 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const { sport_key, env_key } = props.match.params;
  const breadCrumbItems = [
    {
      title: "Push Notification",
      link: `/dashboard/${sport_key}/${env_key}/push-notification`,
    },
    {
      title: "Templates",
    },
  ];

  // get api call
  const dispatch = useDispatch();
  const { sort, value, skip, limit, searchText } = useQueryParams();
  const [data, setData] = useState([]);
  const [Templatecount, setTemplateCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(
    async template_type => {
      setData([]);
      setIsLoading(true);

      const {
        data: { count, push_template },
      } = await dispatch(
        getTemplate({
          skip,
          limit,
          sort,
          sortValue: value,
          template_type,
          searchText,
        }),
      );

      if (count !== 0 && push_template.length === 0) {
        initialRoute(props.history);
      } else {
        setTemplateCount(count);
        setData(push_template);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, value, limit, skip, searchText],
  );

  return (
    <>
      <PanelHeader
        title="Push Template"
        breadCrumbItems={breadCrumbItems}
        refreshHandler={() => {
          /*istanbul ignore next*/
          getData(activeTab === 0 ? "predefined" : "others");
        }}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="add-btn"
            onClick={() => {
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/push-notification/template/add`,
                state: { from: props.location },
              });
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label="Predefined"
            component={Link}
            to={`${props.match.url}/predefined`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${props.match.url}/others`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/predefined`}
            exact
          />
          <Route
            path={`${props.match.path}/:template_type`}
            /*istanbul ignore next*/
            render={Routeprops => {
              /*istanbul ignore next*/
              return (
                <Templates
                  {...Routeprops}
                  data={data}
                  Templatecount={Templatecount}
                  isLoading={isLoading}
                  getData={getData}
                />
              );
            }}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
};

export default Template;
