import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const ANIMATION_TIME_PERIOD_AFTER_WIN = "animation_time_period_after_win";

const UpdateLightTheBeamInfo = props => {
  const [lightTheBeamData, setLightTheBeamData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/light-the-beam`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/nba/admin/data/light_the_beam_info/${uid}`;
    const {
      data: { light_the_beam_info },
    } = await dispatch(get({ url }));

    if (light_the_beam_info.hasOwnProperty(ANIMATION_TIME_PERIOD_AFTER_WIN)) {
      light_the_beam_info[ANIMATION_TIME_PERIOD_AFTER_WIN] = {
        hours:
          light_the_beam_info.animation_time_period_after_win.split(":")[0] ||
          0,
        minutes:
          light_the_beam_info.animation_time_period_after_win.split(":")[1] ||
          0,
      };
    }

    setLightTheBeamData(light_the_beam_info);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    if (data.hasOwnProperty(ANIMATION_TIME_PERIOD_AFTER_WIN)) {
      const { hours, minutes } = data[ANIMATION_TIME_PERIOD_AFTER_WIN];
      data[ANIMATION_TIME_PERIOD_AFTER_WIN] = `${hours}:${minutes}`;
    }

    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/data/light_the_beam_info/${uid}`,
          key: "light_the_beam_info",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Light The Beam"
        breadCrumbItems={[
          {
            title: "Update Light The Beam",
          },
        ]}
        backUrl={getURL}
      />

      {lightTheBeamData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={lightTheBeamData}
          schema={schema}
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateLightTheBeamInfo;
