const schema = {
  class: {
    title: "Config",
    uid: "config",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Hide Scoreboard",
        uid: "hide_scoreboard",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        data_type: "string",
        display_name: "App Db Reset",
        field_metadata: { additional_options: ["Multiple"] },
        mandatory: false,
        multiple: true,
        uid: "app_db_reset",
        _schema: [],
      },
      {
        data_type: "number",
        display_name: "Reset App Db",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        uid: "reset_app_db",
      },
      {
        display_name: "Third Party App DeepLink",
        uid: "thirdparty_app_deeplink",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "App Name",
            uid: "app_name",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Android",
            uid: "android",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "IOS",
            uid: "ios",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Third Party App Config",
        uid: "thirdparty_app_config",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "App Name",
            uid: "app_name",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Config",
            uid: "config",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Regex",
        uid: "regex",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Name",
            uid: "name",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Android",
            uid: "android",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "IOS",
            uid: "ios",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Sdk Config",
        isModal: true,
        uid: "sdk_config",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Display Name",
            isModal: false,
            uid: "display_name",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Name",
            isModal: false,
            uid: "name",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "credentials",
            isModal: false,
            uid: "credentials",
            mandatory: true,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Key",
                isModal: false,
                uid: "key",
                mandatory: true,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Value",
                isModal: false,
                uid: "value",
                mandatory: true,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
            ],
          },
        ],
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
