import { Typography } from "@material-ui/core";
import React from "react";

const PasswordValidator = () => {
  return (
    <div className="password-requirement">
      <Typography
        data-testid="password-requirement-text"
        variant="h6"
        className="mbm d-flex"
      >
        Password Requirement
      </Typography>
      <ul>
        <li
          style={{ listStyleType: "none" }}
          id="low_letter"
          className="incorrect"
        >
          <span>Must contain one lowercase letter</span>
        </li>
        <li
          style={{ listStyleType: "none" }}
          id="up_letter"
          className="incorrect"
        >
          <span>Must contain one Uppercase letter</span>
        </li>
        <li style={{ listStyleType: "none" }} id="digit" className="incorrect">
          <span>Must contain atleast one digit</span>
        </li>
        <li style={{ listStyleType: "none" }} id="length" className="incorrect">
          <span>
            Must contain minimum of 8 characters and maximum 16 characters
            length
          </span>
        </li>
        <li
          style={{ listStyleType: "none" }}
          id="special_character"
          className="incorrect"
        >
          <span>Must contain a special character</span>
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidator;
