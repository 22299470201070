import { getYearArray } from "utils/functions";

const GAME_STATE = [
  { label: "Active", value: "active" },
  { label: "Postponed", value: "PPD" },
  { label: "Rescheduled", value: "Rescheduled" },
  { label: "Cancelled", value: "CNCL" },
  { label: "TBD", value: "TBD" },
];

const schema = {
  class: {
    title: "Schedule",
    uid: "schedule",
    _schema: [
      {
        display_name: "Game State",
        uid: "game_state",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...GAME_STATE],
        },
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game ID",
        uid: "gid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Code",
        uid: "gcode",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Series Info",
        uid: "seri",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Is Game Necessary",
        uid: "is",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Game Time",
        uid: "gametime",
        mandatory: true,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Arena Name",
        uid: "an",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Arena City",
        uid: "ac",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },

      {
        display_name: "Arena State",
        uid: "as",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Status",
        uid: "st",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Status Text",
        uid: "stt",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Visitor",
        uid: "v",
        mandatory: false,
        multiple: false,
        data_type: "group",
        _schema: [
          {
            display_name: "Team Id",
            uid: "tid",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Record",
            uid: "re",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team Abbreviation",
            uid: "ta",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team Name",
            uid: "tn",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team City",
            uid: "tc",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Score",
            uid: "s",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "In Season Tournament Group",
            uid: "ist_group",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Home",
        uid: "h",
        mandatory: false,
        multiple: false,
        data_type: "group",
        _schema: [
          {
            display_name: "Team Id",
            uid: "tid",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Record",
            uid: "re",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team Abbreviation",
            uid: "ta",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team Name",
            uid: "tn",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team City",
            uid: "tc",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Score",
            uid: "s",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "In Season Tournament Group",
            uid: "ist_group",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Broadcast",
        uid: "bd",
        mandatory: false,
        multiple: false,
        data_type: "group",
        _schema: [
          {
            display_name: "Broadcaster",
            uid: "b",
            mandatory: false,
            multiple: true,
            data_type: "group",
            _schema: [
              {
                display_name: "Broadcaster Id",
                uid: "broadcasterId",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Game Sequence Number for that day",
                uid: "seq",
                mandatory: true,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Broadcast Display (call letters, etc.)",
                uid: "disp",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Scope (natl, can, home, away)",
                uid: "scope",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Type (tv, radio)",
                uid: "type",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Language broadcast is in",
                uid: "lan",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "URL",
                uid: "url",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
            ],
            field_metadata: {},
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Game Themes",
        uid: "game_themes",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Display Text",
            uid: "display_text",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Title",
            uid: "title",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Filter Key",
            uid: "filter_key",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Icon Image",
            uid: "icon_image",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
        field_metadata: {},
      },

      {
        display_name: "Postponed Status (A/I)",
        uid: "ppdst",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Buy Ticket",
        uid: "buy_ticket",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Buy Ticket URL",
        uid: "buy_ticket_url",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Clock",
        uid: "cl",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Logo",
        uid: "logo_url",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Hide this Schedule",
        uid: "hide",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Game Subtype",
        uid: "game_subtype",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};
export default schema;
