import { createRecord } from "services/createObject";
import {
  getInteractiveMapCategories,
  getInteractiveMapLevels,
  getInteractiveMapVenues,
} from "services/getObjects";
import { handleError } from "services/helper";
import { MapMode } from "../../Maps/Map";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import cloneDeep from "lodash/cloneDeep";
import PanelHeader from "app/components/Common/PanelHeader";
import SchemaForm from "app/components/Common/schemaForm";

// import all schema
import lineSchema from "../Schema/Line/Schema";
import poiImageSchema from "../Schema/Poi/Image/Schema";
import poiPointSchema from "../Schema/Poi/Point/Schema";
import poiTextSchema from "../Schema/Poi/Text/Schema";
import polygonSchema from "../Schema/Polygon/Schema";

import { showLoader } from "store/reducers/loaderSlice";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const formDatatypeConversion = ({ schema, data }) => {
  schema._schema.forEach(field => {
    switch (field.data_type) {
      case "boolean":
        data[field.uid] = JSON.parse(data[field.uid] || false);
        break;
      case "number":
        data[field.uid] = JSON.parse(data[field.uid]);
        break;
      default:
        break;
    }
  });
  return data;
};
/* istanbul ignore next */
const _isNum = elem => typeof elem === "number";
/* istanbul ignore next */
const isCoordinateElement = elem =>
  Array.isArray(elem) && elem.length === 2 && elem.every(_isNum);

const AddFeature = props => {
  // State
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [venueOptions, setVenueOptions] = useState([]);
  const [schemaState, setSchemaState] = useState(null);
  const [formName, setFormName] = useState(null);

  const [loading, setLoading] = useState(true);

  const lineCoord = [[]];
  const polygonCoord = [[[]]];

  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const type = query.get("type");
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/interactive-maps/features`;

  useEffect(() => {
    dispatch(showLoader({ show: loading }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const setFeatureType = useCallback(
    data => {
      data["type"] = "Feature";
      switch (type) {
        case MapMode.ADD_POI:
          data.geometry["type"] = "Point";
          data.properties["type"] = "poi";
          break;
        case MapMode.ADD_TEXT:
          data.geometry["type"] = "Point";
          data.properties["type"] = "text";
          break;
        case MapMode.ADD_IMAGE:
          data.geometry["type"] = "Point";
          data.properties["type"] = "image";
          break;
        case MapMode.ADD_LINE:
          data.geometry["type"] = "LineString";
          break;
        case MapMode.ADD_POLYGON:
          data.geometry["type"] = "Polygon";
          break;
        /* istanbul ignore next */
        default:
          throw new Error("Invalid feature type");
      }
      return data;
    },
    [type],
  );

  // Validate geometrical coordinates
  const validateData = useCallback(
    data => {
      switch (type) {
        case MapMode.ADD_POI:
          data.geometry.coordinates = [
            data.geometry.coordinates.longitude,
            data.geometry.coordinates.latitude,
          ];
          break;
        case MapMode.ADD_TEXT:
          data.geometry.coordinates = [
            data.geometry.coordinates.longitude,
            data.geometry.coordinates.latitude,
          ];
          break;
        case MapMode.ADD_IMAGE:
          data.geometry.coordinates = [
            data.geometry.coordinates.longitude,
            data.geometry.coordinates.latitude,
          ];
          break;
        case MapMode.ADD_LINE:
          /* istanbul ignore else */
          if (
            !(
              Array.isArray(data.geometry.coordinates) &&
              data.geometry.coordinates.length > 1 &&
              Array.from(data.geometry.coordinates).every(isCoordinateElement)
            )
          )
            throw new Error(
              "Please enter coordinates in [[lng, lat],[lng, lat],[]...] format",
            );
          break;
        case MapMode.ADD_POLYGON:
          /* istanbul ignore else */
          if (
            !(
              Array.isArray(data.geometry.coordinates) &&
              data.geometry.coordinates.length === 1 &&
              Array.isArray(data.geometry.coordinates[0]) &&
              data.geometry.coordinates[0].length > 2 &&
              Array.from(data.geometry.coordinates[0]).every(
                isCoordinateElement,
              )
            )
          )
            throw new Error(
              "Please enter coordinates in [[[lng, lat],[lng, lat],[]...]] format",
            );
          break;
        /* istanbul ignore next */
        default:
          throw new Error("Invalid feature type");
      }
      return data;
    },
    [type],
  );

  const onSubmit = useCallback(
    async data => {
      let prop = data.properties;
      let arr = [];
      for (const field in prop) {
        if (["level_ids"].includes(field)) {
          prop[field].forEach(obj => {
            arr.push(obj.value);
          });
          Object.assign(prop, { level_ids: arr });
        }
      }

      try {
        /* istanbul ignore else */
        if (
          type === MapMode.ADD_POI ||
          type === MapMode.ADD_TEXT ||
          type === MapMode.ADD_IMAGE
        )
          data.geometry.coordinates = formDatatypeConversion({
            schema: schemaState.class._schema[0]._schema[0],
            data: data.geometry.coordinates,
          });
        data.properties = formDatatypeConversion({
          schema: schemaState.class._schema[1],
          data: data.properties,
        });
        data = setFeatureType(data);
        data = validateData(data);
        await dispatch(
          createRecord({
            url: "/v1/admin/interactive_maps/features",
            key: "feature",
            form: data,
          }),
        );
        props.history.push(getURL);
      } catch (error) /*istanbul ignore next*/ {
        const err = {
          response: {
            data: {
              error_message: error.message,
            },
          },
        };
        handleError(err, dispatch);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schemaState, type],
  );

  const getVenuesOptions = useCallback(async () => {
    const {
      data: { venues },
    } = await dispatch(
      getInteractiveMapVenues({
        sport_key,
        env_key,
      }),
    );
    const options = venues
      .map(venue => {
        return { value: venue.id, label: venue.name };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    setVenueOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key]);

  const getLevelOptions = useCallback(async () => {
    const {
      data: { levels },
    } = await dispatch(
      getInteractiveMapLevels({
        sport_key,
        env_key,
      }),
    );
    const options = levels
      .map(level => {
        return { value: level.id, label: level.name };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    setLevelOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key]);

  const getCategoriesOptions = useCallback(async () => {
    const {
      data: { categories },
    } = await dispatch(
      getInteractiveMapCategories({
        sport_key,
        env_key,
      }),
    );
    const options = categories
      .map(category => {
        return { value: category.id, label: category.display_name };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    setCategoryOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key]);

  // Load all select options
  useEffect(() => {
    getVenuesOptions();
    getLevelOptions();
    /* istanbul ignore else */
    if (type === MapMode.ADD_POI) getCategoriesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load schema based on "type" query
  useEffect(() => {
    try {
      if (
        venueOptions.length &&
        levelOptions.length &&
        (type !== MapMode.ADD_POI || categoryOptions.length)
      ) {
        let schema = null;
        switch (type) {
          case MapMode.ADD_POI:
            schema = cloneDeep(poiPointSchema);
            schema.class._schema.forEach(field => {
              if (field.uid === "properties") {
                field._schema.forEach(field => {
                  if (
                    field.uid === "venue_id" &&
                    field.field_metadata.options.length <= 1
                  )
                    field.field_metadata.options = [
                      ...field.field_metadata.options,
                      ...venueOptions,
                    ];
                  if (
                    field.uid === "category_id" &&
                    field.field_metadata.options.length <= 1
                  )
                    field.field_metadata.options = [
                      ...field.field_metadata.options,
                      ...categoryOptions,
                    ];
                  if (
                    field.uid === "level_ids" &&
                    field.field_metadata.options.length <= 1
                  )
                    Object.assign(field, { ...multiSelectProps });
                });
              }
            });
            setSchemaState(schema);
            setFormName("Add Poi Feature");
            setLoading(false);
            break;
          case MapMode.ADD_TEXT:
            schema = cloneDeep(poiTextSchema);
            schema.class._schema.forEach(field => {
              if (field.uid === "properties") {
                field._schema.forEach(field => {
                  if (
                    field.uid === "venue_id" &&
                    field.field_metadata.options.length <= 1
                  )
                    field.field_metadata.options = [
                      ...field.field_metadata.options,
                      ...venueOptions,
                    ];
                  if (
                    field.uid === "level_ids" &&
                    field.field_metadata.options.length <= 1
                  )
                    Object.assign(field, { ...multiSelectProps });
                });
              }
            });
            setSchemaState(schema);
            setFormName("Add Text Feature");
            setLoading(false);
            break;
          case MapMode.ADD_IMAGE:
            schema = cloneDeep(poiImageSchema);
            schema.class._schema.forEach(field => {
              if (field.uid === "properties") {
                field._schema.forEach(field => {
                  if (
                    field.uid === "venue_id" &&
                    field.field_metadata.options.length <= 1
                  )
                    field.field_metadata.options = [
                      ...field.field_metadata.options,
                      ...venueOptions,
                    ];
                  if (
                    field.uid === "level_ids" &&
                    field.field_metadata.options.length <= 1
                  )
                    Object.assign(field, { ...multiSelectProps });
                });
              }
            });
            setSchemaState(schema);
            setFormName("Add Image Feature");
            setLoading(false);
            break;
          case MapMode.ADD_LINE:
            schema = cloneDeep(lineSchema);
            schema.class._schema.forEach(field => {
              if (field.uid === "properties") {
                field._schema.forEach(field => {
                  if (
                    field.uid === "venue_id" &&
                    field.field_metadata.options.length <= 1
                  )
                    field.field_metadata.options = [
                      ...field.field_metadata.options,
                      ...venueOptions,
                    ];
                  if (
                    field.uid === "level_ids" &&
                    field.field_metadata.options.length <= 1
                  )
                    Object.assign(field, { ...multiSelectProps });
                });
              }
            });
            setSchemaState(schema);
            setFormName("Add Line Feature");
            setLoading(false);
            break;
          case MapMode.ADD_POLYGON:
            schema = cloneDeep(polygonSchema);
            schema.class._schema.forEach(field => {
              if (field.uid === "properties") {
                field._schema.forEach(field => {
                  if (
                    field.uid === "venue_id" &&
                    field.field_metadata.options.length <= 1
                  )
                    field.field_metadata.options = [
                      ...field.field_metadata.options,
                      ...venueOptions,
                    ];
                  if (
                    field.uid === "level_ids" &&
                    field.field_metadata.options.length <= 1
                  )
                    Object.assign(field, { ...multiSelectProps });
                });
              }
            });
            setSchemaState(schema);
            setFormName("Add Polygon Feature");
            setLoading(false);
            break;
          /* istanbul ignore next */
          default:
            setSchemaState(null);
            setFormName(null);
            setLoading(false);
            throw new Error("Invalid feature type");
        }
      }
    } catch (error) /* istanbul ignore next */ {
      const err = {
        response: {
          data: {
            error_message: error.message,
          },
        },
      };
      handleError(err, dispatch);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [type, venueOptions, levelOptions, categoryOptions]);

  //multi select level_ids
  const multiSelectProps = {
    multiple: true,
    freeSolo: false,
    getOptionLabel: option => option.label,
    options: levelOptions,
    open: true,
    filterSelectedOptions: false,
    disableCloseOnSelect: true,
    getOptionSelected: (option, value) => option.value === value.value,
    renderOption: (option, { selected }) => {
      return (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      );
    },
  };

  return (
    <>
      <PanelHeader
        title={formName}
        breadCrumbItems={[
          {
            title: "Interactive Map Features",
            link: getURL,
          },
          {
            title: "Add Feature",
          },
        ]}
        backUrl={getURL}
      />
      {schemaState && (
        <SchemaForm
          backUrl={getURL}
          schema={schemaState}
          onSubmit={onSubmit}
          defaultValues={{
            properties: { level_ids: [] },
            ...(type === MapMode.ADD_LINE
              ? { geometry: { coordinates: lineCoord } }
              : type === MapMode.ADD_POLYGON && {
                  geometry: { coordinates: polygonCoord },
                }),
          }}
        />
      )}
    </>
  );
};

export default AddFeature;
