import { Controller, useFormContext } from "react-hook-form";
import { CustomFlexBox } from "styles/global.style";
import AceEditor from "app/components/Common/jsonEditor/jsonEditor";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import Label from "app/components/Common/Label";
import React from "react";

const JSONEditor = React.memo(
  ({
    uid,
    validations,
    display_name,
    onFieldDelete,
    mandatory,
    index,
    isBorder = true,
    defaultValue,
    visible = true,
  }) => {
    const { control, setError, setValue, getValues } = useFormContext();

    if (!getValues("JSONEditor")) {
      setValue("JSONEditor", [uid]);
    } else {
      if (!getValues("JSONEditor").includes(uid)) {
        setValue("JSONEditor", [...getValues("JSONEditor"), uid]);
      }
    }

    return (
      <Controller
        name={`${uid}`}
        control={control}
        rules={{
          required: mandatory ? `${display_name} is required` : false,
        }}
        defaultValue={defaultValue ?? getValues(`${uid}`)}
        render={({ field, formState }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, `${field.name}`);
          const fieldError = get(errors, `${field.name}`);

          return (
            <FieldBorder isBorder={isBorder} isError={hasFieldError}>
              {visible && (
                <div className="editor-wrapper">
                  <CustomFlexBox className="grp-heading">
                    {index !== undefined && index !== "" ? (
                      <Label className="grp-label lbl" required={mandatory}>
                        {display_name}-{index + 1}
                      </Label>
                    ) : (
                      <Label className="grp-label lbl" required={mandatory}>
                        {display_name}
                      </Label>
                    )}
                    {onFieldDelete && (
                      <IconButton
                        onClick={event => onFieldDelete(event, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </CustomFlexBox>
                  <CustomFlexBox className="alignItemsTop grp-content">
                    <AceEditor
                      data-testid={uid}
                      value={
                        field.value
                          ? typeof field.value === "string"
                            ? field.value
                            : JSON.stringify(field.value, null, "\t")
                          : ""
                      }
                      onError={node => {
                        if (node.length) {
                          setError(uid, {
                            type: node[0].type,
                            message: node[0].text,
                          });
                        }
                      }}
                      onChange={field.onChange}
                      name={uid}
                    />
                  </CustomFlexBox>
                  {hasFieldError && (
                    <FormHelperText data-testid={`${uid}-error`} error>
                      {fieldError?.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            </FieldBorder>
          );
        }}
      />
    );
  },
);

export default JSONEditor;
