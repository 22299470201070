import { getObjectForm, getSchema } from "services/customObject/getSchema";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useEffect, useState } from "react";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateObject = props => {
  const [schema, setSchema] = useState();
  const [objectFormData, setObjectFormData] = useState();
  const dispatch = useDispatch();

  const { sport_key, env_key, schema_uid, uid } = props.match.params;
  const backUrl = `/dashboard/${sport_key}/${env_key}/customField/${schema_uid}/objects`;

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          url: `/v1/custom_schema/admin/classes/${schema_uid}/objects/${uid}`,
          key: "object",
          form: data,
        }),
      );
      props.history.push(backUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  const getSchemaObject = async () => {
    try {
      const { data } = await dispatch(getSchema({ uid: schema_uid }));
      setSchema(data);
      getSchemaObjectForm({ schema_uid: schema_uid, obj_uid: uid });
    } catch (error) {
      /*istanbul ignore next*/
      console.error(error);
    }
  };

  const getSchemaObjectForm = async ({ schema_uid, obj_uid }) => {
    try {
      const { data } = await dispatch(getObjectForm({ schema_uid, obj_uid }));
      setObjectFormData(data.object);
    } catch (error) {
      /*istanbul ignore next*/
      console.error(error);
    }
  };

  useEffect(() => {
    getSchemaObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {objectFormData && (
        <>
          <PanelHeader
            title={`Update ${schema.class.title}`}
            breadCrumbItems={[
              {
                title: "Custom Fields",
                link: `/dashboard/${sport_key}/${env_key}/customField`,
              },
              {
                title: `Update ${schema.class.title}`,
              },
            ]}
            backUrl={backUrl}
          />
          <SchemaForm
            onSubmit={onSubmit}
            schema={schema}
            backUrl={backUrl}
            defaultValues={objectFormData}
          />
        </>
      )}
    </Fragment>
  );
};

export default UpdateObject;
