import { FormWrapper } from "styles/global.style";
import TemplateMapper from "app/components/Common/CustomFieldForm/CustomFieldMapper";

const PlayerAverageSettings = () => {
  // States

  // Params

  // Hooks

  // Functions

  // LifeCycle

  // Render
  return (
    <FormWrapper className="settings-wrap" data-testid="form-wrapper">
      <TemplateMapper
        modelName="player_averages"
        title="Player Average Template"
        uid="player_averages_template"
      />
    </FormWrapper>
  );
};

export default PlayerAverageSettings;
