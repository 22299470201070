import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Play By Play",
    uid: "playByPlay",
    _schema: [
      {
        display_name: "Game ID",
        uid: "gid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Period",
        uid: "p",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Event",
        uid: "evt",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Clock",
        uid: "cl",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Description",
        uid: "de",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Description With Abbreviation",
        uid: "de_abbr",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Court location X",
        uid: "locX",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Court location Y",
        uid: "locY",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Message Type",
        uid: "mtype",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Event Type",
        uid: "etype",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Game Code",
        uid: "gcode",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Subtype",
        uid: "game_subtype",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Event Type 1",
        uid: "opt1",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Event Type 2",
        uid: "opt2",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Opposing player ID",
        uid: "opid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Player ID",
        uid: "pid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Home Team Score",
        uid: "hs",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Visitor Team Score",
        uid: "vs",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "epid",
        uid: "epid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "oftid",
        uid: "oftid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Order",
        uid: "ord",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Event Clips",
        uid: "event_clips",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Video URL",
            uid: "video_url",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Video Thumbnail URL",
            uid: "video_thumbnail_url",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Stream Name",
            uid: "stream_name",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
