import { Accordion, AccordionDetails, AccordionSummary } from "../Accordian";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldBorder from "../FieldBorder ";
import has from "lodash/has";
import Label from "../Label";
import React from "react";

export default function AccordionGroup({
  uid,
  title,
  showBorder,
  fieldName,
  errors,
  children,
}) {
  const groupAccordianError = has(errors, fieldName);
  return (
    <FieldBorder
      isBorder={showBorder}
      isError={groupAccordianError}
      key={uid}
      className="accordion-wrap"
    >
      <Accordion data-testid={uid} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label>{title}</Label>
        </AccordionSummary>
        <AccordionDetails>
          {/* {schema.length > 0 &&
            schema.map(schemaObject => {
              //   return generateForm(schemaObject);
            })} */}
          {children}
        </AccordionDetails>
      </Accordion>
    </FieldBorder>
  );
}
