// import { FieldBorderWrapper } from "styles/global.style";
import React from "react";
import styled from "styled-components";
const FieldBorderWrapper = styled.div`
  margin-bottom: 30px;
  .MuiFormLabel-root {
    display: flex;
    font-weight: 400;
    margin-bottom: 22px;
  }
  .editor-label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  legend {
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiFormLabel-asterisk {
    color: ${props => props.theme.palette.red.main};
  }
  & .default {
    border-left: 2px solid ${props => props.theme.palette.grey.greyE0E0E0};
    padding-left: 12px;
    .MuiInputBase-root:before {
      border-left: 2px solid ${props => props.theme.palette.grey.greyE0E0E0};
      content: "";
      position: absolute;
      left: -14px;
      height: 50px;
    }
  }
  & .active {
    &:focus-within {
      border-color: ${props => props.theme.palette.blue.blue600};
    }
    .Mui-focused {
      color: ${props => props.theme.palette.black.blackDark};
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.palette.blue.blue600};
      }
      &:before {
        border-color: ${props => props.theme.palette.blue.blue600};
      }
      legend {
        color: ${props => props.theme.palette.black.blackDark};
      }
      .MuiFormLabel-root {
        color: ${props => props.theme.palette.blue.blue600};
      }
    }
  }
  & .error {
    border-color: ${props => props.theme.palette.red.main};
    &:focus-within {
      border-color: ${props => props.theme.palette.red.main};
    }
    .MuiInputBase-root {
      &.Mui-error {
        &:before {
          border-left-color: ${props => props.theme.palette.red.main};
        }
      }
    }
    .Mui-error.MuiFormLabel-root {
      color: ${props => props.theme.palette.red.main};
    }
    & .MuiFocused.MuiFormLabel-root {
      color: ${props => props.theme.palette.blue.blue600};
    }
  }
  .MuiAccordion-root,
  .form-group {
    .MuiInputBase-root:before {
      content: none;
    }
  }
  .field {
    padding: 0;
  }

  .MuiFormControl-root {
    width: 100%;
    .MuiFormLabel-root {
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  fieldset {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
  }
  .MuiInputBase-input {
    padding: 13px 9px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    line-height: 20px;
    height: 20px;
    &.Mui-disabled {
      color: inherit;
      opacity: 1;
    }
  }

  .MuiFormControlLabel-root {
    .MuiTypography-root {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      font-size: 16px;
    }
  }
  .MuiAccordion-root {
    margin-left: -12px;
    .MuiAccordionSummary-root {
      border: 0;
      border-radius: 0;
      background: ${props => props.theme.palette.white.white200};
      &:hover {
        background: ${props => props.theme.palette.grey.greyE0E0E0};
      }
    }
    .MuiAccordionSummary-content {
      padding: 20px 0;
    }
    .MuiCollapse-container {
      padding-left: 12px;
    }
    .MuiAccordionDetails-root {
      padding-bottom: 0;
    }
  }
`;

export default function FieldBorder({
  isBorder,
  isError,
  children,
  ...restProps
}) {
  return (
    <FieldBorderWrapper {...restProps} className="field-wrapper">
      <div
        className={` ${
          isBorder ? (isError ? "default error" : "default active") : ""
        } `}
      >
        {children}
      </div>
    </FieldBorderWrapper>
  );
}
