const { forEach: each } = Array.prototype;

function computedStyleToInlineStyle(element, options = {}) {
  if (!element) {
    throw new Error("No element specified.");
  }

  if (options.recursive) {
    each.call(element.children, child => {
      computedStyleToInlineStyle(child, options);
    });
  }

  const computedStyle = getComputedStyle(element);
  each.call(options.properties || computedStyle, property => {
    element.style[property] = computedStyle.getPropertyValue(property);
  });
  return element;
}

export default computedStyleToInlineStyle;
