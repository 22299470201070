import { Container as GetStanding } from "app/pages/Dashboard/Data/Team/Standing/get";
import { Route, Switch } from "react-router-dom";
import AddStandings from "app/pages/Dashboard/Data/Team/Standing/add";
import React from "react";
import UpdateStanding from "app/pages/Dashboard/Data/Team/Standing/update";

const Standing = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddStandings} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateStanding}
        exact
      />
      <Route path={props.match.path} component={GetStanding} />
    </Switch>
  );
};

export default Standing;
