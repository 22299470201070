import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";

const EnvCardFooter = ({ time }) => {
  return (
    <Box
      component="div"
      alignItems="center"
      display="flex"
      className="timestamp"
      data-testid="card-footer"
    >
      <AccessTimeIcon className="timeIcon" />
      <Typography component="p">{time}</Typography>
    </Box>
  );
};

EnvCardFooter.propTypes = {
  time: PropTypes.string,
};

export default EnvCardFooter;
