import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { getLightTheBeamData } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import GetLightTheBeamData from "app/pages/Dashboard/Data/LightTheBeam/get/LightTheBeam";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";

const GetLightTheBeam = props => {
  const { sport_key, env_key } = props.match.params;
  const { READ_ONLY } = Constants;
  const { access_type } = useSelector(state => state.application);

  // States
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { sort, sortValue, skip, limit, searchText } = useQueryParams();

  // Get Light The Beam Info API Call
  const getData = useCallback(async () => {
    setIsLoading(true);
    setData([]);

    const {
      data: { count, light_the_beam_info },
    } = await dispatch(
      getLightTheBeamData({
        sport_key,
        env_key,
        skip,
        limit,
        sort,
        sortValue,
        searchText,
      }),
    );

    if (count !== 0 && light_the_beam_info.length === 0) {
      initialRoute(props.history);
    } else {
      setData(light_the_beam_info);
      setCount(count);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit, sort, sortValue, searchText]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <PanelHeader
        title="Light The Beam"
        breadCrumbItems={[
          {
            title: "Light The Beam",
          },
        ]}
        refreshHandler={getData}
        search={true}
      >
        {access_type !== READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="add-btn"
            startIcon={<AddIcon />}
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/light-the-beam/add`,
                state: { from: props.location },
              })
            }
            disabled={isLoading || data.length !== 0}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      <GetLightTheBeamData
        {...props}
        getData={getData}
        data={data}
        isLoading={isLoading}
        count={count}
        access_type={access_type}
      />
    </>
  );
};

export default GetLightTheBeam;
