import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "app/components/Common/Accordian";
import { AccordionIconWrap, KeyValueWrap, NoData } from "styles/global.style";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useModal } from "app/hooks/modal";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddModal from "./addSdkModal";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldBorder from "app/components/Common/FieldBorder ";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import Label from "app/components/Common/Label";
import React, { Fragment, useEffect, useState } from "react";
import Styled from "styled-components";
import TextInput from "app/components/Common/schemaForm/TextInput.js";

const AccordionSummary = Styled(MuiAccordionSummary)`
.MuiAccordionSummary-content{
  display: flex;
  justify-content: space-between;
  align-items:center;
}
`;

const MultipleGroup = data => {
  const [open, setOpen] = useState(false);
  const { show, closeModalHandler, openModalHandler } = useModal();
  const [isVisible, setIsVisible] = useState({});
  const { isBorder = true } = data;
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const { fields, remove, prepend, append } = useFieldArray({
    control,
    name: `${data.name}`,
  });

  const addGroup = ({ name, display_name }) => {
    append({
      credentials: [],
      name,
      display_name,
    });
    closeModalHandler("show");
    setOpen(true);
  };

  const addField = event => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
    prepend({ key: "", value: "" });
  };
  const onFieldDelete = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    remove(index);
  };

  const closeModal = () => {
    closeModalHandler("show");
  };

  useEffect(() => {
    if (data.indexNested >= 0) {
      register(`sdk_config[${data.indexNested}].display_name`);
      register(`sdk_config[${data.indexNested}].name`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.indexNested]);

  const groupAccordianError = has(errors, data.uid);
  return (
    <Fragment>
      <FieldBorder
        isBorder={isBorder}
        key={data.uid}
        isError={groupAccordianError}
      >
        <Accordion defaultExpanded={false} expanded={open}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-testid={`expand-${data.display_name}`}
            onClick={() => setOpen(!open)}
          >
            <Label>{data.display_name}</Label>
            <AccordionIconWrap>
              <IconButton
                className="add-icon"
                data-testid={`add-${data.display_name}`}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (data.isModal) {
                    openModalHandler("show");
                  } else {
                    addField(event);
                  }
                }}
              >
                <AddCircleOutlineIcon fontSize="small" htmlColor="black" />
              </IconButton>
              {![
                "Presence SDK",
                "Purchase SDK",
                "Experience SDK",
                "Sdk Config",
              ].includes(data.display_name) && (
                <IconButton
                  data-testid="delete_sdk"
                  onClick={event => data.onFieldDelete(event, data.indexNested)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </AccordionIconWrap>
          </AccordionSummary>
          <AccordionDetails>
            {fields.length > 0 ? (
              <>
                {fields.map((field, index) => {
                  return (
                    <Fragment key={field.id}>
                      {field.name && (
                        <Fragment key={field.id}>
                          <MultipleGroup
                            isModal={false}
                            name={`sdk_config[${index}].credentials`}
                            indexNested={index}
                            display_name={field.display_name}
                            onFieldDelete={onFieldDelete}
                          />
                        </Fragment>
                      )}

                      {!field.name && (
                        <KeyValueWrap key={field.id}>
                          <div className="wrap">
                            <TextInput
                              display_name={`key`}
                              uid={`sdk_config[${data.indexNested}].credentials[${index}].key`}
                              validations={{}}
                              visible={!!!field.key}
                              data_type="text"
                            />
                          </div>
                          <div className={!field.key ? "wrap" : ""}>
                            <TextInput
                              display_name={
                                field.key === "" ? "value" : field.key
                              }
                              uid={`sdk_config[${data.indexNested}].credentials[${index}].value`}
                              validations={{}}
                              data_type={
                                isVisible[field.id] ? "text" : "password"
                              }
                            />
                          </div>
                          <IconButton
                            data-testid="field-visible"
                            onClick={event => {
                              isVisible[field.id] = !isVisible[field.id];
                              setIsVisible({ ...isVisible });
                            }}
                            className="del-icon"
                          >
                            {isVisible[field.id] ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                          {![
                            "display_name",
                            "android_consumer_key",
                            "ios_consumer_key",
                            "brand_name",
                            "ios_api_key",
                            "android_api_key",
                            "subdomain",
                            "app_id",
                            "app_name",
                            "app_source",
                            "api_key",
                            "api_version",
                            "sso_signing_key",
                            "api_subdomain",
                          ].includes(field.key) && (
                            <IconButton
                              data-testid="field-delete"
                              onClick={event => onFieldDelete(event, index)}
                              className="del-icon"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </KeyValueWrap>
                      )}
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <NoData className="no-data">
                <p>No Data Added Yet</p>
                <Button
                  onClick={event => addField(event)}
                  data-testid="add-field"
                >
                  Add Data
                </Button>
              </NoData>
            )}
          </AccordionDetails>
        </Accordion>
      </FieldBorder>
      <AddModal show={show} closeModal={closeModal} addGroup={addGroup} />
    </Fragment>
  );
};

export default MultipleGroup;
