import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { useQueryParams } from "app/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import DevicesDataView from "./devicesDataView";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";

const DevicesData = props => {
  const { getInstallationData, data, isLoading, totalDataCount } = props;
  const { device_type, sport_key, env_key } = props.match.params;
  const { access_type } = useSelector(state => state.application);

  const [selected, setSelected] = useState([]);
  const [dataId, setDataId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [multipleDeleteModal, setMultipleDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const { searchText } = useQueryParams();

  const headings = [
    { label: "User ID", id: "user_uid" },
    { label: "Device Token", id: "device_token" },
    { label: "Device Type", id: "device_type" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  useEffect(() => {
    setSelected([]);
    getInstallationData(device_type);
  }, [getInstallationData, device_type]);

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };
  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  // Delete Api Call
  const deleteHandler = async event => {
    try {
      const { status } = await props.deleteData({
        url: `v1/push_notification/admin/installation_data/${dataId}`,
      });
      /* istanbul ignore else */
      if (status === 200) {
        handleModalClose();
        setSelected([]);
        setSelected([]);
        getInstallationData(device_type);
      }
    } catch (e) {}
  };

  const handleModalOpen = (event, uid, data) => {
    setDeleteUserId(data.uid);
    setDataId(uid);
    setOpenModal(true);
  };
  const handleModalClose = event => {
    // setSelected([]);
    setOpenModal(false);
    setMultipleDeleteModal(false);
    setDataId("");
  };
  // Delete Multiple Modal
  const multipleDeleteHandler = event => {
    setMultipleDeleteModal(true);
  };

  // Delete Multiple API call
  const deleteMultiple = async event => {
    try {
      props.deleteMultiple({
        url: "v1/push_notification/admin/installation_data",
        uids: selected,
        loader: true,
      });
      handleModalClose();
      setSelected([]);
      getInstallationData(device_type);
    } catch (e) {}
  };

  // Update Api Call
  const updateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/reports/devices/update/${uid}`,
      state: { from: props.location },
    });
  };
  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="update-icon" />,
      handler: updateHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete-icon" />,
      handler: handleModalOpen,
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <DevicesDataView
      data={data}
      headings={headings}
      checkbox={true}
      handleSelectAllClick={handleSelectAllClick}
      handleSingleClick={handleSingleClick}
      selectedList={selected}
      actions={actions}
      actionMenu={false}
      multipleDeleteHandler={multipleDeleteHandler}
      numSelected={selected.length}
      openModal={openModal}
      handleModalClose={handleModalClose}
      deleteHandler={deleteHandler}
      name={`Total ${
        searchText
          ? "Devices Data"
          : device_type === "android"
          ? "Android"
          : "IOS"
      }`}
      deleteUserId={deleteUserId}
      isSorting
      totalCount={totalDataCount}
      isLoading={isLoading}
      deleteMultiple={deleteMultiple}
      multipleDeleteModal={multipleDeleteModal}
    />
  );
};
const mapDispatchToProps = dispatch => {
  return {
    deleteData: payload => dispatch(deleteRecord(payload)),
    deleteMultiple: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(DevicesData);

export { Container, DevicesData as Component };
