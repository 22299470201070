import { Redirect, Route, Switch } from "react-router-dom";
import AddCategory from "app/pages/Dashboard/interactiveMaps/Categories/add";
import AddLevel from "app/pages/Dashboard/interactiveMaps/Levels/add";
import AddVenue from "app/pages/Dashboard/interactiveMaps/Venues/add";
import GetCategories from "app/pages/Dashboard/interactiveMaps/Categories";
import GetFeatures from "app/pages/Dashboard/interactiveMaps/Features";
import GetLevels from "app/pages/Dashboard/interactiveMaps/Levels";
import GetVenues from "app/pages/Dashboard/interactiveMaps/Venues";
import MapAdminPanel from "app/pages/Dashboard/interactiveMaps/Maps";
import UpdateCategory from "app/pages/Dashboard/interactiveMaps/Categories/update";
import UpdateLevel from "app/pages/Dashboard/interactiveMaps/Levels/update";
import UpdateVenue from "app/pages/Dashboard/interactiveMaps/Venues/update";

const InteractiveMaps = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/venues`}
        exact
      />
      {/* venue routes */}
      <Route path={`${props.match.path}/venues/add`} component={AddVenue} />
      <Route
        path={`${props.match.path}/venues/update/:uid`}
        component={UpdateVenue}
        exact
      />
      <Route
        path={`${props.match.path}/venues/edit`}
        component={MapAdminPanel}
        exact
      />
      <Route path={`${props.match.path}/venues`} component={GetVenues} />
      {/* category routes */}
      <Route
        path={`${props.match.path}/categories/add`}
        component={AddCategory}
      />
      <Route
        path={`${props.match.path}/categories/update/:uid`}
        component={UpdateCategory}
        exact
      />
      <Route
        path={`${props.match.path}/categories`}
        component={GetCategories}
      />

      {/* levels routes */}
      <Route path={`${props.match.path}/levels/add`} component={AddLevel} />
      <Route
        path={`${props.match.path}/levels/update/:uid`}
        component={UpdateLevel}
        exact
      />
      <Route path={`${props.match.path}/levels`} component={GetLevels} />
      {/* features routes */}
      <Route path={`${props.match.path}/features`} component={GetFeatures} />
    </Switch>
  );
};

export default InteractiveMaps;
