import { Autocomplete } from "styles/global.style";
import {
  Box,
  MultiChipBox,
  MultiFieldBox,
  Typography,
} from "app/pages/Dashboard/settings/features/cards/Card.style";
import { Button } from "@dfep/ui-component";
import {
  checkIsFieldProtected,
  convertValues,
  dataTypeValidation,
  formDatatypeConversion,
  getFieldComponent,
  identifyComponent,
} from "utils/formFunctions";
import { Constants } from "utils/constants";
import {
  FormProvider,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { FormWrapper } from "styles/global.style";
import { reverseTransformData, setError } from "utils/functions";
import _, { cloneDeep } from "lodash";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import React, { Fragment, useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export const RHFTextField = props => {
  const { control, name, defaultValue, rules, ...restProps } = props;
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });

  return (
    <TextField
      {...inputProps}
      inputRef={ref}
      helperText={error?.message}
      error={!!error?.message}
      onChange={onChange}
      onBlur={e => {
        e.target.value = e.target.value.trim();
        onChange(e.target.value);
      }}
      {...restProps}
    />
  );
};

export const RHFMultipleTextField = props => {
  const {
    label,
    name,
    control,
    defaultValue,
    rules,
    data_type,
    id,
    freeSolo,
    // validations,
    // filterSelectedOptions,
    // getOptionLabel = option => option?.title || option,
    // options = [],
    open = false,
    ...autoCompleteProps
  } = props;
  const {
    field: { ref, onChange, value, onBlur, ...inputProps },
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });

  return (
    <Autocomplete
      freeSolo={freeSolo}
      multiple
      // options={options}
      {...(open === false && { open: false })}
      // getOptionLabel={getOptionLabel}
      // filterSelectedOptions={filterSelectedOptions}
      value={value}
      onChange={(event, value) => {
        /*istanbul ignore else*/
        if (!open && typeof event?.target?.value === "string") {
          if (event.target.value.trim().length > 0) {
            onChange(value);
          }
        } else {
          onChange(value);
        }
      }}
      onBlur={onBlur}
      renderInput={params => {
        return (
          <TextField
            {...params}
            {...inputProps}
            id={id}
            inputRef={ref}
            type={data_type === "string" ? "text" : data_type}
            InputLabelProps={{
              required: !!rules?.required,
            }}
            error={error}
            helperText={
              (error && error?.message) ||
              (!open && `Press "Enter" to add data tag`)
            }
            variant="outlined"
            label={label}
            data-testid={`${id}-multi-input`}
          />
        );
      }}
      {...autoCompleteProps}
    />
  );
};

export const RHFSelectField = props => {
  const {
    control,
    name,
    menuOption,
    defaultValue,
    rules,
    ...restProps
  } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });
  return (
    <TextField
      select
      variant="outlined"
      inputRef={ref}
      {...restProps}
      {...inputProps}
      error={!!error}
      helperText={!!error && error?.message}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {menuOption?.map(option => (
        <MenuItem
          key={option.league_id || option.value || option.uid}
          value={option.league_id || option.value || option.uid}
        >
          {option.label || option.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const RHFCheckboxField = props => {
  const { control, label, name, defaultValue, rules, ...restProps } = props;

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({ name, control, defaultValue, rules });
  return (
    <FormControlLabel
      className="checkbox-field"
      label={label}
      control={
        <Checkbox
          {...inputProps}
          inputRef={ref}
          checked={Boolean(value)}
          onChange={onChange}
          onBlur={e => {
            onChange(e.target.checked);
          }}
          {...restProps}
        />
      }
    />
  );
};

export const Form = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  cmsCollections,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.mapper && { mapper: data.config.mapper }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    model: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  const [isVisible, setIsVisible] = useState({});

  return (
    <>
      {
        <form>
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    value: "",
                    key: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Key and Value
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Key"
                    rules={{ required: `Key is required` }}
                    control={control}
                    id={`${formType}[${index}].key`}
                    name={`${formType}[${index}].key`}
                    defaultValue={item.key}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].key-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  {formType === "mapper" ? (
                    <RHFSelectField
                      control={control}
                      rules={{
                        required: "Value is required",
                      }}
                      label="Value"
                      name={`${formType}[${index}].value`}
                      menuOption={cmsCollections}
                      defaultValue={item.value}
                      id={`${formType}[${index}].value`}
                      inputProps={{
                        readOnly: access_type === READ_ONLY,
                        "data-testid": `${formType}[${index}].value-input`,
                      }}
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                  ) : (
                    <RHFTextField
                      id={`${formType}[${index}].value`}
                      type={isVisible[item.id] ? "text" : "password"}
                      label="Value"
                      rules={{
                        required: "Value is required",
                        pattern: {
                          value: /^\S+$/,
                          message: "Invalid Pattern",
                        },
                      }}
                      control={control}
                      name={`${formType}[${index}].value`}
                      inputProps={{
                        readOnly: access_type === READ_ONLY,
                        "data-testid": `${formType}[${index}].value-input`,
                        pattern: /^\S+$/,
                      }}
                      InputLabelProps={{
                        required: true,
                      }}
                      defaultValue={item.value}
                    />
                  )}
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      {formType !== "mapper" && (
                        <IconButton
                          aria-label="visible"
                          component="span"
                          onClick={() => {
                            const uniqueId = item["id"];
                            isVisible[uniqueId] = !isVisible[uniqueId];
                            setIsVisible({ ...isVisible });
                          }}
                        >
                          {isVisible[item["id"]] ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      )}
                      <IconButton
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              disabled={
                !Object.keys(dirtyFields).length || access_type === READ_ONLY
              }
              role="button"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const TmForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.plan_event_names && {
      plan_event_names: data.config.plan_event_names,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="tm-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    name: "",
                    display_name: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Name and Display Name
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Name"
                    rules={{ required: `Name is required` }}
                    control={control}
                    id={`${formType}[${index}].name`}
                    name={`${formType}[${index}].name`}
                    defaultValue={item.name}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].name-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Display Name"
                    rules={{ required: `Display Name is required` }}
                    control={control}
                    id={`${formType}[${index}].display_name`}
                    name={`${formType}[${index}].display_name`}
                    defaultValue={item.display_name}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].display_name-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const ConfigForm = ({
  schema,
  defaultValues,
  onSubmit,
  uid,
  closeModal,
  access_type,
}) => {
  const formMethods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: cloneDeep(defaultValues),
  });

  const formSubmit = data => {
    if (uid === "broadcaster_details") {
      formDatatypeConversion({ schema, data });
    }
    onSubmit(
      checkIsFieldProtected(
        convertValues({
          data,
        }),
      ),
    );
  };

  const { READ_ONLY } = Constants;

  return (
    <FormWrapper className="feature-modal-form">
      <FormProvider {...formMethods}>
        <form
          noValidate
          onSubmit={formMethods.handleSubmit(formSubmit)}
          data-testid="schema-form"
        >
          {schema.map(schemaClass => {
            return (
              <Fragment key={`${uid}-${Math.random()}`}>
                {getFieldComponent(identifyComponent(schemaClass))}
              </Fragment>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={access_type === READ_ONLY}
            >
              Save
            </Button>
          </Box>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export const LineupPlayerStatusTextMapperForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.lineup_player_status_text_mapper && {
      lineup_player_status_text_mapper:
        data.config.lineup_player_status_text_mapper,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    // formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="lineup_mapper-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    status_text: "",
                    value: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Status Text
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Status Text"
                    rules={{ required: `Status Text is required` }}
                    control={control}
                    id={`${formType}[${index}].status_text`}
                    name={`${formType}[${index}].status_text`}
                    defaultValue={item.status_text}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].status_text-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Value"
                    rules={{ required: `Value is required` }}
                    control={control}
                    id={`${formType}[${index}].value`}
                    name={`${formType}[${index}].value`}
                    defaultValue={item.value}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].value-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const FpayForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.store_config && {
      store_config: data.config.store_config,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="fpay-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    venue_id: "",
                    venue_name: "",
                    store_id: "",
                    terminal_id: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Store Config
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <MultiFieldBox
                key={item.id}
                className="multi-cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="multi-cred-inputs">
                  <RHFTextField
                    label="Venue Id"
                    rules={{ required: `Venue Id is required` }}
                    control={control}
                    id={`${formType}[${index}].venue_id`}
                    name={`${formType}[${index}].venue_id`}
                    defaultValue={item.venue_id}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].venue_id-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Venue Name"
                    rules={{ required: `Venue Name is required` }}
                    control={control}
                    id={`${formType}[${index}].venue_name`}
                    name={`${formType}[${index}].venue_name`}
                    defaultValue={item.venue_name}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].venue_name-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />

                  <RHFTextField
                    label="Store Id"
                    rules={{ required: `Store Id is required` }}
                    control={control}
                    id={`${formType}[${index}].store_id`}
                    name={`${formType}[${index}].store_id`}
                    defaultValue={item.store_id}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].store_id-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />

                  <RHFTextField
                    label="Terminal Id"
                    rules={{ required: `Terminal Id is required` }}
                    control={control}
                    id={`${formType}[${index}].terminal_id`}
                    name={`${formType}[${index}].terminal_id`}
                    defaultValue={item.terminal_id}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].terminal_id-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="multi-cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </MultiFieldBox>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const FieldValidationForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.field_validations && {
      field_validations: data.config.field_validations,
    }),
    config: data.config,
    enabled: data?.enabled,
  };

  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const { control, handleSubmit } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="field-validation-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_button"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    isRequired: false,
                    name: "",
                    type: "",
                    regex: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Field Validations
              </Button>
            )}
          </Box>

          <div className="multi-cred-main">
            {fields.map((item, index) => {
              return (
                <div>
                  <hr />
                  <div className="multi-cred-field-title">
                    <Typography>Field Validation {index + 1}</Typography>
                  </div>
                  <MultiFieldBox
                    key={item.id}
                    className="multi-cred-form"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="multi-cred-inputs">
                      <RHFTextField
                        label="Name"
                        rules={{ required: `Name is required` }}
                        control={control}
                        id={`${formType}[${index}].name`}
                        name={`${formType}[${index}].name`}
                        defaultValue={item.name}
                        inputProps={{
                          readOnly: access_type === READ_ONLY,
                          "data-testid": `${formType}[${index}].name-input`,
                        }}
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                      <RHFTextField
                        label="Type"
                        control={control}
                        id={`${formType}[${index}].type`}
                        name={`${formType}[${index}].type`}
                        defaultValue={item.type}
                        inputProps={{
                          readOnly: access_type === READ_ONLY,
                          "data-testid": `${formType}[${index}].type-input`,
                        }}
                      />
                      <RHFTextField
                        label="Regex"
                        control={control}
                        id={`${formType}[${index}].regex`}
                        name={`${formType}[${index}].regex`}
                        defaultValue={item.regex}
                        inputProps={{
                          readOnly: access_type === READ_ONLY,
                          "data-testid": `${formType}[${index}].regex-input`,
                        }}
                      />
                      <RHFCheckboxField
                        label="isRequired"
                        control={control}
                        id={`${formType}[${index}].isRequired`}
                        name={`${formType}[${index}].isRequired`}
                        defaultValue={item.isRequired}
                        inputProps={{
                          readOnly: access_type === READ_ONLY,
                          "data-testid": `${formType}[${index}].isRequired-input`,
                        }}
                      />
                    </div>
                    <div className="multi-cred-icons">
                      {access_type !== READ_ONLY && (
                        <>
                          <IconButton
                            data-testid="del-btn"
                            aria-label="delete"
                            component="span"
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </MultiFieldBox>
                </div>
              );
            })}
          </div>

          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const HeadersForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.headers && {
      headers: data.config.headers,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    // formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="headers-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    key: "",
                    value: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Config Header
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Header Key"
                    rules={{ required: `Header Key is required` }}
                    control={control}
                    id={`${formType}[${index}].key`}
                    name={`${formType}[${index}].key`}
                    defaultValue={item.key}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].headers_key-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Headers Value"
                    rules={{ required: `headers Value is required` }}
                    control={control}
                    id={`${formType}[${index}].value`}
                    name={`${formType}[${index}].value`}
                    defaultValue={item.value}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].headers_value-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const LocaleForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.locale && {
      locale: data.config.locale,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    // formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });

  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="locale-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    key: "",
                    value: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Config Locale
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Locale Key"
                    rules={{ required: `Locale Key is required` }}
                    control={control}
                    id={`${formType}[${index}].key`}
                    name={`${formType}[${index}].key`}
                    defaultValue={item.key}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].locale_key-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Locale Value"
                    rules={{ required: `locale Value is required` }}
                    control={control}
                    id={`${formType}[${index}].value`}
                    name={`${formType}[${index}].value`}
                    defaultValue={item.value}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].locale_value-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const UserplanForm = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.user_plan && {
      user_plan: data.config.user_plan,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    // formState: { dirtyFields },
  } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });
  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="userplan-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    plan_name: "",
                    theme: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Config User Plan
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Plan Name"
                    rules={{ required: `Plan Name is required` }}
                    control={control}
                    id={`${formType}[${index}].plan_name`}
                    name={`${formType}[${index}].plan_name`}
                    defaultValue={item.plan_name}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].plan_name-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Theme"
                    rules={{ required: `Theme Value is required` }}
                    control={control}
                    id={`${formType}[${index}].theme`}
                    name={`${formType}[${index}].theme`}
                    defaultValue={item.theme}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].theme-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const AndroidConfigForm = ({
  defaultValues,
  uid,
  onSubmit,
  closeModal,
  access_type,
  formType,
}) => {
  const schema = [
    {
      uid: "priority",
      display_name: "Priority",
      data_type: "number",
      field_metadata: {},
      mandatory: false,
      multiple: false,
      _schema: [],
    },
    {
      uid: "defaultUpdateInterval",
      display_name: "Default Update Interval",
      data_type: "number",
      field_metadata: {},
      mandatory: false,
      multiple: false,
      _schema: [],
    },
    {
      uid: "defaultFastestInterval",
      display_name: "Default Fastest Interval",
      data_type: "number",
      field_metadata: {},
      mandatory: false,
      multiple: false,
      _schema: [],
    },
    {
      uid: "minUpdateDistance",
      display_name: "Minimum Update Distance",
      data_type: "string",
      field_metadata: {},
      mandatory: false,
      multiple: false,
      _schema: [],
    },
    {
      uid: "maxNumUpdates",
      display_name: "Maximum Number Of Updates",
      data_type: "number",
      field_metadata: {},
      mandatory: false,
      multiple: false,
      _schema: [],
    },
  ];

  const formMethods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: cloneDeep(defaultValues?.config?.android_config),
  });

  const formSubmit = data => {
    const finalData = cloneDeep(defaultValues);

    Object.assign(finalData, {
      android_config: dataTypeValidation({ data, schema }),
    });

    onSubmit(
      checkIsFieldProtected(
        convertValues({
          data: finalData,
        }),
      ),
    );
  };

  const { READ_ONLY } = Constants;

  return (
    <FormWrapper className="feature-modal-form">
      <FormProvider {...formMethods}>
        <form
          noValidate
          onSubmit={formMethods.handleSubmit(formSubmit)}
          data-testid={`${formType}-schema-form`}
        >
          {schema.map(schemaClass => {
            return (
              <Fragment key={`${uid}-${Math.random()}`}>
                {getFieldComponent(identifyComponent(schemaClass))}
              </Fragment>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={access_type === READ_ONLY}
            >
              Save
            </Button>
          </Box>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export const IOSConfigForm = ({
  defaultValues,
  uid,
  onSubmit,
  closeModal,
  access_type,
  formType,
}) => {
  const schema = [
    {
      uid: "desiredAccuracy",
      display_name: "Desired Accuracy",
      data_type: "string",
      field_metadata: {},
      mandatory: false,
      multiple: false,
      _schema: [],
    },
  ];

  const formMethods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: cloneDeep(defaultValues?.config?.ios_config),
  });

  const formSubmit = data => {
    const finalData = cloneDeep(defaultValues);

    Object.assign(finalData, { ios_config: data });

    onSubmit(
      checkIsFieldProtected(
        convertValues({
          data: finalData,
        }),
      ),
    );
  };

  const { READ_ONLY } = Constants;

  return (
    <FormWrapper className="feature-modal-form">
      <FormProvider {...formMethods}>
        <form
          noValidate
          onSubmit={formMethods.handleSubmit(formSubmit)}
          data-testid={`${formType}-schema-form`}
        >
          {schema.map(schemaClass => {
            return (
              <Fragment key={`${uid}-${Math.random()}`}>
                {getFieldComponent(identifyComponent(schemaClass))}
              </Fragment>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={access_type === READ_ONLY}
            >
              Save
            </Button>
          </Box>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export const CmsRefProps = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  const defaultValues = {
    ...(data.config?.cms_ref_properties && {
      cms_ref_properties: data.config.cms_ref_properties,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const { control, handleSubmit } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });
  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="cms_ref_properties-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    ref_field_uid: "",
                    content_model_uids: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add CMS Ref Properties
              </Button>
            )}
          </Box>
          <div className="multi-cred-main">
            {fields.map((item, index) => {
              return (
                <div>
                  <hr />
                  <MultiChipBox
                    key={item.id}
                    className="multi-chip-form"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="multi-chip-inputs">
                      <RHFTextField
                        label="Reference Field UID"
                        rules={{ required: `Reference UID is Required` }}
                        control={control}
                        id={`${formType}[${index}].ref_field_uid`}
                        name={`${formType}[${index}].ref_field_uid`}
                        defaultValue={item.ref_field_uid}
                        inputProps={{
                          readOnly: access_type === READ_ONLY,
                          "data-testid": `${formType}[${index}].ref_field_uid-input`,
                        }}
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                      <RHFMultipleTextField
                        label="Content Model UID"
                        name={`${formType}[${index}].content_model_uids`}
                        control={control}
                        defaultValue={item.content_model_uids}
                        rules={{ required: `Content UID is Required` }}
                        data_type="string"
                        id={`${formType}[${index}].content_model_uids`}
                        freeSolo={true}
                        inputProps={{
                          readOnly: access_type === READ_ONLY,
                          "data-testid": `${formType}[${index}].content_model_uids-input`,
                        }}
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                    </div>
                    <div className="multi-chip-icons">
                      {access_type !== READ_ONLY && (
                        <>
                          <IconButton
                            data-testid="del-btn"
                            aria-label="delete"
                            component="span"
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </MultiChipBox>
                </div>
              );
            })}
          </div>
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => submitHandler(data))}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const CmsScreenMapper = ({
  formType,
  closeModal,
  submitHandler,
  access_type,
  data,
}) => {
  /* istanbul ignore next */
  if (data.config && data.config.contentstack_screen_mapper) {
    data.config.contentstack_screen_mapper = reverseTransformData(
      data.config.contentstack_screen_mapper,
    );
  }
  const defaultValues = {
    ...(data.config?.contentstack_screen_mapper && {
      contentstack_screen_mapper: data.config.contentstack_screen_mapper,
    }),
    config: data.config,
    enabled: data?.enabled,
  };
  const useFeatureForm = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { control, handleSubmit } = useFeatureForm;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: formType,
  });
  // Finding And Returning indexes of Duplicate values Entered By User
  const findDuplicateIndexes = (array, key) => {
    const seen = new Map();
    const duplicates = {};

    for (let i = 0; i < array.length; i++) {
      const currentItem = array[i][key];
      if (seen.has(currentItem)) {
        if (!duplicates[currentItem]) {
          duplicates[currentItem] = [seen.get(currentItem)];
        }
        duplicates[currentItem].push(i);
      } else {
        seen.set(currentItem, i);
      }
    }

    const duplicateIndexes = Object.values(duplicates).flat();
    return duplicateIndexes.length > 0 ? duplicateIndexes : false;
  };
  const initialErrorState = fields.map(() => ({
    screen: {
      hasError: false,
      message: "",
    },
  }));
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));
  // This useEffect is call when fields are changed
  useEffect(() => {
    setInputError(
      Array(fields.length)
        .fill()
        .map(() => ({
          screen: {
            hasError: false,
            message: "",
          },
        })),
    );
  }, [fields]);

  /* istanbul ignore else */
  const formValidation = data => {
    let duplicateIndexes = findDuplicateIndexes(
      data.contentstack_screen_mapper,
      "screen",
    );
    if (Array.isArray(duplicateIndexes)) {
      const error = setError(
        inputError[0],
        "screen",
        "Duplicate Screen Value!",
      );

      const updatedInputError = inputError.map((item, index) => {
        if (duplicateIndexes.includes(index)) {
          return cloneDeep(error);
        } else {
          return {
            screen: {
              hasError: false,
              message: "",
            },
          };
        }
      });
      setInputError(updatedInputError);
      return;
    }
    return data;
  };
  /* istanbul ignore next */
  const changeData = data => {
    const { contentstack_screen_mapper } = data;
    if (
      !contentstack_screen_mapper ||
      !Array.isArray(contentstack_screen_mapper)
    ) {
      data["contentstack_screen_mapper"] = [];
      return data;
    }
    /* istanbul ignore next */
    const transformedData = contentstack_screen_mapper.map(item => {
      const newItem = {};
      let screen = "";
      let cms_model_uid = "";

      Object.entries(item).forEach(([key, value]) => {
        if (key === "screen") {
          screen = value;
        }
        if (key === "contentstack_model_uid") {
          cms_model_uid = value;
        }
      });
      newItem[screen] = cms_model_uid;
      return newItem;
    });
    data["contentstack_screen_mapper"] = transformedData;
    return data;
  };
  const { READ_ONLY } = Constants;

  return (
    <>
      {
        <form data-testid="cms_screen_mapper-schema-form">
          <Box className="add-btn">
            {access_type !== READ_ONLY && (
              <Button
                data-testid="add_key_btn"
                color="primary"
                component="span"
                variant="text"
                onClick={() => {
                  prepend({
                    screen: "",
                    contentstack_model_uid: "",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Config Cms Mapper
              </Button>
            )}
          </Box>
          {fields.map((item, index) => {
            return (
              <Box
                key={item.id}
                className="cred-form"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="cred-inputs">
                  <RHFTextField
                    label="Screen"
                    rules={{ required: `Screen Name is required` }}
                    control={control}
                    id={`${formType}[${index}].screen`}
                    name={`${formType}[${index}].screen`}
                    defaultValue={item.screen}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].screen_name-input`,
                    }}
                    error={inputError[index]?.screen.hasError || false}
                    helperText={inputError[index]?.screen.message || ""}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <RHFTextField
                    label="Contentstack Model UID"
                    rules={{
                      required: `Contentstack Model UID is required`,
                    }}
                    control={control}
                    id={`${formType}[${index}].contentstack_model_uid`}
                    name={`${formType}[${index}].contentstack_model_uid`}
                    defaultValue={item.contentstack_model_uid}
                    inputProps={{
                      readOnly: access_type === READ_ONLY,
                      "data-testid": `${formType}[${index}].contentstack_model_uid-input`,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className="cred-icons">
                  {access_type !== READ_ONLY && (
                    <>
                      <IconButton
                        data-testid="del-btn"
                        aria-label="delete"
                        component="span"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </Box>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
              data-testid="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              role="button"
              type="submit"
              onClick={handleSubmit(data => {
                const validationResult = formValidation(data);
                if (validationResult) {
                  const updatedData = changeData(validationResult);
                  submitHandler(updatedData);
                }
              })}
              variant="contained"
              color="primary"
              data-testid="save_btn"
            >
              Save
            </Button>
          </Box>
        </form>
      }
    </>
  );
};

export const LanguageConfigForm = ({
  schema,
  defaultValues,
  onSubmit,
  uid,
  closeModal,
  access_type,
}) => {
  const formMethods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: cloneDeep(defaultValues),
  });

  const subscribe = formMethods.watch((data, { name, type }) => {
    if (name && name.match("is_default$") && type === "change") {
      const matches = name.match(/\d+/g);
      const lastIndex = parseInt(matches.slice(-1)[0]);
      if (!isNaN(lastIndex) && lastIndex !== -1) {
        for (let i = 0, to = data.config.languages.length; i < to; i++) {
          const path = `config.languages[${i}].is_default`;
          const isDefaultBool = _(data).get(path);
          if (isDefaultBool && i !== lastIndex) {
            formMethods.setValue(path, false);
          }
        }
      }
    }
  });

  useEffect(() => {
    return () => subscribe.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribe]);

  const formSubmit = data => {
    onSubmit(
      checkIsFieldProtected(
        convertValues({
          data,
        }),
      ),
    );
  };

  const { READ_ONLY } = Constants;

  return (
    <FormWrapper className="feature-modal-form">
      <FormProvider {...formMethods}>
        <form
          noValidate
          onSubmit={formMethods.handleSubmit(formSubmit)}
          data-testid="schema-form"
        >
          {schema.map(schemaClass => {
            return (
              <Fragment key={`${uid}-${Math.random()}`}>
                {getFieldComponent(identifyComponent(schemaClass))}
              </Fragment>
            );
          })}
          <Box className="form-footer" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              role="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={access_type === READ_ONLY}
            >
              Save
            </Button>
          </Box>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};
