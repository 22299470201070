import { Route, Switch } from "react-router-dom";
import AddPlayer from "./add";
import GetPlayers from "app/pages/Dashboard/Data/Roster/Players/get";
import React from "react";
import UpdatePlayer from "./update";

const Players = props => {
  return (
    <>
      <Switch>
        <Route path={`${props.match.path}/add`} component={AddPlayer} exact />
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdatePlayer}
          exact
        />
        <Route path={props.match.path} component={GetPlayers} />
      </Switch>
    </>
  );
};

export default Players;
