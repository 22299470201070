import { Route, Switch } from "react-router-dom";
import AddPlayerLog from "app/pages/Dashboard/Data/Game/PlayerLogs/add";
import GetPlayerLogs from "app/pages/Dashboard/Data/Game/PlayerLogs/get";
import React from "react";
import UpdatePlayerLog from "app/pages/Dashboard/Data/Game/PlayerLogs/update";

const PlayerLogs = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdatePlayerLog}
        exact
      />
      <Route path={`${props.match.path}/add`} component={AddPlayerLog} exact />
      <Route path={props.match.path} component={GetPlayerLogs} />
    </Switch>
  );
};

export default PlayerLogs;
