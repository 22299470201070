import { a11yProps, Tab, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { getPushMessages } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Container as PushMessages } from "app/pages/Dashboard/pushNotification/Messages/get/Messages";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";

const Messages = props => {
  const { access_type } = useSelector(state => state.application);

  let tabObj = { completed: 0, draft: 1, hide: 2, scheduled: 3 };

  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    /*istanbul ignore next*/
    setOpen(false);
  };

  const breadCrumbItems = [
    {
      title: "Push Notification",
      link: props.match.url,
    },
    {
      title: "Messages",
    },
  ];

  // get api call
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { sort, value, skip, limit, searchText } = useQueryParams();
  const [Messagescount, setMessagesCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(
    async status => {
      setData([]);
      setIsLoading(true);
      const {
        data: { count, push_notification_data },
      } = await dispatch(
        getPushMessages({
          skip,
          limit,
          sort,
          sortValue: value,
          status,
          searchText,
        }),
      );

      if (count !== 0 && push_notification_data.length === 0) {
        initialRoute(props.history);
      } else {
        setMessagesCount(count);
        push_notification_data.map(item => {
          item.title = item.message.title;
          item.channels = "iOS, Android";
          return null;
        });
        setData(push_notification_data);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, value, skip, limit, searchText],
  );

  return (
    <>
      <PanelHeader
        title="Messages"
        breadCrumbItems={breadCrumbItems}
        refreshHandler={() => {
          /*istanbul ignore next*/
          getData(Object.keys(tabObj).find(some => tabObj[some] === activeTab));
        }}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            onClick={handleOpen}
            data-testid="add-btn"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label="Sent"
            component={Link}
            to={`${props.match.url}/completed`}
            {...a11yProps(0)}
          />
          <Tab
            label="Draft"
            component={Link}
            to={`${props.match.url}/draft`}
            {...a11yProps(1)}
          />
          <Tab
            label="Archive"
            component={Link}
            to={`${props.match.url}/hide`}
            {...a11yProps(2)}
          />

          <Tab
            label="Scheduled"
            component={Link}
            to={`${props.match.url}/scheduled`}
            {...a11yProps(3)}
          />
        </Tabs>
      )}
      {/* <TabPanel> */}
      <Switch>
        <Redirect
          from={`${props.match.path}`}
          to={`${props.match.url}/completed`}
          exact
        />
        <Route
          path={`${props.match.path}/:status`}
          /*istanbul ignore next*/
          render={Routeprops => {
            /*istanbul ignore next*/
            return (
              <PushMessages
                {...Routeprops}
                handleClose={handleClose}
                dialogOpen={open}
                data={data}
                Messagescount={Messagescount}
                isLoading={isLoading}
                getData={getData}
              />
            );
          }}
          exact
        />
      </Switch>
      {/* </TabPanel> */}
    </>
  );
};

export default Messages;
