import { LoaderWrap } from "./index.style";
import LoaderImg from "assets/loader.gif";
import React from "react";

const Loader = props => {
  return (
    <LoaderWrap>
      <img
        data-testid="loader-img"
        alt="Interactive-maps Loader"
        src={LoaderImg}
      />
    </LoaderWrap>
  );
};

Loader.propTypes = {};

export default Loader;
