import styled from "styled-components";
import TextField from "app/components/Common/TextField";

export const SearchComponent = styled(TextField)`
  padding: 0;
  .MuiTextField-root {
    margin-left: 16px;
    background-color: ${props => props.theme.palette.white.main};
  }
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 9px;
    }
    fieldset {
      border-radius: 4px;
    }
  }
  .MuiFormLabel-root {
    transform: translate(14px, 11px) scale(1);
  }
  .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;
