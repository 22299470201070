import { handleError } from "../helper";
import { httpRequest } from "..";
import { showLoader } from "store/reducers/loaderSlice";
import { showNotifier } from "store/reducers/notifierSlice";

export const deleteRecords = ({ url, uids, loader = true }) => (
  dispatch,
  getState,
) => {
  const { sport_key, env_key } = getState().application;

  if (!uids) {
    uids = {};
  }

  const httpObject = {
    url,
    method: "delete",
    data: {
      uids: uids,
    },
    headers: {
      sport_key,
      env_key,
    },
  };
  return new Promise(function (resolve, reject) {
    /* istanbul ignore else */
    if (loader) {
      dispatch(showLoader({ show: true }));
    }
    httpRequest(httpObject)
      .then(response => {
        dispatch(showLoader({ show: false }));
        dispatch(
          showNotifier({
            open: true,
            message: response.data.notice,
            variant: "success",
          }),
        );
        resolve(response);
      })
      .catch(error => handleError(error, dispatch));
  });
};
