import { Button, Modal } from "@dfep/ui-component";
import { deleteConfirmationMessage } from "utils/functions";
import { NoData } from "styles/global.style";
import { Container as UploadCsv } from "app/pages/Dashboard/pushNotification/Segments/UploadCsv";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const breadCrumbItems = (sport_key, env_key) => [
  {
    title: "Push Notification",
    link: `/dashboard/${sport_key}/${env_key}/push-notification`,
  },
  {
    title: "Segments",
  },
];

const SegmentView = ({
  deleteModal,
  deleteHandler,
  handleModalClose,
  totalCount,
  isLoading,
  headings,
  actions,
  checkbox,
  name,
  addSegmentHandler,
  getSegmentData,
  deleteMultipleModal,
  deleteMultiple,
  numSelected,
  access_type,
  Constants,
  sport_key,
  env_key,
  ...restProps
}) => {
  return (
    <>
      <PanelHeader
        title="Segments"
        breadCrumbItems={breadCrumbItems(sport_key, env_key)}
        refreshHandler={getSegmentData}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <>
            <UploadCsv getSegmentData={getSegmentData} />
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={addSegmentHandler}
              data-testid="add-segment-btn"
            >
              Add
            </Button>
          </>
        )}
      </PanelHeader>
      {isLoading ? (
        <SkeletonTable
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={actions}
        />
      ) : totalCount > 0 ? (
        <Table
          totalCount={totalCount}
          headings={headings}
          checkbox={checkbox}
          name={name}
          actions={access_type !== Constants.READ_ONLY ? actions : []}
          numSelected={numSelected}
          {...restProps}
        />
      ) : (
        <NoData>No Data Found</NoData>
      )}

      <Modal
        className="small"
        open={deleteModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {deleteConfirmationMessage({
          name: restProps?.deleteData?.name,
          module: name,
        })}
      </Modal>
      <Modal
        className="small"
        open={deleteMultipleModal}
        handleClose={handleModalClose}
        title={`Delete ${name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        Are you sure you want to delete <strong>{numSelected}</strong> messeges?
      </Modal>
    </>
  );
};

export default SegmentView;
