import { connect } from "react-redux";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useEffect, useState } from "react";
import UpdateTemplateForm from "../form/UpdateTemplateForm";

const UpdateTemplate = props => {
  const {
    location: { state },
  } = props;
  const { uid, sport_key, env_key } = props.match.params;
  const [data, setData] = useState();

  const getTemplateData = async props => {
    const { data } = await props.getTemplate({
      url: `/v1/push_notification/admin/push_template/${uid}`,
    });

    setData(data.push_template);
  };

  useEffect(() => {
    getTemplateData(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateTemplate = async form => {
    const { data } = await props.UpdateTemplate({
      url: `/v1/push_notification/admin/push_template/${uid}`,
      key: "push_template",
      form,
    });
    /* istanbul ignore else */
    if (data?.push_template?.type) {
      props.history.push(
        `/dashboard/${sport_key}/${env_key}/push-notification/template`,
      );
    }
  };

  const CancelForm = () => {
    props.history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/push-notification/template`,
    );
  };

  return (
    <Fragment>
      <PanelHeader
        title="Update Template"
        backUrl={`/dashboard/${sport_key}/${env_key}/push-notification/template`}
        breadCrumbItems={[
          {
            title: "Push Notification",
          },
        ]}
      ></PanelHeader>
      {data && (
        <UpdateTemplateForm
          team_name={props.team_name}
          push_template={data}
          CancelForm={CancelForm}
          UpdateTemplate={UpdateTemplate}
        />
      )}
    </Fragment>
  );
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    team_name: state.application.team_name,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getTemplate: payload => dispatch(get(payload)),
    UpdateTemplate: payload => dispatch(updateRecord(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(UpdateTemplate);

export { Container, UpdateTemplate as Component };
