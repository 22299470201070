import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Game Details",
    uid: "game_details",
    _schema: [
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },

      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "Message ID",
        uid: "mid",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game ID",
        uid: "gid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Code",
        uid: "gcode",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Game Time",
        uid: "gametime",
        mandatory: true,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Arena City",
        uid: "ac",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Arena Stats",
        uid: "as",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Arena name",
        uid: "an",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Clock",
        uid: "cl",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },

      {
        display_name: "Game Status",
        uid: "st",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Period",
        uid: "p",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Attendance",
        uid: "at",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Games Status Text",
        uid: "stt",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Games Duration",
        uid: "dur",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Visitor Line Score",
        uid: "vls",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Team Abbrivation",
            uid: "ta",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team Name",
            uid: "tn",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team City",
            uid: "tc",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team ID",
            uid: "tid",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Score",
            uid: "s",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Full timeout Remaining",
            uid: "ftout",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Short Timeout Remaining",
            uid: "stout",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 1 points",
            uid: "q1",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 2 points",
            uid: "q2",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 3 points",
            uid: "q3",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 4 points",
            uid: "q4",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 1 points",
            uid: "ot1",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 2 points",
            uid: "ot2",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 3 points",
            uid: "ot3",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 4 points",
            uid: "ot4",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 5 points",
            uid: "ot5",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 6 points",
            uid: "ot6",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 7 points",
            uid: "ot7",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 8 points",
            uid: "ot8",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 9 points",
            uid: "ot9",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 10 points",
            uid: "ot10",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Team Stats Game",
            uid: "tstsg",
            mandatory: false,
            multiple: false,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Fields Goal Attempted",
                uid: "fga",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fields Goal Made",
                uid: "fgm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Three Points Attempted",
                uid: "tpa",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Three Points Made",
                uid: "tpm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Free Throws Attempted",
                uid: "fta",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Free Throws Made",
                uid: "ftm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Offensive Rebounds",
                uid: "oreb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Defensive Rebounds",
                uid: "dreb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Rebounds",
                uid: "reb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Assists",
                uid: "ast",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Steals",
                uid: "stl",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Blocks",
                uid: "blk",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fouls",
                uid: "pf",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "TurnOvers",
                uid: "tov",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fast Break Points",
                uid: "fbpts",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fast Break Points Attempted",
                uid: "fbptsa",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fast Break Points Made",
                uid: "fbptsm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Points in the paint",
                uid: "pip",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Points in the paint Attempted",
                uid: "pipa",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Points in the paint Made",
                uid: "pipm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Biggest Lead",
                uid: "ble",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Bench Points",
                uid: "bpts",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Team Technical Fouls",
                uid: "tf",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "SCP",
                uid: "scp",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "TMREB",
                uid: "tmreb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "TMTOV",
                uid: "tmtov",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "POTOV",
                uid: "potov",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Field Goal Percentage",
                uid: "fg_pct",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Three Point Percentage",
                uid: "tp_pct",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Free Throws Percentage",
                uid: "ft_pct",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Minutes",
                uid: "min",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Team Fouls",
                uid: "team_fouls",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Flagrant Fouls",
                uid: "flagrant_fouls",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Ejections",
                uid: "ejections",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Blocks Against",
                uid: "blocks_against",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Longest Run",
                uid: "longest_run",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Total Turnovers",
                uid: "total_turnovers",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Second Chance Points Made",
                uid: "second_chance_points_made",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Second Chance Points Attempted",
                uid: "second_chance_points_attempted",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
            ],
          },
          {
            display_name: "Quarter Team Stats Game",
            uid: "quarters_stats",
            mandatory: false,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Period",
                uid: "p",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Team Stats Game",
                uid: "tstsg",
                mandatory: false,
                multiple: false,
                data_type: "group",
                field_metadata: {},
                _schema: [
                  {
                    display_name: "Fields Goal Attempted",
                    uid: "fga",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fields Goal Made",
                    uid: "fgm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Three Points Attempted",
                    uid: "tpa",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Three Points Made",
                    uid: "tpm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Free Throws Attempted",
                    uid: "fta",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Free Throws Made",
                    uid: "ftm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Offensive Rebounds",
                    uid: "oreb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Defensive Rebounds",
                    uid: "dreb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Rebounds",
                    uid: "reb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Assists",
                    uid: "ast",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Steals",
                    uid: "stl",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Blocks",
                    uid: "blk",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fouls",
                    uid: "pf",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "TurnOvers",
                    uid: "tov",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fast Break Points",
                    uid: "fbpts",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fast Break Points Attempted",
                    uid: "fbptsa",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fast Break Points Made",
                    uid: "fbptsm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Points in the paint",
                    uid: "pip",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Points in the paint Attempted",
                    uid: "pipa",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Points in the paint Made",
                    uid: "pipm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Biggest Lead",
                    uid: "ble",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Bench Points",
                    uid: "bpts",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Team Technical Fouls",
                    uid: "tf",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "SCP",
                    uid: "scp",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "TMREB",
                    uid: "tmreb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "TMTOV",
                    uid: "tmtov",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "POTOV",
                    uid: "potov",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Field Goal Percentage",
                    uid: "fg_pct",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Three Point Percentage",
                    uid: "tp_pct",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Free Throws Percentage",
                    uid: "ft_pct",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Minutes",
                    uid: "min",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Team Fouls",
                    uid: "team_fouls",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Flagrant Fouls",
                    uid: "flagrant_fouls",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Ejections",
                    uid: "ejections",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Blocks Against",
                    uid: "blocks_against",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Longest Run",
                    uid: "longest_run",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Total Turnovers",
                    uid: "total_turnovers",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Second Chance Points Made",
                    uid: "second_chance_points_made",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Second Chance Points Attempted",
                    uid: "second_chance_points_attempted",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                ],
              },
            ],
          },
          {
            display_name: "Stat Leaders",
            uid: "stat_leaders",
            mandatory: false,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Statistic",
                uid: "stat",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Value",
                uid: "value",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Players",
                uid: "players",
                mandatory: false,
                multiple: true,
                data_type: "group",
                field_metadata: {},
                _schema: [
                  {
                    display_name: "Person ID",
                    uid: "pid",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Player Code",
                    uid: "player_code",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                  {
                    display_name: "First Name",
                    uid: "fn",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                  {
                    display_name: "Last Name",
                    uid: "ln",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                ],
              },
            ],
          },
          {
            display_name: "In Season Tournament Group",
            uid: "ist_group",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Home Line Score",
        uid: "hls",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Team Abbrivation",
            uid: "ta",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team Name",
            uid: "tn",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team City",
            uid: "tc",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Team ID",
            uid: "tid",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Score",
            uid: "s",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Full timeout Remaining",
            uid: "ftout",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Short Timeout Remaining",
            uid: "stout",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 1 points",
            uid: "q1",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 2 points",
            uid: "q2",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 3 points",
            uid: "q3",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Quarter 4 points",
            uid: "q4",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 1 points",
            uid: "ot1",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 2 points",
            uid: "ot2",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 3 points",
            uid: "ot3",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 4 points",
            uid: "ot4",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 5 points",
            uid: "ot5",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 6 points",
            uid: "ot6",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 7 points",
            uid: "ot7",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 8 points",
            uid: "ot8",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 9 points",
            uid: "ot9",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime 10 points",
            uid: "ot10",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Team Stats Game",
            uid: "tstsg",
            mandatory: false,
            multiple: false,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Fields Goal Attempted",
                uid: "fga",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fields Goal Made",
                uid: "fgm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Three Points Attempted",
                uid: "tpa",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Three Points Made",
                uid: "tpm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Free Throws Attempted",
                uid: "fta",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Free Throws Made",
                uid: "ftm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Offensive Rebounds",
                uid: "oreb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Defensive Rebounds",
                uid: "dreb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Rebounds",
                uid: "reb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Assists",
                uid: "ast",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Steals",
                uid: "stl",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Blocks",
                uid: "blk",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fouls",
                uid: "pf",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "TurnOvers",
                uid: "tov",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fast Break Points",
                uid: "fbpts",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fast Break Points Attempted",
                uid: "fbptsa",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Fast Break Points Made",
                uid: "fbptsm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Points in the paint",
                uid: "pip",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Points in the paint Attempted",
                uid: "pipa",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Points in the paint Made",
                uid: "pipm",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Biggest Lead",
                uid: "ble",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Bench Points",
                uid: "bpts",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Team Technical Fouls",
                uid: "tf",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "SCP",
                uid: "scp",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "TMREB",
                uid: "tmreb",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "TMTOV",
                uid: "tmtov",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "POTOV",
                uid: "potov",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Field Goal Percentage",
                uid: "fg_pct",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Three Point Percentage",
                uid: "tp_pct",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Free Throws Percentage",
                uid: "ft_pct",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Minutes",
                uid: "min",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Team Fouls",
                uid: "team_fouls",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Flagrant Fouls",
                uid: "flagrant_fouls",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Ejections",
                uid: "ejections",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Blocks Against",
                uid: "blocks_against",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Longest Run",
                uid: "longest_run",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Total Turnovers",
                uid: "total_turnovers",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Second Chance Points Made",
                uid: "second_chance_points_made",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Second Chance Points Attempted",
                uid: "second_chance_points_attempted",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
            ],
          },
          {
            display_name: "Quarter Team Stats Game",
            uid: "quarters_stats",
            mandatory: false,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Period",
                uid: "p",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Team Stats Game",
                uid: "tstsg",
                mandatory: false,
                multiple: false,
                data_type: "group",
                field_metadata: {},
                _schema: [
                  {
                    display_name: "Fields Goal Attempted",
                    uid: "fga",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fields Goal Made",
                    uid: "fgm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Three Points Attempted",
                    uid: "tpa",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Three Points Made",
                    uid: "tpm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Free Throws Attempted",
                    uid: "fta",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Free Throws Made",
                    uid: "ftm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Offensive Rebounds",
                    uid: "oreb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Defensive Rebounds",
                    uid: "dreb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Rebounds",
                    uid: "reb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Assists",
                    uid: "ast",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Steals",
                    uid: "stl",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Blocks",
                    uid: "blk",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fouls",
                    uid: "pf",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "TurnOvers",
                    uid: "tov",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fast Break Points",
                    uid: "fbpts",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fast Break Points Attempted",
                    uid: "fbptsa",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Fast Break Points Made",
                    uid: "fbptsm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Points in the paint",
                    uid: "pip",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Points in the paint Attempted",
                    uid: "pipa",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Points in the paint Made",
                    uid: "pipm",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Biggest Lead",
                    uid: "ble",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Bench Points",
                    uid: "bpts",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Team Technical Fouls",
                    uid: "tf",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "SCP",
                    uid: "scp",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "TMREB",
                    uid: "tmreb",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "TMTOV",
                    uid: "tmtov",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "POTOV",
                    uid: "potov",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Field Goal Percentage",
                    uid: "fg_pct",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Three Point Percentage",
                    uid: "tp_pct",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Free Throws Percentage",
                    uid: "ft_pct",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Minutes",
                    uid: "min",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Team Fouls",
                    uid: "team_fouls",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Flagrant Fouls",
                    uid: "flagrant_fouls",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Ejections",
                    uid: "ejections",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Blocks Against",
                    uid: "blocks_against",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Longest Run",
                    uid: "longest_run",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Total Turnovers",
                    uid: "total_turnovers",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Second Chance Points Made",
                    uid: "second_chance_points_made",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Second Chance Points Attempted",
                    uid: "second_chance_points_attempted",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                ],
              },
            ],
          },
          {
            display_name: "Stat Leaders",
            uid: "stat_leaders",
            mandatory: false,
            multiple: true,
            data_type: "group",
            field_metadata: {},
            _schema: [
              {
                display_name: "Statistic",
                uid: "stat",
                mandatory: false,
                multiple: false,
                data_type: "string",
                field_metadata: {},
              },
              {
                display_name: "Value",
                uid: "value",
                mandatory: false,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Players",
                uid: "players",
                mandatory: false,
                multiple: true,
                data_type: "group",
                field_metadata: {},
                _schema: [
                  {
                    display_name: "Person ID",
                    uid: "pid",
                    mandatory: false,
                    multiple: false,
                    data_type: "number",
                    field_metadata: {},
                  },
                  {
                    display_name: "Player Code",
                    uid: "player_code",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                  {
                    display_name: "First Name",
                    uid: "fn",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                  {
                    display_name: "Last Name",
                    uid: "ln",
                    mandatory: false,
                    multiple: false,
                    data_type: "string",
                    field_metadata: {},
                  },
                ],
              },
            ],
          },
          {
            display_name: "In Season Tournament Group",
            uid: "ist_group",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Game Stats",
        uid: "gsts",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Leads Changes",
            uid: "lc",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
            _schema: [],
          },
          {
            display_name: "Times Tied",
            uid: "tt",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
            _schema: [],
          },
        ],
      },
      {
        display_name: "Game Subtype",
        uid: "game_subtype",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
