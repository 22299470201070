import { cloneDeep } from "lodash";
import { getOnBoardScreenOptions } from "services/getObjects";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import schema from "./schema";
import SchemaForm from "app/components/Common/schemaForm";

const Form = ({ onSubmit, modelName, backUrl, props, defaultValues }) => {
  // Params
  const { sport_key, env_key } = props.match.params;
  // Redux Hooks
  const dispatch = useDispatch();

  // State
  const [schemaState, setSchemaState] = useState();
  const [screenOptions, setScreenOptions] = useState([]);

  const getScreens = async () => {
    const {
      data: { onboarding_screens },
    } = await dispatch(
      getOnBoardScreenOptions({
        sort: "updated_at",
        sortValue: -1,
        sport_key,
        env_key,
      }),
    );

    const opts = onboarding_screens.map(screen => {
      return { label: screen.title, value: screen.uid };
    });
    setScreenOptions(opts);
  };
  // Component Life Cycle
  useEffect(() => {
    getScreens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Component Life Cycle
  useEffect(() => {
    if (screenOptions.length) {
      const Schema = cloneDeep(schema);
      Schema.class._schema.forEach(obj => {
        if (obj.uid === "screens") {
          obj._schema.forEach(item => {
            if (
              item.uid === "screen" &&
              item.field_metadata.options.length === 1
            ) {
              item.field_metadata.options.push(...screenOptions);
            }
          });
        }
      });
      setSchemaState(Schema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenOptions]);

  return (
    <>
      {schemaState && (
        <SchemaForm
          onSubmit={onSubmit}
          schema={schemaState}
          modelName={modelName}
          backUrl={backUrl}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default Form;
