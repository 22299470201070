import { deleteConfirmationMessage } from "utils/functions";
import { Fragment } from "react";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const TableView = props => {
  const {
    isLoading,
    openModal,
    handleModalClose,
    handleModalDelete,
    multiDeleteReq,
    eventObj,
    syncEvents,
    openSyncModal,
    syncModalClose,
    // refresh,
    ...restProps
  } = props;
  return (
    <Fragment>
      {isLoading ? (
        <SkeletonTable
          headings={props.headings}
          name={props.topicName}
          actions={props.actions}
        />
      ) : props.data.length > 0 ? (
        <Table {...restProps} isSorting />
      ) : (
        <NoData>No Data Found</NoData>
      )}

      <Modal
        className="small"
        open={openModal.delModal}
        handleClose={handleModalClose}
        title={`Delete ${restProps?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: handleModalDelete,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {eventObj &&
          deleteConfirmationMessage({
            name: eventObj?.name,
            module: restProps?.name,
          })}
      </Modal>
      <Modal
        className="small"
        open={openModal.multiDelModal}
        data-testid="delete-modal"
        handleClose={handleModalClose}
        title={`Delete ${restProps?.name}`}
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: multiDeleteReq,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {props.numSelected > 0 && (
          <>
            Are you sure you want to delete <strong>{props.numSelected}</strong>{" "}
            items?
          </>
        )}
      </Modal>
      <Modal
        className="small"
        open={openSyncModal}
        data-testid="sync-modal"
        handleClose={syncModalClose}
        title="Sync"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: syncModalClose,
            additionalAttributes: {
              "data-testid": "sync-cancel",
            },
          },
          {
            id: "2",
            name: "Start",
            handler: syncEvents,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "start-sync",
            },
          },
        ]}
      >
        Are you sure you want to start event sync?
      </Modal>
    </Fragment>
  );
};

export default TableView;
