/* istanbul ignore file */
import { acceptFileType } from "utils/constants";
import { Button, Modal } from "@dfep/ui-component";
import { bytesToSize } from "utils/functions";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Fragment } from "react";
import { getAssets } from "services/getObjects";
import { ModalSubheading, UploadedAssetsModal } from "./modal.style";
import { UploadImgPanel } from "styles/global.style";
import { uploadObject } from "services/uploadObjects";
import { useModal } from "app/hooks/modal";
import { useParams } from "react-router";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FileUpload from "app/components/Common/FileUpload";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PDFImg from "assets/pdf.png";
import React, { useEffect, useRef, useState } from "react";
import RefreshTwoToneIcon from "@material-ui/icons/RefreshTwoTone";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "app/components/Common/Form/TextField";
import TextFieldSimple from "app/components/Common/TextField";

const WAIT_INTERVAL = 700;
let typingTimeOut = null;

const UploadImage = props => {
  const [addAssetModal, setAddAssetModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [img, setImg] = useState("");
  const [url, setUrl] = useState();
  const [action, setAction] = useState();
  const [assetData, setAssetData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const fileInputRef = useRef();
  const { sport_key } = useParams();
  const { show, openModalHandler, closeModalHandler } = useModal();
  const { control, image_url, setValue } = props;

  const openAssetHandler = event => {
    setAddAssetModal(true);
  };

  const closeAssetHandler = event => {
    setSelectedFiles([]);
    setAddAssetModal(false);
  };

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find(item => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  const addAssetHandler = event => {
    startUpload(selectedFiles);
  };

  const startUpload = files => {
    let counter = 0;
    files.forEach(async (file, index) => {
      const formData = new FormData();
      formData.append("asset[upload]", file);
      const response = await props.uploadImage({
        url: `v1/admin/applications/${sport_key}/assets`,
        formData,
      });
      counter++;
      if (response && counter === files.length) {
        setValue(props.fieldName, response.data.asset.url, {
          shouldDirty: true,
        });
        props.ImageState(response.data.asset.url);
        setUrl(response.data.asset.url);
        setAddAssetModal(false);
        setSelectedFiles([]);
      }
    });
  };

  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = files => {
    if (files.length && validateFile(files[0])) setSelectedFiles([files[0]]);
  };

  const validateFile = file => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "application/pdf",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const removeFile = name => {
    const validFileIndex = validFiles.findIndex(e => e.name === name);
    validFiles.splice(validFileIndex, 1);

    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);

    setSelectedFiles([...selectedFiles]);
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  //select image
  const getUploadedHandler = e => {
    let image = img;
    setValue(props.fieldName, image, {
      shouldDirty: true,
    });
    props.ImageState(img);
    setUrl(image);
    setSelectedItem("");
    closeModalHandler("show");
  };

  //close Modal
  const getcloseModalHandler = e => {
    setSelectedItem("");
    setImg("");
    setValue(props.fieldName, undefined, {
      shouldDirty: false,
    });
    closeModalHandler("show");
  };

  //for update image from backend
  useEffect(() => {
    if (image_url) {
      setUrl(image_url);
    }
    props.ImageState(image_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image_url]);

  const getAssetsData = async searchText => {
    setAssetData([]);
    const { data } = await props.getAssets({ sport_key, searchText });
    setAssetData(data.assets);
  };

  const onAssetSearch = event => {
    const text = event.target.value.trim();
    clearTimeout(typingTimeOut);
    typingTimeOut = setTimeout(() => {
      getAssetsData(text);
    }, WAIT_INTERVAL);
  };

  //for showing url in text filed
  const ImageTextFiled = {
    watchInstance: "",
    watchOn: "",
    control,
    validations: {},
    fieldName: props.fieldName,
    uid: props.uid,
    title: props.title,
    type: "text",
    className: "img_url",
    readOnly: true,
    disabled: false,
    doNotTrim: false,
    defaultValue: url,
  };

  const determineItemStyle = uid => {
    const isItemSelected = selectedItem === uid;
    return isItemSelected ? "active" : "";
  };

  return (
    <Fragment>
      {/*Url Field*/}
      {url ? (
        <div className="url-view">
          <TextField {...ImageTextFiled} />
          <IconButton
            className="close-icon"
            onClick={() => {
              props.ImageState("");
              setUrl();
              setImg("");
              setValue(props.fieldName, undefined, {
                shouldDirty: false,
              });
              setAction(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ) : (
        <>
          <UploadImgPanel>
            <Button
              variant="outlined"
              color="default"
              onClick={() => {
                openModalHandler("show");
                getAssetsData();
              }}
              className="choose-btn"
            >
              Choose From Uploads
            </Button>
            <span>Or</span>
            <Button
              className="upload-btn"
              color="primary"
              onClick={openAssetHandler}
              data-testid="add-asset"
            >
              Upload New File
            </Button>
            <span>Or</span>
            <div className="img_url_input">
              <TextFieldSimple
                value={img}
                id={`${props.uid}-url`}
                type="text"
                label="Image URL"
                variant="outlined"
                onChange={e => {
                  setImg(e.target.value);
                  setAction(true);
                }}
              />
              {/*actions buttons*/}
              {action && (
                <div className="input-actions">
                  <IconButton
                    onClick={() => {
                      const image = img;
                      setValue(props.fieldName, image, {
                        shouldDirty: true,
                      });
                      props.ImageState(image);
                      setUrl(image);
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setImg("");
                      props.ImageState("");
                      setValue(props.fieldName, undefined, {
                        shouldDirty: false,
                      });
                      setAction(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </UploadImgPanel>
          {/* file image modal */}
          <Modal
            data-testid="add-asset-modal"
            className="small"
            open={addAssetModal}
            handleClose={closeAssetHandler}
            title="Upload Assets"
            maxwidth="sm"
            actions={[
              { id: "1", name: "Cancel", handler: closeAssetHandler },
              {
                id: "2",
                name: "Add File",
                handler: addAssetHandler,
                additionalAttributes: {
                  variant: "contained",
                  color: "primary",
                },
              },
            ]}
          >
            <FileUpload
              acceptFileType={acceptFileType}
              selectMultiple={false}
              dragOver={dragOver}
              dragEnter={dragEnter}
              dragLeave={dragLeave}
              fileDrop={fileDrop}
              selectedFiles={selectedFiles}
              fileSize={bytesToSize}
              validFiles={validFiles}
              removeFile={removeFile}
              fileInputClicked={fileInputClicked}
              fileInputRef={fileInputRef}
              filesSelected={filesSelected}
            />
          </Modal>
          {/* select image modal */}
          <UploadedAssetsModal
            data-testid="add-asset-modal"
            className="medium uploaded-assets"
            open={show}
            handleClose={getcloseModalHandler}
            title="Choose File"
            maxwidth="md"
            actions={[
              {
                id: "1",
                name: "Cancel",
                handler: getcloseModalHandler,
              },
              {
                id: "2",
                name: "Done",
                handler: getUploadedHandler,
                additionalAttributes: {
                  variant: "contained",
                  color: "primary",
                },
              },
            ]}
          >
            <ModalSubheading>
              <TextFieldSimple label="Search" onChange={onAssetSearch} />
              <IconButton
                onClick={() => {
                  getAssetsData();
                }}
              >
                <RefreshTwoToneIcon />
              </IconButton>
            </ModalSubheading>
            <div className="inner-content">
              <Grid container spacing={6}>
                {assetData?.length > 0 ? (
                  assetData.map(item => {
                    return (
                      <Grid
                        key={item.uid}
                        xs={4}
                        onClick={() => {
                          setSelectedItem(item.uid);
                          setImg(item.url);
                          if (selectedItem === item.uid) {
                            setSelectedItem("");
                            setImg();
                          }
                        }}
                        item
                      >
                        <Card className={determineItemStyle(item.uid)}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="170"
                              width="253"
                              image={
                                item.content_type === "application/pdf"
                                  ? PDFImg
                                  : item.url
                              }
                              title={item.filename}
                            />
                            <CardContent>
                              <Typography variant="body1">
                                {item.filename}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <>
                    <Grid xs={4} item>
                      <Skeleton variant="rect" width={253} height={200} />
                      <Skeleton width="40%" />
                    </Grid>
                    <Grid xs={4} item>
                      <Skeleton variant="rect" width={253} height={200} />
                      <Skeleton width="40%" />
                    </Grid>
                    <Grid xs={4} item>
                      <Skeleton variant="rect" width={253} height={200} />
                      <Skeleton width="40%" />
                    </Grid>
                    <Grid xs={4} item>
                      <Skeleton variant="rect" width={253} height={200} />
                      <Skeleton width="40%" />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </UploadedAssetsModal>
        </>
      )}
    </Fragment>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    uploadImage: payload => dispatch(uploadObject(payload)),
    getAssets: payload => dispatch(getAssets(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(UploadImage);
export { Container, UploadImage as Component };
