import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize, initialRoute } from "utils/functions";
import { Constants } from "utils/constants";
import { getAdvTeamStats } from "services/getObjects";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import AdvStatsData from "app/pages/Dashboard/Data/Team/AdvanceStats/get/AdvStatsData";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";
import Settings from "./settings";

function GetAdvStats({ history, match, location }) {
  // const { url } = match;

  const { READ_ONLY } = Constants;
  const { tid, team_name, access_type } = useSelector(
    state => state.application,
  );

  let tabObj = {};
  tabObj[team_name] = 0;

  tabObj = { ...tabObj, others: 1, settings: 2 };
  const tab = tabObj[location.pathname.split("/").slice(-1)[0]]; // remove "heat" from /dashboard/testone/data/rosters/players/get/heat

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const [advStatData, setAdvStatData] = useState([]);
  const [advStatCount, setAdvStatCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { sort, value: sortValue, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const { sport_key, env_key } = match.params;
  const dispatch = useDispatch();

  const fetchAdvStatsData = useCallback(async () => {
    setIsLoading(true);
    const query = {
      tid: activeTab === 0 ? tid : { $ne: tid },
    };
    const {
      data: { advanced_team_stats, count },
    } = await dispatch(
      getAdvTeamStats({
        tid,
        skip,
        limit,
        sort,
        sortValue,
        query,
        searchText,
      }),
    );

    if (count !== 0 && advanced_team_stats.length === 0) {
      initialRoute(history);
    } else {
      setAdvStatData(advanced_team_stats);
      setAdvStatCount(count);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, skip, limit, sort, sortValue, activeTab, searchText]);

  const syncModalClose = () => {
    closeModalHandler();
  };
  const syncDetails = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/advanced_team_stats" }));
    closeModalHandler();
  };

  // if (tab === undefined) {
  //   return <Redirect from={`${url}`} to={`${url}/${team_name}`} />;
  // }

  return (
    <>
      <PanelHeader
        title="Advance Team Stats"
        breadCrumbItems={[
          {
            title: "Advance Team Stats",
          },
        ]}
        refreshHandler={fetchAdvStatsData}
        {...(access_type !== READ_ONLY && {
          sync: () => openModalHandler("show"),
        })}
        search={true}
      >
        {access_type !== READ_ONLY ? (
          <>
            <Button
              color="primary"
              variant="contained"
              data-testid="Add-btn"
              startIcon={<AddIcon />}
              onClick={() =>
                history.push({
                  pathname: `/dashboard/${sport_key}/${env_key}/data/team/advanceStats/add`,
                  state: { from: location },
                })
              }
            >
              Add
            </Button>
          </>
        ) : null}
      </PanelHeader>

      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${match.url}/${team_name}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${match.url}/others`}
            {...a11yProps(1)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${match.url}/settings`}
            {...a11yProps(2)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect
            from={`${match.path}`}
            to={`${match.url}/${team_name}`}
            exact
          />

          <Route path={`${match.path}/settings`} component={Settings} exact />
          <Route
            path={`${match.path}/:team_name`}
            /*  istanbul ignore next   */
            render={routeProps => {
              /*  istanbul ignore next   */
              return (
                <AdvStatsData
                  fetchAdvStatsData={fetchAdvStatsData}
                  setAdvStatData={setAdvStatData}
                  advStatData={advStatData}
                  advStatCount={advStatCount}
                  isLoading={isLoading}
                  dispatch={dispatch}
                  openSyncModal={show}
                  syncModalClose={syncModalClose}
                  syncDetails={syncDetails}
                  access_type={access_type}
                  READ_ONLY={READ_ONLY}
                  {...routeProps}
                />
              );
            }}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
}

export default GetAdvStats;
