import styled from "styled-components";

export const TreeFormWrapper = styled.div.attrs(props => ({
  className: "tree-wrapper",
}))`
  border-radius: 4px;
  background-color: ${props => props.theme.palette.white.main};
  padding: 24px;
  box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  .main-icon-wrap {
    position: absolute;
    bottom: 0;
  }
  .MuiFormLabel-asterisk {
    color: ${props => props.theme.palette.red.main};
  }
  .field {
    max-height: inherit;
  }
  .MuiAutocomplete-root {
    max-width: 400px;
  }
  .additional-options-field {
    width: 300px;
  }
  .MuiFormControl-root {
    width: 100%;
    .MuiFormLabel-root {
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  fieldset {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
  }
  .MuiInputBase-input {
    padding: 13px 9px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    line-height: 20px;
    height: 20px;
    &.Mui-disabled {
      color: inherit;
      opacity: 1;
    }
  }

  .MuiFormControlLabel-root {
    .MuiTypography-root {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      font-size: 16px;
    }
  }
`;

export const IconWrap = styled.div.attrs(props => ({
  className: "icon-wrap",
}))`
  margin-left: -5px;
  position: relative;
  padding-top: 64px;
  .icon-path {
    border-radius: 50%;
    color: ${props => props.theme.palette.white.main};
    background: ${props => props.theme.palette.grey.grey500};
    cursor: pointer;
    z-index: 1;
    position: relative;
    display: inline-flex;
  }
  svg {
    font-size: 16px;
  }
  &:after {
    content: "";
    border-left: 1.5px dashed ${props => props.theme.palette.grey.grey500};
    position: absolute;
    left: -20px;
    top: 0;
  }
`;

export const AddFieldsTree = styled.div.attrs(props => ({
  className: "add-fields-tree",
}))`
  position: relative;
  padding-bottom: 60px;
  &:after {
    content: "";
    height: calc(100% - 59px);
    position: absolute;
    border-left: 1.5px dashed ${props => props.theme.palette.grey.grey500};
    left: 2px;
    top: 52px;
  }
  .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    border-radius: 8px;
    font-size: 14px;
  }
  .form-control {
    position: relative;
    z-index: 2;
  }
`;

export const FieldCol = styled.div.attrs(props => ({
  className: "field-col",
}))`
  padding-right: 20px;
  .field {
    padding: 0;
  }
  .MuiSelect-select {
    min-height: inherit;
    padding: 15px 9px;
    min-width: 122px;
  }
`;

export const FieldsWrap = styled.div.attrs(props => ({
  className: "fields-wrap",
}))`
  position: relative;
  .icon-settings {
    margin-left: 8px;
  }
  input[type="text"] {
    width: 122px;
  }
`;
export const InnerWrap = styled.div.attrs(props => ({
  className: "inner-wrap",
}))`
  margin-left: 30px;
  .row-wrap:last-of-type {
    &:after {
      content: none;
    }
  }
`;

export const RowWrap = styled.div.attrs(props => ({
  className: "row-wrap",
}))`
  position: relative;
  padding-top: 29px;
  &:before {
    content: "";
    position: absolute;
    top: 51px;
    left: -26px;
    height: 2px;
    z-index: 1;
    border-top: 1.5px dashed ${props => props.theme.palette.grey.grey500};
    width: 25px;
  }
  &:after {
    content: "";
    height: 100%;
    position: absolute;
    border-left: 1.5px dashed ${props => props.theme.palette.grey.grey500};
    left: 2px;
    top: 74px;
  }
`;

export const Inner = styled.div.attrs(props => ({
  className: "inner",
}))`
  display: flex;
  position: relative;
  .arrow {
    cursor: pointer;
    position: absolute;
    left: -36px;
    top: 14px;
    color: ${props => props.theme.palette.white.main};
    border-radius: 50%;
    z-index: 1;
    font-size: 10px;
    background: ${props => props.theme.palette.grey.grey500};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 16px;
    }
  }
`;

export const ParentFieldsWrap = styled.div.attrs(props => ({
  className: "parent-fields-wrap",
}))`
  .MuiTextField-root {
    width: 100%;
  }
  .field {
    padding-top: 0;
  }
`;

export const ActionWrap = styled.div.attrs(props => ({
  className: "action-wrap",
}))`
  display: flex;
  margin-top: 8px;
  svg {
    cursor: pointer;
    color: ${props => props.theme.palette.grey.grey500};
    width: 15px;
    height: 15px;
    margin-top: 7px;
  }
`;
