import "suneditor/dist/css/suneditor.min.css";
import { Controller } from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import has from "lodash/has";
import React from "react";
import RTEditor from "app/components/Common/RTEditor";

const Editor = ({ name, control, validations, defaultValue, ...restProps }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={validations}
        defaultValue={defaultValue}
        render={({
          field: { value, ref, ...inputProps },
          fieldState,
          formState,
        }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, name);
          const fieldError = get(errors, name);
          return (
            <>
              <RTEditor {...restProps} {...inputProps} value={value} />
              {hasFieldError && (
                <FormHelperText error>{fieldError.message}</FormHelperText>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default Editor;
