import { Autocomplete } from "styles/global.style";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "app/components/Common/schemaForm/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import TextField from "app/components/Common/TextField";

const AutoComplete = ({
  uid,
  validations,
  display_name,
  field_metadata,
  data_type,
  index,
  onFieldDelete,
  isBorder = false,
  freeSolo = true,
  options = [],
  getOptionLabel = option => option?.title || option,
  filterSelectedOptions = true,
  open = false,
  defaultValue,
  visible = true,
  ...autoCompletePorps
}) => {
  const { control, getValues } = useFormContext();
  return (
    <Controller
      key={uid}
      name={`${uid}`}
      control={control}
      rules={{
        required: validations?.required ? `${display_name} is required` : false,
      }}
      // defaultValue={[]}
      defaultValue={getValues(`${uid}`) ?? []}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, `${field.name}`);
        const fieldError = get(errors, `${field.name}`);

        return (
          <FieldBorder FieldBorder isBorder={isBorder} isError={hasFieldError}>
            {visible && (
              <Autocomplete
                multiple
                freeSolo={freeSolo}
                // {...(options?.length === 0 && { open: false })}
                {...(open === false && { open: false })}
                options={options}
                getOptionLabel={getOptionLabel}
                value={field.value}
                filterSelectedOptions={filterSelectedOptions}
                onChange={(event, value) => {
                  if (!open && typeof event?.target?.value === "string") {
                    /*istanbul ignore else*/
                    if (event.target.value.trim().length > 0) {
                      field.onChange(value);
                    }
                  } else {
                    field.onChange(value);
                  }
                }}
                onBlur={field.onBlur}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      id={uid}
                      inputRef={field.ref}
                      InputLabelProps={{
                        required: !!validations?.required,
                      }}
                      error={hasFieldError}
                      helperText={
                        (hasFieldError && fieldError?.message) ||
                        (!open && `Press "Enter" to add data tag`)
                      }
                      type={data_type === "string" ? "text" : data_type}
                      variant="outlined"
                      label={`${display_name}`}
                      // margin="normal"
                      fullWidth
                      data-testid={`${uid}-multi-input`}
                    />
                  );
                }}
                {...autoCompletePorps}
              />
            )}
            {onFieldDelete && (
              <IconButton onClick={event => onFieldDelete(event, index)}>
                <DeleteIcon />
              </IconButton>
            )}
            {/* protected field checkbox for Integration's config form */}
            {field_metadata?.hasOwnProperty("Protected") && (
              <Checkbox
                uid={`${uid}-protected-checkbox`}
                display_name="Protected"
                defaultValue={field_metadata.isProtected}
              />
            )}
          </FieldBorder>
        );
      }}
    />
  );
};

export default AutoComplete;
