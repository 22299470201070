/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "@reduxjs/toolkit";
// import { InjectedReducersType } from "utils/types/injector-typings";
import adminSlice from "./adminSlice";
import applicationSlice from "./applicationSlice";
import constantsSlice from "./constants";
import formSlice from "./formSlice";
import loaderSlice from "./loaderSlice";
import notifierSlice from "./notifierSlice";
import sidebarSlice from "./sidebarSlice";

export const reducer = combineReducers({
  notifier: notifierSlice,
  loader: loaderSlice,
  constants: constantsSlice,
  admin: adminSlice,
  application: applicationSlice,
  sidebar: sidebarSlice,
  formData: formSlice,
});

export function createReducer() {
  return reducer;
}

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
/* export function createReducer(injectedReducers: InjectedReducersType = {}) {
    // Initially we don't have any injectedReducers, so returning identity function to avoid the error
    if (Object.keys(injectedReducers).length === 0) {
      return state => state;
    } else {
      return combineReducers({
        ...injectedReducers,
      });
    }
  } */
