import {
  Drawer,
  List,
  ListWrap,
  MenuButton,
  ScrollWrap,
  SidebarToolbar,
} from "./Sidebar.style";
import { openSidebar } from "../../../../store/reducers/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Icons from "../../../../assets/global_icons";
import IconsFormatted from "../../Common/Sidebar/Sidebar-icons/IconsFormatted";
import ListItem from "./ListItem";
import ListItemWrapper from "./ListItemWrapper";
import React from "react";

const Sidebar = ({ sidebarContent }) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(state => state.sidebar.open);

  const handledraweropen = () => {
    dispatch(openSidebar(true));
  };
  const handledrawerclose = () => {
    dispatch(openSidebar(false));
  };
  return (
    <Drawer
      variant="permanent"
      className={isSidebarOpen ? "drawerOpen" : "drawerClose"}
      classes={{
        paper: isSidebarOpen
          ? "drawerOpen sidebar-expand"
          : "drawerClose sidebar-collapse",
      }}
    >
      <SidebarToolbar>
        <Box display="flex" alignItems="center">
          <IconsFormatted name="Dashboard" />
          {isSidebarOpen ? <span className="txt">Dashboard</span> : null}
        </Box>
        <MenuButton
          onClick={handledraweropen}
          className={isSidebarOpen ? "hide menu-icon" : "menu-icon"}
        >
          <Icons name="MenuIcon" />
        </MenuButton>
        <MenuButton
          onClick={handledrawerclose}
          className={!isSidebarOpen ? "hide" : ""}
        >
          <ChevronLeftIcon />
        </MenuButton>
      </SidebarToolbar>
      <ScrollWrap>
        <ListWrap>
          <List>
            {sidebarContent &&
              sidebarContent.map(({ name, icon, child, id, url }) => {
                return (
                  <ListItemWrapper
                    handledraweropen={handledraweropen}
                    isSidebarOpen={isSidebarOpen}
                    key={id}
                    listItem={({ isOpen, handleClick, isSidebarOpen }) => (
                      <ListItem
                        url={url}
                        handledraweropen={handledraweropen}
                        isSidebarOpen={isSidebarOpen}
                        isOpen={isOpen}
                        handleClick={handleClick}
                        icon={icon}
                        text={name}
                        child={child}
                        id={id}
                      />
                    )}
                  />
                );
              })}
          </List>
        </ListWrap>
      </ScrollWrap>
    </Drawer>
  );
};

export default Sidebar;
