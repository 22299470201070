import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Player Stats",
    uid: "player_stats",
    _schema: [
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Player ID",
        uid: "pid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },

      {
        display_name: "Career Averages",
        uid: "ca",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Games Played",
            uid: "gp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Started",
            uid: "gs",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Minutes Per Game",
            uid: "min",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Field Goal Percentage",
            uid: "fgp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three Pointers Percentage",
            uid: "tpp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Free Throw Percentage",
            uid: "ftp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Offensive Rebounds",
            uid: "oreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Defensive Rebounds",
            uid: "dreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Rebounds",
            uid: "reb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Assists",
            uid: "ast",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Steals",
            uid: "stl",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Blocks",
            uid: "blk",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Turnovers",
            uid: "tov",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fouls",
            uid: "pf",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Points",
            uid: "pts",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Career Totals",
        uid: "ct",
        mandatory: false,
        multiple: false,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Games Played",
            uid: "gp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Started",
            uid: "gs",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Minutes Per Game",
            uid: "min",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Field Goals Made",
            uid: "fgm",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Field Goals Attempted",
            uid: "fga",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three pointers Made",
            uid: "tpm",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three pointers Attempted",
            uid: "tpa",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Free throws Made",
            uid: "ftm",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Free throws Attempted",
            uid: "fta",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Offensive Rebounds",
            uid: "oreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Defensive Rebounds",
            uid: "dreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Rebounds",
            uid: "reb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Assists",
            uid: "ast",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Steals",
            uid: "stl",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Blocks",
            uid: "blk",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Turnovers",
            uid: "tov",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fouls",
            uid: "pf",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Points",
            uid: "pts",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },

      {
        display_name: "Season Averages",
        uid: "sa",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Team Id",
            uid: "tid",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Points Val",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Games Played",
            uid: "gp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Started",
            uid: "gs",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Minutes Per Game",
            uid: "min",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Field Goal Percentage",
            uid: "fgp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three Points Percentage",
            uid: "tpp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Free Throw Percentage",
            uid: "ftp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Offensive Rebounds",
            uid: "oreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Defensive Rebounds",
            uid: "dreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Rebounds",
            uid: "reb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Assists",
            uid: "ast",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Steals",
            uid: "stl",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Blocks",
            uid: "blk",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Turnovers",
            uid: "tov",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fouls",
            uid: "pf",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Points",
            uid: "pts",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Season Totals",
        uid: "st",
        mandatory: false,
        multiple: true,
        data_type: "group",
        field_metadata: {},
        _schema: [
          {
            display_name: "Team Id",
            uid: "tid",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Points Val",
            uid: "val",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Games Played",
            uid: "gp",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Games Started",
            uid: "gs",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Minutes Per Game",
            uid: "min",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Field Goals Made",
            uid: "fgm",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Field Goals Attempted",
            uid: "fga",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three pointers Made",
            uid: "tpm",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three pointers Attempted",
            uid: "tpa",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Free throws Made",
            uid: "ftm",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Free throws Attempted",
            uid: "fta",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Offensive Rebounds",
            uid: "oreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Defensive Rebounds",
            uid: "dreb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Rebounds",
            uid: "reb",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Assists",
            uid: "ast",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Steals",
            uid: "stl",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Blocks",
            uid: "blk",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Turnovers",
            uid: "tov",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fouls",
            uid: "pf",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Points",
            uid: "pts",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },

      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
