import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import WebhookForm from "../WebhookForm";

const UpdateWebhooks = props => {
  const [formData, setFormData] = useState({});

  const { sport_key, env_key, uid } = props.match.params;

  const dispatch = useDispatch();
  const getURL = `/dashboard/${sport_key}/${env_key}/webhooks`;

  useEffect(() => {
    fetchWebhook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWebhook = useCallback(async () => {
    const url = `/v1/admin/webhooks/${uid}`;
    const {
      data: { webhook },
    } = await dispatch(get({ url }));
    setFormData(webhook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: {
            url: data.url,
            method: data.method.toUpperCase(),
            headers: JSON.parse(data.headers),
            channels: data.webhookgroup.channel,
          },
          url: `/v1/admin/webhooks/${uid}`,
          key: "webhook",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Update Webhook"
        backUrl={getURL}
        breadCrumbItems={[
          {
            title: "Webhooks",
          },
        ]}
      />
      {Object.keys(formData).length >= 1 && (
        <WebhookForm
          onSubmit={onSubmit}
          formData={formData}
          sport_key={sport_key}
          env_key={env_key}
          location={props.location}
        />
      )}
    </>
  );
};

export default UpdateWebhooks;
