const schema = {
  class: {
    title: "Levels",
    uid: "levels",
    _schema: [
      {
        uid: "id",
        display_name: "Level Id",
        data_type: "number",
        field_metadata: {
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "venue_id",
        display_name: "Venue Id",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "name",
        display_name: "Name",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "is_default",
        display_name: "Is Default",
        data_type: "boolean",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "ordinal",
        display_name: "Ordinal",
        data_type: "number",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
    ],
  },
};
export default schema;
