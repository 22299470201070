import { Add, Get, Update } from "app/pages/Dashboard/Webhooks";
import { Route, Switch } from "react-router-dom";
// import ErrorBoundary from "../../Components/ErrorBoundary/errorBoundary";
import React from "react";

const WebhooksRoute = props => {
  return (
    <>
      <Switch>
        <Route exact path={`${props.match.path}`} component={Get} />
        <Route exact path={`${props.match.path}/add`} component={Add} />
        <Route
          exact
          path={`${props.match.path}/update/:uid`}
          component={Update}
        />
      </Switch>
    </>
  );
};

export default WebhooksRoute;
