const schema = {
  class: {
    title: "Point POI",
    uid: "poi_point",
    _schema: [
      {
        display_name: "Name",
        uid: "name",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Category",
        uid: "category_id",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
      },
      {
        display_name: "Draw layer",
        uid: "draw_layer",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Expandable",
        uid: "expandable",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Hidden",
        uid: "hidden",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Search",
        uid: "search",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Size",
        uid: "size",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Keywords",
        uid: "keywords",
        field_metadata: { additional_options: ["Multiple"] },
        mandatory: false,
        multiple: true,
        data_type: "string",
        _schema: [],
      },
      {
        display_name: "Location",
        uid: "location",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Levels",
        uid: "level_ids",
        field_metadata: {
          select: true,
          multiple: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        data_type: "string",
        _schema: [],
      },
    ],
  },
};

export default schema;
