import styled from "styled-components";

export const LoaderWrap = styled.div`
  position: fixed;
  width: 100%;
  text-align: center;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  z-index: 1350;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100px;
  }
`;
