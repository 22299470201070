const schema = {
  class: {
    title: "Drops v2",
    uid: "dropsv2",
    _schema: [
      {
        uid: "start_date",
        display_name: "Start Date",
        data_type: "date",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          readOnly: true,
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "end_date",
        display_name: "End Date",
        data_type: "date",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          readOnly: true,
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "title",
        display_name: "Title",
        data_type: "string",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "cs_uid",
        display_name: "CS Uid",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "status",
        display_name: "Status",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          multiline: false,
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "winner_count",
        display_name: "Winner Count",
        data_type: "number",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "participants_count",
        display_name: "Participants Count",
        data_type: "number",
        field_metadata: {
          multiline: false,
        },
        multiple: false,
        _schema: [],
      },
      {
        display_name: "Show in Previous Drops",
        uid: "show_in_previous_drops",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
    ],
  },
};

export default schema;
