import { cloneDeep } from "lodash";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import schema from "../schema";
import SchemaForm from "../schemaForm";

const UpdateFeeds = props => {
  const [feedsData, setFeedsData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/wsc`;

  useEffect(() => {
    getFeedsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getFeedsData = useCallback(async () => {
    const url = `/v1/nba/admin/data/wsc_feeds/${uid}`;
    const {
      data: { wsc_feeds },
    } = await dispatch(get({ url }));
    let refined_wsc_feeds = cloneDeep(wsc_feeds);
    refined_wsc_feeds.wsc_tags = refined_wsc_feeds.wsc_tags ?? [];
    refined_wsc_feeds.rating = refined_wsc_feeds.rating ?? "";
    setFeedsData(refined_wsc_feeds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/nba/admin/data/wsc_feeds/${uid}`,
          key: "wsc_feeds",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Wsc Feeds"
        breadCrumbItems={[
          {
            title: "Update Wsc Feeds",
          },
        ]}
        backUrl={getURL}
      />
      {feedsData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={feedsData}
          schema={schema}
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateFeeds;
