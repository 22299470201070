import { acceptFileType } from "utils/constants";
import { Button, Modal } from "@dfep/ui-component";
import { bytesToSize } from "utils/functions";
import { connect, useDispatch } from "react-redux";
import { showLoader } from "store/reducers/loaderSlice";
import { showNotifier } from "store/reducers/notifierSlice";
import { TabBox } from "styles/global.style";
import { uploadObject } from "services/uploadObjects";
import AddIcon from "@material-ui/icons/Add";
import FileUpload from "app/components/Common/FileUpload";
import React, { useEffect, useRef, useState } from "react";

const AddAssets = props => {
  //Add Assets API Call
  const [addAssetModal, setAddAssetModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const { sport_key } = props.match.params;
  const fileInputRef = useRef();
  const dispatch = useDispatch();

  const openModalHandler = event => {
    setAddAssetModal(true);
  };
  const closeModalHandler = event => {
    setSelectedFiles([]);
    setAddAssetModal(false);
  };

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find(item => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);
  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };
  const handleFiles = files => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles(prevArray => [...prevArray, files[i]]);
      }
    }
  };
  const validateFile = file => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "application/pdf",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const removeFile = name => {
    const validFileIndex = validFiles.findIndex(e => e.name === name);
    validFiles.splice(validFileIndex, 1);

    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);

    setSelectedFiles([...selectedFiles]);
  };
  const fileInputClicked = () => {
    fileInputRef.current.click();
  };
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };
  const addAssetHandler = event => {
    startUpload(selectedFiles);
  };

  const startUpload = files => {
    let counter = 0;
    files.forEach(async (file, index) => {
      dispatch(showLoader({ show: true }));
      const formData = new FormData();
      formData.append("asset[upload]", file);
      const response = await props.uploadAsset({
        url: `v1/admin/applications/${sport_key}/assets`,
        formData,
        showToast: false,
        loader: false,
      });
      counter++;
      if (response && counter === files.length) {
        dispatch(
          showNotifier({
            open: true,
            message: response.data.notice,
            variant: "success",
          }),
        );
        setAddAssetModal(false);
        setSelectedFiles([]);
        dispatch(showLoader({ show: false }));
        props.getAssetData();
      }
    });
  };

  return (
    <TabBox>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={openModalHandler}
        data-testid="add-asset"
      >
        Add Assets
      </Button>
      <Modal
        data-testid="add-asset-modal"
        className="small"
        open={addAssetModal}
        handleClose={closeModalHandler}
        title="Upload Assets"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: closeModalHandler },
          {
            id: "2",
            name: "Add File",
            handler: addAssetHandler,
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        <FileUpload
          acceptFileType={acceptFileType}
          dragOver={dragOver}
          dragEnter={dragEnter}
          dragLeave={dragLeave}
          fileDrop={fileDrop}
          selectedFiles={selectedFiles}
          fileSize={bytesToSize}
          validFiles={validFiles}
          removeFile={removeFile}
          fileInputClicked={fileInputClicked}
          fileInputRef={fileInputRef}
          filesSelected={filesSelected}
        />
      </Modal>
    </TabBox>
  );
};

/*istanbul ignore next*/
const mapDispatchToProps = dispatch => {
  return {
    uploadAsset: payload => dispatch(uploadObject(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(AddAssets);
export { Container, AddAssets as Component };
