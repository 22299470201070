import { Button, Modal } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { getWebhooks } from "services/getObjects";
import { Link } from "react-router-dom";
import { NoData } from "styles/global.style";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const WebHooks = ({ match, history, location }) => {
  const { access_type } = useSelector(state => state.application);

  //States
  const [webhooks, setWebhooks] = useState([]);
  const [webhookObj, setWebhookObj] = useState(null);
  const [open, setOpen] = useState(false);
  const [dataId, setUid] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [webhookCount, setWebhookCount] = useState(0);
  // Params
  const { sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { page, skip, limit, sort, value, searchText } = useQueryParams();

  // functions
  // Handle Modal Close (Delete Icon)
  const handleModalClose = event => {
    setOpen(false);
  };

  // Handle Delete Webhook function
  const handleDelete = async (event, uid) => {
    event.stopPropagation();
    await dispatch(
      deleteRecord({
        url: `/v1/admin/webhooks/${uid}`,
      }),
    );
    handleModalClose(event);
    getWebhook();
  };

  // Get Webhooks API Call
  const getWebhook = async () => {
    setLoading(true);

    const {
      data: { webhooks, count },
    } = await dispatch(
      getWebhooks({
        sport_key,
        env_key,
        skip,
        limit,
        sort,
        sortValue: value,
        searchText,
      }),
    );

    if (count !== 0 && webhooks.length === 0) {
      initialRoute(history);
    } else {
      setWebhooks([...webhooks]);
      setWebhookCount(count);
      setLoading(false);
    }
  };

  // Component Life Cycle
  useEffect(() => {
    getWebhook();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, skip, limit, sort, value, searchText]);

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="edit_btn" />,
      handler: (event, uid) => {
        push({
          pathname: `/dashboard/${sport_key}/${env_key}/webhooks/update/${uid}`,
          state: { from: location },
        });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete_btn" />,
      handler: (event, uid, dataItem) => {
        setWebhookObj(dataItem);
        setUid(uid);
        setOpen(true);
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      <PanelHeader
        title="Webhooks"
        refreshHandler={getWebhook}
        data-testid="panel-header"
        breadCrumbItems={[
          {
            title: "Webhooks",
          },
        ]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            data-testid="add-webhook"
            color="primary"
            variant="contained"
            component={Link}
            to={{
              pathname: `/dashboard/${sport_key}/${env_key}/webhooks/add`,
              state: { from: location },
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {isLoading && (
        <SkeletonTable
          headings={[
            { label: "URL", id: "url" },
            { label: "Method", id: "method" },
            { label: "Created At", id: "created_at", type: "date" },
            { label: "Updated At", id: "updated_at", type: "date" },
          ]}
          name="Webhooks"
          actions={[
            {
              id: "edit",
              label: "Edit",
              icon: <EditIcon fontSize="small" data-testid="edit-btn" />,
              handler: () => {},
            },
            {
              id: "delete",
              label: "Delete",
              icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
              handler: () => {},
            },
          ]}
        />
      )}
      {webhooks.length >= 1 && isLoading === false ? (
        <Table
          data-testid="table"
          name="Webhooks"
          headings={[
            { label: "URL", id: "url" },
            { label: "Method", id: "method" },
            { label: "Created At", id: "created_at", type: "date" },
            { label: "Updated At", id: "updated_at", type: "date" },
          ]}
          actions={actions}
          data={webhooks}
          isSorting
          limit={10}
          totalCount={webhookCount}
        />
      ) : (
        !isLoading && (
          <NoData data-testid="empty-data">No Webhooks Found</NoData>
        )
      )}
      <Modal
        className="small"
        data-testid="delete-modal"
        open={open}
        handleClose={handleModalClose}
        title="Delete Webhook"
        maxwidth="sm"
        actions={[
          { id: "1", name: "No", handler: handleModalClose },
          {
            id: "2",
            name: "Yes",
            handler: event => {
              return handleDelete(event, dataId);
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "yes_btn",
            },
          },
        ]}
      >
        {webhookObj &&
          deleteConfirmationMessage({
            name: webhookObj?.url,
            module: "Webhooks",
          })}
      </Modal>
    </>
  );
};

export default WebHooks;
