const schema = {
  class: {
    title: "Refund",
    uid: "refund",
    _schema: [
      {
        uid: "transaction_id",
        display_name: "Transaction Id",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "order_id",
        display_name: "Order Id",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "user_id",
        display_name: "User Id",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        display_name: "Request Refund Date",
        uid: "request_refund_date",
        mandatory: false,
        multiple: false,
        data_type: "date",
        field_metadata: {},
      },
      {
        display_name: "Venue Id",
        uid: "venue_id",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        uid: "store_id",
        display_name: "Store Id",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "terminal_id",
        display_name: "Terminal Id",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "order_request_id",
        display_name: "Order Request Id",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "merchant_reference_code",
        display_name: "Merchant Reference Code",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "invoice_number",
        display_name: "Invoice Number",
        data_type: "string",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "charge_amount",
        display_name: "Charge Amount",
        data_type: "number",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },

      {
        uid: "status",
        display_name: "Status",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "payment_gateway",
        display_name: "Payment Gateway",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "provider",
        display_name: "Provider",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
    ],
  },
};
export default schema;
