import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { connect, useDispatch } from "react-redux";
import { Constants } from "utils/constants";
import { Container as GetFeeds } from "app/pages/Dashboard/Data/News/Feeds/get/feeds";
import { getNewsFeeds } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { Link, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { showNotifier } from "store/reducers/notifierSlice";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useEffect, useState } from "react";
import Settings from "./settings";

const FeedsIndex = props => {
  const [tabValue, setTabValue] = useState(0);
  const [feedsData, setFeedsData] = useState([]);
  const [feedsCount, setFeedsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { sort, sortValue, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const { sport_key, env_key } = props.match.params;
  const { access_type } = useSelector(state => state.application);
  const { READ_ONLY } = Constants;
  const dispatch = useDispatch();

  const getFeedsData = async () => {
    setIsLoading(true);
    setFeedsData([]);
    const {
      data: { count, feeds },
    } = await props.getNewsFeeds({
      skip,
      limit,
      sort,
      sortValue,
      searchText,
    });

    if (count !== 0 && feeds.length === 0) {
      initialRoute(props.history);
    } else {
      setFeedsData(feeds);
      setFeedsCount(count);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    /* istanbul ignore else */

    if (
      props.history.location.pathname ===
      `/dashboard/${sport_key}/${env_key}/data/news/feeds/settings`
    ) {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, [props.history.location.pathname, sport_key, env_key]);

  useEffect(() => {
    getFeedsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, sortValue, limit, skip, searchText]);

  const syncFeeds = () => {
    const syncArticles = requestAPI({
      url: "/v1/nba/admin/sync/articles",
      showToast: false,
      returnError: true,
    });
    const syncGallery = requestAPI({
      url: "/v1/nba/admin/sync/gallery",
      showToast: false,
      returnError: true,
    });
    const syncVideos = requestAPI({
      url: "/v1/nba/admin/sync/videos",
      showToast: false,
      returnError: true,
    });
    Promise.all([
      dispatch(syncArticles),
      dispatch(syncGallery),
      dispatch(syncVideos),
    ])
      .then(() =>
        dispatch(
          showNotifier({
            open: true,
            message: "Sync feeds started",
            variant: "success",
          }),
        ),
      )
      .catch(() =>
        dispatch(
          showNotifier({
            open: true,
            message: "Sync feeds failed",
            variant: "error",
            errors: {},
          }),
        ),
      );
    closeModalHandler();
  };

  return (
    <>
      <PanelHeader
        title="News Feed"
        breadCrumbItems={[
          {
            title: "News Feed",
          },
        ]}
        refreshHandler={getFeedsData}
        search={true}
        {...(access_type !== READ_ONLY && {
          sync: () => openModalHandler("show"),
        })}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="add-btn"
            startIcon={<AddIcon />}
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/news/feeds/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>

      {!searchText && (
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          <Tab
            label="News Feed"
            component={Link}
            to={`${props.match.url}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <Switch>
        <>
          <TabPanel>
            {/*istanbul ignore next*/}
            <Route
              path={`${props.match.path}`}
              render={routeProps => {
                /*istanbul ignore next*/
                return (
                  <GetFeeds
                    {...routeProps}
                    getFeedsData={getFeedsData}
                    feedsData={feedsData}
                    isLoading={isLoading}
                    feedsCount={feedsCount}
                    access_type={access_type}
                    openSyncModal={show}
                    syncFeeds={syncFeeds}
                    closeSyncModal={closeModalHandler}
                  />
                );
              }}
              exact
            />
            <Route
              path={`${props.match.path}/settings`}
              component={Settings}
              exact
            />
          </TabPanel>
        </>
      </Switch>
    </>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getNewsFeeds: payload => dispatch(getNewsFeeds(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(FeedsIndex);

export { Container, FeedsIndex as Component };
