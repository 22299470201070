import { createSlice } from "@reduxjs/toolkit";

export const formSlice = createSlice({
  name: "schemaForm",
  initialState: {},
  reducers: {
    formData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { formData } = formSlice.actions;

export default formSlice.reducer;
