/* istanbul ignore file */
import { Route, Switch } from "react-router-dom";
import AddStartingLineup from "./add";
import GetStartingLineup from "app/pages/Dashboard/Data/Game/StartingLineup/get";
import React from "react";
import UpdateStartingLineup from "./update";

const StartingLineup = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/add`}
        component={AddStartingLineup}
        exact
      />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateStartingLineup}
        exact
      />
      <Route path={props.match.path} component={GetStartingLineup} />
    </Switch>
  );
};

export default StartingLineup;
