import MuiDrawer from "@material-ui/core/Drawer";
import MuiIconButton from "@material-ui/core/IconButton";
import styled from "styled-components";

export const Drawer = styled(MuiDrawer)`
  &.MuiDrawer-root {
    width: ${props => props.theme.sidebar.drawerwidth};
    flex-shrink: 0;
    white-space: nowrap;
    &.drawerOpen,
    .drawerOpen {
      width: ${props => props.theme.sidebar.drawerwidth};
      transition: ${props =>
        props.theme.transitions.create("width", {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen,
        })};
      .MuiIconButton-root {
        display: none;
      }
    }
    &.drawerClose,
    .drawerClose {
      width: ${props => props.theme.sidebar.drawerwidthsmall};
      transition: ${props =>
        props.theme.transitions.create("width", {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.leavingScreen,
        })};
      overflow-x: hidden;
    }
    .MuiDrawer-paper {
      padding-top: 64px;
      overflow: visible;
      color: ${props => props.theme.sidebar.color};
      background: ${props => props.theme.sidebar.background};
      &:hover,
      &:focus {
        &.drawerOpen,
        .drawerOpen {
          .MuiIconButton-root {
            display: block;
            padding: 0;
          }
        }
      }
    }
    .hide {
      display: none;
    }
    .menu-icon .MuiIconButton-label {
      width: 15px;
      height: 15px;
    }
    .sidebar-expand {
      li {
        .menu-name,
        .MuiSvgIcon-root {
          display: block;
        }
      }
    }
    .sidebar-collapse li {
      .menu-name,
      .MuiSvgIcon-root {
        display: none;
      }
    }
    li {
      list-style: none;
    }
    ul {
      padding-left: 0;
    }
    .menu-name,
    .txt {
      margin-left: 21px;
    }
    .sub-menu {
      padding-left: 65px;
    }
    .sub-menu-item {
      padding: 8px 23px 8px 42px;
    }
    .menu-item-data {
      display: flex;
      align-items: center;
    }
    .dashboard-icon {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

export const ScrollWrap = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const ListWrap = styled.div`
  overflow-y: auto;
  margin-right: -17px;
  height: 100%;
  position: relative;
  overflow-x: hidden;
`;

export const List = styled.ul`
  padding-bottom: 90px;
  padding-left: 0;
  margin: 0;
  > li {
    max-width: 260px;
  }
  .menu-item {
    cursor: pointer;
    color: ${props => props.theme.sidebar.color};
    text-decoration: none;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    justify-content: space-between;
    padding: 10px 23px 10px 23px;
    &:hover {
      background: ${props => props.theme.sidebar.menuItem.hoverColor.color};
    }
  }
`;

export const MenuButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: -14px;
    background: ${props => props.theme.sidebar.toolbar.menuButton.background};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    padding: 0;
    top: 10px;
    &:hover {
      background: ${props =>
        props.theme.sidebar.toolbar.menuButton.hoverColor.color};
    }
  }
`;

export const SidebarToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 23px 13px 23px;
  position: relative;
  margin-bottom: 24px;
  .txt {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`;
