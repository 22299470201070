import { Container as GetCore } from "app/pages/Dashboard/Data/Device/Onboardings/Core/get";
import { Route, Switch } from "react-router-dom";
import AddCore from "app/pages/Dashboard/Data/Device/Onboardings/Core/add";
import React from "react";
import UpdateCore from "app/pages/Dashboard/Data/Device/Onboardings/Core/update";

const Core = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddCore} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateCore}
        exact
      />
      <Route path={`${props.match.path}`} component={GetCore} />
    </Switch>
  );
};

export default Core;
