import {
  AccordionDetails,
  AccordionSummary,
} from "app/components/Common/Accordian";
import { AccordionWrapper, Chip } from "./dataSync.style";
import { Badge } from "../../../../../styles/global.style";
import { Button } from "@dfep/ui-component";
import { FormProvider, useForm } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { RHFSwitchField } from "./syncDataForm/SwitchField";
import { scheduler_data } from "utils/constants";
import Autocomplete from "app/components/Common/schemaForm/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import differenceBy from "lodash/differenceBy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Label from "app/components/Common/Label";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

scheduler_data.sort((a, b) => {
  if (a.method < b.method) {
    return -1;
  } else {
    return 1;
  }
});

function ScheduleTypeView({
  label,
  schedule_type,
  data: syncData,
  createSchedule,
  access_type,
  READ_ONLY,
}) {
  const methods = useForm({
    defaultValues: {
      enable: syncData ? syncData?.enable : false,
      messages: [],
      schedule_type: schedule_type,
      service: "NBA_DJ_SERVICE",
    },
  });

  const [schecduleOpt, SetSchecduleOpt] = useState(
    differenceBy(scheduler_data, syncData?.messages, "method"),
  );

  const [selected, setSelected] = useState([]);

  const {
    setValue,
    formState: { dirtyFields },
    reset,
  } = methods;

  const onSubmit = async data => {
    const previousMessages = syncData?.messages ?? [];
    data["messages"] = [...data?.messages, ...previousMessages];
    data["messages"] = differenceBy(data["messages"], selected, "method");

    data["messages"].sort((a, b) => {
      if (a.method < b.method) {
        return -1;
      } else {
        return 1;
      }
    });

    const status = await createSchedule(data);

    /* istanbul ignore else*/
    if (status === 200) {
      reset({
        enable: data.enable,
        messages: [],
        schedule_type: schedule_type,
        service: "NBA_DJ_SERVICE",
      });
      SetSchecduleOpt(differenceBy(scheduler_data, data["messages"], "method"));
      setSelected([]);
    }

    if (data["messages"].length === 0) {
      setValue("enable", false, { shouldDirty: false });
    }
  };

  const handleChipDelete = async deleteChip => {
    if (!selected.includes(deleteChip)) {
      const newSelected = selected;
      setSelected([...newSelected, deleteChip]);
    } else {
      selected.splice(selected.indexOf(deleteChip), 1);
      setSelected([...selected]);
    }
  };

  return (
    <FormProvider {...methods}>
      <AccordionWrapper>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon data-testid={`${schedule_type}-accordian`} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="d-flex">
            <Label>{`${label}`}</Label>
            <Badge>{`${
              syncData?.messages ? syncData.messages.length : 0
            }`}</Badge>
          </div>
          <RHFSwitchField
            color="primary"
            name="enable"
            inputProps={{
              "aria-label": "primary checkbox",
              "data-testid": `${schedule_type}_switch`,
            }}
            handleChange={e => {
              /* istanbul ignore else*/
              if (syncData?.messages && syncData?.messages.length > 0) {
                createSchedule({
                  enable: e.target.checked,
                  messages: syncData?.messages ?? [],
                  schedule_type: schedule_type,
                  service: "NBA_DJ_SERVICE",
                });
              } else {
                setValue("enable", false);
              }
            }}
            disabled={access_type === READ_ONLY}
            className={
              access_type === READ_ONLY && !!syncData?.enable ? "read-only" : ""
            }
          />
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Typography variant="caption">
              Select the items you wish to add/remove from the queue of the
              given schedule and click Save.
            </Typography>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {syncData?.messages?.map(data => {
                return (
                  <Chip
                    key={`${data.method}-${syncData.schedule_type}`}
                    data-testid={`${data.method}_chip`}
                    size="small"
                    label={data.method}
                    className={
                      selected.includes(data) ? "delete-badge" : "default-badge"
                    }
                    onDelete={() => handleChipDelete(data)}
                    deleteIcon={
                      <CloseIcon data-testid={`${data.method}_close`} />
                    }
                  />
                );
              })}
            </Grid>

            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="form-wrap"
            >
              <div className="autocomplete-wrap">
                <Autocomplete
                  multiple
                  freeSolo={false}
                  display_name={label}
                  getOptionLabel={option => option?.method}
                  uid="messages"
                  options={schecduleOpt}
                  open={true}
                  disableCloseOnSelect
                  disableClearable
                  data_type="text"
                  filterSelectedOptions={false}
                  renderOption={(option, { selected }) => {
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.method}
                      </React.Fragment>
                    );
                  }}
                  getOptionSelected={(option, value) =>
                    option?.method === value?.method
                  }
                />
              </div>

              <Button
                disabled={
                  (selected.length === 0 &&
                    Object.keys(dirtyFields).length === 0) ||
                  access_type === READ_ONLY
                }
                variant="contained"
                color="primary"
                type="submit"
                data-testid={`${schedule_type}_save`}
              >
                Save
              </Button>
            </form>
          </>
        </AccordionDetails>
      </AccordionWrapper>
    </FormProvider>
  );
}

export default ScheduleTypeView;
