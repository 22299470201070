import { Redirect, Route, Switch } from "react-router-dom";
import Arena from "app/pages/Dashboard/Data/Arena";
import Device from "app/pages/Dashboard/Data/Device";
import Game from "app/pages/Dashboard/Data/Game";
import LightTheBeam from "app/pages/Dashboard/Data/LightTheBeam";
import News from "app/pages/Dashboard/Data/News";
import React from "react";
import Roster from "app/pages/Dashboard/Data/Roster";
import Team from "app/pages/Dashboard/Data/Team";
import Wsc from "app/pages/Dashboard/Data/Wsc";

const Data = props => {
  /*istanbul ignore next*/
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/device`}
        exact
      />
      <Route path={`${props.match.path}/device`} component={Device} />
      <Route path={`${props.match.path}/news`} component={News} />
      <Route path={`${props.match.path}/wsc`} component={Wsc} />
      <Route path={`${props.match.path}/team`} component={Team} />
      <Route path={`${props.match.path}/rosters`} component={Roster} />
      <Route path={`${props.match.path}/game`} component={Game} />
      <Route path={`${props.match.path}/arena`} component={Arena} />
      <Route
        path={`${props.match.path}/light-the-beam`}
        component={LightTheBeam}
      />
    </Switch>
  );
};

export default Data;
