const schema = {
  class: {
    title: "Eula",
    uid: "eula",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Content",
        uid: "content",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "buttons",
        uid: "buttons",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Title",
            uid: "title",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Action",
            uid: "action",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Position",
            uid: "position",
            mandatory: true,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Custom Fields",
            uid: "custom_fields",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
        ],
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};
export default schema;
