import { Redirect, Route, Switch } from "react-router-dom";
import History from "app/pages/Dashboard/Orders/History";
import Refund from "app/pages/Dashboard/Orders/Refund";

const Orders = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/history`}
        exact
      />
      {/* order routes */}
      <Route path={`${props.match.path}/history`} component={History} />
      <Route path={`${props.match.path}/refund`} component={Refund} />
    </Switch>
  );
};

export default Orders;
