import { FieldWrap } from "styles/global.style";
import MuiTextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React from "react";

const TextField = props => {
  return (
    <FieldWrap
      className={props.className ? props.className + " field" : "field"}
    >
      <MuiTextField
        inputProps={{ "data-testid": `${props.id}-input` }}
        FormHelperTextProps={{ "data-testid": `${props.id}-helper-text` }}
        variant="outlined"
        {...props}
      />
    </FieldWrap>
  );
};

TextField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export default TextField;
