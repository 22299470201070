const schema = {
  class: {
    title: "Drops",
    uid: "drops",
    _schema: [
      {
        uid: "start_date",
        display_name: "Start Date",
        data_type: "date",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
          readOnly: true,
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "end_date",
        display_name: "End Date",
        data_type: "date",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
          readOnly: true,
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "title",
        display_name: "Title",
        data_type: "string",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "cs_uid",
        display_name: "CS Uid",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "status",
        display_name: "Status",
        data_type: "string",
        field_metadata: {
          additional_options: [],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "winner_count",
        display_name: "Winner Count",
        data_type: "number",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "extended_chat_period",
        display_name: "Extended Chat Period",
        data_type: "number",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
    ],
  },
};

export default schema;
