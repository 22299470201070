import { Route, Switch } from "react-router-dom";
import DevicesData from "app/pages/Dashboard/Reports/devicesData";
import React from "react";

const Reports = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/devices`} component={DevicesData} />
    </Switch>
  );
};

export default Reports;
