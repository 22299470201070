import { showNotifier } from "store/reducers/notifierSlice";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/ErrorOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PropTypes from "prop-types";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  default: InfoIcon,
};

const styles1 = theme => ({
  default: {
    backgroundColor: "#333333",
  },
  success: {
    backgroundColor: "#4caf50",
  },
  error: {
    backgroundColor: "#f44336",
  },
  info: {
    backgroundColor: "#2196f3",
  },
  warning: {
    backgroundColor: "#ff9800",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    // opacity: 0.9,
    marginRight: "10px",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          data-testid="snackbar-close-icon"
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info", "default"])
    .isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = theme => ({
  close: {
    padding: theme.spacing / 2,
  },
});

const Notifier = props => {
  const { classes } = props;

  const dispatch = useDispatch();
  const { open, message, variant, errors } = useSelector(
    state => state.notifier,
  );

  // Looping through errors
  const errorMessage = [];
  /*istanbul ignore else*/
  if (errors && typeof errors === "object") {
    for (const key in errors) {
      errorMessage.push(`${key} ${errors[key][0]}`);
    }
  }

  /* useEffect(() => {
    return () => handleSnackbarClose();
  }); */

  const handleSnackbarClose = () => {
    dispatch(
      showNotifier({
        open: false,
      }),
    );
  };

  const snackbarVariant = variant.toString();
  const snackbarMessage =
    variant === "error" ? (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{message}</span>
        {variant === "error" &&
          React.Children.toArray(
            errorMessage.map(data => (
              <span /* style={{ fontStyle: "italic" }} */>{data}</span>
            )),
          )}
      </div>
    ) : (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      open={open}
      snackbarcontentprops={{
        "aria-describedby": "snackbar-message-id",
      }}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          className={classes.close}
          onClick={handleSnackbarClose}
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    >
      <MySnackbarContentWrapper
        onClose={handleSnackbarClose}
        variant={snackbarVariant}
        message={snackbarMessage}
      />
    </Snackbar>
  );
};

Notifier.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifier);
