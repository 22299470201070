import { useSelector } from "react-redux";

// import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Menu from "app/components/Common/Menu/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";

const EnvironmentOptions = ({
  sport_key,
  env_key,
  // handleEdit,
  history,
  handleEnvDelete,
  env_name,
}) => {
  const { isOwner } = useSelector(state => state.application);

  const handleAdv = event => {
    event.preventDefault();
    history.push(`/dashboard/${sport_key}/${env_key}/settings/features`);
  };

  if (isOwner === true) {
    return (
      <Menu
        id="env-menu"
        icon={<MoreVertIcon fontSize="small" />}
        menuItems={[
          // {
          //   name: "Edit",
          //   icon: <CreateIcon fontSize="small" />,
          //   handler: handleEdit,
          //   showItem: () => true,
          // },
          {
            name: "Advanced Settings",
            icon: <SettingsIcon fontSize="small" />,
            handler: handleAdv,
            showItem: () => true,
          },
          {
            name: "Delete",
            icon: <DeleteIcon fontSize="small" />,
            handler: () => {
              handleEnvDelete({ env_key, name: env_name });
            },
            showItem: () => isOwner && env_key !== "dev",
          },
        ]}
      />
    );
  }
  return (
    <IconButton onClick={handleAdv}>
      <SettingsIcon data-testid="settings-icon" fontSize="small" />
    </IconButton>
  );
};

export default EnvironmentOptions;
