import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Players",
    uid: "players",
    _schema: [
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Conference",
        uid: "co",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Divison",
        uid: "di",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Conference Seed",
        uid: "see",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Clinched Playoff Berth",
        uid: "cli",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Clinched Playoff Berth In Division",
        uid: "clid",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Clinched Playoff Berth In Conference",
        uid: "clic",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Eliminated",
        uid: "elim",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Streak",
        uid: "str",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Last 10",
        uid: "l10",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Division Rank",
        uid: "dr",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Conference Record",
        uid: "cr",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Losses",
        uid: "l",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Wins",
        uid: "w",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Home Record",
        uid: "hr",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Away record",
        uid: "ar",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Games Back",
        uid: "gb",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Games Back in Division",
        uid: "gbd",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Games Back in League",
        uid: "gbl",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Win Percentage (Deprecated)",
        uid: "pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Win Percentage in Division",
        uid: "dr_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Win Percentage in Conference",
        uid: "cr_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },

      {
        display_name: "Win Percentage",
        uid: "win_pct",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        uid: "ranking",
        display_name: "Ranking",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Crnk",
            uid: "crnk",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Drnk",
            uid: "drnk",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "streaks",
        display_name: "Streaks",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Home Streak",
            uid: "home_streak",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Road Streak",
            uid: "road_streak",
            mandatory: false,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Win Streak",
            uid: "win_streak",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Loss Streak",
            uid: "loss_streak",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "halftime_record",
        display_name: "Halftime Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Ahead at half wins",
            uid: "ahead_at_half_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Ahead at half losses",
            uid: "ahead_at_half_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Behind at half wins",
            uid: "behind_at_half_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Behind at half losses",
            uid: "behind_at_half_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Tied at half wins",
            uid: "tied_at_half_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Tied at half losses",
            uid: "tied_at_half_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "third_qtr_record",
        display_name: "3rd Qtr Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Ahead at third wins",
            uid: "ahead_at_third_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Ahead at third losses",
            uid: "ahead_at_third_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Behind at third wins",
            uid: "behind_at_third_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Behind at third losses",
            uid: "behind_at_third_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Tied at third wins",
            uid: "tied_at_third_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Tied at third losses",
            uid: "tied_at_third_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "overtime_record",
        display_name: "Overtime Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Overtime wins",
            uid: "ot_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Overtime losses",
            uid: "ot_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "score_gap_record",
        display_name: "Score Gap Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Three points or less wins",
            uid: "three_pts_or_less_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Three points or less losses",
            uid: "three_pts_or_less_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Ten points or more wins",
            uid: "ten_pts_or_more_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Ten points or more losses",
            uid: "ten_pts_or_more_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "hundred_plus_point_record",
        display_name: "100+ point Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Score 100 plus wins",
            uid: "score_100_plus_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Score 100 plus losses",
            uid: "score_100_plus_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opp score 100 plus wins",
            uid: "opp_score_100_plus_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opp score 100 plus losses",
            uid: "opp_score_100_plus_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "five_hundred_percent_opponent_record",
        display_name: "500% opponent Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Opp over 500 wins",
            uid: "opp_over_500_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Opp over 500 losses",
            uid: "opp_over_500_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        uid: "statistical_record",
        display_name: "Statistical Record",
        data_type: "group",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [
          {
            display_name: "Lead in field goal percentage wins",
            uid: "lead_in_fgpct_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Lead in field goal percentage losses",
            uid: "lead_in_fgpct_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Lead in rebound wins",
            uid: "lead_in_reb_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Lead in rebound losses",
            uid: "lead_in_reb_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fewer turnover wins",
            uid: "fewer_turnovers_wins",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
          {
            display_name: "Fewer turnover losses",
            uid: "fewer_turnovers_losses",
            mandatory: false,
            multiple: false,
            data_type: "number",
            field_metadata: {},
          },
        ],
      },
      {
        display_name: "Team abbreviation",
        uid: "ta",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team name",
        uid: "tn",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team city",
        uid: "tc",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
