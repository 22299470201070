import MuiPaper from "@material-ui/core/Paper";
import styled from "styled-components";

export const UserAccount = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding-top: 10px;
  margin-top: -32px;
  .MuiTextField-root {
    width: 100%;
  }
  .field-wrap {
    display: flex;
    margin: 14px 0 0;
  }
  button {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AuthForm = styled.div`
  .MuiFormLabel-asterisk {
    color: ${props => props.theme.palette.red.main};
  }
`;

export const Paper = styled(MuiPaper)`
  padding: 24px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  .MuiTypography-h6 {
    margin-bottom: 17px;
  }
  &.MuiPaper-root {
    background: ${props => props.theme.palette.white.main};
    box-shadow: 0 0 2px ${props => props.theme.palette.grey.greyDark};
    border-radius: 3px;
  }
  .grid-wrap {
    .grid-col {
      padding-bottom: 10px;
      &:nth-child(odd) {
        padding-left: 0;
        padding-right: 12px;
      }
      &:nth-child(even) {
        padding-left: 12px;
        padding-right: 0;
      }
      ${({ theme }) => theme.breakpoints.down("sm")} {
        &:nth-child(odd),
        &:nth-child(even) {
          padding: 0;
        }
      }
    }
  }
  .MuiInputLabel-formControl {
    margin-bottom: 0;
    text-transform: capitalize;
  }
`;

export const PasswordWrap = styled.div`
  position: relative;
  .correct {
    color: green;
  }
  .correct::before {
    position: relative;
    left: -10px;
    content: "✔";
  }
  .incorrect {
    color: red;
  }
  .incorrect::before {
    position: relative;
    left: -10px;
    content: "✖";
  }
  .password-requirement {
    position: absolute;
    z-index: 2;
    background: ${props => props.theme.palette.white.main};
    border-radius: 3px;
    padding: 15px;
    box-shadow: 0 0 5px ${props => props.theme.palette.grey.greyDark};
    min-width: 230px;
    top: 77px;
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: ${props => props.theme.palette.white.main};
      border-width: 12px;
      margin-left: -12px;
    }
    &:before {
      border-color: rgba(204, 204, 204, 0);
      border-bottom-color: ${props => props.theme.palette.grey.greyCCC};
      border-width: 13px;
      margin-left: -13px;
    }
  }
  ul {
    padding-left: 15px;
    margin: 10px 0 0;
    li {
      list-style: none;
      line-height: 24px;
    }
    span {
      font-size: 14px;
    }
  }
`;
