import { Button, Modal } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { createRecord } from "services/createObject";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { get } from "services/getObject";
import { getSchemaClasses } from "services/getObjects";
import { Link } from "react-router-dom";
import { NoData } from "styles/global.style";
import { requestAPI } from "services/helper";
import { showNotifier } from "store/reducers/notifierSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import cloneDeep from "lodash/cloneDeep";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExportIcon from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import ImportIcon from "@material-ui/icons/CloudUploadOutlined";
import Label from "app/components/Common/Label";
import PanelHeader from "app/components/Common/PanelHeader";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";
import Tooltip from "@material-ui/core/Tooltip";

const intialModalState = {
  del: false, // Delete Modal
  warning: false, // Override Schema warning modal
};

export default function SchemaClasses({ match, history, location }) {
  const { limit, sort, value: sortValue, skip, searchText } = useQueryParams();

  const [classesData, setClassesData] = useState([]); // Set All Array of Data
  const [classesCount, setClassesCount] = useState(0); // Count of Data
  const [classId, setClassId] = useState(); // set Single id for delete and update
  const [isLoading, setIsLoading] = useState(true);
  const [importSchema, setImportSchema] = useState({}); //  set schema which is imported from local machine
  const [isModalOpen, setIsModalOpen] = useState(intialModalState);
  const { access_type } = useSelector(state => state.application);

  const dispatch = useDispatch();

  const { sport_key, env_key } = match.params;
  const { push } = history;

  const getClasses = useCallback(async () => {
    setIsLoading(true);
    setClassId();
    const projection = {
      uid: 1,
      title: 1,
      schema_type: 1,
      created_at: 1,
      updated_at: 1,
    };
    const {
      data: { classes, count },
    } = await dispatch(
      getSchemaClasses({
        limit,
        sort,
        sortValue,
        skip,
        query: { schema_type: "collection" },
        projection,
        searchText,
      }),
    );

    if (count !== 0 && classes.length === 0) {
      initialRoute(history);
    } else {
      setClassesData(classes);
      setClassesCount(count);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, sortValue, skip, searchText]);

  const deleteModalHandler = async () => {
    // delete single schema
    const url = `v1/custom_schema/admin/classes/${classId.uid}`;
    await dispatch(deleteRecord({ url }));

    setIsModalOpen(intialModalState);
    setClassId("");
    getClasses();
  };

  const exportSchema = async (event, uid) => {
    // get schema locally
    const { data } = await dispatch(
      get({ url: `v1/custom_schema/admin/classes/${uid}/export` }),
    );
    const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${uid}.json`);
    document.body.appendChild(link);
    link.click();
  };

  /*istanbul ignore next*/

  const importSchemaReq = async schema => {
    // import Api Call

    await dispatch(
      createRecord({
        url: `/v1/custom_schema/admin/classes/import`,
        key: "class",
        form: schema,
      }),
    );

    getClasses();
  };
  /*istanbul ignore next*/
  const getUniqueFile = async schema => {
    // Checking the data exist or not
    try {
      await dispatch(
        requestAPI({
          url: `v1/custom_schema/admin/classes/${schema.uid}`,
          method: "Get",
          showToast: false,
          returnError: true,
        }),
      );
      setClassId(schema.uid);
      setIsModalOpen({ ...isModalOpen, warning: true });
    } catch (err) {
      /*istanbul ignore next*/

      importSchemaReq(schema);
    }
  };

  const linkTitle = ({ title, uid }) => {
    // formater --> add link tag to each title
    return <Link to={`${match.url}/${uid}/objects`}>{title}</Link>;
  };

  const handleModalClose = () => {
    setIsModalOpen(intialModalState);
  };

  const retrieveFiles = files => {
    //Convert and validate the Json data to js
    Array.from(files).forEach(file => {
      file.progress = 0;
      /*istanbul ignore next*/
      if (file.type === "application/json") {
        var reader = new FileReader();
        reader.readAsText(file);

        reader.onloadend = () => {
          try {
            /*istanbul ignore next*/

            const classSchema = cloneDeep(
              JSON.parse(JSON.parse(JSON.stringify(reader.result))),
            );

            if (
              classSchema.hasOwnProperty("schema_type") &&
              classSchema.hasOwnProperty("title") &&
              classSchema.hasOwnProperty("uid")
            ) {
              if (classSchema["schema_type"] !== "collection") {
                dispatch(
                  showNotifier({
                    open: true,
                    message: "Schema type should be 'collection'",
                    errors: {},
                    variant: "error",
                  }),
                );
              } else {
                setImportSchema(classSchema);
                getUniqueFile(classSchema);
              }
            } else {
              dispatch(
                showNotifier({
                  open: true,
                  message: "Provided Json is not valid to import",
                  errors: {},
                  variant: "error",
                }),
              );
            }
          } catch (error) {
            /*istanbul ignore next*/
            dispatch(
              showNotifier({
                open: true,
                message: "Provided Json is not valid to import",
                errors: {},
                variant: "error",
              }),
            );
          }
        };
      }
    });
  };

  useEffect(() => {
    getClasses();
  }, [getClasses]);

  const headings = [
    { label: "Title", id: "title", formator: linkTitle },
    { label: "UID", id: "uid" },
    { label: "Type", id: "schema_type" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const tableActions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon data-testid="edit-btn" />,
      handler: (_, uid) => {
        push({
          pathname: `/dashboard/${sport_key}/${env_key}/customField/update/${uid}`,
          state: { from: location },
        });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon data-testid="delete-btn" />,
      handler: (_, uid, dataItem) => {
        setClassId(dataItem);
        setIsModalOpen({ ...isModalOpen, del: true });
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
    {
      id: "export",
      label: "Export",
      icon: <ExportIcon data-testid="export-btn" />,
      handler: exportSchema,
    },
  ];

  return (
    <>
      {/* Panel Header */}
      <PanelHeader
        data-testid="schema-class-header"
        title="Custom Fields"
        refreshHandler={getClasses}
        breadCrumbItems={[{ title: "Custom Fields" }]}
        search={true}
      >
        {/* Import button */}
        <input
          accept=".json"
          data-testid="import-schema"
          id="json-file-upload-input"
          type="file"
          onChange={e => {
            /*istanbul ignore else*/

            if (e.target.files.length > 0) {
              retrieveFiles(e.target.files);
              e.target.value = null;
            }
          }}
          hidden
        />
        {access_type !== Constants.READ_ONLY && (
          <Label
            htmlFor="json-file-upload-input"
            className="json-file-upload-input"
          >
            <Tooltip title="Import">
              <IconButton color="primary" component="span">
                <ImportIcon />
              </IconButton>
            </Tooltip>
          </Label>
        )}

        {/* Add Button */}
        {access_type !== Constants.READ_ONLY && (
          <Button
            component={Link}
            to={{
              pathname: `/dashboard/${sport_key}/${env_key}/customField/add`,
              state: { from: location },
            }}
            data-testid="add-btn"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {/* Loading Skeliton */}

      {isLoading ? (
        <SkeletonTable
          // data-testid="table-loader"
          headings={headings}
          name="Custom Fields "
          actions={tableActions}
        />
      ) : null}

      {/* Table */}
      {classesData.length > 0 && !isLoading ? (
        <Table
          name="Custom Fields"
          headings={headings}
          data={classesData}
          actions={tableActions}
          isSorting
          totalCount={classesCount}
        />
      ) : (
        !isLoading && <NoData> No record found </NoData>
      )}

      {/* Delete Modal  */}

      <Modal
        data-testid="delete-modal"
        open={isModalOpen.del}
        handleClose={handleModalClose}
        title="Delete Custom Fields"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "delete-modal-cancel",
            },
          },
          {
            id: "2",
            name: "Delete",
            handler: deleteModalHandler,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "delete-modal-btn",
            },
          },
        ]}
      >
        {classId && (
          <>
            {deleteConfirmationMessage({
              name: classId.title,
              module: "Custom Fields",
            })}
          </>
        )}
      </Modal>

      {/* Overide Schema Warning Modal*/}
      <Modal
        className="small"
        open={isModalOpen.warning}
        handleClose={handleModalClose}
        title="Override Schema"
        maxwidth="sm"
        actions={[
          {
            id: "2",
            name: "No",
            handler: handleModalClose,
          },
          {
            id: "1",
            name: "Yes",

            handler: () => {
              /*istanbul ignore next*/
              importSchemaReq(importSchema);
              /*istanbul ignore next*/
              setIsModalOpen(intialModalState);
            },
            additionalAttributes: { variant: "contained", color: "primary" },
          },
        ]}
      >
        {classId && (
          <>
            Schema UID
            {` "${importSchema.title}"
                 `}
            already exists, are you sure you want to override existing schema?
          </>
        )}
      </Modal>
    </>
  );
}
