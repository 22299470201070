import { Button, Modal } from "@dfep/ui-component";
import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { Constants } from "utils/constants";
import { deleteConfirmationMessage, initialRoute } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { getSchemaObjects } from "services/getObjects";
import { Link } from "react-router-dom";
import { NoData } from "styles/global.style";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const intialModalState = {
  del: false,
  multiDel: false,
};

export default function SchemaObjects({
  history,
  match: { params },
  location,
}) {
  const { schema_uid, sport_key, env_key } = params;
  const { limit, sort, value: sortValue, skip, searchText } = useQueryParams();

  const [objectData, setObjectData] = useState([]);
  const [objDataCount, setObjDataCount] = useState(0);
  const [schemaObj, setSchemaObj] = useState({});
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(intialModalState);
  const [isLoading, setIsLoading] = useState(true);
  const { access_type } = useSelector(state => state.application);

  const dispatch = useDispatch();

  const handleModalClose = () => {
    setIsModalOpen(intialModalState);
  };

  const fetchSchemaObjects = useCallback(async () => {
    setIsLoading(true);
    setSelected([]);
    setSchemaObj({});
    const {
      data: { objects, count },
    } = await dispatch(
      getSchemaObjects({
        objectType: schema_uid,
        limit,
        sort,
        sortValue,
        skip,
        searchText,
      }),
    );

    if (count !== 0 && objects.length === 0) {
      initialRoute(history);
    } else {
      setObjectData(objects);
      setObjDataCount(count);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema_uid, limit, sort, sortValue, skip, searchText]);

  const deleteObject = async () => {
    handleModalClose();
    await dispatch(
      deleteRecord({
        url: `/v1/custom_schema/admin/classes/${schema_uid}/objects/${schemaObj.uid}`,
      }),
    );
    fetchSchemaObjects();
  };

  const deleteMultiObject = async () => {
    handleModalClose();
    await dispatch(
      deleteRecords({
        url: `/v1/custom_schema/admin/classes/${schema_uid}/objects`,
        uids: selected,
      }),
    );
    fetchSchemaObjects();
  };

  const handleSingleClick = (event, dataId) => {
    const newSelectedData = checkboxSingleClick({
      selected,
      dataId,
    });

    setSelected(newSelectedData);
  };

  const handleSelectAllClick = event => {
    const newSelected = checkboxSelectAllClick({ event, data: objectData });
    setSelected(newSelected);
  };

  const multipleDeleteHandler = event => {
    setIsModalOpen({ ...isModalOpen, multiDel: true });
  };

  const updateObjectHandler = (event, uid) => {
    history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/customField/${schema_uid}/objects/update/${uid}`,
      state: { from: location },
    });
  };

  useEffect(() => {
    fetchSchemaObjects();
  }, [fetchSchemaObjects]);

  const headings = [
    { label: "UID", id: "uid" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];
  const tableActions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon data-testid="edit-btn" />,
      handler: updateObjectHandler,
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon data-testid="delete-btn" />,
      handler: (event, uid, dataItem) => {
        setIsModalOpen({ ...isModalOpen, del: true });
        setSchemaObj(dataItem);
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  const tableTitle = schema_uid.charAt(0).toUpperCase() + schema_uid.slice(1);

  return (
    <>
      <PanelHeader
        title="Custom Fields"
        data-testid="schema-object-header"
        refreshHandler={fetchSchemaObjects}
        breadCrumbItems={[
          {
            title: "Custom Fields",
            link: `/dashboard/${sport_key}/${env_key}/customField`,
          },
          { title: schema_uid },
        ]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            data-testid="add-btn"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            component={Link}
            to={{
              pathname: `/dashboard/${sport_key}/${env_key}/customField/${schema_uid}/objects/add`,
              state: { from: location },
            }}
          >
            Add
          </Button>
        )}
      </PanelHeader>

      {isLoading ? (
        <SkeletonTable
          // data-testid="table-loader"
          headings={headings}
          name={tableTitle}
          actions={tableActions}
        />
      ) : null}
      {objectData.length > 0 && !isLoading ? (
        <Table
          name={tableTitle}
          checkbox={true}
          headings={headings}
          data={objectData}
          actions={tableActions}
          isSorting
          handleSingleClick={handleSingleClick}
          handleSelectAllClick={handleSelectAllClick}
          selectedList={selected}
          numSelected={selected.length}
          totalCount={objDataCount}
          multipleDeleteHandler={multipleDeleteHandler}
        />
      ) : (
        !isLoading && <NoData> No record is found </NoData>
      )}

      <Modal
        data-testid="schema-object-delete"
        open={isModalOpen.del}
        handleClose={handleModalClose}
        title="Delete Object"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModalClose,
          },
          {
            id: "2",
            name: "Delete",
            handler: () => deleteObject(),
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "delete-modal-btn",
            },
          },
        ]}
      >
        {Object.keys(schemaObj).length > 0 && (
          <>
            {deleteConfirmationMessage({
              name: schemaObj?.uid,
              module: tableTitle,
            })}
          </>
        )}
      </Modal>

      <Modal
        data-testid="schema-object-multiple-delete"
        open={isModalOpen.multiDel}
        handleClose={handleModalClose}
        title="Delete Selected Object"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: handleModalClose,
          },
          {
            id: "2",
            name: "Delete",
            handler: () => deleteMultiObject(),
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "delete-modal-multiple-btn",
            },
          },
        ]}
      >
        {selected.length > 0 && (
          <>
            {" "}
            Are you sure you want to delete <strong>
              {selected.length}
            </strong>{" "}
            object?
          </>
        )}
      </Modal>
    </>
  );
}
