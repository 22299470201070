import "suneditor/dist/css/suneditor.min.css";
import { Controller, useFormContext } from "react-hook-form";
import { CustomFlexBox, FieldGroup } from "styles/global.style";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import FieldBorder from "app/components/Common/FieldBorder ";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import Label from "app/components/Common/Label";
import React from "react";
import RTEditor from "app/components/Common/RTEditor";

const Editor = ({
  uid,
  validations,
  display_name,
  onFieldDelete,
  mandatory,
  index,
  isBorder = true,
  defaultValue,
  visible = true,
}) => {
  const { control, getValues } = useFormContext();
  const state = useSelector(state => state.formData);
  const getFieldData = name => get(state, name);

  return (
    <>
      <Controller
        name={`${uid}`}
        control={control}
        rules={{
          required: mandatory ? `${display_name} is required` : false,
          validate: data => {
            if (
              (mandatory && data === "<p><br></p>") ||
              data === `<p style="float: none;"><br style="float: none;"></p>`
            ) {
              return `${display_name} is required`;
            }
            return true;
          },
        }}
        defaultValue={defaultValue ?? getValues(`${uid}`)}
        render={({
          field: { value, name, ref, onBlur, onChange },
          fieldState,
          formState,
        }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, `${name}`);
          const fieldError = get(errors, `${name}`);
          if (value && typeof value === "object") {
            onChange(getFieldData(name));
          }

          const handleOnChange = e => {
            if (
              e === "<p><br></p>" ||
              e === `<p style="float: none;"><br style="float: none;"></p>`
            )
              onChange(null);
            else onChange(e);
          };

          return (
            <FieldGroup>
              {visible && (
                <FieldBorder isBorder={isBorder} isError={hasFieldError}>
                  <div className="editor-wrapper">
                    <CustomFlexBox className="grp-heading">
                      {index !== undefined && index !== "" ? (
                        <Label className="grp-label lbl" required={mandatory}>
                          {display_name}-{index + 1}
                        </Label>
                      ) : (
                        <Label className="grp-label lbl" required={mandatory}>
                          {display_name}
                        </Label>
                      )}
                      {onFieldDelete && (
                        <IconButton
                          onClick={event => onFieldDelete(event, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </CustomFlexBox>
                    <CustomFlexBox className="alignItemsTop grp-content">
                      <RTEditor
                        name={name}
                        value={value}
                        onChange={handleOnChange}
                        onBlur={onBlur}
                        // ref={ref}
                      />
                    </CustomFlexBox>
                    {hasFieldError && (
                      <FormHelperText error>
                        {fieldError?.message}
                      </FormHelperText>
                    )}
                  </div>
                </FieldBorder>
              )}
            </FieldGroup>
          );
        }}
      />
    </>
  );
};

export default Editor;
