import { Container as MockGames } from "app/pages/Dashboard/Data/Game/mockGames";
import { Redirect, Route, Switch } from "react-router-dom";
import Details from "app/pages/Dashboard/Data/Game/Details/index";
import Leader from "app/pages/Dashboard/Data/Game/Leader";
import PlayByPlay from "app/pages/Dashboard/Data/Game/PlayByPlay";
import PlayerLogs from "app/pages/Dashboard/Data/Game/PlayerLogs";
import React from "react";
import StartingLineup from "app/pages/Dashboard/Data/Game/StartingLineup";

const Game = props => {
  return (
    <Switch>
      <Redirect
        from={props.match.path}
        to={`${props.match.url}/details`}
        exact
      />
      <Route path={`${props.match.path}/leader`} component={Leader} />
      <Route path={`${props.match.path}/details`} component={Details} />
      <Route path={`${props.match.path}/playerLogs`} component={PlayerLogs} />
      <Route path={`${props.match.path}/mockgame`} component={MockGames} />
      <Route path={`${props.match.path}/playByPlay`} component={PlayByPlay} />
      <Route
        path={`${props.match.path}/startingLineup`}
        component={StartingLineup}
      />
    </Switch>
  );
};

export default Game;
