const schema = {
  class: {
    title: "Device Messages",
    uid: "device_messages",
    _schema: [
      {
        multiple: false,
        mandatory: true,
        uid: "key",
        display_name: "Key",
        data_type: "string",
        field_metadata: {},
        format: /^[a-zA-Z\d\-_.,\s]+$/,
        _schema: [],
      },
      {
        multiple: false,
        mandatory: true,
        uid: "message",
        display_name: "Message",
        data_type: "rte",
        field_metadata: {},
        _schema: [],
      },
      {
        multiple: false,
        mandatory: true,
        uid: "log_type",
        display_name: "Log Type",
        data_type: "string",
        field_metadata: {
          select: true,
          options: [
            { label: "Info", value: "info" },
            { label: "Success", value: "success" },
            { label: "Error", value: "error" },
          ],
        },
        _schema: [],
      },
      {
        multiple: false,
        mandatory: false,
        uid: "custom_fields",
        display_name: "Custom Fields",
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
