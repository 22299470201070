import { Form_Mode } from "utils/constants";
import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import SchemaForm from "../schemaForm";

const UpdateCategory = props => {
  const [categoryData, setCategoryData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/interactive-maps/categories`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = useCallback(async () => {
    const url = `/v1/admin/interactive_maps/categories/${uid}`;
    const {
      data: { category },
    } = await dispatch(get({ url }));
    setCategoryData(category);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    let array = [];
    for (const prop in data) {
      if (["child_categories"].includes(prop)) {
        data[prop].forEach(obj => {
          array.push(obj.id);
        });
        Object.assign(data, { child_categories: array });
      }
    }

    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/admin/interactive_maps/categories/${uid}`,
          key: "category",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Update Category"
        breadCrumbItems={[
          {
            title: "Update Category",
          },
        ]}
        backUrl={getURL}
      />

      {categoryData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={categoryData}
          backUrl={getURL}
          props={props}
          mode={Form_Mode.UPDATE}
        />
      )}
    </>
  );
};

export default UpdateCategory;
