import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddAdvPlayerStats = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const backUrl = `/dashboard/${sport_key}/${env_key}/data/rosters/advancedPlayerStats`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/advanced_player_stats",
          key: "advanced_player_stats",
          form: data,
        }),
      );
      props.history.push(backUrl);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Advance Player Stats"
        breadCrumbItems={[
          {
            title: "Add Advance Player Stats",
          },
        ]}
        backUrl={backUrl}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="advanced_player_stats"
        backUrl={backUrl}
      />
    </>
  );
};

export default AddAdvPlayerStats;
