// /* eslint-disable react-hooks/exhaustive-deps */
// import { capitalize, getYearArray } from "utils/functions";
// import { CardLoader } from "./EnvironmentLoader";
// import { Content } from "./Environment.style";
// import { seasons } from "utils/constants";
// import { updateRecord } from "services/updateObject";
// import { useDispatch } from "react-redux";
// import { useEffect, useState } from "react";
// import CloseIcon from "@material-ui/icons/Close";
// import EnvironmentOptions from "./EnvironmentOptions";
// import FormControl from "@material-ui/core/FormControl";
// import Grid from "@material-ui/core/Grid";
// import IconButton from "@material-ui/core/IconButton";
// import InputLabel from "@material-ui/core/InputLabel";
// import isEqual from "lodash/isEqual";
// import MenuItem from "@material-ui/core/MenuItem";
// import PropTypes from "prop-types";
// import SaveIcon from "@material-ui/icons/Save";
// import Select from "@material-ui/core/Select";
// import Typography from "@material-ui/core/Typography";

// const EnvCardContent = ({
//   env,
//   sport_key,
//   fetchEnvironment,
//   leagues,
//   history,
//   handleEnvDelete,
//   isActive,
// }) => {
//   const { name, env_key, league_id = "", season_id = "", year = "" } = env;
//   // Initial Props State
//   const initialEnvProp = {
//     league_id,
//     season_id,
//     year,
//   };
//   // States
//   const [isEdit, setEdit] = useState(false);
//   const [environment, setEnv] = useState(initialEnvProp);
//   const [loading, setLoading] = useState(false);

//   let isChanged = false;

//   // Functions
//   const handleEdit = (event, key = true) => {
//     event.preventDefault();
//     setEdit(key);
//   };

//   const handleEnvChange = event => {
//     event.preventDefault();
//     switch (event.target.name) {
//       case "league":
//         setEnv({ ...environment, league_id: event.target.value });
//         break;
//       case "season":
//         setEnv({ ...environment, season_id: event.target.value });
//         break;
//       case "year":
//         setEnv({ ...environment, year: event.target.value.toString() });
//         break;
//       /*istanbul ignore next*/
//       default:
//         break;
//     }
//   };

//   // React-Redux Hooks
//   const dispatch = useDispatch();

//   const handleSave = async event => {
//     setLoading(true);
//     event.preventDefault();
//     await dispatch(
//       updateRecord({
//         loader: false,
//         url: `/v1/admin/applications/${sport_key}/envs/${env_key}/settings`,
//         key: "settings",
//         form: {
//           nba_sync_config: {
//             ...environment,
//             league_id: environment.league_id,
//             season_id: environment.season_id,
//             year: environment.year,
//           },
//         },
//       }),
//     );
//     setEdit(false);
//     setLoading(false);
//     fetchEnvironment();
//   };

//   if (isEdit) {
//     !isEqual(initialEnvProp, environment)
//       ? (isChanged = true)
//       : (isChanged = false);
//   }
//   useEffect(() => {
//     isActive(isEdit);
//   }, [isEdit]);

//   /*istanbul ignore next*/
//   const onClose = event => {
//     event.preventDefault();
//   };

//   return (
//     <>
//       <Content
//         mb={4}
//         display="flex"
//         alignItems="center"
//         justifyContent="space-between"
//         data-testid={`card-options-${env_key}`}
//       >
//         <Typography variant="h6" component="h6">
//           {capitalize(name)}
//         </Typography>

//         {!isEdit ? (
//           <EnvironmentOptions
//             handleEdit={handleEdit}
//             sport_key={sport_key}
//             env_key={env_key}
//             name={capitalize(name)}
//             fetchEnvironment={fetchEnvironment}
//             history={history}
//             handleEnvDelete={handleEnvDelete}
//             env_name={name}
//           />
//         ) : isChanged ? (
//           <IconButton onClick={handleSave} data-testid="env-save">
//             <SaveIcon fontSize="small" />
//           </IconButton>
//         ) : (
//           <IconButton
//             onClick={event => handleEdit(event, false)}
//             data-testid="env-edit-cancel"
//           >
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         )}
//       </Content>

//       {loading ? (
//         <CardLoader />
//       ) : (
//         <Grid container data-testid="card-settings">
//           <Grid item xs={12}>
//             <FormControl disabled={!isEdit}>
//               <InputLabel
//                 shrink
//                 id="league-native-label-placeholder"
//                 className="lbl"
//               >
//                 League
//               </InputLabel>

//               <Select
//                 name="league"
//                 labelId="league-native-label-placeholder"
//                 id="league-native-label-placeholder"
//                 defaultValue={`${environment?.league_id}`}
//                 value={`${environment?.league_id}`}
//                 onChange={handleEnvChange}
//                 displayEmpty
//                 onClose={onClose}
//                 data-testid="select-league"
//               >
//                 {leagues.map(({ label, league_id }) => (
//                   <MenuItem
//                     value={`${league_id}`}
//                     key={label + league_id}
//                     onClick={onClose}
//                   >
//                     {label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={8}>
//             <FormControl disabled={!isEdit}>
//               <InputLabel
//                 shrink
//                 id="league-native-label-placeholder"
//                 className="lbl"
//               >
//                 Season
//               </InputLabel>
//               <Select
//                 name="season"
//                 labelId="league-native-label-placeholder"
//                 id="league-native-label-placeholder"
//                 defaultValue={`${environment?.season_id}`}
//                 value={`${environment?.season_id}`}
//                 onChange={handleEnvChange}
//                 displayEmpty
//                 onClose={onClose}
//                 data-testid="select-season"
//               >
//                 {seasons.map(({ label, value }) => (
//                   <MenuItem
//                     value={`${value}`}
//                     key={label + value}
//                     onClick={onClose}
//                   >
//                     {label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={4}>
//             <FormControl disabled={!isEdit}>
//               <InputLabel shrink id="league-native-label-placeholder">
//                 Year
//               </InputLabel>

//               <Select
//                 name="year"
//                 labelId="league-native-label-placeholder"
//                 id="league-native-label-placeholder"
//                 defaultValue={`${environment?.year}`}
//                 value={environment?.year}
//                 onChange={handleEnvChange}
//                 displayEmpty
//                 onClose={onClose}
//                 data-testid="select-year"
//               >
//                 {getYearArray().map(({ label, value }) => (
//                   <MenuItem
//                     value={`${value}`}
//                     key={label + value}
//                     onClick={onClose}
//                   >
//                     {label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//       )}
//     </>
//   );
// };

// EnvCardContent.defaultProp = {
//   env: {
//     name: "",
//   },
// };

// EnvCardContent.propTypes = {
//   env: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//   }),
// };

// export default EnvCardContent;

/* eslint-disable react-hooks/exhaustive-deps */
import { capitalize } from "utils/functions";
import { Content } from "./Environment.style";
import EnvironmentOptions from "./EnvironmentOptions";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const EnvCardContent = ({
  env,
  sport_key,
  fetchEnvironment,
  history,
  handleEnvDelete,
}) => {
  const { name, env_key } = env;

  return (
    <>
      <Content
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        data-testid={`card-options-${env_key}`}
      >
        <Typography variant="h6" component="h6" data-testid="card-name">
          {capitalize(name)}
        </Typography>

        <EnvironmentOptions
          sport_key={sport_key}
          env_key={env_key}
          name={capitalize(name)}
          // fetchEnvironment={fetchEnvironment}
          history={history}
          handleEnvDelete={handleEnvDelete}
          env_name={name}
        />
      </Content>
    </>
  );
};

EnvCardContent.defaultProp = {
  env: {
    name: "",
  },
};

EnvCardContent.propTypes = {
  env: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default EnvCardContent;
