import { Controller } from "react-hook-form";
import FieldBorder from "../FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import React from "react";
import TextField from "../TextField";
// watchOn{name:"actions",value:"web_link"},
// watch On Type and value
const TextController = ({
  watchInstance,
  watchOn,
  control,
  validations,
  fieldName,
  uid,
  title,
  type,
  className,
  readOnly,
  disabled,
  visible = true,
  doNotTrim,
  showBorder,
  defaultValue,
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={validations}
      defaultValue={defaultValue ?? ""}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, fieldName);
        const fieldError = get(errors, fieldName);

        // const visible =
        //   Object.keys(watchOn).length > 0
        //     ? watchInstance(watchOn.fieldName) === watchOn.value
        //     : true;

        return (
          visible && (
            <FieldBorder isBorder={showBorder} isError={hasFieldError}>
              <TextField
                label={title}
                type={type}
                variant="outlined"
                error={hasFieldError}
                value={field.value}
                helperText={hasFieldError && fieldError.message}
                FormHelperTextProps={{
                  "data-testid": `${uid}-error`,
                }}
                onChange={field.onChange}
                onBlur={e => {
                  if (!doNotTrim) {
                    e.target.value = e.target.value.trim();
                    field.onChange(e.target.value);
                  }
                  field.onBlur(e.target.value);
                }}
                inputRef={field.ref}
                InputLabelProps={{
                  required: !!validations?.required,
                }}
                InputProps={{
                  readOnly: !!readOnly,
                }}
                inputProps={{
                  "data-testid": uid,
                  "aria-invalid": !!errors[`${fieldName}`] ? true : false,
                }}
                className={className}
                disabled={!!disabled}
              />
            </FieldBorder>
          )
        );
      }}
    />
  );
};

export default TextController;
