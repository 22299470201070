import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import MuiButtonGroup from "@material-ui/core/ButtonGroup";
import styled from "styled-components";

export const Panel = styled.div`
  display: flex;
  padding: 22px 0;
  background: ${props => props.theme.palette.grey.grey100};
  p {
    margin: 0;
  }
  .MuiBreadcrumbs-root {
    margin-top: 8px;
  }
  .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.87);
    &:hover {
      color: ${props => props.theme.palette.grey.grey800};
    }
  }
  .MuiButton-root {
    min-width: 78px;
  }
  .MuiPaper-root {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`;
export const BackLink = styled(Link)`
  color: ${props => props.theme.sidebar.background};
  cursor: pointer;
  svg {
    margin-top: 3px;
    margin-right: 16px;
  }
`;
export const PanelHeaderWrap = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }
  .title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 28px;
    font-weight: 600;
    line-height: 33px;
  }
`;

export const PanelInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiIconButton-root {
    padding: 7px;
  }
`;

export const PanelActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiButton-root {
    margin-left: ${props => props.theme.spacing(4)}px;
    &:first-child {
      margin-left: 0;
    }
  }
  .json-file-upload-input {
    margin-left: 16px;
  }
`;
export const ButtonGroup = styled(MuiButtonGroup)`
  &.split-button {
    margin-left: 16px;
    height: 36px;
    .MuiButton-root {
      margin-left: 0;
      &:focus,
      &:active,
      &:hover {
        border-color: ${props => props.theme.palette.black.blackDark};
        color: ${props => props.theme.palette.black.blackDark};
        background: ${props => props.theme.palette.white.main};
      }
    }
    .arrow-btn {
      min-width: inherit;
      padding: 8px;
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }
  }
`;
