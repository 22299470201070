import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { connect, useSelector } from "react-redux";
import { Constants } from "utils/constants";
import { Container as GetTeams } from "./teams";
import { getTeams } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { Link, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useEffect, useState } from "react";
import Settings from "./settings";

const TeamsIndex = props => {
  const { access_type } = useSelector(state => state.application);

  let tabObj = { teams: 0, settings: 1 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [tabValue, setTabValue] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== tabValue) {
    setTabValue(tab);
  }

  const [teamsData, setTeamsData] = useState([]);
  const [teamsCount, setTeamsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { show, closeModalHandler, openModalHandler } = useModal();
  const { sort, sortValue, skip, limit, searchText } = useQueryParams();
  const { sport_key, env_key } = props.match.params;

  const getTeamsData = useCallback(async () => {
    setIsLoading(true);
    setTeamsData([]);
    const {
      data: { teams, count },
    } = await props.getTeams({
      sort,
      sortValue,
      skip,
      limit,
      searchText,
    });

    if (count !== 0 && teams.length === 0) {
      initialRoute(props.history);
    } else {
      setTeamsData(teams);
      setTeamsCount(count);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, sortValue, skip, limit, searchText]);

  useEffect(() => {
    getTeamsData();
  }, [getTeamsData]);

  //sync To Cms
  const synToCms = async () => {
    await props.syncToCms({
      url: "/v1/nba/admin/sync/cms_teams",
      method: "post",
    });
  };
  const syncModal = () => {
    openModalHandler("show");
  };

  //sync Teams
  const synToTeams = async () => {
    await props.syncTeams({
      url: "/v1/nba/admin/sync/teams",
      method: "post",
    });
    closeModalHandler();
  };

  return (
    <>
      <PanelHeader
        title="Teams"
        breadCrumbItems={[
          {
            title: "Teams",
          },
        ]}
        refreshHandler={getTeamsData}
        sync={syncModal}
        hasSyncCMS={true}
        syncToCMS={synToCms}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="add-btn"
            startIcon={<AddIcon />}
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/team/teams/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>

      {!searchText && (
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          <Tab
            label="Teams"
            component={Link}
            to={`${props.match.url}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <Switch>
        <>
          <TabPanel>
            <Route
              path={`${props.match.path}`}
              /*istanbul ignore next*/
              render={routeProps => (
                /*istanbul ignore next*/
                <GetTeams
                  {...routeProps}
                  getTeamsData={getTeamsData}
                  teamsData={teamsData}
                  isLoading={isLoading}
                  teamsCount={teamsCount}
                  openSync={show}
                  closeSync={closeModalHandler}
                  sendSync={synToTeams}
                  access_type={access_type}
                />
              )}
              exact
            />
            <Route
              path={`${props.match.path}/settings`}
              component={Settings}
              exact
            />
          </TabPanel>
        </>
      </Switch>
    </>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getTeams: payload => dispatch(getTeams(payload)),
    syncToCms: payload => dispatch(requestAPI(payload)),
    syncTeams: payload => dispatch(requestAPI(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(TeamsIndex);

export { Container, TeamsIndex as Component };
