import React from "react";

const CustomServices = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="#fff"
      opacity={0.5}
      {...props}
    >
      <path d="M5.833 13.333c0-.46.373-.833.833-.833H7.5a.833.833 0 010 1.666h-.833a.833.833 0 01-.833-.833zm0-3.333c0-.46.373-.833.833-.833h6.667a.833.833 0 010 1.666H6.667A.833.833 0 015.834 10zm0-3.333c0-.46.373-.833.833-.833h6.667a.833.833 0 010 1.666H6.667a.833.833 0 01-.833-.833zm10-4.167H12.35C12 1.533 11.083.833 10 .833s-2 .7-2.35 1.667H4.167a1.46 1.46 0 00-.333.033 1.676 1.676 0 00-.842.458 1.67 1.67 0 00-.358.533 1.61 1.61 0 00-.133.642V17.5a1.71 1.71 0 00.133.65c.083.2.208.375.358.533a1.67 1.67 0 00.842.458 2.16 2.16 0 00.333.025h11.667A1.67 1.67 0 0017.5 17.5V4.167A1.67 1.67 0 0015.833 2.5zM10 2.292a.63.63 0 01.625.625.63.63 0 01-.625.625.63.63 0 01-.625-.625.63.63 0 01.625-.625zM15.833 17.5H4.167V4.167h11.667V17.5zm-4.247-3.239l-.793-.789-.793.783 1.586 1.578 2.581-2.544-.799-.789-1.783 1.761z" />
    </svg>
  );
};
export default CustomServices;
