import { Container as GetLeaders } from "app/pages/Dashboard/Data/Team/Leaders/get";
import { Route, Switch } from "react-router-dom";
import AddLeaders from "./add";
import React from "react";
import UpdateLeaders from "./update";

const Leaders = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddLeaders} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateLeaders}
        exact
      />
      <Route path={`${props.match.path}`} component={GetLeaders} />
    </Switch>
  );
};

export default Leaders;
