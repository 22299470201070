import { Controller } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import FieldBorder from "../FieldBorder ";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import has from "lodash/has";
import React from "react";

export default function CheckboxField({
  watchInstance,
  watchOn,
  control,
  fieldName,
  validations,
  defaultValue,
  className,
  disabled,
  uid,
  showBorder,
  title,
}) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue}
      rules={validations}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, fieldName);

        const visible =
          Object.keys(watchOn).length > 0
            ? watchInstance(watchOn?.fieldName) === watchOn?.value
            : true;
        return (
          visible && (
            <>
              <FieldBorder isBorder={showBorder} isError={hasFieldError}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={className}
                      data-testid={uid}
                      disabled={disabled}
                      checked={field.value}
                      value={field.value}
                      name={field.name}
                      onChange={event => {
                        return field.onChange(event.target.checked);
                      }}
                      inputProps={{
                        "data-testid": `input-${uid}`,
                        "aria-disabled": !!disabled,
                      }}
                    />
                  }
                  label={title}
                />
              </FieldBorder>
              {hasFieldError && (
                <FormHelperText data-testid={`${uid}-error`} error>
                  {hasFieldError.message}
                </FormHelperText>
              )}
            </>
          )
        );
      }}
    />
  );
}
