import { Redirect, Route, Switch } from "react-router-dom";
import AdvanceStats from "app/pages/Dashboard/Data/Team/AdvanceStats";
import Leaders from "app/pages/Dashboard/Data/Team/Leaders";
import LeadersDetails from "app/pages/Dashboard/Data/Team/Leader_Details";
import React from "react";
import Schedules from "app/pages/Dashboard/Data/Team/Schedules";
import Season from "app/pages/Dashboard/Data/Team/Season_average";
import Splits from "app/pages/Dashboard/Data/Team/Splits";
import Standing from "app/pages/Dashboard/Data/Team/Standing";
import Stats from "app/pages/Dashboard/Data/Team/stats";
import Teams from "app/pages/Dashboard/Data/Team/Teams";
const Team = props => {
  return (
    <Switch>
      <Redirect
        from={props.match.path}
        to={`${props.match.url}/schedules`}
        exact
      />
      <Route path={`${props.match.path}/schedules`} component={Schedules} />
      <Route path={`${props.match.path}/standing`} component={Standing} />
      <Route path={`${props.match.path}/season_average`} component={Season} />
      <Route path={`${props.match.path}/leaders`} component={Leaders} />
      <Route path={`${props.match.path}/teams`} component={Teams} />
      <Route path={`${props.match.path}/stats`} component={Stats} />
      <Route
        path={`${props.match.path}/advanceStats`}
        component={AdvanceStats}
      />

      <Route path={`${props.match.path}/splits`} component={Splits} />

      <Route
        path={`${props.match.path}/leader_details`}
        component={LeadersDetails}
      />
    </Switch>
  );
};

export default Team;
