import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateDropv2Config = props => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/drops/v2/config`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/admin/drops/other/v2/config/${uid}`;
    const {
      data: { drops_v2_config },
    } = await dispatch(get({ url }));
    setData(drops_v2_config);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/admin/drops/other/v2/config/${uid}`,
          key: "drops_v2_config",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Drop v2 Config"
        breadCrumbItems={[
          {
            title: "Update Drop v2 Config",
          },
        ]}
        backUrl={getURL}
      />

      {data && (
        <SchemaForm
          onSubmit={onSubmit}
          modelName="drops_v2_config"
          defaultValues={data}
          schema={schema}
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateDropv2Config;
