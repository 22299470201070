import { Controller } from "react-hook-form";
import { CustomFlexBox } from "styles/global.style";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FieldWrap } from "styles/global.style";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import FieldBorder from "app/components/Common/FieldBorder ";
import get from "lodash/get";
import has from "lodash/has";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";

const FormDate = React.memo(
  ({
    uid,
    validations,
    display_name,
    onFieldDelete,
    mandatory,
    index,
    field_metadata,
    isBorder = false,
    defaultValue,
    visible = true,
  }) => {
    const { control, getValues } = useFormContext();
    const state = useSelector(state => state.formData);
    const getFieldData = name => get(state, name);

    return (
      <Controller
        name={`${uid}`}
        control={control}
        rules={{
          required: mandatory ? `${display_name} is required` : false,
        }}
        defaultValue={defaultValue ?? getValues(`${uid}`)}
        render={({
          field: { ref, name, onChange, value, ...restProps },
          formState,
        }) => {
          const { errors } = formState;
          const hasFieldError = has(errors, `${name}`);
          const fieldError = get(errors, `${name}`);
          if (value && typeof value === "object") {
            onChange(getFieldData(name));
          }
          return (
            <FieldBorder isBorder={isBorder} isError={hasFieldError}>
              {visible && (
                <FieldWrap className="field">
                  <CustomFlexBox>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {!field_metadata ||
                      !Object.keys(field_metadata).includes("timeFormat") ||
                      field_metadata.timeFormat ? (
                        <DateTimePicker
                          disabled={!!field_metadata?.readOnly}
                          {...restProps}
                          clearable
                          inputProps={{
                            "data-testid": `${uid}-date`,
                          }}
                          InputLabelProps={{
                            "data-testid": `${uid}-date-error`,
                            required: mandatory,
                          }}
                          className="datetime-picker"
                          inputVariant="outlined"
                          // allowKeyboardControl={true}
                          label={display_name}
                          ampm
                          value={value ?? null}
                          helperText={hasFieldError && fieldError?.message}
                          onChange={value => {
                            if (value) {
                              onChange(Date.parse(value));
                            } else {
                              onChange(value);
                            }
                          }}
                          error={hasFieldError}
                          format={"yyyy-MM-dd p"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventRoundedIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <DatePicker
                          disabled={!!field_metadata?.readOnly}
                          {...restProps}
                          clearable
                          inputProps={{
                            "data-testid": `${uid}-date`,
                          }}
                          InputLabelProps={{
                            "data-testid": `${uid}-date-error`,
                            required: mandatory,
                          }}
                          className="datetime-picker"
                          inputVariant="outlined"
                          // allowKeyboardControl={true}
                          label={display_name}
                          ampm
                          value={
                            value ? format(new Date(value), "yyyy-MM-dd") : null
                          }
                          helperText={hasFieldError && fieldError?.message}
                          onChange={value => {
                            if (value) {
                              onChange(Date.parse(value));
                            } else {
                              onChange(value);
                            }
                          }}
                          error={hasFieldError}
                          format={"yyyy-MM-dd"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventRoundedIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </MuiPickersUtilsProvider>
                    {onFieldDelete && (
                      <IconButton
                        onClick={event => onFieldDelete(event, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </CustomFlexBox>
                </FieldWrap>
              )}
            </FieldBorder>
          );
        }}
      />
    );
  },
);

export default FormDate;
