import { Button } from "@dfep/ui-component";
import { Chip, Paper } from "./Webhook.style";
import { FormWrapper } from "styles/global.style";
import { getSchemaClasses } from "services/getObjects";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { validateUrl } from "utils/functions";
import AddIcon from "@material-ui/icons/Add";
import clonedeep from "lodash/cloneDeep";
import Form from "app/components/Common/Form";
import FormHelperText from "@material-ui/core/FormHelperText";
import PanelFooter from "app/components/Common/PanelFooter";
import React, { useEffect, useState } from "react";

const ChipComponent = ({ channel, handleDelete }) => {
  return (
    channel?.length > 0 && (
      <Paper>
        {channel?.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            color="primary"
            variant="outlined"
            onDelete={() => handleDelete(chip)}
            // deleteIcon={<CloseIcon data-testid="chip-delete" />}
          />
        ))}
      </Paper>
    )
  );
};

const WebhookForm = ({ onSubmit, formData, sport_key, env_key, location }) => {
  const { state } = location;
  const [customClasses, setClasses] = useState([]);
  // redux hooks
  const dispatch = useDispatch();
  const WebhookForm = useForm({
    mode: "all",
    reValidateMode: "all",
    shouldUnregister: true,
  });

  const {
    formState: { dirtyFields, errors },
  } = WebhookForm;

  const channelGroup = ["channel", "schema-class", "actions"];

  const handleDelete = data => {
    const { channel } = getValues("webhookgroup");
    const filterChip = channel.filter(chip => chip !== data);
    setValue(
      "webhookgroup",
      { handleDelete, channel: filterChip },
      { shouldDirty: true },
    );
  };

  const { getValues, setValue, trigger } = WebhookForm;
  // Form Schema
  const [webhookSchema, setWebhookSchema] = useState({
    title: "",
    name: "add-webhook",
    uid: "webhookform",
    field_metadata: {
      options: [],
    },
    defaultValue: "",
    validations: {},
    type: "group",
    _schema: [
      {
        title: "URL",
        uid: "url",
        name: "url",
        type: "text",
        defaultValue: formData?.url ?? "",
        validations: {
          required: "URL is required.",
          validate: { pattern: val => validateUrl(val) || "Invalid URL." },
        },
        field_metadata: {
          isBorder: false,
          options: [],
        },
        _schema: [],
      },
      {
        title: "Method",
        uid: "method",
        name: "method",
        type: "select",
        defaultValue: formData?.method?.toLowerCase() ?? "",
        validations: {
          required: "Method is required",
        },
        field_metadata: {
          isBorder: false,
          options: [
            {
              label: "GET",
              value: "get",
            },
            {
              label: "POST",
              value: "post",
            },
          ],
        },
        _schema: [],
      },
      {
        title: "Channels",
        uid: "channels",
        name: "channels",
        type: "group",
        defaultValue: "",
        validations: {
          required: "Channel is required.",
        },
        field_metadata: {
          isBorder: true,
          options: [],
        },
        _schema: [
          {
            title: "Channel",
            uid: "channel",
            name: "channel",
            type: "select",
            defaultValue: "custom_schema",
            validations: {
              required: "Channel is required.",
            },
            field_metadata: {
              isBorder: true,
              options: [{ label: "CUSTOM_SCHEMA", value: "custom_schema" }],
            },
            _schema: [],
          },
          {
            title: "Schema-Class",
            uid: "schema-class",
            name: "schema-class",
            type: "select",
            defaultValue: "", //classes[0].value
            validations: {
              required: "Schema is required.",
            },
            field_metadata: {
              isBorder: true,
              options: [], // classes
              selectFieldProps: {
                disabled: false,
              },
            },
            _schema: [],
          },
          {
            title: "Actions",
            uid: "actions",
            name: "actions",
            type: "select",
            defaultValue: "create",
            validations: {
              required: "Actions is required.",
            },
            field_metadata: {
              isBorder: true,
              options: [
                {
                  label: "CREATE",
                  value: "create",
                },
                {
                  label: "UPDATE",
                  value: "update",
                },
                {
                  label: "DELETE",
                  value: "delete",
                },
              ],
            },
            _schema: [],
          },
          {
            title: "",
            uid: "webhookLayout",
            name: "",
            type: "layout",
            defaultValue: "",
            validations: {},
            field_metadata: {},
            _schema: [
              {
                title: "",
                uid: "layout",
                defaultValue: false,
                name: "button",
                type: "component",
                validations: {},
                value: { disabled: customClasses.length === 0 },
                field_metadata: {
                  component: (
                    <>
                      <Button
                        data-testid="add-chip"
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={async () => {
                          const result = await trigger(channelGroup);
                          if (result) {
                            const channels = getValues(channelGroup);
                            const { channel, handleDelete } = getValues(
                              "webhookgroup",
                            );
                            const channelClone = clonedeep(channel);
                            const hook = `${channels[0]}.${channels[1]}.${channels[2]}`;
                            if (!channelClone.includes(hook)) {
                              channelClone.push(hook);
                              setValue(
                                "webhookgroup",
                                {
                                  handleDelete,
                                  channel: channelClone,
                                },
                                { shouldDirty: true },
                              );
                            }
                          }
                        }}
                      >
                        Add
                      </Button>
                      <FormHelperText>
                        Click here to add webhooks tag
                      </FormHelperText>
                    </>
                  ),
                },
              },
              {
                title: "",
                name: "webhookgroup",
                uid: "component",
                type: "component",
                defaultValue: {
                  channel: formData?.channels || [],
                  handleDelete,
                },
                validations: {},
                field_metadata: {
                  component: <ChipComponent />,
                },
              },
            ],
          },
        ],
      },
      {
        title: "Headers",
        uid: "headers",
        name: "headers",
        type: "jsonEditor",
        defaultValue: JSON.stringify(formData?.headers, null, "\t") ?? "{}",
        validations: {
          required: "Headers is required",
        },
        field_metadata: {
          isBorder: true,
        },
        _schema: [],
      },
    ],
  });

  const { push } = useHistory();

  const cancelForm = () => {
    push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/webhooks`,
    );
  };

  // Api Call for getting custom Schema classes
  const getCustomSchemaClasses = async () => {
    const projection = { uid: 1, title: 1 };
    let webhookSchemaClone = clonedeep(webhookSchema);
    let field_metadata =
      webhookSchemaClone._schema[2]._schema[1].field_metadata;
    const {
      data: { classes: data },
    } = await dispatch(getSchemaClasses({ projection }));
    if (data.length > 0) {
      const classes = data.map(({ title: label, uid: value }) => ({
        label,
        value,
      }));
      setClasses([...classes]);
      field_metadata.options = classes;
      setWebhookSchema(webhookSchemaClone);
      setValue("schema-class", classes[0]?.value ?? "", {
        shouldDirty: false,
      });
    } else if (data.length === 0) {
      field_metadata.selectFieldProps.disabled = true;
      setWebhookSchema(webhookSchemaClone);
    }
  };

  useEffect(() => {
    getCustomSchemaClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormWrapper elevation={1} data-testid="WebhookForm">
      <Form
        formData
        formId="WebhookForm"
        customSchema={webhookSchema}
        onSubmit={onSubmit}
        form={WebhookForm}
        additionalFormAttributes={{
          className: "maxWidthSm",
          "data-testid": "webhook-form",
        }}
        watchList={["channel", "schema-class", "actions", "webhookgroup"]}
      />
      <PanelFooter data-testid="panel-footer">
        <div>
          <Button
            onClick={cancelForm}
            variant="contained"
            color="primary"
            data-testid="cancel-form"
          >
            Cancel
          </Button>
          <Button
            disabled={
              !Object.keys(dirtyFields).length || !!Object.keys(errors).length
            }
            data-testid="webhook-submit"
            type="submit"
            variant="contained"
            color="primary"
            form="WebhookForm"
          >
            {formData ? "Save" : "Add"}
          </Button>
        </div>
      </PanelFooter>
    </FormWrapper>
  );
};

export default WebhookForm;
