import { Constants } from "utils/constants";
import {
  StyledTable as Table,
  TableBody,
  TableContainer,
} from "@dfep/ui-component";
import { TableWrap } from "./Table.style";
import { useSelector } from "react-redux";
import Head from "./TableHead";
import Pagination from "../Pagination";
import PropTypes from "prop-types";
import React from "react";
import TableCell from "./TableBodyCell";
import TableToolbar from "app/components/Common/Table/TableToolbar";

export default function TableView({
  data,
  headings,
  checkbox,
  handleSelectAllClick,
  selectedList,
  actions,
  numSelected,
  name,
  multipleDeleteHandler,
  extraInfo,
  extraDateInfo,
  handleSingleClick,
  actionMenu,
  isSorting,
  collapsibleComponent,
  totalCount,
  limit,
  isCheckboxDisabled,
  ...rest
}) {
  const selected = selectedList || [];
  const { access_type } = useSelector(state => state.application);

  return (
    <TableWrap data-testid="table">
      <TableToolbar
        numSelected={numSelected}
        name={name}
        multipleDeleteHandler={multipleDeleteHandler}
      />
      <TableContainer>
        <Table>
          <Head
            checkbox={access_type !== Constants.READ_ONLY && checkbox}
            data={data}
            handleSelectAllClick={handleSelectAllClick}
            selected={selected}
            headings={headings}
            actions={actions}
            isSorting={isSorting}
          />
          <TableBody>
            {data?.map(dataItem => {
              const isItemSelected = selected.includes(dataItem.uid);
              return (
                <TableCell
                  checkbox={access_type !== Constants.READ_ONLY && checkbox}
                  dataItem={dataItem}
                  key={dataItem.uid}
                  isItemSelected={isItemSelected}
                  extraInfo={extraInfo}
                  extraDateInfo={extraDateInfo}
                  headings={headings}
                  handleSingleClick={handleSingleClick}
                  actionMenu={actionMenu}
                  actions={actions}
                  collapsibleComponent={collapsibleComponent}
                  isCheckboxDisabled={isCheckboxDisabled}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalCount && totalCount > limit && (
        <Pagination count={totalCount} limit={limit} />
      )}
    </TableWrap>
  );
}

TableView.defaultProps = {
  data: [{ uid: "" }],
  headings: [{ label: "", id: "" }],
  extraInfo: [{ label: "", id: "" }],
  extraDateInfo: [{ label: "", id: "" }],
  actionMenu: false,
  checkbox: false,
  isSorting: false,
  actions: [],
  limit: 10,
  isCheckboxDisabled: () => false,
};

TableView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
    }),
  ),
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  extraInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  extraDateInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  actionMenu: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string,
      icon: PropTypes.object,
      handler: PropTypes.func,
    }),
  ),
  checkbox: PropTypes.bool,
  handleSelectAllClick: PropTypes.func,
  selectedList: PropTypes.array,
  handleSingleClick: PropTypes.func,
  isSorting: PropTypes.bool,
  name: PropTypes.string.isRequired,
  multipleDeleteHandler: PropTypes.func,
  limit: PropTypes.number,
  totalCount: PropTypes.number,
  isCheckboxDisabled: PropTypes.func,
};
