import { handleError } from "../helper";
import { httpRequest } from "..";
import { showLoader } from "store/reducers/loaderSlice";

export const getSchema = ({ uid, loader = true, handleError = true }) => (
  dispatch,
  getState,
) => {
  const { sport_key, env_key } = getState().application;
  const httpObject = {
    url: `v1/custom_schema/admin/classes/${uid}`,
    data: {
      _method: "Get",
    },
    headers: {
      sport_key,
      env_key,
    },
  };
  return new Promise(function (resolve, reject) {
    if (loader) {
      dispatch(showLoader({ show: true }));
    }
    httpRequest(httpObject)
      .then(response => {
        dispatch(showLoader({ show: false }));
        resolve(response);
      })
      .catch(error => {
        dispatch(showLoader({ show: false }));
        if (!handleError) reject(error);
      });
  });
};

export const getObjectForm = ({ schema_uid, obj_uid, loader = true }) => (
  dispatch,
  getState,
) => {
  const { sport_key, env_key } = getState().application;
  const httpObject = {
    url: `/v1/custom_schema/admin/classes/${schema_uid}/objects/${obj_uid}`,
    data: {
      _method: "Get",
    },
    headers: {
      sport_key,
      env_key,
    },
  };
  return new Promise(function (resolve, reject) {
    if (loader) {
      dispatch(showLoader({ show: true }));
    }
    httpRequest(httpObject)
      .then(response => {
        dispatch(showLoader({ show: false }));
        resolve(response);
      })
      .catch(error => handleError(error, dispatch));
  });
};
