import { createRecord } from "services/createObject";
import { dobErrorHandler, dobValidation } from "utils/functions";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddPlayer = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const { team_name } = useSelector(state => state.application);
  const getURL = `/dashboard/${sport_key}/${env_key}/data/rosters/players/${team_name}`;

  const onSubmit = async data => {
    try {
      let form = data;
      /* istanbul ignore else */
      if (form.dob && typeof data.dob !== "string") {
        dobValidation({ data });
        form = {
          ...form,
          dob: format(data.dob, "yyyy-MM-dd"),
        };
      }
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/players",
          key: "player",
          form,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*  istanbul ignore next   */
      dobErrorHandler({ error, dispatch });
      console.log(error.message);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Players"
        breadCrumbItems={[
          {
            title: "Add Player",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="players"
        backUrl={getURL}
      />
    </>
  );
};

export default AddPlayer;
