const schema = {
  class: {
    title: "Events",
    uid: "events",
    _schema: [
      {
        uid: "name",
        display_name: "Name",
        data_type: "string",
        field_metadata: {
          additional_options: ["Mandatory"],
          multiline: false,
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
            {
              label: "Multiline",
              value: "multiline",
            },
          ],
        },
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "value",
        display_name: "Value",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "entry_value",
        display_name: "Entry Value",
        data_type: "number",
        field_metadata: {
          additional_options: ["Mandatory"],
          options: [
            {
              label: "Mandatory",
              value: "mandatory",
            },
            {
              label: "Multiple",
              value: "multiple",
            },
          ],
          multiline: false,
        },
        min: "0",
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "enabled",
        display_name: "Enabled",
        data_type: "boolean",
        field_metadata: {
          additional_options: [],
          options: [],
          multiline: false,
          multiple: false,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "inbuilt",
        display_name: "Inbuilt",
        data_type: "boolean",
        field_metadata: {
          additional_options: [],
          options: [],
          multiline: false,
          multiple: false,
          readOnly: true,
        },
        mandatory: false,
        multiple: false,
        _schema: [],
      },
    ],
  },
};
export default schema;
