import AddAdvPalyerStats from "./add";
import GetAdvPalyerStats from "app/pages/Dashboard/Data/Roster/AdvPlayerStats/get";
import UpdateAdvPalyerStats from "./update";

import { Route, Switch } from "react-router-dom";
import React from "react";

const AdvPlayersStats = props => {
  return (
    <>
      <Switch>
        <Route
          path={`${props.match.path}/add`}
          component={AddAdvPalyerStats}
          exact
        />
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdateAdvPalyerStats}
          exact
        />
        <Route path={props.match.path} component={GetAdvPalyerStats} />
      </Switch>
    </>
  );
};

export default AdvPlayersStats;
