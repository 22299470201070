import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const UpdateDropContent = props => {
  const [contentData, setContentData] = useState();
  const dispatch = useDispatch();
  const { sport_key, env_key, uid } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/drops/v1/content`;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = useCallback(async () => {
    const url = `/v1/admin/drops/portal/${uid}`;
    const {
      data: { drop },
    } = await dispatch(get({ url }));
    setContentData(drop);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const onSubmit = async data => {
    try {
      await dispatch(
        updateRecord({
          form: data,
          url: `/v1/admin/drops/portal/${uid}`,
          key: "drops_data",
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };
  return (
    <>
      <PanelHeader
        title="Update Drop Content"
        breadCrumbItems={[
          {
            title: "Update Drop Content",
          },
        ]}
        backUrl={getURL}
      />

      {contentData && (
        <SchemaForm
          onSubmit={onSubmit}
          defaultValues={contentData}
          schema={schema}
          modelName="drops"
          backUrl={getURL}
        />
      )}
    </>
  );
};

export default UpdateDropContent;
