import { createRecord } from "services/createObject";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddLeaderDetails = props => {
  const dispatch = useDispatch();
  const { team_name } = useSelector(state => state.application);
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/team/leader_details/${team_name}`;
  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/team_leader_details",
          key: "team_leader_detail",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Team Leader Details"
        breadCrumbItems={[
          {
            title: "Add Team Leader Details",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="team_leader_details"
        backUrl={getURL}
      />
    </>
  );
};

export default AddLeaderDetails;
