import { LoaderWrap } from "./index.style";
import { useSelector } from "react-redux";
import LoaderImg from "assets/DFEP-Loader.gif";
import React from "react";

const Loader = props => {
  const { show } = useSelector(state => state.loader);
  return (
    show && (
      <LoaderWrap>
        <img data-testid="loader-img" alt="DFE Loader" src={LoaderImg} />
      </LoaderWrap>
    )
  );
};

Loader.propTypes = {};

export default Loader;
