window.Buffer = window.Buffer || require("buffer").Buffer;
const crypto = require("crypto-browserify");
export const ENC_KEY = `${process.env.REACT_APP_ENC_KEY}`;
const CIPHER_ALGO_NAME = "aes-256-cbc";
const CIPHER_INPUT_ENCODING = "utf8";
const CIPHER_OUTPUT_ENCODING = "base64";

export const encryptPassword = function (
  password,
  iv = crypto.randomBytes(16),
) {
  try {
    const cipher = crypto.createCipheriv(CIPHER_ALGO_NAME, ENC_KEY, iv);
    const encrypted = cipher.update(
      password,
      CIPHER_INPUT_ENCODING,
      CIPHER_OUTPUT_ENCODING,
    );
    return {
      password: encrypted + cipher.final(CIPHER_OUTPUT_ENCODING),
      iv: iv.toString("hex"),
    };
  } catch (err) {
    throw err;
  }
};
