import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
const ActionBar = ({ actions, dataItem }) => {
  return (
    actions &&
    actions.map(
      (action, key) =>
        (action.hasOwnProperty("showAction")
          ? action.showAction(dataItem)
          : true) && (
          <Tooltip
            classes={{
              popper: "action-tooltip",
            }}
            key={action.id}
            title={action.label}
            arrow
          >
            <IconButton
              aria-label="expand row"
              size="small"
              // data-testid={`${dataItem.name}_iconBtn_${key}`}
              onClick={event => action.handler(event, dataItem.uid, dataItem)}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        ),
    )
  );
};
export default ActionBar;
