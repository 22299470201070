const schema = {
  class: {
    title: "Parking and Directions",
    uid: "parking_and_directions",
    _schema: [
      {
        display_name: "Version",
        uid: "version",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Media",
        uid: "media",
        mandatory: false,
        multiple: true,
        data_type: "file",
        field_metadata: {
          options: [
            { label: "Mandatory", value: "mandatory" },
            { label: "Multiple", value: "multiple" },
          ],
        },
      },
      {
        display_name: "Short Info",
        uid: "short_info",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Buy Parking",
        uid: "buy_parking",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Parking Lots",
        uid: "parking_lots",
        mandatory: false,
        multiple: true,
        data_type: "group",
        _schema: [
          {
            display_name: "Title",
            uid: "title",
            mandatory: true,
            multiple: false,
            data_type: "string",
            field_metadata: {},
          },
          {
            display_name: "Description",
            uid: "descp",
            mandatory: true,
            multiple: false,
            data_type: "rte",
            field_metadata: {},
          },
          {
            display_name: "Coordinate",
            uid: "coordinate",
            mandatory: false,
            multiple: false,
            data_type: "group",
            _schema: [
              {
                display_name: "Latitude",
                uid: "lat",
                mandatory: true,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
              {
                display_name: "Longitude",
                uid: "long",
                mandatory: true,
                multiple: false,
                data_type: "number",
                field_metadata: {},
              },
            ],
          },
          {
            display_name: "Media",
            uid: "media",
            mandatory: true,
            multiple: false,
            data_type: "file",
            field_metadata: {},
          },
          {
            display_name: "Enable Navigation",
            uid: "enable_navigation",
            mandatory: false,
            multiple: false,
            data_type: "boolean",
            field_metadata: {},
          },
          {
            display_name: "Custom Fields",
            uid: "custom_fields",
            mandatory: false,
            multiple: false,
            data_type: "mixed",
            field_metadata: {},
            _schema: [],
          },
        ],
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
