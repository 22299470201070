import styled from "styled-components";

export const Panel = styled.div`
  display: flex;
  padding: 15px;
  background: ${props => props.theme.palette.white.main};
  position: fixed;
  bottom: 28px;
  z-index: 1101;
  left: 0;
  width: 100%;
  border-top: 1px solid ${props => props.theme.palette.grey[300]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
  right: 0;
  justify-content: space-between;
  div:last-child {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    .MuiButton-root {
      margin-left: 15px;
    }
  }
  .MuiButton-root {
    font-weight: 600;
    min-width: 64px;
  }
  .MuiButton-contained.Mui-disabled {
    color: ${props => props.theme.palette.white.main};
    opacity: 0.5;
  }
`;
