import { createRecord } from "services/createObject";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
import schema from "../schema.js";
import SchemaForm from "app/components/Common/schemaForm";

const AddPlayByPlay = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const { team_name } = useSelector(state => state.application);
  const getURL = `/dashboard/${sport_key}/${env_key}/data/game/playByPlay/${team_name}`;

  const onSubmit = async data => {
    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/play_by_play",
          key: "play_by_play",
          form: data,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Play By Play"
        breadCrumbItems={[
          {
            title: "Add Play By Play",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="playByPlays"
        backUrl={getURL}
      />
    </>
  );
};

export default AddPlayByPlay;
