const schema = {
  class: {
    title: "Venues",
    uid: "venues",
    _schema: [
      {
        uid: "id",
        display_name: "Venue Id",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "name",
        display_name: "Name",
        data_type: "string",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [],
      },
      {
        uid: "show_user_location",
        display_name: "Show User Location",
        data_type: "boolean",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "show_traffic",
        display_name: "Show Traffic",
        data_type: "boolean",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "show_buildings",
        display_name: "Show Buildings",
        data_type: "boolean",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "allow_follow_modes",
        display_name: "Allow Follow Modes",
        data_type: "boolean",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "auto_level_switching_default",
        display_name: "Auto Level Switching Default",
        data_type: "boolean",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "selected_icon_multiplier",
        display_name: "Selected Icon Multiplier",
        data_type: "number",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "in_venue_threshold",
        display_name: "In Venue Threshold",
        data_type: "number",
        field_metadata: {},
        mandatory: false,
        multiple: false,
        _schema: [],
      },
      {
        uid: "center_coordinate",
        display_name: "Center Coordinate",
        data_type: "group",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [
          {
            uid: "latitude",
            display_name: "Latitude",
            data_type: "number",
            field_metadata: {
              additional_options: ["Mandatory"],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              multiline: false,
            },
            mandatory: true,
            multiple: false,
            _schema: [],
          },
          {
            uid: "longitude",
            display_name: "Longitude",
            data_type: "number",
            field_metadata: {
              additional_options: ["Mandatory"],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              multiline: false,
            },
            mandatory: true,
            multiple: false,
            _schema: [],
          },
        ],
      },
      {
        uid: "southwest_coordinate",
        display_name: "Southwest Coordinate",
        data_type: "group",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [
          {
            uid: "latitude",
            display_name: "Latitude",
            data_type: "number",
            field_metadata: {
              additional_options: ["Mandatory"],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              multiline: false,
            },
            mandatory: true,
            multiple: false,
            _schema: [],
          },
          {
            uid: "longitude",
            display_name: "Longitude",
            data_type: "number",
            field_metadata: {
              additional_options: ["Mandatory"],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              multiline: false,
            },
            mandatory: true,
            multiple: false,
            _schema: [],
          },
        ],
      },
      {
        uid: "northeast_coordinate",
        display_name: "Northeast Coordinate",
        data_type: "group",
        field_metadata: {},
        mandatory: true,
        multiple: false,
        _schema: [
          {
            uid: "latitude",
            display_name: "Latitude",
            data_type: "number",
            field_metadata: {
              additional_options: ["Mandatory"],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              multiline: false,
            },
            mandatory: true,
            multiple: false,
            _schema: [],
          },
          {
            uid: "longitude",
            display_name: "Longitude",
            data_type: "number",
            field_metadata: {
              additional_options: ["Mandatory"],
              options: [
                {
                  label: "Mandatory",
                  value: "mandatory",
                },
                {
                  label: "Multiple",
                  value: "multiple",
                },
              ],
              multiline: false,
            },
            mandatory: true,
            multiple: false,
            _schema: [],
          },
        ],
      },
    ],
  },
};
export default schema;
