import AddPlayerStats from "./add";
import GetPlayersStats from "app/pages/Dashboard/Data/Roster/PlayerStats/get";
import UpdatePlayerStats from "./update";

import { Route, Switch } from "react-router-dom";
import React from "react";

const PlayersStats = props => {
  return (
    <>
      <Switch>
        <Route
          path={`${props.match.path}/add`}
          component={AddPlayerStats}
          exact
        />
        <Route
          path={`${props.match.path}/update/:uid`}
          component={UpdatePlayerStats}
          exact
        />
        <Route path={props.match.path} component={GetPlayersStats} />
      </Switch>
    </>
  );
};

export default PlayersStats;
