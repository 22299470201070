import {
  BackLink,
  ButtonGroup,
  Panel,
  PanelActions,
  PanelHeaderWrap,
  PanelInner,
} from "./index.style";
import { Button } from "@dfep/ui-component";
import { IconButton, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import Breadcrumb from "../Breadcrums";
import CachedIcon from "@material-ui/icons/Cached";
import Icons from "../../../../assets/global_icons";
import React from "react";

import { blockedSearchArray, Constants } from "utils/constants";
import { useHistory } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Search from "../Search";
import Tooltip from "@material-ui/core/Tooltip";

export default function PanelHeader({
  className,
  backUrl,
  title,
  refreshHandler,
  sync,
  syncToCMS,
  hasSyncCMS,
  breadCrumbItems,
  children,
  search,
  ...restProps
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {
    location: { state, pathname },
  } = useHistory();

  const { access_type, name } = useSelector(state => state.application);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSyncToCMS = () => {
    syncToCMS();
    setOpen(false);
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleListKeyDown = event => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <Panel className={className} {...restProps}>
      {backUrl && (
        <BackLink
          to={state ? state.from.pathname + state.from.search : backUrl}
        >
          <Icons name="BackArrow" />
        </BackLink>
      )}
      <PanelHeaderWrap>
        <PanelInner>
          <Typography className="title" variant="h2">
            {title}
          </Typography>
          <PanelActions>
            <>
              {refreshHandler && (
                <IconButton
                  color="primary"
                  aria-label="Refresh"
                  onClick={refreshHandler}
                  data-testid="component-refresh"
                >
                  <Tooltip title="Refresh" arrow>
                    <CachedIcon />
                  </Tooltip>
                </IconButton>
              )}

              {search &&
                !blockedSearchArray.includes(
                  pathname.split("/").slice(-1)[0],
                ) && <Search />}

              {access_type !== Constants.READ_ONLY && sync && (
                <>
                  <ButtonGroup
                    className="split-button"
                    color="primary"
                    variant="outlined"
                    ref={anchorRef}
                  >
                    <Button data-testid="sync-btn" onClick={sync}>
                      Sync
                    </Button>
                    {hasSyncCMS && (
                      <Button
                        className="arrow-btn"
                        color="primary"
                        variant="outlined"
                        size="small"
                        aria-haspopup="menu"
                        data-testid="menu-open"
                        onClick={handleToggle}
                      >
                        <ExpandMoreOutlinedIcon />
                      </Button>
                    )}
                  </ButtonGroup>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              id="split-button-menu"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleSyncToCMS}>
                                Sync To CMS
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </>
            {children}
          </PanelActions>
        </PanelInner>
        {breadCrumbItems && (
          <Breadcrumb name={name} breadCrumbItems={breadCrumbItems} />
        )}
      </PanelHeaderWrap>
    </Panel>
  );
}

PanelHeader.propTypes = {};
