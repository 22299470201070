import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

import { Container as ForgotPassword } from "./forgotPassword";
import { Container as Login } from "./login";
import { NotFoundPage } from "app/components/NotFoundPage/Loadable";
import { Container as Register } from "./register";
import { Container as ResetPassword } from "./resetPassword";

const Auth = props => {
  return (
    <Switch>
      <Redirect from="/auth" to="/auth/login" exact />
      <Route path={`${props.match.path}/login`} component={Login} exact />
      <Route
        path={`${props.match.path}/forgot-password`}
        component={ForgotPassword}
        exact
      />
      <Route
        path={`${props.match.path}/reset-password/:reset_token`}
        component={ResetPassword}
        exact
      />
      <Route
        path={`${props.match.path}/register/:account_token`}
        component={Register}
        exact
      />
      <Route component={NotFoundPage} exact />
    </Switch>
  );
};

Auth.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
    path: PropTypes.string,
  }),
};

export default Auth;
