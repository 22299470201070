import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddSettings = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/device/setting`;

  const onSubmit = async data => {
    const form = {
      ...data,
      device_type: "ios",
    };
    /* istanbul ignore next */
    form?.menus?.forEach((menu, menuIndex) => {
      menu?.items?.forEach((item, itemIndex) => {
        /* istanbul ignore else */
        if (!item?.display) {
          item.display = "enabled";
        }
        /* istanbul ignore else */
        if (!item?.item_type) {
          delete item.item_type;
        }
      });
    });

    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/app/setting",
          key: "setting",
          form,
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Settings"
        breadCrumbItems={[
          {
            title: "Add Settings",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="app_settings"
        backUrl={getURL}
      />
    </>
  );
};

export default AddSettings;
