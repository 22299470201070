import MuiCollapse from "@material-ui/core/Collapse";
import MuiPaper from "@material-ui/core/Paper";
import MuiTypography from "@material-ui/core/Typography";
import styled from "styled-components";

export const TableWrap = styled(MuiPaper)`
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  border-radius: 4px;
  span {
    &.name-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    &.img-container {
      display: flex;
      align-items: center;
      padding: 5px 0;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &.file-name {
      padding-left: 10px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
    &.date-container {
      display: flex;
      flex-direction: column;
    }
    &.date {
      font-size: 14px;
      line-height: 21px;
    }
    &.time {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
      line-height: 16px;
    }
  }
`;

export const StyledTypography = styled(MuiTypography)`
  &.menu-data {
    padding-left: 1rem;
  }
`;

export const Collapse = styled(MuiCollapse)`
  &.MuiCollapse-container {
    margin-left: -19px;
    margin-right: -16px;
    cursor: default;
  }
  .MuiCollapse-wrapper {
    background: #f7f9fc;
    text-align: left;
    padding: 17px 0;
    .MuiTypography-root {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .labelTitle {
      color: rgba(0, 0, 0, 0.54);
      font-weight: 400;
    }
    .date {
      font-size: 13px;
    }
  }
`;
