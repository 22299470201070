import { Controller } from "react-hook-form";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import get from "lodash/get";
import has from "lodash/has";
import React from "react";

const DatePicker = ({
  fieldName,
  control,
  validations,
  title,
  ampm,
  format,
  className,
  defaultValue,
  uid,
  readOnly,
}) => {
  return (
    <Controller
      key={uid}
      name={fieldName}
      control={control}
      rules={validations}
      render={({ field, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, fieldName);
        const fieldError = get(errors, fieldName);
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              className="datetime-picker"
              inputVariant="outlined"
              allowKeyboardControl={false}
              label={title}
              ampm={ampm}
              value={field.value}
              helperText={hasFieldError && fieldError.message}
              onChange={field.onChange}
              error={hasFieldError}
              inputRef={field.ref}
              disablePast
              format={format}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EventRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        );
      }}
    />
  );
};

export default DatePicker;
