import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize, initialRoute } from "utils/functions";
import { Constants } from "utils/constants";
import { getSchedule } from "services/getObjects";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import GetSchedules from "app/pages/Dashboard/Data/Team/Schedules/get/GetSchedules";
import PanelHeader from "app/components/Common/PanelHeader";
import Settings from "./settings";

const SchedulesTabs = props => {
  const active = () => {
    if (props.location.pathname === `${props.match.url}/others`) {
      return 1;
    } else if (props.location.pathname === `${props.match.url}/settings`) {
      return 2;
    } else {
      return 0;
    }
  };

  const { tid, team_name, access_type } = useSelector(
    state => state.application,
  );

  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;

  const activeTab = active();
  const [tabValue, setValue] = useState(activeTab);
  /* istanbul ignore else */
  if (activeTab !== undefined && activeTab !== tabValue) {
    setValue(activeTab);
  }

  const breadCrumbItems = [
    {
      title: "Schedules",
    },
  ];

  const [data, setData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { sort, value, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();

  const getSchedules = useCallback(
    async teamName => {
      setIsLoading(true);
      const {
        data: { schedules, count },
      } = await dispatch(
        getSchedule({
          team_name: teamName,
          tid,
          skip,
          limit,
          sort,
          sortValue: value,
          searchText,
        }),
      );

      if (count !== 0 && schedules.length === 0) {
        initialRoute(props.history);
      } else {
        setTotalDataCount(count);
        setData(schedules);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tid, skip, limit, sort, value, searchText],
  );

  const syncModalOpen = () => {
    openModalHandler("show");
  };

  const syncDetails = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/schedule" }));
    closeModalHandler();
  };

  const syncToCMS = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/cms_schedules" }));
  };

  return (
    <>
      <PanelHeader
        title="Schedules"
        breadCrumbItems={breadCrumbItems}
        refreshHandler={() =>
          tabValue ? getSchedules("others") : getSchedules(team_name)
        }
        sync={syncModalOpen}
        hasSyncCMS
        syncToCMS={syncToCMS}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="add-btn"
            startIcon={<AddIcon />}
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/team/schedules/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${props.match.url}/${team_name}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${props.match.url}/others`}
            {...a11yProps(1)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(2)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/${team_name}`}
            exact
          />

          <Route
            path={`${props.match.path}/settings`}
            component={Settings}
            exact
          />
          <Route
            path={`${props.match.path}/:team_name`}
            /*  istanbul ignore next   */
            render={routeProps => {
              /*  istanbul ignore next   */
              return (
                <GetSchedules
                  getSchedules={getSchedules}
                  data={data}
                  totalDataCount={totalDataCount}
                  isLoading={isLoading}
                  dispatch={dispatch}
                  openSyncModal={show}
                  syncModalClose={closeModalHandler}
                  syncDetails={syncDetails}
                  access_type={access_type}
                  {...routeProps}
                />
              );
            }}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
};

export default SchedulesTabs;
