import { Redirect, Route, Switch } from "react-router-dom";
import AddFeeds from "app/pages/Dashboard/Data/Wsc/Feeds/add";
import Feeds from "app/pages/Dashboard/Data/Wsc/Feeds/get";
import React from "react";
import UpdateFeeds from "app/pages/Dashboard/Data/Wsc/Feeds/update";

const Wsc = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/feeds`}
        exact
      />
      <Route
        path={`${props.match.path}/feeds/add`}
        component={AddFeeds}
        exact
      />

      <Route
        path={`${props.match.path}/feeds/update/:uid`}
        component={UpdateFeeds}
        exact
      />
      <Route path={`${props.match.path}/feeds`} component={Feeds} />
    </Switch>
  );
};

export default Wsc;
