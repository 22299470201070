import { get } from "services/getObject";
import { updateRecord } from "services/updateObject";
import { useDispatch } from "react-redux";
import BroadcastForm from "../BroadcastTopicForm";
import PanelHeader from "app/components/Common/PanelHeader";

import React, { useEffect, useState } from "react";

function BroadcastTopicUpdate({ history, match, location, ...restporps }) {
  const [broadcastData, setBroadcastData] = useState();
  const { sport_key, env_key, uid: broadcastTopicId } = match.params;
  const { state } = location;

  const dispatch = useDispatch();

  const getDataById = async () => {
    const { data } = await dispatch(
      get({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics/${broadcastTopicId}`,
      }),
    );
    setBroadcastData(data);
  };

  const upadteDataById = async form => {
    const data = await dispatch(
      updateRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics/${broadcastTopicId}`,
        key: "topic",
        form,
      }),
    );
    /* istanbul ignore else */
    if (data.status === 200) {
      history.push(`/dashboard/${sport_key}/${env_key}/broadcast-topics`);
    }
  };

  const cancelFormHandler = async () => {
    history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/broadcast-topics`,
    );
  };
  useEffect(() => {
    getDataById();
    return () => {
      setBroadcastData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PanelHeader
        title="Update Topic"
        backUrl={`/dashboard/${sport_key}/${env_key}/broadcast-topics`}
        breadCrumbItems={[
          {
            title: "Broadcast Topics",
          },
        ]}
      />
      {broadcastData && (
        <BroadcastForm
          broadcastData={broadcastData.topic}
          apiCallFunction={upadteDataById}
          cancelForm={cancelFormHandler}
        />
      )}
    </>
  );
}

export default BroadcastTopicUpdate;
