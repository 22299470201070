import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Container as DevicesData } from "app/pages/Dashboard/Reports/devicesData/get/devicesData";
import { getInstallationData } from "services/getObjects/index";
import { initialRoute } from "utils/functions";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQueryParams } from "app/hooks";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";

const DevicesDataIndex = props => {
  let tabObj = { android: 0, ios: 1 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  // get api call
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const { sort, value, skip, limit, searchText } = useQueryParams();

  const getData = useCallback(
    async device_type => {
      try {
        setIsLoading(true);
        const {
          data: { installation_data, count },
        } = await dispatch(
          getInstallationData({
            device_type,
            skip,
            limit,
            sort,
            sortValue: value,
            searchText,
          }),
        );

        if (count !== 0 && installation_data.length === 0) {
          initialRoute(props.history);
        } else {
          setTotalDataCount(count);
          setData(installation_data);
          setIsLoading(false);
        }
      } catch (err) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, value, skip, limit, searchText],
  );

  return (
    <>
      <PanelHeader
        title="Devices Data"
        breadCrumbItems={[
          {
            title: "Reports",
          },
        ]}
        refreshHandler={() => {
          getData(activeTab === 0 ? "android" : "ios");
        }}
        search={true}
      />

      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label="Android"
            component={Link}
            to={`${props.match.url}/android`}
            {...a11yProps(0)}
          />
          <Tab
            label="IOS"
            component={Link}
            to={`${props.match.url}/ios`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/android`}
            exact
          />
          <Route
            path={`${props.match.path}/:device_type`}
            render={routeProps => (
              <DevicesData
                {...routeProps}
                getInstallationData={getData}
                data={data}
                isLoading={isLoading}
                totalDataCount={totalDataCount}
              />
            )}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
};

export default DevicesDataIndex;
