import { AuthContainer, AuthFormWrap, FooterInfo } from "../authGlobal.style";
import { authServices } from "services/auth";
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@material-ui/core";
import { Button } from "@dfep/ui-component";
import { connect } from "react-redux";
import { Constants } from "utils/constants";
import { encryptPassword } from "utils/crypto";
import { Link } from "react-router-dom";
import { setError, validateEmail } from "utils/functions";
import { setKey } from "utils/cookies";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import Checkbox from "@material-ui/core/Checkbox";
import cloneDeep from "lodash/cloneDeep";
import footerLogo from "assets/raweng-logo.svg";
import loginLogo from "assets/DFEP-login-logo.svg";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TextField from "app/components/Common/TextField";
import useInput from "app/components/Common/customHooks/useInput";

const initialErrorState = {
  email: {
    hasError: false,
    message: "",
  },
  password: {
    hasError: false,
    message: "",
  },
};

const Login = props => {
  const [email, bindEmail] = useInput("");
  // const [password, bindPassword] = useInput("");
  const [password, bindPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputError, setInputError] = useState(cloneDeep(initialErrorState));

  // This useEffect is call when email/password is changed
  useEffect(() => {
    setInputError(cloneDeep(initialErrorState));
  }, [email, password]);

  // Login API Call on Form Submit
  const authenticate = async e => {
    e.preventDefault();
    try {
      const form = await formValidation();
      props
        .loginService({
          url: "/v1/admin/auth/login",
          form,
          showToast: true,
        })
        .then(result => {
          setKey(Constants.ACCESS_TOKEN, result.data.tokens.access_token);
          setKey(Constants.REFRESH_TOKEN, result.data.tokens.refresh_token);
          props.history.push("/dashboard");
        });
      /* loginService().then(result => {
        setKey(Constants.ACCESS_TOKEN, result.data.tokens.access_token);
        setKey(Constants.REFRESH_TOKEN, result.data.tokens.refresh_token);
        props.history.push("/dashboard");
      }); */
    } catch (error) {}
  };

  // Form Validation for Email and Password
  const formValidation = () => {
    return new Promise((resolve, reject) => {
      if (!email) {
        const error = setError(inputError, "email", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Email field is required" });
      } else if (!validateEmail(email)) {
        const error = setError(inputError, "email", "Invalid Email Address!");
        setInputError(cloneDeep(error));
        reject({ error: "Invalid Email Address!" });
      } else if (!password) {
        const error = setError(inputError, "password", "Field is Required!");
        setInputError(cloneDeep(error));
        reject({ error: "Password field is required" });
      } else {
        const form = { email, ...encryptPassword(password) };
        resolve(form);
      }
    });
  };

  return (
    <React.Fragment>
      <AuthContainer>
        <AuthFormWrap>
          <img alt="logo" className="login-logo" src={loginLogo} />
          <Paper className="auth-form">
            <Typography variant="h6" component="h1">
              Login to your account
            </Typography>
            <form data-testid="login-form" noValidate onSubmit={authenticate}>
              <TextField
                label="Email"
                id="email"
                type="email"
                required={true}
                error={inputError.email.hasError}
                helperText={inputError.email.message}
                {...bindEmail}
              />
              <TextField
                label="Password"
                id="password"
                type={showPassword ? "text" : "password"}
                error={inputError.password.hasError}
                helperText={inputError.password.message}
                onChange={e => bindPassword(e.target.value)}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="password-eye-toggle"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="field-wrap link-btn">
                <Box
                  // justifyContent="space-between"
                  justifyContent="flex-end"
                  alignItems="center"
                  display="flex"
                >
                  {/* <FormControlLabel
                    label="Remember me"
                    control={<Checkbox checked="" value="" name="" />}
                  /> */}
                  <Link to="/auth/forgot-password">
                    <Button color="primary">Forgot Password?</Button>
                  </Link>
                </Box>
              </div>

              <div className="field-wrap">
                <Button type="submit" variant="contained" color="primary">
                  Login
                </Button>
              </div>
            </form>
            {/* inside login box footerLogo  */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className="info"
            >
              {/* <p>Powered by:</p> */}
              <img alt="logo" className="footer-logo" src={footerLogo} />
            </Box>
          </Paper>
        </AuthFormWrap>
        <FooterInfo>
          <Typography variant="body1" component="h6">
            © 2021 DFEP. All rights reserved.
          </Typography>
        </FooterInfo>
      </AuthContainer>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    loginService: payload => dispatch(authServices(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Login);

export { Container, Login as Component };
