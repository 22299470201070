import { Container as GetEula } from "app/pages/Dashboard/Data/Device/Eula/get";
import { Route, Switch } from "react-router-dom";
import AddEula from "app/pages/Dashboard/Data/Device/Eula/add";
import React from "react";
import UpdateEula from "app/pages/Dashboard/Data/Device/Eula/update";

const Eula = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddEula} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateEula}
        exact
      />
      <Route path={`${props.match.path}`} component={GetEula} />
    </Switch>
  );
};

export default Eula;
