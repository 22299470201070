const schema = {
  class: {
    title: "Polygon",
    uid: "polygon",
    _schema: [
      {
        display_name: "Name",
        uid: "name",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Push Notification Tag",
        uid: "push_notification_tag",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Priority",
        uid: "priority",
        mandatory: false,
        multiple: false,
        data_type: "number",
        min: 0,
        field_metadata: {},
      },
    ],
  },
};

export default schema;
