export const customButtonList = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["fontColor", "hiliteColor", "textStyle"],
  ["outdent", "indent"],
  ["paragraphStyle", "blockquote"],
  ["align", "horizontalRule", "list", "lineHeight"],
  ["table", "link", "image"],
  ["fullScreen", "showBlocks", "codeView"],
];
