import { createRecord } from "services/createObject";
import { TeamStandingMapperObject } from "utils/constants";
import { useDispatch } from "react-redux";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import schema from "../schema";
import SchemaForm from "app/components/Common/schemaForm";

const AddStandings = props => {
  const dispatch = useDispatch();
  const { sport_key, env_key } = props.match.params;
  const getURL = `/dashboard/${sport_key}/${env_key}/data/team/standing/heat`;

  const onSubmit = async data => {
    const submitObj = {};

    //<------
    //destructuring data on single level
    for (const property in TeamStandingMapperObject) {
      if (property in data) {
        for (const key in data[property]) {
          submitObj[key] = data[property][key];
        }
        delete data[property];
      }
    }
    //----->

    try {
      await dispatch(
        createRecord({
          url: "/v1/nba/admin/data/team_standing",
          key: "team_standing",
          form: { ...submitObj, ...data },
        }),
      );
      props.history.push(getURL);
    } catch (error) {
      /*istanbul ignore next*/
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader
        title="Add Standing"
        breadCrumbItems={[
          {
            title: "Add Standing",
          },
        ]}
        backUrl={getURL}
      />
      <SchemaForm
        onSubmit={onSubmit}
        schema={schema}
        modelName="team_standings"
        backUrl={getURL}
      />
    </>
  );
};

export default AddStandings;
