import { get } from "services/getObject";
import { getGoogleMapApiKey } from "services/getObjects";
import { GOOGLE_MAPS, googleMapsLibrariesToImport } from "utils/constants";
import { handleError } from "services/helper";
import { removeGoogleMapsPackage } from "utils/functions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Map from "./Map";
import React, { useCallback, useEffect, useState } from "react";

const modulesToBeRemoved = [GOOGLE_MAPS.JS_API_LOADER];

function GeoMap(props) {
  const [reactWrapperComponents, setRWComponent] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [googleMapApiKey, setGoogleMapApiKey] = useState(null);
  const [mapLoaderInstance, setMapLoaderInstance] = useState({});
  const [mapLoaderStatus, setMapLoaderStatus] = useState(null);
  const [geoJSONData, setGeoJSONData] = useState();

  const [libraryLoaded, setLibraryLoaded] = useState(false);

  const { uid } = props.match.params;
  const { sport_key, env_key } = useSelector(state => state.application);

  const dispatch = useDispatch();

  const callBack = useCallback((status, loader) => {
    setMapLoaderStatus(status);
    setMapLoaderInstance(loader);
  }, []);

  useEffect(() => {
    import("@googlemaps/react-wrapper").then(setRWComponent);
  }, []);

  const importMapLibraries = () => {
    if (!libraryLoaded && mapLoaderInstance && mapLoaderStatus === "SUCCESS") {
      const libraryPromises = googleMapsLibrariesToImport.map(library =>
        mapLoaderInstance.importLibrary(library),
      );

      Promise.all(libraryPromises)
        .then(() => {
          setLibraryLoaded(true);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    importMapLibraries();

    return () => {
      removeGoogleMapsPackage({
        mapLoaderInstance,
        mapLoaderStatus,
        modulesToBeRemoved,
      });
      setLibraryLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaderInstance, mapLoaderStatus]);

  const getInitialData = useCallback(async () => {
    try {
      setShowLoader(true);
      const urlQueryParams = new URLSearchParams();
      urlQueryParams.append("device", "ios");
      urlQueryParams.append("version", process.env.REACT_APP_GRAPHQL_VERSION);
      urlQueryParams.append("Content-Type", "application/json");
      urlQueryParams.append("sport_key", sport_key);
      urlQueryParams.append("env_key", env_key);
      urlQueryParams.append("query", "{config{integrations}}");

      const {
        data: {
          data: {
            config: { integrations },
          },
        },
      } = await dispatch(
        getGoogleMapApiKey({
          url: `/v1/app/graphql?${urlQueryParams.toString()}`,
        }),
      );
      /* istanbul ignore else */
      if (!Object.keys(integrations).includes("geo_fencing"))
        throw new Error("Can not find geo_fencing integration");

      const { google_api_key } = integrations["geo_fencing"];

      urlQueryParams.delete("query");

      setGoogleMapApiKey(google_api_key);

      setShowLoader(false);
    } catch (error) {
      const err = {
        response: {
          data: {
            error_message: error.message,
          },
        },
      };
      handleError(err, dispatch);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key]);

  useEffect(() => {
    if (
      !sport_key ||
      !env_key ||
      sport_key.length === 0 ||
      env_key.length === 0
    ) {
      const err = {
        response: {
          data: {
            error_message: "Can not find valid sport_key or env_key",
          },
        },
      };
      handleError(err, dispatch);
    } else getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport_key, env_key, getInitialData]);

  useEffect(() => {
    if (uid) {
      // Only fetch GeoJSON data if uid is present (update mode)
      getGeoJSONData();
    } else {
      // If no uid (add mode), set showLoader to false
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const getGeoJSONData = useCallback(async () => {
    if (uid) {
      const url = `/v1/admin/geo_fencing/geo_location/${uid}`;
      try {
        const {
          data: { geo_location },
        } = await dispatch(get({ url }));
        setGeoJSONData(geo_location);
        // After setting geoJSONData, hide the loader
        setShowLoader(false);
      } catch (error) {
        // Handle error here
        console.error("Error fetching GeoJSON data", error);
        // If there's an error, hide the loader
        setShowLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const zoom = 8;

  const render = status => {
    switch (status) {
      case reactWrapperComponents.Status.LOADING:
        return <Loader />;
      case reactWrapperComponents.Status.FAILURE:
        return <Loader />;
      case reactWrapperComponents.Status.SUCCESS:
        if (uid) {
          // In update mode (uid is present), render the Map component
          if (geoJSONData && libraryLoaded) {
            return (
              <Map
                zoom={zoom}
                dispatch={dispatch}
                sport_key={sport_key}
                env_key={env_key}
                geoJSON={geoJSONData}
                {...props}
              />
            );
          } else {
            // Wait for geoJSONData to be available in update mode
            return <Loader />;
          }
        } else {
          // In add mode (uid is not present), render the Map component immediately
          return (
            libraryLoaded && (
              <Map
                zoom={zoom}
                dispatch={dispatch}
                sport_key={sport_key}
                env_key={env_key}
                {...props}
              />
            )
          );
        }
      default:
        return <Loader />;
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      {googleMapApiKey && reactWrapperComponents && (
        <reactWrapperComponents.Wrapper
          apiKey={googleMapApiKey}
          // libraries={googleMapsLibrariesToImport}
          render={render}
          callback={callBack}
        />
      )}
    </>
  );
}

export default GeoMap;
