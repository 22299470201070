import { cloneDeep } from "lodash";
import { Form_Mode } from "utils/constants";
import {
  getInteractiveMapCategories,
  getInteractiveMapVenues,
} from "services/getObjects";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import schema from "./schema";
import SchemaForm from "app/components/Common/schemaForm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Form = ({ onSubmit, modelName, backUrl, props, defaultValues, mode }) => {
  // Params
  const { sport_key, env_key } = props.match.params;

  // Redux Hooks
  const dispatch = useDispatch();

  // State
  const [schemaState, setSchemaState] = useState();
  const [options, setOptions] = useState([]);
  const [values, setDefaultValues] = useState();
  const [venueOptions, setVenueOptions] = useState([]);

  // drop-down options for child_categories field
  const getChildCategoryOptions = categories => {
    const opts = [];
    categories.forEach((item, i) => {
      opts.push({
        title: item.display_name,
        id: item.id,
      });
    });

    const options =
      mode === "update"
        ? opts.filter(obj => obj.id !== defaultValues.id)
        : opts;

    setOptions(options);
  };

  // Get Categories API Call
  const getCategories = async () => {
    const {
      data: { categories },
    } = await dispatch(
      getInteractiveMapCategories({
        sport_key,
        env_key,
      }),
    );
    getChildCategoryOptions(categories);
  };

  const getVenues = async () => {
    const {
      data: { venues },
    } = await dispatch(
      getInteractiveMapVenues({
        sport_key,
        env_key,
      }),
    );

    const opts = venues.map(venue => {
      return { label: venue.name, value: venue.id };
    });
    setVenueOptions(opts);
  };

  // Component Life Cycle
  useEffect(() => {
    getCategories();
    getVenues();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //multi select child_categories
  const multiSelectProps = {
    multiple: true,
    freeSolo: false,
    getOptionLabel: option => option.title,
    options: options,
    open: true,
    filterSelectedOptions: false,
    disableCloseOnSelect: true,
    getOptionSelected: (option, value) => option.id === value.id,
    renderOption: (option, { selected }) => {
      return (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </>
      );
    },
  };

  // Component Life Cycle
  useEffect(() => {
    if (options.length && venueOptions.length) {
      const Schema = cloneDeep(schema);

      Schema.class._schema.forEach(obj => {
        if (obj.uid === "child_categories") {
          Object.assign(obj, { ...multiSelectProps });
        }
        if (
          obj.uid === "venue_id" &&
          venueOptions.length &&
          obj.field_metadata.options.length <= 1
        ) {
          obj.field_metadata.options.push(...venueOptions);
        }
      });

      //Category Id Field not to be visible in Add Form
      if (mode === Form_Mode.ADD) {
        Schema.class._schema.splice(
          Schema.class._schema.findIndex(item => item.uid === "id"),
          1,
        );
      }

      setSchemaState(Schema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, venueOptions]);

  useEffect(() => {
    restructureData(defaultValues);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // restructureData for update
  const restructureData = defaultValues => {
    let arr = [],
      values = cloneDeep(defaultValues);

    for (const prop in values) {
      if (["child_categories"].includes(prop)) {
        values.child_categories.forEach(id => {
          options.forEach(obj => {
            if (id === obj.id) {
              arr.push({
                title: obj.title,
                id: obj.id,
              });
            }
          });
        });
        if (
          (arr.length && values.child_categories.length) ||
          values.child_categories.length === 0
        ) {
          values["child_categories"] = arr;
          setDefaultValues(values);
        }
      }
    }
  };
  //

  return (
    <>
      {values && mode === Form_Mode.UPDATE && schemaState && (
        <SchemaForm
          onSubmit={onSubmit}
          schema={schemaState}
          modelName={modelName} //"categories"
          backUrl={backUrl}
          defaultValues={values}
        />
      )}
      {mode === Form_Mode.ADD && schemaState && (
        <SchemaForm
          onSubmit={onSubmit}
          schema={schemaState}
          modelName={modelName} //"categories"
          backUrl={backUrl}
        />
      )}
    </>
  );
};

export default Form;
