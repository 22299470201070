import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { connect, useDispatch } from "react-redux";
import { Constants } from "utils/constants";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { getOnBoardScreen } from "services/getObjects";
import { initialRoute, saveSportsEnvKey } from "utils/functions";
import { useQueryParams } from "app/hooks";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "./ScreenTableView";

const intialModalState = {
  delModal: false,
  multiDelModal: false,
};

const Screen = props => {
  const { refresh, setRefresh, access_type } = props;

  const [data, setData] = useState([]);
  const [uid, setUid] = useState("");
  const [deleteDataObj, setDeleteDataObj] = useState({});
  const { sport_key, env_key } = props.match.params;
  const [modal, setOpenModal] = useState(intialModalState);
  const [selected, setSelected] = useState([]);
  const [screenCount, setScreenCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const { sort, value, skip, limit, searchText } = useQueryParams();

  const getData = useCallback(
    async () => {
      setSelected([]);
      const {
        data: { count, onboarding_screens },
      } = await props.getScreens({
        skip,
        limit,
        sort,
        sortValue: value,
        searchText,
      });

      if (count !== 0 && onboarding_screens.length === 0) {
        initialRoute(props.history);
      } else {
        setData(onboarding_screens);
        setScreenCount(count);
        setRefresh(false);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skip, limit, sort, value, searchText],
  );

  const handleModalDelete = () => {
    const url = `/v1/nba/admin/app/onboard_screen/${uid}`;
    props.deleteScreen({ url }).then(res => {
      setIsLoading(true);
      setOpenModal(intialModalState);
      setUid("");
      getData(props);
    });
  };

  const multiDeleteReq = () => {
    const url = `/v1/nba/admin/app/onboard_screen`;
    props.deleteMultiScreen({ url, uids: selected }).then(res => {
      setIsLoading(true);
      setOpenModal(intialModalState);
      setSelected([]);
      getData(props);
    });
  };

  useEffect(() => {
    saveSportsEnvKey({ dispatch, params: props.match.params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refresh === false) {
      setIsLoading(true);
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    if (refresh === true) {
      setIsLoading(true);
      getData(props);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const UpdateHandler = (event, uid) => {
    props.history.push({
      pathname: `/dashboard/${sport_key}/${env_key}/data/device/onBoarding/screens/update/${uid}`,
      state: { from: props.location },
    });
  };

  const multipleDeleteHandler = () => {
    setOpenModal({ ...modal, multiDelModal: true });
  };

  const handleModalClose = () => {
    setUid("");
    setOpenModal(intialModalState);
  };

  const handleSingleClick = (event, dataId) => {
    const newSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    const newSelecteds = checkboxSelectAllClick({ event, data });
    setSelected(newSelecteds);
  };

  const headings = [
    { label: "Name", id: "title" },
    { label: "Created At", id: "created_at", type: "date" },
    { label: "Updated At", id: "updated_at", type: "date" },
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditRoundedIcon fontSize="small" data-testid="update-btn" />,
      handler: UpdateHandler,
    },

    {
      id: "delete",
      label: "Delete",
      icon: <DeleteRoundedIcon fontSize="small" data-testid="delete-btn" />,
      handler: (event, uid, dataItem) => {
        setUid(uid);
        setDeleteDataObj(dataItem);
        setOpenModal({ ...modal, delModal: true });
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      <TableView
        refresh={refresh}
        isLoading={isLoading}
        data={data}
        headings={headings}
        actions={actions}
        checkbox={true}
        handleModalDelete={handleModalDelete}
        handleModalClose={handleModalClose}
        openModal={modal}
        name="Onboarding Screens"
        selectedList={selected}
        handleSingleClick={handleSingleClick}
        handleSelectAllClick={handleSelectAllClick}
        numSelected={selected.length}
        totalCount={screenCount}
        multipleDeleteHandler={multipleDeleteHandler}
        multiDeleteReq={multiDeleteReq}
        deleteDataObj={deleteDataObj}
      />
    </>
  );
};
/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    getScreens: payload => dispatch(getOnBoardScreen(payload)),
    deleteScreen: payload => dispatch(deleteRecord(payload)),
    deleteMultiScreen: payload => dispatch(deleteRecords(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(Screen);

export { Container, Screen as Component };
