import { getYearArray } from "utils/functions";

const schema = {
  class: {
    title: "Players",
    uid: "players",
    _schema: [
      {
        display_name: "Year",
        uid: "year",
        mandatory: true,
        multiple: false,
        data_type: "number",
        field_metadata: {
          select: true,
          options: [{ label: "Select", value: "" }, ...getYearArray()],
        },
      },
      {
        display_name: "League ID",
        uid: "league_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Season ID",
        uid: "season_id",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Player ID",
        uid: "pid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team ID",
        uid: "tid",
        mandatory: true,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Player Code",
        uid: "pc",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "First Name",
        uid: "fn",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Last Name",
        uid: "ln",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Jersey Number",
        uid: "num",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Position",
        uid: "pos",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Date of birth",
        uid: "dob",
        mandatory: false,
        multiple: false,
        data_type: "date",
        field_metadata: {
          timeFormat: false,
        },
      },
      {
        display_name: "Height",
        uid: "ht",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Weight",
        uid: "wt",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Years Experience",
        uid: "y",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "School Name",
        uid: "sn",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Type of School",
        uid: "ty",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team Abbreviation",
        uid: "ta",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Team City",
        uid: "tc",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Country",
        uid: "co",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Prior to NBA",
        uid: "la",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Draft Year",
        uid: "dy",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Free Agent",
        uid: "fa",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Status",
        uid: "s",
        mandatory: false,
        multiple: false,
        data_type: "string",
        field_metadata: {},
      },
      {
        display_name: "Headshot Image",
        uid: "headshot_image_url",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Action Image",
        uid: "action_image_url",
        mandatory: false,
        multiple: false,
        data_type: "file",
        field_metadata: {},
      },
      {
        display_name: "Bio",
        uid: "bio",
        mandatory: false,
        multiple: false,
        data_type: "rte",
        field_metadata: {},
      },
      {
        display_name: "Order",
        uid: "order",
        mandatory: false,
        multiple: false,
        data_type: "number",
        field_metadata: {},
      },
      {
        display_name: "Hide this Player",
        uid: "hide",
        mandatory: false,
        multiple: false,
        data_type: "boolean",
        field_metadata: {},
      },
      {
        display_name: "Custom Fields",
        uid: "custom_fields",
        mandatory: false,
        multiple: false,
        data_type: "mixed",
        field_metadata: {},
        _schema: [],
      },
    ],
  },
};

export default schema;
