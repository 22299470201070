import { cloneDeep } from "lodash";
import { Form_Mode } from "utils/constants";
import { getInteractiveMapVenues } from "services/getObjects";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import schema from "./schema";
import SchemaForm from "app/components/Common/schemaForm";

const Form = ({ onSubmit, modelName, backUrl, props, defaultValues, mode }) => {
  // Params
  const { sport_key, env_key } = props.match.params;

  // Redux Hooks
  const dispatch = useDispatch();

  // State
  const [schemaState, setSchemaState] = useState();
  const [venueOptions, setVenueOptions] = useState([]);

  const getVenues = async () => {
    const {
      data: { venues },
    } = await dispatch(
      getInteractiveMapVenues({
        sport_key,
        env_key,
      }),
    );

    const opts = venues.map(venue => {
      return { label: venue.name, value: venue.id };
    });
    setVenueOptions(opts);
  };

  // Component Life Cycle
  useEffect(() => {
    getVenues();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Component Life Cycle
  useEffect(() => {
    if (venueOptions.length) {
      const Schema = cloneDeep(schema);

      Schema.class._schema.forEach(obj => {
        if (
          obj.uid === "venue_id" &&
          venueOptions.length &&
          obj.field_metadata.options.length <= 1
        ) {
          obj.field_metadata.options.push(...venueOptions);
        }
      });

      //Level Id Field not to be visible in Add Form
      if (mode === Form_Mode.ADD) {
        Schema.class._schema.splice(
          Schema.class._schema.findIndex(item => item.uid === "id"),
          1,
        );
      }

      setSchemaState(Schema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueOptions]);

  return (
    <>
      {mode === Form_Mode.UPDATE && schemaState && (
        <SchemaForm
          onSubmit={onSubmit}
          schema={schemaState}
          backUrl={backUrl}
          defaultValues={defaultValues}
        />
      )}
      {mode === Form_Mode.ADD && schemaState && (
        <SchemaForm
          onSubmit={onSubmit}
          schema={schemaState}
          backUrl={backUrl}
        />
      )}
    </>
  );
};

export default Form;
