/* istanbul ignore file */
import { handleError } from "../helper";
import { httpRequest } from "..";
import { showLoader } from "store/reducers/loaderSlice";
import { showNotifier } from "store/reducers/notifierSlice";

// DFEP Admin Auth API Call Services
const authServices = ({
  url,
  key = "user",
  form,
  method = "POST",
  showToast = false,
  loader = true,
}) => {
  return (dispatch, getState) => {
    const httpObj = {
      url,
      data: {
        _method: method,
        [key]: form ? form : undefined,
      },
    };
    return new Promise(function (resolve, reject) {
      if (loader) {
        dispatch(showLoader({ show: true }));
      }
      httpRequest(httpObj)
        .then(response => {
          dispatch(showLoader({ show: false }));
          if (showToast) {
            dispatch(
              showNotifier({
                open: true,
                message: response.data.notice,
                variant: "success",
              }),
            );
          }
          resolve(response);
        })
        .catch(error => handleError(error, dispatch));
    });
  };
};
export { authServices };
