/* istanbul ignore file */
import { acceptFileType } from "utils/constants";
import { Button, Modal } from "@dfep/ui-component";
import { bytesToSize } from "utils/functions";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getAssets } from "services/getObjects";
import { getDataOptions } from "services/getObjects";
import {
  ModalMessage,
  ModalMessageWrapper,
  ModalSubheading,
  StyledUploadButton,
  UploadedAssetsModal,
} from "./modal.style";
import { UploadImgPanel } from "styles/global.style";
import { uploadObject } from "services/uploadObjects";
import { useModal } from "app/hooks/modal";
import { useParams } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FileUpload from "app/components/Common/FileUpload";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PDFImg from "assets/pdf.png";
import React, { useEffect, useRef, useState } from "react";
import RefreshTwoToneIcon from "@material-ui/icons/RefreshTwoTone";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

import TextField from "app/components/Common/TextField";

import InfiniteScroll from "react-infinite-scroll-component";

const WAIT_INTERVAL = 700;
let typingTimeOut = null;

const ImageUploadView = props => {
  const fileInputRef = useRef();

  const { sport_key } = useParams();

  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);
  const [assetsData, setAssetsData] = useState([]);
  const [img, setImg] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [action, setAction] = useState();

  const { show, openModalHandler, closeModalHandler, showDelete } = useModal();

  //States required for scroll component
  const [page, setPage] = useState(0);
  //intentionally set count undefined initially
  //to avoid it from setting hasMore to false in useEffect
  const [count, setCount] = useState();
  const [hasMore, setHasMore] = useState(true);

  const [searchData, setSearchData] = useState("");

  const getAssetsData = async () => {
    openModalHandler("show");
    setLoading(true);

    const { data } = await props.getAssets({
      sport_key,
      skip: page * 9,
      limit: 9,
      searchText: searchData,
    });

    setAssetsData(prevItems => {
      return [...prevItems, ...data.assets];
    });
    setCount(data.count);
    setPage(prevPage => prevPage + 1);

    setLoading(false);
  };

  useEffect(() => {
    if (assetsData.length >= count) {
      setHasMore(false);
    } else setHasMore(true);
  }, [assetsData, count]);

  const getSelectedImage = e => {
    props.field.onChange(img);
    setSelectedItem();
    closeModalHandler("show");
    resetChooseFromUploadStates();
    setSearchData("");
  };

  const onAssetSearch = event => {
    const text = event.target.value.trim();
    clearTimeout(typingTimeOut);
    typingTimeOut = setTimeout(() => {
      setSearchData(text);
    }, WAIT_INTERVAL);
  };

  const resetChooseFromUploadStates = () => {
    setPage(0);
    setAssetsData([]);
    setCount(0);
  };

  useEffect(() => {
    //reset the states to their initial values
    resetChooseFromUploadStates();
  }, [searchData]);

  useEffect(() => {
    if (page === 0 && assetsData.length === 0 && count === 0 && show)
      getAssetsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, assetsData, count]);

  const determineItemStyle = uid => {
    const isItemSelected = selectedItem === uid;
    return isItemSelected ? "active" : "";
  };

  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      //Drag & drop upload restricted to one file
      if (!selectedFiles.length) handleFiles(files);
    }
  };

  const handleFiles = files => {
    if (files.length && validateFile(files[0])) setSelectedFiles([files[0]]);
  };

  const validateFile = file => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "application/pdf",
      "application/json",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const removeFile = name => {
    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);

    setSelectedFiles([...selectedFiles]);
  };

  const fileInputClicked = () => {
    if (!selectedFiles.length) fileInputRef.current.click();
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const addAssetHandler = event => {
    startUpload(selectedFiles);
  };

  const startUpload = files => {
    files.forEach(async (file, index) => {
      const formData = new FormData();
      formData.append("asset[upload]", file);
      const response = await props.uploadImage({
        url: `v1/admin/applications/${sport_key}/assets`,
        formData,
      });
      setSelectedFiles([]);
      closeModalHandler("showDelete");
      props.field.onChange(response.data.asset.url);
    });
  };

  return (
    <>
      <UploadImgPanel>
        <StyledUploadButton
          variant="outlined"
          color="default"
          onClick={() => getAssetsData()}
          className="choose-btn"
        >
          Choose From Uploads
        </StyledUploadButton>
        <span>Or</span>
        <Button
          className="upload-btn"
          color="primary"
          onClick={() => openModalHandler("showDelete")}
          data-testid="add-asset"
        >
          Upload New File
        </Button>
        <span>Or</span>
        <div className="img_url_input">
          <TextField
            id={`url-${props.field.name}`}
            type="text"
            value={img}
            label="File URL"
            variant="outlined"
            onChange={e => {
              if (e.target.value.trim()) {
                setImg(e.target.value.trim());
                setAction(true);
              } else {
                setImg("");
                setAction(false);
              }
            }}
          />
          {/*actions buttons*/}
          {action && (
            <div className="input-actions">
              <IconButton
                onClick={() => {
                  props.field.onChange(img);
                }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  props.field.onChange();
                  setImg("");
                  setAction(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
      </UploadImgPanel>
      {/* file image modal */}
      <Modal
        data-testid="add-asset-modal"
        className="small"
        open={showDelete}
        handleClose={() => closeModalHandler("showDelete")}
        title="Upload Assets"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: () => {
              setSelectedFiles([]);
              return closeModalHandler("show");
            },
          },
          {
            id: "2",
            name: "Add File",
            handler: addAssetHandler,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        <FileUpload
          acceptFileType={props.field_metadata?.file_type || acceptFileType}
          selectMultiple={false}
          dragOver={dragOver}
          dragEnter={dragEnter}
          dragLeave={dragLeave}
          fileDrop={fileDrop}
          selectedFiles={selectedFiles}
          fileSize={bytesToSize}
          validFiles={selectedFiles}
          removeFile={removeFile}
          fileInputClicked={fileInputClicked}
          fileInputRef={fileInputRef}
          filesSelected={filesSelected}
        />
      </Modal>
      {/* select image modal */}
      <UploadedAssetsModal
        id="scrollableDiv"
        data-testid="add-asset-modal"
        className="medium uploaded-assets"
        open={show}
        handleClose={() => closeModalHandler("show")}
        title="Choose File"
        maxwidth="md"
        actions={[
          {
            id: "1",
            name: "Cancel",
            handler: () => {
              setImg("");
              closeModalHandler("show");
              resetChooseFromUploadStates();
              setSearchData("");
            },
          },
          {
            id: "2",
            name: "Done",
            handler: getSelectedImage,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        <InfiniteScroll
          dataLength={assetsData.length}
          next={getAssetsData}
          hasMore={hasMore}
          scrollThreshold="0px"
          loader={
            <div className="inner-content">
              <Grid container spacing={6}>
                <>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                </>
              </Grid>
            </div>
          }
          endMessage={
            <ModalMessageWrapper>
              <ModalMessage>No data to load.</ModalMessage>
            </ModalMessageWrapper>
          }
          height={563}
          scrollableTarget="scrollableDiv"
          style={{ overflowX: "hidden" }}
        >
          <ModalSubheading>
            <TextField label="Search" onChange={onAssetSearch} />
            <IconButton onClick={() => resetChooseFromUploadStates()}>
              <RefreshTwoToneIcon />
            </IconButton>
          </ModalSubheading>
          <div className="inner-content">
            <Grid container spacing={6}>
              {loading && assetsData.length === 0 ? (
                <>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                  <Grid xs={4} item>
                    <Skeleton variant="rect" width={253} height={200} />
                    <Skeleton width="40%" />
                  </Grid>
                </>
              ) : (
                assetsData.map(item => (
                  <Grid
                    key={item.uid}
                    xs={4}
                    onClick={() => {
                      setSelectedItem(item.uid);
                      setImg(item.url);
                      if (selectedItem === item.uid) {
                        setSelectedItem("");
                        setImg();
                      }
                    }}
                    item
                  >
                    <Card className={determineItemStyle(item.uid)}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="170"
                          width="253"
                          image={
                            item.content_type === "application/pdf"
                              ? PDFImg
                              : item.url
                          }
                          title={item.filename}
                        />
                        <CardContent>
                          <Typography variant="body1">
                            {item.filename}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </div>
        </InfiniteScroll>
      </UploadedAssetsModal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    uploadImage: payload => dispatch(uploadObject(payload)),
    getAssets: payload => dispatch(getAssets(payload)),
    dataSearch: payload => dispatch(getDataOptions(payload)),
  };
};

const Container = connect(null, mapDispatchToProps)(ImageUploadView);
export { Container, ImageUploadView as Component };
