import { Accordion, AccordionDetails, AccordionSummary } from "../Accordian";
import { Controller, useWatch } from "react-hook-form";

import { CustomFlexBox } from "styles/global.style";
import AceEditor from "../jsonEditor/jsonEditor";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldBorder from "../FieldBorder ";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import Label from "../Label";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import TextField from "../TextField";
import Typography from "@material-ui/core/Typography";
const Form = ({
  formId,
  customSchema,
  additionalFormAttributes,
  watchList,
  form,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = form;

  watchList?.length &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useWatch({
      name: watchList,
      control,
    });

  const generateForm = schema => {
    const {
      uid,
      name,
      title,
      type,
      field_metadata,
      validations,
      _schema,
      defaultValue = "",
    } = schema;
    const { options, readOnly } = field_metadata;
    switch (type) {
      case "text":
        return (
          <FieldBorder
            key={uid}
            isBorder={!!field_metadata?.isBorder}
            isError={!!errors[`${name}`]}
          >
            <Controller
              name={name}
              control={control}
              rules={validations}
              defaultValue={defaultValue}
              render={({ field, formState }) => {
                const { errors } = formState;
                const hasFieldError = has(errors, name);
                const fieldError = get(errors, name);
                return (
                  <TextField
                    label={title}
                    type="text"
                    variant="outlined"
                    error={hasFieldError}
                    value={field.value}
                    helperText={hasFieldError && fieldError.message}
                    FormHelperTextProps={{
                      "data-testid": `${uid}-error`,
                    }}
                    onChange={field.onChange}
                    onBlur={e => {
                      if (!field_metadata?.doNotTrim) {
                        e.target.value = e.target.value.trim();
                        field.onChange(e.target.value);
                      }
                      field.onBlur(e.target.value);
                    }}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: !!validations?.required,
                    }}
                    InputProps={{
                      readOnly: !!readOnly,
                    }}
                    inputProps={{
                      "data-testid": uid,
                      "aria-invalid": !!errors[`${name}`] ? true : false,
                    }}
                    {...field_metadata?.textFieldProps}
                  />
                );
              }}
            />
          </FieldBorder>
        );
      case "number":
        return (
          <Controller
            key={uid}
            name={name}
            control={control}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field, fieldState, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, name);
              const fieldError = get(errors, name);
              return (
                <FieldBorder
                  isBorder={!!field_metadata?.isBorder}
                  isError={hasFieldError}
                >
                  <TextField
                    label={title}
                    type="number"
                    variant="outlined"
                    error={hasFieldError}
                    helperText={hasFieldError && fieldError.message}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: !!validations?.required,
                    }}
                    InputProps={{
                      readOnly: !!readOnly,
                    }}
                    inputProps={{
                      "data-testid": uid,
                    }}
                    FormHelperTextProps={{
                      "data-testid": `${uid}-error`,
                    }}
                    {...field_metadata?.numberFieldProps}
                  />
                </FieldBorder>
              );
            }}
          />
        );
      case "date":
        return (
          <Controller
            key={uid}
            name={name}
            control={control}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, name);
              const fieldError = get(errors, name);
              return (
                <FieldBorder
                  isBorder={!!field_metadata?.isBorder}
                  isError={hasFieldError}
                >
                  <TextField
                    label={title}
                    type="date"
                    variant="outlined"
                    error={hasFieldError}
                    helperText={hasFieldError && fieldError.message}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: !!validations?.required,
                    }}
                    inputProps={{
                      "data-testid": uid,
                    }}
                    InputProps={{
                      readOnly: !!readOnly,
                    }}
                    FormHelperTextProps={{
                      "data-testid": `${uid}-error`,
                    }}
                    {...field_metadata?.dataFieldProps}
                  />
                </FieldBorder>
              );
            }}
          />
        );
      case "select":
        return (
          <Controller
            key={uid}
            name={name}
            control={control}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field, fieldState, formState }) => {
              const { errors } = formState;
              const hasFieldError = has(errors, name);
              const fieldError = get(errors, name);
              return (
                <FieldBorder
                  isBorder={!!field_metadata?.isBorder}
                  isError={hasFieldError}
                >
                  <TextField
                    label={title}
                    variant="outlined"
                    error={hasFieldError}
                    select
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    helperText={hasFieldError && fieldError.message}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    InputLabelProps={{
                      required: !!validations?.required,
                    }}
                    InputProps={{
                      readOnly: !!readOnly,
                    }}
                    inputProps={{
                      "data-testid": uid,
                    }}
                    FormHelperTextProps={{
                      "data-testid": `${uid}-error`,
                    }}
                    {...field_metadata?.selectFieldProps}
                  >
                    {options.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FieldBorder>
              );
            }}
          />
        );
      case "checkbox":
        return (
          <Grid item key={uid}>
            <Controller
              name={name}
              control={control}
              rules={validations}
              defaultValue={defaultValue}
              render={({ field, formState }) => {
                const { errors } = formState;
                const hasFieldError = has(errors, name);
                return (
                  <FieldBorder
                    isBorder={!!field_metadata?.isBorder}
                    isError={hasFieldError}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid={uid}
                          disabled={!!readOnly}
                          checked={field.value}
                          value={field.value}
                          name={field[name]}
                          onChange={event => {
                            return field.onChange(event.target.checked);
                          }}
                          inputProps={{
                            "data-testid": `input-${uid}`,
                            "aria-disabled": !!readOnly,
                          }}
                          {...field_metadata?.checkboxProps}
                        />
                      }
                      label={title}
                    />
                  </FieldBorder>
                );
              }}
            />
          </Grid>
        );
      case "radio":
        const radioGroupErrorExist = has(errors, name);
        return (
          <Grid item key={uid}>
            <Paper
              style={{ padding: "20px" }}
              elevation={radioGroupErrorExist ? 3 : 1}
            >
              <FormControl error={radioGroupErrorExist}>
                <FormLabel component="legend">{title}</FormLabel>
                <Controller
                  name={name}
                  control={control}
                  rules={validations}
                  defaultValue={defaultValue}
                  render={({ field: { value }, fieldState, formState }) => {
                    const { errors } = formState;
                    const hasFieldError = has(errors, name);
                    const fieldError = get(errors, name);
                    return (
                      <FieldBorder
                        isBorder={!!field_metadata?.isBorder}
                        isError={hasFieldError}
                      >
                        <RadioGroup
                          data-testid={uid}
                          value={value}
                          onChange={e => {
                            setValue(name, e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                        >
                          {options.map(
                            ({ label, value: radioValue, mapSchema }) => (
                              <div key={uid}>
                                <FormControlLabel
                                  value={radioValue}
                                  control={
                                    <Radio
                                      InputProps={{
                                        readOnly: !!readOnly,
                                      }}
                                      {...field_metadata?.radioProps}
                                    />
                                  }
                                  label={label}
                                />

                                {_schema.length > 0 &&
                                  mapSchema?.length > 0 &&
                                  mapSchema.map(schemaName => {
                                    return (
                                      radioValue === value &&
                                      generateForm(
                                        _schema.find(
                                          schemaObject =>
                                            schemaObject.name === schemaName,
                                        ),
                                      )
                                    );
                                  })}
                              </div>
                            ),
                          )}
                        </RadioGroup>
                        {hasFieldError && (
                          <FormHelperText>{fieldError.message}</FormHelperText>
                        )}
                      </FieldBorder>
                    );
                  }}
                />
              </FormControl>
            </Paper>
          </Grid>
        );
      case "checkboxGroup":
        const checkboxGroupErrorExist = has(errors, name);
        return (
          <Grid key={uid} item data-testid={uid}>
            <FormControl error={checkboxGroupErrorExist}>
              <FormLabel component="legend">{title}</FormLabel>
              <Controller
                name={name}
                control={control}
                rules={validations}
                defaultValue={defaultValue}
                render={({
                  field: { value: checkboxValue, name },
                  fieldState,
                  formState,
                }) => {
                  const { errors } = formState;
                  const hasFieldError = has(errors, name);
                  const fieldError = get(errors, name);
                  return (
                    <>
                      <FormGroup>
                        {options.map(
                          ({ label, name: checkboxName, mapSchema, uid }) => (
                            <div key={uid}>
                              {generateForm({
                                uid: `${uid}`,
                                name: `${name}.${checkboxName}`,
                                title: label,
                                type: "checkbox",
                                field_metadata: {
                                  isBorder: !!field_metadata?.isBorder,
                                },
                                validations: {},
                                _schema: [],
                                defaultValue: checkboxValue[checkboxName],
                              })}

                              {_schema.length > 0 &&
                                mapSchema?.length > 0 &&
                                mapSchema.map(values => {
                                  return (
                                    checkboxValue[checkboxName] &&
                                    generateForm(
                                      _schema.find(
                                        schemaObject =>
                                          schemaObject.name === values,
                                      ),
                                    )
                                  );
                                })}
                            </div>
                          ),
                        )}
                      </FormGroup>
                      {hasFieldError && (
                        <FormHelperText>{fieldError.message}</FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            </FormControl>
          </Grid>
        );
      case "layout":
        return (
          <Grid {...field_metadata?.layout} key={uid}>
            {_schema.length > 0 &&
              _schema.map(schemaObject => generateForm(schemaObject))}
          </Grid>
        );
      case "component":
        const { component } = field_metadata;
        return (
          <Controller
            key={uid}
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value: props } }) =>
              React.cloneElement(component, { ...props })
            }
          />
        );
      case "group":
        const groupError = has(errors, name);
        return (
          <FieldBorder
            key={uid}
            isBorder={!!field_metadata?.isBorder}
            isError={groupError}
            data-testid={uid}
          >
            {title.trim().length > 0 && <Label>{title}</Label>}

            {_schema.length > 0 &&
              _schema.map(schemaObject => generateForm(schemaObject))}
          </FieldBorder>
        );
      case "groupAccordian":
        const groupAccordianError = has(errors, name);
        return (
          <FieldBorder
            isBorder={!!field_metadata?.isBorder}
            isError={groupAccordianError}
            key={uid}
          >
            <Accordion
              data-testid={uid}
              defaultExpanded={true}
              {...field_metadata?.accordianProps}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                {...field_metadata?.accordianSummaryProps}
              >
                <Label>{title}</Label>
              </AccordionSummary>
              <AccordionDetails {...field_metadata?.accordianDetailsProps}>
                {_schema.length > 0 &&
                  _schema.map(schemaObject => {
                    return generateForm(schemaObject);
                  })}
              </AccordionDetails>
            </Accordion>
          </FieldBorder>
        );

      case "fileUpload":
        return (
          <Grid item key={uid}>
            <Controller
              name={name}
              control={control}
              rules={validations}
              render={({
                field: { onBlur, value, name, ref },

                formState,
              }) => {
                const { errors } = formState;
                const hasFieldError = has(errors, name);
                const fieldError = get(errors, name);
                return (
                  <FieldBorder
                    isBorder={!!field_metadata?.isBorder}
                    isError={hasFieldError}
                  >
                    <FormControl error={hasFieldError}>
                      <Typography variant="h6">{title}</Typography>
                      <Divider />
                      <Button
                        data-testid={uid}
                        style={{ margin: "10px 0" }}
                        variant="contained"
                        component="label"
                        color={!isEmpty(value) ? "default" : "primary"}
                      >
                        Upload File
                        <input
                          name={name}
                          ref={ref}
                          onBlur={onBlur}
                          {...field_metadata?.uploadFileProps}
                          onChange={e => {
                            setValue(name, e.target.files, {
                              shouldValidate: true,
                            });
                          }}
                          type="file"
                          hidden
                        />
                      </Button>
                      {!isEmpty(value) &&
                        Object.values(value).map(file => (
                          <Typography key={uid}>
                            {file.name.length > 20
                              ? `${file.name.substring(0, 20)}...`
                              : file.name}
                          </Typography>
                        ))}
                      {hasFieldError && (
                        <FormHelperText>{fieldError.message}</FormHelperText>
                      )}
                    </FormControl>
                  </FieldBorder>
                );
              }}
            />
          </Grid>
        );
      case "jsonEditor":
        return (
          <Grid item key={uid}>
            <Controller
              name={name}
              control={control}
              rules={validations}
              defaultValue={defaultValue}
              render={({ field: { value, onChange }, formState }) => {
                const { errors } = formState;
                const hasFieldError = has(errors, name);
                const fieldError = get(errors, name);
                return (
                  <FieldBorder
                    isBorder={!!field_metadata?.isBorder}
                    isError={hasFieldError}
                  >
                    <div className="editor-wrapper">
                      <CustomFlexBox className="grp-heading">
                        <Label className="grp-label lbl">{title}</Label>
                      </CustomFlexBox>
                      <CustomFlexBox className="alignItemsTop grp-content">
                        <AceEditor
                          data-testid={uid}
                          value={value}
                          onError={node => {
                            if (node.length) {
                              setError(name, {
                                type: node[0].type,
                                message: node[0].text,
                              });
                            }
                          }}
                          onChange={newValue => {
                            onChange(newValue);
                          }}
                          name={name}
                          {...field_metadata?.jsonEditorProps}
                        />
                      </CustomFlexBox>
                      {hasFieldError && (
                        <FormHelperText data-testid={`${uid}-error`} error>
                          {fieldError.message}
                        </FormHelperText>
                      )}
                    </div>
                  </FieldBorder>
                );
              }}
            />
          </Grid>
        );
      default:
        return (
          <Grid item key={uid}>
            <Controller
              name={name}
              control={control}
              rules={validations}
              defaultValue={defaultValue}
              render={({ field, formState }) => {
                const { errors } = formState;
                const hasFieldError = has(errors, name);
                const fieldError = get(errors, name);
                return (
                  <TextField
                    data-testid={uid}
                    label={title}
                    type={type}
                    variant="outlined"
                    error={hasFieldError}
                    helperText={hasFieldError && fieldError.message}
                    {...field}
                    inputRef={field.ref}
                  />
                );
              }}
            />
          </Grid>
        );
    }
  };

  return (
    <form
      id={formId}
      data-testid={`${formId}_form`}
      {...additionalFormAttributes}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {generateForm(customSchema)}
    </form>
  );
};

export default Form;
