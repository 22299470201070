import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import React, { Fragment } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";
import TextField from "app/components/Common/TextField";

const TableView = props => {
  return (
    <Fragment>
      {props.isLoading ? (
        <SkeletonTable
          isSorting
          headings={props.headings}
          checkbox={props.checkbox}
          name={props.name}
          actions={props.actions}
        />
      ) : props.totalCount > 0 ? (
        <Table {...props} />
      ) : (
        <NoData data-testid="no-data-mock-game">No Data Found</NoData>
      )}
      <Modal
        className="small"
        open={props.openModal}
        handleClose={props.handleModalClose}
        title={
          props.actionType === "start" ? "Start Mock Game" : "Abort Mock Game"
        }
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: props.handleModalClose },
          {
            id: "2",
            name: props.actionType === "start" ? "Start" : "Abort",
            handler:
              props.actionType === "start"
                ? props.handleModalStart
                : props.handleModalAbort,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        {props.actionType === "start" ? (
          <>
            Are you sure want to start mock game?
            <TextField
              id="fast_forward_minutes"
              label="Fast Forward Minutes"
              inputProps={{
                "data-testid": "fast_forward_minutes-input",
                min: 0,
              }}
              type="number"
              {...props.setMinutes}
              error={props.inputError.fastForwardMinutesInput.hasError}
              helperText={props.inputError.fastForwardMinutesInput.message}
            />
          </>
        ) : (
          "Are you sure want to abort mock game?"
        )}
      </Modal>
    </Fragment>
  );
};

export default TableView;
