import {
  Content,
  ContentChildren,
  ContentToolbar,
  Footer,
  LayoutWrap,
} from "./index.style";
import { Header, SubHeader } from "@dfep/ui-component";
import { useSelector } from "react-redux";
import React from "react";
import UserIcon from "assets/user.svg";

const Layout = props => {
  const { first_name, email } = useSelector(state => state.admin);
  const { name: applicationName } = useSelector(state => state.application);

  const headerProps = {
    userName: first_name || email,
    actions: [
      {
        id: 1,
        name: "Profile",
        handler: () => props.history.push("/dashboard/account/profile"),
      },
      { id: 2, name: "Sign Out", handler: props.logout },
    ],
    avatar: UserIcon,
    logoAction: () => props.history.push("/"),
  };

  return (
    <LayoutWrap>
      <Header {...headerProps} />
      <SubHeader showDashboardIcon={true} heading={applicationName || ""} />
      <Content>
        <ContentToolbar />
        {/* Content children will come here after Toolbar Div */}

        <ContentChildren>{props.children}</ContentChildren>
      </Content>
      <Footer className="footer" />
    </LayoutWrap>
  );
};

export default Layout;
