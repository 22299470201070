import { Link } from "react-router-dom";
import { Card as MuiCard } from "@dfep/ui-component";
import MuiBox from "@material-ui/core/Box";
import MuiGrid from "@material-ui/core/Grid";
import MuiTypography from "@material-ui/core/Typography";
import styled from "styled-components";

export const BoxWrap = styled(MuiBox)`
  /* min-height: 45px; */
  .version-update {
    padding-right: 32px;
    &:last-child {
      padding-right: 0;
    }
  }
`;
export const Box = styled(MuiBox)`
  .MuiBox-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    .card {
      border-width: 0 10px 20px 10px;
      border-color: ${props => props.theme.palette.grey.grey100};
      border-style: solid;
      border-radius: 4px;
      background: ${props => props.theme.palette.white.main};
      &:hover,
      &:focus {
        background: ${props => props.theme.palette.white.white200};
        .MuiCard-root {
          background: ${props => props.theme.palette.white.white200};
        }
      }
      .MuiCard-root {
        box-shadow: none;
      }
      .MuiTypography-h3 {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        word-break: break-word;
      }
      .update-holder {
        ${({ theme }) => theme.breakpoints.down("md")} {
          display: block;
        }
      }
    }
  }
  .skeleton-loader {
    .MuiBox-root {
      width: 100%;
    }
  }
`;

export const Card = styled(MuiCard)`
  .MuiCardContent-root {
    &.deviceCard {
      position: relative;
      padding: 14px 25px 16px 16px;
      .MuiIconButton-root {
        padding: 6px;
        position: absolute;
        top: 10px;
        right: 10px;
        svg {
          color: ${props => props.theme.palette.grey.grey500};
        }
      }
    }
  }
`;

export const Typography = styled(MuiTypography)`
  span {
    color: rgba(0, 0, 0, 0.54);
  }
`;
export const CardLink = styled(Link)`
  text-decoration: none;
`;
