import { Route, Switch } from "react-router-dom";
import AddLightTheBeamInfo from "app/pages/Dashboard/Data/LightTheBeam/add";
import GetLightTheBeam from "app/pages/Dashboard/Data/LightTheBeam/get";
import React from "react";
import UpdateLightTheBeam from "app/pages/Dashboard/Data/LightTheBeam/update";

const LightTheBeam = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/add`}
        component={AddLightTheBeamInfo}
        exact
      />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateLightTheBeam}
        exact
      />
      <Route path={props.match.path} component={GetLightTheBeam} />
    </Switch>
  );
};

export default LightTheBeam;
