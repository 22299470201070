import { Redirect, Route, Switch } from "react-router-dom";
import Events from "app/pages/Dashboard/Data/Arena/Events";
import GetParkingAndDirections from "app/pages/Dashboard/Data/Arena/ParkingAndDirections";
import Policies from "app/pages/Dashboard/Data/Arena/Policies";
import React from "react";

const Arena = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/events`}
        exact
      />
      <Route path={`${props.match.path}/policies`} component={Policies} />
      <Route path={`${props.match.path}/events`} component={Events} />
      <Route
        path={`${props.match.path}/parkingAndDirections`}
        component={GetParkingAndDirections}
      />
    </Switch>
  );
};

export default Arena;
