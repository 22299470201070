import { Autocomplete } from "styles/global.style";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "app/components/Common/TextField";

const AutoCompleteField = ({
  name,
  display_name,
  data_type,
  options,
  defaultValue,
  parentName,
  validations,
}) => {
  const { control, setValue } = useFormContext();

  const inputOptions = options.map(option => option.label);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validations}
      render={({ field }) => {
        return (
          <Autocomplete
            data-testid="autocomplete"
            multiple
            options={inputOptions}
            getOptionLabel={option => option}
            filterSelectedOptions
            value={field.value}
            onChange={(_, value) => {
              setValue(`${parentName}.mandatory`, value.includes("Mandatory"));
              setValue(`${parentName}.multiple`, !!value.includes("Multiple"));
              setValue(
                `${parentName}.field_metadata.multiline`,
                value.includes("Multiline"),
              );
              field.onChange(value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={display_name}
                // placeholder="Additional Options"
                type={data_type}
                inputRef={field.ref}
              />
            )}
          />
        );
      }}
    />
  );
};

export default AutoCompleteField;
