import { connect } from "react-redux";
import { createRecord } from "services/createObject";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { Fragment } from "react";
import TemplateForm from "app/pages/Dashboard/pushNotification/Template/form/AddTemplateForm";

const AddTemplate = props => {
  const {
    location: { state },
  } = props;
  const { sport_key, env_key } = props.match.params;

  const createTemplate = async form => {
    const { data } = await props.AddTemplate({
      url: `/v1/push_notification/admin/push_template`,
      key: "push_template",
      form,
    });
    /* istanbul ignore else */
    if (data?.push_template?.type) {
      props.history.push(
        `/dashboard/${sport_key}/${env_key}/push-notification/template`,
      );
    }
  };

  const CancelForm = () => {
    props.history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/push-notification/template`,
    );
  };

  return (
    <Fragment>
      <PanelHeader
        title="Add Template"
        backUrl={`/dashboard/${sport_key}/${env_key}/push-notification/template`}
        breadCrumbItems={[
          {
            title: "Push Notification",
          },
        ]}
      ></PanelHeader>
      <TemplateForm
        team_name={props.team_name}
        CancelForm={CancelForm}
        createTemplate={createTemplate}
      />
    </Fragment>
  );
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    team_name: state.application.team_name,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return {
    AddTemplate: payload => dispatch(createRecord(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(AddTemplate);

export { Container, AddTemplate as Component };
