/* istanbul ignore file */
import { Modal } from "@dfep/ui-component";
import MuiBox from "@material-ui/core/Box";
import MuiCard from "@material-ui/core/Card";
import MuiCardContent from "@material-ui/core/CardContent";
import MuiGrid from "@material-ui/core/Grid";
import MuiTypography from "@material-ui/core/Typography";
import styled from "styled-components";

export const TopGrid = styled(MuiGrid)`
  &.cards-container {
    margin-bottom: 42px;
  }

  .MuiGrid-root {
    &.cards {
      padding-right: 15px;
      padding-bottom: 15px;
      &:nth-child(3) {
        padding-right: 0;
      }
      ${({ theme }) => theme.breakpoints.down("xs")} {
        padding: 10px;
      }
    }
  }
`;
export const SelectType = styled(Modal)`
  &.MuiDialog-root {
    position: relative;
    .MuiDialog-paperWidthSm {
      max-width: 800px;
    }
    .MuiDialogContent-root {
      .MuiIconButton-root {
        position: absolute;
        top: 12px;
        right: 20px;
        &:hover,
        &:focus {
          background: none;
        }
      }
    }
  }
`;

export const Card = styled(MuiCard)`
  &.MuiCard-root {
    border-radius: 6px;
    cursor: pointer;
    box-shadow: none;
    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const CardContent = styled(MuiCardContent)`
  &.MuiCardContent-root {
    text-align: center;
    padding: 27px 16px 24px;
    min-height: 234px;
  }
`;
export const Box = styled(MuiBox)`
  &.MuiBox-root {
    min-height: 83px;
  }
`;
export const MUITypography = styled(MuiTypography)`
  &.card-title {
    font-weight: 600;
    text-transform: uppercase;
  }
`;
