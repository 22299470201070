import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize, initialRoute } from "utils/functions";
import { Constants } from "utils/constants";
import { getGameStartingLineup } from "services/getObjects";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";
import Settings from "app/pages/Dashboard/Data/Game/StartingLineup/get/Settings";
import StartingLineupData from "app/pages/Dashboard/Data/Game/StartingLineup/get/GetStartingLineup";

function GetStartingLineup(props) {
  const {
    match: {
      path,
      url,
      params: { sport_key, env_key },
    },
    location,
    history,
  } = props;

  const { READ_ONLY } = Constants;
  const { team_name, tid, access_type } = useSelector(
    state => state.application,
  );

  let tabObj = {};
  tabObj[team_name] = 0;

  tabObj = { ...tabObj, settings: 1 };
  const tab = tabObj[location.pathname.split("/").slice(-1)[0]]; // get "team_name" from /dashboard/app_name/data/game/startingLineup/team_name

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const [data, setData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { sort, value, skip, limit, searchText } = useQueryParams();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const dispatch = useDispatch();

  const getStartingLineups = useCallback(async () => {
    setIsLoading(true);

    const query = {
      $or: [
        { "home.teamId": activeTab === 0 ? tid : { $ne: tid } },
        { "visitor.teamId": activeTab === 0 ? tid : { $ne: tid } },
      ],
    };

    const {
      data: { lineups, count },
    } = await dispatch(
      getGameStartingLineup({
        tid,
        skip,
        limit,
        sort,
        sortValue: value,
        query,
        searchText,
      }),
    );

    if (count !== 0 && lineups.length === 0) {
      initialRoute(history);
    } else {
      setTotalDataCount(count);
      setData(lineups);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, skip, limit, sort, value, activeTab, searchText]);

  const syncModalClose = () => {
    closeModalHandler();
  };
  const syncDetails = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/line_ups" }));
    closeModalHandler();
  };

  if (tab === undefined) {
    return <Redirect from={url} to={`${url}/${team_name}`} />;
  }

  return (
    <>
      <PanelHeader
        title="Starting Lineup"
        breadCrumbItems={[
          {
            title: "Starting Lineup",
          },
        ]}
        refreshHandler={getStartingLineups}
        {...(access_type !== READ_ONLY && {
          sync: () => openModalHandler("show"),
        })}
        search={true}
      >
        {access_type !== READ_ONLY && (
          <Button
            color="primary"
            variant="contained"
            data-testid="Add-btn"
            startIcon={<AddIcon />}
            onClick={() =>
              history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/game/startingLineup/add`,
                state: { from: location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${url}/${team_name}`}
            {...a11yProps(0)}
          />
          {/* <Tab
            label="Others"
            component={Link}
            to={`${url}/others`}
            {...a11yProps(1)}
          /> */}
          <Tab
            label="Settings"
            component={Link}
            to={`${url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect from={path} to={`${url}/${team_name}`} exact />
          <Route path={`${path}/settings`} component={Settings} exact />
          <Route
            path={`${path}/:team_name`}
            /*  istanbul ignore next   */
            render={routeProps => {
              /*  istanbul ignore next   */
              return (
                <StartingLineupData
                  dispatch={dispatch}
                  getStartingLineups={getStartingLineups}
                  data={data}
                  setData={setData}
                  totalDataCount={totalDataCount}
                  isLoading={isLoading}
                  openSyncModal={show}
                  syncModalClose={syncModalClose}
                  syncDetails={syncDetails}
                  access_type={access_type}
                  READ_ONLY={READ_ONLY}
                  {...routeProps}
                />
              );
            }}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
}
export default GetStartingLineup;
