import { Redirect, Route, Switch } from "react-router-dom";
import GeoMap from "app/pages/Dashboard/geoFencing/Maps/index.js";
import GetGeoLocations from "app/pages/Dashboard/geoFencing/geoLocation/index.js";
//import Maps from "";

const GeoFencing = props => {
  return (
    <Switch>
      <Redirect
        from={`${props.match.path}`}
        to={`${props.match.url}/geo_location`}
        exact
      />

      {/* GeoLocation routes */}
      <Route path={`${props.match.path}/geo_location/add`} component={GeoMap} />
      <Route
        path={`${props.match.path}/geo_location/update/:uid`}
        component={GeoMap}
      />
      <Route
        path={`${props.match.path}/geo_location`}
        component={GetGeoLocations}
      />
    </Switch>
  );
};

export default GeoFencing;
