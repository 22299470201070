import { createRecord } from "services/createObject";
import { useDispatch } from "react-redux";
import BroadcastForm from "../BroadcastTopicForm";
import PanelHeader from "app/components/Common/PanelHeader";
import React from "react";
export default function AddBroadcastTopic({
  history,
  match,
  location,
  ...restporps
}) {
  const { sport_key, env_key } = match.params;
  const { state } = location;

  const disptach = useDispatch();

  const addTopic = async form => {
    const data = await disptach(
      createRecord({
        url: `/v1/admin/applications/${sport_key}/envs/${env_key}/broadcast/topics`,
        key: "topic",
        form,
      }),
    );
    /* istanbul ignore else */
    if (data.status === 201) {
      history.push(`/dashboard/${sport_key}/${env_key}/broadcast-topics`);
    }
  };

  const cancelFormHandler = async () => {
    history.push(
      state
        ? state.from.pathname + state.from.search
        : `/dashboard/${sport_key}/${env_key}/broadcast-topics`,
    );
  };

  return (
    <div dats-testid="resolved">
      <PanelHeader
        title="Add Topic"
        backUrl={`/dashboard/${sport_key}/${env_key}/broadcast-topics/push`}
        breadCrumbItems={[
          {
            title: "Broadcast Topics",
          },
        ]}
      />
      <BroadcastForm
        apiCallFunction={addTopic}
        cancelForm={cancelFormHandler}
      />
    </div>
  );
}
