import "ace-builds/webpack-resolver";
import { Controller } from "react-hook-form";
import { CustomFlexBox } from "styles/global.style";
import AceEditor from "../jsonEditor/jsonEditor";
import FieldBorder from "../FieldBorder ";
import FormHelperText from "@material-ui/core/FormHelperText";
import get from "lodash/get";
import has from "lodash/has";
import Label from "../Label";
import React from "react";

const JsonEdior = ({
  fieldName,
  showBorder,
  defaultValue,
  validations,
  control,
  uid,
  title,
  setError,
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, formState }) => {
        const { errors } = formState;
        const hasFieldError = has(errors, fieldName);
        const fieldError = get(errors, fieldName);
        return (
          <FieldBorder isBorder={showBorder} isError={hasFieldError}>
            <div className="editor-wrapper">
              <CustomFlexBox className="grp-heading">
                <Label className="grp-label lbl">{title}</Label>
              </CustomFlexBox>
              <CustomFlexBox className="alignItemsTop grp-content">
                <AceEditor
                  data-testid={uid}
                  value={value}
                  onError={node => {
                    if (node.length) {
                      setError(fieldName, {
                        type: node[0].type,
                        message: node[0].text,
                      });
                    }
                  }}
                  onChange={newValue => {
                    onChange(newValue);
                  }}
                  name={fieldName}
                />
              </CustomFlexBox>
              {hasFieldError && (
                <FormHelperText data-testid={`${uid}-error`} error>
                  {fieldError.message}
                </FormHelperText>
              )}
            </div>
          </FieldBorder>
        );
      }}
    />
  );
};

export default JsonEdior;
