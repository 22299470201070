import {
  ActionButton,
  Box,
  BtnWrap,
  IconBox,
  Paper,
  Section,
  Typography,
} from "./settings.style";
import { applicationAction } from "store/reducers/applicationSlice";
import { connect } from "react-redux";
import { createRecord } from "services/createObject";
import { deleteRecord } from "services/deleteObject";
import { Modal } from "@dfep/ui-component";
import { updateRecord } from "services/updateObject";
import Copy from "clipboard-copy";
import CopyIcon from "@material-ui/icons/FileCopy";
import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
//Form
import { useForm } from "react-hook-form";
import { validateAppName, validateEmail } from "utils/functions";
import Form from "./Form";

const Settings = props => {
  const { sport_key, master_key, current_app_name } = props;

  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [isVisible, setVisibility] = useState(false);
  const [copySnackbar, setCopySnackbar] = useState(false);
  const [openOwnershipModal, setOpenOwnershipModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);

  // Rename Application
  const handleRenameModal = () => {
    setOpenRenameModal(!openRenameModal);
  };
  const handleRename = async data => {
    try {
      const {
        data: { application },
      } = await props.renameApp({
        url: `/v1/admin/applications/${sport_key}`,
        form: data,
        key: "application",
      });
      props.saveAppName({ name: application.name });
      setOpenRenameModal(!openRenameModal);
    } catch (e) {}
  };

  const renameFormInstance = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all",
    watch: "all",
  });
  const ownershipFormInstance = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all",
  });
  const deleteFormInstance = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    validateCriteriaMode: "all",
    watch: "all",
  });

  const watchAppName = renameFormInstance.watch("name");
  const watchConfirmAppNAme = deleteFormInstance.watch("confirm_app");

  const handleCancel = () => {
    /*istanbul ignore else*/
    if (watchAppName !== current_app_name) {
      renameFormInstance.setValue("name", current_app_name, {
        shouldValidate: true,
      });
    }
  };

  // MasterKey Visibility
  const handleVisibility = () => {
    setVisibility(!isVisible);
  };
  // MasterKey Copy
  const handleCopy = event => {
    Copy(master_key);
    setCopySnackbar(true);
  };

  /*istanbul ignore next*/
  const handleSnackbarClose = event => setCopySnackbar(false);

  // Transfer Ownership
  const handleOwnershipModal = () => {
    setOpenOwnershipModal(!openOwnershipModal);
  };
  const handleTransferOwnership = async ({ email }) => {
    try {
      await props.transferOwnership({
        url: `/v1/admin/applications/${sport_key}/transfer_ownership`,
        form: { email: email },
        key: "collaborator",
      });
      handleOwnershipModal();
      props.history.push("/");
    } catch (e) {}
  };

  // Delete
  const handleDeleteModal = () => {
    setOpenDelModal(!openDelModal);
  };
  const handleDelete = async ({ confirm_app }) => {
    try {
      /*istanbul ignore else*/
      if (confirm_app === current_app_name) {
        await props.deleteApp({
          url: `/v1/admin/applications/${sport_key}`,
        });
        handleDeleteModal();
        props.history.push("/");
      }
    } catch (e) {}
  };
  return (
    <>
      <Paper elevation={3}>
        <Section>
          <Typography variant="h6">Application Name</Typography>
          <Form
            formInstance={renameFormInstance}
            id="rename-app-form"
            className="maxWidthSm"
            handleFormSubmit={handleRename}
            autoFocus={false}
            name="name"
            requiredMsg="Application Name is required."
            pattern={val => validateAppName(val) || "Invalid Application Name."}
            defaultValue={current_app_name}
            TextFieldId="rename-app"
            placeholder="Enter Application Name"
            label="Application Name"
            type="text"
            variant="outlined"
          />
          <BtnWrap>
            <ActionButton
              data-testid="settings-cancel-btn"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              Cancel
            </ActionButton>
            <ActionButton
              data-testid="settings-rename-btn"
              variant="contained"
              color="primary"
              disabled={
                !renameFormInstance.formState.dirtyFields?.name ||
                !!Object.keys(renameFormInstance.formState.errors).length ||
                watchAppName === current_app_name
              }
              onClick={handleRenameModal}
            >
              Rename
            </ActionButton>
          </BtnWrap>
        </Section>

        <Section>
          <Typography variant="h6">Master Key</Typography>
          <BtnWrap>
            <Box>
              <Typography
                data-testid="master-key"
                id="master-key"
                variant="body2"
                className="master-key"
              >
                {isVisible ? master_key : "•".repeat(master_key.length)}
              </Typography>
            </Box>
            <IconBox>
              {!isVisible ? (
                <VisibilityIcon
                  data-testid="unhide"
                  onClick={handleVisibility}
                />
              ) : (
                <VisibilityOffIcon
                  data-testid="hide"
                  onClick={handleVisibility}
                />
              )}
              <CopyIcon
                onClick={handleCopy}
                fontSize="small"
                data-testid="copy-btn"
              />
            </IconBox>
          </BtnWrap>
        </Section>

        <Section>
          <Typography variant="h6">Transfer Ownership</Typography>
          <Typography variant="body2">
            <span>
              Once you transfer the ownership, you will be added as a
              collaborator, Please be certain.
            </span>
          </Typography>
          <BtnWrap>
            <ActionButton
              data-testid="settings-transferOwnership-btn"
              variant="contained"
              color="primary"
              onClick={handleOwnershipModal}
            >
              Transfer Ownership
            </ActionButton>
          </BtnWrap>
        </Section>

        <Section>
          <Typography variant="h6">Delete this application</Typography>
          <Typography variant="body2">
            <span>
              Once you delete the application, there is no going back. Please be
              certain.
            </span>
          </Typography>
          <BtnWrap>
            <ActionButton
              className="delete-btn"
              data-testid="settings-deleteApp-btn"
              variant="contained"
              onClick={handleDeleteModal}
            >
              Delete This Application
            </ActionButton>
          </BtnWrap>
        </Section>
      </Paper>

      {/* Rename App */}
      <Modal
        data-testid="rename-modal"
        className="small"
        open={openRenameModal}
        handleClose={handleRenameModal}
        title="Rename Application"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleRenameModal },
          {
            id: "2",
            name: "Rename",
            handler: null,
            additionalAttributes: {
              form: "rename-app-form",
              type: "submit",
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        <Typography>
          <span>Are you sure you want to rename application</span>
          <strong> {current_app_name}</strong> ?
        </Typography>
      </Modal>
      {/*Transfer OwnerShip*/}
      <Modal
        data-testid="ownership-modal"
        open={openOwnershipModal}
        handleClose={handleOwnershipModal}
        title="Transfer Ownership"
        actions={[
          { id: "1", name: "Cancel", handler: handleOwnershipModal },
          {
            id: "2",
            name: "Transfer",

            additionalAttributes: {
              type: "submit",
              form: "transferOwnership-form",
              disabled:
                !ownershipFormInstance.formState.dirtyFields?.email ||
                !!Object.keys(ownershipFormInstance.formState.errors).length,
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        <Form
          formInstance={ownershipFormInstance}
          id="transferOwnership-form"
          className="maxWidthSm"
          handleFormSubmit={handleTransferOwnership}
          autoFocus={false}
          name="email"
          requiredMsg="Email is required."
          pattern={val => validateEmail(val) || "Invalid email."}
          defaultValue=""
          TextFieldId="email"
          placeholder="Enter Email"
          label="Email"
          type="email"
          variant="outlined"
        />
      </Modal>
      {/* Delete Application  */}
      <Modal
        data-testid="delete-app-modal"
        open={openDelModal}
        handleClose={handleDeleteModal}
        title="Delete Application"
        actions={[
          { id: "1", name: "Cancel", handler: handleDeleteModal },
          {
            id: "2",
            name: "Delete",
            additionalAttributes: {
              type: "submit",
              form: "delete-app-form",
              disabled: watchConfirmAppNAme !== current_app_name,
              variant: "contained",
              color: "primary",
            },
          },
        ]}
      >
        <Typography>
          <span>Are you sure you want to delete application</span>{" "}
          <strong>{current_app_name}</strong>?
        </Typography>
        <Form
          formInstance={deleteFormInstance}
          id="delete-app-form"
          className="maxWidthSm"
          handleFormSubmit={handleDelete}
          autoFocus={false}
          name="confirm_app"
          requiredMsg="Application Name is required."
          pattern={val => validateAppName(val) || "Invalid Application Name."}
          defaultValue=""
          TextFieldId="confirm-app"
          placeholder="Enter Application Name"
          label="Application Name"
          type="text"
          variant="outlined"
        />
      </Modal>
      <Snackbar
        className="copied-snackbar-popup"
        open={copySnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Master key has been copied"
      />
    </>
  );
};

/*istanbul ignore next*/
const mapStateToProps = state => {
  return {
    sport_key: state.application.sport_key,
    master_key: state.application.master_key,
    current_app_name: state.application.name,
  };
};

/*istanbul ignore next*/
const mapDispatchToProps = dispatch => {
  return {
    renameApp: payload => dispatch(updateRecord(payload)),
    transferOwnership: payload => dispatch(createRecord(payload)),
    deleteApp: payload => dispatch(deleteRecord(payload)),
    saveAppName: payload => dispatch(applicationAction(payload)),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Settings);
export { Container, Settings as Component };
