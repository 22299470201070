import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "app/components/Common/Accordian";
import { customFieldsOptions as c_options } from "utils/constants";
import { createRecord } from "services/createObject";
import { get } from "services/getObject";
import { getSchema } from "services/customObject/getSchema";
import { requestAPI } from "services/helper";
import { showNotifier } from "store/reducers/notifierSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import cloneDeep from "lodash/cloneDeep";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FieldBorderWrapper from "app/components/Common/FieldBorder ";
import Label from "app/components/Common/Label";
import PropTypes from "prop-types";
import TreeView from "app/components/Common/CustomFieldForm";

const TemplateMapper = ({ modelName, title, uid, service_name = "nba" }) => {
  // States
  const [schema, setSchema] = useState({});
  const [errors, setErrors] = useState({});
  const [showDownloadbtn, setShowDownloadBtn] = useState(false);

  // Hooks
  const dispatch = useDispatch();

  const defaultSchema = {
    class: {
      schema_type: "template",
      title: title,
      uid: uid,
      _schema: [
        {
          uid: "",
          display_name: "",
          data_type: "string",
          field_metadata: {
            options: c_options,
            additional_options: [],
          },
          _schema: [],
        },
      ],
    },
  };

  // Functions
  const showErrors = error => {
    setErrors({ ...error });
  };

  const getTemplateName = useCallback(async () => {
    setSchema({});
    try {
      const {
        data: {
          template_mapper: { template_name },
        },
      } = await dispatch(
        requestAPI({
          url: `/v1/custom_schema/admin/template_mapper/search/${modelName}`,
          returnError: true,
          showToast: false,
          data: { _method: "Get" },
          loader: false,
        }),
      );
      /*istanbul ignore else*/
      if (template_name) {
        getTemplateSchema(template_name);
      }
    } catch ({ response: { status } }) {
      /*istanbul ignore else*/
      if (status === 422) {
        setSchema(defaultSchema);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplateSchema = async template_uid => {
    const { data } = await dispatch(
      getSchema({ uid: template_uid, loader: false }),
    );
    setShowDownloadBtn(true);
    setSchema(data);
  };

  const saveTemplate = async ({ uid, form }) => {
    try {
      await dispatch(
        requestAPI({
          url: `/v1/custom_schema/admin/classes/${uid}`,
          method: "PUT",
          returnError: true,
          data: { class: { ...form } },
        }),
      );
      getTemplateName();
    } catch ({ response: { status: resStat } }) {
      /*istanbul ignore else*/
      if (resStat === 422) {
        await dispatch(
          createRecord({
            url: `/v1/custom_schema/admin/classes`,
            key: "class",
            form,
          }),
        );
        await dispatch(
          createRecord({
            url: `/v1/custom_schema/admin/template_mapper`,
            key: "template_mapper",
            form: {
              model_name: modelName,
              service_name: service_name,
              template_name: form.uid,
            },
          }),
        );
        getTemplateName();
      }
    }
  };

  const onSubmit = data => {
    const form = { ...schema.class, ...data };
    saveTemplate({ uid: schema.class.uid, form });
  };

  // LifeCycle
  useEffect(() => {
    getTemplateName();
    return () => {
      setSchema({});
    };
  }, [getTemplateName]);

  const exportSchema = async event => {
    // get schema locally
    const { data } = await dispatch(
      get({ url: `v1/custom_schema/admin/classes/${schema.class.uid}/export` }),
    );
    const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${uid}.json`);
    document.body.appendChild(link);
    link.click();
  };

  const retrieveFiles = files => {
    //Convert and validate the Json data to js
    Array.from(files).forEach(file => {
      file.progress = 0;
      /*istanbul ignore next*/
      if (file.type === "application/json") {
        var reader = new FileReader();
        reader.readAsText(file);

        reader.onloadend = () => {
          try {
            const classSchema = cloneDeep(
              JSON.parse(JSON.parse(JSON.stringify(reader.result))),
            );

            /*istanbul ignore next*/
            if (
              classSchema.hasOwnProperty("schema_type") &&
              classSchema.hasOwnProperty("title") &&
              classSchema.hasOwnProperty("uid")
            ) {
              if (classSchema["schema_type"] !== "template") {
                dispatch(
                  showNotifier({
                    open: true,
                    message: "Schema type should be 'template'",
                    errors: {},
                    variant: "error",
                  }),
                );
              } else if (classSchema.uid !== schema.class.uid) {
                dispatch(
                  showNotifier({
                    open: true,
                    message:
                      "UID in json file does not match with existing UID",
                    errors: {},
                    variant: "error",
                  }),
                );
              } else {
                saveTemplate({ uid: classSchema.uid, form: classSchema });
              }
            } else {
              dispatch(
                showNotifier({
                  open: true,
                  message: "JSON does not match required format",
                  errors: {},
                  variant: "error",
                }),
              );
            }
          } catch (error) {
            /*istanbul ignore next*/
            dispatch(
              showNotifier({
                open: true,
                message: "Something went wrong please try again",
                errors: {},
                variant: "error",
              }),
            );
          }
        };
      }
    });
  };

  // Checking if children is Object or Array
  /*istanbul ignore else*/
  if (Object.keys(schema).length > 0) {
    return (
      <FieldBorderWrapper
        isBorder={true}
        isError={Object.keys(errors).length > 0}
        data-testid="field-border"
      >
        <Accordion defaultExpanded={true} data-testid="customField-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Label>Template Fields</Label>
          </AccordionSummary>
          <AccordionDetails>
            <TreeView
              {...{
                schema,
                schema_type: "template",
                readOnly: { title: true, uid: true },
                onSubmit,
                showErrors,
                "data-testid": "customfield-treeview",
                exportSchema,
                retrieveFiles,
                showDownloadbtn,
              }}
            />
          </AccordionDetails>
        </Accordion>
      </FieldBorderWrapper>
    );
  }

  return (
    <Box
      display="flex"
      component="div"
      justifyContent="center"
      data-testid="custom-field-loader"
    >
      <CircularProgress color="primary" size={20} />
    </Box>
  );
};

export default TemplateMapper;

TemplateMapper.propTypes = {
  modelName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};
