import { Container as GetEvents } from "app/pages/Dashboard/Data/Arena/Events/get";
import { Route, Switch } from "react-router-dom";
import AddEvents from "app/pages/Dashboard/Data/Arena/Events/add";
import React from "react";
import UpdateEvents from "app/pages/Dashboard/Data/Arena/Events/update";

const Events = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateEvents}
        exact
      />
      <Route path={`${props.match.path}/add`} component={AddEvents} exact />
      <Route path={`${props.match.path}`} component={GetEvents} />
    </Switch>
  );
};

export default Events;
