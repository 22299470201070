import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { Constants } from "utils/constants";
import { getDropsContent } from "services/getObjects";
import { initialRoute } from "utils/functions";
import { Link, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import GetContent from "app/pages/Dashboard/drops/drops_v1/Content/get/GetContent";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useState } from "react";
import Settings from "./Settings";

export default function DropsContent({ match, history, location }) {
  let tabObj = { drops: 0, settings: 1 };
  const tab = tabObj[location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }
  const { sport_key, env_key } = match.params;
  const { access_type } = useSelector(state => state.application);

  //States
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [contentCount, setContentCount] = useState(0);

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { skip, limit, sort, value, searchText } = useQueryParams();

  // Get Stories API Call
  const getContent = async () => {
    setLoading(true);

    const {
      data: { drops, count },
    } = await dispatch(
      getDropsContent({
        sport_key,
        env_key,
        skip,
        limit,
        sort,
        sortValue: value,
        searchText,
      }),
    );

    if (count !== 0 && drops.length === 0) {
      initialRoute(history);
    } else {
      setContent([...drops]);
      setContentCount(count);
      setLoading(false);
    }
  };

  return (
    <>
      <PanelHeader
        title="Drop Content"
        refreshHandler={getContent}
        data-testid="panel-header"
        breadCrumbItems={[
          {
            title: "Drop Content",
          },
        ]}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            data-testid="add_content-btn"
            color="primary"
            variant="contained"
            component={Link}
            to={{
              pathname: `/dashboard/${sport_key}/${env_key}/drops/portal/add`,
              state: { from: location },
            }}
            startIcon={<AddIcon />}
            disabled={true}
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
        >
          <Tab
            label="Drop Content"
            component={Link}
            to={`${match.url}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${match.url}/settings`}
            {...a11yProps(1)}
          />
        </Tabs>
      )}
      <Switch>
        <>
          <TabPanel>
            <Route path={`${match.path}/settings`} component={Settings} exact />
            <Route
              path={`${match.path}`}
              render={routeProps => (
                <GetContent
                  {...routeProps}
                  match={match}
                  history={history}
                  location={location}
                  getContent={getContent}
                  content={content}
                  contentCount={contentCount}
                  isLoading={isLoading}
                  access_type={access_type}
                />
              )}
              exact
            />
          </TabPanel>
        </>
      </Switch>
    </>
  );
}
