import React from "react";

const CustomField = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={0.5}>
        <path
          clipRule="evenodd"
          d="M1.667 3.667a2 2 0 012-2h12.666a2 2 0 012 2v2.666a2 2 0 01-2 2H3.667a2 2 0 01-2-2V3.667zM1.667 13.667a2 2 0 012-2h12.666a2 2 0 012 2v2.666a2 2 0 01-2 2H3.667a2 2 0 01-2-2v-2.666z"
          stroke="#EEE"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x={5} y={4} width={7} height={2} rx={1} fill="#EEE" />
        <rect x={5} y={14} width={7} height={2} rx={1} fill="#EEE" />
      </g>
    </svg>
  );
};
export default CustomField;
