import { Route, Switch } from "react-router-dom";
import { Container as SplitsTabs } from "app/pages/Dashboard/Data/Team/Splits/get";
import AddTeamSplits from "./add";
import React from "react";
import UpdateTeamSplits from "./update";

const Splits = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/add`} component={AddTeamSplits} exact />
      <Route
        path={`${props.match.path}/update/:uid`}
        component={UpdateTeamSplits}
        exact
      />
      <Route path={props.match.path} component={SplitsTabs} />
    </Switch>
  );
};

export default Splits;
