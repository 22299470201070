import {
  checkboxSelectAllClick,
  checkboxSingleClick,
} from "utils/tableActionHandler";
import { Constants } from "utils/constants";
import { deleteConfirmationMessage } from "utils/functions";
import { deleteRecord } from "services/deleteObject";
import { deleteRecords } from "services/deleteObjects";
import { Modal } from "@dfep/ui-component";
import { NoData } from "styles/global.style";
import { useDispatch } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import SkeletonTable from "app/components/Common/SkeletonTable";
import Table from "app/components/Common/Table";

const headings = [
  { label: "Title", id: "title" },
  { label: "Created At", id: "created_at", type: "date" },
  { label: "Updated At", id: "updated_at", type: "date" },
];

const Getv2Config = ({
  match,
  history,
  location,
  getConfig,
  config,
  count,
  isLoading,
  access_type,
}) => {
  const { READ_ONLY } = Constants;
  // States
  const [configObj, setConfigObj] = useState(null);
  const [dataId, setUid] = useState("");
  const [selected, setSelected] = useState([]); //for checkboxes

  // handle modals
  const {
    showDelete,
    showMultipleDelete,
    closeModalHandler,
    openModalHandler,
  } = useModal();

  // Params
  const { sport_key, env_key } = match.params;
  const { push } = history;

  // Redux Hooks
  const dispatch = useDispatch();

  // Pagination
  const { page, skip, limit, sort, value, searchText } = useQueryParams();

  // Functions
  // Handle Modal Close (Delete Icon)
  const handleModalClose = event => {
    closeModalHandler();
    setUid("");
    setConfigObj({});
  };

  // Handle Delete Config function
  const handleDelete = async (event, uid) => {
    event.stopPropagation();
    await dispatch(
      deleteRecord({
        url: `/v1/admin/drops/other/v2/config/${uid}`,
      }),
    );
    setSelected([]);
    handleModalClose(event);
    getConfig();
  };

  // Handle Multi Delete
  const multipleDeleteHandler = event => {
    openModalHandler("showMultipleDelete");
  };
  const deleteMultiple = async () => {
    await dispatch(
      deleteRecords({
        url: `/v1/admin/drops/other/v2/config`,
        uids: selected,
      }),
    );
    setSelected([]);
    getConfig();
    handleModalClose();
  };
  const handleSingleClick = (event, dataId) => {
    const newSingleSelected = checkboxSingleClick({ selected, dataId });
    setSelected(newSingleSelected);
  };

  const handleSelectAllClick = event => {
    const newMultiSelected = checkboxSelectAllClick({ event, data: config });
    setSelected(newMultiSelected);
  };

  // Component Life Cycle
  useEffect(() => {
    setSelected([]);
    getConfig();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, skip, limit, sort, value, searchText]);

  const actions = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditIcon fontSize="small" data-testid="edit_btn" />,
      handler: (event, uid) => {
        push({
          pathname: `/dashboard/${sport_key}/${env_key}/drops/v2/config/update/${uid}`,
          state: { from: location },
        });
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: <DeleteIcon fontSize="small" data-testid="delete_btn" />,
      handler: (event, uid, dataItem) => {
        setConfigObj(dataItem);
        setUid(uid);
        openModalHandler("showDelete");
      },
      showAction: () => access_type !== Constants.READ_ONLY,
    },
  ];

  return (
    <>
      {isLoading && (
        <SkeletonTable
          headings={headings}
          name="Drop V2 Config"
          actions={[
            {
              id: "edit",
              label: "Edit",
              icon: <EditIcon fontSize="small" data-testid="edit-btn" />,
              handler: () => {},
            },
            {
              id: "delete",
              label: "Delete",
              icon: <DeleteIcon fontSize="small" data-testid="delete-btn" />,
              handler: () => {},
            },
          ]}
          checkbox={true}
        />
      )}
      {config.length >= 1 && isLoading === false ? (
        <Table
          data-testid="table"
          name="Drop V2 Config"
          headings={headings}
          actions={actions}
          data={config}
          isSorting
          limit={10}
          totalCount={count}
          checkbox={access_type !== READ_ONLY}
          handleSelectAllClick={handleSelectAllClick}
          handleSingleClick={handleSingleClick}
          selectedList={selected}
          numSelected={selected.length}
          multipleDeleteHandler={multipleDeleteHandler}
        />
      ) : (
        !isLoading && <NoData data-testid="empty-data">No Data Found</NoData>
      )}
      <Modal
        className="small"
        data-testid="delete-modal"
        open={showDelete}
        handleClose={handleModalClose}
        title="Delete Drop V2 Config"
        maxwidth="sm"
        actions={[
          {
            id: "1",
            name: "No",
            handler: handleModalClose,
            additionalAttributes: {
              "data-testid": "no_btn",
            },
          },
          {
            id: "2",
            name: "Yes",
            handler: event => {
              return handleDelete(event, dataId);
            },
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "yes_btn",
            },
          },
        ]}
      >
        {configObj &&
          deleteConfirmationMessage({
            name: configObj?.title,
            module: "Drop V2 Config",
          })}
      </Modal>
      <Modal
        className="small"
        open={showMultipleDelete}
        handleClose={handleModalClose}
        title="Delete Drop V2 Config"
        maxwidth="sm"
        actions={[
          { id: "1", name: "Cancel", handler: handleModalClose },
          {
            id: "2",
            name: "Delete",
            handler: deleteMultiple,
            additionalAttributes: {
              variant: "contained",
              color: "primary",
              "data-testid": "confirm_multi_del",
            },
          },
        ]}
      >
        Are you sure want to delete <strong>{selected.length}</strong> items?
      </Modal>
    </>
  );
};

export default Getv2Config;
