import { a11yProps, Tab, TabPanel, Tabs } from "app/components/Common/Tabs";
import { Button } from "@dfep/ui-component";
import { capitalize } from "utils/functions";
import { Constants } from "utils/constants";
import { getGameLeader } from "services/getObjects";
import { Container as GetLeader } from "app/pages/Dashboard/Data/Game/Leader/get/GetLeader";
import { initialRoute } from "utils/functions";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { requestAPI } from "services/helper";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "app/hooks/modal";
import { useQueryParams } from "app/hooks";
import AddIcon from "@material-ui/icons/Add";
import PanelHeader from "app/components/Common/PanelHeader";
import React, { useCallback, useState } from "react";
import Settings from "./Settings";

const LeaderTabs = props => {
  const { team_name, tid, access_type } = useSelector(
    state => state.application,
  );

  let tabObj = {};
  tabObj[team_name] = 0;

  tabObj = { ...tabObj, others: 1, settings: 2 };
  const tab = tabObj[props.location.pathname.split("/").slice(-1)[0]];

  const [activeTab, setActiveTab] = useState(tab ?? 0);
  /* istanbul ignore else */
  if (tab !== undefined && tab !== activeTab) {
    setActiveTab(tab);
  }

  const dispatch = useDispatch();
  const { show, closeModalHandler, openModalHandler } = useModal();
  const { sport_key, env_key } = props.match.params;
  const { sort, limit, value, skip, searchText } = useQueryParams();

  const syncModalClose = () => {
    closeModalHandler();
  };
  const syncDetails = () => {
    dispatch(requestAPI({ url: "/v1/nba/admin/sync/game_leaders" }));
    closeModalHandler();
  };

  //  get data api
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalDataCount, setTotalDataCount] = useState();

  // const { team_name, sport_key, env_key } = props.match.params;

  const getLeaders = useCallback(
    async team_name => {
      setIsLoading(true);
      try {
        const {
          data: { game_leaders, count },
        } = await dispatch(
          getGameLeader({
            team_name,
            tid,
            sort,
            limit,
            value,
            skip,
            searchText,
          }),
        );

        if (count !== 0 && game_leaders.length === 0) {
          initialRoute(props.history);
        } else {
          setData(game_leaders);
          setTotalDataCount(count);
          setIsLoading(false);
        }
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, limit, value, skip, searchText, tid],
  );

  return (
    <>
      <PanelHeader
        title="Game Leader"
        breadCrumbItems={[
          {
            title: "Game Leader",
          },
        ]}
        refreshHandler={() => {
          getLeaders(tab === 0 ? team_name : "others");
        }}
        {...(access_type !== Constants.READ_ONLY && {
          sync: () => openModalHandler("show"),
        })}
        search={true}
      >
        {access_type !== Constants.READ_ONLY && (
          <Button
            color="primary"
            data-testid="add-btn"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              props.history.push({
                pathname: `/dashboard/${sport_key}/${env_key}/data/game/leader/add`,
                state: { from: props.location },
              })
            }
          >
            Add
          </Button>
        )}
      </PanelHeader>
      {!searchText && (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={capitalize(team_name)}
            component={Link}
            to={`${props.match.url}/${team_name}`}
            {...a11yProps(0)}
          />
          <Tab
            label="Others"
            component={Link}
            to={`${props.match.url}/others`}
            {...a11yProps(1)}
          />
          <Tab
            label="Settings"
            component={Link}
            to={`${props.match.url}/settings`}
            {...a11yProps(2)}
          />
        </Tabs>
      )}
      <TabPanel>
        <Switch>
          <Redirect
            from={`${props.match.path}`}
            to={`${props.match.url}/${team_name}`}
            exact
          />
          <Route
            path={`${props.match.path}/settings`}
            component={Settings}
            exact
          />
          <Route
            path={`${props.match.path}/:team_name`}
            render={routeProps => (
              <GetLeader
                {...routeProps}
                tid={tid}
                openSyncModal={show}
                syncModalClose={syncModalClose}
                syncDetails={syncDetails}
                access_type={access_type}
                getLeaders={getLeaders}
                data={data}
                isLoading={isLoading}
                totalDataCount={totalDataCount}
              />
            )}
            exact
          />
        </Switch>
      </TabPanel>
    </>
  );
};

export default LeaderTabs;
