/* istanbul ignore file */
import { Footer as MuiFooter } from "@dfep/ui-component";
import styled from "styled-components";

export const LayoutWrap = styled.div`
  display: flex;
  .team-name {
    padding-left: 16px;
    margin-left: 4px;
    border-left: 1px solid ${({ theme }) => theme.palette.grey.grey300};
    text-transform: capitalize;
  }
  .seprator {
    display: none;
  }
  .dashboard-icon {
    color: ${({ theme }) => theme.palette.grey.greyE0E0E0};
  }
  .footer {
    background-color: ${({ theme }) => theme.palette.grey.grey100};
  }
`;

export const ContentToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  min-height: calc(
    ${props => props.theme.header.maxHeight} +
      ${props => props.theme.subHeader.maxHeight}
  );
  // necessary for content to be below app bar
  //...theme.mixins.toolbar, [......... not working in case of Subheader + Header]
`;

export const Content = styled.main`
  flex-grow: 1;
  padding: 0;
  /* overflow-x: hidden; */
  &.content {
    padding: 0 24px;
  }
  &.contentShift {
    transition: ${props =>
      props.theme.transitions.create("margin", {
        easing: props.theme.transitions.easing.easeOut,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
    margin-left: 0;
  }
`;

export const ContentChildren = styled.div`
  padding: 24px 24px 90px;
  &.innerContent {
    padding: 0 0 120px;
  }
`;

export const PanelContent = styled.div`
  display: flex;
  overflow-x: hidden;
`;

export const Footer = styled(MuiFooter)`
  background-color: ${({ theme }) => theme.palette.grey.grey100};
  .MuiList-padding {
    padding: 3px 0;
  }
`;
