import MuiGrid from "@material-ui/core/Grid";
import styled from "styled-components";

export const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    position: relative;
    /* overflow: hidden; */
    &.message-content {
      .MuiInputBase-root {
      }
      .MuiButtonBase-root {
        background: ${props => props.theme.palette.grey.greyF2F2F2};
        border-left: 1px solid rgba(0, 0, 0, 0.38);
        border-radius: 0 8px 8px 0;
        padding: 10.5px 12px;
        position: absolute;
        top: 7px;
        right: 7px;
      }
      .code-btn-icon {
        height: 45px;
        z-index: 3;
      }
      .MuiSelect-select {
        &:focus {
          background: ${props => props.theme.palette.white.main};
        }
      }
    }
  }
`;

export const DiabledLayer = styled.div`
  left: 0;
  position: absolute;
  top: 5px;
  border: 1px solid transparent;
  border-radius: 8px;
  z-index: 2;
  padding: 13px 9px;
  right: 0;
  margin: 0 6px;
  height: 47px;
`;
